// @ts-strict-ignore
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {Subscriptions} from '../../utils/subscriptions';
import {CustomerService} from '../../services/customer.service';
import {Customer} from '../../models/customer';
import {FeatureToggle} from '../../models/feature-toggle';
import {CustomerSketchSet} from '../../models/customer-sketch-set';
import {CustomerReportTemplate} from '../../models/customer-report-template';
import {FeatureToggleCheckService} from '../../services/feature-toggle-check.service';
import {HistoryService} from "../../services/history.service";

@Component({
    selector: 'app-customer-detail',
    templateUrl: './customer-detail.component.html'
})
export class CustomerDetailComponent implements OnInit, OnDestroy {
    public customersEnabled = false;
    public customerSpecificReportTemplatesEnabled = false;

    form: UntypedFormGroup;
    private subscriptions: Subscription[] = [];
    public sketchSets: CustomerSketchSet[] = [];
    public reportTemplates: CustomerReportTemplate[] = [];
    private DEFAULT_TEMPLATE = 'Standaard PDF';

    constructor(
        @Inject('CustomerService') private customerService: CustomerService,
        @Inject('FeatureToggleCheckService') private featureToggleCheckService: FeatureToggleCheckService,
        private route: ActivatedRoute,
        private toast: ToastrService,
        private router: Router,
        fb: UntypedFormBuilder,
        private historyService: HistoryService
    ) {
        this.form = this.createForm(fb);
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.route.data.subscribe(data => data.customer ? this.bindCustomer(data.customer) : null)
        );
        this.featureToggleCheckService.featureToggles().subscribe(featureToggles => {
            this.customersEnabled = FeatureToggleCheckService.isEnabled(featureToggles, FeatureToggle.CustomersEnabled);
            this.customerSpecificReportTemplatesEnabled
                = FeatureToggleCheckService.isEnabled(featureToggles, FeatureToggle.CustomerSpeciticReportTemplatesEnabled);
            if (this.customersEnabled) {
                this.subscriptions.push(
                    this.customerService.getCustomerSketchSets().subscribe(
                        data => {
                            this.sketchSets = data;
                        }, err => console.error(err)
                    )
                );
            }
            if (this.customerSpecificReportTemplatesEnabled) {
                this.subscriptions.push(
                    this.customerService.getCustomerReportTemplates().subscribe(
                        data => {
                            this.reportTemplates = [{name: this.DEFAULT_TEMPLATE}, ...data];
                        }, err => console.error(err)
                    )
                );
            }
        });
    }

    ngOnDestroy(): void {
        Subscriptions.unsubscribeAll(this.subscriptions);
    }

    async save() {
        const newCustomer = this.form.value as Customer;

        try {
            let result;

            if (newCustomer.reportTemplate === this.DEFAULT_TEMPLATE) {
                newCustomer.reportTemplate = null;
            }
            if (newCustomer.id) {
                result = await this.customerService.putCustomer(newCustomer).toPromise();
            } else {
                result = await this.customerService.postCustomer(newCustomer).toPromise();
            }
            this.toast.success('Opgeslagen');
            this.form.patchValue(result);
            this.goBack();
        } catch (ex) {
            console.error('Unable to save newCustomer', ex);
            this.toast.error('Opslaan mislukt');
        }
    }

    cancel() {
        this.goBack();
    }

    goBack() {
        this.historyService.goBack(['/beheer/customers']);
    }

    public createForm(fb: UntypedFormBuilder): UntypedFormGroup {
        return fb.group({
            id: fb.control(null),
            name: fb.control(null),
            description: fb.control(null),
            sketchSet: fb.control(null),
            reportTemplate: fb.control(null),
        });
    }

    private bindCustomer(customer: Customer) {
        if (customer.reportTemplate === null) {
            customer.reportTemplate = this.DEFAULT_TEMPLATE;
        }
        this.form.patchValue(customer);
    }
}
