import {ProjectAuthorization} from './project-authorization';
import {ProjectWorkerGroup} from './project-worker-group';
import {User} from './user';
import {Customer} from './customer';
import {MapLayerData} from './maplayerdata';
import {SharepointCredentialListDTO} from "./sharepoint-credential";

export enum ProjectExportTemplate {
    DEFAULT = 'DEFAULT',
    WELDING = 'WELDING'
}

export interface Project {
    id: number;
    name: string;
    code: string;
    active: boolean;
    vise: boolean;
    wid: string;
    hasMaintenanceContract: boolean;
    requireVerification: boolean;
    requireSignature: boolean;
    customer?: Customer;
    authorizations: ProjectAuthorization[];
    workerGroups: ProjectWorkerGroup[];
    coordinators: User[];
    jobNotifyEmail: string;
    jobNotifyEmailSubject?: string;
    configurationUrl: string;
    mapLayersData: MapLayerData[];
    logo: string;
    gis: boolean;
    sharepointExportEnabled: boolean;
    sharepointUrl: string;
    sharepointOverrule: boolean;
    sharepointSiteId: string;
    sharepointExportPath: string;
    sharepointLibrary: string;
    sharepointCredential: SharepointCredentialListDTO;
    exportTemplate?: ProjectExportTemplate
}
