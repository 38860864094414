// @ts-strict-ignore
import {Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {
    AbstractControl,
    FormControl, FormGroup,
    ValidationErrors,
    Validators
} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {SharepointCredentialService} from "../../services/sharepoint-credential.service";
import {ToastrService} from "ngx-toastr";
import {SharepointCredential, SharepointCredentialAuthenticationType} from "../../models/sharepoint-credential";
import {firstValueFrom, merge, Observable, of, Subscription} from "rxjs";
import {Subscriptions} from "../../utils/subscriptions";
import {Forms} from "../../utils/forms";
import {redactedValueAsUndefined} from "../../utils/redacted";

@Component({
    selector: 'app-sharepoint-credential-detail',
    templateUrl: './sharepoint-credential-detail.component.html'
})
export class SharepointCredentialDetailComponent implements OnInit, OnDestroy {
    form = new FormGroup({
        id: new FormControl<number>(null),
        title: new FormControl<string>('', [Validators.required, Validators.maxLength(255)]),
        authenticationType: new FormControl<SharepointCredentialAuthenticationType>("Password", Validators.required),
        hostname: new FormControl<string>(
            null,
            [Validators.required, Validators.maxLength(255)],
            [this.validateSharepointCredentialNotTaken.bind(this)]
        ),
        tenantId: new FormControl<string>('', [Validators.required, Validators.maxLength(255)]),
        clientId: new FormControl<string>('', [Validators.required, Validators.maxLength(255)]),
        clientSecret: new FormControl<string>('', [Validators.required, Validators.maxLength(255)]),
        username: new FormControl<string>('', [Validators.required, Validators.maxLength(255)]),
        password: new FormControl<string>('', [Validators.required, Validators.maxLength(255)]),
    })

    private subscriptions: Subscription[] = [];

    constructor(
        private route: ActivatedRoute,
        @Inject('SharepointCredentialService') private sharepointCredentialService: SharepointCredentialService,
        private toast: ToastrService,
        private router: Router
    ) {}

    ngOnInit(): void {
        const routeDataSubscription = this.route.data.subscribe((data) => {
           if (data.sharepointCredential) {
               this.form.patchValue(data.sharepointCredential);
           }
        });
        this.subscriptions.push(routeDataSubscription);

        this.subscriptions.push(merge(
            of(this.form.controls.authenticationType.value),
            this.form.controls.authenticationType.valueChanges
        ).subscribe(authenticationType => {
            if (authenticationType == 'ClientCredentials') {
                this.form.controls.username.disable()
                this.form.controls.password.disable()
            } else {
                this.form.controls.username.enable()
                this.form.controls.password.enable()
            }
        }))
    }

    ngOnDestroy(): void {
        Subscriptions.unsubscribeAll(this.subscriptions)
    }

    async save() {
        if (!this.form.valid) {
            Forms.updateValueAndValidityRecursive(this.form);
            this.toastInvalidForm();
            return;
        }

        const formValue = this.form.value as SharepointCredential;

        try {
            const item = {
                ...formValue,
                clientSecret: redactedValueAsUndefined(formValue.clientSecret),
                password: redactedValueAsUndefined(formValue.password)
            }

            const result = await firstValueFrom(formValue.id
                ? this.sharepointCredentialService.putSharepointCredential(item)
                : this.sharepointCredentialService.postSharepointCredential(item))

            this.toast.success('Opgeslagen');
            this.form.patchValue(result);

            this.goBack();
        } catch (ex) {
            console.error('Unable to save sharepoint credential', ex);
            this.toast.error('Opslaan mislukt');
        }
    }

    cancel() {
        this.goBack();
    }

    goBack() {
        this.router.navigate(['/beheer/sharepoint-credentials']);
    }

    private validateSharepointCredentialNotTaken(control: AbstractControl): Observable<ValidationErrors> {
        const currentId = +this.form.get('id').value;

        return Forms.validateNotTaken(control, currentId, this.sharepointCredentialService.exists(control.value))
    }

    private toastInvalidForm() {
        this.toast.error('Vul alle verplichte velden in (in het rood aangegeven)');
    }

}
