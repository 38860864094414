import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {AngularPlugin} from "@microsoft/applicationinsights-angularplugin-js";
import {ApplicationInsights, IExceptionTelemetry} from "@microsoft/applicationinsights-web";
import {AuthenticationService} from "./authentication.service";

@Injectable({
    providedIn: 'root'
})
export class ApplicationInsightsService {
    private initialized = false;
    private appInsights?: ApplicationInsights
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) {}

    async initialize() {
        if(this.initialized) {
            // Already initialized
            return;
        }

        try {
            this.initialized = true;

            const connectionString = await fetch('/.well-known/ai-connection-string')
                .then(response => response.json())
                .then(responseJson => responseJson['connectionString']);

            if (!connectionString) {
                console.warn("Disabling Application insights, connection string not set")
                return;
            }

            const angularPlugin = new AngularPlugin()
            this.appInsights = new ApplicationInsights({
                config: {
                    connectionString: connectionString,

                    enableCorsCorrelation: true,
                    correlationHeaderDomains: [window.location.origin],

                    extensions: [angularPlugin],
                    extensionConfig: {
                        [angularPlugin.identifier]: {router: this.router}
                    }
                }
            });
            this.appInsights.loadAppInsights();

            console.info('Application insights initialized');
            (window as any).appInsights = this.appInsights;
        } catch (e) {
            console.error('Failed to initialize Application insights', e);
            this.initialized = false;
        }

        this.authenticationService.loggedInEmail$.subscribe(email => {
            if (email) {
                this.appInsights?.setAuthenticatedUserContext(email)
            } else {
                this.appInsights?.clearAuthenticatedUserContext()
            }
        });
    }

    trackException(exception: IExceptionTelemetry) {
        this.appInsights?.trackException(exception);
    }

}
