import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Inject, Injectable} from '@angular/core';
import {ProjectFormService} from '../services/project-form.service';
import {ProjectForm} from '../models/form-type';

@Injectable({
    providedIn: 'root'
})
export class ProjectFormResolver implements Resolve<ProjectForm> {

    constructor(
        @Inject('ProjectFormService') private projectFormService: ProjectFormService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.projectFormService.getDetail(+route.params.id, +route.params.formId);
    }
}
