import {TableFilter} from '../components/table/table.component';
import {HttpParams} from '@angular/common/http';
import {ActivatedRoute, Params, Router} from '@angular/router';

export class Filters {
    static applyFiltering(filtering: TableFilter, httpParams: HttpParams) {
        Object.keys(filtering.filter).forEach(key => {
            const filter = filtering.filter[key];
            const value = typeof filter === 'object' ? filter.value : filter;
            const operator = typeof filter === 'object' ? filter.operator : ':';

            httpParams = httpParams.append('search', `${key}${operator}${value}`);
        });

        if (filtering.sort && filtering.sort.sort && filtering.sort.direction) {
            httpParams = httpParams
                .set('sort', filtering.sort.sort)
                .set('direction', filtering.sort.direction);
        }

        return httpParams;
    }

    static getFilteringFromParams(queryParams: Params): TableFilter {
        const tableFilter: TableFilter = {
            sort: {
                sort: queryParams.sort,
                direction: queryParams.direction
            },
            filter: {}
        };

        Object.keys(queryParams).forEach(key => {
            if (key.startsWith('filter-')) {
                tableFilter.filter[`${key.replace('filter-', '')}`] = queryParams[key];
            }
        });

        return tableFilter;
    }

    static getSearchFromParams(params: Params) {
        if (params.q) {
            return params.q;
        }

        return null;
    }

    static tableFilterToQueryParamsObject(filtering: TableFilter) {
        const queryParams: {[key: string]: any} = {};
        Object.keys(filtering.filter).forEach(key => {
            if (filtering.filter[key]) {
                queryParams[`filter-${key}`] = filtering.filter[key];
            }
        });

        return queryParams;
    }

    static saveFilteringToRoute(filtering: TableFilter, activatedRoute: ActivatedRoute, router: Router, additionalParams: Params = {}) {
        let queryParams = {...activatedRoute.snapshot.queryParams};
        delete queryParams['q']; // Cannot combine filters and search

        Object.keys(filtering.filter).forEach(key => {
            if (filtering.filter[key]) {
                queryParams[`filter-${key}`] = filtering.filter[key];
            } else {
                delete queryParams[`filter-${key}`];
            }
        });

        if (filtering.sort) {
            Object.entries(filtering.sort).forEach(([key, value]) => {
                queryParams[key] = value;
            });
        }

        Filters.mergeCurrentTabAndPage(queryParams, activatedRoute);
        queryParams = {...queryParams, ...additionalParams};

        router.navigate([], {relativeTo: activatedRoute, queryParams, replaceUrl: true});
    }

    static saveSearchToRoute(searchValue: string, activatedRoute: ActivatedRoute, router: Router) {
        const queryParams = {q: searchValue};

        Filters.mergeCurrentTabAndPage(queryParams, activatedRoute);

        router.navigate([], {relativeTo: activatedRoute, queryParams, replaceUrl: true});
    }

    private static mergeCurrentTabAndPage(newQueryParams: {[key: string]: any}, activatedRoute: ActivatedRoute) {
        const oldQueryParams = activatedRoute.snapshot.queryParams;

        if (oldQueryParams && oldQueryParams.tab) {
            newQueryParams['tab'] = oldQueryParams.tab;
        }
        if (oldQueryParams && oldQueryParams.page) {
            newQueryParams['page'] = oldQueryParams.page;
        }
    }
}
