<app-toolbar [showBackBtn]="true"></app-toolbar>
<div class="vwui-container is-small">
  <div class="vwui-card">
    <div class="vwui-card__header">{{'Projectdiscipline'|translate}}</div>
    <div class="vwui-card__content">
      <form [formGroup]="form" (submit)="save()" [class.form-is-valid]="form.valid">
        <div class="vwui-form-group" [appFormControlError]="form.get('title')">
          <label for="title" class="vwui-label">{{'Projectdisciplinenaam'|translate}}</label>
          <input id="title" class="vwui-input" name="title" type="text" formControlName="title" autocomplete="off">
        </div>
        <button type="submit" class="d-none"><!-- Needed to submit with enter --></button>
      </form>
    </div>
  </div>
</div>
<app-actionbar *ngIf="form.dirty" (cancel)="goBack()" (save)="save()"></app-actionbar>
