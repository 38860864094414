import {Component, OnInit} from '@angular/core';
import {NgSelectConfig} from '@ng-select/ng-select';
import {TranslateService} from '@ngx-translate/core';
import {nlLanguage} from '../assets/i18n/nl';
import {nlLanguageTenantSpecific} from '../assets/i18n/nl-tenantspecific';
import {Title} from '@angular/platform-browser';
import {HistoryService} from "./services/history.service";
import {ApplicationInsightsService} from "./services/application-insights.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

    constructor(
        private config: NgSelectConfig,
        private translate: TranslateService,
        private titleService: Title,
        private historyService: HistoryService,
        private appInsightsService: ApplicationInsightsService
    ) {
        translate.setTranslation('nl', nlLanguage);
        translate.setTranslation('nl', nlLanguageTenantSpecific, true);
        translate.setDefaultLang('nl');
        translate.use('nl').subscribe();
    }

    ngOnInit(): void {
        this.appInsightsService.initialize()
        this.applyNgSelectConfig(this.config);
        this.titleService.setTitle( this.translate.instant('application.title') );
    }

    // noinspection JSMethodCanBeStatic
    private applyNgSelectConfig(config: NgSelectConfig) {
        config.notFoundText = 'Geen resultaten';
        config.addTagText = 'Toevoegen';
        config.typeToSearchText = 'Type om te zoeken';
        config.loadingText = 'Laden...';
    }
}
