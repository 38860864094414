// @ts-strict-ignore
import {AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {AbstractForm, FormType} from '../models/form-type';
import {ChapterFormItem, FormItem, QuestionSetQuestion, QuestionType} from '../models/form-item';
import {Observable, of, timer} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

export class Forms {
    static updateValueAndValidityRecursive(form: UntypedFormGroup) {
        const queue = [];
        for (const controlKey in form.controls) {
            if (form.controls.hasOwnProperty(controlKey)) {
                queue.push(form.controls[controlKey]);
            }
        }

        while (queue.length) {
            const formControl = queue.pop();
            formControl.markAsTouched();
            formControl.updateValueAndValidity();

            if (formControl instanceof UntypedFormGroup) {
                for (const controlKey in formControl.controls) {
                    if (formControl.controls.hasOwnProperty(controlKey)) {
                        queue.push(formControl.controls[controlKey]);
                    }
                }
            }
        }
    }

    static validateNotTaken(control: AbstractControl, currentId: number, existsCheck: Observable<number>): Observable<ValidationErrors> {
        return timer(300).pipe(
            switchMap(() => {
                if (!control.value) {
                    return of(null);
                }

                return existsCheck;
            }),
            map(existingId => (!currentId && existingId) || (currentId !== +existingId && existingId) ? {exists: true} : null)
        );
    }

    static convertToChapterTree(formType: AbstractForm): Array<FormItem> {
        const result = [];

        let chapterCount = 0;
        let lastChapter: ChapterFormItem|QuestionSetQuestion = null;

        for (const formItem of formType.formItems) {
            if (formItem.type === QuestionType.chapter) {
                chapterCount = chapterCount + 1;

                lastChapter = formItem;
                lastChapter.children = [];
                lastChapter.chapterNumber = chapterCount;

                result.push(lastChapter);
            } else if (formItem.type === QuestionType.questionSet) {
                lastChapter = formItem;
                lastChapter.children = [];
                let lastQuestionSetChapter = null;

                // Loop through the formItems from the questionSet and set chapterNumber
                for (const questionSetFormItem of formItem.questionSet.formItems) {
                    if (questionSetFormItem.type === QuestionType.chapter) {
                        chapterCount = chapterCount + 1;

                        lastQuestionSetChapter = questionSetFormItem;
                        lastQuestionSetChapter.children = [];
                        lastQuestionSetChapter.chapterNumber = chapterCount;

                        lastChapter.children.push(lastQuestionSetChapter);
                    } else {
                        lastQuestionSetChapter.children.push(questionSetFormItem);
                    }
                }

                result.push(lastChapter);
            } else {
                lastChapter.children.push(formItem);
            }
        }

        return result;
    }

    static toggleValidation(control: AbstractControl, validators: ValidatorFn[], toggle: boolean) {
        if (toggle) {
            control.setValidators(validators);
        } else {
            control.setValidators(null);
            control.patchValue('');
        }

        control.updateValueAndValidity();
    }

    static validateFormNumber(control: AbstractControl, response: FormType[], form: UntypedFormGroup): boolean {
        if (control.value) {
            if (response.length > 0) {
                for (const formType of response) {
                    if (form.controls.id.value && (formType.id !== form.controls.id.value)) {
                        return true;
                    } else if (!form.controls.id.value) {
                        return true;
                    }
                }
            }
        }
        return false;
    }
}
