// @ts-strict-ignore
import {Observable, of} from 'rxjs';
import {PageResponse} from '../../models/page-response';
import {AbstractCrudServiceMock} from './abstract-crud.service.mock';
import {Injectable} from '@angular/core';
import {QuestionSet} from '../../models/question-set';
import {QuestionSetService} from '../question-set.service';
import {FormTypes} from '../../models/form-type';
import * as moment from 'moment';

export const mockQuestionSets: Array<QuestionSet> = [
    {
        id: 2,
        title: 'Boortoren',
        formItems: [],
        type: FormTypes.QuestionSet,
        askLocation: false,
        showLocationOnMap: false,
        createdBy: 'w.slaghekke@recognize.nl',
        updatedAt: moment().toISOString(),
        createdAt: moment().toISOString(),
        executorCanCreate: true,
        askExecutorToFillFormAgain: false,
        copyAnswers: false,
        contactPerson: null,
        revisionNumber: '0'
    },
    {
        id: 1,
        title: 'Hoogspanningsmast',
        formItems: [],
        type: FormTypes.QuestionSet,
        askLocation: false,
        showLocationOnMap: false,
        createdBy: 'w.slaghekke@recognize.nl',
        updatedAt: moment().toISOString(),
        createdAt: moment().toISOString(),
        executorCanCreate: true,
        askExecutorToFillFormAgain: false,
        copyAnswers: false,
        contactPerson: null,
        revisionNumber: '0'
    }
];

@Injectable()
export class QuestionSetServiceMock extends AbstractCrudServiceMock<QuestionSet> implements QuestionSetService {

    constructor() {
        super(mockQuestionSets);
    }

    getQuestionSets(page: number): Observable<PageResponse<QuestionSet>> {
        return this.getAll();
    }

    getQuestionSet(id: number) {
        return this.get(id);
    }

    exists(title: string) {
        const result = mockQuestionSets.find(item => item.title === title);

        return of(result ? result.id : null);
    }

    postQuestionSet(questionSet: QuestionSet) {
        return this.post(questionSet);
    }

    putQuestionSet(questionSet: QuestionSet): Observable<QuestionSet> {
        return this.put(questionSet);
    }
}
