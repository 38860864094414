// @ts-strict-ignore
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {map, shareReplay, switchMap, tap} from 'rxjs/operators';
import {PageResponse} from '../models/page-response';

export class Paginator<T> {
    private pageSubject = new BehaviorSubject<number>(this.initialPage);
    private refreshSubject = new BehaviorSubject<void>(null);

    totalPages: number;
    currentPage: number;

    isEmpty: boolean;
    content$: Observable<T[]> = combineLatest([this.pageSubject, this.refreshSubject]).pipe(
        switchMap(() => this.loadFunction(this.pageSubject.value)),
        tap(response => {
            this.totalPages = response.totalPages;
            this.currentPage = response.number;
            this.isEmpty = response.empty;
        }),
        map(response => response.content),
        shareReplay({bufferSize: 1, refCount: true})
    );
    page$ = this.pageSubject.asObservable();

    constructor(
        private loadFunction: (page: number) => Observable<PageResponse<T>>,
        private initialPage: number = 0
    ) {}

    refresh() {
        this.refreshSubject.next(null);
    }

    next() {
        this.pageSubject.next(this.pageSubject.value + 1);
    }

    previous() {
        this.pageSubject.next(this.pageSubject.value - 1);
    }

    setPage(page: number) {
        this.pageSubject.next(page);
    }
}
