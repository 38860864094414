<app-toolbar
    [showAddBtn]="true"
    (add)="openModal()"
    addBtnLabel="{{'Contentcodes importeren'|translate}}">
</app-toolbar>
<app-table *ngIf="paginator$|async as paginator" [paginator]="paginator" [headerTitles]="headerTitles"
           (filterChanged)="onTableFilterChange($event)">
    <ng-template let-item>
        <tr>
            <td>{{ item.orderId }}</td>
            <td>{{ item.uploadFilename }}</td>
            <td>{{ item.totalCodes }}</td>
            <td>{{ item.unusedCodes }}</td>
            <td>{{ item.createdAt | dateFormat }}</td>
        </tr>
    </ng-template>
</app-table>
