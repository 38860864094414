<ng-container [formGroup]="configForm">
    <div [appFormControlError]="configForm.controls.totalDecimal" class="vwui-form-group">
        <label class="vwui-label" for="totalDecimal">{{'Aantal cijfers achter de komma'|translate}}</label>
        <input
            class="vwui-input"
            id="totalDecimal"
            name="totalDecimal"
            formControlName="totalDecimal"
            autocomplete="off"
            type="number"
            min="0"
            max="12"
            placeholder="{{'Aantal cijfers achter de komma'|translate}}">
    </div>
    <label class="vwui-input-control is-toggle has-label mb-4 vwui-label">
        <input class="vwui-input" type="checkbox" name="showAverage" formControlName="showAverage">
        <span>{{'Toon gemiddelde'|translate}}</span>
        <span class="checkbox"></span>
    </label>
    <label class="vwui-input-control is-toggle has-label mb-4 vwui-label">
        <input class="vwui-input" type="checkbox" name="showSum" formControlName="showSum">
        <span>{{'Toon som'|translate}}</span>
        <span class="checkbox"></span>
    </label>
</ng-container>
