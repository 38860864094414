<app-modal
    [modalTitle]="'Kies het excelsheet en de benodigde afbeeldingen' | translate"
    [showSaveBtn]="true"
    [enableSaveBtn]="!!workbook"
    modalSaveBtnLabel="{{'Importeer formulieren' | translate}}"
    [showCloseBtn]="true"
    [modalCancelBtnLabel]="'Sluiten' | translate"
    (cancel)="close(false)"
    (save)="close(true)"
>
    <app-file-upload-btn (fileChange)="setWorkbook($event)"
                         [accept]="'.xlsx'" [maxSize]="5000000"
                         data-ci="import-form-types-workbook-button">
        {{'Selecteer een excelsheet'|translate}}
        <app-icon *ngIf="workbook" iconName="check"></app-icon>
    </app-file-upload-btn>

    <app-file-upload-btn (fileChange)="setImageZip($event)"
                         [accept]="'.zip'" [maxSize]="25000000"
                         data-ci="import-form-types-images-button">
        {{'Selecteer een zip bestand met afbeeldingen'|translate}}
        <app-icon *ngIf="imageZip" iconName="check"></app-icon>
    </app-file-upload-btn>

</app-modal>
