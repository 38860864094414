// @ts-strict-ignore
import {Component, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {UserRoles} from '../../models/user';

@Component({
    selector: 'app-user-roles',
    templateUrl: './user-roles.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => UserRolesComponent),
            multi: true
        }
    ]
})
export class UserRolesComponent implements ControlValueAccessor {

    disabled = false;
    userRoles = [
        {
            title: 'Applicatiebeheerder',
            role: UserRoles.RoleApplicationAdmin,
            checked: false
        },
        {
            title: 'Coördinator',
            role: UserRoles.RoleCoordinator,
            checked: false
        },
        {
            title: 'Masterdatabeheerder',
            role: UserRoles.RoleMasterDataAdmin,
            checked: false
        },
        {
            title: 'Uitvoering',
            role: UserRoles.RoleExecutor,
            checked: false
        }
    ];

    private onChange: (value: Array<UserRoles>) => void;
    private onTouched: () => void;

    writeValue(checkedRoleNames: Array<UserRoles>): void {
        this.userRoles.forEach(userRole => {
            userRole.checked = checkedRoleNames.indexOf(userRole.role) !== -1;
        });
    }

    registerOnChange(fn: (value: Array<UserRoles>) => void): void {
        this.onChange = (value) => {
            fn(value);
        };
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    change(event: Event, roleName: UserRoles) {
        const checkbox = event.target as HTMLInputElement;
        const userRole = this.userRoles.find(it => it.role === roleName);
        userRole.checked = checkbox.checked;

        const checkedRoleNames = this.userRoles
            .filter(it => it.checked)
            .map(it => it.role);

        this.onTouched();
        this.onChange(checkedRoleNames);
    }
}
