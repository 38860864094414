import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'tableQuestionAnswers',
})
export class TableQuestionAnswersPipe implements PipeTransform {
    transform(value: string): string[] {
        if (!value) {
            return [];
        }

        return value.split(',')
            .map(it => it.replace('.', ','));
    }
}
