import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ProjectAuthorization} from '../models/project-authorization';
import {Injectable} from '@angular/core';
import {Project} from '../models/project';
import {GraphUserReference} from '../models/graph-user';

export interface ProjectAddAuthorizationRequestInterface {
    graphUser: GraphUserReference;
    executionGroupIds: number[];
    projectExecutionGroupIds: number[];
}

export interface ProjectAuthorizationsService {
    getAuthorizations(projectId: number): Observable<Array<ProjectAuthorization>>;
    addAuthorization(projectId: number, request: ProjectAddAuthorizationRequestInterface): Observable<Project>;
    removeAuthorization(projectId: number, request: {graphUser: GraphUserReference}): Observable<Project>;
}

@Injectable()
export class ProjectAuthorizationsServiceImpl implements ProjectAuthorizationsService {
    constructor(private httpClient: HttpClient) {
    }

    getAuthorizations(projectId: number): Observable<Array<ProjectAuthorization>> {
        return this.httpClient.get<Array<ProjectAuthorization>>(`/api/v1/projects/${projectId}/authorizations`);
    }

    addAuthorization(projectId: number, request: ProjectAddAuthorizationRequestInterface): Observable<Project> {
        return this.httpClient.post<Project>(`/api/v1/projects/${projectId}/authorizations`, request);
    }

    removeAuthorization(projectId: number, request: {graphUser: GraphUserReference}): Observable<Project> {
        return this.httpClient.post<Project>(`/api/v1/projects/${projectId}/authorizations/delete`, request);
    }
}
