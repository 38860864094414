<div class="vwui-card u-mb-l">
    <div class="vwui-card__header has-action">
        <div>{{ 'Voorbeeld formulier'|translate }}</div>
    </div>
    <div class="vwui-modal-content vwui-border-bottom-1">
        <div>{{ 'Titel'|translate }}: {{ formType?.title }}</div>
        <div>{{ 'Gewijzigd'|translate }}: {{ formType?.updatedAt|dateFormat }}</div>
        <div>{{ 'Contactpersoon'|translate }}: <small>{{ formType?.contactPerson }}</small></div>
        <div>
            {{ 'Objecttypes'|translate }}:
            <span *ngFor="let object of formType?.paulaObjectTypes"
                  class="badge badge-primary mr-1">{{ object.title }}</span>
        </div>
        <div>
            {{ 'Bedrijven'|translate }}:
            <span *ngFor="let company of formType?.companies"
                  class="badge badge-primary mr-1">{{ company.name }}</span>
        </div>
        <div>
            {{ 'Tags'|translate }}:
            <ng-container *ngFor="let tag of formType?.tags">
                <app-tag [className]="tag.className">{{tag.name}}</app-tag>
            </ng-container>
        </div>
    </div>
    <app-form-viewer [formItems]="formItems"></app-form-viewer>
</div>
