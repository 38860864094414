import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Inject, Injectable} from '@angular/core';
import {ProjectJobService} from '../services/project-job.service';
import {ProjectJob} from '../models/project-job';

@Injectable({
    providedIn: 'root'
})
export class ProjectJobResolver implements Resolve<ProjectJob> {

    constructor(
        @Inject('ProjectJobService') private projectJobService: ProjectJobService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.projectJobService.getDetail(+route.params.id);
    }
}
