// @ts-strict-ignore
import {Component} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {FileUploadEvent} from '../file-upload-btn/file-upload.interface';

@Component({
    selector: 'app-import-form-v2-modal',
    templateUrl: './import-form-v2-modal.component.html'
})
export class ImportFormV2ModalComponent {
    workbook: File = null;
    imageZip: File = null;
    import = false;

    constructor(private modalRef: BsModalRef) {}

    close(shouldImport: boolean) {
        this.import = shouldImport;
        this.modalRef.hide();
    }

    setWorkbook(event: FileUploadEvent) {
        this.workbook = event.target.files[0];
        event.complete();
    }

    setImageZip(event: FileUploadEvent) {
        this.imageZip = event.target.files[0];
        event.complete();
    }
}
