<app-modal modalTitle="{{'Apple contentcodes toevoegen'|translate}}" modalSaveBtnLabel="{{'Uploaden'|translate}}" [loadingSaveBtn]="true" [enableSaveBtn]="!!file" (cancel)="cancel()" (save)="startUpload($event)">
    <div class="vwui-form-group mb-4">
        <label for="upload" class="vwui-label">{{'Excel bestand'|translate}}</label>
        <app-file-upload-btn id="upload" [loaderEnabled]="false" (fileChange)="onFileChange($event)" accept=".xls">
            {{fileName}}
        </app-file-upload-btn>
    </div>
    <small>
        {{'Applicatiebeheerders ontvangen een waarschuwingsbericht per e-mail, zodra er minder dan X contentcodes over zijn.'|translate: {amount: notificationThreshold} }}
    </small>
</app-modal>
