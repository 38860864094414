// @ts-strict-ignore
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {Subscriptions} from '../../utils/subscriptions';
import {Forms} from '../../utils/forms';
import {Company} from '../../models/company';
import {CompanyService} from '../../services/company.service';
import {HistoryService} from "../../services/history.service";

@Component({
    selector: 'app-company-detail',
    templateUrl: './company-detail.component.html'
})
export class CompanyDetailComponent implements OnInit, OnDestroy {
    form: UntypedFormGroup;
    private subscriptions: Subscription[] = [];

    constructor(
        private route: ActivatedRoute,
        @Inject('CompanyService') private companyService: CompanyService,
        private toast: ToastrService,
        private router: Router,
        fb: UntypedFormBuilder,
        private historyService: HistoryService,
    ) {
        this.form = this.createForm(fb);
    }

    ngOnInit(): void {
        const routeDataSubscription = this.route.data.subscribe(data => data.company ? this.form.patchValue(data.company) : null);
        this.subscriptions.push(routeDataSubscription);
    }

    ngOnDestroy(): void {
        Subscriptions.unsubscribeAll(this.subscriptions);
    }

    async save() {
        const newCompany = this.form.value as Company;

        try {
            let result;

            if (newCompany.id) {
                result = await this.companyService.putCompany(newCompany).toPromise();
            } else {
                result = await this.companyService.postCompany(newCompany).toPromise();
            }
            this.toast.success('Opgeslagen');
            this.form.patchValue(result);

            this.goBack();
        } catch (ex) {
            console.error('Unable to save company', ex);
            this.toast.error('Opslaan mislukt');
        }
    }

    cancel() {
        this.goBack();
    }

    goBack() {
        this.historyService.goBack(['/beheer/companies']);
    }

    validateCompanyNotTaken(control: AbstractControl): Observable<ValidationErrors> {
        const currentId = +this.form.get('id').value;

        return Forms.validateNotTaken(control, currentId, this.companyService.exists(control.value));
    }

    private createForm(fb: UntypedFormBuilder): UntypedFormGroup {
        return fb.group({
            id: fb.control(null),
            name: fb.control(null, [Validators.required], [this.validateCompanyNotTaken.bind(this)]),
        });
    }

}
