<label class="vwui-label question-choices-label">
    {{'Keuzes'|translate}}
    <span *ngIf="choices.length > 0">Verplicht</span>
</label>
<div class="vwui-list">
    <div class="vwui-list-item-input" *ngFor="let choice of choices; let i = index">
        <div class="vwui-list-item-input__group">
            <input
                class="vwui-input"
                [name]="'choice-title['+i+']'"
                [value]="choice.title"
                (change)="updateChoice(i, $event)"
                autocomplete="off"
            >
            <div class="vwui-list-item-input__sort">
                <button type="button" class="vwui-list-item-input__sort-btn" [disabled]="i === 0" (click)="up(choice.position)">
                    <app-icon iconName="chevron-up"></app-icon>
                </button>
                <button type="button" class="vwui-list-item-input__sort-btn" [disabled]="(i+1) === choices.length" (click)="down(choice.position)">
                    <app-icon iconName="chevron-down"></app-icon>
                </button>
            </div>

            <div class="vwui-list-item__settings">
                <div class="vwui-list-item__settings-item" *ngIf="choice.textRemark">
                    <app-icon iconName="message"></app-icon>
                    <span class="vwui-list-item__settings-required text-danger" *ngIf="choice.textRemarkRequired">*</span>
                </div>
                <div  class="vwui-list-item__settings-item" *ngIf="choice.imageRemark">
                    <app-icon iconName="image-grey"></app-icon>
                    <span class="vwui-list-item__settings-required text-danger" *ngIf="choice.imageRemarkRequired">*</span>
                </div>
            </div>
        </div>

        <button type="button" class="vwui-list-item-input__action vwui-list-item-input__action-btn" (click)="toggleSettings(choice)">
            <span>
                <app-icon iconName="settings"></app-icon>
            </span>
        </button>

        <button (click)="remove(choice)" class="vwui-list-item-input__action vwui-list-item-input__action-btn vwui-list-item-input__action-btn--delete">
            <span>
                <app-icon iconName="trash-2"></app-icon>
            </span>
        </button>
    </div>
    <app-add-btn (add)="add()"></app-add-btn>
</div>
