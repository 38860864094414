// @ts-strict-ignore
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {LoadingBtnEvent} from '../loading-btn/loading-btn.interface';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html'
})
export class ModalComponent {
    @Input() modalTitle: string;
    @Input() showSaveBtn = true;
    @Input() showCloseBtn = true;
    @Input() modalSaveBtnLabel = 'Opslaan';
    @Input() modalCancelBtnLabel = 'Annuleren';
    @Input() loadingSaveBtn = false; // Submit button will be loading-btn if true
    @Input() enableSaveBtn = true;

    @Output() cancel = new EventEmitter<void>();
    @Output() save = new EventEmitter<void | LoadingBtnEvent>();

    onCancel() {
        this.cancel.emit();
    }

    onSave(event?: LoadingBtnEvent) {
        this.save.emit(event);
    }
}
