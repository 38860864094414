<app-modal modalTitle="Keuzevraag instellingen" (cancel)="onSave()" (save)="onSave()" modalSaveBtnLabel="{{ 'Gereed'|translate }}">
    <table class="vwui-table">
        <tr>
            <th>Vraag</th>
            <th>Verplicht</th>
        </tr>
        <tr>
            <td>
                <button type="button" class="vwui-list-item-input__action vwui-list-item-input__action-btn" [class.is-active]="textRemark" (click)="toggleText()">
                    <span tooltip="{{'Opmerking'|translate}}">
                        <app-icon iconName="message"></app-icon>
                    </span>
                </button>
            </td>
            <td>
                <app-checkbox
                    name="textRemarkRequired"
                    [disabled]="!textRemark"
                    [checked]="textRemarkRequired"
                    (valueChange)="toggleTextRequired($event)"
                ></app-checkbox>
            </td>
        </tr>
        <tr>
            <td>
                <button type="button" class="vwui-list-item-input__action vwui-list-item-input__action-btn" [class.is-active]="imageRemark" (click)="toggleImage()">
                    <span tooltip="{{'Foto'|translate}}">
                        <app-icon iconName="image-grey"></app-icon>
                    </span>
                </button>
            </td>
            <td>
                <app-checkbox
                    name="imageRequired"
                    [disabled]="!imageRemark"
                    [checked]="imageRemarkRequired"
                    (change)="toggleImageRequired($event)"
                ></app-checkbox>
            </td>
        </tr>
    </table>
</app-modal>
