import {TableFilter} from "../components/table/table.component";
import {Observable} from "rxjs";
import {PageResponse} from "../models/page-response";
import {SharepointCredential, SharepointCredentialListDTO} from "../models/sharepoint-credential";
import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Filters} from "../utils/filters";
import {PartialIdentiable} from "../models/identifiable";

export interface SharepointCredentialService {
    getSharepointCredentials(page: number, filter?: TableFilter): Observable<PageResponse<SharepointCredentialListDTO>>;
    getSharepointCredential(id: number): Observable<SharepointCredential>;
    putSharepointCredential(sharepointCredential: PartialIdentiable<SharepointCredential>): Observable<SharepointCredential>;
    postSharepointCredential(sharepointCredential: SharepointCredential): Observable<SharepointCredential>;
    exists(hostname: string): Observable<number | null>;
}

@Injectable()
export class SharepointCredentialServiceImpl implements SharepointCredentialService {
    constructor(private httpClient: HttpClient) {
    }

    getSharepointCredentials(page: number, filter?: TableFilter): Observable<PageResponse<SharepointCredentialListDTO>> {
        let params = new HttpParams().set('page', '' + page);

        if (filter) {
            params = Filters.applyFiltering(filter, params);
        }

        return this.httpClient.get<PageResponse<SharepointCredentialListDTO>>('/api/v1/sharepoint-credentials', {params});
    }

    getSharepointCredential(id: number): Observable<SharepointCredential> {
        return this.httpClient.get<SharepointCredential>(`/api/v1/sharepoint-credentials/${id}`);
    }

    putSharepointCredential(sharepointCredential: PartialIdentiable<SharepointCredential>): Observable<SharepointCredential> {
        return this.httpClient.put<SharepointCredential>(`/api/v1/sharepoint-credentials/${sharepointCredential.id}`, sharepointCredential);
    }

    postSharepointCredential(sharepointCredential: SharepointCredential): Observable<SharepointCredential> {
        return this.httpClient.post<SharepointCredential>(`/api/v1/sharepoint-credentials`, sharepointCredential);
    }

    exists(hostname: string): Observable<number | null> {
        const params = new HttpParams().set('hostname', hostname);

        return this.httpClient.get<number>(`/api/v1/sharepoint-credentials/exists`, {params});
    }
}
