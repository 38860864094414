// @ts-strict-ignore
import {Component, forwardRef, Inject} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {UploadService} from '../../services/upload.service';
import {NgxFileDropEntry} from 'ngx-file-drop';
import {UploadedFile} from '../../models/uploaded-file';
import {PendingUpload} from '../../models/pending-upload';
import {isFileSystemFileEntry} from '../../utils/ngx-file-drop-utils';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {v4 as uuid} from 'uuid';
import {HttpEventType} from '@angular/common/http';

@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FileUploadComponent),
            multi: true
        }
    ]
})
export class FileUploadComponent implements ControlValueAccessor {
    readonly maxFileCount = 10;
    readonly maxFileSize = 10 * 1024 * 1024;

    pendingFiles: PendingUpload[] = [];
    files: UploadedFile[] = [];
    disabled = false;

    private onChange: (value: UploadedFile[]) => void;
    private onTouched: () => void;

    constructor(
        @Inject('UploadService') private uploadService: UploadService,
        private toast: ToastrService,
        private translateService: TranslateService
    ) {
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(obj: any): void {
        this.files = obj;
    }

    getFileCount(): number {
        return this.files.length + this.pendingFiles.length;
    }

    async onDropped(dropEntries: NgxFileDropEntry[]) {
        dropEntries.forEach(({fileEntry}) => {
            if (isFileSystemFileEntry(fileEntry)) {
                fileEntry.file(file => {
                    if (this.getFileCount() >= this.maxFileCount) {
                        this.errorToast('file-upload.max-file-count-exceeded', {
                            fileName: fileEntry.name,
                            maxFileCount: this.maxFileCount
                        });
                        return;
                    }

                    if (file.size > this.maxFileSize) {
                        this.errorToast('file-upload.max-file-size-exceeded', {
                            fileName: fileEntry.name,
                            maxFileSize: `${this.maxFileSize / 1024 / 1024}MB`
                        });
                        return;
                    }

                    this.uploadFile(file);
                });
            }
        });
    }

    onFileClicked(file: UploadedFile) {
        window.open(file.src, '_blank');
    }


    removeFile(event: Event, file: UploadedFile) {
        event.stopPropagation();
        const index = this.files.indexOf(file);
        if (index !== -1) {
            this.files.splice(index, 1);
        }
        this.filesUpdated();
    }

    private filesUpdated() {
        if (this.onChange) {
            this.onChange(this.files);
        }
        if (this.onTouched) {
            this.onTouched();
        }
    }

    private uploadFile(file: File) {
        const pendingUpload: PendingUpload = {file, progress: 0};
        this.pendingFiles.push(pendingUpload);
        this.uploadService.uploadFile(uuid(), file).subscribe({
            next: event => {
                switch (event.type) {
                    case HttpEventType.UploadProgress:
                        pendingUpload.progress = Math.round(event.loaded / event.total * 100);
                        break;
                    case HttpEventType.Response:
                        this.files.push(event.body);
                        this.filesUpdated();
                        break;
                }
            },
            complete: () => {
                // Remove upload from pendingFiles
                const uploadIndex = this.pendingFiles.indexOf(pendingUpload);
                if (uploadIndex !== -1) {
                    this.pendingFiles.splice(uploadIndex, 1);
                }
            }
        });
    }

    private errorToast(message: string, interpolateParams?: Object) {
        this.toast.error(
            this.translateService.instant(message, interpolateParams)
        );
    }
}
