import {Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {FeatureToggleCheckService} from '../feature-toggle-check.service';
import {FeatureToggle, FeatureToggleData} from '../../models/feature-toggle';

@Injectable()
export class FeatureToggleCheckServiceMock extends FeatureToggleCheckService {

    featureToggles(): Observable<Array<FeatureToggleData>> {
        return of([
            {featureToggleName: 'test' as FeatureToggle, enabled: true},
            {featureToggleName: FeatureToggle.Maximo, enabled: true},
            {featureToggleName: FeatureToggle.Vise, enabled: true},
            {featureToggleName: FeatureToggle.AppleContentCodes, enabled: true},
            {featureToggleName: FeatureToggle.PrivacyEnabled, enabled: true},
            {featureToggleName: FeatureToggle.CustomersEnabled, enabled: true},
            {featureToggleName: FeatureToggle.AddToSummaryEnabled, enabled: true},
            {featureToggleName: FeatureToggle.ProjectJobShowRejectReason, enabled: true},
            {featureToggleName: FeatureToggle.projectJobAnswerMetaEnabled, enabled: true},
            {featureToggleName: FeatureToggle.SharepointJobExport, enabled: true},
            {featureToggleName: FeatureToggle.FormTypeImportV2, enabled: true},
        ]);
    }
}
