// @ts-strict-ignore
import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {FormTypeService} from '../../services/form-type.service';
import {FormType, FormTypes, formTypeStatuses} from '../../models/form-type';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from '../../services/authentication.service';
import {Subscription} from "rxjs";
import {switchMap} from "rxjs/operators";
import {HistoryService} from "../../services/history.service";

@Component({
    selector: 'app-form-type-copy',
    templateUrl: './form-type-copy.component.html'
})
export class FormTypeCopyComponent implements OnInit, OnDestroy {
    formType: FormType;
    form: UntypedFormGroup;
    formTypeStatuses = formTypeStatuses;
    formTitle = this.translate.instant('Kopieer formulier');
    showFormNumberHelp = false;
    saving = false;

    @Input() formTypeId: number;

    private loggedInEmail$ = this.authenticationService.loggedInEmail$;

    private subscriptions: Subscription[] = [];

    constructor(
        @Inject('FormTypeService') private formTypeService: FormTypeService,
        private activatedRoute: ActivatedRoute,
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private toast: ToastrService,
        private translate: TranslateService,
        private authenticationService: AuthenticationService,
        private historyService: HistoryService
    ) {
        this.form = this.formBuilder.group({
            id: this.formBuilder.control(null),
            title: this.formBuilder.control(null, [
                Validators.required, Validators.maxLength(320)]),
            code: new UntypedFormControl('', [Validators.required, Validators.maxLength(255)]),
            status: this.formBuilder.control('Open', [Validators.required]),
            paulaObjectTypes: this.formBuilder.control([], [Validators.required]),
            companies: this.formBuilder.control([]),
            tags: this.formBuilder.control([]),
            contactPerson: this.formBuilder.control(this.loggedInEmail$.value),
            type: this.formBuilder.control(FormTypes.FormType),
            askLocation: this.formBuilder.control(false),
            showLocationOnMap: this.formBuilder.control(false),
            revisionNumber: this.formBuilder.control(null),
        });
    }

    ngOnInit() {
        this.formTypeService.getDetail(this.activatedRoute.snapshot.params.id).subscribe(
            data => (this.form.patchValue(data))
        );

        this.subscriptions.push(
            this.form.get('code').valueChanges.pipe(
                switchMap(code => this.formTypeService.getByCode(code))
            ).subscribe((response: FormType[]) => {
                this.showFormNumberHelp = response.length > 0;
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach(it => it.unsubscribe());
    }

    goBack() {
        this.historyService.goBack(['beheer', 'form-types'])
    }

    cancel() {
        this.goBack();
    }

    async save() {
        if (!this.form.valid) {
            this.toast.error(
                this.translate.instant('Formulier is niet volledig'),
                this.translate.instant('Fout')
            );
            return;
        }

        this.saving = true;

        try {
            await this.formTypeService.postFormTypeCopy(this.form.value).toPromise();
            this.toast.success(
                this.translate.instant('Formulier gekopieerd'),
                this.translate.instant('Succes')
            );

            setTimeout(() => {
                this.saving = false;
                return this.router.navigate(['/beheer/form-types']);
            }, 2000);
        } catch (error) {
            console.error(error);
            this.toast.error(
                this.translate.instant('Opslaan mislukt'),
                this.translate.instant('Fout')
            );
            this.saving = false;
        }
    }
}
