// @ts-strict-ignore
import {Component, forwardRef, Inject, Input} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {EntitySelectComponent} from './item-select.component';
import {PageResponse} from '../../models/page-response';
import {ProjectExecutionGroupService} from '../../services/project-execution-group.service';
import {ProjectExecutionGroup} from '../../models/project-execution-group';

@Component({
    selector: 'app-project-execution-group-select',
    templateUrl: './item-select.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ProjectExecutionGroupSelectComponent),
            multi: true
        },
    ]
})
export class ProjectExecutionGroupSelectComponent extends EntitySelectComponent<ProjectExecutionGroup, {}> {
    @Input() projectId: number

    constructor(
        @Inject('ProjectExecutionGroupService') private executionGroupService: ProjectExecutionGroupService,
    ) {
        super();

        this.placeholder = 'Selecteer één of meerdere projectdisciplines';
        this.multiple = true;
    }

    loadMore(page: number, filter: {term: string}): Observable<PageResponse<ProjectExecutionGroup>> {
        return this.executionGroupService.getProjectExecutionGroups(this.projectId, page, {
            filter: {
                title: filter && filter.term ? filter.term : ''
            }
        });
    }

    getLabelText(item: ProjectExecutionGroup) {
        return item.title;
    }

    getFilter(): Observable<{}> {
        return of({});
    }
}
