// @ts-strict-ignore
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Project} from '../../models/project';
import {Subscription} from 'rxjs';
import {Subscriptions} from '../../utils/subscriptions';
import {ActivatedRoute, Router} from '@angular/router';
import {LoadingBtnEvent} from '../../components/loading-btn/loading-btn.interface';
import {ProjectService} from '../../services/project.service';
import {FileUtil} from '../../utils/file';

type ProjectDetailTab = 'detail'|'coordinators'|'users'|'forms'|'objects'|'mapLayers'|'export'|'execution-groups';

@Component({
    selector: 'app-project-detail',
    templateUrl: './project-detail.component.html'
})
export class ProjectDetailComponent implements OnInit, OnDestroy {
    project: Project;
    currentTab: ProjectDetailTab = this.route.snapshot.queryParamMap.get('tab') as ProjectDetailTab || 'detail';

    private subscriptions: Subscription[] = [];

    constructor(
        @Inject('ProjectService') private projectService: ProjectService,
        private route: ActivatedRoute,
        private router: Router
    ) {}

    ngOnInit(): void {
        const routeDataSubscription = this.route.data.subscribe((data) => {
            if (data.project) {
                this.project = data.project;
            }
        });

        this.subscriptions.push(routeDataSubscription);
    }

    ngOnDestroy(): void {
        Subscriptions.unsubscribeAll(this.subscriptions);
    }

    setCurrentTab(tab: ProjectDetailTab): void {
        this.currentTab = tab;
        this.router.navigate([], {relativeTo: this.route, queryParams: {tab}, replaceUrl: true});
    }

    get projectTitle() {
        return this.project ? this.project.name : 'Nieuw project';
    }

    onProjectSave(project: Project) {
        this.project = {...project};
    }

    openMap() {
        this.router.navigate(['beheer', 'projects', this.project.id, 'map']);
    }

    async exportProject(event: LoadingBtnEvent) {
        try {
            const {filename, data} = await this.projectService.exportProject(this.project.id).toPromise();
            FileUtil.downloadBlobAsFile(data, filename);
        } finally {
            event.complete();
        }
    }
}
