import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'arrayPropertyValues',
})
export class ArrayPropertyValuesPipe implements PipeTransform {
    transform(value: any[], key: string, seperator: string = ', ') {
        if (!value) {
            return '';
        }

        return value.map(it => it[key]).join(seperator);
    }
}
