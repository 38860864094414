import {TableFilter} from "../components/table/table.component";
import {Observable} from "rxjs";
import {PageResponse} from "../models/page-response";
import {PaulaObstructionCode} from "../models/paula-obstruction-code";
import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Filters} from "../utils/filters";

export interface ObstructionCodeService {
    getObstructionCodes(page: number, filter?: TableFilter): Observable<PageResponse<PaulaObstructionCode>>;
    getObstructionCode(id: number): Observable<PaulaObstructionCode>;
    putObstructionCode(obstructionCode: PaulaObstructionCode): Observable<PaulaObstructionCode>;
    postObstructionCode(obstructionCode: PaulaObstructionCode): Observable<PaulaObstructionCode>;
    exists(code: string): Observable<number>;
}

@Injectable()
export class ObstructionCodeServiceImpl implements ObstructionCodeService {
    constructor(private httpClient: HttpClient) {
    }

    getObstructionCodes(page: number, filter?: TableFilter): Observable<PageResponse<PaulaObstructionCode>> {
        let params = new HttpParams().set('page', '' + page);

        if (filter) {
            params = Filters.applyFiltering(filter, params);
        }

        return this.httpClient.get<PageResponse<PaulaObstructionCode>>('/api/v1/obstruction-codes', {params});
    }

    getObstructionCode(id: number): Observable<PaulaObstructionCode> {
        return this.httpClient.get<PaulaObstructionCode>(`/api/v1/obstruction-codes/${id}`);
    }

    putObstructionCode(obstructionCode: PaulaObstructionCode): Observable<PaulaObstructionCode> {
        return this.httpClient.put<PaulaObstructionCode>(`/api/v1/obstruction-codes/${obstructionCode.id}`, obstructionCode);
    }

    postObstructionCode(obstructionCode: PaulaObstructionCode): Observable<PaulaObstructionCode> {
        return this.httpClient.post<PaulaObstructionCode>(`/api/v1/obstruction-codes`, obstructionCode);
    }

    exists(code: string): Observable<number> {
        const params = new HttpParams().set('code', code);

        return this.httpClient.get<number>(`/api/v1/obstruction-codes/exists`, {params});
    }
}
