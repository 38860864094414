<div class="vwui-form-group u-mb-m">
    <label class="vwui-label">{{'Vervolgopdracht'|translate}}</label>
    <div>
        {{'Koppel een vervolgopdracht aan een keuze'|translate}}
    </div>
</div>
<ng-container *ngFor="let choice of _choicesControl.value">
    <div class="d-flex flex-column vwui-card p-3 mb-4" *ngIf="choice.followUpProjectJobTemplate">
        <div class="d-flex justify-content-between">
            <h6>{{'Keuze'|translate}}: {{ choice.title }}</h6>
            <div class="mb-1 vwui-btn-grp">
                <button class="vwui-btn-no-bg" (click)="updateFollowUpProjectJobForChoice(choice, $event)">
                    <app-icon iconName="edit-2"></app-icon>
                </button>
                <button id="delete" class="vwui-btn-no-bg" (click)="deleteFollowUpProjectJobForChoice(choice.id)">
                    <app-icon iconName="trash-2"></app-icon>
                </button>
            </div>
        </div>
        <ng-container *ngIf="choice.followUpProjectJobTemplate; let template">
            <span>{{ 'Opdrachtnaam'|translate}}: {{ template.title }}</span>
            <span>{{ 'Formulier'|translate}}: {{ template.projectForm.title }}</span>
            <span *ngIf="template.recoveryTime; let recoveryTime">
                {{ 'Hersteltermijn'|translate}}: {{ $any(recoveryTimes)[recoveryTime] }}
            </span>
            <span *appHasFeatureToggle="maximoToggle">{{'Actienemer'|translate}}: {{ template.actionTaker }}</span>
        </ng-container>
    </div>
</ng-container>
<div class="d-flex">
    <ng-select
        [formControl]="followUpChoiceControl"
        bindLabel="title"
        bindValue="id"
        id="select_a_option"
        placeholder="{{ 'Kies een keuze'|translate }}"
        class="flex-grow-1"
        [items]="choicesWithoutFollowUpProjectJobTemplate"
        [clearable]="false">
    </ng-select>
    <button class="vwui-btn ml-3"
            type="button" [disabled]="!followUpChoiceControl.value" (click)="configureFollowUpProjectJobForChoice(followUpChoiceControl.value)">
        {{'Koppelen'|translate}}
    </button>
</div>

