import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {TableFilter} from '../components/table/table.component';
import {Filters} from '../utils/filters';
import {PageResponse} from '../models/page-response';
import {InterfaceProcessingError, InterfaceProcessingErrorDetail} from '../models/interface-processing-error';
import {Observable} from 'rxjs';

export interface InterfaceProcessingErrorService {
    getInterfaceProcessingErrors(page: number, filter?: TableFilter): Observable<PageResponse<InterfaceProcessingError>>;
    resolve(id: number): Observable<any>;
    deleteInterfaceProcessingError(id: number): Observable<any>;
    retryInterfaceProcessingError(id: number): Observable<any>;
    getInterfaceProcessingError(number: number): Observable<InterfaceProcessingErrorDetail>;
}

@Injectable()
export class InterfaceProcessingErrorServiceImpl implements InterfaceProcessingErrorService {

    constructor(private httpClient: HttpClient) {
    }

    getInterfaceProcessingErrors(page: number, filter?: TableFilter) {
        let params = new HttpParams().set('page', '' + page);

        if (filter) {
            params = Filters.applyFiltering(filter, params);
        }

        return this.httpClient.get<PageResponse<InterfaceProcessingError>>('/api/v1/interface-processing-errors', {params});
    }

    getInterfaceProcessingError(id: number): Observable<InterfaceProcessingErrorDetail> {
        return this.httpClient.get<InterfaceProcessingErrorDetail>(`/api/v1/interface-processing-errors/${id}`);
    }

    resolve(id: number) {
        return this.httpClient.post(`/api/v1/interface-processing-errors/${id}/resolve`, {});
    }

    deleteInterfaceProcessingError(id: number) {
        return this.httpClient.post(`/api/v1/interface-processing-errors/${id}/delete`, {});
    }

    retryInterfaceProcessingError(id: number) {
        return this.httpClient.post(`/api/v1/interface-processing-errors/${id}/retry`, {});
    }
}
