// @ts-strict-ignore
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscriptions} from '../../utils/subscriptions';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ProjectJob, ProjectJobStatus} from '../../models/project-job';

type ProjectJobDetailTab = 'detail' | 'answers';

@Component({
    selector: 'app-project-job-detail',
    templateUrl: './project-job-detail.component.html'
})
export class ProjectJobDetailComponent implements OnInit, OnDestroy {
    projectJob: ProjectJob;
    currentTab: ProjectJobDetailTab = this.route.snapshot.queryParamMap.get('tab') as ProjectJobDetailTab || 'detail';
    preselectedImageUuid?: string = this.route.snapshot.queryParamMap.get('imageuuid');

    private subscriptions: Subscription[] = [];

    constructor(
        private route: ActivatedRoute,
        private router: Router
    ) {
    }

    ngOnInit(): void {
        const routeDataSubscription = this.route.data.subscribe((data) => {
            if (data.projectJob) {
                this.projectJob = data.projectJob;
            }
        });

        this.subscriptions.push(routeDataSubscription);
    }

    ngOnDestroy(): void {
        Subscriptions.unsubscribeAll(this.subscriptions);
    }

    setCurrentTab(tab: ProjectJobDetailTab): void {
        this.currentTab = tab;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {tab},
            queryParamsHandling: "merge",
            replaceUrl: true
        });
    }

    onProjectJobSave(projectJob: ProjectJob) {
        this.projectJob = projectJob;
    }
}
