// @ts-strict-ignore
// @ts-ignore

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    Output
} from '@angular/core';
import {
    AbstractControl,
    FormArray,
    FormControl,
    FormGroup,
    UntypedFormControl,
    UntypedFormGroup,
    ValidatorFn,
    Validators
} from '@angular/forms';
import {
    ChapterFormItem,
    Choice,
    FormItem,
    QuestionListType,
    QuestionType,
    questionTypeTitles,
    SelectedFormItem
} from '../../models/form-item';
import {firstValueFrom, Subscription} from 'rxjs';
import {AsideService} from '../../vwui/aside/aside.service';
import {Forms} from '../../utils/forms';
import {BsModalService} from 'ngx-bootstrap/modal';
import {ProjectJob} from '../../models/project-job';
import {ToastrService} from 'ngx-toastr';
import {ImageAnnotationModalComponent} from '../image-annotation-modal/image-annotation-modal.component';
import {v4 as uuid} from 'uuid';
import {FileUtil} from '../../utils/file';
import {FeatureToggle} from '../../models/feature-toggle';
import {AbstractForm, FormType, FormTypes} from '../../models/form-type';
import {CKEditor5} from '@ckeditor/ckeditor5-angular/ckeditor';
import {UploadService} from '../../services/upload.service';
import {NumberQuestionFollowUp} from '../../models/number-question-follow-up';
import {BuiltinFormulas, FormulaConfig} from "../../models/formula-config";
import {getEmptyTableColumn, TableColumn, TableColumnFormGroup} from "../../models/table-column";
import {NgSelectComponent} from "@ng-select/ng-select";
import {tableColumnKeyUniqueValidator} from "../../utils/table-column-key-unique-validator";

@Component({
    selector: 'app-question-edit',
    templateUrl: './question-edit.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuestionEditComponent implements OnChanges, OnDestroy {
    @Input() selectedFormItem: SelectedFormItem | null = null;
    @Input() formType: AbstractForm | null = null;

    /**
     * ProjectJob used for creating follow-up ProjectJobs with choice-questions.
     */
    @Input() followUpProjectJob?: ProjectJob;
    @Input() followUpForm?: FormType;

    @Output() cancel = new EventEmitter<void>();
    @Output() save = new EventEmitter<FormItem>();

    // @ts-ignore
    EditorPromise = import('@ckeditor/ckeditor5-build-classic').then(result => result.default);

    editorConfig: CKEditor5.Config = {
        toolbar: ['link'],
        allowedContent: 'a[!href][!target];',
        link: {
            defaultProtocol: 'https://',
            decorators: {
                addTargetToExternalLinks: {
                    mode: 'automatic',
                    callback: (_: unknown) => true,
                    attributes: {target: '_blank', rel: 'noopener noreferrer'}
                }
            }
        }
    };

    privacyEnabledToggle = FeatureToggle.PrivacyEnabled;
    addToSummaryEnabledToggle = FeatureToggle.AddToSummaryEnabled;

    formItem: FormItem | null = null;
    formChapter: ChapterFormItem | null = null;
    formChapterItems: Array<FormItem> = [];

    form: FormGroup<{
        id: FormControl<number | null>;
        position: FormControl<number | null>;
        title: FormControl<string | null>;
        descriptionType: FormControl<'text' | 'image' | null>;
        description: FormControl<string | null>;
        imageDescription: FormControl<string | null>;
        helpText: FormControl<string | null>;
        type: FormControl<QuestionType | null>;
        required: FormControl<boolean | null>;
        privacyEnabled: FormControl<boolean | null>;
        addToSummaryEnabled: FormControl<boolean | null>;
        showSummaryEnabled: FormControl<boolean | null>;
        reference: FormControl<string | null>;

        preFillEnabled?: FormControl<boolean>;
        preFillValue?: FormControl<string | null>;

        toleranceEnabled?: FormControl<boolean>;
        toleranceValue?: FormControl<string | null>;

        multiline?: FormControl<boolean>;

        multiple?: FormControl<boolean>;
        choices?: FormControl<Choice[]>;

        totalDecimal?: FormControl<number | null>;
        followUp?: FormControl<NumberQuestionFollowUp[]>;

        time?: FormControl<boolean>;

        multipoint?: FormControl<boolean>;

        questionDependency?: FormControl<Choice[]>;

        questionCount?: FormControl<number | null>;
        questionLabel?: FormControl<string | null>;
        averageTolerance?: FormControl<string | null>;

        formulaFields?: FormControl<FormulaConfig | null>;

        referenceImages?: FormControl<string>;

        pivot?: FormControl<boolean>;
        columns?: FormArray<TableColumnFormGroup>;
    }> | null = null;

    questionType: QuestionType | null = null;
    tab = 'general';

    averageToleranceEnabledControl = new UntypedFormControl(false);

    formulaConfigs: FormulaConfig[] = BuiltinFormulas;

    questionTypes: QuestionListType[] = [
        {
            type: QuestionType.text,
            label: questionTypeTitles[QuestionType.text]
        },
        {
            type: QuestionType.number,
            label: questionTypeTitles[QuestionType.number]
        },
        {
            type: QuestionType.choice,
            label: questionTypeTitles[QuestionType.choice]
        },
        {
            type: QuestionType.date,
            label: questionTypeTitles[QuestionType.date]
        },
        {
            type: QuestionType.signature,
            label: questionTypeTitles[QuestionType.signature]
        },
        {
            type: QuestionType.photo,
            label: questionTypeTitles[QuestionType.photo]
        },
        {
            type: QuestionType.object,
            label: questionTypeTitles[QuestionType.object]
        },
        // {
        //     type: QuestionType.list,
        //     label: questionTypeTitles[QuestionType.list]
        // },
        {
            type: QuestionType.table,
            label: questionTypeTitles[QuestionType.table]
        },
        {
            type: QuestionType.formula,
            label: questionTypeTitles[QuestionType.formula]
        },
        {
            type: QuestionType.location,
            label: questionTypeTitles[QuestionType.location]
        },
        {
            type: QuestionType.referenceImage,
            label: questionTypeTitles[QuestionType.referenceImage]
        },
        // {
        //     type: QuestionType.tabular,
        //     label: questionTypeTitles[QuestionType.tabular]
        // }
    ];

    columnTypes: { type: TableColumn["type"], label: string }[] = [
        {
            type: QuestionType.text,
            label: questionTypeTitles[QuestionType.text]
        },
        {
            type: QuestionType.number,
            label: questionTypeTitles[QuestionType.number]
        }
    ];

    private formSubscriptions: Subscription[] = [];
    private subscriptions: Subscription[] = [];

    constructor(
        @Inject('UploadService') private uploadService: UploadService,
        private modalService: BsModalService,
        private asideService: AsideService,
        private toast: ToastrService,
        private changeDetectorRef: ChangeDetectorRef,
    ) {
        this.subscriptions.push(this.asideService.asideOpen.subscribe(asideOpen => {
            if (!asideOpen) {
                this.resetFormItem();
            } else {
                this.initFormItem();
            }
        }));
    }

    ngOnDestroy(): void {
        this.formSubscriptions.forEach(subscription => subscription.unsubscribe());
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    ngOnChanges() {
        this.initFormItem();
    }

    get isChapter() {
        return this.selectedFormItem && this.selectedFormItem.type === 'chapter';
    }

    get isQuestion() {
        return this.selectedFormItem && this.selectedFormItem.type === 'question';
    }

    private initFormItem() {
        if (this.selectedFormItem) {
            if (this.isChapter) {
                if (this.selectedFormItem.formItem) {
                    this.formItem = this.selectedFormItem.formItem;

                    this.createForm(this.formItem.type, this.formItem);
                } else {
                    this.questionType = QuestionType.chapter;
                    this.createForm(this.questionType);
                }
            } else if (this.selectedFormItem.previousFormItem) {
                this.formChapter = this.selectedFormItem.previousFormItem as ChapterFormItem;
                this.formChapterItems = this.formChapter.children || [];

                if (this.selectedFormItem.formItem) {
                    this.formItem = this.selectedFormItem.formItem;

                    this.createForm(this.formItem.type, this.formItem);
                } else {
                    this.questionType = null;
                    this.createForm(this.questionType);
                    this.switchTab('general');
                }
            }
        }
    }

    private resetFormItem() {
        this.selectedFormItem = null;
        this.formItem = null;
        this.formChapterItems = [];
        this.formChapter = null;
        this.questionType = null;
    }

    onTypeChange(type: QuestionType | null) {
        if (type !== this.questionType) {
            this.formSubscriptions.forEach(subscription => subscription.unsubscribe());

            this.questionType = type;
            this.createForm(type);
        }
    }

    onSave() {
        if (this.form === null) {
            console.error('onSaved failed, this.form is not set')
            return;
        }
        if (this.form.invalid) {
            Forms.updateValueAndValidityRecursive(this.form);
            return false;
        }

        this.save.emit(this.form.getRawValue() as FormItem);
    }

    onCancel() {
        this.questionType = null;
        this.cancel.emit();
    }

    switchTab(tab: string) {
        this.tab = tab;
    }

    showImagePreview(imageSrc: string | null) {
        this.modalService.show(ImageAnnotationModalComponent, {
            ignoreBackdropClick: true,
            class: 'modal-xl bg-dark',
            initialState: {
                imageSrc,
                saveCallback: (async data => {
                    try {
                        const file = await firstValueFrom(this.uploadService.uploadImage(uuid(), FileUtil.dataURItoBlob(data)));
                        this.form?.controls?.imageDescription?.setValue(file.id);
                        this.changeDetectorRef.markForCheck();
                    } catch (e) {
                        this.toast.error('Afbeelding opslaan mislukt.');
                        console.error(e);
                    }
                })
            } as Partial<ImageAnnotationModalComponent>
        });
    }

    getToleranceMinValue(value: string) {
        return (value && value.split('^')[0]) || '';
    }

    getToleranceMaxValue(value: string) {
        return (value && value.split('^')[1]) || '';
    }

    isFormType() {
        return this.followUpForm && this.followUpForm.type === FormTypes.FormType;
    }

    get previousQuestions(): Array<FormItem> {
        if (this.selectedFormItem?.possibleDependencies && this.selectedFormItem.possibleDependencies.length > 0) {
            return this.selectedFormItem?.possibleDependencies;
        }

        if (!this.formItem) {
            return this.formChapterItems.filter(item => item.type === QuestionType.choice);
        }

        const questionIndex = this.formChapterItems.findIndex((item) => item === this.formItem);
        return this.formChapterItems.filter((item, index) => index < questionIndex && item.type === QuestionType.choice);
    }

    private createForm(type: QuestionType | null, question: FormItem | null = null) {
        const id = this.formItem ? this.formItem.id : null;

        this.form = new UntypedFormGroup({
            id: new UntypedFormControl(id),
            position: new UntypedFormControl(null),
            title: new UntypedFormControl(null, [Validators.required]),
            descriptionType: new UntypedFormControl('text'),
            description: new UntypedFormControl(null, Validators.maxLength(511)),
            imageDescription: new UntypedFormControl(null),
            helpText: new UntypedFormControl(null, Validators.maxLength(511)),
            type: new UntypedFormControl({value: type, disabled: !!id}, [Validators.required]),
            required: new UntypedFormControl(false),
            privacyEnabled: new UntypedFormControl(false),
            addToSummaryEnabled: new UntypedFormControl(false),
            showSummaryEnabled: new UntypedFormControl(false),
            showPhotosBelowEachOtherInExport: new UntypedFormControl(false),
            reference: new UntypedFormControl(uuid(), control => {
                return this.formType?.formItems?.some(item =>
                        item.id !== this.formItem?.id
                        && (item.reference !== null && item.reference === control.value
                            || (item.type === 'questionSet' && item.questionSet.formItems.some(questionSetItem =>
                                    questionSetItem.reference !== null
                                    && questionSetItem.reference === control.value
                                )
                            )
                        )
                ) ? {notUnique: true} : null;
            })
        });

        this.formSubscriptions.push(this.form.controls.type.valueChanges.subscribe(
            questionType => this.onTypeChange(questionType)
        ));
        this.formSubscriptions.push(this.form.controls.reference.valueChanges.subscribe(value => {
            if (value === '') {
                this.form!!.controls.reference.setValue(null);
            }
        }));

        if (QuestionType.text == type || QuestionType.number == type || QuestionType.choice == type) {
            this.form.addControl('preFillEnabled', new UntypedFormControl(false));
            this.form.addControl('preFillValue', new UntypedFormControl(''));

            this.formSubscriptions.push(
                this.form.controls.preFillEnabled!!.valueChanges.subscribe((enabled: boolean) => {
                    const control: AbstractControl = this.form!!.controls.preFillValue!!;
                    const validators: ValidatorFn[] = [Validators.required];

                    Forms.toggleValidation(control, validators, enabled);
                })
            );
        }

        if (QuestionType.number === type || QuestionType.choice === type || QuestionType.list === type || QuestionType.table === type) {
            this.form.addControl('toleranceEnabled', new UntypedFormControl(false));
            this.form.addControl('toleranceValue', new UntypedFormControl(''));

            this.formSubscriptions.push(
                this.form.controls.toleranceEnabled!!.valueChanges.subscribe((enabled: boolean) => {
                    const control: AbstractControl = this.form!!.controls.toleranceValue!!;
                    const validators: ValidatorFn[] = [Validators.required];

                    Forms.toggleValidation(control, validators, enabled);
                })
            );
        }

        if (QuestionType.text === type) {
            this.form.addControl('multiline', new UntypedFormControl(false));
        }

        if (QuestionType.choice === type) {
            this.form.addControl('multiple', new UntypedFormControl(false));
            this.form.addControl('choices', new UntypedFormControl([]));
        }

        if (QuestionType.number === type) {
            this.form.addControl('totalDecimal', new UntypedFormControl(null, [Validators.min(0), Validators.max(12)]));
            this.form.addControl('followUp', new FormControl<NumberQuestionFollowUp[]>([], {
                nonNullable: true
            }))
        }

        if (QuestionType.date === type) {
            this.form.addControl('time', new UntypedFormControl(false));
        }

        if (QuestionType.location == type) {
            this.form.addControl('multipoint', new FormControl<boolean>(false, {
                nonNullable: true
            }));
        }

        if (this.selectedFormItem?.possibleDependencies &&
            this.selectedFormItem?.possibleDependencies.length > 0) {
            this.form.addControl('questionDependency', new UntypedFormControl([]));
        }

        if (QuestionType.list === type || QuestionType.table === type) {
            this.form.addControl('questionCount', new UntypedFormControl(null, [Validators.required, Validators.min(1), Validators.max(20)]));
            this.form.addControl('questionLabel', new UntypedFormControl(null, [Validators.required]));
            this.form.addControl('averageTolerance', new UntypedFormControl(null));

            this.formSubscriptions.push(
                this.averageToleranceEnabledControl.valueChanges.subscribe((enabled: boolean) => {
                    const control: AbstractControl = this.form!!.controls.averageTolerance!!;
                    const validators: ValidatorFn[] = [Validators.required];

                    Forms.toggleValidation(control, validators, enabled);
                })
            );
        }

        if (QuestionType.formula === type) {
            this.form.addControl('formulaFields', new FormControl<FormulaConfig | null>(null, [Validators.required]));
        }

        if (QuestionType.referenceImage === type) {
            this.form.addControl('referenceImages', new FormControl('', {
                nonNullable: true,
                validators: [Validators.required]
            }));
        }

        if (QuestionType.tabular === type) {
            this.form.addControl('pivot', new FormControl(false, {
                nonNullable: true
            }))

            const columnFormArray = new FormArray<TableColumnFormGroup>([])
            if (question !== null && question.type === QuestionType.tabular) {

                question.columns.forEach(column =>
                    columnFormArray.push(this.makeColumnFormGroup(columnFormArray, column))
                )
            }

            this.form.addControl('columns', columnFormArray)
        }

        this.setFormValue(question);
    }

    private setFormValue(question: FormItem | null) {
        if (question) {
            this.questionType = question.type;

            if (!question.descriptionType) {
                question.descriptionType = 'text';
            }

            if (question.type === QuestionType.list || question.type === QuestionType.table) {
                this.averageToleranceEnabledControl.setValue(question.averageTolerance != null);
            }

            this.form!!.patchValue(question);
        }
    }

    addColumn<T extends TableColumn['type']>(select: NgSelectComponent, columnsControl: FormArray<TableColumnFormGroup>, type: T) {
        select.writeValue(null);
        columnsControl.push(this.makeColumnFormGroup(columnsControl, getEmptyTableColumn(type)));
    }

    private makeColumnFormGroup(
        columnsControl: FormArray<TableColumnFormGroup>,
        column: TableColumn
    ) {
        return new FormGroup({
            type: new FormControl(column.type, {nonNullable: true}),
            key: new FormControl(column.key, {nonNullable: true, validators: [
                Validators.required,
                Validators.pattern('[a-z0-9-]+'),
                tableColumnKeyUniqueValidator(columnsControl)
            ]}),
            label: new FormControl(column.label, {nonNullable: true, validators: Validators.required}),
            config: new FormControl(column.config, {nonNullable: true})
        })
    }
}
