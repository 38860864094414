<app-toolbar
    [showAddBtn]="true"
    addBtnLabel="{{'Nieuwe opdracht'|translate}}"
    (add)="openAddJobModal()">
    <app-file-upload-btn (fileChange)="onFileChange($event)">{{'Opdrachten importeren'|translate}}</app-file-upload-btn>
    <app-loading-btn iconName="download" (btnClick)="exportJobs($event)">
        {{'Opdrachten exporteren'|translate}}
    </app-loading-btn>
    <button class="vwui-btn has-icon" tooltip="{{'Opdrachten op kaart bekijken'|translate}}" container="body"
            (click)="openSelectMapProjectModal()">
        <app-icon iconName="map"></app-icon>
        {{'Projectkaart openen'|translate}}
    </button>
</app-toolbar>
<div class="vwui-container is-full">
    <div class="table-filter">
        <div class="vwui-filter">
            <ng-container *ngFor="let filter of jobStatusFilters">
                <ng-container *appHasFeatureToggle="filter.featureToggle">
                <div class="vwui-filter__item" [class.is-active]="filter.status === (activeFilter$|async)"
                     (click)="selectFilter(filter.status)">
                    {{ filter.title }} ({{ filter.count }})
                </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
    <app-multi-select-table *ngIf="paginator$|async; let paginator"
                            #jobTable #multiSelectTable
                            [paginator]="paginator"
                            [headerTitles]="tableHeaders$|async"
                            [enableContextMenu]="true"
                            [enableMultiSelect]="(activeFilter$|async) !== 'Archived'"
                            (filterChanged)="onTableFilterChange($event)"
                            (itemClick)="onJobClick($event)">
        <ng-template #contextMenuHeader></ng-template>
        <ng-template #actions>
            <div *ngIf="multipleActionPending" class="vwui-spinner vwui-spinner--height-auto"></div>
            <button tooltip="{{'Naar uitvoering'|translate}}" type="button" class="vwui-icon-btn"
                    *ngIf="(activeFilter$|async) === 'Concept'"
                    [class.invisible]="multipleActionPending"
                    (click)="transitionMultipleTo($event, jobTable.selection, 'AvailableForInspection')">
                <app-icon iconName="calendar" tooltip="{{'Naar uitvoering'|translate}}"></app-icon>
            </button>
            <button tooltip="{{'Goedkeuren'|translate}}" type="button" class="vwui-icon-btn"
                    *ngIf="(activeFilter$|async) === 'AvailableForVerification'"
                    [class.invisible]="multipleActionPending"
                    (click)="transitionMultipleTo($event, jobTable.selection, 'Approved')">
                <app-icon iconName="approve" tooltip="{{'Goedkeuren'|translate}}"></app-icon>
            </button>
            <button tooltip="{{'Afkeuren'|translate}}" type="button" class="vwui-icon-btn"
                    *ngIf="(activeFilter$|async) === 'AvailableForVerification'"
                    [class.invisible]="multipleActionPending"
                    (click)="rejectJob($event, jobTable.selection)">
                <app-icon iconName="decline" tooltip="{{'Afkeuren'|translate}}"></app-icon>
            </button>
            <button tooltip="{{'Definitief afkeuren'|translate}}" type="button" class="vwui-icon-btn"
                    *ngIf="(activeFilter$|async) === 'AvailableForVerification' || (activeFilter$|async) === 'Rejected'"
                    [class.invisible]="multipleActionPending"
                    (click)="transitionMultipleTo($event, jobTable.selection, 'DefinitivelyRejected')">
                <app-icon iconName="decline" tooltip="{{'Definitief afkeuren'|translate}}"></app-icon>
            </button>
            <button [tooltip]="'export.job' | translate" type="button" class="vwui-icon-btn"
                    *ngIf="(activeFilter$|async) === 'Approved' || (activeFilter$|async) === 'Rejected' || (activeFilter$|async) === 'DefinitivelyRejected'"
                    [class.invisible]="multipleActionPending"
                    (click)="exportMultipleJobs(jobTable.selection)">
                <app-icon iconName="pdf" [tooltip]="'export.job' | translate"></app-icon>
            </button>
            <button id="cancelled" tooltip="{{ 'Vervallen'|translate }}" type="button" class="vwui-icon-btn is-danger"
                    *ngIf="(activeFilter$ | async) === 'Concept' || (activeFilter$ | async) === 'AvailableForInspection' || (activeFilter$ | async) === 'InProgress'"
                    [class.invisible]="multipleActionPending"
                    (click)="transitionMultipleTo($event, jobTable.selection, 'Archived')">
                <app-icon iconName="trash-2" tooltip="{{'Vervallen'|translate}}"></app-icon>
            </button>
        </ng-template>
        <ng-template #row let-item>
            <td>{{ item.title }}</td>
            <td>{{ item.code }}</td>
            <td>{{ item.paulaObject.objectOmschrijvingKort }}</td>
            <td>{{ item.projectName }}</td>
            <td *ngIf="shouldShowInspectionDateColumn(activeFilter$|async)">{{ (item.inspectionDate || getLastAnswerDate(item)) | dateFormat }}</td>
            <td *ngIf="shouldUseAnswersForInspectionDateColumn(activeFilter$|async)">{{ getLastAnswerDate(item) | dateFormat }}</td>
            <td *ngIf="shouldShowInspectorColumn(activeFilter$|async)">{{ item.inspectorNames.join(', ') }}</td>
            <td *ngIf="shouldShowConceptColumns(activeFilter$|async)">{{ item.deadline | dateFormat }}</td>
            <td class="vwui-table__fixed-column-width vwui-multi-select-table--unclickable" (click)="$event.stopPropagation(); $event.preventDefault()">
                <div class="vwui-table__actions">
                    <div *ngIf="item === downloadingFiles" class="vwui-spinner"></div>
                    <app-action-menu *ngIf="item !== downloadingFiles" [growUpward]=true #actionMenu>
                        <button type="button" class="vwui-menu-item" *ngIf="item.status === 'Concept'"
                                (click)="transitionTo($event, item, 'AvailableForInspection')">
                            <app-icon iconName="calendar"></app-icon>
                            {{'Naar uitvoering'|translate}}
                        </button>
                        <button type="button" class="vwui-menu-item" *ngIf="item.status === 'AvailableForVerification'"
                                (click)="transitionTo($event, item, 'Approved')">
                            <app-icon iconName="approve"></app-icon>
                            {{'Goedkeuren'|translate}}
                        </button>
                        <button type="button" class="vwui-menu-item" *ngIf="item.status === 'AvailableForVerification' || item.status === 'Obstructed'"
                                (click)="rejectJob($event, [item])">
                            <app-icon iconName="decline"></app-icon>
                            {{'Afkeuren'|translate}}
                        </button>
                        <button type="button" class="vwui-menu-item"
                                *ngIf="item.status === 'AvailableForVerification' || item.status === 'Rejected' || item.status === 'Obstructed'"
                                (click)="transitionTo($event, item, 'DefinitivelyRejected')">
                            <app-icon iconName="decline"></app-icon>
                            {{'Definitief afkeuren'|translate}}
                        </button>
                        <button type="button" class="vwui-menu-item"
                                *ngIf="item.status === 'Approved' || item.status === 'Rejected' || item.status === 'DefinitivelyRejected'"
                                (click)="exportJob(item)">
                            <app-icon iconName="pdf"></app-icon>
                            {{ 'export.job' |translate}}
                        </button>
                        <button type="button" class="vwui-menu-item"
                                *ngIf="item.status === 'Approved' || item.status === 'Rejected' || item.status === 'DefinitivelyRejected'"
                                (click)="resendJobExport(item)">
                            <app-icon iconName="arrow-right"></app-icon>
                            {{ 'job.export.resend' |translate}}
                        </button>
                        <button type="button" class="vwui-menu-item is-danger"
                                *ngIf="item.status === 'Concept' || item.status === 'AvailableForInspection' || item.status === 'InProgress' || item.status === 'Obstructed'"
                                (click)="transitionTo($event, item, 'Archived')">
                            <app-icon iconName="trash-2"></app-icon>
                            {{'Vervallen'|translate}}
                        </button>
                        <button type="button" class="vwui-menu-item"
                                (click)="openCloneJobModal(item)">
                            <app-icon iconName="duplicate"></app-icon>
                            {{'Kopieren'|translate}}
                        </button>
                    </app-action-menu>
                </div>
            </td>
        </ng-template>
    </app-multi-select-table>
</div>
