import {Component, forwardRef, Inject} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {EntitySelectComponent} from './item-select.component';
import {PageResponse} from '../../models/page-response';
import {CustomerService} from '../../services/customer.service';
import {TranslateService} from "@ngx-translate/core";
import {Customer} from "../../models/customer";

@Component({
    selector: 'app-customer-select',
    templateUrl: './item-select.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CustomerSelectComponent),
            multi: true
        },
    ]
})
export class CustomerSelectComponent extends EntitySelectComponent<Customer, {}> {
    constructor(
        @Inject('CustomerService') private customerService: CustomerService,
        translateService: TranslateService,
    ) {
        super();
        this.placeholder = translateService.instant('Kies een opdrachtgever');
    }

    loadMore(page: number, filterInput: {term: string}): Observable<PageResponse<Customer>> {
        const filter: {[key: string]: any} = {
            name: filterInput && filterInput.term ? filterInput.term : '',
        };
        return this.customerService.getCustomers(page, {filter});
    }

    getLabelText(item: Customer) {
        return item.name;
    }

    getFilter(): Observable<{}> {
        return of({});
    }
}
