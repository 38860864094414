import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class JobExportService {

    constructor(private httpClient: HttpClient) {}

    downloadJobExport(jobId: number): Observable<HttpResponse<Blob>> {
        return this.httpClient.get(`/api/v1/job-export/${jobId}/download`, {responseType: 'blob', observe: 'response'});
    }

    downloadMultipleJobsExport(jobIds: number[]): Observable<HttpResponse<Blob>> {
        const params = new HttpParams().set('jobs', jobIds.join(','));

        return this.httpClient.get(`/api/v1/job-export/combined-download`, {responseType: 'blob', observe: 'response', params});
    }
}
