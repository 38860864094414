import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Inject, Injectable} from '@angular/core';
import {AuthenticationProviderConfigService} from '../services/authentication-provider-config.service';
import {AuthenticationProviderConfig} from '../models/authentication-provider-config';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationProviderConfigResolver implements Resolve<AuthenticationProviderConfig> {

    constructor(
        @Inject('AuthenticationProviderConfigService') private authenticationProviderConfigService: AuthenticationProviderConfigService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.authenticationProviderConfigService.getRegistration(+route.params.id);
    }

}
