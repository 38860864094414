<app-toolbar
    [showAddBtn]="true"
    [addBtnRoute]="'/beheer/question-sets/new'"
    addBtnLabel="{{'Nieuwe vragenset'|translate}}">
</app-toolbar>
<div class="vwui-container">
    <app-table *ngIf="paginator$|async;let paginator" [paginator]="paginator"
               [headerTitles]="[{sortKey: 'title', filterKey: 'title', title: 'Vragensetnaam'}]"
               (filterChanged)="onTableFilterChange($event)">
        <ng-template let-item>
            <tr [routerLink]="['/beheer/question-sets/', item.id]">
                <td>{{ item.title }}</td>
            </tr>
        </ng-template>
    </app-table>
</div>
