// @ts-strict-ignore
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {Forms} from '../../utils/forms';
import {ProjectExecutionGroupService} from '../../services/project-execution-group.service';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {ProjectExecutionGroup} from '../../models/project-execution-group';
import {Subscriptions} from '../../utils/subscriptions';
import {HistoryService} from "../../services/history.service";

@Component({
    selector: 'app-project-execution-group-detail',
    templateUrl: './project-execution-group-detail.component.html'
})
export class ProjectExecutionGroupDetailComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];

    executionGroup: ProjectExecutionGroup;
    form: UntypedFormGroup = new UntypedFormGroup({
        id: new UntypedFormControl(null),
        project: new UntypedFormControl(null),
        title: new UntypedFormControl(
            null,
            [Validators.required, Validators.maxLength(255)],
            [this.validateProjectExecutionGroupNotTaken.bind(this)]
        ),
    })

    constructor(
        @Inject('ProjectExecutionGroupService') private executionGroupService: ProjectExecutionGroupService,
        private toast: ToastrService,
        private route: ActivatedRoute,
        private router: Router,
        private historyService: HistoryService,
    ) {
    }

    ngOnInit(): void {
        this.form.patchValue({project: this.route.snapshot.params.project})
        this.subscriptions.push(this.route.data.subscribe((data) => {
            if (data.executionGroup) {
                this.executionGroup = data.executionGroup;
                this.form.patchValue(this.executionGroup);
            }
        }));
    }

    ngOnDestroy(): void {
        Subscriptions.unsubscribeAll(this.subscriptions);
    }

    async save() {
        if (!this.form.valid) {
            const titleErrors = this.form.controls.title.errors;
            if (titleErrors.maxlength) {
                this.toast.error(`Projectdisciplinenaam bevat meer dan ${titleErrors.maxlength.requiredLength} karakters`);
            }
            if (titleErrors.exists) {
                this.toast.error('Er bestaat al een discipline met de opgegeven naam');
            }

            let missingRequiredField = false;
            for (const control of Object.values(this.form.controls)) {
                if (control?.errors?.required) {
                    missingRequiredField = true;
                    break;
                }
            }

            if (missingRequiredField) {
                this.toast.error('Vul alle verplichte velden in (in het rood aangegeven)');
            }

            Forms.updateValueAndValidityRecursive(this.form);
            return;
        }

        const newProjectExecutionGroup = this.form.value as ProjectExecutionGroup;

        try {
            let result;

            if (newProjectExecutionGroup.id) {
                result = await this.executionGroupService.putProjectExecutionGroup(
                    newProjectExecutionGroup.project,
                    newProjectExecutionGroup
                ).toPromise();
            } else {
                result = await this.executionGroupService.postProjectExecutionGroup(
                    newProjectExecutionGroup.project,
                    newProjectExecutionGroup
                ).toPromise();
            }
            this.toast.success('Opgeslagen');
            this.form.patchValue(result);

            this.goBack();
        } catch (ex) {
            console.error('Unable to save object type', ex);
            this.toast.error('Opslaan mislukt');
        }
    }

    cancel() {
        this.goBack();
    }

    goBack() {
        this.historyService.goBack(['/beheer/projects', this.route.snapshot.params.project], {
            queryParams: {tab: 'execution-groups'}
        });
    }

    validateProjectExecutionGroupNotTaken(control: AbstractControl): Observable<ValidationErrors> {
        const currentId = +this.form.get('id').value;
        const currentProject = +this.form.get('project').value

        return Forms.validateNotTaken(control, currentId, this.executionGroupService.exists(currentProject, control.value));
    }
}
