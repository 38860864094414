// @ts-strict-ignore
import {Component, ContentChild, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import {TableComponent} from '../table/table.component';
import {Identifiable} from '../../models/identifiable';
import {Observable, Subscription} from 'rxjs';

@Component({
    selector: 'app-multi-select-table',
    templateUrl: './multi-select-table.component.html'
})
export class MultiSelectTableComponent<T extends Identifiable> extends TableComponent<T> {
    @ContentChild('actions') actionsTemplate: TemplateRef<unknown>;
    @ContentChild('row') templateRef: TemplateRef<unknown>;
    @ContentChild('contextMenuHeader') contextMenuHeaderTemplate: TemplateRef<unknown>;

    @Input() enableMultiSelect = true; // remove after all project-list multi-select actions are implemented

    @Output() selectionChanged = new EventEmitter<T[]>();
    @Output() itemClick = new EventEmitter<T>();

    selection: T[] = [];
    private resetSelectionSubscription: Subscription|undefined;

    @Input() set resetSelection$(value: Observable<any>) {
        if (this.resetSelectionSubscription) {
            this.resetSelectionSubscription.unsubscribe();
        }

        this.resetSelectionSubscription = value.subscribe(() => {
           this.selection = [];
        });
    }

    get columnCount(): number {
        return this.headerTitles.length + (this.enableContextMenu ? 1 : 0) + (this.enableMultiSelect ? 1 : 0);
    }

    setAllSelected(selected: boolean, items: T[]) {
        if (selected) {
            this.selection = [...this.selection, ...items];
        } else {
            const itemIds = items.map(it => it.id);
            this.selection = this.selection.filter(it => !itemIds.includes(it.id));
        }
    }

    setItemSelected(selected: boolean, item: T) {
        if (selected) {
            this.selection = [...this.selection, item];
        } else {
            this.selection = this.selection.filter(it => it.id !== item.id);
        }

        this.selectionChanged.emit(this.selection);
    }

    resetSelection() {
        this.selection = [];
        this.selectionChanged.emit(this.selection);
    }

    isSelected(item: T) {
        return this.selection.find(it => it.id === item.id) !== undefined;
    }

    allSelected(items: T[]) {
        const selectionIds = this.selection.map(it => it.id);
        const itemIds = items.map(it => it.id);

        return itemIds.every(id => selectionIds.includes(id));
    }

    onItemClick(target: EventTarget, item: T) {
        // Ignore clicks on the TD with checkbox or unclickable class
        if ((target as HTMLElement).classList.contains('vwui-multi-select-table__checkbox-column')
            || (target as HTMLElement).classList.contains('vwui-multi-select-table--unclickable')
        ) {
            return;
        }

        this.itemClick.emit(item);
    }

    stopPropagation(event: Event) {
        event.stopPropagation();
    }
}
