// @ts-strict-ignore
import {Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {GraphService} from '../../services/graph.service';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {
    catchError,
    debounceTime,
    filter,
    map,
    scan,
    shareReplay,
    startWith,
    switchMap,
    take
} from 'rxjs/operators';
import {BsModalService} from 'ngx-bootstrap/modal';
import {UserExecutionGroupModalComponent} from '../user-execution-group-modal/user-execution-group-modal.component';
import {Project} from '../../models/project';
import {ProjectService} from '../../services/project.service';
import {ToastrService} from 'ngx-toastr';
import {SearchConfig} from '../users-search/users-search.component';
import {WorkerGroupService} from '../../services/worker-group.service';
import {WorkerGroup} from '../../models/worker-group';
import {ProjectAuthorization} from '../../models/project-authorization';
import {GraphUser} from '../../models/graph-user';
import {
    ProjectAddAuthorizationRequestInterface,
    ProjectAuthorizationsService
} from '../../services/project-authorizations.service';
import {ProjectWorkerGroupsService} from '../../services/project-worker-groups.service';
import {CompanyService} from '../../services/company.service';

@Component({
    selector: 'app-project-users-tab',
    templateUrl: './project-users-tab.component.html'
})
export class ProjectUsersTabComponent implements OnChanges {
    @Input() project: Project;
    @Output() projectSaved = new EventEmitter<Project>();

    private projectAuthorizationsSubject = new BehaviorSubject(0);
    projectAuthorization$ = this.projectAuthorizationsSubject.pipe(
        filter(projectId => projectId > 0),
        shareReplay(1),
        switchMap(projectId => this.projectAuthorizationsService.getAuthorizations(+projectId))
    );

    private projectWorkerGroupsSubject = new BehaviorSubject(0);
    projectWorkerGroups$ = this.projectWorkerGroupsSubject.asObservable().pipe(
        filter(projectId => projectId > 0),
        shareReplay(1),
        switchMap(projectId => this.projectWorkerGroupsService.getWorkerGroups(projectId))
    );

    search$ = new BehaviorSubject('');

    workerGroups$ = this.search$.pipe(
        debounceTime(100),
        switchMap(it => this.workerGroupService.getWorkerGroups(it)),
        map(it => it.content.filter(workerGroup => {
            return -1 === this.project.workerGroups.findIndex(workerGroupProject => {
                return workerGroupProject.workerGroup.id === workerGroup.id;
            });
        })),
        catchError(() => of([]))
    );

    searchConfigs$: Observable<SearchConfig[]> = this.graphService.getGraphs().pipe(
        map(graphs => graphs.map(graph => ({
            title: graph.title,
            isGroup: false,
            searchData: this.search$.pipe(
                debounceTime(100),
                switchMap(search => this.graphService.getUsers(graph.id, search).pipe(
                    catchError((err) => {
                        console.error(err);
                        return of([]);
                    })
                )),
                map(it => it.filter(user => {
                    return -1 === this.project.authorizations.findIndex(authorization => {
                        return authorization.user.username.toLowerCase() === user.username.toLowerCase();
                    });
                })),
            )
        }))),
        startWith([
            {
                title: 'Groepen',
                searchData: this.workerGroups$,
                isGroup: true
            }
        ]),
        scan((a, b) => a.concat(b), [])
    );

    constructor(
        @Inject('GraphService') private graphService: GraphService,
        @Inject('ProjectService') private projectService: ProjectService,
        @Inject('CompanyService') private companyService: CompanyService,
        @Inject('WorkerGroupService') private workerGroupService: WorkerGroupService,
        @Inject('ProjectAuthorizationsService') private projectAuthorizationsService: ProjectAuthorizationsService,
        @Inject('ProjectWorkerGroupsService') private projectWorkerGroupsService: ProjectWorkerGroupsService,
        private toast: ToastrService,
        private modalService: BsModalService
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.project && changes.project.currentValue) {
            const project = changes.project.currentValue as Project;
            this.projectAuthorizationsSubject.next(project.id);
            this.projectWorkerGroupsSubject.next(project.id);
        }
    }

    searchChanged(search: string) {
        this.search$.next(search);
    }

    openUserModal(authorization: ProjectAuthorization) {
        const modal = this.modalService.show(UserExecutionGroupModalComponent, {
            class: 'modal-dialog-centered',
            initialState: {executionGroupHolder: authorization} as Partial<UserExecutionGroupModalComponent>
        });
        const userExecutionGroupModal = modal.content as UserExecutionGroupModalComponent;

        this.modalService.onHidden.pipe(
            take(1)
        ).subscribe(async () => {
            if (userExecutionGroupModal.result === 'confirmed') {
                authorization.executionGroups = userExecutionGroupModal.form.controls.executionGroups.value;
                authorization.projectExecutionGroups = userExecutionGroupModal.form.controls.projectExecutionGroups.value;

                try {
                    const addAuthorization: ProjectAddAuthorizationRequestInterface = {
                        graphUser: authorization.user,
                        executionGroupIds: authorization.executionGroups.map(executionGroup => executionGroup.id),
                        projectExecutionGroupIds: authorization.projectExecutionGroups.map(executionGroup => executionGroup.id),
                    };

                    const project = await this.projectAuthorizationsService.addAuthorization(this.project.id, addAuthorization).toPromise();
                    this.projectSaved.emit(project);
                    this.search$.next(this.search$.value);
                    this.toast.success('Opgeslagen');
                } catch (e) {
                    this.toast.error('Opslaan mislukt');
                }
            }
        });
    }

    addUser(user: GraphUser) {
        this.openUserModal({
            project: this.project.id,
            user,
            executionGroups: [],
            projectExecutionGroups: []
        });
    }

    async addGroup(workerGroup: WorkerGroup) {
        try {
            const project = await this.projectWorkerGroupsService.addWorkerGroup(this.project.id, workerGroup.id).toPromise();
            this.projectSaved.emit(project);
            this.search$.next(this.search$.value);
            this.toast.success('Opgeslagen');
        } catch (e) {
            this.toast.error('Opslaan mislukt');
        }
    }

    async removeWorkerGroup(workerGroup: WorkerGroup) {
        try {
            const project = await this.projectWorkerGroupsService.removeWorkerGroup(this.project.id, workerGroup.id).toPromise();
            this.projectSaved.emit(project);
            this.search$.next(this.search$.value);
            this.toast.success('Verwijderd');
        } catch (error) {
            this.toast.error('Verwijderen mislukt');
        }
    }

    async removeUser(user: GraphUser) {
        try {
            const authorization = this.project.authorizations.find(projectUser => projectUser.user.username === user.username);
            const project = await this.projectAuthorizationsService
                .removeAuthorization(this.project.id, {graphUser: authorization.user}).toPromise();

            this.projectSaved.emit(project);
            this.search$.next(this.search$.value);

            this.toast.success('Verwijderd');
        } catch (error) {
            this.toast.error('Verwijderen mislukt');
        }
    }

    sortByDisplayName(projectAuthorizations: Array<ProjectAuthorization>) {
        return projectAuthorizations.sort(
            (a: ProjectAuthorization, b: ProjectAuthorization) => {
                return a.user.displayName?.toLowerCase() > b.user.displayName?.toLowerCase() ? 1 : -1
            }
        )
    }
}
