<div *ngIf="customersEnabled">
    <app-toolbar [showBackBtn]="true" (back)="goBack()"></app-toolbar>
    <div class="vwui-container is-small">
        <div class="vwui-card">
            <div class="vwui-card__header">{{'Opdrachtgever'|translate}}</div>
            <div class="vwui-card__content">
                <form [formGroup]="form" (submit)="save()">
                    <div class="vwui-form-group mb-4" [appFormControlError]="form.get('name')">
                        <label for="name" class="vwui-label">{{'Naam'|translate}}</label>
                        <input id="name" class="vwui-input" name="name" type="text" formControlName="name" autocomplete="off">
                    </div>
                    <div class="vwui-form-group mb-4" [appFormControlError]="form.get('description')">
                        <label for="description" class="vwui-label">{{'Omschrijving'|translate}}</label>
                        <textarea id="description" class="vwui-textarea" name="description" formControlName="description" autocomplete="off"></textarea>
                    </div>
                    <div class="vwui-form-group__select mb-4" *ngIf="sketchSets?.length > 0">
                        <label for="sketchSet" class="vwui-label">{{'Tekenset'|translate}}</label>
                        <ng-select
                            id="sketchSet"
                            formControlName="sketchSet"
                            bindLabel="name"
                            bindValue="name"
                            [placeholder]="'Kies een tekenset'|translate"
                            [items]="sketchSets"
                            [clearable]="false">
                        </ng-select>
                    </div>
                    <div class="vwui-form-group__select" *ngIf="customerSpecificReportTemplatesEnabled">
                        <label for="reportTemplate" class="vwui-label">{{'Rapportage sjabloon'|translate}}</label>
                        <ng-select
                            id="reportTemplate"
                            formControlName="reportTemplate"
                            bindLabel="name"
                            bindValue="name"
                            [placeholder]="'Kies een rapportage sjabloon' | translate"
                            [items]="reportTemplates"
                            [clearable]="false">
                        </ng-select>
                    </div>

                    <button type="submit" class="d-none"><!-- Needed to submit with enter --></button>
                </form>
            </div>
        </div>
    </div>
</div>
<app-actionbar *ngIf="form.dirty" (cancel)="cancel()" (save)="save()"></app-actionbar>
