import {Component, Inject, OnDestroy} from '@angular/core';
import {FormTypeService} from '../../services/form-type.service';
import {HeaderTitle, TableFilter} from '../../components/table/table.component';
import {ActivatedRoute, Router} from '@angular/router';
import {firstValueFrom, Observable, timer} from 'rxjs';
import {FileUploadEvent} from '../../components/file-upload-btn/file-upload.interface';
import {HttpErrorResponse} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {FormType} from '../../models/form-type';
import {FileUtil} from '../../utils/file';
import {FeatureToggleCheckService} from '../../services/feature-toggle-check.service';
import {FeatureToggle, FeatureToggleData} from '../../models/feature-toggle';
import {filter, switchMap, take} from 'rxjs/operators';
import {BsModalService} from 'ngx-bootstrap/modal';
import {
    ImportFormSuccessModalComponent
} from '../../components/import-form-success-modal/import-form-success-modal.component';
import {FormTypeStatusService} from '../../services/form-type-status.service';
import {ImportFormV2ModalComponent} from '../../components/import-form-v2-modal/import-form-v2-modal.component';
import {ImportTemplateService} from '../../services/import-template.service';
import {AbstractListComponent} from "../abstract-list.component";
import {PageResponse} from "../../models/page-response";
import {
    CopyProjectFormToFormTypeModalComponent
} from "../../components/copy-project-form-to-form-type-modal/copy-project-form-to-form-type-modal.component";
import {ImportReportError} from "../../models/form-type-import-status";

@Component({
    selector: 'app-form-type-list',
    templateUrl: './form-type-list.component.html'
})
export class FormTypeListComponent extends AbstractListComponent<FormType> implements OnDestroy {
    tableHeaders: HeaderTitle[] = [
        {sortKey: 'title', filterKey: 'title', title: 'Formuliernaam'},
        {sortKey: 'code', filterKey: 'code', title: 'Formuliernummer'},
        {sortKey: 'status', filterKey: 'status', title: 'Status'},
        {filterKey: 'paulaObjectTypes.title', title: 'Objecttypes'},
        {filterKey: 'companies.name', title: 'Bedrijven'},
        {sortKey: 'contactPerson', filterKey: 'contactPerson', title: 'Contactpersoon'},
        {sortKey: 'updatedAt', filterKey: 'updatedAt', title: 'Laatste wijziging'},
        {filterKey: 'tags.name', title: 'Tags'}
    ];
    featureFormTypeImportV2Enabled = false;
    importingFormTypeStatuses = false;
    importingFormType = false;
    downloadingTemplate = false;

    private featureToggles: FeatureToggleData[] = [];

    constructor(
        @Inject('FormTypeService') public formTypeService: FormTypeService,
        @Inject('FormTypeStatusService') private formTypeStatusService: FormTypeStatusService,
        private toast: ToastrService,
        private translate: TranslateService,
        private templateService: ImportTemplateService,
        @Inject('FeatureToggleCheckService') private featureToggleCheckService: FeatureToggleCheckService,
        private modalService: BsModalService,
        activatedRoute: ActivatedRoute,
        router: Router,
    ) {
        super(activatedRoute, router);
        this.subscriptions.push(
            this.featureToggleCheckService.featureToggles().subscribe(featureToggles => {
                this.featureToggles = featureToggles;
                this.featureFormTypeImportV2Enabled = featureToggles.find(it => it.featureToggleName === 'formTypeImportV2')?.enabled || false;
            })
        );
    }

    loadData(page: number, filter: TableFilter, search: string | undefined): Observable<PageResponse<FormType>> {
        return this.formTypeService.getList(page, filter);
    }

    async importFormTypes(event: FileUploadEvent) {
        if (!FeatureToggleCheckService.isEnabled(this.featureToggles, FeatureToggle.FormTypeImportV2)) {
            return this.importFormTypesV1(event);
        }
    }

    private async importFormTypesV1(event: FileUploadEvent) {
        try {
            await this.formTypeService.importFormTypesV1(event.target.files!![0]).toPromise();
            this.toast.success(
                this.translate.instant('De opdrachten zijn succesvol geïmporteerd.'),
                this.translate.instant('Succes')
            );
        } catch (e) {
            console.error('Failed to import objects', e);
            if (e instanceof HttpErrorResponse) {
                if (e.error && e.error.fieldErrors) {
                    const fieldErrors = e.error.fieldErrors;
                    if (fieldErrors.cell) {
                        this.toast.error(
                            `Het importeren van opdrachten is mislukt, fout in cell ${fieldErrors.cell}`
                            + `, ongeldige waarde: ${fieldErrors.value}`, 'Fout'
                        );
                        return;
                    }
                    if (fieldErrors.row) {
                        this.toast.error(
                            `Het importeren van opdrachten is mislukt, fout in rij ${fieldErrors.row}`,
                            'Fout');
                        return;
                    }
                }
            }

            this.toast.error(
                this.translate.instant('Het importeren van opdrachten is mislukt'),
                this.translate.instant('Fout')
            );
        } finally {
            event.target.value = '';
            event.complete();
        }
    }

    async downloadImportTemplate() {
        try {
            this.downloadingTemplate = true;
            const blob = await firstValueFrom(this.templateService.getTemplate());
            FileUtil.downloadBlobAsFile(blob, 'import-template.xlsx');
        } catch (ex) {
            console.error('Error occurred while downloading import template', ex);
            this.toast.error(this.translate.instant('Er is iets mis gegaan bij het downloaden van de import template'));
        } finally {
            this.downloadingTemplate = false;
        }

    }

    async openImportV2Modal() {
        this.importingFormType = true;
        const modalRef = this.modalService.show(ImportFormV2ModalComponent, {
            ignoreBackdropClick: true,
            class: 'modal-dialog-centered',
        });

        modalRef.onHide?.pipe(
            take(1)
        ).subscribe(() => {
            const modalContent = modalRef.content
            if (modalContent && modalContent.import && modalContent.workbook) {
                this.importFormTypesV2(modalContent.workbook, modalContent.imageZip);
            } else {
                this.importingFormType = false;
            }
        });
    }

    private async importFormTypesV2(workbook: File, imageZip: File) {
        try {
            const initialImport = await firstValueFrom(this.formTypeService.importFormTypesV2(workbook, imageZip));
            const importStatus = await firstValueFrom(timer(300, 1000).pipe(
                switchMap(() => this.formTypeService.importStatus(initialImport.id)),
                filter(it => it.status !== 'Queued'),
                take(1)
            ));

            const importReport = importStatus.importReport;
            if (importReport && importReport.success) {
                this.modalService.show(ImportFormSuccessModalComponent, {
                    class: 'modal-dialog-centered',
                    initialState: {
                        importedForms: importReport.importedForms
                    }
                });
            } else {
                const applicationTitle = this.translate.instant('application.title');
                if (importReport && importReport.errors.length) {
                    importReport.errors.forEach(({message, data}) => {
                        const errorMessage = this.translate.instant(message, {...data, applicationTitle});
                        if (errorMessage !== message) {
                            this.toast.error(`${errorMessage}.`);
                        } else {
                            if (!data.value || !data.column) {
                                this.toast.error(this.translate.instant('Invalid import value - ROW', data) + '.');
                            } else {
                                this.toast.error(this.translate.instant('Invalid import value - ALL', data) + '.');
                            }
                        }
                    });
                } else {
                    this.toast.error(this.translate.instant('Er is iets mis gegaan bij het importeren van formulieren') + '.');
                }
            }
        } catch (e) {
            console.error('Something went wrong while importing form types', e);
            this.toast.error(this.translate.instant('Er is iets mis gegaan bij het importeren van formulieren') + '.');
        } finally {
            this.importingFormType = false;
        }
    }

    async downloadForm(formType: FormType) {
        try {
            const httpResponse = await firstValueFrom(this.formTypeService.downloadForm(formType));
            const contentDisposition = httpResponse.headers.get('content-disposition');
            const fileName = contentDisposition!!.split('filename=')[1].replace(/"/g, '');

            FileUtil.downloadBlobAsFile(httpResponse.body!!, fileName);
        } catch (error) {
            if (error instanceof HttpErrorResponse) {
                this.toast.error(this.translate.instant('Exporteren formulier is mislukt.'));
            }
        }
    }

    async importFormTypeStatuses(event: FileUploadEvent) {
        if (this.importingFormTypeStatuses) {
            console.warn('Form type status import is already busy');
            return;
        }

        try {
            this.importingFormTypeStatuses = true;

            const initialImport = await firstValueFrom(this.formTypeStatusService.importFormTypeStatus(event.target.files!![0]));
            const {importReport} = await firstValueFrom(timer(300, 1000).pipe(
                switchMap(() => this.formTypeStatusService.importStatus(initialImport.id)),
                filter(it => it.status !== 'Queued'),
                take(1)
            ));

            if (importReport && importReport.success) {
                this.toast.success(this.translate.instant('Statussen zijn succesvol bijgewerkt') + '.');
            } else {
                const applicationTitle = this.translate.instant('application.title');
                if (importReport && importReport.errors.length) {
                    importReport.errors.forEach(({data, message}: ImportReportError) => {
                        const errorMessage = this.translate.instant(message, {...data, applicationTitle});
                        if (errorMessage !== message) {
                            this.toast.error(`${errorMessage}.`);
                        } else {
                            if (!data.value || !data.column) {
                                this.toast.error(this.translate.instant('Invalid import value - ROW', data) + '.');
                            } else {
                                this.toast.error(this.translate.instant('Invalid import value - ALL', data) + '.');
                            }
                        }
                    });
                } else {
                    this.toast.error(this.translate.instant('Er is iets mis gegaan bij het importeren van formulieren') + '.');
                }
            }
        } catch (e) {
            console.error('Something went wrong while importing form type statuses', e);
            this.toast.error(this.translate.instant('Er is iets mis gegaan bij het importeren van formulieren') + '.');
        } finally {
            event.complete();
            this.importingFormTypeStatuses = false;
        }
    }

    async openCopyProjectFormModal() {
        this.modalService.show(CopyProjectFormToFormTypeModalComponent, {
            ignoreBackdropClick: true,
            class: 'modal-dialog-centered'
        })
    }
}
