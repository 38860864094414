// @ts-strict-ignore
import {Component, EventEmitter, Inject, OnDestroy, OnInit, Output} from '@angular/core';
import {TableFilter} from '../../components/table/table.component';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {Filters} from '../../utils/filters';
import {Paginator} from '../../utils/paginator';
import {CustomerService} from '../../services/customer.service';
import {ToastrService} from 'ngx-toastr';
import {Customer} from '../../models/customer';
import {FeatureToggle} from '../../models/feature-toggle';

@Component({
    selector: 'app-customer-list',
    templateUrl: './customer-list.component.html'
})
export class CustomerListComponent implements OnInit, OnDestroy {
    customersEnabled = FeatureToggle.CustomersEnabled;
    paginator = new Paginator(page => this.customerService.getCustomers(page));
    private queryParamSubscription: Subscription;
    @Output() eventEmitter = new EventEmitter<Customer>();

    constructor(
        @Inject('CustomerService') private customerService: CustomerService,
        protected activatedRoute: ActivatedRoute,
        protected router: Router,
        private toast: ToastrService
    ) { }

    ngOnDestroy(): void {
        this.queryParamSubscription.unsubscribe();
    }

    ngOnInit(): void {
        this.queryParamSubscription = this.activatedRoute.queryParams.subscribe((queryParams) => {
            if (queryParams) {
                const filter = Filters.getFilteringFromParams(queryParams);
                this.paginator = new Paginator(page => this.customerService.getCustomers(page, filter));
            }
        });
    }

    onTableFilterChange(filter: TableFilter) {
        this.paginator = new Paginator(page => this.customerService.getCustomers(page, filter));
        Filters.saveFilteringToRoute(filter, this.activatedRoute, this.router);
    }

    async deleteCustomer(customer: Customer) {
        try {
            const c = await this.customerService.deleteCustomer(customer).toPromise();
            this.eventEmitter.emit(c);
            this.toast.success('Verwijderd');
            this.ngOnInit();
        } catch (e) {
            this.toast.error('Verwijderen mislukt');
            console.error(e);
        }
    }
}
