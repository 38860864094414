<app-modal modalTitle="{{'Selecteer een project'|translate}}" modalSaveBtnLabel="{{'Kaart bekijken'|translate}}" (save)="submit()" (cancel)="closeModal('cancelled')">
    <p>
        {{'Selecteer hieronder het project dat u op de kaart wilt bekijken'|translate}}
    </p>
    <form [formGroup]="form" *ngIf="form">
        <div class="u-mb-m">
            <div [appFormControlError]="form.get('project')" class="vwui-form-group">
                <label for="project" class="vwui-label">{{'Project'|translate}}</label>
                <app-project-select id="project" formControlName="project"></app-project-select>
            </div>
        </div>
        <button type="submit" class="d-none"><!-- Needed to submit with enter --></button>
    </form>
</app-modal>
