// @ts-strict-ignore
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {defer, merge, Observable, of, Subscription} from 'rxjs';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscriptions} from '../../utils/subscriptions';
import {Forms} from '../../utils/forms';
import {AuthenticationProviderConfig} from '../../models/authentication-provider-config';
import {AuthenticationProviderConfigService} from '../../services/authentication-provider-config.service';
import {HistoryService} from "../../services/history.service";

@Component({
    selector: 'app-authentication-provider-config-detail',
    templateUrl: './authentication-provider-config-detail.component.html',
})
export class AuthenticationProviderConfigDetailComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];

    config: AuthenticationProviderConfig;

    form = new UntypedFormGroup({
        id: new UntypedFormControl(null),
        title: new UntypedFormControl(null, Validators.required),
        type: new UntypedFormControl('azure'),
        loginClientId: new UntypedFormControl(null, Validators.required),
        loginSecret: new UntypedFormControl(null, Validators.required),
        brandingTextColor: new UntypedFormControl('#ffffff', Validators.required),
        brandingFirstColor: new UntypedFormControl('#008cbd', Validators.required),
        brandingSecondColor: new UntypedFormControl('#008cbd', Validators.required),
        defaultProject: new UntypedFormControl(null),
        // Azure specific fields
        tenantId: new UntypedFormControl(null, Validators.required, this.validateRegistrationNotTaken.bind(this)),
        clientId: new UntypedFormControl(null, Validators.required),
        secret: new UntypedFormControl(null, Validators.required),
        loginTenantId: new UntypedFormControl(null, Validators.required),
        // OpenID specific fields
        openidConfiguration: new UntypedFormControl(null, Validators.required)
    });

    constructor(
        @Inject('AuthenticationProviderConfigService') private registrationService: AuthenticationProviderConfigService,
        private toast: ToastrService,
        private route: ActivatedRoute,
        private router: Router,
        private historyService: HistoryService,
    ) {
    }

    ngOnInit(): void {
        const typeControl = this.form.get('type');
        this.subscriptions.push(
            merge(
                defer(() => of(typeControl.value)),
                typeControl.valueChanges
            ).subscribe((type: AuthenticationProviderConfig['type']) => {
                this.setControlsEnabled(type === 'azure', [
                    'tenantId', 'clientId', 'secret', 'loginTenantId'
                ]);
                this.setControlsEnabled(type === 'openid', [
                    'openidConfiguration'
                ]);
                this.form.updateValueAndValidity();
            })
        );

        this.subscriptions.push(
            this.route.data.subscribe((data) => {
                if (data.registration) {
                    this.config = data.registration;
                    this.form.patchValue(this.config);
                }
            })
        );
    }

    ngOnDestroy(): void {
        Subscriptions.unsubscribeAll(this.subscriptions);
    }

    async save() {
        if (!this.form.valid) {
            if (this.form.controls.tenantId.errors?.exists) {
                this.toast.error('Er bestaat al een koppeling met de opgegeven Directory ID');
            } else {
                this.toast.error('Vul alle verplichte velden in (in het rood aangegeven)');
            }

            Forms.updateValueAndValidityRecursive(this.form);
            return;
        }

        const newRegistration: AuthenticationProviderConfig = {
            ...this.config,
            ...this.form.value
        };

        try {
            let result;

            if (newRegistration.id) {
                result = await this.registrationService.putRegistration(newRegistration).toPromise();
            } else {
                result = await this.registrationService.postRegistration(newRegistration).toPromise();
            }
            this.toast.success('Opgeslagen');
            this.form.patchValue(result);

            this.goBack();
        } catch (ex) {
            console.error('Unable to save registration', ex);
            this.toast.error('Opslaan mislukt');
        }
    }

    cancel() {
        this.goBack();
    }

    goBack() {
        this.historyService.goBack(['/beheer/registrations']);
    }

    validateRegistrationNotTaken(control: AbstractControl): Observable<ValidationErrors> {
        const currentId = +this.form.get('id').value;

        return Forms.validateNotTaken(control, currentId, this.registrationService.exists(control.value));
    }

    getConsentUrl() {
        return `https://login.microsoftonline.com/${this.form.get('tenantId').value}/adminconsent`
            + `?client_id=${encodeURIComponent(this.form.get('clientId').value)}&redirect_uri=${encodeURIComponent(window.location.href)}`;
    }

    private setControlsEnabled(enabled: boolean, controls: string[]) {
        controls.forEach(control => {
            const controlObj = this.form.get(control);
            if (controlObj === null) {
                return;
            }

            if (enabled) {
                controlObj.enable({onlySelf: true});
            } else {
                controlObj.disable({onlySelf: true});
            }
        });
    }
}
