<div class="vwui-container">
    <ng-container *ngFor="let chapter of jobForm?.chapters">
        <div class="vwui-card u-mb-l" *ngIf="isChapterVisible(chapter.position)">
            <div class="vwui-card__header">{{ chapter.position / 1000 }}. {{ chapter.title }}
                <span class="vwui-badge is-success" *ngIf="dependentQuestionPosition(chapter) as dependentQuestionPosition">
                    {{'Afhankelijk van'|translate}}: {{ dependentQuestionPosition }}
                </span>
            </div>
            <div class="vwui-list">
                <ng-container *ngFor="let question of chapter.questions">
                    <div class="vwui-list-item" *ngIf="isQuestionVisible(question.position)">
                        <div class="vwui-list-item__content">
                            <div class="container-fluid">
                                <div class="row no-gutters">
                                    <div class="col">
                                        {{ chapter.position / 1000 }}.{{ question.position % 1000 }} {{ question.title }}
                                        <span class="vwui-badge is-primary">{{ question.type | questionTypeTitle }}</span>
                                        <span *ngIf="question?.formulaFields?.label" class="vwui-badge is-primary">
                                            {{ question.formulaFields.label }}
                                        </span>
                                        <span class="vwui-badge ml-2"
                                              *ngIf="question.required">{{'Verplicht'|translate}}</span>
                                        <span class="vwui-badge is-success"
                                              *ngIf="dependentQuestionPosition(question) as dependentQuestionPosition">
                                        {{'Afhankelijk van'|translate}}: {{ dependentQuestionPosition }}
                                    </span>
                                    </div>
                                    <div class="col text-right">
                                        <ng-container *appHasFeatureToggle="'downloadAnswerImageEnabled'">
                                            <button class="app-project-job-detail__download-button vwui-btn is-success"
                                                    *ngIf="shouldShowDownloadButton(question)"
                                                    (click)="downloadFiles(question)">
                                                {{'Download'|translate}}
                                            </button>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="vwui-list-item__content">
                            <ng-container *ngIf="getAnswer(question.position) as answer" [ngSwitch]="question.type">
                                <div *ngSwitchCase="'text'" [innerHTML]="answer.value | nl2br"></div>
                                <ng-container *ngSwitchCase="'number'">{{ answer.value }}</ng-container>
                                <div *ngSwitchCase="'choice'">
                                    <ul class="list-style-inside">
                                        <ng-container *ngFor="let choice of question.choices">
                                            <li *ngIf="isQuestionChoiceSelected(choice)">
                                                {{ choice.title }}
                                            </li>
                                        </ng-container>
                                    </ul>
                                    <div *ngIf="answer.remarkImage || answer.remarkText" class="mt-3">
                                        <strong>{{'Opmerking'|translate}}</strong>
                                        <div *ngIf="answer.remarkText" class="mt-3">
                                            {{ answer.remarkText }}
                                        </div>
                                        <div *ngIf="answer.remarkImage" class="mt-3">
                                            <div class="d-flex flex-row">
                                                <div class="mr-3" *ngFor="let photoUuid of parseImageAnswer(answer.remarkImage)">
                                                    <img appBlobHref [photoUuid]="photoUuid" [id]="photoUuid"
                                                         (click)="openImagePreview(photoUuid)"
                                                         class="img-thumbnail app-project-job-detail__thumbnail"
                                                         alt="Foto">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ng-container *ngSwitchCase="'date'">
                                    {{ answer.value | dateFormat:'default':(question.time ? 'DD-MM-YYYY HH:mm:ss' : 'DD-MM-YYYY') }}
                                </ng-container>
                                <ng-container *ngSwitchCase="'table'">
                                    <ng-container *ngTemplateOutlet="listAnswer; context:{question, answer}"></ng-container>
                                </ng-container>
                                <ng-container *ngSwitchCase="'list'">
                                    <ng-container *ngTemplateOutlet="listAnswer; context:{question, answer}"></ng-container>
                                </ng-container>
                                <ng-container *ngSwitchCase="'tabular'">
                                    <div *ngIf="parseTabularJson(answer.value) as tabularAnswer" class="w-100">
                                        <table class="ng-select-table ng-select-table--tabular">
                                            <ng-container *ngFor="let row of tabularAnswer|keyvalue; let i = index;">
                                                <tr *ngIf="i !== 0">
                                                    <td [attr.colspan]="question.pivot ? 2 : this.question.columns.length">&nbsp;</td>
                                                </tr>

                                                <thead>
                                                    <th class="text-dark table-header"
                                                        [attr.colspan]="question['pivot'] ? 2 : this.question.columns.length">
                                                        {{ row.value.label }}
                                                    </th>
                                                </thead>

                                                <thead *ngIf="!question.pivot">
                                                    <tr>
                                                        <th *ngFor="let column of question.columns">{{column.label}}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <ng-container *ngIf="!question.pivot else pivotTable">
                                                        <tr *ngFor="let answer of tabularAnswer|keyvalue">
                                                            <td *ngFor="let answer of answer.value.columns|keyvalue">
                                                                {{ answer.value }}
                                                            </td>
                                                        </tr>
                                                    </ng-container>

                                                    <ng-template #pivotTable>
                                                        <tr *ngFor="let column of question.columns; let i = index;">
                                                            <th>{{column.label}}</th>
                                                            <ng-container *ngFor="let answer of tabularAnswer|keyvalue">
                                                                <td *ngIf="answer.key === row.key">
                                                                    {{ answer.value.columns[i] }}
                                                                </td>
                                                            </ng-container>
                                                        </tr>
                                                    </ng-template>
                                                </tbody>
                                            </ng-container>
                                        </table>
                                    </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="'signature'">
                                    <img class="img-thumbnail app-project-job-detail__thumbnail"
                                         [src]="'/blob/' + answer.value" alt="{{'Handtekening'|translate}}">
                                </ng-container>
                                <ng-container *ngSwitchCase="'object'">
                                    <div *ngIf="getObject(jobForm.project, answer.value)|async as item" class="w-100">
                                        <h5 class="mb-2">{{ item.objectOmschrijvingKort }}</h5>
                                        <table class="ng-select-table">
                                            <thead>
                                            <tr>
                                                <th>{{'Object ID'|translate}}</th>
                                                <th>{{'Hoofdfilter'|translate}}</th>
                                                <th>{{'Subfilter'|translate}}</th>
                                                <th>{{'Geo'|translate}}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>{{ item.objectId }}</td>
                                                <td>{{ item.objectFilter }}</td>
                                                <td>{{ item.objectSubFilter }}</td>
                                                <td>{{ item.objectGeo }}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="'formula'">
                                    <div *ngIf="parseFormulaJson(answer.value) as formulaAnswer" class="w-100">
                                        <table class="ng-select-table">
                                            <thead>
                                            <tr>
                                                <th>{{'Variablen'|translate}}</th>
                                                <th>{{'Waardes'|translate}}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr *ngFor="let field of question.formulaFields?.fields || []">
                                                <td>{{ field.label }}</td>
                                                <td>{{ (formulaAnswer[field.fieldName] | number:('1.' + field.totalDecimal + '-' + field.totalDecimal)) || '-' }}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="'location'">
                                    {{ answer.value|questionTypeLocation:'from' }}<br>
                                    {{ answer.value|questionTypeLocation:'to' }}
                                </ng-container>
                                <ng-container *ngSwitchCase="'photo'">
                                    <div class="container-fluid">
                                        <div class="row no-gutters">
                                            <div class="col-12 col-sm-6 col-md-3 pr-3 pb-3"
                                                 *ngFor="let photoUuid of parseImageAnswer(answer.value)">
                                                <div class="card p-1">
                                                    <img appBlobHref [photoUuid]="photoUuid" class="card-img-top img-fluid" [id]="photoUuid"
                                                         alt="{{ 'Foto'|translate }}"
                                                         (click)="openImagePreview(photoUuid)"
                                                         [ngClass]="(previewAnswerImageEnabled$|async) ? 'app-project-job-detail__thumbnail__answer-image' : ''"
                                                    />
                                                    <div class="card-body p-3"
                                                         *appHasFeatureToggle="projectJobAnswerMetaEnabled">
                                                        <div *ngFor="let answerMeta of answer.projectJobAnswerMeta">
                                                            <div *ngIf="photoUuid === answerMeta.answerId">
                                                                <div class="container">
                                                                    <div class="row text-muted no-gutters">
                                                                        <div class="col-4">
                                                                            <small>{{ 'Foto'| translate }}</small>
                                                                        </div>
                                                                        <div class="col-8 text-right"
                                                                             *ngIf="answerMeta.fileCreatedAt !== null; else AnswerCreatedAt">
                                                                            <small>{{ answerMeta.fileCreatedAt|dateFormat }}</small>
                                                                        </div>
                                                                        <ng-template #AnswerCreatedAt>
                                                                            <div class="col-8 text-right">
                                                                                <small
                                                                                    class="text-nowrap">{{ answerMeta.createdAt|dateFormat }}</small>
                                                                            </div>
                                                                        </ng-template>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="col text-max-3-lines-scroll">
                                                                            <div
                                                                                *ngFor="let answerMeta of answer.projectJobAnswerMeta">
                                                                                <div
                                                                                    *ngIf="photoUuid === answerMeta.answerId">
                                                                                    {{ answerMeta.comment }}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="'referenceImage'">
                                    <div *ngFor="let photoUuid of parseImageAnswer(answer.value)">
                                        <img *ngIf="photoUuid !== ''" appBlobHref [photoUuid]="photoUuid" [id]="photoUuid"
                                             (click)="openImagePreview(photoUuid)"
                                             class="img-thumbnail app-project-job-detail__thumbnail"
                                             alt="Foto">
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>
</div>

<div class="vwui-actionbar" *ngIf="jobForm?.status === verificationStatus">
    <button class="vwui-btn is-danger-full" (click)="rejectJob()" [disabled]="rejectingOrApproving">Afkeuren</button>
    <button class="vwui-btn is-success" (click)="approveJob()" [disabled]="rejectingOrApproving">Goedkeuren</button>
</div>

<ng-template #listAnswer let-answer="answer" let-question="question">
    <ul class="list-style-inside">
        <li *ngFor="let rowAnswer of answer.value | tableQuestionAnswers; let i = index">
            {{ question.questionLabel }} {{ i + 1 }}: {{ rowAnswer }}
        </li>
        <li>
            <strong>{{'Totaal'|translate}}
                : {{ answer.value | tableQuestionAnswers | sum | number:'1.0-6':'nl-NL' }}</strong>
        </li>
        <li>
            <strong>{{'Gemiddelde'|translate}}
                : {{ answer.value | tableQuestionAnswers | avg | number:'1.0-6':'nl-NL' }}</strong>
        </li>
    </ul>
</ng-template>
