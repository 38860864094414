import {AbstractForm, FormTypes, ProjectForm} from './form-type';
import {Project} from './project';
import {Object} from './object';
import {ProjectJobSubmit} from './project-job-submit';
import {ProjectJobAnswer} from './job-form/project-job-answer';
import {UploadedFile} from './uploaded-file';

export enum ProjectJobStatus {
    Concept = 'Concept',
    AvailableForInspection = 'AvailableForInspection',
    AvailableForVerification = 'AvailableForVerification',
    Obstructed = 'Obstructed',
    InProgress = 'InProgress',
    Approved = 'Approved',
    Rejected = 'Rejected',
    DefinitivelyRejected = 'DefinitivelyRejected',
    Archived = 'Archived'
}

export const projectJobStatusTitles = {
    Concept: 'Concept',
    AvailableForInspection: 'Voor uitvoering',
    InProgress: 'In uitvoering',
    AvailableForVerification: 'Voor verificatie',
    Obstructed: 'Obstructie',
    Approved: 'Goedgekeurd',
    Rejected: 'Afgekeurd',
    DefinitivelyRejected: 'Definitief afgekeurd',
    Archived: 'Vervallen'
};

export interface ProjectJob extends AbstractForm {
    id: number;
    code: string;
    type: FormTypes.ProjectJob;
    title: string;
    projectName?: string;
    originalProjectFormId: number;
    inspectorNames: string[];
    inspectionDate: Date;
    deadline: Date;
    startDate: Date;
    answers: ProjectJobAnswer[];
    submits: ProjectJobSubmit[];
    status: ProjectJobStatus;
    project: Project;
    paulaObject: Object;
    extraFields: ProjectJobExtra;
    originalProjectJob: ProjectJob;
    originalProjectForm: ProjectForm;
    attachments: UploadedFile[];
}

export interface ProjectJobExtraLatLong {
    gpsLatitudeFrom: number | null;
    gpsLatitudeTo: number | null;
    gpsLongitudeFrom: number | null;
    gpsLongitudeTo: number | null;
}

export interface ProjectJobExtra extends ProjectJobExtraLatLong {
    locationFrom: string;
    locationTo: string;
    originatedChoice?: string;
    originatedQuestion?: number;
    maximoReference?: string;
    maximoParent?: string;
    maximoLead?: string;
    maximoSiteId?: string;
    maximoWolo4?: string;
    talendWid?: string;
}

export type ProjectJobUpdateDTO = Pick<ProjectJob, 'id' | 'extraFields' | 'title' | 'startDate' | 'attachments'>;
