// @ts-strict-ignore
import {InterfaceProcessingErrorService} from '../interface-processing-error.service';
import {Observable, of} from 'rxjs';
import {PageResponse} from '../../models/page-response';
import {
    InterfaceProcessingError,
    InterfaceProcessingErrorDetail,
    InterfaceProcessingErrorType
} from '../../models/interface-processing-error';
import {TableFilter} from '../../components/table/table.component';
import {AbstractCrudServiceMock} from './abstract-crud.service.mock';
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';

const processingErrors: InterfaceProcessingError[] = [
    {
        id: 2,
        type: InterfaceProcessingErrorType.Maximo,
        workOrder: {
            id: 1,
            type: 'GetWorkorder',
            workOrderId: 'FLEXINSPECTTOP_FXWOTOP_9978550.1576504376022855564.xml',
            retries: 3,
            createdAt: new Date(),
            updatedAt: new Date(),
            data: '<test></test>'
        },
        message: 'Project "{{project_identifier}}" does not exists',
        data: {project_identifier: 'VISP2_VISP2'},
        resolvedAt: undefined,
        retryable: true,
        occurredAt: new Date('2021-08-02T09:44:39.580Z')
    },
    {
        id: 3,
        type: InterfaceProcessingErrorType.Vise,
        reference: '7f0ec1e7-2542-4321-a493-8ec628cabdb5',
        workOrder: null,
        message: 'Received error report from Talend: {{message}}',
        data: {
            xml: '<?xml version="1.0" encoding="utf-8"?><Blaat></Blaat>',
            message: 'Testbericht'
        },
        resolvedAt: undefined,
        retryable: false,
        occurredAt: new Date('2021-08-02T09:44:39.580Z')
    }
];

@Injectable()
export class InterfaceProcessingErrorServiceMock
    extends AbstractCrudServiceMock<InterfaceProcessingError> implements InterfaceProcessingErrorService {

    constructor() {
        super(processingErrors);
    }

    getInterfaceProcessingErrors(page: number, filter?: TableFilter): Observable<PageResponse<InterfaceProcessingError>> {
        return this.getAll(filter).pipe(
            map((response) => {
                response.content = response.content.filter(it => it.resolvedAt === undefined);

                return response;
            })
        );
    }

    getInterfaceProcessingError(number: number): Observable<InterfaceProcessingErrorDetail> {
        return of({...processingErrors[0], xml: processingErrors[0].workOrder.data});
    }

    resolve(id: number): Observable<any> {
        const processingError = this.data.find(it => it.id === id);
        if (processingError) {
            processingError.resolvedAt = new Date();
        }

        return of(null);
    }

    deleteInterfaceProcessingError(id: number): Observable<any> {
        const processingError = this.data.find(it => it.id === id);
        return this.delete(processingError);
    }

    retryInterfaceProcessingError(id: number): Observable<any> {
        return of(null);
    }
}
