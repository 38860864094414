// @ts-strict-ignore
import {Observable, of} from 'rxjs';
import {PageResponse} from '../../models/page-response';
import {AbstractCrudServiceMock} from './abstract-crud.service.mock';
import {Injectable} from '@angular/core';
import {ProjectExecutionGroup} from '../../models/project-execution-group';
import {ProjectExecutionGroupService} from '../project-execution-group.service';
import {mockProjects} from './project.service.mock';

export const mockProjectExecutionGroups: Array<ProjectExecutionGroup> = [
    {
        id: 2,
        project: mockProjects[0].id,
        title: 'Schiphol tunnel bij project'
    },
    {
        id: 1,
        project: mockProjects[0].id,
        title: 'Treinspoor bij project'
    }
];

@Injectable()
export class ProjectExecutionGroupServiceMock extends AbstractCrudServiceMock<ProjectExecutionGroup> implements ProjectExecutionGroupService {

    constructor() {
        super(mockProjectExecutionGroups);
    }

    getProjectExecutionGroups(projectId: number, page: number): Observable<PageResponse<ProjectExecutionGroup>> {
        return this.getAll({filter: {
            project: {
                operator: '=',
                value: `${projectId}`
            }
        }});
    }

    getProjectExecutionGroup(projectId: number, id: number) {
        return this.get(id);
    }

    putProjectExecutionGroup(projectId: number, executionGroup: ProjectExecutionGroup): Observable<ProjectExecutionGroup> {
        return this.put(executionGroup);
    }

    postProjectExecutionGroup(projectId: number, executionGroup: ProjectExecutionGroup) {
        return this.post(executionGroup);
    }

    exists(projectId: number, title: string) {
        const result = mockProjectExecutionGroups.find(item => item.project == projectId && item.title === title);

        return of(result ? result.id : null);
    }
}
