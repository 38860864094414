<app-toolbar [toolbarTitle]="workergroupName" [showBackBtn]="true" (back)="cancel()">
    <button class="vwui-btn is-primary has-icon" (click)="goToWorkerGroups()" *ngIf="!isNew()">
        <app-icon iconName="edit-2"></app-icon>
        {{'Medewerkersgroepen beheren'|translate}}
    </button>
</app-toolbar>

<div class="vwui-container is-small">
    <div class="vwui-card">
        <div class="vwui-card__header">
            <div class="vwui-card__title">{{'Medewerkersgroep'|translate}}</div>
        </div>
        <div class="vwui-card__content">
            <form [formGroup]="form" (submit)="save()">
                <div [appFormControlError]="form.get('name')" class="vwui-form-group mb-4">
                    <label for="name" class="vwui-label">{{'Medewerkersgroepnaam'|translate}}</label>
                    <input id="name" name="name" type="text" class="vwui-input" formControlName="name">
                </div>
                <button type="submit" class="d-none"><!-- Needed to submit with enter --></button>
            </form>
        </div>
    </div>
</div>

<app-actionbar *ngIf="form.dirty" (cancel)="cancel()" (save)="save()"></app-actionbar>
