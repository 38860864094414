// @ts-strict-ignore
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {PageResponse} from '../models/page-response';
import {User, UserProject, UserRoles} from '../models/user';
import {Observable} from 'rxjs';
import {SearchFilter, TableFilter} from '../components/table/table.component';
import {Filters} from '../utils/filters';

export interface UserService {
    getUsers(page: number, filter?: TableFilter): Observable<PageResponse<User>>;
    getUsersByRole(role: UserRoles): Observable<PageResponse<User>>;
    getUser(id: number): Observable<User>;
    getUserByUsername(username: string): Observable<User>;
    putUser(user: User): Observable<User>;
    postUser(user: Omit<User, 'id'>): Observable<User>;
    exists(username: string): Observable<number>;
    getUserProjects(id: number): Observable<UserProject[]>;
    decoupleContentCode(id: number): Observable<any>;
}

@Injectable()
export class UserServiceImpl implements UserService {
    constructor(private httpClient: HttpClient) {
    }

    getUsers(page: number, filter?: TableFilter) {
        let params = new HttpParams().set('page', '' + page);

        if (filter) {
            params = Filters.applyFiltering(filter, params);
        }

        return this.httpClient.get<PageResponse<User>>('/api/v1/users', {params});
    }

    getUsersByRole(role: UserRoles): Observable<PageResponse<User>> {
        const searchFilter: SearchFilter = {
            roles: role,
        };

        const filter: TableFilter = {
            sort: null,
            filter: searchFilter
        };

        return this.getUsers(0, filter);
    }

    getUser(id: number) {
        return this.httpClient.get<User>(`/api/v1/users/${id}`);
    }

    getUserByUsername(username: string) {
        const params = new HttpParams().set('username', username);
        return this.httpClient.get<User>(`/api/v1/users/findByUserName`, {params});
    }

    getUserProjects(id: number) {
        return this.httpClient.get<UserProject[]>(`/api/v1/users/${id}/projects`);
    }

    putUser(user: User) {
        const userId = user.id;

        return this.httpClient.put<User>(`/api/v1/users/${userId}`, user);
    }

    postUser(user: User) {
        return this.httpClient.post<User>('/api/v1/users', user);
    }

    exists(username: string): Observable<number> {
        const params = new HttpParams().set('username', username);

        return this.httpClient.get<number>(`api/v1/users/exists`, {params});
    }

    decoupleContentCode(id: number): Observable<any> {
        return this.httpClient.delete(`/api/v1/users/${id}/apple-content-code`);
    }
}
