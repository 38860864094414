// @ts-strict-ignore
import {Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges} from '@angular/core';
import {NavigationExtras, Router} from '@angular/router';
import {map} from 'rxjs/operators';
import {Observable, Subscription} from 'rxjs';
import {HistoryService} from "../../services/history.service";

@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html'
})
export class ToolbarComponent implements OnChanges, OnDestroy {
    @Input() toolbarTitle?: string;
    @Input() showAddBtn = false;
    @Input() showBackBtn = false;
    @Input() addBtnLabel = 'Nieuw';
    @Input() backBtnRoute: string | any[];
    @Input() backBtnExtras: NavigationExtras;
    @Input() addBtnRoute: string;

    @Input() goBack$: Observable<any>;

    @Output() add = new EventEmitter<void>();
    @Output() default = new EventEmitter<void>();
    @Output() back = new EventEmitter<void>();

    canGoHistoryBack$ = this.historyService.previousPage$.pipe(map(url => url !== null));

    goBackSubscription: Subscription;

    constructor(private router: Router, private historyService: HistoryService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.goBack$) {
            this.ngOnDestroy();
            this.goBackSubscription = (changes.goBack$.currentValue as Observable<any>).subscribe(_ => {
                this.onBack();
            });
        }
    }

    ngOnDestroy(): void {
        if (this.goBackSubscription) {
            this.goBackSubscription.unsubscribe();
        }
    }

    async onBack() {
        return this.historyService.goBack([this.backBtnRoute], this.backBtnExtras);
    }

    onAdd() {
        this.add.emit();

        if (this.addBtnRoute) {
            this.router.navigate([this.addBtnRoute]);
        }
    }
}
