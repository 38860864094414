// @ts-strict-ignore
import {AbstractCrudServiceMock} from './abstract-crud.service.mock';
import {FormItem, QuestionSetQuestion, QuestionType} from '../../models/form-item';
import {QuestionService} from '../question.service';
import {Observable} from 'rxjs';
import {Inject, Injectable} from '@angular/core';
import {FormTypeService} from '../form-type.service';
import {FormType, FormTypeApiEndpoint} from '../../models/form-type';
import {FormTypeServiceMock} from './form-type.service.mock';
import {QuestionSetServiceMock} from './question-set.service.mock';
import {QuestionSet} from '../../models/question-set';

@Injectable()
export class QuestionServiceMock extends AbstractCrudServiceMock<FormItem> implements QuestionService {

    constructor(
        @Inject('FormTypeService') private formTypeService: FormTypeServiceMock,
        @Inject('QuestionSetService') private questionSetService: QuestionSetServiceMock
    ) {
        super([]);
    }

    postQuestion(formTypeId: number, question: FormItem, endpoint: FormTypeApiEndpoint): Observable<FormItem> {
        if (endpoint === 'form-types') {
            this.formTypeService.getDetail(formTypeId).subscribe((formType: FormType) => {
                if (question.id === null) {
                    question.id = formType.formItems.length + 1;
                }

                if (question.type === QuestionType.questionSet) {
                    this.questionSetService.getQuestionSet(question.questionSet.id).subscribe((questionSet: QuestionSet) => {
                        const questionSetItem = question as QuestionSetQuestion;

                        questionSetItem.questionSet = questionSet;

                        formType.formItems.push(questionSetItem);
                    });
                } else {
                    formType.formItems.push(question);
                }
            });
        } else {
            this.questionSetService.getQuestionSet(formTypeId).subscribe((questionSet: QuestionSet) => {
                questionSet.formItems.push(question);
            });
        }

        if (question.type == QuestionType.choice && question.choices) {
            question.choices.forEach((choice) => {
                if (!choice.id) {
                    choice.id = this.nextChoiceId();
                }
            });
        }

        return this.post(question);
    }

    putQuestion(formTypeId: number, question: FormItem, endpoint: FormTypeApiEndpoint): Observable<FormItem> {
        if (endpoint === 'form-types') {
            this.formTypeService.getDetail(formTypeId).subscribe((formType: FormType) => {
                const existingQuestion = formType.formItems.find(currentFormItem => currentFormItem.id === question.id);

                Object.assign(existingQuestion, question);
            });
        } else {
            this.questionSetService.getQuestionSet(formTypeId).subscribe((questionSet: QuestionSet) => {
                const existingQuestion = questionSet.formItems.find(currentFormItem => currentFormItem.id === question.id);

                Object.assign(existingQuestion, question);
            });
        }

        return this.put(question);
    }

    deleteQuestion(formTypeId: number, question: FormItem, endpoint: FormTypeApiEndpoint): Observable<FormItem> {
        if (endpoint === 'form-types') {
            this.formTypeService.getDetail(formTypeId).subscribe((formType: FormType) => {
                formType.formItems = formType.formItems.filter(currentFormItem => currentFormItem.id !== question.id);
            });
        } else {
            this.questionSetService.getQuestionSet(formTypeId).subscribe((questionSet: QuestionSet) => {
                questionSet.formItems = questionSet.formItems.filter(currentFormItem => currentFormItem.id !== question.id);
            });
        }

        return this.delete(question);
    }

    private nextChoiceId() {
        return this.formTypeService.nextChoiceId();
    }
}
