// @ts-strict-ignore
import {Injectable} from '@angular/core';
import {AbstractCrudServiceMock} from './abstract-crud.service.mock';
import {Observable, of} from 'rxjs';
import {PageResponse} from '../../models/page-response';
import {TableFilter} from '../../components/table/table.component';
import {Company} from '../../models/company';
import {CompanyService} from '../company.service';

export const mockCompanies: Array<Company> = [
    {
        id: 1,
        name: 'VolkerWessels'
    },
    {
        id: 2,
        name: 'Recognize B.V.'
    }
];

@Injectable()
export class CompanyServiceMock extends AbstractCrudServiceMock<Company> implements CompanyService {
    constructor() {
        super(mockCompanies);
    }

    getCompanies(page: number, filter?: TableFilter): Observable<PageResponse<Company>> {
        return this.getAll(filter);
    }

    exists(name: string): Observable<number> {
        const result = mockCompanies.find(item => item.name === name);

        return of(result ? result.id : null);
    }

    getCompany(id: number): Observable<Company> {
        return this.get(id);
    }

    postCompany(company: Company): Observable<Company> {
        return this.post(company);
    }

    putCompany(company: Company): Observable<Company> {
        return this.put(company);
    }
}
