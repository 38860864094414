// @ts-strict-ignore
import {Directive, Inject, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {Subscription} from 'rxjs';
import {FeatureToggleCheckService} from '../services/feature-toggle-check.service';
import {FeatureToggleData, FeatureToggleName} from '../models/feature-toggle';

@Directive({
    selector: '[appHasFeatureToggle]'
})
export class HasFeatureToggleDirective implements OnInit, OnDestroy {
    @Input('appHasFeatureToggle') featureTogglename: FeatureToggleName|FeatureToggleName[];

    private featureTogglesChangedSubscription: Subscription;

    constructor(
        @Inject('FeatureToggleCheckService') private featureToggleCheckService: FeatureToggleCheckService,
        protected templateRef: TemplateRef<any>,
        protected viewContainer: ViewContainerRef
    ) {}

    ngOnInit(): void {
        this.featureTogglesChangedSubscription = this.featureToggleCheckService
            .featureToggles()
            .subscribe((featureToggles) => this.rerender(featureToggles));
    }

    ngOnDestroy(): void {
        if (this.featureTogglesChangedSubscription) {
            this.featureTogglesChangedSubscription.unsubscribe();
        }
    }

    private rerender(featureToggles: FeatureToggleData[]) {
        let isEnabled = true;

        if (this.featureTogglename !== null && this.featureTogglename !== undefined) {
            if (!Array.isArray(this.featureTogglename)) {
                this.featureTogglename = [this.featureTogglename];
            }

            isEnabled = this.featureTogglename.some(featureToggle => {
                return FeatureToggleCheckService.isEnabledForName(featureToggles, featureToggle);
            });
        }

        if (isEnabled) {
            this.viewContainer.clear();
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }
}
