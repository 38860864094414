// @ts-strict-ignore
import {Component, Inject, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject, combineLatest, firstValueFrom, Observable, of, skip} from 'rxjs';
import {HeaderTitle, TableFilter} from '../../components/table/table.component';
import {Filters} from '../../utils/filters';
import {ActionMenuComponent} from '../../vwui/action-menu/action-menu.component';
import {ProjectJobService} from '../../services/project-job.service';
import {ProjectJob, ProjectJobStatus} from '../../models/project-job';
import {map, switchMap, take, tap} from 'rxjs/operators';
import {AbstractListComponent} from '../abstract-list.component';
import {PageResponse} from '../../models/page-response';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ToastrService} from 'ngx-toastr';
import {ProjectJobModalComponent} from '../../components/project-job-modal/project-job-modal.component';
import {Project} from '../../models/project';
import {JobExportService} from '../../services/job-export.service';
import {FileUtil} from '../../utils/file';
import {HttpErrorResponse} from '@angular/common/http';
import {LoadingBtnEvent} from '../../components/loading-btn/loading-btn.interface';
import {FileUploadEvent} from '../../components/file-upload-btn/file-upload.interface';
import {ProjectSelectModalComponent} from '../../components/project-select-modal/project-select-modal.component';
import {MultiSelectTableComponent} from '../../components/multi-select-table/multi-select-table.component';
import {TranslateService} from '@ngx-translate/core';
import {
    ProjectJobRejectModalComponent
} from '../../components/project-job-reject-modal/project-job-reject-modal.component';
import {FeatureToggle} from '../../models/feature-toggle';
import {
    ProjectJobCloneModalComponent
} from '../../components/project-job-clone-modal/project-job-clone-modal.component';

interface JobStatusFilter {
    title: string;
    status: ProjectJobStatus;
    count: number;
    featureToggle: FeatureToggle | null
}

@Component({
    selector: 'app-project-job-list',
    templateUrl: './project-job-list.component.html'
})
export class ProjectJobListComponent extends AbstractListComponent<ProjectJob> {
    @ViewChild('actionmenu') actionMenu: ActionMenuComponent;
    @ViewChild('multiSelectTable') multiSelectTable: MultiSelectTableComponent<ProjectJob>;
    @ViewChild('jobTable') jobTable: MultiSelectTableComponent<ProjectJob>|undefined;

    private lastTableFilter: TableFilter;
    private bsModalRef: BsModalRef;
    private selectedProject: Project;

    downloadingFiles: ProjectJob | false = false;
    multipleActionPending: boolean = false;

    activeFilter$ = new BehaviorSubject<ProjectJobStatus>(
        this.activatedRoute.snapshot.queryParams['filter-status'] || ProjectJobStatus.Concept
    );
    tableHeaders$: Observable<HeaderTitle[]> = this.activeFilter$.pipe(
        map(activeFilter => [
            {
                sortKey: 'title',
                filterKey: 'title',
                title: 'Opdrachtnaam',
                type: 'text',
            },
            {
                sortKey: 'code',
                filterKey: 'code',
                title: 'Opdrachtnummer',
                type: 'text',
            },
            {
                sortKey: 'paulaObject.objectOmschrijvingKort',
                filterKey: 'paulaObject.objectOmschrijvingKort',
                title: 'Object',
                type: 'text',
            },
            {
                sortKey: 'project.name',
                filterKey: 'project.name',
                title: 'Project',
                type: 'text',
            },
            {
                sortKey: 'inspectionDate',
                filterKey: 'inspectionDate',
                title: 'Keuringsdatum',
                type: 'date',
                visible: this.shouldShowInspectionDateColumn(activeFilter)
            },
            {
                sortKey: 'answers.createdAt',
                filterKey: 'createdAt',
                title: 'Keuringsdatum',
                type: 'date',
                visible: this.shouldUseAnswersForInspectionDateColumn(activeFilter)
            },
            {
                sortKey: 'answers.createdBy',
                filterKey: 'answers.createdBy',
                title: 'Keurder',
                type: 'text',
                visible: this.shouldShowInspectorColumn(activeFilter),
                minCharacters: 3
            },
            {
                sortKey: 'deadline',
                filterKey: 'deadline',
                title: 'Hersteltermijn',
                type: 'date',
                visible: this.shouldShowConceptColumns(activeFilter)
            },
        ])
    );

    jobStatusFilters: JobStatusFilter[] = [
        {title: this.translateService.instant('Concept'), status: ProjectJobStatus.Concept, count: 0, featureToggle: null},
        {
            title: this.translateService.instant('Voor uitvoering'),
            status: ProjectJobStatus.AvailableForInspection,
            count: 0,
            featureToggle: null
        },
        {title: this.translateService.instant('In uitvoering'), status: ProjectJobStatus.InProgress, count: 0, featureToggle: null},
        {
            title: this.translateService.instant('Voor verificatie'),
            status: ProjectJobStatus.AvailableForVerification,
            count: 0,
            featureToggle: null
        },
        {title: this.translateService.instant('Obstructie'), status: ProjectJobStatus.Obstructed, count: 0, featureToggle: FeatureToggle.ObstructionCodes},
        {title: this.translateService.instant('Goedgekeurd'), status: ProjectJobStatus.Approved, count: 0, featureToggle: null},
        {title: this.translateService.instant('Afgekeurd'), status: ProjectJobStatus.Rejected, count: 0, featureToggle: null},
        {
            title: this.translateService.instant('Definitief afgekeurd'),
            status: ProjectJobStatus.DefinitivelyRejected,
            count: 0,
            featureToggle: null
        },
        {title: this.translateService.instant('Vervallen'), status: ProjectJobStatus.Archived, count: 0, featureToggle: null}
    ];

    constructor(
        @Inject('ProjectJobService') private projectJobService: ProjectJobService,
        private jobExportService: JobExportService,
        protected activatedRoute: ActivatedRoute,
        protected router: Router,
        private modalService: BsModalService,
        private toast: ToastrService,
        private translateService: TranslateService,
    ) {
        super(activatedRoute, router);
        activatedRoute.queryParams.subscribe(params => {
            if (!params['filter-status']) {
                this.setJobStatusFilter(ProjectJobStatus.Concept);
            }
        });

        // Skip the first, it will swallow the page
        this.activeFilter$.pipe(skip(1)).subscribe(activeFilter => {
            this.setJobStatusFilter(activeFilter);
        });
    }

    async onJobClick(job: ProjectJob) {
        await this.router.navigate(['/beheer/project-jobs/', job.id], {
            queryParams: Filters.tableFilterToQueryParamsObject(await firstValueFrom(this.tableFilter$))
        });
    }

    loadData(currPage: number, currFilter: TableFilter): Observable<PageResponse<ProjectJob>> {
        // Applies minCharacters by setting the filter to an empty string when the min characters condition is not met
        return combineLatest([of({page: currPage, filter: currFilter}), firstValueFrom(this.tableHeaders$)]).pipe(
            map(([pageAndFilter, tableHeaders]) => {
                tableHeaders.forEach(tableHeader => {
                    if (
                        tableHeader.minCharacters !== undefined &&
                        pageAndFilter.filter.filter[tableHeader.filterKey] !== undefined &&
                        ('' + pageAndFilter.filter.filter[tableHeader.filterKey]).length < tableHeader.minCharacters
                    ) {
                        pageAndFilter.filter.filter[tableHeader.filterKey] = '';
                    }
                });

                return pageAndFilter;
            }),
            tap(({filter}) => this.loadJobStatusCounters(filter)),
            switchMap(({page, filter}) => {
                return this.projectJobService.getList(page, filter);
            })
        );
    }

    shouldShowInspectorColumn(activeFilter: ProjectJobStatus): boolean {
        return activeFilter === ProjectJobStatus.Approved
            || activeFilter === ProjectJobStatus.Rejected
            || activeFilter === ProjectJobStatus.DefinitivelyRejected
            || activeFilter === ProjectJobStatus.AvailableForVerification
            || activeFilter === ProjectJobStatus.InProgress;
    }

    shouldShowInspectionDateColumn(activeFilter: ProjectJobStatus): boolean {
        return activeFilter === ProjectJobStatus.Approved
            || activeFilter === ProjectJobStatus.Rejected
            || activeFilter === ProjectJobStatus.AvailableForVerification
            || activeFilter === ProjectJobStatus.DefinitivelyRejected;
    }

    shouldUseAnswersForInspectionDateColumn(activeFilter: ProjectJobStatus): boolean {
        return activeFilter === ProjectJobStatus.InProgress;
    }

    shouldShowConceptColumns(activeFilter: ProjectJobStatus): boolean {
        return activeFilter === ProjectJobStatus.Concept;
    }

    getLastAnswerDate(job: ProjectJob) {
        if (job.answers.length === 0) {
            return null;
        }
        return job.answers.reduce((latestAnswer, answer) => {
            return answer.createdAt > latestAnswer.createdAt ? answer : latestAnswer;
        }).createdAt;
    }

    onTableFilterChange(tableFilter: TableFilter) {
        this.lastTableFilter = tableFilter;
        super.onTableFilterChange({
            sort: tableFilter.sort,
            filter: {...tableFilter.filter, status: this.activeFilter$.value}
        });
    }

    selectFilter(status: ProjectJobStatus) {
        this.activeFilter$.next(status);
    }

    openAddJobModal() {
        this.bsModalRef = this.modalService.show(ProjectJobModalComponent, {
            class: 'modal-dialog-centered',
            initialState: {
                project: this.selectedProject,
                onClose: (result) => {
                    if (result === 'confirmed') {
                        this.refresh();
                        this.toast.success('Opdracht aangemaakt');
                    }

                    if (result === 'error') {
                        this.toast.error('Toevoegen niet gelukt');
                    }
                }
            },
        });
    }

    openSelectMapProjectModal() {
        const modalRef = this.modalService.show(ProjectSelectModalComponent, {
            class: 'modal-dialog-centered',
            initialState: {
                project: this.selectedProject,
                onClose: (result, data) => {
                    if (result === 'confirmed') {
                        modalRef.hide();
                        this.router.navigate(['beheer', 'projects', data.id, 'map']);
                    }
                }
            },
        });
    }

    async rejectJob(event: Event, items: ProjectJob[]) {
        if (FeatureToggle.ProjectJobShowRejectReason) {
            await this.openRejectModal(event, items);
        } else {
            await this.transitionMultipleTo(event, items, 'Rejected');
        }
    }

    async openRejectModal(event: Event, items: ProjectJob[]) {
        const modal = this.modalService.show(ProjectJobRejectModalComponent, {
            class: 'modal-dialog-centered'
        });
        const projectJobRejectModal = modal.content as ProjectJobRejectModalComponent;

        this.modalService.onHidden.pipe(
            take(1)
        ).subscribe(async () => {
            if (projectJobRejectModal.result === 'confirmed') {
                const reason = projectJobRejectModal.form.get('projectJobRejectGroup').value;
                await this.transitionMultipleTo(event, items, 'Rejected', reason !== '' ? reason : null);
            }
        });
    }

    /**
     * status type is 'ProjectJobStatus | string' because enums cannot be used in the templates.
     */
    async transitionTo(event: Event, item: ProjectJob, status: ProjectJobStatus | string, comment?: string) {
        event.stopPropagation();

        try {
            await firstValueFrom(this.projectJobService.transition(item, status as ProjectJobStatus, comment));
        } catch(r) {
            const errorMessage = this.translateService.instant(r.error.message);
            return this.toast.error(errorMessage);
        }

        await this.refresh();
    }

    async transitionMultipleTo(event: Event, items: ProjectJob[], status: ProjectJobStatus | string, comment?: string) {
        try {
            this.multipleActionPending = true;

            const failedTransitions = [];
            for (const item of items) {
                try {
                    await this.transitionTo(event, item, status, comment);
                    this.multiSelectTable.setItemSelected(false, item);
                } catch (error) {
                    console.error(error);
                    failedTransitions.push(item.title);
                }
            }
            if (failedTransitions.length > 0) {
                this.toast.error('The following projects could not be updated:' + failedTransitions.join(', '));
            }
            this.refresh();
        } finally {
            this.multipleActionPending = false;
        }
    }

    async resendJobExport(item: ProjectJob) {
        try {
            await this.projectJobService.resendExport(item).toPromise();
            this.toast.success(this.translateService.instant('job.export.resend.success'));
        } catch (error) {
            if (error instanceof HttpErrorResponse) {
                let errorMessage = this.translateService.instant('Exporteren van de opdracht is mislukt.');
                if (error.error && error.error.message) {
                    const translated = this.translateService.instant(error.error.message);
                    if (translated !== error.error.message) {
                        errorMessage += '\n' + translated;
                    }
                }

                this.toast.error(errorMessage);
            }
        }
    }

    async exportJob(item: ProjectJob) {
        this.downloadingFiles = item;

        // export as blob
        try {
            const httpResponse = await this.jobExportService.downloadJobExport(item.id).toPromise();
            const contentDisposition = httpResponse.headers.get('content-disposition');
            const fileName = contentDisposition.split('filename=')[1];

            FileUtil.downloadBlobAsFile(httpResponse.body, `${fileName.slice(1, fileName.length - 1)}`);
        } catch (error) {
            if (error instanceof HttpErrorResponse) {
                this.toast.error(this.translateService.instant('Exporteren van de opdracht is mislukt.'));
            }
        } finally {
            this.downloadingFiles = false;
        }
    }

    async exportMultipleJobs(items: ProjectJob[]) {
        // exports as blob
        try {
            this.multipleActionPending = true;

            const httpResponse = await firstValueFrom(this.jobExportService.downloadMultipleJobsExport(items.map(it => it.id)));
            const contentDisposition = httpResponse.headers.get('content-disposition');
            const fileName = contentDisposition.split('filename=')[1];

            FileUtil.downloadBlobAsFile(httpResponse.body, `${fileName.slice(1, fileName.length - 1)}`);
        } catch (error) {
            if (error instanceof HttpErrorResponse) {
                this.toast.error(this.translateService.instant('Exporteren van opdrachten is mislukt.'));
            }
        } finally {
            this.multipleActionPending = false;
        }
    }

    private setJobStatusFilter(status: ProjectJobStatus) {
        const queryParams = this.activatedRoute.snapshot.queryParams;
        const filter = Filters.getFilteringFromParams(queryParams);

        if (this.jobTable && filter.filter.status !== status) {
            this.jobTable.resetSelection();
        }
        filter.filter.status = status;

        this.onTableFilterChange(filter);
    }

    private async loadJobStatusCounters(filter: TableFilter) {
        const projectJobCount = await firstValueFrom(this.projectJobService.getCounters(filter));
        this.jobStatusFilters.forEach(it => {
            it.count = projectJobCount[it.status];
        });
    }

    async exportJobs(event: LoadingBtnEvent) {
        try {
            const {filename, data} = await this.projectJobService.exportJobs(this.lastTableFilter).toPromise();
            FileUtil.downloadBlobAsFile(data, filename);
        } finally {
            event.complete();
        }
    }

    async onFileChange(event: FileUploadEvent) {
        try {
            await this.projectJobService.importJobs(event.target.files[0]).toPromise();
            this.toast.success('De opdrachten zijn succesvol geïmporteerd.');
            await this.refresh();
        } catch (e) {
            console.error('Failed to import objects', e);
            if (e instanceof HttpErrorResponse) {
                if (e.error && e.error.fieldErrors) {
                    const fieldErrors = e.error.fieldErrors;
                    if (fieldErrors.cell) {
                        this.toast.error(
                            `Het importeren van opdrachten is mislukt, fout in cell ${fieldErrors.cell}`
                            + `, ongeldige waarde: ${fieldErrors.value}`
                        );
                        return;
                    }
                    if (fieldErrors.row) {
                        this.toast.error(`Het importeren van opdrachten is mislukt, fout in rij ${fieldErrors.row}`);
                        return;
                    }
                }
            }

            this.toast.error('Het importeren van opdrachten is mislukt');
        } finally {
            event.target.value = '';
            event.complete();
        }
    }

    openCloneJobModal(job: ProjectJob) {
        if (this.isMaximoOrViseJob(job)) {
            this.showCopyErrorToast();
            return;
        }

        this.bsModalRef = this.modalService.show(ProjectJobCloneModalComponent, {
            class: 'modal-dialog-centered',
            initialState: {
                projectJob: job,
                onClose: (result) => {
                    if (result === 'confirmed') {
                        this.refresh();
                        this.toast.success('Opdracht gekopieerd');
                    }

                    if (result === 'error') {
                        this.toast.error(' Kopiëren niet gelukt');
                    }
                }
            },
        });
    }

    showCopyErrorToast() {
        this.toast.error('Deze opdracht is gekoppeld aan Vise of Maximo en kan daarom niet gekopieerd worden.')
    }

    isMaximoOrViseJob(job: ProjectJob): boolean {
        return !!(job.extraFields.maximoReference
            || job.extraFields.maximoParent
            || job.extraFields.maximoLead
            || job.extraFields.maximoSiteId
            || job.extraFields.maximoWolo4
            || job.extraFields.talendWid);
    }
}
