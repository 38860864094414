import {Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {GraphService} from '../graph.service';
import {GraphUser} from '../../models/graph-user';
import {Graph} from '../../models/graph';
import {UserRoles} from '../../models/user';

export const mockGraphs: Graph[] = [
    {
        id: 1,
        title: 'VWInfra'
    }
];

export const mockUsers: Array<GraphUser> = [
    {
        username: 'piet@recognize.nl',
        displayName: 'Piet Pietjes',
        companyName: 'Recognize',
        mail: 'piet@recognize.nl',
        userType: 'Guest',
        tenantId: 'dummy',
        roles: [
            UserRoles.RoleExecutor,
            UserRoles.RoleCoordinator
        ]
    },
    {
        username: 'henk@recognize.nl',
        displayName: 'Henk de Boer',
        companyName: 'Recognize',
        mail: 'henk@recognize.nl',
        userType: 'Guest',
        tenantId: 'dummy',
        roles: [
            UserRoles.RoleExecutor,
            UserRoles.RoleCoordinator
        ]
    }
];

@Injectable()
export class GraphServiceMock implements GraphService {
    data = mockUsers;
    graphData = mockGraphs;

    getUsers(): Observable<GraphUser[]> {
        return of(this.data);
    }

    getGraphs(): Observable<Graph[]> {
        return of(this.graphData);
    }
}
