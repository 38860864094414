// @ts-strict-ignore
import {AuthenticationProviderConfigService} from '../authentication-provider-config.service';
import {Observable, of} from 'rxjs';
import {PageResponse} from '../../models/page-response';
import {AuthenticationProviderConfig} from '../../models/authentication-provider-config';
import {AbstractCrudServiceMock} from './abstract-crud.service.mock';
import {Injectable} from '@angular/core';

export const mockRegistrations: Array<AuthenticationProviderConfig> = [
    {
        type: 'azure',
        id: 2,
        title: 'VWInfra',
        tenantId: 'cdc477bf-b6e3-4345-b1be-3b225394e17e',
        clientId: 'cdc477bf-b6e3-4345-b1be-3b225394e17e',
        secret: 'supergeheimsecret',
        loginTenantId: 'cdc477bf-b6e3-4345-b1be-3b225394e17e',
        loginClientId: 'cdc477bf-b6e3-4345-b1be-3b225394e17e',
        loginSecret: 'supergeheimsecret',
        brandingTextColor: '#000000',
        brandingFirstColor: '#ffffff',
        brandingSecondColor: '#ffffff',
        defaultProject: null,
        consentGranted: true
    }
];

@Injectable()
export class AuthenticationProviderConfigServiceMock extends AbstractCrudServiceMock<AuthenticationProviderConfig>
    implements AuthenticationProviderConfigService {

    constructor() {
        super(mockRegistrations);
    }

    getRegistrations(page: number): Observable<PageResponse<AuthenticationProviderConfig>> {
        return this.getAll();
    }

    getRegistration(id: number) {
        return this.get(id);
    }

    putRegistration(registration: AuthenticationProviderConfig): Observable<AuthenticationProviderConfig> {
        return this.put(registration);
    }

    postRegistration(registration: AuthenticationProviderConfig) {
        return this.post(registration);
    }

    exists(tenantId: string) {
        const result = mockRegistrations.find(item => item.type === 'azure' && item.tenantId === tenantId);

        return of(result ? result.id : null);
    }
}
