// @ts-strict-ignore
import {ChangeDetectorRef, Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {BsModalService} from 'ngx-bootstrap/modal';
import {recoveryTimes} from '../../models/recovery-time';
import {FeatureToggle} from '../../models/feature-toggle';
import {
    FollowUpNumberQuestionModalComponent
} from "../follow-up-number-question-modal/follow-up-number-question-modal.component";
import {
    determineFollowUpType,
    followUpChoices,
    NumberQuestionFollowUp,
    NumberQuestionFollowUpType
} from "../../models/number-question-follow-up";
import {Project} from "../../models/project";

@Component({
    selector: 'app-follow-up-number-question',
    templateUrl: './question-follow-up-number-question.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => QuestionFollowUpNumberQuestionComponent),
            multi: true
        }
    ]
})
export class QuestionFollowUpNumberQuestionComponent implements ControlValueAccessor {
    private onChange = (value: NumberQuestionFollowUp[]) => {}
    private onTouched = () => {}

    @Input()
    project: Project | null = null;

    followUps: NumberQuestionFollowUp[] = [];

    readonly followUpChoiceControl = new FormControl<NumberQuestionFollowUpType>(null);
    readonly followUpChoices = followUpChoices
    readonly recoveryTimes = recoveryTimes;
    readonly maximoToggle = FeatureToggle.Maximo;

    constructor(
        private modalService: BsModalService,
        private changeDetectorRef: ChangeDetectorRef,
    ) {
    }

    configureNumberQuestionFollowup(type: NumberQuestionFollowUpType) {
        this.modalService.show(FollowUpNumberQuestionModalComponent, {
            class: 'modal-dialog-centered',
            initialState: {
                type,
                project: this.project,
                onClose: (result, value) => {
                    if (result === 'confirmed') {
                        this.followUps.push(value)
                        this.emitValueChanged()
                    }
                }
            }
        })
        this.followUpChoiceControl.setValue(null)
    }

    updateFollowUp(item: NumberQuestionFollowUp) {
        this.modalService.show(FollowUpNumberQuestionModalComponent, {
            class: 'modal-dialog-centered',
            initialState: {
                formValue: item,
                project: this.project,
                onClose: (result, value) => {
                    if (result === 'confirmed') {
                        const oldIndex = this.followUps.indexOf(item)
                        if (oldIndex !== -1) {
                            this.followUps[oldIndex] = value;
                        } else {
                            this.followUps.push(value)
                        }

                        this.emitValueChanged()
                    }
                }
            }
        })
    }

    deleteFollowUp(item: NumberQuestionFollowUp) {
        const index = this.followUps.indexOf(item);
        if (index !== -1) {
            this.followUps.splice(index, 1);
        } else {
            console.error('Tried to delete item that is not in followUps list', {item, followUps: this.followUps});
        }

        this.emitValueChanged()
    }

    private emitValueChanged() {
        this.onChange(this.followUps);
        this.onTouched();
        this.changeDetectorRef.markForCheck();
    }

    registerOnChange(fn: (value: NumberQuestionFollowUp[]) => void): void {
        this.onChange = fn
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn
    }

    writeValue(value: NumberQuestionFollowUp[]): void {
        this.followUps = value
    }

    followUpLabel(item: NumberQuestionFollowUp): string {
        const type = determineFollowUpType(item);
        return followUpChoices.find(choice => choice.type == type).label
            .replace('min', String(item.greaterThan))
            .replace('max', String(item.lessThan))
    }
}
