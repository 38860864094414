import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable()
export class BlobService {
    constructor(private http: HttpClient) {
    }

    getBlob(uuid: string): Observable<Blob> {
        return this.http.get(`/blob/${uuid}`, {responseType: 'blob'});
    }
}
