<app-toolbar
    [showAddBtn]="true"
    [addBtnRoute]="'/beheer/workergroups/new'"
    addBtnLabel="{{'Nieuwe medewerkersgroep'|translate}}">
</app-toolbar>
<div class="vwui-container">
    <app-table
        *ngIf="paginator$|async;let paginator" [paginator]="paginator"
        [headerTitles]="tableHeaders"
        [enableContextMenu]="true"
        (filterChanged)="onTableFilterChange($event)">
        <ng-template let-item>
            <tr [routerLink]="['/beheer/workergroups/', item.id]">
                <td>{{ item.name }}</td>
                <td>{{ item.workerGroupUsers && item.workerGroupUsers.length }}</td>
                <td class="vwui-table__fixed-column-width vwui-multi-select-table--unclickable">
                    <div class="vwui-table__actions">
                        <app-action-menu #actionMenu>
                            <button class="vwui-menu-item" [routerLink]="['/beheer/workergroups/', item.id]">
                                <app-icon iconName="edit-2"></app-icon>
                                {{'Bewerken'|translate}}
                            </button>
                            <button *ngIf="item.projectCount === 0" class="vwui-menu-item is-danger" (click)="onRemove($event, item.id)">
                                <app-icon iconName="trash-2"></app-icon>
                                {{'Verwijderen'|translate}}
                            </button>
                        </app-action-menu>
                    </div>
                </td>
            </tr>
        </ng-template>
    </app-table>
</div>
