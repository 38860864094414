import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {AsideService} from '../../vwui/aside/aside.service';
import {AbstractForm, FormType, FormTypeApiEndpoint, FormTypes} from '../../models/form-type';
import {ChapterFormItem, FormItem, QuestionSetQuestion, QuestionType, SelectedFormItem} from '../../models/form-item';
import {firstValueFrom, Subscription} from 'rxjs';
import {QuestionService} from '../../services/question.service';
import {ToastrService} from 'ngx-toastr';
import {FormTypeService} from '../../services/form-type.service';
import {Forms} from '../../utils/forms';
import {QuestionSetService} from '../../services/question-set.service';
import {QuestionSet} from '../../models/question-set';
import {environment} from '../../../environments/environment';
import {BsModalService} from 'ngx-bootstrap/modal';
import {DuplicateFormItemModalComponent} from '../../components/duplicate-form-item-modal/duplicate-form-item-modal.component';
import {FormCloneUtils} from '../../utils/form-clone-utils';
import {FormUtils} from '../../utils/form-utils';
import {FormBuilderService} from '../../services/form-builder.service';
import {v4 as uuid} from 'uuid';
import {ModalResult} from "../../models/modal.result";

@Component({
    selector: 'app-form-builder',
    templateUrl: './form-builder.component.html'
})
export class FormBuilderComponent implements OnInit, OnDestroy {
    formType: AbstractForm | null = null;
    formItems: FormItem[] = [];

    selectedFormItem: SelectedFormItem | null = null;

    questionSetsVisible = false;

    routerEventSubscription: Subscription | null = null;
    routeDataSubscription: Subscription | null = null;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private asideService: AsideService,
        private modalService: BsModalService,
        @Inject('FormTypeService') private formTypeService: FormTypeService,
        @Inject('QuestionSetService') private questionSetService: QuestionSetService,
        @Inject('QuestionService') private questionService: QuestionService,
        private toast: ToastrService,
        private formBuilderService: FormBuilderService,
    ) {}

    ngOnInit() {
        if (environment.useMock) {
            // This must be used for the E2E tests
            this.routerEventSubscription = this.router.events.subscribe(routerEvent => {
                if (this.routeDataSubscription) {
                    this.routeDataSubscription.unsubscribe();
                }

                if (routerEvent instanceof NavigationEnd) {
                    this.initRouteDataSubscription();
                    this.formBuilderService.initFormBuilder(this.formItems);
                }
            });
        }
        this.initRouteDataSubscription();
        this.formBuilderService.initFormBuilder(this.formItems);
    }

    private initRouteDataSubscription() {
        this.routeDataSubscription = this.route.data.subscribe((data) => {
            if (data.formType) {
                this.formType = data.formType;
                this.formItems = Forms.convertToChapterTree(data.formType) as FormItem[];
            }

            if (data.questionSet) {
                this.formType = data.questionSet;
                this.formItems = Forms.convertToChapterTree(data.questionSet) as FormItem[];
            }
        });
    }

    get formTitle() {
        if (this.formType) {
            return this.formType.title;
        }
    }

    get isFormType() {
        return this.formType && this.formType.type === FormTypes.FormType;
    }

    get isQuestionSet() {
        return this.formType && this.formType.type === FormTypes.QuestionSet;
    }

    get isProjectForm() {
        return this.formType && this.formType.type === FormTypes.ProjectForm;
    }

    get apiEndpoint() {
        if (this.isQuestionSet) {
            return FormTypeApiEndpoint.QuestionSet;
        }

        if (this.isProjectForm) {
            return FormTypeApiEndpoint.ProjectForm;
        }

        return FormTypeApiEndpoint.FormType;
    }

    get questionSetsSelected(): QuestionSetQuestion[] {
        return this.formItems.filter((formItem): formItem is QuestionSetQuestion => {
            return formItem.type === QuestionType.questionSet;
        });
    }

    get allowAddQuestionSets() {
        return this.isFormType;
    }

    isChapterFormItem(formItem: FormItem) {
        return formItem.type === QuestionType.chapter;
    }

    isQuestionSetFormItem(formItem: FormItem) {
        return formItem.type === QuestionType.questionSet;
    }

    ngOnDestroy() {
        if (this.routeDataSubscription) {
            this.routeDataSubscription.unsubscribe();
        }

        if (this.routerEventSubscription) {
            this.routerEventSubscription.unsubscribe();
        }
    }

    addFormItem(type: 'chapter'|'question'|'questionSet', previousFormItem: FormItem) {
        this.closeAside();

        const previousPosition = previousFormItem ? previousFormItem.position : 0;
        const possibleNextPosition = type === 'question'
            ? this.getLatestPosition(previousFormItem)
            : (Math.floor(previousPosition / 1000) + 1) * 1000;

        this.selectedFormItem = {
            type,
            previousFormItem,
            possibleDependencies: FormUtils.getPossibleDependencies(possibleNextPosition, this.formItems)
        };

        if (type === 'questionSet') {
            this.showQuestionSets();
        } else {
            this.openAside();
        }
    }

    selectFormItem(type: 'chapter'|'question', formItem: FormItem, previousFormItem: FormItem) {
        this.selectedFormItem = {
            type,
            formItem,
            previousFormItem,
            possibleDependencies: FormUtils.getPossibleDependencies(formItem.position, this.formItems)
        };

        this.openAside();
    }

    duplicateFormItem(type: 'chapter' | 'question', formItem: FormItem) {
        const modalRef = this.modalService.show(DuplicateFormItemModalComponent, {
            initialState: {
                formItem,
                formItems: this.formItems,
                onClose: async (result: ModalResult, destinationChapter: ChapterFormItem) => {
                    if (result === 'confirmed') {
                        modalRef.hide();
                        try {
                            if (type === 'chapter') {
                                formItem.reference = uuid();
                                (formItem as ChapterFormItem)?.children?.forEach(question => question.reference = uuid());
                                await this.cloneChapter(formItem, destinationChapter);
                            } else {
                                formItem.reference = uuid();
                                await this.cloneQuestion(formItem, destinationChapter);
                            }

                            this.toast.success('Opgeslagen');
                        } catch (e) {
                            this.toast.error('Kopieren mislukt');
                            console.error('Duplicate failed', e);
                        } finally {
                            this.refresh();
                        }
                    }
                }
            }
        });
    }

    isChapterSelected(chapter: FormItem) {
        if (this.selectedFormItem && this.selectedFormItem.formItem) {
            return this.selectedFormItem.formItem.id === chapter.id;
        } else {
            return false;
        }
    }

    getLatestPosition(previousFormItem?: FormItem) {
        if (previousFormItem) {
            return previousFormItem.position + 999;
        } else {
            return this.formItems.length > 0 ? this.formItems[this.formItems.length - 1].position + 1000 : 1000;
        }
    }

    async saveFormItem(formItem: FormItem) {
        if (!this.formType) {
            console.error('saveFormItem failed, this.formType is null')
            return
        }
        if (!this.selectedFormItem) {
            console.error('saveFormItem failed, this.selectedFormItem is null')
            return
        }

        try {
            if (formItem.id) {
                await firstValueFrom(this.questionService.putQuestion(this.formType.id, formItem, this.apiEndpoint));
            } else {
                if (formItem.type === QuestionType.chapter) {
                    if (this.selectedFormItem.previousFormItem) {
                        formItem.position = this.getLatestPosition(this.selectedFormItem.previousFormItem);
                    } else {
                        formItem.position = this.getLatestPosition();
                    }
                } else {
                    const formItemChapter = this.selectedFormItem.previousFormItem as ChapterFormItem;

                    formItem.position = ((formItemChapter.children?.length || 0) + 1) + formItemChapter.position;
                }

                await firstValueFrom(this.questionService.postQuestion(this.formType?.id, formItem, this.apiEndpoint));
            }

            this.toast.success('Opgeslagen');
            this.closeAside();
            this.refresh();
        } catch (error) {
            console.error(error);
            this.toast.error('Opslaan mislukt');
        }
    }

    async saveQuestionSetItem(questionSet: QuestionSet) {
        if (!this.formType) {
            console.error('saveQuestionSetItem failed, this.formType is null')
            return
        }
        if (!this.selectedFormItem) {
            console.error('saveQuestionSetItem failed, this.selectedFormItem is null')
            return
        }

        const item: Omit<QuestionSetQuestion, 'id'> = {
            title: questionSet.title,
            description: '',
            imageDescription: null,
            type: QuestionType.questionSet,
            position: this.getLatestPosition(this.selectedFormItem.previousFormItem),
            questionSet: { ...questionSet },
            reference: null
        };

        try {
            await firstValueFrom(this.questionService.postQuestion(this.formType.id, item, this.apiEndpoint));

            this.hideQuestionSets();
        } catch (error) {
            console.error('addQuestionSetItem error', error);
        } finally {
            this.refresh();
        }
    }

    async onQuestionsSort(chapter: FormItem, formItems: FormItem[]) {
        const currentChapter = chapter as ChapterFormItem;
        const currentQuestions = currentChapter.children || [];

        if (!this.formType) {
            console.error('onQuestionsSort failed, this.formType is null')
            return
        }
        const currentFormType = this.formType;

        try {
            const promises: Promise<unknown>[] = [];

            formItems.forEach(formItem => {
                formItem.position = formItem.position + chapter.position;

                promises.push(
                    firstValueFrom(this.questionService.putQuestion(currentFormType.id, formItem, this.apiEndpoint))
                );
            });

            await Promise.all(promises);
        } catch (error) {
            currentFormType.formItems = currentQuestions;

            console.error('onQuestionSort error', error);
            this.toast.error('Vragen sorteren mislukt');
        }
    }

    async deleteFormItem(formItem: FormItem) {
        try {
            if (!this.formType) {
                console.error('deleteFormItem failed, this.formType is null')
                return
            }
            const currentFormType = this.formType;

            const promises = [];

            if (formItem.type === QuestionType.chapter) {
                formItem?.children?.forEach(child => {
                    promises.push(firstValueFrom(this.questionService.deleteQuestion(currentFormType.id, child, this.apiEndpoint)));
                });
            }

            promises.push(firstValueFrom(this.questionService.deleteQuestion(currentFormType.id, formItem, this.apiEndpoint)));

            await Promise.all(promises);

            this.closeAside();
            this.refresh();
        } catch (error) {
            console.error('deleteFormItem error', error);
            this.toast.error('Verwijderen mislukt');
        }
    }

    openAside() {
        this.asideService.open();
    }

    closeAside() {
        this.selectedFormItem = null;
        this.asideService.close();
    }

    showQuestionSets() {
        window.scrollTo(0, 0);
        this.questionSetsVisible = true;
        this.asideService.close();
    }

    hideQuestionSets() {
        this.questionSetsVisible = false;
    }

    private refresh() {
        this.selectedFormItem = null;

        this.router.navigate([this.router.url]);
    }

    private async cloneQuestion(formItem: FormItem, destinationChapter: ChapterFormItem) {
        if (!this.formType) {
            console.error('cloneQuestion failed, this.formType is null')
            return
        }

        const cloned = await FormCloneUtils.cloneFormItem(
            formItem,
            ((destinationChapter?.children?.length || 0) + 1) + destinationChapter.position
        );
        await firstValueFrom(this.questionService.postQuestion(
            this.formType.id, cloned, this.apiEndpoint
        ));
        this.refresh();
    }

    private async cloneChapter(formItem: FormItem, destinationChapter: ChapterFormItem) {
        if (!this.formType) {
            console.error('cloneChapter failed, this.formType is null')
            return
        }

        if (formItem.type !== QuestionType.chapter) {
            throw new Error('Invalid formItem type');
        }

        let position = destinationChapter.position + 999;
        const items = FormCloneUtils.cloneChapter(formItem, position);
        for (const item of items) {
            item.position = position;
            const savedItem = await firstValueFrom(this.questionService.postQuestion(
                this.formType.id, item, this.apiEndpoint
            ));
            position = savedItem.position + 1;
        }
    }
}
