import {Component, Inject} from '@angular/core';
import {ObjectTypeService} from '../../services/object-type.service';
import {PaulaObjectType, paulaObjectTypeStatusTitles} from '../../models/paula-object-type';
import {AbstractListComponent} from "../abstract-list.component";
import {ActivatedRoute, Router} from "@angular/router";
import {TableFilter} from "../../components/table/table.component";
import {Observable} from "rxjs";
import {PageResponse} from "../../models/page-response";

@Component({
    selector: 'app-object-type-list',
    templateUrl: './object-type-list.component.html'
})
export class ObjectTypeListComponent extends AbstractListComponent<PaulaObjectType> {
    objectTypeStatusTitles = paulaObjectTypeStatusTitles;

    tableHeaders = [
        {sortKey: 'title', filterKey: 'title', title: 'Objecttypenaam'},
        {sortKey: 'code', filterKey: 'code', title: 'Objecttype ID'},
        {sortKey: 'description', filterKey: 'description', title: 'Omschrijving'},
        {filterKey: 'companies.name', title: 'Bedrijven'},
        {sortKey: 'status', filterKey: 'status', title: 'Status'}
    ];

    constructor(
        @Inject('ObjectTypeService') public objectTypeService: ObjectTypeService,
        activatedRoute: ActivatedRoute,
        router: Router,
    ) {
        super(activatedRoute, router);
    }

    loadData(page: number, filter: TableFilter, search: string | undefined): Observable<PageResponse<PaulaObjectType>> {
        return this.objectTypeService.getObjectTypes(page, filter);
    }
}
