<app-toolbar [toolbarTitle]="formTitle" [showBackBtn]="true" (back)="goBack()">
    <button class="vwui-btn is-primary has-icon" (click)="goToFormQuestions()" *ngIf="!isNew">
        <app-icon iconName="edit-2"></app-icon>
        {{'Vragen beheren'|translate}}
    </button>
</app-toolbar>
<div class="vwui-container is-small">
    <div class="vwui-card">
        <div class="vwui-card__header">{{'Vragenset'|translate}}</div>
        <div class="vwui-card__content">
            <form [formGroup]="form" (submit)="save()">
                <div class="vwui-form-group" [appFormControlError]="form.get('title')">
                    <label for="title" class="vwui-label">{{'Vragensetnaam'|translate}}</label>
                    <input id="title" class="vwui-input" name="title" type="text" formControlName="title" autocomplete="off">
                </div>
                <button type="submit" class="d-none"><!-- Needed to submit with enter --></button>
            </form>
        </div>
    </div>
</div>
<app-actionbar *ngIf="form.dirty" (cancel)="cancel()" (save)="save()"></app-actionbar>
