import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Project} from '../models/project';

export interface SharepointService {
    testConnection(project: Project): Observable<string>;
}

@Injectable()
export class SharepointServiceImpl implements SharepointService {
    constructor(private http: HttpClient) {
    }

    testConnection(project: Project): Observable<string> {
        return this.http.post<string>(`/api/v1/sharepoint/test-connection`, project);
    }
}
