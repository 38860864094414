import IdentityManager from "@arcgis/core/identity/IdentityManager";
import ServerInfo from "@arcgis/core/identity/ServerInfo";
import {ArcgisToken} from "../models/arcgis-token";
import Credential from "@arcgis/core/identity/Credential";
import esriConfig from "@arcgis/core/config";
import RequestOptions = __esri.RequestOptions;
import CredentialProperties = __esri.CredentialProperties;
export const IdentityManagerUnsafe = IdentityManager as (typeof IdentityManager & {
    credentials: Credential[];

    _getServerInstanceRoot(url: string): string

    signIn(e: string, t: ServerInfo, r: {
        isAdmin: boolean | undefined;
        signal: unknown;
    }): Promise<unknown>
})
const IdentityManagerUnsafeOriginalSignIn = IdentityManagerUnsafe.signIn

/**
 * Beware, this code is very hacky, the feature we are providing here auth external to arcgis js
 * is not properly supported, so we have to use private functions and workarounds to make it
 * function properly
 *
 * This code may break at any upgrade of arcgis js because of usage of undocumented functions
 */
export async function configurePortalAndAuthentication(
    configurationUrl: string,
    getToken: (
        portalToken?: string,
        serverUrl?: string
    ) => Promise<ArcgisToken>
) {
    esriConfig.portalUrl = configurationUrl;

    const serverRoot = IdentityManagerUnsafe._getServerInstanceRoot(configurationUrl)

    esriConfig.request.interceptors = [{
        urls: /^.*\/generateToken$/,
        before: (requestParams: {url: string, requestOptions?: RequestOptions}) => {
            if (serverRoot === IdentityManagerUnsafe._getServerInstanceRoot(requestParams.url)) {
                console.log('token', JSON.parse(JSON.stringify(requestParams)));
                return getToken(
                    requestParams.requestOptions?.query?.token,
                    requestParams.requestOptions?.query?.serverUrl
                );
            }
        }
    }];

    // EXTREME Hack
    IdentityManagerUnsafe.signIn = (e, t, r) => {
        if (serverRoot === IdentityManagerUnsafe._getServerInstanceRoot(e)) {
            console.log('Hijack', e, t, r);

            const fakeFormResult = {
                username: 'AssetCheck',
                password: 'fakePassword'
            }

            // Hijack the signIn prompt flow
            return IdentityManagerUnsafe.generateToken(
                t,
                fakeFormResult,
                {isAdmin: r.isAdmin, signal: r.signal} as any
            ).then((generatedToken: ArcgisToken) => {
                const existing = IdentityManagerUnsafe.findCredential(t.server, fakeFormResult.username)
                if (existing) {
                    existing.token = generatedToken.token
                    existing.expires = generatedToken.expires
                    existing.ssl = generatedToken.ssl

                    return existing;
                }

                return new Credential({
                    userId: fakeFormResult.username,
                    server: t.server,
                    token: generatedToken.token,
                    expires: null != generatedToken.expires ? Number(generatedToken.expires) : undefined,
                    ssl: !!generatedToken.ssl,
                    isAdmin: r.isAdmin,
                    validity: undefined
                } as CredentialProperties);
            })
        }

        return IdentityManagerUnsafeOriginalSignIn.call(IdentityManagerUnsafe, e, t, r);
    }
}
