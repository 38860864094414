<div class="vwui-table-pagination">
    <div class="vwui-pagination">

        <button class="vwui-pagination__btn" [disabled]="paginator.currentPage === 0" (click)="paginator.previous()">
            <app-icon iconName="chevron-left"></app-icon>
        </button>

        <span class="vwui-pagination__pages">
            {{ (paginator.currentPage || 0) + 1 }} / {{ paginator.totalPages || 1 }}
        </span>

        <button class="vwui-pagination__btn" [disabled]="paginator.currentPage + 1 >= paginator.totalPages"
                (click)="paginator.next()">
            <app-icon iconName="chevron-right"></app-icon>
        </button>
    </div>
</div>
