<div class="vwui-toolbar">
    <div class="vwui-toolbar__left">
        <button
            class="vwui-btn has-icon is-back"
            *ngIf="showBackBtn && (backBtnRoute || (canGoHistoryBack$|async))"
            (click)="onBack()">
            <app-icon iconName="corner-up-left"></app-icon>
            Vorige
        </button>
        <h1 class="vwui-toolbar__title" *ngIf="toolbarTitle">{{ toolbarTitle }}</h1>
    </div>
    <div class="vwui-toolbar__right">
        <ng-content></ng-content>
        <button
            class="vwui-btn has-icon is-primary"
            *ngIf="showAddBtn"
            (click)="onAdd()">
            <app-icon iconName="plus"></app-icon>
            {{ addBtnLabel }}
        </button>
    </div>
</div>
