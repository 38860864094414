import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthenticatedGuard} from './services/authenticated-guard.service';
import {AuthCallbackComponent} from './components/auth-callback/auth-callback.component';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {FormTypeListComponent} from './pages/form-type-list/form-type-list.component';
import {FormTypeDetailComponent} from './pages/form-type-detail/form-type-detail.component';
import {FormTypeResolver} from './resolvers/form-type.resolver';
import {ObjectTypeListComponent} from './pages/object-type-list/object-type-list.component';
import {ObjectTypeDetailComponent} from './pages/object-type-detail/object-type-detail.component';
import {ObjectTypeResolver} from './resolvers/object-type.resolver';
import {FormBuilderComponent} from './pages/form-builder/form-builder.component';
import {UserListComponent} from './pages/user-list/user-list.component';
import {UserDetailComponent} from './pages/user-detail/user-detail.component';
import {UserResolver} from './resolvers/user.resolver';
import {QuestionSetListComponent} from './pages/question-set-list/question-set-list.component';
import {QuestionSetDetailComponent} from './pages/question-set-detail/question-set-detail.component';
import {QuestionSetResolver} from './resolvers/question-set.resolver';
import {ProjectDetailComponent} from './pages/project-detail/project-detail.component';
import {ProjectListComponent} from './pages/project-list/project-list.component';
import {ProjectResolver} from './resolvers/project.resolver';
import {ObjectResolver} from './resolvers/object.resolver';
import {ObjectDetailComponent} from './pages/object-detail/object-detail.component';
import {ProjectFormResolver} from './resolvers/project-form.resolver';
import {ProjectFormDetailComponent} from './pages/project-form-detail/project-form-detail.component';
import {ExecutionGroupListComponent} from './pages/execution-group-list/execution-group-list.component';
import {ExecutionGroupDetailComponent} from './pages/execution-group-detail/execution-group-detail.component';
import {ExecutionGroupResolver} from './resolvers/execution-group.resolver';
import {WorkerGroupListComponent} from './pages/worker-group-list/worker-group-list.component';
import {WorkergroupDetailComponent} from './pages/worker-group-detail/worker-group-detail.component';
import {WorkerGroupResolver} from './resolvers/worker-group.resolver';
import {WorkerGroupBuilderComponent} from './pages/worker-group-builder/worker-group-builder.component';
import {ProjectJobListComponent} from './pages/project-job-list/project-job-list.component';
import {
    AuthenticationProviderConfigListComponent
} from './pages/authentication-provider-config-list/authentication-provider-config-list.component';
import {
    AuthenticationProviderConfigDetailComponent
} from './pages/authentication-provider-config-detail/authentication-provider-config-detail.component';
import {AuthenticationProviderConfigResolver} from './resolvers/authentication-provider-config-resolver.service';
import {ProjectJobDetailComponent} from './pages/project-job-detail/project-job-detail.component';
import {ProjectJobResolver} from './resolvers/project-job-resolver.service';
import {ObjectProjectResolver} from './resolvers/object-project.resolver';
import {ProjectMapComponent} from './pages/project-map/project-map.component';
import {CompanyListComponent} from './pages/company-list/company-list.component';
import {CompanyDetailComponent} from './pages/company-detail/company-detail.component';
import {CompanyResolver} from './resolvers/company.resolver';
import {
    InterfaceProcessingErrorDetailComponent
} from './pages/interface-processing-error-detail/interface-processing-error-detail.component';
import {InterfaceProcessingErrorResolver} from './resolvers/interface-processing-error.resolver';
import {AppleContentCodesListComponent} from './pages/apple-content-codes-list/apple-content-codes-list.component';
import {RootComponent} from './pages/root/root.component';
import {AppleContentGetCodeUrlComponent} from './pages/apple-content-get-code-url/apple-content-get-code-url.component';
import {CustomerListComponent} from './pages/customer-list/customer-list.component';
import {CustomerDetailComponent} from './pages/customer-detail/customer-detail.component';
import {CustomerResolver} from './resolvers/customer.resolver';
import {FormTypeCopyComponent} from './pages/form-type-copy/form-type-copy.component';
import {
    InterfaceProcessingErrorListComponent
} from './pages/interface-processing-error-list/interface-processing-error-list.component';
import {
    ProjectExecutionGroupDetailComponent
} from './pages/project-execution-group-detail/project-execution-group-detail.component';
import {ProjectExecutionGroupResolver} from './resolvers/project-execution-group.resolver';
import {ObstructionCodeListComponent} from "./pages/obstruction-code-list/obstruction-code-list.component";
import {ObstructionCodeDetailComponent} from "./pages/obstruction-code-detail/obstruction-code-detail.component";
import {ObstructionCodeResolver} from "./resolvers/obstruction-code.resolver";
import {SharepointCredentialResolver} from "./resolvers/sharepoint-credential.resolver";
import {
    SharepointCredentialDetailComponent
} from "./pages/sharepoint-credential-detail/sharepoint-credential-detail.component";
import {
    SharepointCredentialListComponent
} from "./pages/sharepoint-credential-list/sharepoint-credential-list.component";

const routes: Routes = [
    {path: 'beheer/auth-callback', component: AuthCallbackComponent},
    {
        path: 'beheer',
        children: [
            {
                path: 'apple-content-url',
                component: AppleContentGetCodeUrlComponent,
            },
            {
                path: '',
                component: RootComponent,
                children: [
                    {
                        path: 'auth-callback',
                        component: AuthCallbackComponent,
                    },
                    {
                        path: 'dashboard',
                        component: DashboardComponent,
                        canActivate: [AuthenticatedGuard]
                    },
                    {
                        path: 'form-types',
                        canActivate: [AuthenticatedGuard],
                        children: [
                            {
                                path: '',
                                component: FormTypeListComponent,
                            },
                            {
                                path: 'new',
                                component: FormTypeDetailComponent,
                            },
                            {
                                path: ':id',
                                resolve: {
                                    formType: FormTypeResolver,
                                },
                                component: FormTypeDetailComponent,
                            },
                            {
                                path: ':id/questions',
                                resolve: {
                                    formType: FormTypeResolver
                                },
                                runGuardsAndResolvers: 'always',
                                component: FormBuilderComponent,
                            },
                            {
                                path: ':id/copy',
                                component: FormTypeCopyComponent,
                            },
                        ]
                    },
                    {
                        path: 'object-types',
                        canActivate: [AuthenticatedGuard],
                        children: [
                            {
                                path: '',
                                component: ObjectTypeListComponent,
                            },
                            {
                                path: 'new',
                                component: ObjectTypeDetailComponent,
                            },
                            {
                                path: ':id',
                                resolve: {
                                    objectType: ObjectTypeResolver
                                },
                                component: ObjectTypeDetailComponent,
                            }
                        ]
                    },
                    {
                        path: 'obstruction-codes',
                        canActivate: [AuthenticatedGuard],
                        children: [
                            {
                                path: '',
                                component: ObstructionCodeListComponent,
                            },
                            {
                                path: 'new',
                                component: ObstructionCodeDetailComponent,
                            },
                            {
                                path: ':id',
                                resolve: {
                                    obstructionCode: ObstructionCodeResolver
                                },
                                component: ObstructionCodeDetailComponent,
                            }
                        ]
                    },
                    {
                        path: 'question-sets',
                        canActivate: [AuthenticatedGuard],
                        children: [
                            {
                                path: '',
                                component: QuestionSetListComponent,
                            },
                            {
                                path: 'new',
                                component: QuestionSetDetailComponent,
                            },
                            {
                                path: ':id',
                                resolve: {
                                    questionSet: QuestionSetResolver
                                },
                                component: QuestionSetDetailComponent,
                            },
                            {
                                path: ':id/questions',
                                resolve: {
                                    questionSet: QuestionSetResolver
                                },
                                runGuardsAndResolvers: 'always',
                                component: FormBuilderComponent,
                            }
                        ]
                    },
                    {
                        path: 'companies',
                        canActivate: [AuthenticatedGuard],
                        children: [
                            {
                                path: '',
                                component: CompanyListComponent,
                            },
                            {
                                path: 'new',
                                component: CompanyDetailComponent,
                            },
                            {
                                path: ':id',
                                resolve: {
                                    company: CompanyResolver
                                },
                                component: CompanyDetailComponent
                            }
                        ]
                    },
                    {
                        path: 'customers',
                        canActivate: [AuthenticatedGuard],
                        children: [
                            {
                                path: '',
                                component: CustomerListComponent,
                            },
                            {
                                path: 'new',
                                component: CustomerDetailComponent,
                            },
                            {
                                path: ':id',
                                resolve: {
                                    customer: CustomerResolver
                                },
                                component: CustomerDetailComponent
                            }
                        ]
                    },
                    {
                        path: 'users',
                        canActivate: [AuthenticatedGuard],
                        children: [
                            {
                                path: '',
                                component: UserListComponent,
                            },
                            {
                                path: 'new',
                                component: UserDetailComponent,
                            },
                            {
                                path: ':id',
                                resolve: {
                                    user: UserResolver
                                },
                                component: UserDetailComponent,
                            }
                        ]
                    },
                    {
                        path: 'registrations',
                        canActivate: [AuthenticatedGuard],
                        children: [
                            {
                                path: '',
                                component: AuthenticationProviderConfigListComponent,
                            },
                            {
                                path: 'new',
                                component: AuthenticationProviderConfigDetailComponent,
                            },
                            {
                                path: ':id',
                                resolve: {
                                    registration: AuthenticationProviderConfigResolver
                                },
                                component: AuthenticationProviderConfigDetailComponent,
                            }
                        ]
                    },
                    {
                        path: 'projects',
                        canActivate: [AuthenticatedGuard],
                        children: [
                            {
                                path: '',
                                component: ProjectListComponent,
                            },
                            {
                                path: 'new',
                                component: ProjectDetailComponent,
                            },
                            {
                                path: ':id',
                                resolve: {
                                    project: ProjectResolver
                                },
                                component: ProjectDetailComponent,
                            },
                            {
                                path: ':project/objects/new',
                                component: ObjectDetailComponent,
                                resolve: {
                                    project: ObjectProjectResolver
                                }
                            },
                            {
                                path: ':project/objects/:id',
                                resolve: {
                                    object: ObjectResolver,
                                    project: ObjectProjectResolver
                                },
                                component: ObjectDetailComponent
                            },
                            {
                                path: ':id/form/:formId',
                                resolve: {
                                    projectForm: ProjectFormResolver
                                },
                                component: ProjectFormDetailComponent
                            },
                            {
                                path: ':id/form/:formId/questions',
                                resolve: {
                                    formType: ProjectFormResolver
                                },
                                runGuardsAndResolvers: 'always',
                                component: FormBuilderComponent
                            },
                            {
                                path: ':project/map',
                                resolve: {
                                    project: ObjectProjectResolver
                                },
                                component: ProjectMapComponent
                            },
                            {
                                path: ':project/execution-groups/new',
                                component: ProjectExecutionGroupDetailComponent
                            },
                            {
                                path: ':project/execution-groups/:id',
                                resolve: {
                                    executionGroup: ProjectExecutionGroupResolver
                                },
                                component: ProjectExecutionGroupDetailComponent
                            }
                        ]
                    },
                    {
                        path: 'project-jobs',
                        canActivate: [AuthenticatedGuard],
                        children: [
                            {
                                path: '',
                                component: ProjectJobListComponent,
                            },
                            {
                                path: ':id',
                                resolve: {
                                    projectJob: ProjectJobResolver
                                },
                                component: ProjectJobDetailComponent,
                            },
                        ]
                    },
                    {
                        path: 'workergroups',
                        canActivate: [AuthenticatedGuard],
                        children: [
                            {
                                path: '',
                                component: WorkerGroupListComponent,
                            },
                            {
                                path: 'new',
                                component: WorkergroupDetailComponent,
                            },
                            {
                                path: ':id',
                                resolve: {
                                    workergroup: WorkerGroupResolver
                                },
                                component: WorkergroupDetailComponent,
                            },
                            {
                                path: ':id/workers',
                                resolve: {
                                    workergroup: WorkerGroupResolver
                                },
                                runGuardsAndResolvers: 'always',
                                component: WorkerGroupBuilderComponent,
                            }
                        ]
                    },
                    {
                        path: 'interface-processing-errors',
                        canActivate: [AuthenticatedGuard],
                        children: [
                            {
                                path: '',
                                component: InterfaceProcessingErrorListComponent
                            },
                            {
                                path: ':id',
                                resolve: {
                                    processingError: InterfaceProcessingErrorResolver
                                },
                                component: InterfaceProcessingErrorDetailComponent
                            }
                        ]
                    },
                    {
                        path: 'execution-groups',
                        canActivate: [AuthenticatedGuard],
                        children: [
                            {
                                path: '',
                                component: ExecutionGroupListComponent
                            },
                            {
                                path: 'new',
                                component: ExecutionGroupDetailComponent
                            },
                            {
                                path: ':id',
                                resolve: {
                                    executionGroup: ExecutionGroupResolver
                                },
                                component: ExecutionGroupDetailComponent
                            }
                        ]
                    },
                    {
                        path: 'sharepoint-credentials',
                        canActivate: [AuthenticatedGuard],
                        children: [
                            {
                                path: '',
                                component: SharepointCredentialListComponent,
                            },
                            {
                                path: 'new',
                                component: SharepointCredentialDetailComponent,
                            },
                            {
                                path: ':id',
                                resolve: {
                                    sharepointCredential: SharepointCredentialResolver
                                },
                                component: SharepointCredentialDetailComponent,
                            }
                        ]
                    },
                    {
                        path: 'apple',
                        canActivate: [AuthenticatedGuard],
                        children: [
                            {
                                path: '',
                                component: AppleContentCodesListComponent
                            }
                        ]
                    },
                    {path: '**', redirectTo: '/beheer/dashboard'}
                ]
            }
        ]
    },
    {path: '**', redirectTo: '/beheer/dashboard'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        errorHandler: (err) => {
            console.error('Navigation failed: ', err);
            throw err;
        },
        onSameUrlNavigation: 'reload'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
