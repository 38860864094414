<div class="vwui-nav" [class.is-open]="navOpen">
    <div class="vwui-nav__container">
        <div class="vwui-nav__main">
            <div class="vwui-nav-list">
                <ng-container *ngIf="!templateRef">
                    <ng-container *ngFor="let navItem of mainNavItems">
                        <div class="vwui-nav-list__header" *ngIf="navItem.header">
                            {{ navItem.header }}
                        </div>
                        <a *ngFor="let navLink of navItem.items" class="vwui-nav-list__item"
                           [routerLink]="navLink.routerLink"
                            routerLinkActive="is-active">
                            {{ navLink.label }}
                        </a>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="templateRef">
                    <ng-template *ngFor="let navItem of mainNavItems" [ngTemplateOutlet]="templateRef"
                                 [ngTemplateOutletContext]="{$implicit: navItem}">
                    </ng-template>
                </ng-container>
            </div>
        </div>
        <div class="vwui-nav-list__bottom" *ngIf="hasBottomNavItems">
            <div class="vwui-nav-list">
                <a class="vwui-nav-list__item"
                   *ngFor="let navLink of bottomNavItems"
                   [routerLink]="navLink.routerLink"
                   routerLinkActive="is-active">{{ navLink.label }}</a>
            </div>
        </div>
    </div>
    <div class="vwui-nav__overlay" (click)="toggleNav()"></div>
</div>
