// @ts-strict-ignore
import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {Project} from '../../models/project';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {Forms} from '../../utils/forms';
import {ProjectMapLayersService} from '../../services/project-map-layers.service';
import {
    ProjectMapLayerPropertiesModalComponent
} from '../project-map-layer-properties-modal/project-map-layer-properties-modal.component';
import {BsModalService} from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-project-map-layers-tab',
    templateUrl: './project-map-layers-tab.component.html'
})
export class ProjectMapLayersTabComponent implements OnInit {
    public readonly MAP_LAYER_COUNT = 5;
    public readonly MAP_LAYER_PROPERTIES_COUNT = 7;

    @Input() project: Project;
    @Output() projectSaved = new EventEmitter<Project>();

    form: UntypedFormGroup;
    detailsOpen = false;

    constructor(
        @Inject('ProjectMapLayersService') private projectMapLayersService: ProjectMapLayersService,
        private modalService: BsModalService,
        private toast: ToastrService,
    ) {}

    get mapLayerControls() {
        return (this.form.get('mapLayersData') as UntypedFormArray).controls as UntypedFormControl[];
    }

    async ngOnInit() {
        this.form = await this.createForm();
    }

    async save() {
        if (!this.form.valid) {
            Forms.updateValueAndValidityRecursive(this.form);
            return;
        }

        const newProject = this.form.value as Project;

        try {
            const nonEmptyMapLayers = newProject.mapLayersData.filter(it => !!it.mapUrl);
            const result = await this.projectMapLayersService.putMapLayers(
                this.project.id, {...newProject, mapLayers: nonEmptyMapLayers}
            ).toPromise();
            this.projectSaved.emit(result);

            this.toast.success('Opgeslagen');

            let mapLayers = result.mapLayersData;
            if (mapLayers.length < this.MAP_LAYER_COUNT) {
                mapLayers = mapLayers.concat(
                    Array(this.MAP_LAYER_COUNT - mapLayers.length).fill('')
                );
            }
            this.form.patchValue({mapLayers});
        } catch (error) {
            console.error('Unable to save project', error);
            this.toast.error('Opslaan mislukt');
        }
    }

    cancel() {
        history.back();
    }

    addMaplayerProperties(control: any) {
        this.detailsOpen = true;
        const modalRef = this.modalService.show(ProjectMapLayerPropertiesModalComponent, {
            initialState: {
                destinationControl: control,
                onClose: async () => {
                    this.detailsOpen = false;
                    modalRef.hide();
                }
            }
        });
    }

    private async createForm() {
        const mapLayersResponse = await this.projectMapLayersService.getMapLayers(this.project.id).toPromise();

        return new UntypedFormGroup({
            configurationUrl: new UntypedFormControl(
                mapLayersResponse.configurationUrl,
                [Validators.maxLength(255), Validators.pattern('^https:\/\/[^\\s]+')]
            ),
            mapLayersData: new UntypedFormArray(Array(this.MAP_LAYER_COUNT).fill('').map(
                (defaultVal, index) => {
                    const mapLayers = mapLayersResponse.mapLayers;
                    return new UntypedFormGroup({
                        id: new UntypedFormControl(mapLayers[index] ? mapLayers[index].id : null),
                        mapUrl: new UntypedFormControl(
                                mapLayers[index] ? mapLayers[index].mapUrl : defaultVal,
                                Validators.pattern('^https:\/\/[^\\s]+')
                            ),
                        objectModalTitle: new UntypedFormControl(
                            mapLayers[index] ? mapLayers[index].objectModalTitle : defaultVal
                        ),
                        objectModalShowAttachments: new UntypedFormControl(
                            mapLayers[index] ? mapLayers[index].objectModalShowAttachments : defaultVal
                        ),
                        objectModalProperties: new UntypedFormArray(Array(this.MAP_LAYER_PROPERTIES_COUNT).fill('').map(
                            (_, propertyIndex) => {
                                return new UntypedFormGroup({
                                        label: new UntypedFormControl(
                                            mapLayers[index] && mapLayers[index].objectModalProperties
                                                ? mapLayers[index].objectModalProperties[propertyIndex]
                                                    ? mapLayers[index].objectModalProperties[propertyIndex].label
                                                    : null
                                                : null
                                        ),
                                        fieldName: new UntypedFormControl(
                                            mapLayers[index] && mapLayers[index].objectModalProperties
                                                ? mapLayers[index].objectModalProperties[propertyIndex]
                                                ? mapLayers[index].objectModalProperties[propertyIndex].fieldName
                                                : null
                                                : null
                                        ),
                                    }
                                );
                            }
                        ))
                    });
                }
            ))
        });
    }
}
