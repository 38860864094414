// @ts-strict-ignore
import {Component, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {v4 as uuid} from 'uuid';
import {Choice} from '../../models/form-item';
import {BsModalService} from 'ngx-bootstrap/modal';
import {
    ChoiceQuestionSettingsModalComponent
} from '../choice-question-settings-modal/choice-question-settings-modal.component';

@Component({
    selector: 'app-question-choices',
    templateUrl: './question-choices.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => QuestionChoicesComponent),
            multi: true
        }
    ]
})
export class QuestionChoicesComponent implements ControlValueAccessor {

    disabled = false;
    private onChange: (value: Array<Choice>) => void;
    private onTouched: () => void;
    choices: Array<Choice> = [];

    constructor(private modalService: BsModalService) {
    }

    registerOnChange(fn: any): void {
        this.onChange = (value) => {
            fn(value);
        };
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(choices: Choice[]): void {
        this.choices = choices;
        this.sortChoices();
    }

    add() {
        this.choices.push({
            id: uuid(),
            title: '',
            position: this.choices.length + 1,
            imageRemark: false,
            imageRemarkRequired: false,
            textRemark: false,
            textRemarkRequired: false
        });
    }

    up(position: number) {
        this.choices.find(choice => choice.position === position).position--;
        this.choices.find(choice => choice.position === position - 1).position++;

        this.sortAndChangeChoices();
    }

    down(position: number) {
        this.choices.find(choice => choice.position === position + 1).position--;
        this.choices.find(choice => choice.position === position).position++;

        this.sortAndChangeChoices();
    }

    remove(choice: Choice) {
        this.choices = this.choices.filter(it => it.id !== choice.id);

        this.sortAndChangeChoices();
    }

    updateChoice(i: number, event: Event) {
        const target = event.target as HTMLInputElement;
        this.choices[i].title = target.value;

        this.onTouched();
        this.onChange(this.choices);
    }

    private sortAndChangeChoices() {
        this.sortChoices();
        this.onTouched();
        this.onChange(this.choices);
    }

    private sortChoices() {
        this.choices.sort((a, b) => {
            return a.position - b.position;
        });

        let reposition = 1;
        this.choices.forEach(choice => choice.position = reposition++);
    }

    toggleSettings(choice: Choice) {
        this.modalService.show(ChoiceQuestionSettingsModalComponent, {
            initialState: {
                choice: choice,
                onClose(result: Pick<Choice, 'textRemark' | 'textRemarkRequired' | 'imageRemark' | 'imageRemarkRequired'>) {
                    choice.textRemark = result.textRemark;
                    choice.textRemarkRequired = result.textRemarkRequired;
                    choice.imageRemark = result.imageRemark;
                    choice.imageRemarkRequired = result.imageRemarkRequired;
                }
            }
        });
    }
}
