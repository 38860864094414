import {Identifiable} from './identifiable';
import {QuestionSet} from './question-set';
import {FormType, ProjectForm} from './form-type';
import {Object} from './object';
import {NumberQuestionFollowUp} from "./number-question-follow-up";
import {FormulaConfig} from "./formula-config";
import {TableColumn} from "./table-column";

export enum QuestionType {
    text = 'text',
    choice = 'choice',
    questionSet = 'questionSet',
    signature = 'signature',
    photo = 'photo',
    number = 'number',
    date = 'date',
    chapter = 'chapter',
    object = 'object',
    list = 'list',
    table = 'table',
    formula = 'formula',
    location = "location",
    referenceImage = 'referenceImage',
    tabular = 'tabular'
}
export type QuestionTypeUnion = `${QuestionType}`

export interface AbstractFormItem extends Identifiable {
    title: string;
    descriptionType?: 'text' | 'image';
    description?: string;
    imageDescription?: string | null;
    position: number;
    type: QuestionType;
    privacyEnabled?: boolean;
    addToSummaryEnabled?: boolean;
    showSummaryEnabled?: boolean;
    reference: string | null;
    chapterNumber?: number;
}

export interface AbstractQuestion extends AbstractFormItem {
    required: boolean;
    helpText?: string;
    preFillEnabled: boolean;
    preFillValue: string;
    toleranceEnabled: boolean;
    toleranceValue: string;
    questionDependency: Array<Choice>;
}

export interface TextQuestion extends AbstractQuestion {
    type: QuestionType.text;
    multiline: boolean;
}

export interface ChoiceQuestion extends AbstractQuestion {
    type: QuestionType.choice;
    multiple: boolean;
    choices: Array<Choice>;
}

export interface SignatureQuestion extends AbstractQuestion {
    type: QuestionType.signature;
}

export interface ObjectQuestion extends AbstractQuestion {
    type: QuestionType.object;
}

export interface PhotoQuestion extends AbstractQuestion {
    type: QuestionType.photo;
}

export interface NumberQuestion extends AbstractQuestion {
    type: QuestionType.number;
    totalDecimal: number;
    followUp: NumberQuestionFollowUp[];
}

export interface DateQuestion extends AbstractQuestion {
    type: QuestionType.date;
    time: boolean;
}

export interface ListQuestion extends AbstractQuestion {
    type: QuestionType.list | QuestionType.table;
    questionLabel: string;
    questionCount: number;
    averageTolerance?: string;
}

export interface FormulaQuestion extends AbstractQuestion {
    type: QuestionType.formula;
    formulaFields?: FormulaConfig
}

export interface ReferenceImageQuestion extends AbstractQuestion {
    type: QuestionType.referenceImage;
    referenceImages: string;
}

export interface LocationQuestion extends AbstractQuestion {
    type: QuestionType.location;
    multipoint: boolean;
}

export interface TabularQuestion extends AbstractQuestion {
    type: QuestionType.tabular;
    pivot: boolean;
    columns: TableColumn[];
}

export interface QuestionSetQuestion extends AbstractFormItem {
    type: QuestionType.questionSet;
    children?: Array<FormItem>;
    questionSet: QuestionSet;
}

export interface ChapterFormItem extends AbstractFormItem {
    type: QuestionType.chapter;
    children?: Array<FormItem>;
    questionDependency?: Array<Choice>;
}

export type FormItem =
    TextQuestion
    | ChoiceQuestion
    | QuestionSetQuestion
    | SignatureQuestion
    | ObjectQuestion
    | PhotoQuestion
    | NumberQuestion
    | DateQuestion
    | ListQuestion
    | ChapterFormItem
    | FormulaQuestion
    | ReferenceImageQuestion
    | LocationQuestion
    | TabularQuestion;

export interface Choice {
    id: string;
    title: string;
    position: number;
    followUpProjectJobTemplate?: FollowUpProjectJobTemplate;
    followUpFormTemplate?: FollowUpFormTemplate;
    imageRemark: boolean;
    imageRemarkRequired?: boolean;
    textRemark: boolean;
    textRemarkRequired?: boolean;
    remarkRequired?: boolean;
}

export const questionTypeTitles: {[key in keyof typeof QuestionType]: string } = {
    text: 'Tekst',
    choice: 'Keuze',
    questionSet: 'Vragenset',
    signature: 'Handtekening',
    photo: 'Foto',
    number: 'Getal',
    date: 'Datum',
    chapter: 'Hoofdstuk',
    object: 'Object',
    list: 'Lijst',
    table: 'Tabel',
    formula: 'Formule',
    location: 'Locatie',
    referenceImage: 'Referentieafbeelding',
    tabular: 'Tabel'
};

export interface QuestionListType {
    type: QuestionType;
    label: string;
}

export interface SelectedFormItem {
    type: 'question' | 'chapter' | 'questionSet';
    previousFormItem: FormItem;
    formItem?: FormItem;
    possibleDependencies?: FormItem[];
}

export interface FollowUpProjectJobTemplate {
    id?: number;
    title: string;
    projectForm: ProjectForm|number;
    recoveryTime: string;
    actionTaker: string|null;
}

export interface FollowUpFormTemplate {
    id?: number;
    title: string;
    formType: FormType|number;
    recoveryTime: string;
    actionTaker: string|null;
}

