// @ts-strict-ignore
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {Project} from '../../models/project';
import {ProjectMapLayersService} from '../project-map-layers.service';
import {mockProjects} from './project.service.mock';
import {MapLayerDataRequest, MapLayerDataResponse} from '../../models/maplayerdata';

@Injectable()
export class ProjectMapLayersServiceMock implements ProjectMapLayersService {
    getMapLayers(projectId: number): Observable<MapLayerDataResponse> {
        const foundProject = mockProjects.find((project) => project.id === projectId);

        return foundProject !== undefined ? of({
            configurationUrl: foundProject.configurationUrl,
            mapLayers: foundProject.mapLayersData
        }) : of(null);
    }

    putMapLayers(projectId: number, data: MapLayerDataRequest): Observable<Project> {
        const foundProject = mockProjects.find((project) => project.id === projectId);

        if (foundProject !== undefined) {
            foundProject.configurationUrl = data.configurationUrl;
            foundProject.mapLayersData = data.mapLayers;
        }

        return of(foundProject);
    }
}
