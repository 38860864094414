// @ts-strict-ignore
import {Component, OnInit} from '@angular/core';
import {Choice} from '../../models/form-item';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-choice-question-settings-modal',
  templateUrl: './choice-question-settings-modal.component.html',
})
export class ChoiceQuestionSettingsModalComponent implements OnInit {
    choice: Choice;
    onClose: (result: Pick<Choice, 'textRemark' | 'textRemarkRequired' | 'imageRemark' | 'imageRemarkRequired'>) => void

    textRemark = false;
    textRemarkRequired = false;
    imageRemark = false;
    imageRemarkRequired = false;

    constructor(private modalRef: BsModalRef) {
    }

    ngOnInit() {
        this.textRemark = this.choice.textRemark;
        this.textRemarkRequired = this.choice.textRemarkRequired;
        this.imageRemark = this.choice.imageRemark;
        this.imageRemarkRequired = this.choice.imageRemarkRequired;
    }

    toggleText() {
        this.textRemark = !this.textRemark;
        if (!this.textRemark) {
            this.textRemarkRequired = false;
        }
    }

    toggleImage() {
        this.imageRemark = !this.imageRemark;
        if (!this.imageRemark) {
            this.imageRemarkRequired = false;
        }
    }

    toggleTextRequired(event: Event) {
        const element = event.target as HTMLInputElement;
        return this.textRemarkRequired = element.checked;
    }

    toggleImageRequired(event: Event) {
        const element = event.target as HTMLInputElement;
        return this.imageRemarkRequired = element.checked;
    }

    onSave() {
        this.onClose({
            textRemark: this.textRemark,
            textRemarkRequired: this.textRemarkRequired,
            imageRemark: this.imageRemark,
            imageRemarkRequired: this.imageRemarkRequired
        });

        return this.modalRef.hide();
    }
}
