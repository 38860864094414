// @ts-strict-ignore
import {HttpClient, HttpParams} from '@angular/common/http';
import {PageResponse} from '../models/page-response';
import {Object} from '../models/object';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {TableFilter} from '../components/table/table.component';
import {Filters} from '../utils/filters';
import {map} from 'rxjs/operators';
import {ImportStatus} from '../models/import-status';

export interface ObjectService {
    searchObjects(project: number, page: number, search: string, queryParams?: object): Observable<PageResponse<Object>>;
    getObjects(project: number, page: number, filter?: TableFilter): Observable<PageResponse<Object>>;
    getObject(project: number, id: number): Observable<Object>;
    putObject(project: number, object: Object): Observable<Object>;
    postObject(project: number, object: Object): Observable<Object>;
    importObjects(project: number, file: File): Observable<number>;
    importObjectsStatus(project: number, statusId: number): Observable<ImportStatus>;
    exportObjects(project: number): Observable<{ filename: string, data: Blob }>;
    exists(project: number, objectId: string): Observable<number>;
}

@Injectable()
export class ObjectServiceImpl implements ObjectService {
    constructor(private httpClient: HttpClient) {
    }

    searchObjects(project: number, page: number, search: string, queryParams: object = {}): Observable<PageResponse<Object>> {
        let params = new HttpParams()
            .set('q', '' + search)
            .set('page', '' + page);

        Object.entries(queryParams).forEach(([key, value]) => {
            params = params.set(key, value);

        })

        return this.httpClient.get<PageResponse<Object>>(`/api/v1/projects/${project}/objects/search`, {params});
    }

    getObjects(project: number , page: number, filter?: TableFilter) {
        let params = new HttpParams().set('page', '' + page);

        if (filter) {
            params = Filters.applyFiltering(filter, params);
        }

        return this.httpClient.get<PageResponse<Object>>(`/api/v1/projects/${project}/objects`, {params});
    }

    getObject(project: number, id: number) {
        return this.httpClient.get<Object>(`api/v1/projects/${project}/objects/${id}`);
    }

    exists(project: number, objectId: string) {
        return this.httpClient.get<number>(`api/v1/projects/${project}/objects/exists`, {params: {objectId}});
    }

    putObject(project: number, object: Object) {
        return this.httpClient.put<Object>(`api/v1/projects/${project}/objects/${object.id}`, object);
    }

    postObject(project: number, object: Object) {
        return this.httpClient.post<Object>(`api/v1/projects/${project}/objects`, object);
    }

    importObjects(project: number, file: File) {
        const data = new FormData();
        data.append('file', file, file.name);

        return this.httpClient.post<number>(`api/v1/projects/${project}/objects/import`, data);
    }

    importObjectsStatus(project: number, statusId: number): Observable<ImportStatus> {
        return this.httpClient.get<ImportStatus>(`api/v1/projects/${project}/objects/import/${statusId}`);
    }

    exportObjects(project: number): Observable<{ filename: string, data: Blob }> {
        return this.httpClient.get(`api/v1/projects/${project}/objects/export`, {
            observe: 'response',
            responseType: 'blob'
        }).pipe(map(it => {
            const contentDisposition = it.headers.get('Content-Disposition');
            const filename = contentDisposition.match(/filename="(.+)"/)[1];
            return {filename, data: it.body};
        }));
    }
}
