import {EntitySelectComponent} from './item-select.component';
import {Component, forwardRef, Inject} from '@angular/core';
import {User} from '../../models/user';
import {Observable, of} from 'rxjs';
import {PageResponse} from '../../models/page-response';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {UserService} from '../../services/user.service';

@Component({
    selector: 'app-user-select',
    templateUrl: './item-select.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => UserSelectComponent),
            multi: true
        }
    ]
})
export class UserSelectComponent extends EntitySelectComponent<User, {}> {

    constructor(
        @Inject('UserService') private userService: UserService,
    ) {
        super();

        this.placeholder = 'Selecteer een gebruiker';
    }

    getFilter(): Observable<{}> {
        return of({});
    }

    getLabelText(item: User): string {
        return item.username;
    }

    loadMore(page: number, filter: { term: string }): Observable<PageResponse<User>> {
        return this.userService.getUsers(page, {
            filter: {
                username: filter && filter.term ? filter.term : ''
            }
        });
    }
}
