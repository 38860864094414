<app-toolbar [showBackBtn]="true"
             [backBtnRoute]="'/beheer/project-jobs'"
             [toolbarTitle]="projectJob?.title"
></app-toolbar>
<div class="vwui-tabs">
    <button class="vwui-tabs__item" [class.is-active]="currentTab === 'detail'" (click)="setCurrentTab('detail')">
        {{'Algemeen'|translate}}
    </button>
    <button class="vwui-tabs__item" [class.is-active]="currentTab === 'answers'" (click)="setCurrentTab('answers')">
        {{'Antwoorden'|translate}}
    </button>
</div>
<app-project-job-detail-tab *ngIf="currentTab === 'detail'" [projectJob]="projectJob"
                            (projectJobSaved)="onProjectJobSave($event)"></app-project-job-detail-tab>

<app-project-job-answers-tab
    *ngIf="currentTab === 'answers'"
    [projectJob]="projectJob"
    [preselectedImageUuid]="preselectedImageUuid"
></app-project-job-answers-tab>
