// @ts-strict-ignore
import {Component, forwardRef, Inject, Input} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {EntitySelectComponent} from './item-select.component';
import {PageResponse} from '../../models/page-response';
import {Tag} from '../../models/tag';
import {TagService} from '../../services/tag.service';

@Component({
    selector: 'app-tag-select',
    templateUrl: './tag-select.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TagSelectComponent),
            multi: true
        },
    ]
})
export class TagSelectComponent extends EntitySelectComponent<Tag, {}> {
    @Input() mustHaveForms = false;

    constructor(
        @Inject('TagService') private tagService: TagService,
    ) {
        super();
        this.placeholder = 'Selecteer een tag';
    }

    addTag = (search: string) => {
        const evenDistributionTwelveNumbers = Math.min( 12, Math.max(1, Math.floor( Math.random() * 13 ) ) );
        return this.tagService.postTag({
            id: null,
            name: search,
            className: '' + evenDistributionTwelveNumbers
        }).toPromise();
    }

    loadMore(page: number, filterInput: {term: string}): Observable<PageResponse<Tag>> {
        const filter: {[key: string]: string} = {
            name: filterInput && filterInput.term ? filterInput.term : '',
        };
        if (this.mustHaveForms) {
            filter['formTypes.title'] = '%';
        }
        return this.tagService.getTags(page, {filter});
    }

    getLabelText(item: Tag) {
        return item.name;
    }

    getFilter(): Observable<{}> {
        return of({});
    }
}
