import {Component, ContentChild, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef} from '@angular/core';
import {Paginator} from '../../utils/paginator';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {Filters} from '../../utils/filters';
import {TooltipDirective} from 'ngx-bootstrap/tooltip';

export interface HeaderTitle {
    sortKey?: string;
    filterKey?: string;
    title: string;
    type?: 'text' | 'date';
    visible?: boolean;
    minCharacters?: number;
}

export enum SortDirection {
    Ascending = 'ASC',
    Descending = 'DESC'
}

export interface Sorting {
    sort: string;
    direction: string;
}

export interface SearchFilterOperatorValue { operator: (':' | '='); value: string; }
export type SearchFilterValue = string | SearchFilterOperatorValue;

export interface SearchFilter {
    [key: string]: SearchFilterValue;
}

export interface TableFilter {
    sort?: Sorting;
    filter: SearchFilter;
}

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html'
})
export class TableComponent<T> implements OnInit, OnDestroy {

    @ContentChild(TemplateRef) templateRef: TemplateRef<unknown> | null = null;

    @Input() paginator: Paginator<T> | null = null;
    @Input() headerTitles: Array<HeaderTitle> = [];
    @Input() standalone = false; // If true then render without table-container
    @Input() enableContextMenu = false; // If true then render a contextmenu header
    @Input() enableScroll = true;
    @Output() sortChanged = new EventEmitter<Sorting>();
    @Output() filterChanged = new EventEmitter<TableFilter>();

    tableContainerClassList = {
        'vwui-table-container': !this.standalone
    };

    get columnCount(): number {
        return this.headerTitles.length + (this.enableContextMenu ? 1 : 0);
    }

    private tableFilter: TableFilter = {
        filter: {}
    };

    private queryParamsSubscription: Subscription | null = null;

    constructor(private activatedRoute: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.tableContainerClassList = {
            'vwui-table-container': !this.standalone
        };
        this.queryParamsSubscription = this.activatedRoute.queryParams.subscribe((queryParams) => {
            if (queryParams) {
                this.tableFilter = Filters.getFilteringFromParams(queryParams);
            }
        });
    }

    ngOnDestroy(): void {
        if (this.queryParamsSubscription) {
            this.queryParamsSubscription.unsubscribe();
        }
    }

    isKeyAscending(sortKey: string) {
        if (this.tableFilter.sort && this.tableFilter.sort.sort === sortKey) {
            return this.tableFilter.sort.direction === SortDirection.Ascending;
        }

        return false;
    }

    isKeyDescending(sortKey: string) {
        if (this.tableFilter.sort && this.tableFilter.sort.sort === sortKey) {
            return this.tableFilter.sort.direction === SortDirection.Descending;
        }

        return false;
    }

    changeSort(sortKey: string) {
        if (!sortKey) {
            return;
        }

        if (this.tableFilter.sort === null || this.tableFilter.sort?.sort !== sortKey) {
            this.tableFilter.sort = {
                sort: sortKey,
                direction: SortDirection.Descending
            };
        } else {
            this.tableFilter.sort.direction = this.tableFilter.sort.direction === SortDirection.Ascending
                ? SortDirection.Descending
                : SortDirection.Ascending;
        }

        this.filterChanged.emit(this.tableFilter);
    }

    changeFilter(event: Event, headerTitle: HeaderTitle, filterKey: string, tooltip?: TooltipDirective) {
        if (!filterKey) {
            return;
        }

        const value = (event.target as HTMLInputElement).value || '';
        this.tableFilter.filter[filterKey] = value;
        this.filterChanged.emit(this.tableFilter);

        setTimeout(() => {
            if (headerTitle.minCharacters && value && value.length < headerTitle.minCharacters) {
                tooltip?.show();
            } else {
                tooltip?.hide();
            }
        });
    }

    filterValue(filterKey: string) {
        if (!filterKey) {
            return '';
        }

        return this.tableFilter.filter[filterKey] ? this.tableFilter.filter[filterKey] : '';
    }

    isHeaderVisible(header: HeaderTitle): boolean {
        return header.visible !== false;
    }

    trackHeaderBy(index: number, header: HeaderTitle) {
        return header.filterKey;
    }
}
