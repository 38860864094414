// @ts-strict-ignore
import {Component, Inject, OnInit} from '@angular/core';
import {ProjectJobService} from '../../services/project-job.service';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Forms} from '../../utils/forms';
import {Project} from '../../models/project';
import {ModalResult} from "../../models/modal.result";


@Component({
    selector: 'app-project-select-modal',
    templateUrl: './project-select-modal.component.html'
})
export class ProjectSelectModalComponent implements OnInit {
    project: Project;

    form: UntypedFormGroup;
    onClose: (result: ModalResult, data: Project) => void;

    constructor(
        @Inject('ProjectJobService') private projectJobService: ProjectJobService,
        protected bsModalRef: BsModalRef,
        private fb: UntypedFormBuilder
    ) {
    }

    ngOnInit(): void {
        this.createForm(this.fb);

        // Load initial project if needed
        if (this.project) {
            this.form.controls.project.patchValue(this.project);
        }
    }

    closeModal(result: ModalResult, data: any = null) {
        this.bsModalRef.hide();
        this.onClose(result, data);
    }

    createForm(fb: UntypedFormBuilder) {
        this.form = fb.group({
            project: fb.control(null, [Validators.required]),
        });
    }

    async submit() {
        if (!this.form.valid) {
            Forms.updateValueAndValidityRecursive(this.form);
        }

        this.onClose('confirmed', this.form.get('project').value);
    }
}
