// @ts-strict-ignore
import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormItem, FormulaQuestion, QuestionType} from '../../models/form-item';
import {ActionMenuComponent} from '../../vwui/action-menu/action-menu.component';

@Component({
    selector: 'app-question-list-item',
    templateUrl: './question-list-item.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuestionListItemComponent implements OnInit {
    @ViewChild('actionMenu') actionMenu: ActionMenuComponent;

    @Input() readonly = false;
    @Input() parentFormItem: FormItem;
    @Input() chapterNumber: number;
    @Input() chapterPosition: number;
    @Input() formItem: FormItem;
    @Input() isSelected = false;
    @Input() getDependentQuestionPosition: (formItem: FormItem) => string;

    @Output() edit = new EventEmitter<FormItem>();
    @Output() duplicate = new EventEmitter<FormItem>();
    @Output() remove = new EventEmitter<FormItem>();

    dependentQuestionPosition: string;

    ngOnInit() {
        this.dependentQuestionPosition = this.getDependentQuestionPosition(this.formItem);
    }

    closeMenu() {
        if (this.actionMenu) {
            this.actionMenu.closeMenu();
        }
    }

    onEdit() {
        this.closeMenu();
        this.edit.emit(this.formItem);
    }

    onDuplicate() {
        this.closeMenu();
        this.duplicate.emit(this.formItem);
    }

    onRemove(event: Event) {
        event.preventDefault();
        event.stopPropagation();

        this.closeMenu();
        this.remove.emit(this.formItem);
    }

    isRequired(item: FormItem) {
        return 'required' in item ? item['required'] : false;
    }

    asFormulaQuestionOrNull(item: FormItem): FormulaQuestion {
        return item.type == QuestionType.formula ? item : null
    }
}
