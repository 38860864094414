<ng-container>
    <div [ngClass]="tableContainerClassList">
        <ng-content select="app-search"></ng-content>
        <div [class.vwui-table-container-scroll]="enableScroll">
            <app-paginator [paginator]="paginator"></app-paginator>
            <table class="vwui-table" [ngClass]="{'has-hover': !paginator.isEmpty, 'has-action': !paginator.isEmpty}" >
                <thead>
                <tr>
                    <ng-container *ngFor="let headerTitle of headerTitles; trackBy: trackHeaderBy">
                        <th *ngIf="isHeaderVisible(headerTitle)" (click)="changeSort(headerTitle?.sortKey)"
                            [class.sortable]="headerTitle.sortKey !== undefined">
                            {{ headerTitle.title }}
                            <span
                                    class="vwui-table__sort-icon"
                                    [class.is-ascending]="isKeyAscending(headerTitle?.sortKey)"
                                    [class.is-descending]="isKeyDescending(headerTitle?.sortKey)">
                                        <app-icon iconName="chevron-down"></app-icon>
                                </span>
                        </th>
                    </ng-container>
                    <th *ngIf="enableContextMenu"></th>
                </tr>
                <tr>
                    <ng-container *ngFor="let headerTitle of headerTitles; trackBy: trackHeaderBy">
                        <th *ngIf="isHeaderVisible(headerTitle)">
                            <input #headerTitlePop="bs-tooltip"
                                   *ngIf="headerTitle.filterKey" class="vwui-input" type="text"
                                   placeholder="{{'Zoeken'|translate}}" [value]="filterValue(headerTitle.filterKey)"
                                   (input)="changeFilter($event, headerTitle, headerTitle.filterKey, headerTitlePop)"
                                   [tooltip]="'Minimaal ' + headerTitle.minCharacters + ' karakters vereist'"
                                   triggers="">
                        </th>
                    </ng-container>
                    <th *ngIf="enableContextMenu"></th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngIf="(paginator.content$|async) as values">
                    <ng-template *ngFor="let value of values" [ngTemplateOutlet]="templateRef" [ngTemplateOutletContext]="{$implicit: value}"></ng-template>
                </ng-container>
                <tr *ngIf='paginator.isEmpty'>
                    <td [colSpan]="!headerTitles || headerTitles.length">{{ 'Er zijn geen items gevonden'|translate }}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <app-paginator *ngIf="!paginator.isEmpty" [paginator]="paginator"></app-paginator>
    </div>
</ng-container>
