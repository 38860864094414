<app-toolbar [showBackBtn]="true" (back)="goBack()"></app-toolbar>

<div class="vwui-container is-small">
    <div class="vwui-card">
        <div class="vwui-card__header">{{'Obstructiecode'|translate}}</div>
        <div class="vwui-card__content">
            <form [formGroup]="form" (submit)="save()">
                <div class="vwui-form-group mb-4" [appFormControlError]="form.get('code')">
                    <label for="code" class="vwui-label">{{'Obstructiecode'|translate}}</label>
                    <input id="code" class="vwui-input" name="code" type="text" formControlName="code" autocomplete="off">
                    <app-validation-error [form]="form" [controlName]="'code'"></app-validation-error>
                </div>
                <div class="vwui-form-group mb-4" [appFormControlError]="form.get('name')">
                    <label for="name" class="vwui-label">{{'Naam'|translate}}</label>
                    <input id="name" class="vwui-input" name="name" type="text" formControlName="name" autocomplete="off">
                    <app-validation-error [form]="form" [controlName]="'name'"></app-validation-error>
                </div>
                <button type="submit" class="d-none"><!-- Needed to submit with enter --></button>
            </form>
        </div>
    </div>
</div>

<app-actionbar *ngIf="form.dirty" (cancel)="cancel()" (save)="save()"></app-actionbar>
