<div class="vwui-list">
    <div class="vwui-list-item" *ngIf="formItems.length === 0">
        <div class="vwui-list-item__content">
            <div>{{ 'Er zijn geen items gevonden'|translate }}</div>
        </div>
    </div>
    <div class="vwui-list-item" *ngIf="formItems.length > 0">
        <div class="vwui-list-item__content vwui-list-item__content--auto-height">
            <ng-container *ngFor="let formItem of formItems; let i = index">
                <p style="display: block;" *ngIf="formItem.type === 'chapter'"><strong>{{ getChapterNumber(formItem.position) }}. {{ formItem.title }}</strong>
                    <span class="vwui-badge is-success" *ngIf="dependentQuestionPosition(formItem)">
                        {{'Afhankelijk van'|translate}}: {{ dependentQuestionPosition(formItem) }}
                    </span>
                </p>
                <div *ngIf="formItem.type !== 'chapter' && formItem.type !== 'questionSet'">
                    <p>
                        <span>{{ getChapterNumber(formItem.position) }}.{{ getChapterPosition(formItem.position) }} {{ formItem.title }}</span>
                        <span class="vwui-badge is-primary">{{ formItem.type | questionTypeTitle }}</span>
                        <span class="vwui-badge" *ngIf="formItem.required">{{'Verplicht'|translate}}</span>
                        <span class="vwui-badge is-success" *ngIf="dependentQuestionPosition(formItem) !== null">
                        {{'Afhankelijk van'|translate}}: {{ dependentQuestionPosition(formItem) }}
                        </span>
                        <span [innerHTML]="formItem.description" class="text-muted small child-paragraph-no-margin"></span>
                    </p>
                </div>
                <ng-container *ngIf="formItem.type === 'questionSet'">
                    <app-question-set-items [chapterNumber]="getChapterNumber(formItem.position)"
                                            [questionSet]="formItem.questionSet"></app-question-set-items>
                </ng-container>
            </ng-container>
        </div>
    </div>
    <div class="vwui-list__action"><!----></div>
</div>
