<div class="vwui-form-group__select">
    <select id="question-dependency" class="vwui-select" (change)="questionDependencyChanged($event)">
        <option value="">{{'Kies een vraag'|translate}}</option>
        <option *ngFor="let previousQuestion of previousQuestions" [value]="previousQuestion.id" [selected]="selectedQuestion?.id === previousQuestion.id">
            {{ getChapterNumber(previousQuestion) }}.{{ getChapterPosition(previousQuestion) }} {{ previousQuestion.title }}
        </option>
    </select>
</div>

<div *ngIf="selectedQuestion" class="u-mt-m">
    <label class="vwui-label">{{'Antwoord(en)'|translate}}</label>
    <div *ngFor="let choice of selectedQuestion.choices">
        <app-checkbox #questionChoices name="questionDependencyOption[]" [value]="choice.id" [checked]="selectedChoiceIds.indexOf(choice.id) !== -1" (valueChange)="choiceChanged()">
            {{ choice.title }}
        </app-checkbox>
    </div>
</div>
