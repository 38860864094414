// @ts-strict-ignore
import {ProjectService} from '../project.service';
import {Injectable} from '@angular/core';
import {AbstractCrudServiceMock} from './abstract-crud.service.mock';
import {Observable, of} from 'rxjs';
import {Project, ProjectExportTemplate} from '../../models/project';
import {PageResponse} from '../../models/page-response';
import {FormType} from '../../models/form-type';

export const mockProjects: Array<Project> = [
    {
        id: 1,
        name: 'Stationstraat lantaarnpalen',
        code: 'P001',
        active: true,
        vise: false,
        wid: null,
        authorizations: [],
        workerGroups: [],
        coordinators: [],
        jobNotifyEmail: null,
        hasMaintenanceContract: true,
        requireVerification: false,
        requireSignature: false,
        configurationUrl: null,
        mapLayersData: [ { mapUrl: 'https://mockgis.com/arcgis/rest/services/mockservice/MapServer', id: undefined } ],
        logo: null,
        gis: false,
        sharepointExportEnabled: false,
        sharepointUrl: 'https://recognizegroup.sharepoint.com/sites/VWInfraAssetCheck/Shared Documents/Forms/AllItems.aspx?viewid=e5a76cf0-ae50-430c-b98e-ef9074912c33&id=%2Fsites%VWInfraAssetCheck%2Fopdrachten_export',
        sharepointOverrule: false,
        sharepointSiteId: 'VWInfraAssetCheck',
        sharepointExportPath: 'opdrachten_export',
        sharepointLibrary: 'Shared Documents',
        sharepointCredential: null,
        exportTemplate: ProjectExportTemplate.DEFAULT
    },
    {
        id: 2,
        name: 'Stationstraat lantaarnpalen (handtekening vereist)',
        code: 'P002',
        active: true,
        vise: false,
        wid: null,
        authorizations: [],
        workerGroups: [],
        coordinators: [],
        jobNotifyEmail: null,
        hasMaintenanceContract: true,
        requireVerification: true,
        requireSignature: true,
        configurationUrl: null,
        mapLayersData: [ { mapUrl: 'https://mockgis.com/arcgis/rest/services/mockservice/MapServer', id: undefined } ],
        logo: null,
        gis: false,
        sharepointExportEnabled: false,
        sharepointUrl: 'https://recognizegroup.sharepoint.com/sites/VWInfraAssetCheck/Shared Documents/Forms/AllItems.aspx?viewid=e5a76cf0-ae50-430c-b98e-ef9074912c33&id=%2Fsites%VWInfraAssetCheck%2Fopdrachten_export',
        sharepointOverrule: false,
        sharepointSiteId: 'VWInfraAssetCheck',
        sharepointExportPath: 'opdrachten_export',
        sharepointLibrary: 'Shared Documents',
        sharepointCredential: null,
        exportTemplate: ProjectExportTemplate.DEFAULT
    }
];

@Injectable()
export class ProjectServiceMock extends AbstractCrudServiceMock<Project> implements ProjectService {

    constructor() {
        super(mockProjects);
    }

    getProjects(page: number): Observable<PageResponse<Project>> {
        return this.getAll();
    }

    getProject(id: number): Observable<Project> {
        return this.get(id);
    }

    postProject(project: Project): Observable<Project> {
        return this.post(project);
    }

    putProject(project: Project): Observable<Project> {
        return this.put(project);
    }

    exists(code: string): Observable<number> {
        const result = mockProjects.find(item => item.code === code);

        return of(result ? result.id : null);
    }

    cloneFormType(projectId: number, title: string, formType: number, code: string): Observable<FormType> {
        return of();
    }

    exportProject(id: number): Observable<{ filename: string; data: Blob }> {
        return of(null);
    }
}
