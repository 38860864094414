import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Project} from "../../models/project";
import {FormType, ProjectForm} from "../../models/form-type";
import {BsModalRef} from "ngx-bootstrap/modal";
import {FormTypeService} from "../../services/form-type.service";
import {BehaviorSubject, firstValueFrom, merge, Observable, of, Subscription, switchMap} from "rxjs";
import {map, shareReplay} from "rxjs/operators";
import {PageResponse} from "../../models/page-response";
import {LoadingBtnEvent} from "../loading-btn/loading-btn.interface";
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";
import {HttpErrorResponse} from "@angular/common/http";

type FormExists = 'existing' | 'non_existing' | 'pending'

@Component({
  selector: 'app-copy-project-form-to-form-type-modal',
  templateUrl: './copy-project-form-to-form-type-modal.component.html'
})
export class CopyProjectFormToFormTypeModalComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];

    form = new FormGroup({
        project: new FormControl<Project | null>(null, Validators.required),
        projectForm: new FormControl<ProjectForm | null>(null, Validators.required)
    })

    formExists$: Observable<FormExists> = this.form.controls.projectForm.valueChanges.pipe(
        switchMap(form => {
            return merge(
                of<FormExists>('pending'),
                form === null
                    ? of<FormExists>('non_existing')
                    : this.formTypeService.getList(0, {
                        filter: {
                            code: {
                                operator: '=',
                                value: form.code
                            }
                        }
                    }).pipe(
                        map<PageResponse<FormType>, FormExists>(response => response.empty ? 'non_existing' : 'existing')
                    )
            );
        }),
        shareReplay({
            bufferSize: 1,
            refCount: true
        })
    )

    savingForm = new BehaviorSubject<boolean>(false);
    isLoading$ = merge(this.savingForm, this.formExists$.pipe(map(status => status == 'pending')))

  constructor(
      @Inject('FormTypeService') private formTypeService: FormTypeService,
      public bsModalRef: BsModalRef,
      private toastr: ToastrService,
      private router: Router

  ) { }

    ngOnInit() {
        this.subscriptions.push(this.form.controls.project.valueChanges.subscribe(() => {
            // Clear form if selected project changes
            this.form.controls.projectForm.setValue(null)
        }))
    }

    ngOnDestroy() {
        this.subscriptions.forEach(it => it.unsubscribe())
    }

    async submit(loadingEvent?: LoadingBtnEvent) {
        const projectForm = this.form.controls.projectForm.value

        if (this.form.valid != true || 'pending' == await firstValueFrom(this.formExists$) || projectForm == null) {
            if (loadingEvent) {
                loadingEvent.complete()
            }

            return
        }

        try {
            const formType = await firstValueFrom(this.formTypeService.copyProjectForm(projectForm.id))

            await this.router.navigate(['/beheer/form-types/', formType.id])
            this.toastr.success('Formulier overgenomen');
            this.bsModalRef.hide()
        } catch (e) {
            console.error('copyProjectForm failed', e);

            if (e instanceof HttpErrorResponse && e.error && e.error.message == 'Copying forms with followups is not supported') {
                this.toastr.error('Projectformulieren met werkopvolging overnemen is niet toegestaan');
            } else {
                this.toastr.error('Projectformulier overnemen mislukt');
            }
        } finally {
            if (loadingEvent) {
                loadingEvent.complete()
            }
        }
    }
}
