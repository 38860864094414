import {Observable} from 'rxjs';
import {MapLayerDataRequest, MapLayerDataResponse} from '../models/maplayerdata';
import {Project} from '../models/project';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

export interface ProjectMapLayersService {
    getMapLayers(projectId: number): Observable<MapLayerDataResponse>;
    putMapLayers(projectId: number, mapLayers: MapLayerDataRequest): Observable<Project>;
}

@Injectable()
export class ProjectMapLayersServiceImpl implements ProjectMapLayersService {
    constructor(private httpClient: HttpClient) {
    }

    getMapLayers(projectId: number) {
        return this.httpClient.get<MapLayerDataResponse>(`/api/v1/projects/${projectId}/map-layers`);
    }

    putMapLayers(projectId: number, mapLayers: MapLayerDataRequest) {
        return this.httpClient.put<Project>(`/api/v1/projects/${projectId}/map-layers`, mapLayers);
    }
}
