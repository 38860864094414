// @ts-strict-ignore
import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-checkbox',
    templateUrl: './checkbox.component.html'
})
export class CheckboxComponent {
    @Input() type: 'checkbox'|'radio' = 'checkbox';
    @Input() checked: boolean;
    @Input() value: any;
    @Input() name: any;
    @Input() disabled = false;
    @Output() valueChange = new EventEmitter<Event>();

    onChange(event: Event) {
        const input = event.target as HTMLInputElement;
        this.checked = input.checked;

        this.valueChange.emit(event);
    }

    get checkboxClass() {
        return {
            'is-checkbox': this.type === 'checkbox',
            'is-radio': this.type === 'radio',
            'id-disabled': this.disabled
        };
    }
}
