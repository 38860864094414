import {Component, forwardRef, Inject} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {EntitySelectComponent} from './item-select.component';
import {createPageResponse, PageResponse} from '../../models/page-response';
import {GraphService} from "../../services/graph.service";
import {Graph} from "../../models/graph";
import {map} from "rxjs/operators";

@Component({
    selector: 'app-graph-select',
    templateUrl: './item-select.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => GraphSelectComponent),
            multi: true
        },
    ]
})
export class GraphSelectComponent extends EntitySelectComponent<Graph, {}> {
    constructor(
        @Inject('GraphService') private graphService: GraphService,
    ) {
        super();
    }

    loadMore(page: number, filter: {term: string}): Observable<PageResponse<Graph>> {
        return this.graphService.getGraphs().pipe(
            map(graphs => {
                return createPageResponse(graphs.filter(graph => -1 !== graph.title.indexOf(filter.term)))
            })
        );
    }

    getLabelText(item: Graph) {
        return item.title;
    }

    getFilter(): Observable<{}> {
        return of({});
    }
}
