// @ts-strict-ignore
import {v4 as uuid} from 'uuid';
import {
    AbstractFormItem,
    AbstractQuestion,
    ChapterFormItem,
    Choice,
    ChoiceQuestion,
    DateQuestion,
    FollowUpFormTemplate,
    FollowUpProjectJobTemplate,
    FormItem,
    FormulaQuestion,
    LocationQuestion,
    NumberQuestion,
    ObjectQuestion,
    PhotoQuestion,
    QuestionType,
    ReferenceImageQuestion,
    SignatureQuestion,
    ListQuestion, TabularQuestion,
    TextQuestion
} from '../models/form-item';
import {NumberQuestionFollowUp} from "../models/number-question-follow-up";

export interface ChoiceMapping {
    [uuid: string]: Choice;
}

export class FormCloneUtils {
    static cloneChapter(item: ChapterFormItem, position: number): FormItem[] {
        const choiceMapping: ChoiceMapping = {};

        return [
            this.cloneFormItem(item, position),
            ...item.children.map(child => FormCloneUtils.cloneFormItem(
                child,
                position,
                choiceMapping
            ))
        ];
    }

    static cloneFormItem(item: FormItem, position: number, choiceMapping = {}): FormItem {
        const abstractFormItem = FormCloneUtils.copyAbstractFormItem(item, position);
        if (item.type === QuestionType.questionSet) {
            throw new Error('Not implemented');
        } else if (item.type === QuestionType.chapter) {
            return abstractFormItem as ChapterFormItem;
        } else {
            const abstractQuestion = FormCloneUtils.copyAbstractQuestion(abstractFormItem, item, choiceMapping);
            return FormCloneUtils.copyQuestion(item, abstractQuestion, choiceMapping);
        }
    }

    private static copyAbstractFormItem(
        {title, descriptionType, description, imageDescription, type, reference}: AbstractFormItem,
        position: number
    ): AbstractFormItem {
        return {id: undefined, title, description, descriptionType, imageDescription, position, type, reference};
    }

    private static copyAbstractQuestion(
        abstractItem: AbstractFormItem,
        {
            required, helpText, preFillEnabled, preFillValue, toleranceEnabled, toleranceValue, questionDependency
        }: Omit<AbstractQuestion, keyof AbstractFormItem>,
        choiceMapping: ChoiceMapping
    ): AbstractQuestion {
        return {
            ...abstractItem, required, helpText, preFillEnabled, preFillValue, toleranceEnabled, toleranceValue,
            questionDependency: FormCloneUtils.cloneQuestionDependency(questionDependency, choiceMapping)
        };
    }

    private static copyQuestion(
        originalItem: FormItem,
        abstractQuestion: AbstractQuestion,
        choiceMapping: ChoiceMapping
    ): FormItem {
        const itemType = originalItem.type
        switch (itemType) {
            case QuestionType.text:
                return FormCloneUtils.addMissingProperties<TextQuestion>({
                    multiline: originalItem.multiline
                }, abstractQuestion);
            case QuestionType.choice:
                const clonedChoiceQuestion = FormCloneUtils.addMissingProperties<ChoiceQuestion>({
                    choices: this.cloneChoices(originalItem, choiceMapping),
                    multiple: originalItem.multiple
                }, abstractQuestion);

                clonedChoiceQuestion.toleranceValue = clonedChoiceQuestion.toleranceValue.split(',')
                    .filter(it => !!it)
                    .map(toleranceItem => choiceMapping[toleranceItem.trim()].id)
                    .join(',');

                clonedChoiceQuestion.preFillValue = clonedChoiceQuestion.preFillValue.split(',')
                    .filter(it => !!it)
                    .map(prefillItem => choiceMapping[prefillItem.trim()].id)
                    .join(',');

                clonedChoiceQuestion.choices = clonedChoiceQuestion.choices.map(choice => ({
                    ...choice,
                    followUpProjectJobTemplate: this.cloneFollowUpProjectJobTemplate(choice.followUpProjectJobTemplate),
                    followUpFormTemplate: this.cloneFollowUpFormTemplate(choice.followUpFormTemplate)
                }));

                return clonedChoiceQuestion;
            case QuestionType.signature:
                return FormCloneUtils.addMissingProperties<SignatureQuestion>({}, abstractQuestion);
            case QuestionType.object:
                return FormCloneUtils.addMissingProperties<ObjectQuestion>({}, abstractQuestion);
            case QuestionType.photo:
                return FormCloneUtils.addMissingProperties<PhotoQuestion>({}, abstractQuestion);
            case QuestionType.number:
                return FormCloneUtils.addMissingProperties<NumberQuestion>({
                    totalDecimal: originalItem.totalDecimal,
                    followUp: originalItem.followUp.map(item => this.cloneNumberQuestionFollowUp(item))
                }, abstractQuestion);
            case QuestionType.table:
            case QuestionType.list:
                return FormCloneUtils.addMissingProperties<ListQuestion>({
                    questionCount: originalItem.questionCount,
                    questionLabel: originalItem.questionLabel,
                    averageTolerance: originalItem.averageTolerance
                }, abstractQuestion);
            case QuestionType.date:
                return FormCloneUtils.addMissingProperties<DateQuestion>({
                    time: originalItem.time
                }, abstractQuestion);
            case QuestionType.formula:
                return FormCloneUtils.addMissingProperties<FormulaQuestion>({
                    formulaFields: originalItem.formulaFields
                }, abstractQuestion);
            case QuestionType.referenceImage:
                return FormCloneUtils.addMissingProperties<ReferenceImageQuestion>({
                    referenceImages: originalItem.referenceImages
                }, abstractQuestion)
            case QuestionType.location:
                return FormCloneUtils.addMissingProperties<LocationQuestion>({
                    multipoint: originalItem.multipoint
                }, abstractQuestion);
            case QuestionType.tabular:
                return FormCloneUtils.addMissingProperties<TabularQuestion>({
                    pivot: originalItem.pivot,
                    columns: originalItem.columns
                }, abstractQuestion);
            case QuestionType.questionSet:
            case QuestionType.chapter:
                throw new Error(`Passing ${itemType} to copyQuestion is not supported`);
            default:
                return formTypeUnsupported(itemType)
        }
    }

    private static cloneChoices(originalItem: ChoiceQuestion, mapping: ChoiceMapping): Choice[] {
        return originalItem.choices.map(it => {
            const clonedChoice: Choice = ({
                ...it, id: uuid(),
            });
            mapping[it.id] = clonedChoice;
            if (it.followUpProjectJobTemplate) {
                clonedChoice.followUpProjectJobTemplate = {
                    ...it.followUpProjectJobTemplate
                };
            }
            return clonedChoice;
        });
    }

    private static cloneQuestionDependency(
        originalQuestionDependency: Choice[],
        mapping: ChoiceMapping
    ) {
        const mappedChoices = originalQuestionDependency
            ? originalQuestionDependency.map(choice => mapping[choice.id]).filter(it => !!it)
            : [];

        return mappedChoices.length > 0 ? mappedChoices : undefined;
    }

    private static addMissingProperties<T extends FormItem>(
        properties: Omit<T, keyof AbstractQuestion>,
        abstractQuestion: AbstractQuestion
    ): T {
        return {...abstractQuestion, ...properties} as T;
    }

    private static inSameChapter(a: AbstractFormItem, b: AbstractFormItem) {
        return Math.floor(a.position / 1000) === Math.floor(b.position / 1000);
    }

    private static cloneFollowUpProjectJobTemplate(
        followUpProjectJobTemplate: FollowUpProjectJobTemplate | undefined
    ): FollowUpProjectJobTemplate | undefined {
        if (followUpProjectJobTemplate) {
            const {id, projectForm, ...followUpWithOmittedKeys} = followUpProjectJobTemplate;
            return {
                ...followUpWithOmittedKeys,
                projectForm: (typeof projectForm !== 'number' ? projectForm.id : projectForm)
            };
        }
    }

    private static cloneFollowUpFormTemplate(
        followUpFormTemplate: FollowUpFormTemplate | undefined
    ): FollowUpFormTemplate | undefined {
        if (followUpFormTemplate) {
            const {id, formType, ...followUpWithOmittedKeys} = followUpFormTemplate;
            return {
                ...followUpWithOmittedKeys,
                formType: (typeof formType !== 'number' ? formType.id : formType)
            };
        }
    }

    private static cloneNumberQuestionFollowUp(
        numberQuestionFollowUp: NumberQuestionFollowUp
    ): NumberQuestionFollowUp {
        const {id, ...rest} = numberQuestionFollowUp

        return rest
    }

}

export function formTypeUnsupported(type: never): never {
    throw new Error('Unsupported formItem type: ' + type);
}
