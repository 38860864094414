// @ts-strict-ignore
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {Project} from '../../models/project';
import {mockProjects} from './project.service.mock';
import {ProjectCoordinatorsService} from '../project-coordinators.service';
import {User} from '../../models/user';
import {mockUsers} from './user.service.mock';

@Injectable()
export class ProjectCoordinatorsServiceMock implements ProjectCoordinatorsService {
    getCoordinators(projectId: number): Observable<Array<User>> {
        const foundProject = mockProjects.find((project) => project.id === projectId);

        return foundProject !== undefined ? of(foundProject.coordinators) : of([]);
    }

    addCoordinator(projectId: number, coordinatorId: number): Observable<Project> {
        const foundProject = mockProjects.find((project) => project.id === projectId);
        const foundUser = mockUsers.find(user => user.id === coordinatorId);

        if (foundProject && foundUser) {
            foundProject.coordinators.push(foundUser);
        }

        return of(foundProject);
    }

    removeCoordinator(projectId: number, coordinatorId: number): Observable<Project> {
        const foundProject = mockProjects.find((project) => project.id === projectId);
        const foundUser = mockUsers.find(user => user.id === coordinatorId);

        if (foundProject && foundUser) {
            foundProject.coordinators = foundProject.coordinators.filter(otherUser => otherUser.id !== foundUser.id);
        }

        return of(foundProject);
    }
}
