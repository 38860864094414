import {User} from '../models/user';
import {Observable} from 'rxjs';
import {Project} from '../models/project';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

export interface ProjectCoordinatorsService {
    getCoordinators(projectId: number): Observable<Array<User>>;
    addCoordinator(projectId: number, coordinatorId: number): Observable<Project>;
    removeCoordinator(projectId: number, coordinatorId: number): Observable<Project>;
}

@Injectable()
export class ProjectCoordinatorsServiceImpl implements ProjectCoordinatorsService {
    constructor(private httpClient: HttpClient) {
    }

    getCoordinators(projectId: number) {
        return this.httpClient.get<Array<User>>(`/api/v1/projects/${projectId}/coordinators`);
    }

    addCoordinator(projectId: number, coordinatorId: number): Observable<Project> {
        return this.httpClient.post<Project>(`/api/v1/projects/${projectId}/coordinators/${coordinatorId}`, null);
    }

    removeCoordinator(projectId: number, coordinatorId: number): Observable<Project> {
        return this.httpClient.delete<Project>(`/api/v1/projects/${projectId}/coordinators/${coordinatorId}`);
    }
}
