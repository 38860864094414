// @ts-strict-ignore
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {Subscriptions} from '../../utils/subscriptions';
import {QuestionSet} from '../../models/question-set';
import {QuestionSetService} from '../../services/question-set.service';
import {FormTypes} from '../../models/form-type';
import {HistoryService} from "../../services/history.service";

@Component({
    selector: 'app-question-set-detail',
    templateUrl: './question-set-detail.component.html'
})
export class QuestionSetDetailComponent implements OnInit, OnDestroy {
    form: UntypedFormGroup;
    questionSet: QuestionSet;

    private subscriptions: Subscription[] = [];

    constructor(
        private route: ActivatedRoute,
        private toast: ToastrService,
        private router: Router,
        @Inject('QuestionSetService') private questionSetService: QuestionSetService,
        fb: UntypedFormBuilder,
        private historyService: HistoryService,
    ) {
        this.form = this.createForm(fb);
    }

    ngOnInit() {
        const routeDataSubscription = this.route.data.subscribe((data) => {
            if (data.questionSet) {
                this.questionSet = data.questionSet;
                this.form.patchValue(this.questionSet);
            }
        });
        this.subscriptions.push(routeDataSubscription);
    }

    ngOnDestroy() {
        Subscriptions.unsubscribeAll(this.subscriptions);
    }

    get formTitle() {
        return this.questionSet ? this.questionSet.title : 'Nieuwe vragenset';
    }

    get isNew() {
        return !this.form.get('id').value;
    }

    async save() {
        const newQuestionSet = this.form.value as QuestionSet;

        try {
            let result;

            if (newQuestionSet.id) {
                result = await this.questionSetService.putQuestionSet(newQuestionSet).toPromise();
            } else {
                result = await this.questionSetService.postQuestionSet(newQuestionSet).toPromise();
            }
            this.toast.success('Opgeslagen');
            this.form.patchValue(result);

            this.goBack();
        } catch (ex) {
            console.error('Unable to save questionSet', ex);
            this.toast.error('Opslaan mislukt');
        }
    }

    cancel() {
        this.goBack();
    }

    goBack() {
        this.historyService.goBack(['/beheer/question-sets']);
    }

    goToFormQuestions() {
        this.router.navigate([this.router.url, 'questions']);
    }

    private createForm(fb: UntypedFormBuilder): UntypedFormGroup {
        return fb.group({
            id: fb.control(null),
            title: fb.control(null, [Validators.required]),
            type: fb.control(FormTypes.QuestionSet),
        });
    }
}
