// @ts-strict-ignore
import {AbstractCrudServiceMock} from './abstract-crud.service.mock';
import {ProjectJobService} from '../project-job.service';
import {ProjectJob, ProjectJobStatus, ProjectJobUpdateDTO} from '../../models/project-job';
import {TableFilter} from '../../components/table/table.component';
import {Observable, of, throwError} from 'rxjs';
import {PageResponse} from '../../models/page-response';
import {ProjectJobCount} from '../../models/project-job-count';
import {FormTypes} from '../../models/form-type';
import {map} from 'rxjs/operators';
import {ProjectJobCopyRequest} from '../../models/project-job-copy-request';
import * as moment from 'moment';
import {ProjectJobForm} from '../../models/job-form/project-job-form';
import {mockObjects} from './object.service.mock';
import {mockProjects} from './project.service.mock';
import {QuestionType} from '../../models/form-item';
import {Injectable} from '@angular/core';

export const mockProjectJobs: ProjectJob[] = [
    {
        id: 777,
        code: '123456',
        title: 'Building a sunblock',
        inspectionDate: new Date(),
        originalProjectFormId: 888,
        inspectorNames: ['Willem'],
        projectName: 'Helders desk needs special sunblock',
        status: ProjectJobStatus.InProgress,
        type: FormTypes.ProjectJob,
        executorCanCreate: true,
        askExecutorToFillFormAgain: false,
        copyAnswers: false,
        contactPerson: null,
        revisionNumber: '0',
        formItems: [
            {
                id: 2,
                type: QuestionType.chapter,
                position: 1000,
                title: 'Hoofdstuk 1',
                reference: null
            },
            {
                id: 3,
                type: QuestionType.text,
                title: 'Vraag 1.1',
                descriptionType: 'text',
                description: 'Vul maar gewoon wat in a.u.b.',
                imageDescription: null,
                helpText: 'Dit is een stukje help text, omdat de vraag nog niet duidelijk genoeg is.',
                required: true,
                position: 1001,
                preFillEnabled: false,
                preFillValue: null,
                multiline: false,
                toleranceEnabled: false,
                toleranceValue: '',
                questionDependency: [],
                reference: null
            }
        ],
        createdBy: 'h.goncalves@recognize.nl',
        createdAt: moment().toISOString(),
        updatedAt: moment().toISOString(),
        deadline: null,
        startDate: null,
        answers: [{
            id: 2,
            position: 1001,
            value: 'Test antwoord 2',
            createdAt: new Date(),
            createdBy: 'Willem',
            job: 777,
            revision: 0
        }],
        submits: [],
        originalProjectJob: null,
        originalProjectForm: null,
        extraFields: {
            locationFrom: null,
            locationTo: null,
            gpsLatitudeFrom: null,
            gpsLatitudeTo: null,
            gpsLongitudeFrom: null,
            gpsLongitudeTo: null,
            originatedChoice: null,
            originatedQuestion: null,
            maximoParent: null,
            maximoLead: null,
            maximoReference: null,
            maximoSiteId: null,
            maximoWolo4: null,
            talendWid: null
        },
        paulaObject: mockObjects[0],
        project: mockProjects[0],
        askLocation: false,
        showLocationOnMap: false,
        attachments: [],
    },
    {
        id: 778,
        code: '1234567',
        title: 'Building a pc',
        inspectionDate: new Date(),
        originalProjectFormId: 888,
        inspectorNames: [],
        projectName: 'Helders desk needs a special pc',
        status: ProjectJobStatus.Concept,
        type: FormTypes.ProjectJob,
        formItems: [],
        createdBy: 'h.goncalves@recognize.nl',
        createdAt: moment().toISOString(),
        updatedAt: moment().toISOString(),
        executorCanCreate: true,
        askExecutorToFillFormAgain: false,
        copyAnswers: false,
        contactPerson: null,
        revisionNumber: '0',
        deadline: null,
        startDate: null,
        answers: [],
        submits: [],
        originalProjectJob: null,
        originalProjectForm: null,
        extraFields: {
            locationFrom: null,
            locationTo: null,
            gpsLatitudeFrom: null,
            gpsLatitudeTo: null,
            gpsLongitudeFrom: null,
            gpsLongitudeTo: null,
            originatedChoice: null,
            originatedQuestion: null,
            maximoParent: null,
            maximoLead: null,
            maximoReference: null,
            maximoSiteId: null,
            maximoWolo4: null,
            talendWid: null
        },
        paulaObject: mockObjects[0],
        project: mockProjects[0],
        askLocation: false,
        showLocationOnMap: false,
        attachments: [],
    },
    {
        id: 779,
        code: '12345678',
        title: 'Building a djenga tower',
        inspectionDate: new Date(),
        originalProjectFormId: 888,
        inspectorNames: [],
        projectName: 'Helders desk needs a special djenga tower',
        status: ProjectJobStatus.Concept,
        type: FormTypes.ProjectJob,
        formItems: [],
        createdBy: 'h.goncalves@recognize.nl',
        createdAt: moment().toISOString(),
        updatedAt: moment().toISOString(),
        deadline: null,
        startDate: null,
        answers: [],
        submits: [],
        executorCanCreate: true,
        askExecutorToFillFormAgain: false,
        copyAnswers: false,
        contactPerson: null,
        revisionNumber: '0',
        originalProjectJob: null,
        originalProjectForm: null,
        extraFields: {
            locationFrom: null,
            locationTo: null,
            gpsLatitudeFrom: null,
            gpsLatitudeTo: null,
            gpsLongitudeFrom: null,
            gpsLongitudeTo: null,
            originatedChoice: null,
            originatedQuestion: null,
            maximoParent: null,
            maximoLead: null,
            maximoReference: null,
            maximoSiteId: null,
            maximoWolo4: null,
            talendWid: null
        },
        paulaObject: mockObjects[0],
        project: mockProjects[0],
        askLocation: false,
        showLocationOnMap: false,
        attachments: [],
    },
    {
        id: 888,
        code: '1234',
        title: 'Renovatie Waalbrug',
        inspectionDate: new Date(),
        originalProjectFormId: 999,
        inspectorNames: [],
        projectName: 'Stationstraat lantaarnpalen',
        status: ProjectJobStatus.Concept,
        type: FormTypes.ProjectJob,
        executorCanCreate: true,
        askExecutorToFillFormAgain: false,
        copyAnswers: false,
        contactPerson: null,
        revisionNumber: '0',
        formItems: [],
        createdBy: 'w.slaghekke@recognize.nl',
        createdAt: moment().toISOString(),
        updatedAt: moment().toISOString(),
        deadline: null,
        startDate: null,
        answers: [],
        submits: [],
        originalProjectJob: null,
        originalProjectForm: null,
        extraFields: {
            locationFrom: null,
            locationTo: null,
            gpsLatitudeFrom: null,
            gpsLatitudeTo: null,
            gpsLongitudeFrom: null,
            gpsLongitudeTo: null,
            originatedChoice: null,
            originatedQuestion: null,
            maximoParent: null,
            maximoLead: null,
            maximoReference: null,
            maximoSiteId: null,
            maximoWolo4: null,
            talendWid: null
        },
        paulaObject: mockObjects[0],
        project: mockProjects[0],
        askLocation: false,
        showLocationOnMap: false,
        attachments: [],
    },
    {
        id: 999,
        code: '2345',
        title: 'Sluis Ijmuiden',
        inspectionDate: new Date(),
        originalProjectFormId: 998,
        inspectorNames: ['Tim pie, Rene tie'],
        projectName: 'Stationstraat lantaarnpalen',
        status: ProjectJobStatus.Approved,
        type: FormTypes.ProjectJob,
        formItems: [],
        createdBy: 'w.slaghekke@recognize.nl',
        createdAt: moment().toISOString(),
        updatedAt: moment().toISOString(),
        executorCanCreate: true,
        askExecutorToFillFormAgain: false,
        copyAnswers: false,
        contactPerson: null,
        revisionNumber: '0',
        deadline: null,
        startDate: null,
        answers: [],
        submits: [],
        originalProjectJob: null,
        originalProjectForm: null,
        extraFields: {
            locationFrom: null,
            locationTo: null,
            gpsLatitudeFrom: null,
            gpsLatitudeTo: null,
            gpsLongitudeFrom: null,
            gpsLongitudeTo: null,
            originatedChoice: null,
            originatedQuestion: null,
            maximoParent: null,
            maximoLead: null,
            maximoReference: null,
            maximoSiteId: null,
            maximoWolo4: null,
            talendWid: null
        },
        paulaObject: mockObjects[0],
        project: mockProjects[0],
        askLocation: false,
        showLocationOnMap: false,
        attachments: [],
    },
    {
        id: 1111,
        code: '2346',
        title: 'Formulier voor verificatie',
        inspectionDate: new Date(),
        originalProjectFormId: 998,
        inspectorNames: ['Tim pie, Rene tie'],
        projectName: 'Stationstraat lantaarnpalen',
        status: ProjectJobStatus.AvailableForVerification,
        type: FormTypes.ProjectJob,
        formItems: [],
        createdBy: 'w.slaghekke@recognize.nl',
        createdAt: moment().toISOString(),
        updatedAt: moment().toISOString(),
        executorCanCreate: true,
        askExecutorToFillFormAgain: false,
        copyAnswers: false,
        contactPerson: null,
        revisionNumber: '0',
        deadline: null,
        startDate: null,
        answers: [],
        submits: [],
        originalProjectJob: null,
        originalProjectForm: null,
        extraFields: {
            locationFrom: null,
            locationTo: null,
            gpsLatitudeFrom: null,
            gpsLatitudeTo: null,
            gpsLongitudeFrom: null,
            gpsLongitudeTo: null,
            originatedChoice: null,
            originatedQuestion: null,
            maximoParent: null,
            maximoLead: null,
            maximoReference: null,
            maximoSiteId: null,
            maximoWolo4: null,
            talendWid: null
        },
        paulaObject: mockObjects[0],
        project: mockProjects[0],
        askLocation: false,
        showLocationOnMap: false,
        attachments: [],
    },
    {
        id: 1112,
        code: '2346',
        title: 'Ander formulier',
        inspectionDate: new Date(),
        originalProjectFormId: 998,
        inspectorNames: ['Tim pie, Rene tie'],
        projectName: 'Stationstraat lantaarnpalen',
        status: ProjectJobStatus.AvailableForVerification,
        type: FormTypes.ProjectJob,
        formItems: [],
        createdBy: 'w.slaghekke@recognize.nl',
        createdAt: moment().toISOString(),
        updatedAt: moment().toISOString(),
        executorCanCreate: true,
        askExecutorToFillFormAgain: false,
        copyAnswers: false,
        contactPerson: null,
        revisionNumber: '0',
        deadline: null,
        startDate: null,
        answers: [],
        submits: [],
        originalProjectJob: null,
        originalProjectForm: null,
        extraFields: {
            locationFrom: null,
            locationTo: null,
            gpsLatitudeFrom: null,
            gpsLatitudeTo: null,
            gpsLongitudeFrom: null,
            gpsLongitudeTo: null,
            originatedChoice: null,
            originatedQuestion: null,
            maximoParent: null,
            maximoLead: null,
            maximoReference: null,
            maximoSiteId: null,
            maximoWolo4: null,
            talendWid: null
        },
        paulaObject: mockObjects[0],
        project: mockProjects[1],
        askLocation: false,
        showLocationOnMap: false,
        attachments: [],
    }
];

export const mockProjectJobForms: ProjectJobForm[] = [
    {
        id: 888,
        title: 'Renovatie Waalbrug',
        createdAt: new Date(),
        updatedAt: new Date(),
        createdBy: 'w.slaghekke@recognize.nl',
        status: ProjectJobStatus.Concept,
        currentPosition: null,
        chapters: [],
        answers: [],
        answerRevision: 0,
        project: 1
    },
    {
        id: 777,
        title: 'Building a sun blocker',
        createdAt: new Date(),
        updatedAt: new Date(),
        createdBy: 'h.goncalves@recognize.nl',
        status: ProjectJobStatus.InProgress,
        currentPosition: null,
        chapters: [],
        answers: [],
        answerRevision: 0,
        project: 1
    },
    {
        id: 999,
        title: 'Sluis Ijmuiden',
        createdAt: new Date(),
        updatedAt: new Date(),
        createdBy: 'j.hobert@vwtelecom.com',
        status: ProjectJobStatus.Approved,
        currentPosition: null,
        project: 1,
        chapters: [
            {
                id: 2,
                position: 1000,
                title: 'Hoofdstuk 1',
                questions: [
                    {
                        id: 3,
                        type: 'text',
                        title: 'Vraag 1.1',
                        descriptionType: 'text',
                        description: 'Vul maar gewoon wat in a.u.b.',
                        imageDescription: null,
                        helpText: 'Dit is een stukje help text, omdat de vraag nog niet duidelijk genoeg is.',
                        required: true,
                        position: 1001,
                        preFillEnabled: false,
                        preFillValue: null,
                        multiline: false,
                        toleranceEnabled: false,
                        toleranceValue: '',
                        questionDependency: [],
                        reference: null
                    },
                    {
                        id: 21,
                        type: 'choice',
                        title: 'Keuzevraag multiple met tolerantie',
                        descriptionType: null,
                        description: null,
                        imageDescription: null,
                        helpText: null,
                        required: false,
                        position: 1002,
                        preFillEnabled: false,
                        preFillValue: null,
                        multiple: true,
                        toleranceEnabled: true,
                        toleranceValue: 'choice-1,choice-3',
                        choices: [
                            {
                                id: 'choice-1',
                                position: 1,
                                title: 'Optie 1'
                            },
                            {
                                id: 'choice-2',
                                position: 2,
                                title: 'Optie 2'
                            },
                            {
                                id: 'choice-3',
                                position: 3,
                                title: 'Optie 3'
                            }
                        ],
                        questionDependency: [],
                        reference: null
                    },
                    {
                        id: 22,
                        type: 'object',
                        title: 'Objectvraag',
                        descriptionType: 'text',
                        description: 'Vul maar gewoon wat in a.u.b.',
                        imageDescription: null,
                        helpText: 'Dit is een stukje help text, omdat de vraag nog niet duidelijk genoeg is.',
                        required: true,
                        position: 1003,
                        preFillEnabled: false,
                        preFillValue: null,
                        toleranceEnabled: false,
                        toleranceValue: '',
                        questionDependency: [],
                        reference: null
                    }
                ]
            },
            {
                id: 4,
                position: 2000,
                title: 'Hoofdstuk 2',
                questions: [
                    {
                        id: 5,
                        type: 'text',
                        title: 'Vraag 2.1 Multiline tekst',
                        descriptionType: null,
                        description: null,
                        imageDescription: null,
                        helpText: null,
                        required: false,
                        position: 2001,
                        preFillEnabled: false,
                        preFillValue: null,
                        multiline: true,
                        toleranceEnabled: false,
                        toleranceValue: '',
                        questionDependency: [],
                        reference: null
                    },
                    {
                        id: 4,
                        type: 'number',
                        title: 'Vraag 2.2 Getal',
                        descriptionType: 'text',
                        description: 'Is pipo lekker aan het vissen?',
                        imageDescription: null,
                        helpText: null,
                        required: false,
                        position: 2002,
                        preFillEnabled: true,
                        preFillValue: 'Antwoord op vraag 2.2',
                        toleranceEnabled: false,
                        toleranceValue: '',
                        questionDependency: [],
                        totalDecimal: 2,
                        reference: null
                    }
                ]
            }
        ],
        answers: [
            {
                id: 2,
                position: 1001,
                value: 'Test antwoord 2',
                createdAt: new Date(),
                createdBy: 'Testsmurf',
                job: 1,
                revision: 0
            },
            {
                id: 3,
                position: 1002,
                value: 'choice-1,choice-2',
                createdAt: new Date(),
                createdBy: 'Testsmurf',
                job: 1,
                revision: 0
            },
            {
                id: 4,
                position: 1003,
                value: '888',
                createdAt: new Date(),
                createdBy: 'Testsmurf',
                job: 1,
                revision: 0
            },
            {
                id: 5,
                position: 2001,
                value: 'Test antwoord 2\ntest\ntest\ntest',
                createdAt: new Date(),
                createdBy: 'Testsmurf',
                job: 1,
                revision: 0
            },
            {
                id: 6,
                position: 2002,
                value: '3.14',
                createdAt: new Date(),
                createdBy: 'Testsmurf',
                job: 1,
                revision: 0
            }
        ],
        answerRevision: 0
    },
    {
        id: 1111,
        title: 'Formulier voor verificatie',
        status: ProjectJobStatus.AvailableForVerification,
        createdAt: new Date(),
        updatedAt: new Date(),
        createdBy: 'j.hobert@vwtelecom.com',
        currentPosition: null,
        project: 1,
        answers: [],
        chapters: [],
        answerRevision: 0
    },
    {
        id: 1112,
        title: 'Ander formulier',
        status: ProjectJobStatus.AvailableForVerification,
        createdAt: new Date(),
        updatedAt: new Date(),
        createdBy: 'j.hobert@vwtelecom.com',
        currentPosition: null,
        project: 1,
        answers: [],
        chapters: [],
        answerRevision: 0
    },
];

@Injectable()
export class ProjectJobServiceMock extends AbstractCrudServiceMock<ProjectJob> implements ProjectJobService {

    constructor() {
        super(mockProjectJobs);
    }

    getList(page: number, filter?: TableFilter): Observable<PageResponse<ProjectJob>> {
        return this.getAll(filter);
    }

    getDetail(jobId: number): Observable<ProjectJob> {
        return this.get(jobId);
    }

    getFormDetail(jobId: number): Observable<ProjectJobForm> {
        return of(mockProjectJobForms.find(it => it.id === jobId));
    }

    transition(job: ProjectJob, status: ProjectJobStatus): Observable<ProjectJob> {
        return this.get(job.id).pipe(map(it => {
            it.status = status;
            return it;
        }));
    }


    resendExport(job: ProjectJob): Observable<void> {
        return [
            ProjectJobStatus.Approved,
            ProjectJobStatus.Rejected,
            ProjectJobStatus.DefinitivelyRejected
        ].includes(job.status)
            ? of(null)
            : throwError('current job status doesn\'t allow sending mail');
    }

    getCounters(filter: TableFilter): Observable<ProjectJobCount> {
        return this.getAll().pipe(
            map(jobs => {
                    const statusses = [
                        ProjectJobStatus.InProgress,
                        ProjectJobStatus.Approved,
                        ProjectJobStatus.AvailableForVerification,
                        ProjectJobStatus.Obstructed,
                        ProjectJobStatus.Archived,
                        ProjectJobStatus.AvailableForInspection,
                        ProjectJobStatus.Concept,
                        ProjectJobStatus.Rejected,
                        ProjectJobStatus.DefinitivelyRejected,
                    ];

                    return statusses.reduce<{[key: string]: number}>((result, status) =>  {
                        result[status] = jobs.content.filter(job => job.status === status).length;
                        return result;
                    }, {}) as ProjectJobCount;
                }
            )
        );
    }

    cloneProjectFormToJob(projectId: number, copyRequest: ProjectJobCopyRequest): Observable<ProjectJob> {
        return of({
            status: ProjectJobStatus.Concept,
            code: '2345',
            inspectionDate: new Date(),
            id: this.nextId(),
            title: copyRequest.title,
            originalProjectFormId: copyRequest.projectForm,
            inspectorNames: [],
            formItems: [],
            type: FormTypes.ProjectJob,
            createdAt: moment().toISOString(),
            updatedAt: moment().toISOString(),
        } as ProjectJob);
    }

    exists(code: string): Observable<number> {
        const result = mockProjectJobs.find(item => item.code === code);

        return of(result ? result.id : null);
    }

    exportJobs(): Observable<{ filename: string; data: Blob }> {
        return throwError('exportJobs not implemented in mock');
    }

    importJobs(file: File): Observable<any> {
        return of('');
    }

    patchProjectJobDetails(jobId: number, extras: ProjectJobUpdateDTO): Observable<ProjectJobUpdateDTO> {
        return this.put(extras);
    }

    generateJobCode(): Observable<string> {
        return of(Math.round(Math.random() * 10000).toString().padStart(5, '0'));
    }

    cloneProjectJob(projectJob: ProjectJob, newName: string, newCode: string): Observable<ProjectJob> {
        const newJob = {
            ...projectJob,
            title: newName,
            code: newCode,
            id: mockProjectJobs[mockProjectJobs.length].id + 1
        }

        mockProjectJobs.push(newJob);
        return of(newJob)
    }
}
