import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Object} from '../models/object';
import {Inject, Injectable} from '@angular/core';
import {ObjectService} from '../services/object.service';

@Injectable({
    providedIn: 'root'
})
export class ObjectResolver implements Resolve<Object> {

    constructor(
        @Inject('ObjectService') private objectService: ObjectService
    ) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.objectService.getObject(route.params.project, route.params.id);
    }
}
