// @ts-strict-ignore
import {Injectable} from '@angular/core';
import {AbstractCrudServiceMock} from './abstract-crud.service.mock';
import {Observable, of, throwError} from 'rxjs';
import {PageResponse} from '../../models/page-response';
import {FormTypes, ProjectForm, ProjectFormStatus} from '../../models/form-type';
import {TableFilter} from '../../components/table/table.component';
import {mockObjectTypes} from './object-type.service.mock';
import * as moment from 'moment';
import {ProjectFormService} from '../project-form.service';
import {mockProjects} from './project.service.mock';
import {QuestionType} from '../../models/form-item';
import {PaulaObjectType} from '../../models/paula-object-type';
import {map} from 'rxjs/operators';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {ProjectFormCopyResponse} from '../../models/project-form-copy-response';

export const mockProjectForms: Array<ProjectForm> = [
    {
        id: 1,
        code: '0001',
        type: FormTypes.ProjectForm,
        askLocation: false,
        showLocationOnMap: false,
        contactPerson: 'email@address.com',
        title: 'Boortoren formulier',
        status: ProjectFormStatus.Released,
        formOriginalId: 1,
        paulaObjectTypes: [mockObjectTypes[0]],
        formItems: [
            {
                id: 1,
                type: QuestionType.chapter,
                title: 'Hoofdstuk 1',
                position: 1000,
                reference: null
            },
            {
                id: 2,
                type: QuestionType.text,
                title: 'Tekstvraag',
                position: 1001,
                multiline: false,
                required: false,
                preFillEnabled: false,
                preFillValue: null,
                toleranceEnabled: false,
                toleranceValue: '',
                questionDependency: null,
                reference: null
            }
        ],
        createdAt: moment().toISOString(),
        createdBy: null,
        updatedAt: moment().toISOString(),
        project: mockProjects[0],
        executionGroups: [],
        projectExecutionGroups: [],
        executorCanCreate: false,
        askExecutorToFillFormAgain: false,
        copyAnswers: false,
        revisionNumber: '1'
    },
    {
        id: 2,
        code: '0002',
        type: FormTypes.ProjectForm,
        askLocation: false,
        showLocationOnMap: false,
        contactPerson: 'frans@address.com',
        title: 'Boor zonder parent',
        status: ProjectFormStatus.Released,
        formOriginalId: null,
        paulaObjectTypes: [mockObjectTypes[0]],
        formItems: [
            {
                id: 1,
                type: QuestionType.chapter,
                title: 'Hoofdstuk 1',
                position: 1000,
                reference: null
            },
            {
                id: 2,
                type: QuestionType.text,
                title: 'Tekstvraag',
                position: 1001,
                multiline: false,
                required: false,
                preFillEnabled: false,
                preFillValue: null,
                toleranceEnabled: false,
                toleranceValue: '',
                questionDependency: null,
                reference: null
            }
        ],
        createdAt: moment().toISOString(),
        createdBy: null,
        updatedAt: moment().toISOString(),
        project: mockProjects[0],
        executionGroups: [],
        projectExecutionGroups: [],
        executorCanCreate: false,
        askExecutorToFillFormAgain: false,
        copyAnswers: false,
        revisionNumber: '1'
    }
];

@Injectable()
export class ProjectFormServiceMock extends AbstractCrudServiceMock<ProjectForm> implements ProjectFormService {

    constructor() {
        super(mockProjectForms);
    }

    getList(projectId: number, page: number, filter?: TableFilter): Observable<PageResponse<ProjectForm>> {
        return this.getAll();
    }

    getDetail(projectId: number, projectFormId: number): Observable<ProjectForm> {
        return this.get(projectFormId);
    }

    putProjectForm(projectId: number, projectForm: ProjectForm): Observable<ProjectForm> {
        return this.put(projectForm);
    }

    exists(projectId: number, code: string): Observable<number> {
        return of(null);
    }

    transition(job: ProjectForm, status: ProjectFormStatus): Observable<ProjectForm> {
        return this.get(job.id).pipe(map(it => {
            it.status = status;
            return it;
        }));
    }

    cloneFormType(
        projectId: number,
        title: string,
        formType: number,
        code: string,
        objectTypes: PaulaObjectType[]
    ): Observable<ProjectFormCopyResponse> {
        if (code === 'DUBBEL') {
            return throwError(new HttpErrorResponse({
                error: {
                    globalErrors: ['Code already in use']
                }
            }));
        }

        return of();
    }

    downloadProjectForm(projectId: number, projectForm: ProjectForm): Observable<HttpResponse<Blob>> {
        return of();
    }
}
