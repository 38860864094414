<form *ngIf="form" [formGroup]="form" (submit)="save()" [class.form-is-valid]="form.valid">
    <div class="vwui-container is-small">
        <div class="vwui-card">
            <div class="vwui-card__header">{{ 'Projectinformatie'|translate }}</div>
            <div class="vwui-card__content">
                <div class="vwui-form-group u-mb-m" [appFormControlError]="form.controls.name">
                    <label for="name" class="vwui-label">{{'Projectnaam'|translate}}*</label>
                    <input id="name" class="vwui-input" name="name" formControlName="name" type="text" autocomplete="off" />
                    <div *ngIf="form.controls.name.hasError('required') && form.controls.name.touched" class="text-danger">
                        <small>{{ 'Projectnaam is verplicht'|translate }}</small>
                    </div>
                    <div *ngIf="form.controls.name.getError('maxlength') as error" class="text-danger">
                        <small>
                            {{ ('Maximaal'|translate) + ' ' + error['requiredLength'] + ' ' + ('karakters'|translate) }}
                            ({{ ('huidige invoer'|translate) + ': ' + error['actualLength'] }})
                        </small>
                    </div>
                </div>
                <div class="vwui-form-group u-mb-m" [appFormControlError]="form.controls.code">
                    <label for="code" class="vwui-label">{{'Projectnummer'|translate}}*</label>
                    <input id="code" class="vwui-input" name="code" formControlName="code" type="text" autocomplete="off" />
                    <div *ngIf="form.controls.code.hasError('required') && form.controls.code.touched" class="text-danger">
                        <small>{{ 'Projectnummer is verplicht'|translate }}</small>
                    </div>
                </div>
                <div class="vwui-form-group u-mb-m" [appFormControlError]="form.controls.jobNotifyEmail">
                    <label for="jobNotifyEmail" class="vwui-label">{{'Opdracht export e-mailadres'|translate}}</label>
                    <input id="jobNotifyEmail" class="vwui-input" name="jobNotifyEmail" formControlName="jobNotifyEmail" type="text" autocomplete="off">
                </div>
                <div class="vwui-form-group u-mb-m" [appFormControlError]="form.controls.jobNotifyEmailSubject">
                    <label for="jobNotifyEmailSubject" class="vwui-label">{{'Opdracht export e-mail onderwerp'|translate}}</label>
                    <div class="form-control__tags">
                        <ng-container *ngFor="let emailNotificationTag of emailNotificationTags">
                            <button
                                *ngIf="!form.controls.jobNotifyEmailSubject.value?.includes(emailNotificationTag)"
                                (click)="addEmailNotificationTag($event)"
                                [attr.data-tag]="emailNotificationTag"
                                type="button"
                                class="form-control__tags-item"
                                tabindex="-1"
                            ></button>
                        </ng-container>
                    </div>
                    <textarea
                        id="jobNotifyEmailSubject"
                        formControlName="jobNotifyEmailSubject"
                        class="vwui-textarea"
                        name="jobNotifyEmailSubject"
                        type="text"
                        autocomplete="off"
                        placeholder="{{ 'Onderwerp'|translate }}"
                        rows="1"
                        appTextareaAutoresize
                    ></textarea>

                    <div *ngIf="form.controls.jobNotifyEmailSubject.getError('maxlength') as error" class="text-danger">
                        <small>
                            {{ ('Maximaal'|translate) + ' ' + error['requiredLength'] + ' ' + ('karakters'|translate) }}
                            ({{ ('huidige invoer'|translate) + ': ' + error['actualLength'] }})
                        </small>
                    </div>
                </div>
                <div *appHasFeatureToggle="'customersEnabled'" class="vwui-form-group u-mb-m"
                     [appFormControlError]="form.controls.customer">
                    <label for="customer" class="vwui-label">{{'Opdrachtgever'|translate}}*</label>
                    <app-customer-select id="customer" formControlName="customer">
                    </app-customer-select>
                    <div *ngIf="form.controls.name.hasError('required') && form.controls.customer.touched" class="text-danger">
                        <small>{{ 'Opdrachtgever is verplicht'|translate }}</small>
                    </div>
                </div>
                <div class="vwui-form-group u-mb-m" [appFormControlError]="form.controls.active">
                    <label for="active" class="vwui-label">{{'Project actief'|translate}}</label>
                    <div class="vwui-toggle">
                        <input id="active" class="vwui-input" type="checkbox" formControlName="active">
                        <div class="vwui-toggle__element"></div>
                    </div>
                </div>
                <div class="vwui-form-group u-mb-m" [appFormControlError]="form.controls.vise">
                    <label for="vise" class="vwui-label">{{'Vise project'|translate}}</label>
                    <div class="vwui-toggle">
                        <input id="vise" class="vwui-input" type="checkbox" formControlName="vise">
                        <div class="vwui-toggle__element"></div>
                    </div>
                </div>
                <div [hidden]="!form.controls.vise.value" class="vwui-form-group u-mb-m" [appFormControlError]="form.controls.wid">
                    <label for="wid" class="vwui-label">{{'Workspace ID'|translate}}*</label>
                    <input id="wid" class="vwui-input" name="wid" formControlName="wid" type="text" autocomplete="off" />
                    <div *ngIf="form.controls.wid.hasError('required') && form.controls.wid.touched" class="text-danger">
                        <small>{{ 'Workspace ID is verplicht'|translate }}</small>
                    </div>
                    <div *ngIf="form.controls.wid.hasError('maxLength') && form.controls.wid.touched" class="text-danger">
                        <small>{{ 'Workspace ID mag niet meer dan 255 karakters bevatten'|translate }}</small>
                    </div>
                </div>
                <div class="vwui-form-group u-mb-m" [appFormControlError]="form.controls.hasMaintenanceContract">
                    <label for="hasMaintenanceContract" class="vwui-label">{{'Onderhoudscontract'|translate}}</label>
                    <div class="d-flex align-items-center">
                        <span class="vwui-toggle">
                            <input id="hasMaintenanceContract" class="vwui-input" type="checkbox" formControlName="hasMaintenanceContract">
                            <span class="vwui-toggle__element"></span>
                        </span>
                        <small class="vwui-form-group__helper-text">
                            {{ 'Vervolgopdrachten van een onderhoudscontract-project lopen via Maximo, en zijn daarom niet direct zichtbaar na het insturen van de opdracht.'|translate }}
                        </small>
                    </div>
                </div>
                <div class="vwui-form-group u-mb-m" [appFormControlError]="form.controls.requireVerification">
                    <label for="requireVerification" class="vwui-label">{{'Vereis verificatie'|translate}}</label>
                    <div class="vwui-toggle">
                        <input id="requireVerification" class="vwui-input" type="checkbox" formControlName="requireVerification">
                        <div class="vwui-toggle__element"></div>
                    </div>
                </div>
                <div *ngIf="form.controls.requireVerification.value" class="vwui-form-group u-mb-m" [appFormControlError]="form.controls.requireSignature">
                    <label for="requireSignature" class="vwui-label">{{'Handtekening vereist voor goedkeuring'|translate}}</label>
                    <div class="vwui-toggle">
                        <input id="requireSignature" class="vwui-input" type="checkbox" formControlName="requireSignature">
                        <div class="vwui-toggle__element"></div>
                    </div>
                </div>
                <div class="vwui-form-group" *appHasFeatureToggle="'sharepointJobExport'">
                    <div class="vwui-form-group u-mt-m" [appFormControlError]="form.controls.sharepointExportEnabled">
                        <label class="vwui-input-control is-toggle has-label mb-4 vwui-label">
                            <input class="vwui-input" type="checkbox" formControlName="sharepointExportEnabled">
                            <span>{{'Exporteer naar Sharepoint'|translate}}</span>
                            <span class="checkbox"></span>
                        </label>
                    </div>
                    <ng-container *ngIf="form.value.sharepointExportEnabled">
                        <div class="vwui-form-group u-mt-m" [appFormControlError]="form.controls.sharepointUrl">
                            <label for="sharepointUrl" class="vwui-label">{{'Sharepoint URL'|translate}}*</label>
                            <input id="sharepointUrl" class="vwui-input" name="sharepointUrl"
                                   formControlName="sharepointUrl" type="text" autocomplete="off"
                                   [tooltip]="(form.controls.sharepointUrl.disabled ? 'Sharepoint URL wordt niet bijgewerkt bij overschrijven' : null)|translate"/>
                            <div
                                *ngIf="form.controls.sharepointUrl.hasError('required') && form.controls.sharepointUrl.touched"
                                class="text-danger">
                                <small>{{ 'Sharepoint URL is verplicht'|translate }}</small>
                            </div>
                            <div *ngIf="form.controls.sharepointUrl.hasError('invalidUrl') && form.controls.sharepointUrl.touched" class="text-danger">
                                <small>{{ 'Sharepoint URL is niet geldig'|translate }}</small>
                            </div>
                        </div>

                        <div class="vwui-form-group u-mt-m" [appFormControlError]="form.controls.sharepointOverrule">
                            <label class="vwui-input-control is-toggle has-label mb-4 vwui-label">
                                <input class="vwui-input" type="checkbox" formControlName="sharepointOverrule">
                                <span>{{'Overschrijf Sharepoint waardes'|translate}}</span>
                                <span class="checkbox"></span>
                            </label>
                        </div>

                        <div class="vwui-form-group u-mt-m" [appFormControlError]="form.controls.sharepointSiteId">
                            <label for="sharepointSiteId" class="vwui-label">{{'Sharepoint site-ID'|translate}}*</label>
                            <input id="sharepointSiteId" class="vwui-input" name="sharepointSiteId"
                                   formControlName="sharepointSiteId" type="text" autocomplete="off"
                                   [tooltip]="(form.controls.sharepointUrl.enabled ? 'Sharepoint site-ID wordt bepaald op basis van de Sharepoint URL' : null)|translate"/>
                            <div
                                *ngIf="form.controls.sharepointSiteId.hasError('required') && form.controls.sharepointSiteId.touched"
                                class="text-danger">
                                <small>{{ 'Sharepoint site-ID is verplicht'|translate }}</small>
                            </div>
                        </div>

                        <div class="vwui-form-group u-mt-m" [appFormControlError]="form.controls.sharepointLibrary">
                            <label for="sharepointLibrary" class="vwui-label">{{'Sharepoint bibliotheek'|translate}}
                                *</label>
                            <input id="sharepointLibrary" class="vwui-input" name="sharepointLibrary"
                                   formControlName="sharepointLibrary" type="text" autocomplete="off"
                                   [tooltip]="(form.controls.sharepointUrl.enabled ? 'Sharepoint bibliotheek wordt bepaald op basis van de Sharepoint URL' : null)|translate"/>
                            <div
                                *ngIf="form.controls.sharepointLibrary.hasError('required') && form.controls.sharepointLibrary.touched"
                                class="text-danger">
                                <small>{{ 'Sharepoint bibliotheek is verplicht'|translate }}</small>
                            </div>
                        </div>

                        <div class="vwui-form-group u-mt-m" [appFormControlError]="form.controls.sharepointExportPath">
                            <label for="sharepointUploadPath"
                                   class="vwui-label">{{'Sharepoint export-map (pad)'|translate}}*</label>
                            <input id="sharepointUploadPath" class="vwui-input" name="sharepointUploadPath"
                                   formControlName="sharepointExportPath" type="text" autocomplete="off"
                                   [tooltip]="(form.controls.sharepointUrl.enabled ? 'Sharepoint export-map (pad) wordt bepaald op basis van de Sharepoint URL' : null)|translate"/>
                            <div
                                *ngIf="form.controls.sharepointExportPath.hasError('required') && form.controls.sharepointExportPath.touched"
                                class="text-danger">
                                <small>{{ 'Sharepoint export-map is verplicht'|translate }}</small>
                            </div>
                            <div
                                *ngIf="form.controls.sharepointExportPath.hasError('pattern') && form.controls.sharepointExportPath.touched"
                                class="text-danger">
                                <small>{{ 'De volgende tekens mogen niet gebruikt worden: " * : < > ? |'|translate }}</small>
                            </div>
                        </div>

                        <div class="vwui-form-group u-mt-m" [appFormControlError]="form.controls.sharepointCredential">
                            <label for="sharepointCredential"
                                   class="vwui-label">{{'Sharepoint inloggegevens'|translate}}*</label>
                            <app-sharepoint-credential-select
                                id="sharepointCredential"
                                formControlName="sharepointCredential"
                                [selectHostname]="sharepointHostname">
                            </app-sharepoint-credential-select>
                            <div
                                *ngIf="form.controls.sharepointCredential.touched && form.controls.sharepointCredential.value === null"
                                class="text-danger">
                                <small>{{ 'Geen inloggegevens gevonden voor domein'|translate:{domain: sharepointHostname} }}</small>
                            </div>
                        </div>
                        <div class="u-mt-m" *ngIf="sharepointConnectionStatus">
                            {{ 'Status'|translate }}: {{ ('project.sharepoint.connection_status.' + sharepointConnectionStatus)|translate }}
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <button type="submit" class="d-none"></button>
</form>

<app-actionbar *ngIf="form.dirty" (save)="save()" (cancel)="cancel()"></app-actionbar>
