import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Customer} from '../models/customer';
import {CustomerService} from '../services/customer.service';

@Injectable({
    providedIn: 'root'
})
export class CustomerResolver implements Resolve<Customer> {
    constructor(
        @Inject('CustomerService') private customerService: CustomerService
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Customer> {
        return this.customerService.getCustomer(+route.params.id);
    }
}
