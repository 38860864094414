// @ts-strict-ignore
import {Component, Inject, OnInit} from '@angular/core';
import {ProjectJobService} from '../../services/project-job.service';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ChapterFormItem, FormItem, QuestionType} from '../../models/form-item';
import {UntypedFormControl, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ModalResult} from "../../models/modal.result";

@Component({
    selector: 'app-duplicate-form-item-modal',
    templateUrl: './duplicate-form-item-modal.component.html',
})
export class DuplicateFormItemModalComponent implements OnInit {
    formItem: FormItem = null;
    formItems: FormItem[] = [];
    chapters: ChapterFormItem[] = [];

    destinationControl = new UntypedFormControl(null, Validators.required);

    onClose: (result: ModalResult, destination: ChapterFormItem) => void;

    constructor(
        @Inject('ProjectJobService') private projectJobService: ProjectJobService,
        private translateService: TranslateService,
        protected bsModalRef: BsModalRef,
    ) {
    }

    ngOnInit(): void {
        this.chapters = this.formItems.filter((it): it is ChapterFormItem => {
            return it.type === QuestionType.chapter;
        });
    }

    getModalTitle() {
        return (this.formItem.type === QuestionType.chapter ? 'Hoofdstuk' : 'Vraag')
            + ` '${this.formItem.title}' kopieren`;
    }

    getModalDescription() {
        return this.formItem.type === QuestionType.chapter
            ? this.translateService.instant('Kies het hoofdstuk waarachter dit hoofstuk moet worden gekopieerd')
            : this.translateService.instant('Kies het hoofdstuk waarnaar de vraag moet worden gekopieerd')
    }

    closeModal(result: ModalResult, data: any = null) {
        this.onClose(result, data);
        this.bsModalRef.hide();
    }

    submit() {
        if (!this.destinationControl.valid) {
            this.destinationControl.updateValueAndValidity();
            return;
        }

        this.closeModal('confirmed', this.destinationControl.value);
    }

}
