import {HttpClient, HttpParams} from '@angular/common/http';
import {PageResponse} from '../models/page-response';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {TableFilter} from '../components/table/table.component';
import {Filters} from '../utils/filters';
import {ProjectExecutionGroup} from '../models/project-execution-group';

export interface ProjectExecutionGroupService {
    getProjectExecutionGroups(projectId: number, page: number, filter?: TableFilter): Observable<PageResponse<ProjectExecutionGroup>>;
    getProjectExecutionGroup(projectId: number, id: number): Observable<ProjectExecutionGroup>;
    putProjectExecutionGroup(projectId: number, executionGroup: ProjectExecutionGroup): Observable<ProjectExecutionGroup>;
    postProjectExecutionGroup(projectId: number, executionGroup: ProjectExecutionGroup): Observable<ProjectExecutionGroup>;
    exists(projectId: number, title: string): Observable<number>;
}

@Injectable()
export class ProjectExecutionGroupServiceImpl implements ProjectExecutionGroupService {
    constructor(private httpClient: HttpClient) {
    }

    getProjectExecutionGroups(projectId: number, page: number, filter?: TableFilter) {
        let params = new HttpParams().set('page', '' + page);

        if (filter) {
            params = Filters.applyFiltering(filter, params);
        }

        return this.httpClient.get<PageResponse<ProjectExecutionGroup>>(`/api/v1/projects/${projectId}/execution-groups`, {params});
    }

    getProjectExecutionGroup(projectId: number, id: number) {
        return this.httpClient.get<ProjectExecutionGroup>(`api/v1/projects/${projectId}/execution-groups/${id}`);
    }

    putProjectExecutionGroup(projectId: number, executionGroup: ProjectExecutionGroup) {
        return this.httpClient.put<ProjectExecutionGroup>(`api/v1/projects/${projectId}/execution-groups/${executionGroup.id}`, executionGroup);
    }

    postProjectExecutionGroup(projectId: number, executionGroup: ProjectExecutionGroup) {
        return this.httpClient.post<ProjectExecutionGroup>(`api/v1/projects/${projectId}/execution-groups`, executionGroup);
    }

    exists(projectId: number, title: string) {
        const params = new HttpParams().set('title', title);

        return this.httpClient.get<number>(`api/v1/projects/${projectId}/execution-groups/exists`, {params});
    }
}
