<app-toolbar [toolbarTitle]="projectForm.title" [showBackBtn]="true">
    <button class="vwui-btn is-primary has-icon" (click)="goToFormQuestions()">
        <app-icon iconName="edit-2"></app-icon>
        {{'Vragen beheren'|translate}}
    </button>
</app-toolbar>
<div class="vwui-container is-full">
    <div class="row">
        <div class="col-lg-8">
            <div class="vwui-card">
                <div class="vwui-card__header">
                    <div class="vwui-card__title">{{'Formulier'|translate}}</div>
                </div>
                <div class="vwui-card__content">
                    <form [formGroup]="form" (submit)="save()">
                        <div [appFormControlError]="form.get('title')" class="vwui-form-group mb-4">
                            <label for="title" class="vwui-label">{{'Formuliernaam'|translate}}</label>
                            <input id="title" name="title" type="text" class="vwui-input" formControlName="title">
                        </div>
                        <div [appFormControlError]="form.get('code')" class="vwui-form-group mb-4">
                            <label for="code" class="vwui-label">{{'Formuliernummer'|translate}}</label>
                            <input id="code" name="code" type="text" class="vwui-input" formControlName="code">
                        </div>
                        <div class="u-mb-m">
                            <div [appFormControlError]="form.get('executionGroups')" class="vwui-form-group mb-4">
                                <label for="executionGroups"
                                       class="vwui-label">{{'Disciplines(optioneel)'|translate}}</label>
                                <app-execution-group-select id="executionGroups"
                                                            formControlName="executionGroups">
                                </app-execution-group-select>
                            </div>
                        </div>
                        <div class="u-mb-m">
                            <div [appFormControlError]="form.get('projectExecutionGroups')"
                                 class="vwui-form-group mb-4">
                                <label for="projectExecutionGroups"
                                       class="vwui-label">{{'Projectdisciplines(optioneel)'|translate}}</label>
                                <app-project-execution-group-select [projectId]="this.projectForm.project.id"
                                                                    id="projectExecutionGroups"
                                                                    formControlName="projectExecutionGroups">
                                </app-project-execution-group-select>
                            </div>
                        </div>
                        <div class="u-mb-m">
                            <div [appFormControlError]="form.get('paulaObjectTypes')" class="vwui-form-group mb-4">
                                <label for="executionGroups" class="vwui-label">{{'Objecttypes'|translate}}</label>
                                <app-paula-object-type-select id="paulaObjectTypes" [multiple]="true"
                                                              formControlName="paulaObjectTypes"></app-paula-object-type-select>
                            </div>
                        </div>
                        <div class="u-mb-m">
                            <div [appFormControlError]="form.get('contactPersoon')" class="vwui-form-group mb-4">
                                <label for="contactPerson" class="vwui-label">{{'Contactpersoon'|translate}}</label>
                                <app-user-select id="contactPerson" formControlName="contactPerson"></app-user-select>
                            </div>
                        </div>
                        <div [appFormControlError]="form.get('tags')" class="vwui-form-group mb-4">
                            <label for="tags" class="vwui-label">{{'Tags'|translate}}</label>
                            <app-tag-select id="tags" formControlName="tags" [multiple]="true"></app-tag-select>
                        </div>
                        <div [appFormControlError]="form.get('revisionNumber')" class="vwui-form-group mb-4">
                            <label for="revisionNumber" class="vwui-label">{{ 'Revisienummer'|translate }}</label>
                            <input id="revisionNumber" name="revisionNumber" type="text" class="vwui-input"
                                   formControlName="revisionNumber"
                                   placeholder="{{ 'Revisienummer'|translate }}">
                        </div>
                        <div class="u-mb-m">
                            <div [appFormControlError]="form.get('askLocation')" class="vwui-form-group  mb-4">
                                <label class="vwui-input-control is-toggle has-label mb-4 vwui-label">
                                    <input class="vwui-input" type="checkbox" formControlName="askLocation">
                                    <span>{{'Vraag locatie (uit / aan)'|translate}}</span>
                                    <span class="checkbox"></span>
                                </label>
                            </div>
                        </div>
                        <div class="u-mb-m" *ngIf="form.get('askLocation').value">
                            <div [appFormControlError]="form.get('showLocationOnMap')" class="vwui-form-group mb-4">
                                <label class="vwui-input-control is-toggle has-label mb-4 vwui-label">
                                    <input class="vwui-input" type="checkbox" formControlName="showLocationOnMap">
                                    <span>{{'Toon locatie op kaart (uit / aan)'|translate}}</span>
                                    <span class="checkbox"></span>
                                </label>
                            </div>
                        </div>
                        <div class="u-mb-m">
                            <div [appFormControlError]="form.get('copyAnswers')" class="vwui-form-group mb-4">
                                <label class="vwui-input-control is-toggle has-label mb-4 vwui-label">
                                    <input class="vwui-input" type="checkbox" formControlName="copyAnswers">
                                    <span>{{'Keurder vragen of antwoorden overgenomen moeten worden uit eerdere opdracht'|translate}}</span>
                                    <span class="checkbox"></span>
                                </label>
                            </div>
                            <div [appFormControlError]="form.get('executorCanCreate')" class="vwui-form-group mb-4">
                                <label class="vwui-input-control is-toggle has-label mb-4 vwui-label">
                                    <input class="vwui-input" type="checkbox" formControlName="executorCanCreate">
                                    <span>{{'In app te kiezen bij aanmaken opdracht'|translate}}</span>
                                    <span class="checkbox"></span>
                                </label>
                            </div>
                            <div [appFormControlError]="form.get('askExecutorToFillFormAgain')"
                                 class="vwui-form-group mb-4 ml-4">
                                <label class="vwui-input-control is-toggle has-label mb-4 vwui-label">
                                    <input class="vwui-input" type="checkbox"
                                           formControlName="askExecutorToFillFormAgain">
                                    <span>{{'Vraag gebruiker of formulier nogmaals ingevuld moet worden'|translate}}</span>
                                    <span class="checkbox"></span>
                                </label>
                            </div>
                        </div>
                        <button type="submit" class="d-none"><!-- Needed to submit with enter --></button>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="vwui-card">
                <div class="vwui-card__header">{{'Gerelateerde formulieren'|translate}}</div>
                <div class="vwui-card__content">
                    <div class="vwui-form-group mb-4">
                        <label for="originalFormTitle"
                               class="vwui-label">{{'Oorspronkelijk master-data formulier'|translate}}</label>
                        <input id="originalFormTitle" name="originalFormTitle" class="vwui-input is-plain"
                               type="text" autocomplete="off" disabled [value]="originalFormTitle$ | async">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-actionbar *ngIf="form.dirty" (save)="save()" (cancel)="goBack()"></app-actionbar>
