// @ts-strict-ignore
import {TableFilter} from '../components/table/table.component';
import {Observable} from 'rxjs';
import {PageResponse} from '../models/page-response';
import {ProjectJob, ProjectJobStatus, ProjectJobUpdateDTO} from '../models/project-job';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Filters} from '../utils/filters';
import {ProjectJobCount} from '../models/project-job-count';
import {ProjectJobCopyRequest} from '../models/project-job-copy-request';
import {ProjectJobForm} from '../models/job-form/project-job-form';
import {map} from 'rxjs/operators';

export interface ProjectJobService {
    getList(page: number, filter: TableFilter): Observable<PageResponse<ProjectJob>>;

    getDetail(jobId: number): Observable<ProjectJob>;

    getFormDetail(jobId: number): Observable<ProjectJobForm>;

    patchProjectJobDetails(jobId: number, data: Partial<ProjectJobUpdateDTO>): Observable<ProjectJobUpdateDTO>;

    getCounters(filter: TableFilter): Observable<ProjectJobCount>;

    transition(job: ProjectJob, status: ProjectJobStatus, comment?: string): Observable<ProjectJob>;

    resendExport(job: ProjectJob): Observable<void>;

    cloneProjectFormToJob(projectId: number, copyRequest: ProjectJobCopyRequest): Observable<ProjectJob>;

    exists(code: string): Observable<number>;

    exportJobs(filter: TableFilter): Observable<{ filename: string, data: Blob }>;

    importJobs(file: File): Observable<any>;

    generateJobCode(): Observable<string>;

    cloneProjectJob(projectJob: ProjectJob, newName: string, newCode: string): Observable<ProjectJob>;
}

@Injectable({
    providedIn: 'root'
})
export class ProjectJobServiceImpl implements ProjectJobService {

    constructor(private httpClient: HttpClient) {
    }

    cloneProjectJob(sourceJob: ProjectJob, title: string, code: string): Observable<ProjectJob> {
        return this.httpClient.post<ProjectJob>(`/api/v1/project-jobs/${sourceJob.id}/clone`, {
            title,
            code,
        });
    }

    getList(page: number, filter?: TableFilter): Observable<PageResponse<ProjectJob>> {
        let params = new HttpParams().set('page', '' + page);

        if (filter) {
            params = Filters.applyFiltering(filter, params);
        }

        return this.httpClient.get<PageResponse<ProjectJob>>(`/api/v1/project-jobs`, {params});
    }

    getDetail(jobId: number): Observable<ProjectJob> {
        return this.httpClient.get<ProjectJob>(`/api/v1/project-jobs/${jobId}`);
    }

    getFormDetail(jobId: number): Observable<ProjectJobForm> {
        return this.httpClient.get<ProjectJobForm>(`/api/v1/project-jobs/${jobId}/form`);
    }

    getCounters(filter: TableFilter): Observable<ProjectJobCount> {
        let params = new HttpParams();

        if (filter) {
            // Remove status filter
            const {status, ...otherFilters} = filter.filter

            params = Filters.applyFiltering({
                sort: filter.sort,
                filter: otherFilters
            }, params);
        }

        return this.httpClient.get<ProjectJobCount>(`/api/v1/project-jobs/counters`, {params});
    }

    patchProjectJobDetails(jobId: number, data: ProjectJobUpdateDTO): Observable<any> {
        return this.httpClient.put<ProjectJobUpdateDTO>(`/api/v1/project-jobs/${jobId}/extras`, data);
    }

    transition(job: ProjectJob, status: ProjectJobStatus, comment?: string) {
        const data = new FormData()
        if (comment !== null) {
            data.append('comment', comment)
        }
        return this.httpClient.post<ProjectJob>(`/api/v1/project-jobs/${job.id}/transition`, data, {params: {status}});
    }

    resendExport(job: ProjectJob) {
        return this.httpClient.post<void>(`/api/v1/project-jobs/${job.id}/resend-export`, '');
    }

    cloneProjectFormToJob(projectId: number, copyRequest: ProjectJobCopyRequest): Observable<ProjectJob> {
        return this.httpClient.post<ProjectJob>(`/api/v1/projects/${projectId}/jobs/copy`, copyRequest);
    }

    exists(code: string): Observable<number> {
        const params = new HttpParams().set('code', code);

        return this.httpClient.get<number>(`api/v1/project-jobs/exists`, {params});
    }

    exportJobs(filter: TableFilter): Observable<{ filename: string; data: Blob }> {
        let params = new HttpParams();

        if (filter) {
            params = Filters.applyFiltering(filter, params);
        }
        return this.httpClient.get(`api/v1/project-jobs/export`, {
            params,
            observe: 'response',
            responseType: 'blob'
        }).pipe(map(it => {
            const contentDisposition = it.headers.get('Content-Disposition');
            const filename = contentDisposition.match(/filename="(.+)"/)[1];
            return {filename, data: it.body};
        }));
    }

    importJobs(file: File) {
        const data = new FormData();
        data.append('file', file, file.name);

        return this.httpClient.post(`api/v1/project-jobs/import`, data);
    }

    generateJobCode(): Observable<string> {
        return this.httpClient.get<{ code: string }>('api/v1/project-jobs/code').pipe(map(it => it.code));
    }
}
