// @ts-strict-ignore
import {Component, Inject, Input} from '@angular/core';
import {Project} from '../../models/project';
import {BsModalService} from 'ngx-bootstrap/modal';
import {ProjectFormModalComponent} from '../project-form-modal/project-form-modal.component';
import {ToastrService} from 'ngx-toastr';
import {take} from 'rxjs/operators';
import {HeaderTitle, TableFilter} from '../table/table.component';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {ProjectFormService} from '../../services/project-form.service';
import {ProjectForm} from '../../models/form-type';
import {AbstractListComponent} from '../../pages/abstract-list.component';
import {PageResponse} from '../../models/page-response';
import {HttpErrorResponse} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {FileUtil} from '../../utils/file';
import {ProjectMultipleFormsModalComponent} from "../project-multiple-forms-modal/project-multiple-forms-modal.component";

@Component({
    selector: 'app-project-forms-tab',
    templateUrl: './project-forms-tab.component.html'
})
export class ProjectFormsTabComponent extends AbstractListComponent<ProjectForm> {
    @Input() project: Project;

    tableHeaders: HeaderTitle[] = [
        {sortKey: 'title', filterKey: 'title', title: this.translate.instant('Formuliernaam')},
        {sortKey: 'code', filterKey: 'code', title: this.translate.instant('Formuliernummer')},
        {filterKey: 'paulaObjectTypes.title', title: this.translate.instant('Objecttype')},
        {filterKey: 'executionGroups.title', title: this.translate.instant('Disciplines')},
        {sortKey: 'contactPerson', filterKey: 'contactPerson', title: this.translate.instant('Contactpersoon')},
        {sortKey: 'updatedAt', filterKey: 'updatedAt', title: this.translate.instant('Laatste wijziging')},
        {filterKey: 'tags.name', title: this.translate.instant('Tags')},
    ];

    constructor(
        @Inject('ProjectFormService') private projectFormService: ProjectFormService,
        protected activatedRoute: ActivatedRoute,
        protected router: Router,
        private modalService: BsModalService,
        private toast: ToastrService,
        private translate: TranslateService,
    ) {
        super(activatedRoute, router);
    }

    loadData(page: number, filter: TableFilter): Observable<PageResponse<ProjectForm>> {
        return this.projectFormService.getList(this.project.id, page, filter);
    }

    openAddFormModal() {
        const bsModalRef = this.modalService.show(ProjectFormModalComponent, {
            class: 'modal-dialog-centered',
            initialState: {projectId: this.project.id}
        });

        this.modalService.onHide.pipe(
            take(1)
        ).subscribe(() => {
            if (bsModalRef.content.result === 'confirmed') {
                this.refresh();
                this.toast.success('Formulier aangemaakt');
            } else if (bsModalRef.content.result === 'confirmed_with_workorders') {
                this.refresh();
                this.toast.success('Formulier met gekoppelde vervolgformulieren aangemaakt \n' +
                    'Controleer de keuzes of de vervolgopdrachten naar wens zijn.');
            }
        });
    }

    openAddMultipleFormsModal() {
        const bsModalRef = this.modalService.show(ProjectMultipleFormsModalComponent, {
            class: 'modal-dialog-centered modal-xxl',
            initialState: {projectId: this.project.id}
        });

        this.modalService.onHide.pipe(
            take(1)
        ).subscribe(() => {
            if (bsModalRef.content.result === 'confirmed') {
                this.refresh();
                this.toast.success('Formulier(en) aangemaakt');
            }
        });
    }

    onFormClick(item: ProjectForm) {
        this.router.navigate(['/beheer/projects/', this.project.id, 'form', item.id]);
    }

    async downloadProjectForm(item: ProjectForm) {
        try {
            const httpResponse = await this.projectFormService.downloadProjectForm(this.project.id, item).toPromise();
            const contentDisposition = httpResponse.headers.get('content-disposition');
            const fileName = contentDisposition.split('filename=')[1].replace(/"/g, '');

            FileUtil.downloadBlobAsFile(httpResponse.body, fileName);
        } catch (error) {
            console.error(error);
            if (error instanceof HttpErrorResponse) {
                this.toast.error(this.translate.instant('Exporteren formulier is mislukt.'));
            }
        }
    }

    async transitionTo(event: Event, item: ProjectForm, status: string) {
        event.stopPropagation();
        try {
            await this.projectFormService.transition(item, status as any).toPromise();
            await this.refresh();
        } catch (e) {
            console.error(e);
        }
    }
}
