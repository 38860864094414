import {Observable} from 'rxjs';
import {WorkerGroup} from '../models/worker-group';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Project} from '../models/project';

export interface ProjectWorkerGroupsService {
    getWorkerGroups(projectId: number): Observable<WorkerGroup[]>;
    addWorkerGroup(projectId: number, workerGroupId: number): Observable<Project>;
    removeWorkerGroup(projectId: number, workerGroupId: number): Observable<Project>;
}

@Injectable()
export class ProjectWorkerGroupsServiceImpl implements ProjectWorkerGroupsService {
    constructor(private httpClient: HttpClient) {
    }

    getWorkerGroups(projectId: number): Observable<WorkerGroup[]> {
        return this.httpClient.get<WorkerGroup[]>(`/api/v1/projects/${projectId}/worker-groups`);
    }

    addWorkerGroup(projectId: number, workerGroupId: number): Observable<Project> {
        return this.httpClient.post<Project>(`/api/v1/projects/${projectId}/worker-groups/${workerGroupId}`, null);
    }

    removeWorkerGroup(projectId: number, workerGroupId: number): Observable<Project> {
        return this.httpClient.delete<Project>(`/api/v1/projects/${projectId}/worker-groups/${workerGroupId}`);
    }
}
