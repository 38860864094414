import {WorkOrder} from './work-order';
import {Identifiable} from "./identifiable";

export interface InterfaceProcessingError extends Identifiable {
    workOrder: WorkOrder|null;
    reference?: string;
    message: string;
    data: {[key: string]: string};
    resolvedAt: Date;
    type: InterfaceProcessingErrorType;
    retryable: boolean;
    occurredAt: Date;
}

export interface InterfaceProcessingErrorDetail extends InterfaceProcessingError {
    xml: string;
}

export enum InterfaceProcessingErrorType {
    Maximo = 'Maximo',
    Vise = 'Vise',
    Quintiq = 'Quintiq',
    Sharepoint = 'Sharepoint',
    JobTransition = 'JobTransition'
}
