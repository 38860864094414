<div class="vwui-container is-full">
    <app-users-grid *ngIf="(projectAuthorization$|async) as projectAuthorizations">
        <app-users-search
                (search)="searchChanged($event)"
                (userSelected)="addUser($event)"
                (groupSelected)="addGroup($event)"
                [config]="searchConfigs$|async">
        </app-users-search>
        <ng-container *ngIf="projectAuthorizations.length > 0; else empty">
            <app-users-table
                title="{{'Medewerkers'|translate}}"
                [total]="projectAuthorizations.length"
            >
                <thead>
                <tr>
                    <th>{{'Medewerker'|translate}}</th>
                    <th>{{'Werkgever'|translate}}</th>
                    <th>{{'Projectdisciplines'|translate}}</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let authorization of sortByDisplayName(projectAuthorizations)">
                    <td>
                        <del *ngIf="authorization.user?.roles?.indexOf('ROLE_EXECUTOR') === -1; else activeExecutor" tooltip="Inactieve gebruiker, deze gebruiker kan niet inloggen op de app.">
                            {{ authorization.user.displayName }}<br>
                            {{ authorization.user.mail }}
                        </del>
                        <ng-template #activeExecutor>
                            {{ authorization.user.displayName }}<br>
                            {{ authorization.user.mail }}
                        </ng-template>
                    </td>
                    <td>{{ authorization.user.companyName }}</td>
                    <td>
                        <span class="vwui-tag vwui-tag--default" *ngFor="let executionGroup of authorization.projectExecutionGroups">
                            {{ executionGroup.title | truncate:50 }}
                        </span>
                    </td>
                    <td>
                        <div class="vwui-table__actions">
                            <app-action-menu>
                                <button class="vwui-menu-item" (click)="openUserModal(authorization)">
                                    <app-icon iconName="edit-2"></app-icon>
                                    {{'Bewerken'|translate}}
                                </button>
                                <button class="vwui-menu-item is-danger" (click)="removeUser(authorization.user)">
                                    <app-icon iconName="trash-2"></app-icon>
                                    {{'Verwijderen'|translate}}
                                </button>
                            </app-action-menu>
                        </div>
                    </td>
                </tr>
                </tbody>
            </app-users-table>
            <div class="u-mb-l"></div>
        </ng-container>
        <ng-container *ngIf="(projectWorkerGroups$|async) as workerGroups">
            <app-users-table
                title="{{'Groepen'|translate}}"
                *ngIf="workerGroups.length > 0"
                [total]="workerGroups.length"
            >
                <thead>
                    <tr>
                        <th>{{'Groepnaam'|translate}}</th>
                        <th>{{'Aantal medewerkers'|translate}}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let workerGroup of workerGroups">
                        <td>{{ workerGroup.name }}</td>
                        <td>{{ workerGroup.workerGroupUsers.length }}</td>
                        <td>
                            <div class="vwui-table__actions">
                                <app-action-menu>
                                    <button class="vwui-menu-item is-danger" (click)="removeWorkerGroup(workerGroup)">
                                        <app-icon iconName="trash-2"></app-icon>
                                        {{'Verwijderen'|translate}}
                                    </button>
                                </app-action-menu>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </app-users-table>
        </ng-container>
        <ng-template #empty>
            <app-users-empty-card *ngIf="!(projectWorkerGroups$|async)?.length">
                {{'Selecteer medewerkers of groepen om het projectteam samen te stellen'|translate}}
            </app-users-empty-card>
        </ng-template>
    </app-users-grid>
</div>
