import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AsideService {
    asideOpen: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor() { }

    open() {
        this.asideOpen.next(true);

        document.body.classList.add('aside-open');
    }

    close() {
        this.asideOpen.next(false);

        document.body.classList.remove('aside-open');
    }
}
