import {Injectable} from "@angular/core";
import {AbstractCrudServiceMock} from "./abstract-crud.service.mock";
import {SharepointCredential, SharepointCredentialListDTO} from "../../models/sharepoint-credential";
import {SharepointCredentialService} from "../sharepoint-credential.service";
import {Observable, of} from "rxjs";
import {PageResponse} from "../../models/page-response";
import {PartialIdentiable} from "../../models/identifiable";

export const mockSharepointCredentials: Array<SharepointCredential> = [
    {
        id: 1,
        title: 'VWInfra',
        type: "Password",
        hostname: 'vwinfra.sharepoint.com',
        clientId: 'id',
        clientSecret: 'secret',
        tenantId: 'tenant',
        username: 'user',
        password: 'pass'
    },
    {
        id: 2,
        title: 'BVGO',
        type: "Password",
        hostname: 'bvgo.sharepoint.com',
        clientId: 'id',
        clientSecret: 'secret',
        tenantId: 'tenant',
        username: 'user',
        password: 'pass'
    }
]

@Injectable()
export class SharepointCredentialServiceMock extends AbstractCrudServiceMock<SharepointCredential> implements SharepointCredentialService {

    constructor() {
        super(mockSharepointCredentials)
    }

    getSharepointCredentials(page: number): Observable<PageResponse<SharepointCredentialListDTO>> {
        return this.getAll();
    }

    getSharepointCredential(id: number): Observable<SharepointCredential> {
        return this.get(id);
    }

    exists(hostname: string): Observable<number | null> {
        const result = mockSharepointCredentials.find(item => item.hostname == hostname)

        return of(result ? result.id: null)
    }

    postSharepointCredential(sharepointCredential: SharepointCredential): Observable<SharepointCredential> {
        return this.post(sharepointCredential);
    }

    putSharepointCredential(sharepointCredential: PartialIdentiable<SharepointCredential>): Observable<SharepointCredential> {
        return this.put(sharepointCredential)
    }
}
