<app-toolbar [showBackBtn]="true" backBtnRoute="/beheer/users"></app-toolbar>
<div class="vwui-container is-full">
    <div class="row">
        <div class="col-lg-6">
            <div class="vwui-card">
                <div class="vwui-card__header">{{'Gebruiker'|translate}}</div>
                <div class="vwui-card__content">
                    <form [formGroup]="form" (submit)="save()">
                        <div [appFormControlError]="form.get('username')" class="vwui-form-group mb-4">
                            <label for="username" class="vwui-label">{{'UPN / Gebruikersnaam'|translate}}</label>
                            <input id="username" name="username" type="text" class="vwui-input" formControlName="username">
                        </div>
                        <div class="vwui-form-group mb-4" *ngIf="user && user.mail">
                            <label class="vwui-label">{{'E-mailadres'|translate}}</label>
                            <input type="text" class="vwui-input" disabled [value]="user.mail">
                        </div>
                        <div class="vwui-form-group mb-4" *ngIf="user && user.displayName">
                            <label class="vwui-label">{{'Naam'|translate}}</label>
                            <input type="text" class="vwui-input" disabled [value]="user.displayName">
                        </div>
                        <div class="vwui-form-group mb-4" *ngIf="user && user.company">
                            <label class="vwui-label">{{'Bedrijf'|translate}}</label>
                            <input type="text" class="vwui-input" disabled [value]="user.company.name">
                        </div>
                        <div class="vwui-form-group mb-4">
                            <label class="vwui-label">{{'Handtekening'|translate}}</label>
                            <app-image-upload [multiple]="false" formControlName="signatureId"></app-image-upload>
                        </div>
                        <div [appFormControlError]="form.get('roles')" class="vwui-form-group mb-4">
                            <h5>{{'Rollen'|translate}}</h5>
                            <app-user-roles formControlName="roles"></app-user-roles>
                        </div>
                        <div *ngIf="showWarning" class="text-danger">
                            Let op! Er ontbreken rollen die nodig zijn om in te loggen op de app of beheer<span *ngIf="dirtyForm"> (na opslaan van dit formulier)</span>.
                        </div>
                        <button type="submit" class="d-none"><!-- Needed to submit with enter --></button>
                    </form>

                    <div *ngIf="userContentCode$ && (userContentCode$ | async) as contentCode">
                        <p class="vwui-label">Apple contentcode</p>
                        <p>
                            <a [href]=contentCode.link target="_blank">{{contentCode.link}}</a>
                        </p>
                        <button
                            id="decoupleAppleContentCode"
                            (click)="decouple()"
                            tooltip="Koppelt deze gebruiker los van de Apple contentcode. Na deze actie kan de gebruiker een nieuwe contentcode verkrijgen"
                            class="vwui-btn is-danger">
                            <app-icon iconName="trash-2"></app-icon>
                            Ontkoppel contentcode
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6" *ngIf="userProjects">
            <div class="vwui-card">
                <div class="vwui-card__header">{{'Projecten'|translate}}</div>
                <table class="vwui-card__content vwui-table">
                    <tr>
                        <th>{{'Project'|translate}}</th>
                        <th>{{'Rollen'|translate}}</th>
                    </tr>
                    <tr *ngFor="let project of userProjects">
                        <td>{{ project.project.name }}</td>
                        <td>{{ rolesToTranslatedString(project.roles) }}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>

<app-actionbar *ngIf="form.dirty" (cancel)="cancel()" (save)="save()"></app-actionbar>
