// @ts-strict-ignore
import {ProjectWorkerGroupsService} from '../project-worker-groups.service';
import {Observable, of} from 'rxjs';
import {WorkerGroup} from '../../models/worker-group';
import {mockProjects} from './project.service.mock';
import {Project} from '../../models/project';
import {mockWorkergroup} from './worker-group.service.mock';
import {Injectable} from '@angular/core';

@Injectable()
export class ProjectWorkerGroupServiceMock implements ProjectWorkerGroupsService {
    getWorkerGroups(projectId: number): Observable<WorkerGroup[]> {
        const foundProject = mockProjects.find(project => project.id === projectId);

        return of(foundProject.workerGroups.map(projectWorkerGroup => projectWorkerGroup.workerGroup));
    }

    addWorkerGroup(projectId: number, workerGroupId: number): Observable<Project> {
        const foundProject = mockProjects.find(project => project.id === projectId);
        const foundWorkerGroup = mockWorkergroup.find(workerGroup => workerGroup.id === workerGroupId);

        foundProject.workerGroups = [...foundProject.workerGroups, {
            project: projectId,
            workerGroup: foundWorkerGroup
        }];

        return of(foundProject);
    }

    removeWorkerGroup(projectId: number, workerGroupId: number): Observable<Project> {
        const foundProject = mockProjects.find(project => project.id === projectId);
        foundProject.workerGroups = foundProject.workerGroups
            .filter(projectWorkerGroup => projectWorkerGroup.workerGroup.id !== workerGroupId);

        return of(foundProject);
    }
}
