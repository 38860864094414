import {Component, forwardRef, Inject} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {EntitySelectComponent} from './item-select.component';
import {PageResponse} from '../../models/page-response';
import {PaulaObjectType} from '../../models/paula-object-type';
import {ObjectTypeService} from '../../services/object-type.service';

@Component({
    selector: 'app-paula-object-type-select',
    templateUrl: './item-select.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PaulaObjectTypeSelectComponent),
            multi: true
        },
    ]
})
export class PaulaObjectTypeSelectComponent extends EntitySelectComponent<PaulaObjectType, {}> {
    constructor(
        @Inject('ObjectTypeService') private objectTypeService: ObjectTypeService,
    ) {
        super();

        this.placeholder = 'Selecteer een objecttype';
    }

    loadMore(page: number, filter: {term: string}): Observable<PageResponse<PaulaObjectType>> {
        return this.objectTypeService.getObjectTypes(page, {
            filter: {
                title: filter && filter.term ? filter.term : ''
            }
        });
    }

    getLabelText(item: PaulaObjectType) {
        return item.title;
    }

    getFilter(): Observable<{}> {
        return of({});
    }
}
