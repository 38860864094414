import {AbstractControl, FormArray, ValidatorFn} from "@angular/forms";
import {TableColumnFormGroup} from "../models/table-column";

export function tableColumnKeyUniqueValidator(
    columnFormArray: FormArray<TableColumnFormGroup>
): ValidatorFn {
    return (control: AbstractControl) => {
        if (!control.value) {
            // Skip validation for empty values
            return null;
        }

        return -1 !== columnFormArray.controls.findIndex(group => {
            const groupKeyControl = group.controls.key
            return groupKeyControl != control && groupKeyControl.value === control.value
        }) ? {unique: true} : null
    }
}
