import {ErrorHandler, Injectable} from "@angular/core";
import {ApplicationInsightsService} from "../services/application-insights.service";

@Injectable()
export class AppInsightsErrorHandler extends ErrorHandler {

    constructor(
        private applicationInsightsService: ApplicationInsightsService
    ) {
        super();
    }

    handleError(error: any): void {
        super.handleError(error)

        this.applicationInsightsService.trackException({ exception: error })
    }

}
