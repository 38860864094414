// @ts-strict-ignore
import {Injectable} from '@angular/core';
import {AbstractCrudServiceMock} from './abstract-crud.service.mock';
import {Observable, of} from 'rxjs';
import {PageResponse} from '../../models/page-response';
import {TableFilter} from '../../components/table/table.component';
import {Customer} from '../../models/customer';
import {CustomerService} from '../customer.service';
import {CustomerSketchSet} from '../../models/customer-sketch-set';
import {CustomerReportTemplate} from '../../models/customer-report-template';

export const mockCustomers: Array<Customer> = [
    {
        id: 1,
        name: 'Opdrachtgever',
        description: 'Omschrijving',
        sketchSet: 'Default',
        reportTemplate: 'Default',
    }
];

@Injectable()
export class CustomerServiceMock extends AbstractCrudServiceMock<Customer> implements CustomerService {
    constructor() {
        super(mockCustomers);
    }

    getCustomers(page: number, filter?: TableFilter): Observable<PageResponse<Customer>> {
        return this.getAll(filter);
    }

    exists(name: string): Observable<number> {
        const result = mockCustomers.find(item => item.name === name);

        return of(result ? result.id : null);
    }

    getCustomerSketchSets(): Observable<CustomerSketchSet[]> {
        return of([]);
    }

    getCustomerReportTemplates(): Observable<CustomerReportTemplate[]> {
        return of([]);
    }

    getCustomer(id: number): Observable<Customer> {
        return this.get(id);
    }

    postCustomer(customer: Customer): Observable<Customer> {
        return this.post(customer);
    }

    putCustomer(customer: Customer): Observable<Customer> {
        return this.put(customer);
    }

    deleteCustomer(customer: Customer): Observable<Customer> {
        return this.delete(customer);
    }
}
