export interface PageResponse<T> {
    content: T[];
    empty: boolean;
    first: boolean;
    last: boolean;
    number: number;
    numberOfElements: number;
    size: number;
    totalElements: number;
    totalPages: number;
}

export function createEmptyPageResponse<T>(): PageResponse<T> {
    return {
        content: [],
        empty: true,
        first: true,
        last: true,
        number: 0,
        numberOfElements: 25,
        size: 0,
        totalElements: 0,
        totalPages: 1
    };
}


export function createPageResponse<T>(items: T[]): PageResponse<T> {
    return {
        content: items,
        empty: true,
        first: true,
        last: true,
        number: 0,
        numberOfElements: 25,
        size: items.length,
        totalElements: items.length,
        totalPages: 1
    };
}
