<app-toolbar
    [showAddBtn]="true"
    (add)="createUser()"
    addBtnLabel="{{'Nieuwe gebruiker'|translate}}">
</app-toolbar>
<div class="vwui-container">
    <div class="table-filter">
        <div class="vwui-filter">
            <ng-container *ngFor="let filter of userFilters">
                <div class="vwui-filter__item"
                     [class.is-active]="appliedFilter?.filter?.roles === filter.role"
                     (click)="selectRoleFilter(filter.role)">
                    {{ filter.title }}
                </div>
            </ng-container>
        </div>
    </div>
    <app-table *ngIf="paginator$|async;let paginator"
               [paginator]="paginator" [headerTitles]="headerTitles"
               (filterChanged)="onTableFilterChange($event)">
        <ng-template let-item>
            <tr [routerLink]="['/beheer/users/', item.id]">
                <td>{{ item.username }}</td>
                <td>{{ item.displayName }}</td>
                <td>{{ item.company ? item.company.name : "" }}</td>
            </tr>
        </ng-template>
    </app-table>
</div>
