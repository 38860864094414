import {Component, Inject} from '@angular/core';
import {AbstractListComponent} from '../abstract-list.component';
import {AbstractAuthenticationProviderConfig} from '../../models/authentication-provider-config';
import {HeaderTitle, TableFilter} from '../../components/table/table.component';
import {AuthenticationProviderConfigService} from '../../services/authentication-provider-config.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {PageResponse} from '../../models/page-response';

@Component({
    selector: 'app-authentication-provider-config-list',
    templateUrl: './authentication-provider-config-list.component.html'
})
export class AuthenticationProviderConfigListComponent extends AbstractListComponent<AbstractAuthenticationProviderConfig> {
    headerTitles: HeaderTitle[] = [
        {sortKey: 'title', filterKey: 'title', title: 'Titel'},
        {sortKey: 'type', filterKey: 'type', title: 'Soort inlogmethode'},
        {sortKey: 'defaultProject.name', filterKey: 'defaultProject.name', title: 'Standaard project'},
        {title: 'Toestemming'}
    ];

    constructor(
        @Inject('AuthenticationProviderConfigService') private registrationService: AuthenticationProviderConfigService,
        protected activatedRoute: ActivatedRoute,
        protected router: Router,
    ) {
        super(activatedRoute, router);
    }

    loadData(page: number, filter: TableFilter): Observable<PageResponse<AbstractAuthenticationProviderConfig>> {
        return this.registrationService.getRegistrations(page, filter);
    }
}
