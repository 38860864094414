// @ts-strict-ignore
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Data, Router} from '@angular/router';
import {FormTypes, ProjectForm} from '../../models/form-type';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    UntypedFormControl,
    ValidationErrors,
    Validators
} from '@angular/forms';
import {firstValueFrom, Observable, of, Subscription} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {Subscriptions} from '../../utils/subscriptions';
import {Forms} from '../../utils/forms';
import {ProjectService} from '../../services/project.service';
import {ProjectFormService} from '../../services/project-form.service';
import {AuthenticationService} from '../../services/authentication.service';
import {UserService} from '../../services/user.service';
import {User} from '../../models/user';
import {HistoryService} from "../../services/history.service";
import {first, map, switchMap} from "rxjs/operators";
import {FormTypeService} from "../../services/form-type.service";

@Component({
    selector: 'app-project-form-detail',
    templateUrl: './project-form-detail.component.html'
})
export class ProjectFormDetailComponent implements OnInit, OnDestroy {
    public projectForm: ProjectForm;
    public form = new FormGroup({
        id: new UntypedFormControl(null),
        title: new UntypedFormControl(null, [Validators.required, Validators.maxLength(320)]),
        code: new UntypedFormControl(null, [Validators.required, Validators.maxLength(255)], this.validateCodeNotTaken.bind(this)),
        paulaObjectTypes: new FormControl([], {nonNullable: true}),
        project: new FormControl(null, {nonNullable: true}),
        tags: new UntypedFormControl([]),
        type: new FormControl<FormTypes.ProjectForm>(FormTypes.ProjectForm),
        executionGroups: new UntypedFormControl([]),
        projectExecutionGroups: new UntypedFormControl([]),
        askLocation: new FormControl<boolean>(false, {nonNullable: true}),
        contactPerson: new UntypedFormControl({username: this.authenticationService.loggedInEmail$.value}),
        revisionNumber: new UntypedFormControl(null),
        showLocationOnMap: new FormControl<boolean>(true, {nonNullable: true}),
        copyAnswers: new FormControl<boolean>(false, {nonNullable: true}),
        executorCanCreate: new FormControl<boolean>(true, {nonNullable: true}),
        askExecutorToFillFormAgain: new FormControl<boolean>(true, {nonNullable: true})
    });
    public users: User[] = [];

    private subscriptions: Subscription[] = [];

    readonly originalFormTitle$: Observable<string | null>  = this.route.data.pipe(
        map<Data, ProjectForm>( data => data.projectForm),
        switchMap(projectForm => projectForm.formOriginalId !== null
            ? this.formTypeService.getDetail(projectForm.formOriginalId)
            : of(null)),
        map(formType => formType !== null ? formType.title : "Onbekend")
    )

    constructor(
        @Inject('ProjectService') private projectService: ProjectService,
        @Inject('ProjectFormService') private projectFormService: ProjectFormService,
        @Inject('UserService') private userService: UserService,
        @Inject('FormTypeService') private formTypeService: FormTypeService,
        private route: ActivatedRoute,
        private router: Router,
        private toast: ToastrService,
        private authenticationService: AuthenticationService,
        private historyService: HistoryService
    ) {
    }

    ngOnInit(): void {
        const routeDataSubscription = this.route.data.subscribe((data) => {
            if (data.projectForm) {
                this.projectForm = data.projectForm;
                this.patchForm(data.projectForm);
            }
        });

        this.subscriptions.push(routeDataSubscription);

        this.subscriptions.push(this.form.controls.executorCanCreate.valueChanges.subscribe(executorCanCreate => {
            if (executorCanCreate) {
                this.form.controls.askExecutorToFillFormAgain.enable();
            } else {
                this.form.controls.askExecutorToFillFormAgain.disable();
                this.form.controls.askExecutorToFillFormAgain.setValue(false);
            }
        }))
    }

    ngOnDestroy(): void {
        Subscriptions.unsubscribeAll(this.subscriptions);
    }

    async save() {
        if (!this.form.valid) {
            Forms.updateValueAndValidityRecursive(this.form);
            this.toastInvalidForm();
            return;
        }

        try {
            const projectForm = this.form.getRawValue()
            if (this.form.controls.contactPerson.value) {
                projectForm.contactPerson = this.form.controls.contactPerson.value.username;
            }

            const savedProjectForm = await firstValueFrom(this.projectFormService.putProjectForm(this.projectForm.project.id, projectForm));

            this.projectForm = savedProjectForm;
            this.patchForm(savedProjectForm);

            this.toast.success('Opgeslagen');
        } catch (error) {
            console.error(error);
            this.toast.error('Opslaan mislukt');
        }
    }

    goToFormQuestions() {
        this.router.navigate([this.router.url, 'questions']);
    }

    goBack() {
        this.route.paramMap.pipe(first()).subscribe(map => {
            this.historyService.goBack([`/beheer/projects/${map.get('id')}`]);
        })
    }

    private toastInvalidForm() {
        this.toast.error('Vul alle verplichte velden in (in het rood aangegeven)');
    }

    validateCodeNotTaken(control: AbstractControl): Observable<ValidationErrors> {
        const project = this.form.get('project').value;
        const currentId = +this.form.get('id').value;

        return Forms.validateNotTaken(
            control,
            currentId,
            this.projectFormService.exists(project && project.id, control.value)
        );
    }

    private patchForm(form: ProjectForm) {
        this.form.patchValue({
            ...form,
            contactPerson: {username: form.contactPerson}
        });
    }
}
