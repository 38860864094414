import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginModalComponent} from './components/login-modal/login-modal.component';
import {AuthCallbackComponent} from './components/auth-callback/auth-callback.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {JWT_OPTIONS, JwtModule} from '@auth0/angular-jwt';
import {AuthenticationService} from './services/authentication.service';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {AuthenticatedGuard} from './services/authenticated-guard.service';
import {ModalModule} from 'ngx-bootstrap/modal';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {NotAuthorizedModalComponent} from './components/not-authorized-modal/not-authorized-modal.component';
import {AuthCheckService} from './services/auth-check.service';
import {InlineSVGModule} from 'ng-inline-svg';
import localeNL from '@angular/common/locales/nl';
import {registerLocaleData} from '@angular/common';
import {VwuiModule} from './vwui/vwui.module';
import {FormTypeListComponent} from './pages/form-type-list/form-type-list.component';
import {FormTypeService, FormTypeServiceImpl} from './services/form-type.service';
import {PaginatorComponent} from './components/paginator/paginator.component';
import {FormTypeDetailComponent} from './pages/form-type-detail/form-type-detail.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ObjectTypeListComponent} from './pages/object-type-list/object-type-list.component';
import {ObjectTypeDetailComponent} from './pages/object-type-detail/object-type-detail.component';
import {ToastNoAnimationModule, ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule, NoopAnimationsModule} from '@angular/platform-browser/animations';
import {ControlFormErrorDirective} from './directives/control-form-error.directive';
import {FormTypeStatusTitlePipe} from './pipes/form-type-status-title.pipe';
import {DateFormatPipe} from './pipes/date-format.pipe';
import {DateHttpInterceptorService} from './interceptor/date-http-interceptor.service';
import {ForbiddenResponseInterceptor} from './interceptor/forbidden-response.interceptor';
import {NgSelectModule} from '@ng-select/ng-select';
import {PaulaObjectTypeSelectComponent} from './components/item-select/paula-object-type-select.component';
import {environment} from '../environments/environment';
import {ObjectTypeService, ObjectTypeServiceImpl} from './services/object-type.service';
import {ObjectTypeServiceMock} from './services/mock/object-type.service.mock';
import {QuestionsListComponent} from './components/questions-list/questions-list.component';
import {QuestionEditComponent} from './components/question-edit/question-edit.component';
import {FormBuilderComponent} from './pages/form-builder/form-builder.component';
import {FormViewerComponent} from './components/form-viewer/form-viewer.component';
import {UserListComponent} from './pages/user-list/user-list.component';
import {TableComponent} from './components/table/table.component';
import {UserDetailComponent} from './pages/user-detail/user-detail.component';
import {UserRolesComponent} from './components/user-roles/user-roles.component';
import {UserService, UserServiceImpl} from './services/user.service';
import {UserServiceMock} from './services/mock/user.service.mock';
import {HasRoleDirective} from './directives/has-role.directive';
import {HasFeatureToggleDirective} from './directives/has-feature-toggle.directive';
import {AuthCheckServiceMock} from './services/mock/auth-check.service.mock';
import {FeatureToggleCheckServiceMock} from './services/mock/feature-toggle-check.service.mock';
import {QuestionSetDetailComponent} from './pages/question-set-detail/question-set-detail.component';
import {QuestionSetService, QuestionSetServiceImpl} from './services/question-set.service';
import {QuestionSetServiceMock} from './services/mock/question-set.service.mock';
import {QuestionChoicesComponent} from './components/question-choices/question-choices.component';
import {LoginOverlayComponent} from './components/login-overlay/login-overlay.component';
import {QuestionTypeTitlePipe} from './pipes/question-type-title.pipe';
import {SortablejsModule} from 'ngx-sortablejs';
import {FormTypeServiceMock} from './services/mock/form-type.service.mock';
import {QuestionServiceMock} from './services/mock/question.service.mock';
import {QuestionServiceImpl} from './services/question.service';
import {QuestionDependencyComponent} from './components/question-dependency/question-dependency.component';
import {CheckboxComponent} from './components/checkbox/checkbox.component';
import {QuestionListItemComponent} from './components/question-list-item/question-list-item.component';
import {AddBtnComponent} from './components/add-btn/add-btn.component';
import {QuestionAnswerComponent} from './components/question-answer/question-answer.component';
import {FormItemChapterTitleComponent} from './components/form-item-chapter-title/form-item-chapter-title.component';
import {QuestionSetCardComponent} from './components/question-set-card/question-set-card.component';
import {QuestionSetListComponent} from './pages/question-set-list/question-set-list.component';
import {QuestionSetItemsComponent} from './components/question-set-items/question-set-items.component';
import {ProjectListComponent} from './pages/project-list/project-list.component';
import {ProjectServiceImpl} from './services/project.service';
import {ProjectServiceMock} from './services/mock/project.service.mock';
import {EmptyStateMessageComponent} from './components/empty-state-message/empty-state-message.component';
import {ProjectDetailComponent} from './pages/project-detail/project-detail.component';
import {ProjectDetailTabComponent} from './components/project-detail-tab/project-detail-tab.component';
import {ProjectUsersTabComponent} from './components/project-users-tab/project-users-tab.component';
import {GraphServiceImpl} from './services/graph.service';
import {GraphServiceMock} from './services/mock/graph.service.mock';
import {ProjectFormModalComponent} from './components/project-form-modal/project-form-modal.component';
import {FormTypeSelectComponent} from './components/item-select/form-type-select.component';
import {ProjectFormsTabComponent} from './components/project-forms-tab/project-forms-tab.component';
import {
    UserExecutionGroupModalComponent
} from './components/user-execution-group-modal/user-execution-group-modal.component';
import {ModalComponent} from './components/modal/modal.component';
import {ProjectObjectTabComponent} from './components/project-object-tab/project-object-tab.component';
import {ObjectDetailComponent} from './pages/object-detail/object-detail.component';
import {ObjectService, ObjectServiceImpl} from './services/object.service';
import {ObjectServiceMock} from './services/mock/object.service.mock';
import {WorkerGroupListComponent} from './pages/worker-group-list/worker-group-list.component';
import {WorkerGroupServiceImpl} from './services/worker-group.service';
import {WorkerGroupServiceMock} from './services/mock/worker-group.service.mock';
import {WorkergroupDetailComponent} from './pages/worker-group-detail/worker-group-detail.component';
import {UsersSearchComponent} from './components/users-search/users-search.component';
import {UsersGridComponent} from './components/users-grid/users-grid.component';
import {UsersEmptyCardComponent} from './components/users-empty-card/users-empty-card.component';
import {UsersTableComponent} from './components/users-table/users-table.component';
import {WorkerGroupBuilderComponent} from './pages/worker-group-builder/worker-group-builder.component';
import {ProjectFormDetailComponent} from './pages/project-form-detail/project-form-detail.component';
import {ProjectFormServiceImpl} from './services/project-form.service';
import {ProjectFormServiceMock} from './services/mock/project-form.service.mock';
import {ExecutionGroupListComponent} from './pages/execution-group-list/execution-group-list.component';
import {ExecutionGroupDetailComponent} from './pages/execution-group-detail/execution-group-detail.component';
import {ExecutionGroupServiceImpl} from './services/execution-group.service';
import {ExecutionGroupServiceMock} from './services/mock/execution-group.service.mock';
import {NgxFileDropModule} from 'ngx-file-drop';
import {ImageUploadComponent} from './components/image-upload/image-upload.component';
import {ImagePreviewModalComponent} from './components/image-preview-modal/image-preview-modal.component';
import {
    ImageAnswerPreviewModalComponent
} from './components/image-answer-preview-modal/image-answer-preview-modal.component';
import {ExecutionGroupSelectComponent} from './components/item-select/execution-group-select.component';
import {ProjectJobListComponent} from './pages/project-job-list/project-job-list.component';
import {ProjectJobServiceImpl} from './services/project-job.service';
import {ProjectJobServiceMock} from './services/mock/project-job.service.mock';
import {ProjectJobModalComponent} from './components/project-job-modal/project-job-modal.component';
import {ProjectSelectComponent} from './components/item-select/project-select.component';
import {ProjectFormSelectComponent} from './components/item-select/project-form-select.component';
import {ObjectSelectComponent} from './components/item-select/object-select.component';
import {
    AuthenticationProviderConfigListComponent
} from './pages/authentication-provider-config-list/authentication-provider-config-list.component';
import {
    AuthenticationProviderConfigDetailComponent
} from './pages/authentication-provider-config-detail/authentication-provider-config-detail.component';
import {AuthenticationProviderConfigServiceImpl} from './services/authentication-provider-config.service';
import {AuthenticationProviderConfigServiceMock} from './services/mock/authentication-provider-config.service.mock';
import {UserSelectCardComponent} from './components/user-select/user-select-card.component';
import {
    ProjectCoordinatorsTabComponent
} from './components/project-coordinators-tab/project-coordinators-tab.component';
import {JobExportService} from './services/job-export.service';
import {ExternalLinkComponent} from './components/external-link/external-link.component';
import {MultiSelectTableComponent} from './components/multi-select-table/multi-select-table.component';
import {
    FollowUpProjectJobModalComponent
} from './components/follow-up-project-job-modal/follow-up-project-job-modal.component';
import {FollowUpFormModalComponent} from './components/follow-up-form-modal/follow-up-form-modal.component';
import {
    QuestionFollowUpProjectJobsComponent
} from './components/question-follow-up-project-jobs/question-follow-up-project-jobs.component';
import {QuestionFollowUpFormsComponent} from './components/question-follow-up-forms/question-follow-up-forms.component';
import {ProjectJobDetailComponent} from './pages/project-job-detail/project-job-detail.component';
import {NlToBrPipe} from './pipes/nl-to-br.pipe';
import {ProjectJobDetailTabComponent} from './components/project-job-detail-tab/project-job-detail-tab.component';
import {ProjectJobAnswersTabComponent} from './components/project-job-answers-tab/project-job-answers-tab.component';
import {FileUploadBtnComponent} from './components/file-upload-btn/file-upload-btn.component';
import {LoadingBtnComponent} from './components/loading-btn/loading-btn.component';
import {FlatpickrModule} from 'angularx-flatpickr';
import {ProjectJobStatusPipe} from './pipes/project-job-status-title.pipe';
import {ProjectMapLayersTabComponent} from './components/project-map-layers-tab/project-map-layers-tab.component';
import {ProjectMapComponent} from './pages/project-map/project-map.component';
import {ProjectSelectModalComponent} from './components/project-select-modal/project-select-modal.component';
import {JobLocationSelectMapComponent} from './components/job-location-select-map/job-location-select-map.component';
import {
    NoJobsWithLocationModalComponent
} from './components/no-jobs-with-location-modal/no-jobs-with-location-modal.component';
import {ArrayPropertyValuesPipe} from './pipes/array-property-values.pipe';
import {FileInputValueAccessorDirective} from './components/file-input-value-accessor';
import {ProjectMapLayersServiceImpl} from './services/project-map-layers.service';
import {ProjectMapLayersServiceMock} from './services/mock/project-map-layers.service.mock';
import {ProjectCoordinatorsServiceImpl} from './services/project-coordinators.service';
import {ProjectCoordinatorsServiceMock} from './services/mock/project-coordinators.service.mock';
import {ProjectAuthorizationsServiceImpl} from './services/project-authorizations.service';
import {ProjectAuthorizationsServiceMock} from './services/mock/project-authorizations.service.mock';
import {ProjectWorkerGroupsServiceImpl} from './services/project-worker-groups.service';
import {ProjectWorkerGroupServiceMock} from './services/mock/project-worker-group.service.mock';
import {
    DuplicateFormItemModalComponent
} from './components/duplicate-form-item-modal/duplicate-form-item-modal.component';
import {
    ProjectMapLayerPropertiesModalComponent
} from './components/project-map-layer-properties-modal/project-map-layer-properties-modal.component';
import {ImageAnnotationModalComponent} from './components/image-annotation-modal/image-annotation-modal.component';
import {TableQuestionAnswersPipe} from './pipes/table-question-answers.pipe';
import {ImageAnnotationComponent} from './components/image-annotation/image-annotation.component';
import {CompanyListComponent} from './pages/company-list/company-list.component';
import {CompanyServiceImpl} from './services/company.service';
import {CompanyServiceMock} from './services/mock/company.service.mock';
import {TagServiceImpl} from './services/tag.service';
import {TagServiceMock} from './services/mock/tag.service.mock';
import {CompanyDetailComponent} from './pages/company-detail/company-detail.component';
import {CompanySelectComponent} from './components/item-select/company-select.component';
import {TagSelectComponent} from './components/item-select/tag-select.component';
import {TranslateModule} from '@ngx-translate/core';
import {
    InterfaceProcessingErrorService,
    InterfaceProcessingErrorServiceImpl
} from './services/interface-processing-error.service';
import {SumPipe} from './pipes/sum.pipe';
import {AvgPipe} from './pipes/avg.pipe';
import {FeatureToggleCheckService} from './services/feature-toggle-check.service';
import {AppleContentCodesListComponent} from './pages/apple-content-codes-list/apple-content-codes-list.component';
import {
    AppleContentCodesImportModalComponent
} from './components/apple-content-codes-import-modal/apple-content-codes-import-modal.component';
import {
    InterfaceProcessingErrorDetailComponent
} from './pages/interface-processing-error-detail/interface-processing-error-detail.component';
import {AppleContentCodeServiceImpl} from './services/apple-content-code.service';
import {AppleContentCodeServiceMock} from './services/mock/apple-content-code.service.mock';
import {AppleContentGetCodeUrlComponent} from './pages/apple-content-get-code-url/apple-content-get-code-url.component';
import {RootComponent} from './pages/root/root.component';
import {CustomerServiceImpl} from './services/customer.service';
import {CustomerListComponent} from './pages/customer-list/customer-list.component';
import {CustomerServiceMock} from './services/mock/customer.service.mock';
import {CustomerDetailComponent} from './pages/customer-detail/customer-detail.component';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {ProjectJobRejectModalComponent} from './components/project-job-reject-modal/project-job-reject-modal.component';
import {TruncatePipe} from './pipes/truncate.pipe';
import {BlobService} from './services/blob.service';
import {BlobHrefDirective} from './directives/blob-href.directive';
import {FormTypeCopyComponent} from './pages/form-type-copy/form-type-copy.component';
import {FormTypeExampleComponent} from './pages/form-type-example/form-type-example.component';
import {ProjectExportTabComponent} from './components/project-export-tab/project-export-tab.component';
import {SharepointServiceImpl} from './services/sharepoint.service';
import {InterfaceProcessingErrorServiceMock} from './services/mock/interface-processing-error.service.mock';
import {
    InterfaceProcessingErrorListComponent
} from './pages/interface-processing-error-list/interface-processing-error-list.component';
import {UserSelectComponent} from './components/item-select/user-select.component';
import {
    ImportFormSuccessModalComponent
} from './components/import-form-success-modal/import-form-success-modal.component';
import {JwtConfig} from '@auth0/angular-jwt/lib/angular-jwt.module';
import {FormTypeStatusServiceImpl} from './services/form-type-status.service';
import {FormTypeStatusServiceMock} from './services/mock/form-type-status.service.mock';
import {ImportFormV2ModalComponent} from './components/import-form-v2-modal/import-form-v2-modal.component';
import {ImportTemplateService} from './services/import-template.service';
import {SharepointServiceMock} from './services/mock/sharepoint.service.mock';
import {UploadServiceImpl} from './services/upload.service';
import {UploadServiceMock} from './services/mock/upload.service.mock';
import {FileUploadComponent} from './components/file-upload/file-upload.component';
import {ProjectJobCloneModalComponent} from './components/project-job-clone-modal/project-job-clone-modal.component';
import {ProjectExecutionGroupServiceImpl} from './services/project-execution-group.service';
import {ProjectExecutionGroupServiceMock} from './services/mock/project-execution-group.service.mock';
import {ProjectExecutionGroupSelectComponent} from './components/item-select/project-execution-group-select.component';
import {
    ProjectExecutionGroupDetailComponent
} from './pages/project-execution-group-detail/project-execution-group-detail.component';
import {
    ProjectExecutionGroupTabComponent
} from './components/project-execution-group-tab/project-execution-group-tab.component';
import {ObstructionCodeListComponent} from "./pages/obstruction-code-list/obstruction-code-list.component";
import {ObstructionCodeServiceImpl} from "./services/obstruction-code.service";
import {ObstructionCodeServiceMock} from "./services/mock/obstruction-code.service.mock";
import {ObstructionCodeDetailComponent} from "./pages/obstruction-code-detail/obstruction-code-detail.component";
import {
    ProjectMultipleFormsModalComponent
} from './components/project-multiple-forms-modal/project-multiple-forms-modal.component';
import {ValidationErrorComponent} from "./components/validation-error/validation-error.component";
import {SharepointCredentialServiceImpl} from "./services/sharepoint-credential.service";
import {SharepointCredentialServiceMock} from "./services/mock/sharepoint-credential.service.mock";
import {
    SharepointCredentialListComponent
} from "./pages/sharepoint-credential-list/sharepoint-credential-list.component";
import {
    SharepointCredentialDetailComponent
} from "./pages/sharepoint-credential-detail/sharepoint-credential-detail.component";
import {CustomerSelectComponent} from "./components/item-select/customer-select.component";
import {SharepointCredentialSelectComponent} from "./components/item-select/sharepoint-credential-select.component";
import {QuestionTypeLocationPipe} from "./pipes/question-type-location.pipe";
import {MultiImageUploadComponent} from "./components/multi-image-upload/multi-image-upload.component";
import {
    QuestionFollowUpNumberQuestionComponent
} from "./components/question-follow-up-number-question/question-follow-up-number-question.component";
import {FollowUpNumberQuestionModalComponent} from './components/follow-up-number-question-modal/follow-up-number-question-modal.component';
import {ChoiceQuestionSettingsModalComponent} from './components/choice-question-settings-modal/choice-question-settings-modal.component';
import {CreateUserModalComponent} from './components/create-user-modal/create-user-modal.component';
import {GraphSelectComponent} from "./components/item-select/graph-select.component";
import {GraphUserSelectComponent} from "./components/item-select/graph-user-select.component";
import { TextareaAutoresizeDirective } from './directives/textarea-autoresize.directive';
import { QuestionEditTabularColumnTextComponent } from './components/question-edit-tabular-column-text/question-edit-tabular-column-text.component';
import { QuestionEditTabularColumnNumberComponent } from './components/question-edit-tabular-column-number/question-edit-tabular-column-number.component';
import {CopyProjectFormToFormTypeModalComponent} from './components/copy-project-form-to-form-type-modal/copy-project-form-to-form-type-modal.component';
import {RouterlinkStopPropagationDirective} from './directives/routerlink-stop-propagation.directive';
import {AppInsightsErrorHandler} from "./utils/app-insights-error-handler";

registerLocaleData(localeNL, 'nl');

export function jwtOptionsFactory(authenticationService: AuthenticationService): JwtConfig {
    return {
        tokenGetter: () => {
            return authenticationService.getJwtToken();
        },
        allowedDomains: [/.*/],
        disallowedRoutes: [
            /\/assets\/i18n\/[a-z]+\.json$/
        ]
    };
}

export function serviceWithMock(provide: string, service: any, mock: any): any {
    return {
        useClass: environment.useMock ? mock : service,
        provide: provide
    };
}

@NgModule({
    declarations: [
        AppComponent,
        LoginModalComponent,
        AuthCallbackComponent,
        NotAuthorizedModalComponent,
        FormTypeListComponent,
        FormTypeDetailComponent,
        PaginatorComponent,
        DashboardComponent,
        ObjectTypeListComponent,
        ObjectTypeDetailComponent,
        ObstructionCodeListComponent,
        ObstructionCodeDetailComponent,
        SharepointCredentialListComponent,
        SharepointCredentialDetailComponent,
        FormTypeStatusTitlePipe,
        ProjectJobStatusPipe,
        QuestionTypeTitlePipe,
        DateFormatPipe,
        NlToBrPipe,
        SumPipe,
        AvgPipe,
        QuestionTypeLocationPipe,
        PaulaObjectTypeSelectComponent,
        CompanySelectComponent,
        TagSelectComponent,
        QuestionsListComponent,
        QuestionEditComponent,
        FormBuilderComponent,
        UserListComponent,
        TableComponent,
        UserDetailComponent,
        UserRolesComponent,
        ControlFormErrorDirective,
        HasRoleDirective,
        HasFeatureToggleDirective,
        BlobHrefDirective,
        QuestionSetCardComponent,
        QuestionSetListComponent,
        QuestionSetDetailComponent,
        QuestionChoicesComponent,
        LoginOverlayComponent,
        QuestionDependencyComponent,
        CheckboxComponent,
        QuestionListItemComponent,
        AddBtnComponent,
        QuestionAnswerComponent,
        FormItemChapterTitleComponent,
        QuestionSetItemsComponent,
        ProjectListComponent,
        EmptyStateMessageComponent,
        ProjectDetailComponent,
        ProjectDetailTabComponent,
        ProjectMapLayersTabComponent,
        ProjectUsersTabComponent,
        ProjectFormModalComponent,
        FormTypeSelectComponent,
        ProjectFormsTabComponent,
        UserExecutionGroupModalComponent,
        ModalComponent,
        ProjectObjectTabComponent,
        ObjectDetailComponent,
        ModalComponent,
        ProjectObjectTabComponent,
        ObjectDetailComponent,
        ModalComponent,
        ProjectFormDetailComponent,
        ExecutionGroupListComponent,
        ExecutionGroupDetailComponent,
        ImageUploadComponent,
        ImagePreviewModalComponent,
        ImageAnswerPreviewModalComponent,
        ModalComponent,
        WorkerGroupListComponent,
        WorkergroupDetailComponent,
        UsersTableComponent,
        UsersSearchComponent,
        UsersGridComponent,
        UsersEmptyCardComponent,
        WorkerGroupBuilderComponent,
        ExecutionGroupDetailComponent,
        ExecutionGroupSelectComponent,
        ProjectExecutionGroupSelectComponent,
        ProjectExecutionGroupDetailComponent,
        ProjectJobListComponent,
        ProjectJobDetailComponent,
        ProjectJobModalComponent,
        ProjectSelectComponent,
        ProjectFormSelectComponent,
        ObjectSelectComponent,
        AuthenticationProviderConfigListComponent,
        AuthenticationProviderConfigDetailComponent,
        UserSelectCardComponent,
        ProjectCoordinatorsTabComponent,
        ExternalLinkComponent,
        MultiSelectTableComponent,
        FollowUpProjectJobModalComponent,
        FollowUpFormModalComponent,
        QuestionFollowUpProjectJobsComponent,
        QuestionFollowUpFormsComponent,
        QuestionFollowUpNumberQuestionComponent,
        FileUploadBtnComponent,
        LoadingBtnComponent,
        ProjectJobDetailTabComponent,
        ProjectJobAnswersTabComponent,
        ProjectSelectModalComponent,
        ProjectMapComponent,
        JobLocationSelectMapComponent,
        NoJobsWithLocationModalComponent,
        ArrayPropertyValuesPipe,
        FileInputValueAccessorDirective,
        DuplicateFormItemModalComponent,
        ProjectMapLayerPropertiesModalComponent,
        CompanyListComponent,
        CompanyDetailComponent,
        TableQuestionAnswersPipe,
        ImageAnnotationComponent,
        ImageAnnotationModalComponent,
        InterfaceProcessingErrorListComponent,
        AppleContentCodesListComponent,
        AppleContentCodesImportModalComponent,
        InterfaceProcessingErrorDetailComponent,
        AppleContentGetCodeUrlComponent,
        RootComponent,
        CustomerListComponent,
        CustomerDetailComponent,
        ProjectJobRejectModalComponent,
        TruncatePipe,
        FormTypeCopyComponent,
        FormTypeExampleComponent,
        FormViewerComponent,
        ProjectExportTabComponent,
        UserSelectComponent,
        ImportFormSuccessModalComponent,
        ImportFormV2ModalComponent,
        FileUploadComponent,
        ProjectJobCloneModalComponent,
        ProjectExecutionGroupTabComponent,
        ProjectMultipleFormsModalComponent,
        ValidationErrorComponent,
        CustomerSelectComponent,
        SharepointCredentialSelectComponent,
        FollowUpNumberQuestionModalComponent,
        MultiImageUploadComponent,
        ChoiceQuestionSettingsModalComponent,
        CreateUserModalComponent,
        GraphSelectComponent,
        GraphUserSelectComponent,
        TextareaAutoresizeDirective,
        QuestionEditTabularColumnTextComponent,
        QuestionEditTabularColumnNumberComponent,
        CopyProjectFormToFormTypeModalComponent,
        RouterlinkStopPropagationDirective,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        BsDropdownModule.forRoot(),
        JwtModule.forRoot({
            jwtOptionsProvider: {
                provide: JWT_OPTIONS,
                useFactory: jwtOptionsFactory,
                deps: [AuthenticationService]
            }
        }),
        ModalModule.forRoot(),
        InlineSVGModule.forRoot({baseUrl: ''}), // needs to be empty string so frontend/ is appended
        environment.useMock ? ToastNoAnimationModule.forRoot() : ToastrModule.forRoot({
            timeOut: 8000,
            preventDuplicates: true
        }),
        environment.useMock ? NoopAnimationsModule : BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
        VwuiModule,
        NgxFileDropModule,
        TooltipModule.forRoot(),
        SortablejsModule.forRoot({
            animation: 150,
            fallbackOnBody: true,
            forceFallback: true
        }),
        FlatpickrModule.forRoot(),
        TranslateModule.forRoot({}),
        CKEditorModule
    ],
    providers: [
        AuthenticatedGuard,
        AuthenticationService,
        serviceWithMock('FormTypeService', FormTypeServiceImpl, FormTypeServiceMock),
        serviceWithMock('FormTypeStatusService', FormTypeStatusServiceImpl, FormTypeStatusServiceMock),
        serviceWithMock('AuthCheckService', AuthCheckService, AuthCheckServiceMock),
        serviceWithMock('FeatureToggleCheckService', FeatureToggleCheckService, FeatureToggleCheckServiceMock),
        serviceWithMock('ObjectTypeService', ObjectTypeServiceImpl, ObjectTypeServiceMock),
        serviceWithMock('ObjectService', ObjectServiceImpl, ObjectServiceMock),
        serviceWithMock('ObstructionCodeService', ObstructionCodeServiceImpl, ObstructionCodeServiceMock),
        serviceWithMock('SharepointCredentialService', SharepointCredentialServiceImpl, SharepointCredentialServiceMock),
        serviceWithMock('QuestionService', QuestionServiceImpl, QuestionServiceMock),
        serviceWithMock('QuestionSetService', QuestionSetServiceImpl, QuestionSetServiceMock),
        serviceWithMock('UserService', UserServiceImpl, UserServiceMock),
        serviceWithMock('ProjectService', ProjectServiceImpl, ProjectServiceMock),
        serviceWithMock('ProjectMapLayersService', ProjectMapLayersServiceImpl, ProjectMapLayersServiceMock),
        serviceWithMock('ProjectCoordinatorsService', ProjectCoordinatorsServiceImpl, ProjectCoordinatorsServiceMock),
        serviceWithMock('ProjectFormService', ProjectFormServiceImpl, ProjectFormServiceMock),
        serviceWithMock('GraphService', GraphServiceImpl, GraphServiceMock),
        serviceWithMock('ExecutionGroupService', ExecutionGroupServiceImpl, ExecutionGroupServiceMock),
        serviceWithMock('ProjectExecutionGroupService', ProjectExecutionGroupServiceImpl, ProjectExecutionGroupServiceMock),
        serviceWithMock('WorkerGroupService', WorkerGroupServiceImpl, WorkerGroupServiceMock),
        serviceWithMock('ProjectJobService', ProjectJobServiceImpl, ProjectJobServiceMock),
        serviceWithMock(
            'AuthenticationProviderConfigService',
            AuthenticationProviderConfigServiceImpl,
            AuthenticationProviderConfigServiceMock
        ),
        serviceWithMock('ProjectAuthorizationsService', ProjectAuthorizationsServiceImpl, ProjectAuthorizationsServiceMock),
        serviceWithMock('ProjectWorkerGroupsService', ProjectWorkerGroupsServiceImpl, ProjectWorkerGroupServiceMock),
        serviceWithMock('CompanyService', CompanyServiceImpl, CompanyServiceMock),
        serviceWithMock('TagService', TagServiceImpl, TagServiceMock),
        serviceWithMock('InterfaceProcessingErrorService', InterfaceProcessingErrorServiceImpl, InterfaceProcessingErrorServiceMock),
        serviceWithMock('AppleContentCodeService', AppleContentCodeServiceImpl, AppleContentCodeServiceMock),
        serviceWithMock('CustomerService', CustomerServiceImpl, CustomerServiceMock),
        serviceWithMock('SharepointService', SharepointServiceImpl, SharepointServiceMock),
        serviceWithMock('UploadService', UploadServiceImpl, UploadServiceMock),
        JobExportService,
        BlobService,
        FormTypeStatusTitlePipe,
        ProjectJobStatusPipe,
        QuestionTypeTitlePipe,
        DateFormatPipe,
        ArrayPropertyValuesPipe,
        NlToBrPipe,
        SumPipe,
        AvgPipe,
        TableQuestionAnswersPipe,
        QuestionTypeLocationPipe,
        ImportTemplateService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ForbiddenResponseInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: DateHttpInterceptorService,
            multi: true
        },
        {
            provide: ErrorHandler,
            useClass: AppInsightsErrorHandler
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
