<app-toolbar [toolbarTitle]="workerGroupTitle" [showBackBtn]="true" (back)="goBack()"></app-toolbar>

<div class="vwui-container is-full">
    <app-users-grid>
        <app-users-search
            (search)="searchChanged($event)"
            (userSelected)="addUser($event)"
            [config]="searchConfigs$|async">
        </app-users-search>

        <ng-container *ngIf="workerGroup.workerGroupUsers.length > 0; else empty">
            <app-users-table
                title="Medewerkers"
                *ngIf="workerGroup.workerGroupUsers.length > 0"
                [total]="workerGroup.workerGroupUsers.length"
            >
                <thead>
                <tr>
                    <th>{{'Medewerker'|translate}}</th>
                    <th>{{'Werkgever'|translate}}</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let wgu of workerGroup.workerGroupUsers">
                    <td>
                        {{ wgu.user.displayName }}<br>
                        {{ wgu.user.mail }}
                    </td>
                    <td>{{ wgu.user.companyName }}</td>
                    <td>
                        <div class="vwui-table__actions">
                            <app-action-menu>
                                <button class="vwui-menu-item" (click)="openUserModal(wgu)">
                                    <app-icon iconName="edit-2"></app-icon>
                                    {{'Bewerken'|translate}}
                                </button>
                                <button class="vwui-menu-item is-danger" (click)="removeUser(wgu.user)">
                                    <app-icon iconName="trash-2"></app-icon>
                                    {{'Verwijderen'|translate}}
                                </button>
                            </app-action-menu>
                        </div>
                    </td>
                </tr>
                </tbody>
            </app-users-table>
        </ng-container>

        <ng-template #empty>
            <app-users-empty-card>
                {{'Selecteer medewerkers om medewerkersgroepen samen te stellen'|translate}}
            </app-users-empty-card>
        </ng-template>

    </app-users-grid>
</div>
