import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Inject, Injectable} from '@angular/core';
import {QuestionSet} from '../models/question-set';
import {QuestionSetService} from '../services/question-set.service';

@Injectable({
    providedIn: 'root'
})
export class QuestionSetResolver implements Resolve<QuestionSet> {

    constructor(
        @Inject('QuestionSetService') private questionSetService: QuestionSetService
    ) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.questionSetService.getQuestionSet(+route.params.id);
    }
}
