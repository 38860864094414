<app-toolbar [showBackBtn]="true" (back)="goBack()"></app-toolbar>

<div class="vwui-container is-small">
    <div class="vwui-card">
        <div class="vwui-card__header">{{'Objecttype'|translate}}</div>
        <div class="vwui-card__content">
            <form [formGroup]="form" (submit)="save()">
                <div class="vwui-form-group mb-4" [appFormControlError]="form.get('title')">
                    <label for="title" class="vwui-label">{{'Objecttypenaam'|translate}}</label>
                    <input id="title" class="vwui-input" name="title" type="text" formControlName="title" autocomplete="off">
                </div>
                <div class="vwui-form-group mb-4" [appFormControlError]="form.get('code')">
                    <label for="code" class="vwui-label">{{'Objecttype ID'|translate}}</label>
                    <input id="code" class="vwui-input" name="code" type="text" formControlName="code" autocomplete="off">
                </div>
                <div class="vwui-form-group mb-4" [appFormControlError]="form.get('description')">
                    <label for="description" class="vwui-label">{{'Omschrijving'|translate}}</label>
                    <textarea id="description" class="vwui-textarea" name="description" formControlName="description" autocomplete="off"></textarea>
                </div>
                <div class="vwui-form-group mb-4">
                    <label for="companies" class="vwui-label">{{'Bedrijven'|translate}}</label>
                    <app-company-select id="companies" formControlName="companies" [multiple]="true"></app-company-select>
                </div>
                <div class="vwui-form-group mb-4" [appFormControlError]="form.get('status')">
                    <label for="status" class="vwui-label">{{'Status'|translate}}</label>
                    <ng-select id="status" formControlName="status" [items]="ObjectTypeStatusItems" [clearable]="false" bindLabel="label" bindValue="value">
                    </ng-select>
                </div>
                <button type="submit" class="d-none"><!-- Needed to submit with enter --></button>
            </form>
        </div>
    </div>
</div>

<app-actionbar *ngIf="form.dirty" (cancel)="goBack()" (save)="save()"></app-actionbar>
