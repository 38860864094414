import {Component, Inject} from '@angular/core';
import {UserRoles} from '../../models/user';
import {FeatureToggle} from '../../models/feature-toggle';
import {NavItem} from '../../vwui/nav/nav.interface';
import {TranslateService} from '@ngx-translate/core';
import {AppleContentCodeService} from '../../services/apple-content-code.service';
import {notificationThreshold} from '../../models/apple-content-code';

@Component({
    selector: 'app-root',
    templateUrl: './root.component.html'
})
export class RootComponent {
    public navItems: NavItem[];
    public amountOfAvailableCodes$ = this.appleContentCodeService.amountOfAvailableCodes$;
    public notificationThreshold = notificationThreshold;

    constructor(
        private translate: TranslateService,
        @Inject('AppleContentCodeService')  private appleContentCodeService: AppleContentCodeService
    ) {
        this.navItems = [
            {
                header: this.translate.instant('Master databeheer'),
                data: {
                    role: UserRoles.RoleMasterDataAdmin
                },
                items: [
                    {
                        label: this.translate.instant('Objecttypes'),
                        routerLink: '/beheer/object-types'
                    },
                    {
                        label: this.translate.instant('Vragensets'),
                        routerLink: '/beheer/question-sets'
                    },
                    {
                        label: this.translate.instant('Formulieren'),
                        routerLink: '/beheer/form-types'
                    },
                    {
                        label: this.translate.instant('Bedrijven'),
                        routerLink: '/beheer/companies'
                    },
                    {
                        label: this.translate.instant('Obstructiecodes'),
                        data: {
                            featureToggle: [FeatureToggle.ObstructionCodes]
                        },
                        routerLink: '/beheer/obstruction-codes'
                    }
                ]
            },
            {
                header: this.translate.instant('Applicatiebeheerder'),
                data: {
                    role: UserRoles.RoleApplicationAdmin
                },
                items: [
                    {
                        label: this.translate.instant('Gebruiker'),
                        routerLink: '/beheer/users'
                    },
                    {
                        label: this.translate.instant('Inlogmethodes'),
                        routerLink: '/beheer/registrations'
                    },
                    {
                        label: this.translate.instant('Interface foutmeldingen'),
                        data: {
                            featureToggle: [FeatureToggle.Maximo, FeatureToggle.Vise]
                        },
                        routerLink: '/beheer/interface-processing-errors'
                    },
                    {
                        label: this.translate.instant('Apple contentcodes'),
                        routerLink: '/beheer/apple',
                        data: {
                            featureToggle: [FeatureToggle.AppleContentCodes]
                        }
                    },
                    {
                        label: this.translate.instant('SharePoint inloggegevens'),
                        routerLink: '/beheer/sharepoint-credentials'
                    },
                ]
            },
            {
                header: this.translate.instant('Coördinator'),
                data: {
                    role: [UserRoles.RoleCoordinator, UserRoles.RoleApplicationAdmin]
                },
                items: [
                    {
                        label: this.translate.instant('Projecten'),
                        routerLink: '/beheer/projects'
                    },
                    {
                        label: this.translate.instant('Opdrachten'),
                        routerLink: '/beheer/project-jobs'
                    },
                    {
                        label: this.translate.instant('Disciplines'),
                        routerLink: '/beheer/execution-groups'
                    },
                    {
                        label: this.translate.instant('Medewerkersgroepen'),
                        routerLink: '/beheer/workergroups'
                    },
                    {
                        label: this.translate.instant('Opdrachtgevers'),
                        routerLink: '/beheer/customers',
                        data: {
                            featureToggle: FeatureToggle.CustomersEnabled
                        }
                    }
                ]
            }
        ];
    }
}
