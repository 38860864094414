import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {FormTypeImportStatus} from '../models/form-type-import-status';
import {FormTypeStatusImportStatus} from '../models/form-type-status-import-status';

export interface FormTypeStatusService {
    importFormTypeStatus(file: File): Observable<FormTypeImportStatus>;
    importStatus(importId: number): Observable<FormTypeImportStatus>;
}

@Injectable()
export class FormTypeStatusServiceImpl implements FormTypeStatusService {
    constructor(private httpClient: HttpClient) {
    }

    importFormTypeStatus(file: File): Observable<FormTypeImportStatus> {
        const data = new FormData();
        data.append('file', file, file.name);

        return this.httpClient.post<FormTypeStatusImportStatus>(`api/v1/form-types/import-status`, data);
    }

    importStatus(importId: number): Observable<FormTypeImportStatus> {
        return this.httpClient.get<FormTypeStatusImportStatus>(`/api/v1/form-types/import-status/${importId}/status`);
    }
}
