<app-toolbar
    [showAddBtn]="true"
    [addBtnRoute]="'/beheer/registrations/new'"
    addBtnLabel="{{'Nieuwe inlogmethode'|translate}}">
</app-toolbar>
<div class="vwui-container">
    <app-table *ngIf="paginator$|async;let paginator" [paginator]="paginator" [headerTitles]="headerTitles"
               (filterChanged)="onTableFilterChange($event)">
        <ng-template let-item>
            <tr [routerLink]="['/beheer/registrations/', item.id]">
                <td>{{ item.title }}</td>
                <td>
                    <ng-container [ngSwitch]="item.type">
                        <ng-template ngSwitchCase="azure">{{'Azure'|translate}}</ng-template>
                        <ng-template ngSwitchCase="openid">{{'OpenID Connect'|translate}}</ng-template>
                    </ng-container>
                </td>
                <td>{{ item.defaultProject?.name || '-' }}</td>
                <td>
                    <span *ngIf="item.consentGranted; else noConsent" class="vwui-badge is-success text-nowrap">
                        <app-icon iconName="check"></app-icon>
                        {{'Beheerderstoestemming'|translate}}
                    </span><br>
                    <ng-template #noConsent>
                        <span class="vwui-badge is-danger text-nowrap">
                            <app-icon iconName="x"></app-icon>
                            {{'Geen beheerderstoestemming'|translate}}
                        </span>
                    </ng-template>
                </td>
            </tr>
        </ng-template>
    </app-table>
</div>
