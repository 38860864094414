import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {TableFilter} from '../components/table/table.component';
import {Filters} from '../utils/filters';
import {PageResponse} from '../models/page-response';
import {Observable} from 'rxjs';
import {ProjectForm, ProjectFormDetailsRequest, ProjectFormStatus} from '../models/form-type';
import {PaulaObjectType} from '../models/paula-object-type';
import {Tag} from '../models/tag';
import {ProjectFormCopyResponse} from '../models/project-form-copy-response';

export interface ProjectFormService {
    getList(projectId: number, page: number, filter?: TableFilter): Observable<PageResponse<ProjectForm>>;
    getDetail(projectId: number, projectFormId: number): Observable<ProjectForm>;
    putProjectForm(projectId: number, projectForm: ProjectFormDetailsRequest): Observable<ProjectForm>;
    transition(job: ProjectForm, status: ProjectFormStatus): Observable<ProjectForm>;
    exists(projectId: number, code: string): Observable<number>;
    cloneFormType(
        projectId: number,
        title: string,
        formType: number,
        code: string,
        objectTypes: PaulaObjectType[],
        tags: Tag[],
        contactPerson: string
    ): Observable<ProjectFormCopyResponse>;
    downloadProjectForm(projectId: number, projectForm: ProjectForm): Observable<HttpResponse<Blob>>;
}

@Injectable({
    providedIn: 'root'
})
export class ProjectFormServiceImpl implements ProjectFormService {
    constructor(private httpClient: HttpClient) {
    }

    getList(projectId: number, page: number, filter?: TableFilter): Observable<PageResponse<ProjectForm>> {
        let params = new HttpParams().set('page', '' + page);

        if (filter) {
            params = Filters.applyFiltering(filter, params);
        }

        return this.httpClient.get<PageResponse<ProjectForm>>(`/api/v1/projects/${projectId}/forms`, {params});
    }

    getDetail(projectId: number, projectFormId: number): Observable<ProjectForm> {
        return this.httpClient.get<ProjectForm>(`/api/v1/projects/${projectId}/forms/${projectFormId}`);
    }

    putProjectForm(projectId: number, projectForm: ProjectForm) {
        return this.httpClient.put<ProjectForm>(`/api/v1/projects/${projectId}/forms/${projectForm.id}`, projectForm);
    }

    transition(projectForm: ProjectForm, status: ProjectFormStatus) {
        return this.httpClient.post<ProjectForm>(
            `/api/v1/projects/${projectForm.project.id}/forms/${projectForm.id}/transition`,
            '',
            {params: {status}}
        );
    }

    exists(projectId: number, code: string) {
        return this.httpClient.get<number>(`api/v1/projects/${projectId}/forms/exists`, {params: {code}});
    }

    cloneFormType(
        projectId: number,
        title: string,
        formType: number,
        code: string,
        objectTypes: PaulaObjectType[],
        tags: Tag[],
        contactPerson: string
    ) {
        return this.httpClient.post<ProjectFormCopyResponse>(`/api/v1/projects/${projectId}/forms/copy`, {
            title,
            formType,
            code,
            objectTypes,
            tags,
            contactPerson
        });
    }

    downloadProjectForm(projectId: number, projectForm: ProjectForm): Observable<HttpResponse<Blob>> {
        return this.httpClient.get(
            `/api/v1/project-form-export/${projectForm.id}`,
            {responseType: 'blob', observe: 'response'}
        );
    }
}
