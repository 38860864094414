// @ts-strict-ignore
import {Injectable} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import open, {AuthorizationSuccessResponse} from '../utils/oauth-popup';
import {BehaviorSubject, Subject} from 'rxjs';
import {concatMap, take} from 'rxjs/operators';
import {environment} from '../../environments/environment';

export interface TokenResponse {
    access_token: string;
    id_token: string;
    refresh_token: string;
}

export interface AccessTokenPayload {
    upn: string;
}

export enum AuthenticationState {
    Authenticated = 'authenticated',
    Unauthenticated = 'unauthenticated',
    WaitingForPopup = 'waiting_for_popup',
    Pending = 'pending',
}

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private pendingJwtGet: Promise<string> = null;
    private jwtHelper: JwtHelperService = new JwtHelperService();

    private interactiveAuthenticationSubject = new Subject<void>();

    readonly loggedInEmail$ = new BehaviorSubject<string>(null);
    readonly authenticationState$ = new BehaviorSubject<AuthenticationState>(null);

    /**
     * Ensures application is authenticated
     */
    async ensureAuthenticated(): Promise<void> {
        const isAdV2 = localStorage.getItem('migratedToAzureAdv2') === 'true';
        if (!isAdV2) {
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('accessToken');
            localStorage.setItem('migratedToAzureAdv2', 'true');
        }

        await this.getJwtToken();
    }

    /**
     * Attempt to trigger interactive authentication,
     * this only works if authentication-state is pending
     */
    triggerInteractiveAuthentication() {
        this.interactiveAuthenticationSubject.next();
    }

    /**
     * Fetches valid token from storage or handles authentication if not available
     * Uses the pendingJwtGet property to prevent concurrent token refreshes / authentication attempts
     */
    async getJwtToken(): Promise<string> {
        try {
            if (this.pendingJwtGet === null) {
                this.pendingJwtGet = this.doGetJwtToken();
            }

            return await this.pendingJwtGet;
        } catch (e) {
            throw e;
        } finally {
            this.pendingJwtGet = null;
        }
    }

    logout(): void {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('idToken');
        this.loggedInEmail$.next(null);
        this.authenticationState$.next(AuthenticationState.Unauthenticated);
    }

    private async doGetJwtToken(): Promise<string> {
        return this.getStoredIdToken()
            || await this.getRefreshedIdToken()
            || await this.waitForInteractiveAuthentication();
    }

    private waitForInteractiveAuthentication(): Promise<string> {
        this.authenticationState$.next(AuthenticationState.Pending);
        return this.interactiveAuthenticationSubject.pipe(
            concatMap(() => this.getNewAccessToken()),
            take(1),
        ).toPromise();
    }

    private getStoredIdToken(): string {
        const accessToken = localStorage.getItem('idToken');
        if (accessToken !== null && accessToken !== 'null' && !this.jwtHelper.isTokenExpired(accessToken)) {
            const tokenData = this.jwtHelper.decodeToken(accessToken) as AccessTokenPayload;
            this.loggedInEmail$.next(tokenData.upn);
            this.authenticationState$.next(AuthenticationState.Authenticated);

            return accessToken;
        }
    }

    private async getRefreshedIdToken(): Promise<string> {
        try {
            const refreshToken = localStorage.getItem('refreshToken');
            if (refreshToken !== null) {
                const refreshResponse = await this.requestRefreshToken(refreshToken);

                localStorage.setItem('accessToken', refreshResponse.access_token);
                localStorage.setItem('refreshToken', refreshResponse.refresh_token);
                localStorage.setItem('idToken', refreshResponse.id_token);

                const tokenData = this.jwtHelper.decodeToken(refreshResponse.id_token) as AccessTokenPayload;
                this.loggedInEmail$.next(tokenData.upn);
                this.authenticationState$.next(AuthenticationState.Authenticated);

                return refreshResponse.id_token;
            }
        } catch (e) {
            console.warn('Refresh failed', e);
            this.loggedInEmail$.next(null);
            this.authenticationState$.next(AuthenticationState.Unauthenticated);
        }
    }

    private async getNewAccessToken(): Promise<string> {
        try {
            this.authenticationState$.next(AuthenticationState.WaitingForPopup);
            const authorizationSuccessResponse = await this.getCode();
            const tokenResponse = await this.requestToken(authorizationSuccessResponse);

            localStorage.setItem('accessToken', tokenResponse.access_token);
            localStorage.setItem('refreshToken', tokenResponse.refresh_token);
            localStorage.setItem('idToken', tokenResponse.id_token);

            const tokenData = this.jwtHelper.decodeToken(tokenResponse.id_token) as AccessTokenPayload;
            this.loggedInEmail$.next(tokenData.upn);
            this.authenticationState$.next(AuthenticationState.Authenticated);

            return tokenResponse.id_token;
        } catch (e) {
            console.error('Failed to authenticate', e);
            this.loggedInEmail$.next(null);
            this.authenticationState$.next(AuthenticationState.Unauthenticated);
        }
    }

    private async getCode(): Promise<AuthorizationSuccessResponse> {
        const url = '/oauth2/redirect/admin';
        return await open(url, {name: 'Azure AD Signin'});
    }

    private requestToken(auth: AuthorizationSuccessResponse): Promise<TokenResponse> {
        return this.doPost({code: auth.code, state: auth.state}, '/oauth2/token/admin');
    }

    private requestRefreshToken(refreshToken: string): Promise<TokenResponse> {
        return this.doPost({refresh_token: refreshToken}, '/oauth2/refresh');
    }

    private doPost(parameters: object, url: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const queryString = Object.entries(parameters).map(([k, v]) => {
                return encodeURIComponent(k) + '=' + encodeURIComponent(v);
            }).join('&');
            const xhr = new XMLHttpRequest();
            xhr.open('POST', environment.apiPrefix + url);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    resolve(JSON.parse(xhr.responseText));
                } else {
                    reject(xhr.statusText);
                    console.error(xhr.statusText);
                }
            };
            xhr.send(queryString);
        });
    }
}
