import {Component, forwardRef, Inject, Input} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {EntitySelectComponent} from './item-select.component';
import {PageResponse} from '../../models/page-response';
import {Company} from '../../models/company';
import {CompanyService} from '../../services/company.service';

@Component({
    selector: 'app-company-select',
    templateUrl: './item-select.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CompanySelectComponent),
            multi: true
        },
    ]
})
export class CompanySelectComponent extends EntitySelectComponent<Company, {}> {
    @Input() mustHaveForms = false;

    constructor(
        @Inject('CompanyService') private companyService: CompanyService,
    ) {
        super();
        this.placeholder = 'Selecteer een bedrijf';
    }

    loadMore(page: number, filterInput: {term: string}): Observable<PageResponse<Company>> {
        const filter: {[key: string]: any} = {
            name: filterInput && filterInput.term ? filterInput.term : '',
        };
        if (this.mustHaveForms) {
            filter['formTypes.title'] = '%';
        }
        return this.companyService.getCompanies(page, {filter});
    }

    getLabelText(item: Company) {
        return item.name;
    }

    getFilter(): Observable<{}> {
        return of({});
    }
}
