import {TableFilter} from '../components/table/table.component';
import {Observable} from 'rxjs';
import {PageResponse} from '../models/page-response';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Filters} from '../utils/filters';
import {Company} from '../models/company';

export interface CompanyService {
    getCompany(id: number): Observable<Company>;
    getCompanies(page: number, filter?: TableFilter): Observable<PageResponse<Company>>;
    putCompany(company: Company): Observable<Company>;
    postCompany(company: Company): Observable<Company>;
    exists(name: string): Observable<number>;
}

@Injectable()
export class CompanyServiceImpl implements CompanyService {
    constructor(private httpClient: HttpClient) {
    }

    getCompany(id: number): Observable<Company> {
        return this.httpClient.get<Company>(`/api/v1/companies/${id}`);
    }

    getCompanies(page: number, filter?: TableFilter) {
        let params = new HttpParams().set('page', '' + page);

        if (filter) {
            params = Filters.applyFiltering(filter, params);
        }

        return this.httpClient.get<PageResponse<Company>>(`/api/v1/companies`, {params});
    }

    putCompany(company: Company) {
        return this.httpClient.put<Company>(`api/v1/companies/${company.id}`, company);
    }

    postCompany(company: Company) {
        return this.httpClient.post<Company>('api/v1/companies', company);
    }

    exists(name: string) {
        const params = new HttpParams().set('name', name);

        return this.httpClient.get<number>(`api/v1/companies/exists`, {params});
    }
}
