import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {WorkerGroup} from '../../models/worker-group';
import {GraphUser} from '../../models/graph-user';

export interface SearchConfig {
    title: string;
    isGroup: boolean;
    searchData: Observable<GraphUser[]|WorkerGroup[]>;
}

@Component({
    selector: 'app-users-search',
    templateUrl: './users-search.component.html'
})
export class UsersSearchComponent {
    @Input() config: SearchConfig[] = [];
    @Input() activeFilter = 0;

    @Output() search = new EventEmitter<string>();
    @Output() userSelected = new EventEmitter<GraphUser>();
    @Output() groupSelected = new EventEmitter<WorkerGroup>();

    selectFilter(index: number) {
        this.activeFilter = index;
    }
}
