// @ts-strict-ignore
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {Forms} from '../../utils/forms';
import {ExecutionGroupService} from '../../services/execution-group.service';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {ExecutionGroup} from '../../models/execution-group';
import {Subscriptions} from '../../utils/subscriptions';
import {HistoryService} from "../../services/history.service";

@Component({
    selector: 'app-execution-group-detail',
    templateUrl: './execution-group-detail.component.html'
})
export class ExecutionGroupDetailComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];

    executionGroup: ExecutionGroup;
    form: UntypedFormGroup;

    constructor(
        @Inject('ExecutionGroupService') private executionGroupService: ExecutionGroupService,
        private toast: ToastrService,
        private route: ActivatedRoute,
        private router: Router,
        fb: UntypedFormBuilder,
        private historyService: HistoryService
    ) {
        this.form = this.createForm(fb);
    }

    ngOnInit(): void {
        const routeDataSubscription = this.route.data.subscribe((data) => {
            if (data.executionGroup) {
                this.executionGroup = data.executionGroup;
                this.form.patchValue(this.executionGroup);
            }
        });
        this.subscriptions.push(routeDataSubscription);
    }

    ngOnDestroy(): void {
        Subscriptions.unsubscribeAll(this.subscriptions);
    }

    async save() {
        if (!this.form.valid) {
            if (this.form.controls.title.errors.exists) {
                this.toast.error('Er bestaat al een discipline met de opgegeven naam');
            } else {
                this.toast.error('Vul alle verplichte velden in (in het rood aangegeven)');
            }

            Forms.updateValueAndValidityRecursive(this.form);
            return;
        }

        const newExecutionGroup = this.form.value as ExecutionGroup;

        try {
            let result;

            if (newExecutionGroup.id) {
                result = await this.executionGroupService.putExecutionGroup(newExecutionGroup).toPromise();
            } else {
                result = await this.executionGroupService.postExecutionGroup(newExecutionGroup).toPromise();
            }
            this.toast.success('Opgeslagen');
            this.form.patchValue(result);

            this.goBack();
        } catch (ex) {
            console.error('Unable to save object type', ex);
            this.toast.error('Opslaan mislukt');
        }
    }

    cancel() {
        this.goBack();
    }

    goBack() {
        this.historyService.goBack(['/beheer/execution-groups']);
    }

    validateExecutionGroupNotTaken(control: AbstractControl): Observable<ValidationErrors> {
        const currentId = +this.form.get('id').value;

        return Forms.validateNotTaken(control, currentId, this.executionGroupService.exists(control.value));
    }

    private createForm(fb: UntypedFormBuilder): UntypedFormGroup {
        return fb.group({
            id: fb.control(null),
            title: fb.control(null, [Validators.required], [this.validateExecutionGroupNotTaken.bind(this)]),
        });
    }
}
