// @ts-strict-ignore
declare const ImageBlobReduce: () => {
    _calculate_size: (env: any) => any,
    toBlob: (blob: Blob) => Promise<Blob>
};
const resizer = ImageBlobReduce();

export class PictureUtil {
    // Should be equal to imageOptions in app
    static maxWidth = 1600;
    static maxHeight = 1200;

    static async getPicture(): Promise<{ blob: Blob, exif: any }> {
        try {
            const [base64String, contentType] = await PictureUtil.getImageFromUpload();
            const blob = this.base64ToBlob(base64String, contentType);

            return {
                blob,
                exif: null,
            };
        } catch (error) {
            console.error(error);
            throw error;
        } finally {
            const input = document.getElementById('file-input') as HTMLInputElement;
            input.value = '';
        }
    }

    static async getImageFromUpload() {
        return new Promise<[string, string]>(async (resolve, reject) => {
            const input = document.getElementById('file-input') as HTMLInputElement;

            window.addEventListener('mouseover', function checkCancel() {
                setTimeout(() => {
                    if (input.files.length < 1) {
                        reject('Cancelled File Upload');
                    }
                }, 100);
                window.removeEventListener('mouseover', checkCancel);
            });

            input.addEventListener('input', (_) => {
                const file = input.files[0];

                const reader = new FileReader();
                reader.addEventListener('load', function readImage() {
                    const results = (reader.result as string).split(',');
                    let contentType;
                    if (results[0] === 'data:image/jpeg;base64') {
                        contentType = 'image/jpeg';
                    } else if (results[0] === 'data:image/png;base64') {
                        contentType = 'image/png';
                    } else {
                        reject('Unsupported File Type');
                    }
                    const b64 = results[1];
                    resolve([b64, contentType]);
                    reader.removeEventListener('load', readImage);
                });
                reader.readAsDataURL(file);
            });
            input.click();
        });
    }

    /**
     * Turn base 64 image into a blob, so we can send it using multipart/form-data posts
     * @param b64Data image data
     * @param contentType image content type
     * @param sliceSize slice size
     * @return given image data as {Blob}
     */
    static base64ToBlob(b64Data: string, contentType: string = '', sliceSize: number = 512): Blob {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, {type: contentType});
    }

    /**
     * Resize image to fit the max image dimensions and keep aspect ratio
     * @param blob image data
     * @param maxWidth max image width
     * @param maxHeight max image height
     */
    static async resizeBlob(blob: Blob, maxWidth = PictureUtil.maxWidth, maxHeight = PictureUtil.maxHeight) {
        try {
            resizer._calculate_size = (env) => {
                const scaleX = maxWidth / env.image.width;
                const scaleY = maxHeight / env.image.height;
                const scale = Math.min(scaleX, scaleY, 1);

                env.transform_width = Math.round(env.image.width * scale);
                env.transform_height = Math.round(env.image.height * scale);

                return env;
            };

            return await resizer.toBlob(blob);
        } catch (e) {
            console.warn('resizeBlob failed', e);
            // Fallback for unsupported image types
            return blob;
        }
    }
}
