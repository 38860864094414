import {TableFilter} from '../components/table/table.component';
import {Observable} from 'rxjs';
import {PageResponse} from '../models/page-response';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Filters} from '../utils/filters';
import {Customer} from '../models/customer';
import {CustomerSketchSet} from '../models/customer-sketch-set';
import {CustomerReportTemplate} from '../models/customer-report-template';

export interface CustomerService {
    getCustomer(customerId: number): Observable<Customer>;
    getCustomers(page: number, filter?: TableFilter): Observable<PageResponse<Customer>>;
    getCustomerSketchSets(): Observable<CustomerSketchSet[]>;
    getCustomerReportTemplates(): Observable<CustomerReportTemplate[]>;
    putCustomer(customer: Customer): Observable<Customer>;
    postCustomer(customer: Customer): Observable<Customer>;
    deleteCustomer(customer: Customer): Observable<Customer>;
}

@Injectable()
export class CustomerServiceImpl implements CustomerService {
    constructor(private httpClient: HttpClient) { }

    getCustomer(customerId: number): Observable<Customer> {
        return this.httpClient.get<Customer>(`/api/v1/customer/${customerId}`);
    }

    getCustomers(page: number, filter?: TableFilter): Observable<PageResponse<Customer>> {
        let params = new HttpParams().set('page', '' + page);

        if (filter) {
            params = Filters.applyFiltering(filter, params);
        }

        return this.httpClient.get<PageResponse<Customer>>(`/api/v1/customers`, {params});
    }

    getCustomerSketchSets(): Observable<CustomerSketchSet[]> {
        return this.httpClient.get<CustomerSketchSet[]>('/api/v1/sketch-sets');
    }

    getCustomerReportTemplates(): Observable<CustomerReportTemplate[]> {
        return this.httpClient.get<CustomerReportTemplate[]>('/api/v1/report-templates');
    }

    putCustomer(customer: Customer): Observable<Customer> {
        return this.httpClient.put<Customer>(`api/v1/customer/${customer.id}`, customer);
    }

    postCustomer(customer: Customer): Observable<Customer> {
        return this.httpClient.post<Customer>(`api/v1/customer/`, customer);
    }

    deleteCustomer(customer: Customer): Observable<Customer> {
        return this.httpClient.delete<Customer>(`api/v1/customer/${customer.id}`);
    }
}
