<label class="vwui-label" [class.has-error]="(control.dirty || control.touched) && control.invalid">Referentieafbeeldingen</label>
<div class="sortable-container">
    <input id="file-input" type="file" accept=".jpg,.png" multiple hidden
           [disabled]="(disabledSubject | async) || (uploadingSubject | async) || (imagesSubject | async).length >= MAX_IMAGES"
           (change)="addPhoto($any($event.target))">
    <label for="file-input" class="sortable-grid__item sortable-grid__item--add"
           [class.has-error]="(control.dirty || control.touched) && control.invalid">
        <img *ngIf="(uploadingSubject|async) !== true" src="/beheer/assets/icons/icon-add-image.svg" class="icon" alt="Foto toevoegen" />
        <div *ngIf="(uploadingSubject|async) === true" class="vwui-spinner"></div>
        Foto toevoegen
    </label>
    <div class="sortable-grid">
        <div *ngFor="let uuid of imagesSubject | async" class="image-grid__item">
            <img class="image-thumbnail" src="/blob/{{uuid}}" [alt]="uuid"/>
            <app-icon class="image-thumbnail__icon" iconName="trash-2" (click)="removePhoto(uuid)"></app-icon>
        </div>
    </div>
</div>
