import {Identifiable} from './identifiable';
import {PaulaObjectType} from './paula-object-type';
import {FormItem} from './form-item';
import {Project} from './project';
import {ExecutionGroup} from './execution-group';
import {Company} from './company';
import {Tag} from './tag';
import {ProjectExecutionGroup} from './project-execution-group';

export enum FormTypeStatus {
    Open = 'Open',
    Released = 'Released',
    Revoked = 'Revoked'
}

export enum ProjectFormStatus {
    Released = 'Released',
    Archived = 'Archived'
}

export enum FormTypes {
    FormType = 'formType',
    QuestionSet = 'questionSet',
    ProjectForm = 'projectForm',
    ProjectJob = 'projectJob'
}

export enum FormTypeApiEndpoint {
    FormType = 'form-types',
    QuestionSet = 'question-sets',
    ProjectForm = 'project-form'
}

export const formTypeStatuses: {value: FormTypeStatus, label: string}[] = [
    {label: 'Concept', value: FormTypeStatus.Open},
    {label: 'Vrijgegeven', value: FormTypeStatus.Released},
    {label: 'Ingetrokken', value: FormTypeStatus.Revoked}
];

export interface AbstractForm extends Identifiable {
    title: string;
    type: FormTypes;
    formItems: FormItem[];
    askLocation: boolean;
    showLocationOnMap: boolean;
    createdBy: string;
    createdAt: string;
    updatedAt: string;
    executorCanCreate: boolean;
    askExecutorToFillFormAgain: boolean;
    copyAnswers: boolean;
    contactPerson: string|null;
    revisionNumber: string;
}

export interface FormType extends AbstractForm {
    type: FormTypes.FormType;
    code: string;
    status: FormTypeStatus;
    paulaObjectTypes: PaulaObjectType[];
    companies: Company[];
    tags: Tag[];
    inspectionForm: boolean;
}

export type FormTypeRequest = Omit<FormType, 'createdAt' | 'createdBy' | 'updatedAt' | 'formItems'>

export interface ProjectForm extends AbstractForm {
    type: FormTypes.ProjectForm;
    status: ProjectFormStatus;
    project: Project;
    paulaObjectTypes: PaulaObjectType[];
    executionGroups: ExecutionGroup[];
    projectExecutionGroups: ProjectExecutionGroup[];
    formOriginalId: number | null;
    code: string;
}

export type ProjectFormDetailsRequest = Omit<ProjectForm, 'createdAt' | 'createdBy' | 'updatedAt' | 'formItems' | 'status' | 'formOriginalId' >

export const formTypeTitles = {
    Open: 'Concept',
    Released: 'Vrijgegeven',
    Revoked: 'Ingetrokken'
};
