<ng-select [compareWith]="compare"
           [items]="items | async"
           [hideSelected]="true"
           [loading]="loading"
           [typeahead]="onInput"
           (scrollToEnd)="scrollToEnd()"
           [formControl]="formControl"
           [multiple]="multiple"
           [placeholder]="placeholder"
           [maxSelectedItems]="4"
           [addTag]="addTag"
           [clearable]="true"
           class="tag-select"
>
    <ng-template ng-label-tmp let-item="item" let-clear="clear">
        <app-tag [className]="item.className" (click)="clear(item)">{{ item.name }}<app-icon iconName="x"></app-icon></app-tag>
    </ng-template>
    <ng-template ng-option-tmp let-item="item">
        <app-tag [className]="item.className">{{ item.name }}</app-tag>
    </ng-template>
</ng-select>
