import {Pipe, PipeTransform} from '@angular/core';
import {questionTypeTitles} from '../models/form-item';

@Pipe({
    name: 'questionTypeTitle',
})
export class QuestionTypeTitlePipe implements PipeTransform {
    transform(value: keyof typeof questionTypeTitles) {
        if (!value) {
            return '';
        }

        return questionTypeTitles[value] || '';
    }
}
