import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {FormTypeService} from '../services/form-type.service';
import {FormType} from '../models/form-type';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FormTypeResolver implements Resolve<FormType> {
    constructor(
        @Inject('FormTypeService') private formTypeService: FormTypeService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<FormType> {
        return this.formTypeService.getDetail(+route.params.id);
    }
}
