// @ts-strict-ignore
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Authority} from '../models/authority';
import {Subject} from 'rxjs';
import {shareReplay, startWith, switchMap} from 'rxjs/operators';

@Injectable()
export class AuthCheckService {

    private _authChanged = new Subject<void>();
    private authorities$ = this._authChanged.asObservable().pipe(
        startWith<void, null>(null),
        switchMap(() => this.httpClient.get<Array<Authority>>('/api/v1/authorities'))
    ).pipe(shareReplay(1));

    constructor(private httpClient: HttpClient) {
    }

    authorities() {
        return this.authorities$;
    }

    authChanged() {
        this._authChanged.next();
    }
}
