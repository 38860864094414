// @ts-strict-ignore
import {Component, Inject} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {Paginator} from "../../utils/paginator";
import {FormTypeService} from "../../services/form-type.service";
import {HeaderTitle, TableFilter} from "../table/table.component";
import {ActivatedRoute, Router} from "@angular/router";
import {FormType, ProjectForm} from "../../models/form-type";
import {ProjectFormService} from "../../services/project-form.service";
import {ToastrService} from "ngx-toastr";
import {HttpErrorResponse} from "@angular/common/http";
import {LoadingBtnEvent} from '../loading-btn/loading-btn.interface';

@Component({
    selector: 'app-project-multiple-forms-modal',
    templateUrl: './project-multiple-forms-modal.component.html'
})
export class ProjectMultipleFormsModalComponent {

    paginator = new Paginator(page => this.formTypeService.getList(page, {filter: {status: 'vrijgegeven'}}));
    tableHeaders: HeaderTitle[] = [
        {title: ''},
        {sortKey: 'title', filterKey: 'title', title: 'Formuliernaam'},
        {sortKey: 'code', filterKey: 'code', title: 'Formuliernummer'},
        {filterKey: 'companies.name', title: 'Bedrijven'},
        {filterKey: 'tags.name', title: 'Tags'},
        {filterKey: 'paulaObjectTypes.title', title: 'Objecttypes'}
    ];
    result: 'cancelled' | 'confirmed' | 'confirmed_with_workorders';
    projectId: number;
    selection: FormType[] = [];

    constructor(
        @Inject('FormTypeService') private formTypeService: FormTypeService,
        @Inject('ProjectFormService') private projectFormService: ProjectFormService,
        private bsModalRef: BsModalRef,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private toast: ToastrService,
    ) {
    }

    onTableFilterChange(filters: TableFilter) {
        this.paginator = new Paginator(page => this.formTypeService.getList(page, {sort: filters.sort, filter: {...filters.filter, status: 'vrijgegeven'}}));
    }

    isSelected(item: FormType) {
        return this.selection.find(it => it.id === item.id) !== undefined;
    }

    toggleItemSelected(item: FormType) {
        this.setItemSelected(!this.selection.includes(item), item);
    }

    setItemSelected(selected: boolean, item: FormType) {
        if (selected) {
            this.selection = [...this.selection, item];
        } else {
            this.selection = this.selection.filter(it => it.id !== item.id);
        }
    }

    stopPropagation(event: Event) {
        event.stopPropagation();
    }

    async cloneForm(formType: FormType) {
        const projectFormResponse = await this.projectFormService.cloneFormType(
            this.projectId,
            formType.title,
            formType.id,
            formType.code,
            formType.paulaObjectTypes,
            formType.tags,
            formType.contactPerson,
        ).toPromise();

        const projectFormBody: ProjectForm = {
            ...projectFormResponse.projectForm,
            contactPerson: formType.contactPerson
        };

        await this.projectFormService.putProjectForm(this.projectId, projectFormBody).toPromise();
    }

    async cloneMultipleForms(event: LoadingBtnEvent) {
        const unknownFailures = [], duplicates = [];
        for(const formType of this.selection) {
            try {
                await this.cloneForm(formType);
            } catch (error) {
                console.error(error)
                if (this.isDuplicateError(error)) {
                    duplicates.push(formType.title)
                } else {
                    unknownFailures.push(formType.title);
                }
            }
        }
        event.complete()

        if (unknownFailures.length > 0) {
            this.toast.error('De volgende formulieren konden niet worden toegevoegd vanwege een onbekende fout:' + unknownFailures.join(', '));
        }
        if (duplicates.length > 0) {
            this.toast.error('De volgende formulieren konden niet worden toegevoegd vanwege een conflict:' + duplicates.join(', '));
        }

        this.closeModal('confirmed');
    }

    isDuplicateError(error: HttpErrorResponse): boolean {
        return error.error.globalErrors.includes('Code already in use')
    }

    closeModal(result: 'cancelled' | 'confirmed' | 'confirmed_with_workorders') {
        this.result = result;
        this.bsModalRef.hide();
    }

}
