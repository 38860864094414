<ng-container *ngIf="isChapter">
    <form [formGroup]="form" (submit)="onSave()" *ngIf="form">
        <div class="vwui-aside-content">
            <div class="u-mb-m">
                <div [appFormControlError]="form.get('title')" class="vwui-form-group u-mb-m">
                    <label class="vwui-label" for="chapterTitle">{{'Hoofdstuk'|translate}}</label>
                    <input
                        class="vwui-input"
                        id="chapterTitle"
                        name="title"
                        formControlName="title"
                        autocomplete="off"
                        type="text"
                        placeholder="Hoofdstuk">
                </div>
                <div *ngIf="form.get('questionDependency')" class="vwui-form-group u-mb-m" [appFormControlError]="form.get('questionDependency')">
                    <label class="vwui-label">{{'Afhankelijkheid'|translate}}</label>
                    <div *ngIf="previousQuestions.length > 0">
                        <app-question-dependency
                            id="questionDependency-chapter"
                            formControlName="questionDependency"
                            [previousQuestions]="previousQuestions"
                        ></app-question-dependency>
                    </div>
                    <div *ngIf="previousQuestions.length === 0">
                        {{ 'Voeg hoger in het formulier een keuzevraag toe om hier een afhankelijkheid van een eerder antwoord in te kunnen stellen'|translate }}
                    </div>
                </div>
            </div>
        </div>
        <button type="submit" class="d-none"><!-- Needed to submit with enter --></button>
    </form>
</ng-container>

<ng-container *ngIf="isQuestion">
    <div class="vwui-aside-tabs">
        <div class="vwui-tabs">
            <button [ngClass]="{'vwui-tabs__item': true, 'is-active': tab === 'general'}" (click)="switchTab('general')">
                {{'Algemeen'|translate}}
            </button>
            <button *ngIf="questionType === 'tabular'"
                    [ngClass]="{'vwui-tabs__item': true, 'is-active': tab === 'tabularColumns'}"
                    (click)="switchTab('tabularColumns')">
                {{'Kolommen'|translate}}
            </button>
            <button [ngClass]="{'vwui-tabs__item': true, 'is-active': tab === 'advanced'}" (click)="switchTab('advanced')">
                {{'Geavanceerd'|translate}}
            </button>
        </div>
    </div>

    <form [formGroup]="form" (submit)="onSave()" *ngIf="form">
        <div class="vwui-aside-content with-tabs" [hidden]="tab !== 'general'">
            <div class="u-mb-m">
                <div [appFormControlError]="form.get('type')" class="vwui-form-group">
                    <label class="vwui-label" for="type">{{'Type vraag'|translate}}</label>
                    <div class="vwui-form-group__select">
                        <ng-select
                            id="type"
                            formControlName="type"
                            bindLabel="label"
                            bindValue="type"
                            placeholder="Kies een type vraag"
                            [items]="questionTypes"
                            [clearable]="false">
                        </ng-select>
                    </div>
                    <span *ngIf="form.get('type').disabled" class="vwui-form-group__description">
                        {{'Het vraagtype kan niet meer gewijzigd worden, nadat deze is opgeslagen'|translate}}
                        {{'Om het vraagtype te wijzigen dient u de vraag te verwijderen en opnieuw toe te voegen'|translate}}
                    </span>
                </div>
            </div>

            <div *ngIf="questionType">
                <div class="u-mb-m">
                    <div [appFormControlError]="form.get('title')" class="vwui-form-group">
                        <label class="vwui-label" for="title">{{'Vraag'|translate}}</label>
                        <input
                            class="vwui-input"
                            id="title"
                            name="title"
                            formControlName="title"
                            autocomplete="off"
                            type="text"
                            placeholder="{{'Vraag'|translate}}">
                    </div>
                </div>
                <div class="u-mb-m">
                    <div class="vwui-form-group">
                        <label class="vwui-label">{{'Omschrijving'|translate}}</label>
                        <span class="vwui-form-group__description">
                        {{'Let op: Alleen het geselecteerde venster wordt weergegeven in de app'|translate}}
                    </span>
                        <div class="vwui-options" *ngIf="form.get('type').value !== 'signature'">
                            <label class="vwui-options__option"
                                   [class.is-active]="form.get('descriptionType').value === 'text'">
                                {{'Tekst'|translate}}
                                <input type="radio" value="text" formControlName="descriptionType">
                            </label>
                            <label class="vwui-options__option"
                                   [class.is-active]="form.get('descriptionType').value === 'image'">
                                {{'Foto'|translate}}
                                <input type="radio" value="image" formControlName="descriptionType">
                            </label>
                        </div>

                        <ng-container *ngIf="form.get('descriptionType').value === 'text'">
                            <ckeditor class="vwui-editor" *ngIf="EditorPromise | async as Editor; else editorLoadingSpinner"
                                [editor]="Editor"
                                [config]="editorConfig"
                                formControlName="description">
                            </ckeditor>
                        </ng-container>
                        <ng-container *ngIf="form.get('descriptionType').value === 'image'">
                            <app-image-upload formControlName="imageDescription"
                                              (showImagePreview)="showImagePreview($event)"></app-image-upload>
                            <span class="vwui-form-group__description">
                                {{'Aanbevolen resolutie 1035x460 (aspect ratio 9:4)'|translate}}
                            </span>
                        </ng-container>
                    </div>
                </div>
                <div class="u-mb-m">
                    <p>
                        {{ EditorPromise | async | json }}
                    </p>
                    <div class="vwui-form-group">
                        <label class="vwui-label" for="helpText">{{'Helptekst'|translate}}</label>
                        <ckeditor class="vwui-editor" *ngIf="EditorPromise | async as Editor; else editorLoadingSpinner"
                            [editor]="Editor"
                            [config]="editorConfig"
                            formControlName="helpText"
                            id="helpText">
                        </ckeditor>
                    </div>
                </div>

                <div *ngIf="form.get('choices')" class="vwui-form-group u-mb-m">
                    <app-question-choices id="choices" formControlName="choices"></app-question-choices>
                </div>

                <div *ngIf="form.get('totalDecimal')" class="u-mb-m">
                    <div [appFormControlError]="form.get('totalDecimal')" class="vwui-form-group">
                        <label class="vwui-label" for="totalDecimal">{{'Aantal cijfers achter de komma'|translate}}</label>
                        <input
                            class="vwui-input"
                            id="totalDecimal"
                            name="totalDecimal"
                            formControlName="totalDecimal"
                            autocomplete="off"
                            type="number"
                            min="0"
                            max="12"
                            placeholder="{{'Aantal cijfers achter de komma'|translate}}">
                    </div>
                </div>

                <div *ngIf="form.controls.pivot" class="u-mb-m">
                    <label class="vwui-input-control is-toggle has-label mb-4 vwui-label">
                        <input class="vwui-input" type="checkbox" name="pivot" formControlName="pivot">
                        <span>{{'Pivot (verwissel kolommen en rijen)'|translate}}</span>
                        <span class="checkbox"></span>
                    </label>
                </div>

                <div class="u-mb-m">
                    <label class="vwui-input-control is-toggle has-label mb-4 vwui-label">
                        <input class="vwui-input" type="checkbox" name="required" formControlName="required">
                        <span>{{'Verplicht'|translate}}</span>
                        <span class="checkbox"></span>
                    </label>
                </div>
                <div *ngIf="form.get('type').value === 'photo'" class="u-mb-m">
                    <label class="vwui-input-control is-toggle has-label mb-4 vwui-label">
                        <input class="vwui-input" type="checkbox" name="showPhotosBelowEachOtherInExport"
                               formControlName="showPhotosBelowEachOtherInExport">
                        <span>{{'Meerdere fotos onder elkaar plaatsen'|translate}}</span>
                        <span class="checkbox"></span>
                    </label>
                </div>
                <div *ngIf="form.get('multiline')" class="u-mb-m">
                    <label class="vwui-input-control is-toggle has-label mb-4 vwui-label">
                        <input class="vwui-input" type="checkbox" name="multiline" formControlName="multiline">
                        <span>{{'Meerdere regels'|translate}}</span>
                        <span class="checkbox"></span>
                    </label>
                </div>
                <div *ngIf="form.get('multipoint')" class="u-mb-m">
                    <label class="vwui-input-control is-toggle has-label mb-4 vwui-label">
                        <input class="vwui-input" type="checkbox" name="multipoint" formControlName="multipoint">
                        <span>{{'Van en tot locatie'|translate}}</span>
                        <span class="checkbox"></span>
                    </label>
                </div>
                <div *ngIf="form.get('multiple')" class="u-mb-m">
                    <label class="vwui-input-control is-toggle has-label mb-4 vwui-label">
                        <input class="vwui-input" type="checkbox" name="multiple" formControlName="multiple">
                        <span>{{'Meerdere antwoorden mogelijk'|translate}}</span>
                        <span class="checkbox"></span>
                    </label>
                </div>
                <div *ngIf="form.get('time')" class="u-mb-m">
                    <label class="vwui-input-control is-toggle has-label mb-4 vwui-label">
                        <input class="vwui-input" type="checkbox" name="time" formControlName="time">
                        <span>{{'Tijd'|translate}}</span>
                        <span class="checkbox"></span>
                    </label>
                </div>
                <div *ngIf="form.get('formulaFields')" class="u-mb-m">
                    <label class="vwui-label" for="formula">Formule</label>
                    <div class="vwui-form-group__select">
                        <ng-select
                            id="formula"
                            formControlName="formulaFields"
                            bindLabel="label"
                            placeholder="{{'Kies een type formule'|translate}}"
                            [items]="formulaConfigs"
                            [clearable]="false"
                        >
                        </ng-select>
                    </div>
                </div>
                <div *ngIf="form.get('questionLabel')" class="u-mb-m">
                    <div class="vwui-form-group">
                        <label class="vwui-label" for="questionLabel">{{'Tabelvraaglabel'|translate}}</label>
                        <input
                            class="vwui-input"
                            id="questionLabel"
                            name="questionLabel"
                            formControlName="questionLabel"
                            autocomplete="off"
                            type="text"
                            placeholder="{{'Tabelvraaglabel'|translate}}">
                    </div>
                </div>
                <div *ngIf="form.get('questionCount')" class="u-mb-m">
                    <div class="vwui-form-group">
                        <label class="vwui-label" for="questionCount">{{'Aantal vragen'|translate}}</label>
                        <input
                            class="vwui-input"
                            id="questionCount"
                            name="questionCount"
                            formControlName="questionCount"
                            autocomplete="off"
                            min="1"
                            max="20"
                            type="number"
                            placeholder="{{'Aantal vragen'|translate}}">
                    </div>
                </div>
                <div *ngIf="form.get('referenceImages')" class="u-mb-m" [appFormControlError]="form.get('referenceImages')">
                    <app-multi-image-upload formControlName="referenceImages"></app-multi-image-upload>
                </div>
            </div>
        </div>
        <div class="vwui-aside-content with-tabs" [hidden]="tab !== 'tabularColumns'">

            <ng-container *ngIf="form.controls.columns as columnsControl">
                <ng-container *ngFor="let column of columnsControl.controls; last as isLast, index as i"
                              [ngSwitch]="column.value.type"
                              [formGroup]="column">
                    <div class="u-mb-m">
                        <div [appFormControlError]="column.controls.label" class="vwui-form-group">
                            <label class="vwui-label" [for]="'columnLabel'+i">{{'Kolomnaam'|translate}}</label>
                            <input
                                class="vwui-input"
                                [id]="'columnLabel'+i"
                                [name]="'columnLabel'+i"
                                formControlName="label"
                                autocomplete="off"
                                type="text"
                                placeholder="{{'Kolomlabel'|translate}}">
                        </div>
                    </div>
                    <div class="u-mb-m">
                        <div [appFormControlError]="column.controls.key" class="vwui-form-group">
                            <label class="vwui-label" [for]="'columnKey'+i">{{'Kolom veldnaam'|translate}}</label>
                            <span class="vwui-form-group__description">
                                {{'veldnaam moet uniek zijn, en mag enkel a-z 0-9 en \'-\' bevatten'|translate}}
                            </span>
                            <span *ngIf="column.controls.key.errors?.unique"
                                  class="vwui-form-group__description">
                                {{'Veldnaam is niet uniek'|translate}}
                            </span>
                            <input
                                class="vwui-input"
                                [id]="'columnKey'+i"
                                [name]="'columnKey'+i"
                                formControlName="key"
                                autocomplete="off"
                                type="text"
                                pattern="[a-z0-9-]+"
                                placeholder="{{'Kolom veldnaam'|translate}}">
                        </div>
                    </div>

                    <app-question-edit-tabular-column-text *ngSwitchCase="'text'" formControlName="config">
                    </app-question-edit-tabular-column-text>

                    <app-question-edit-tabular-column-number *ngSwitchCase="'number'" formControlName="config">
                    </app-question-edit-tabular-column-number>

                    <p *ngSwitchDefault>
                        ERROR: Niet ondersteund kolom-type "{{column.value.type}}"
                    </p>

                    <hr>
                </ng-container>
                <ng-select #select placeholder="Nieuwe kolom"
                           [items]="columnTypes"
                           (change)="addColumn(select, columnsControl, $event?.type)">
                </ng-select>
            </ng-container>
        </div>
        <div class="vwui-aside-content with-tabs" [hidden]="tab !== 'advanced'">
            <div *ngIf="form.get('questionDependency')" class="vwui-form-group u-mb-m">
                <label class="vwui-label">{{'Afhankelijkheid'|translate}}</label>
                <div *ngIf="previousQuestions.length > 0">
                    <app-question-dependency
                        [formGroup]="form" id="questionDependency" formControlName="questionDependency"
                        [previousQuestions]="previousQuestions"
                    ></app-question-dependency>
                </div>
                <div *ngIf="previousQuestions.length === 0">
                    {{ 'Voeg hoger in het formulier een keuzevraag toe om hier een afhankelijkheid van een eerder antwoord in te kunnen stellen'|translate }}
                </div>
            </div>
            <div *ngIf="form.get('toleranceEnabled')" class="u-mb-m">
                <div class="u-mb-m">
                    <label class="vwui-input-control is-toggle has-label mb-4 vwui-label">
                        <input class="vwui-input" type="checkbox" name="toleranceEnabled"
                               formControlName="toleranceEnabled">
                        <span>{{ form.get('type').value === 'list' || form.get('type').value === 'table' ? 'Individuele tolerantie' : 'Tolerantie' }}</span>
                        <span class="checkbox"></span>
                    </label>
                </div>
                <app-question-answer
                    #questionAnswer
                    label="{{'Waar moet het aan voldoen?'|translate}}"
                    [class.d-none]="!form.get('toleranceEnabled').value"
                    [question]="$any(form.getRawValue())"
                    [formControl]="$any(form.controls['toleranceValue'])"
                    [overrideAllowMultiple]="true"
                >
                    <div number-answer>
                        <input
                            #min
                            class="vwui-input mb-2"
                            autocomplete="off"
                            type="number"
                            placeholder="{{'Minimum waarde'|translate}}"
                            [value]="getToleranceMinValue(questionAnswer.answerValue)"
                            (input)="questionAnswer.onInputChange($any($event), min.value+'^'+max.value)"
                        >
                        <input
                            #max
                            class="vwui-input"
                            autocomplete="off"
                            type="number"
                            placeholder="{{'Maximum waarde'|translate}}"
                            [value]="getToleranceMaxValue(questionAnswer.answerValue)"
                            (input)="questionAnswer.onInputChange($any($event), min.value+'^'+max.value)"
                        >
                    </div>
                </app-question-answer>
            </div>
            <div *ngIf="form.get('averageTolerance')" class="u-mb-m">
                <div class="u-mb-m">
                    <label class="vwui-input-control is-toggle has-label mb-4 vwui-label">
                        <input class="vwui-input" type="checkbox" [formControl]="averageToleranceEnabledControl">
                        <span>{{'Gemiddelde tolerantie'|translate}}</span>
                        <span class="checkbox"></span>
                    </label>
                </div>
                <div class="vwui-form-group" [class.d-none]="!averageToleranceEnabledControl.value">
                    <div>
                        <input
                            #tableToleranceMin
                            class="vwui-input mb-2"
                            autocomplete="off"
                            type="number"
                            placeholder="{{'Minimum waarde'|translate}}"
                            [value]="getToleranceMinValue(form.get('averageTolerance').value)"
                            (input)="form.get('averageTolerance').setValue(tableToleranceMin.value+'^'+tableToleranceMax.value)"
                        >
                        <input
                            #tableToleranceMax
                            class="vwui-input"
                            autocomplete="off"
                            type="number"
                            placeholder="{{'Maximum waarde'|translate}}"
                            [value]="getToleranceMaxValue(form.get('averageTolerance').value)"
                            (input)="form.get('averageTolerance').setValue(tableToleranceMin.value+'^'+tableToleranceMax.value)"
                        >
                    </div>
                </div>
            </div>
            <div *ngIf="form.get('preFillEnabled')" class="u-mb-m">
                <div class="u-mb-m">
                    <label class="vwui-input-control is-toggle has-label mb-4 vwui-label">
                        <input class="vwui-input" type="checkbox" name="preFillEnabled"
                               formControlName="preFillEnabled">
                        <span>{{'Standaard antwoord invullen'|translate}}</span>
                        <span class="checkbox"></span>
                    </label>
                </div>
                <app-question-answer
                    #questionAnswer
                    label="{{'Standaard antwoord'|translate}}"
                    [class.d-none]="!form.get('preFillEnabled').value"
                    [question]="form.getRawValue()"
                    [formControl]="form.controls['preFillValue']"
                >
                    <div number-answer>
                        <input
                            class="vwui-input"
                            autocomplete="off"
                            type="number"
                            placeholder="{{'Standaard antwoord'|translate}}"
                            [value]="questionAnswer.answerValue"
                            (input)="questionAnswer.onInputChange($event, $event.target.value)">
                    </div>
                </app-question-answer>
            </div>
            <ng-container *ngIf="form.get('choices') as choicesControl">
                <div *ngIf="followUpProjectJob" class="u-mb-m">
                    <app-follow-up-project-jobs
                        [choicesControl]="choicesControl"
                        [multipleControl]="form.controls.multiple"
                        [followUpProjectJob]="followUpProjectJob"></app-follow-up-project-jobs>
                </div>
                <div *ngIf="form.get('choices') && followUpForm && isFormType()" class="u-mb-m">
                    <app-follow-up-forms
                        [choicesControl]="choicesControl"
                        [multipleControl]="form.controls.multiple"
                        [followUpForm]="followUpForm"></app-follow-up-forms>
                </div>
            </ng-container>
            <div *ngIf="form.get('type').value === 'number'" class="u-mb-m">
                <app-follow-up-number-question formControlName="followUp"
                    [project]="followUpProjectJob?.project || null">
                </app-follow-up-number-question>
            </div>
            <div *appHasFeatureToggle="privacyEnabledToggle" class="u-mb-m">
                <label class="vwui-input-control is-toggle has-label mb-4 vwui-label">
                    <input class="vwui-input" type="checkbox" name="privacyEnabled" formControlName="privacyEnabled">
                    <span>{{'Deze vraag bevat privacy gevoelige informatie'|translate}}</span>
                    <span class="checkbox"></span>
                </label>
            </div>
            <div *appHasFeatureToggle="addToSummaryEnabledToggle">
                <div class="u-mb-m">
                    <label class="vwui-input-control is-toggle has-label mb-4 vwui-label">
                        <input class="vwui-input" type="checkbox" name="addToSummaryEnabled" formControlName="addToSummaryEnabled">
                        <span>{{'Voeg deze vraag toe aan de samenvatting'|translate}}</span>
                        <span class="checkbox"></span>
                    </label>
                </div>
                <div *ngIf="form.get('type').value === 'signature'" class="u-mb-m">
                    <label class="vwui-input-control is-toggle has-label mb-4 vwui-label">
                        <input class="vwui-input" type="checkbox" name="showSummaryEnabled" formControlName="showSummaryEnabled">
                        <span>{{'Toon samenvatting bij deze vraag'|translate}}</span>
                        <span class="checkbox"></span>
                    </label>
                </div>
            </div>
            <div class="u-mb-m">
                <div [appFormControlError]="form.get('reference')" class="vwui-form-group">
                    <label class="vwui-label" for="reference">{{'Vraagreferentie'|translate}}</label>
                    <input
                        class="vwui-input"
                        id="reference"
                        name="reference"
                        formControlName="reference"
                        autocomplete="off"
                        type="text"
                        placeholder="{{'Vraagreferentie'|translate}}">
                    <span class="vwui-form-group__description">
                        {{'Vraagreferentie moet uniek zijn binnen het formulier'|translate}}
                    </span>
                </div>
            </div>
        </div>
        <button type="submit" class="d-none"><!-- Needed to submit with enter --></button>
    </form>
</ng-container>
<app-aside-actionbar (cancel)="onCancel()" (save)="onSave()"></app-aside-actionbar>
<ng-template #editorLoadingSpinner><div class="spinner"></div></ng-template>
