<div class="vwui-modal-header">
    <h3 class="vwui-modal-title">{{ modalTitle }}</h3>
    <div class="vwui-modal-close" *ngIf="showCloseBtn" (click)="onCancel()">
        <app-icon iconName="x"></app-icon>
    </div>
</div>
<div class="vwui-modal-content">
    <ng-content></ng-content>
</div>
<div class="vwui-modal-footer">
    <button *ngIf="showCloseBtn" class="vwui-btn" (click)="onCancel()">{{ modalCancelBtnLabel }}</button>
    <button *ngIf="showSaveBtn && !loadingSaveBtn" class="vwui-btn is-success" (click)="onSave()" [disabled]="!enableSaveBtn">{{ modalSaveBtnLabel }}</button>
    <app-loading-btn *ngIf="showSaveBtn && loadingSaveBtn" class="is-success" (btnClick)="onSave($event)" [disabled]="!enableSaveBtn">{{ modalSaveBtnLabel }}</app-loading-btn>
</div>
