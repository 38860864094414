// @ts-strict-ignore
import {Injectable} from "@angular/core";
import {NavigationExtras, Router, RoutesRecognized} from "@angular/router";
import {filter, map, pairwise, take} from "rxjs/operators";
import {BehaviorSubject} from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class HistoryService {
    private currentPage: string = null
    previousPage$ = new BehaviorSubject<string>(null);

    constructor(
        private router: Router,
    ) {
        this.router.events
            .pipe(
                filter((evt: any) => evt instanceof RoutesRecognized),
                map<RoutesRecognized, string>(route => route.urlAfterRedirects.split("?")[0]),
                filter(route => route !== this.currentPage),
                pairwise()
            ).subscribe((routes: string[]) => {
            this.currentPage = routes[1];
            this.previousPage$.next(routes[0]);
        });
    }

    async goBack(commands?: any[], extras?: NavigationExtras) {
        if (await this.previousPage$.pipe(take(1)).toPromise() === null && commands !== null) {
            return this.router.navigate(commands, extras);
        } else {
            history.back();
        }
    }
}
