// @ts-strict-ignore
import {combineLatest, Subscription} from 'rxjs';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormGroup, ValidationErrors} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {filter} from "rxjs/operators";

@Component({
    selector: 'app-validation-error',
    templateUrl: './validation-error.component.html'
})
export class ValidationErrorComponent implements OnInit, OnDestroy {
    @Input() form: UntypedFormGroup
    @Input() controlName: string

    message: string = null
    private subscription: Subscription = null

    constructor(private translate: TranslateService) {
    }

    ngOnInit(): void {
        this.subscription = combineLatest(
            [this.form.controls[this.controlName].valueChanges, this.form.statusChanges]
        ).pipe(
            filter(([, status]) => status !== 'PENDING')
        ).subscribe(() => {
            this.message = this.getMessage(this.form.controls[this.controlName].errors)
        })
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    getMessage(errors: ValidationErrors): string | null {
        const messages: string[] = [];

        if (!errors) {
            return null;
        }

        Object.keys(errors).forEach(key => {
            switch (key) {
                case "required":
                    messages.push(this.translate.instant("Veld is verplicht"));
                    break;
                case "maxlength":
                    const max = errors[key].requiredLength;
                    messages.push(this.translate.instant(`Veld mag maximaal ${max} karakters zijn`));
                    break;
                case "exists":
                    messages.push(this.translate.instant("Deze code is reeds in gebruik"));
                    break;
                default:
                    console.error(`Unknown error '${key}'`, errors[key]);
            }
        })

        return messages.join(", ");
    }
}
