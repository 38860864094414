<div class="vwui-form-group" *ngIf="question">
    <label class="vwui-label">{{ label }}</label>
    <div *ngIf="isTextQuestion">
        <input
            class="vwui-input"
            autocomplete="off"
            type="text"
            placeholder="{{'Standaard antwoord'|translate}}"
            maxlength="100"
            [ngModel]="answerValue"
            (input)="onInputChange($event, $event.target.value)">
        <span class="vwui-form-group__description">{{'Maximaal 100 karakters'|translate}}</span>
    </div>
    <div *ngIf="isMultilineTextQuestion">
        <textarea
            class="vwui-textarea"
            autocomplete="off"
            placeholder="{{'Standaard antwoord'|translate}}"
            maxlength="4500"
            [ngModel]="answerValue"
            (input)="onInputChange($event, $event.target.value)"></textarea>
        <span class="vwui-form-group__description">{{'Maximaal 4500 karakters'|translate}}</span>
    </div>
    <div *ngIf="isNumberQuestion || isListQuestion">
        <ng-content select="[number-answer]"></ng-content>
    </div>
    <div *ngIf="isChoiceQuestion">
        <ng-container *ngIf="questionChoices && questionChoices.length; else noChoices">
            <div *ngFor="let choice of questionChoices">
                <app-checkbox
                    [type]="checkboxType"
                    [name]="choice.title"
                    [value]="choice.id"
                    [checked]="isChecked(choice.id)"
                    (valueChange)="onCheckboxChange($event)"
                >
                    {{ choice.title }}
                </app-checkbox>
            </div>
        </ng-container>
        <ng-template #noChoices>
            <div>
                {{'Er zijn nog geen keuzes toegevoegd'|translate}}
                {{'Voeg eerst een keuze toe aan deze vraag om een tolerantie in te kunnen stellen'|translate}}
            </div>
        </ng-template>
    </div>
</div>
