import {Component} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ImportedForm} from 'src/app/models/form-type-import-status';

@Component({
    selector: 'app-import-form-success-modal',
    templateUrl: './import-form-success-modal.component.html'
})
export class ImportFormSuccessModalComponent {
    importedForms: ImportedForm[] = [];

    constructor(private modalRef: BsModalRef) {}

    close() {
        this.modalRef.hide();
    }
}
