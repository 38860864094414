// @ts-strict-ignore
import {Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {ObstructionCodeService} from "../../services/obstruction-code.service";
import {ToastrService} from "ngx-toastr";
import {PaulaObstructionCode} from "../../models/paula-obstruction-code";
import {Observable, Subscription} from "rxjs";
import {Subscriptions} from "../../utils/subscriptions";
import {Forms} from "../../utils/forms";
import {HistoryService} from "../../services/history.service";

@Component({
    selector: 'app-obstruction-code-detail',
    templateUrl: './obstruction-code-detail.component.html'
})
export class ObstructionCodeDetailComponent implements OnInit, OnDestroy {

    form: UntypedFormGroup = new UntypedFormGroup({
        id: new UntypedFormControl(null),
        code: new UntypedFormControl(
            null,
            [Validators.required, Validators.maxLength(10)],
            [this.validateObstructionCodeNotTaken.bind(this)]
        ),
        name: new UntypedFormControl(null, [Validators.required, Validators.maxLength(50)]),
    })
    obstructionCode: PaulaObstructionCode;
    private subscriptions: Subscription[] = [];

    constructor(
        private route: ActivatedRoute,
        @Inject('ObstructionCodeService') private obstructionCodeService: ObstructionCodeService,
        private toast: ToastrService,
        private router: Router,
        private historyService: HistoryService,
    ) {
    }

    ngOnInit(): void {
        const routeDataSubscription = this.route.data.subscribe((data) => {
            if (data.obstructionCode) {
                this.obstructionCode = data.obstructionCode;
                this.form.patchValue(this.obstructionCode);
            }
        });
        this.subscriptions.push(routeDataSubscription);
    }

    ngOnDestroy(): void {
        Subscriptions.unsubscribeAll(this.subscriptions)
    }

    async save() {
        if (!this.form.valid) {
            Forms.updateValueAndValidityRecursive(this.form);
            this.toastInvalidForm();
            return;
        }

        const newObstructionCode = this.form.value as PaulaObstructionCode;

        try {
            const result = await (newObstructionCode.id ?
                this.obstructionCodeService.putObstructionCode(newObstructionCode).toPromise() :
                this.obstructionCodeService.postObstructionCode(newObstructionCode).toPromise())

            this.toast.success('Opgeslagen');
            this.form.patchValue(result);

            this.goBack();
        } catch (ex) {
            console.error('Unable to save obstruction code', ex);
            this.toast.error('Opslaan mislukt');
        }
    }

    cancel() {
        this.goBack();
    }

    goBack() {
        this.historyService.goBack(['/beheer/obstruction-codes']);
    }

    private validateObstructionCodeNotTaken(control: AbstractControl): Observable<ValidationErrors> {
        const currentId = +this.form.get('id').value;

        return Forms.validateNotTaken(control, currentId, this.obstructionCodeService.exists(control.value))
    }

    private toastInvalidForm() {
        this.toast.error('Vul alle velden correct in (in het rood aangegeven)');
    }

}
