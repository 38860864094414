// @ts-strict-ignore
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'sum'})
export class SumPipe implements PipeTransform {
    transform(values: String[]): number {
        return values
            .map(value => value.replace(',', '.'))
            .map(value => {
                try {
                    return parseFloat(value);
                } catch (error) {
                    console.warn('Unable to parse float', error);
                }

                return null;
            })
            .filter(value => value !== null && !isNaN(value))
            .reduce(((prev, curr) => prev + curr));
    }
}
