// @ts-strict-ignore
import {Component, ElementRef, Inject, Input} from '@angular/core';
import {ProjectJobForm} from '../../models/job-form/project-job-form';
import {FormUtils} from '../../utils/form-utils';
import {QuestionChoice} from '../../models/job-form/question-choice';
import {ProjectJob, ProjectJobStatus} from '../../models/project-job';
import {ProjectJobService} from '../../services/project-job.service';
import {ObjectService} from '../../services/object.service';
import {Object} from '../../models/object';
import {ChapterFormItem, QuestionType} from '../../models/form-item';
import {Question} from '../../models/job-form/question';
import {BsModalService} from 'ngx-bootstrap/modal';
import {ImageAnswerPreviewModalComponent} from '../image-answer-preview-modal/image-answer-preview-modal.component';
import * as JSZip from 'jszip';
import * as FileSaver from 'file-saver';
import {FeatureToggleCheckService} from '../../services/feature-toggle-check.service';
import {FeatureToggle} from '../../models/feature-toggle';
import {DatetimeQuestion} from '../../models/job-form/datetime-question';
import {TextQuestion} from '../../models/job-form/text-question';
import {NumberQuestion} from '../../models/job-form/number-question';
import {ChoiceQuestion} from '../../models/job-form/choice-question';
import {SignatureQuestion} from '../../models/job-form/signature-question';
import {PhotoQuestion} from '../../models/job-form/photo-question';
import {ObjectQuestion} from '../../models/job-form/object-question';
import {mimetypeToExtension} from '../../utils/mime-type';
import {ProjectJobRejectModalComponent} from '../project-job-reject-modal/project-job-reject-modal.component';
import {take} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {ProjectJobFormChapter} from '../../models/job-form/project-job-form-chapter';
import {firstValueFrom} from 'rxjs';
import {Filters} from '../../utils/filters';

@Component({
    selector: 'app-project-job-answers-tab',
    templateUrl: './project-job-answers-tab.component.html'
})
export class ProjectJobAnswersTabComponent {

    @Input()
    set projectJob(value: ProjectJob) {
        this.projectJobService.getFormDetail(value.id).toPromise().then(it => this.jobForm = it);
    }

    @Input()
    private set preselectedImageUuid(imageUuid: string|null) {
        if (imageUuid) {
            this.scrollToImageUUID(imageUuid);
            this.openImagePreview(imageUuid);
        }
    }

    jobForm: ProjectJobForm;
    objects: { [key: string]: Promise<Object> } = {};

    previewAnswerImageEnabled$ = this.featureToggleCheckService.featureToggle(FeatureToggle.previewAnswerImageEnabled);

    projectJobAnswerMetaEnabled = FeatureToggle.projectJobAnswerMetaEnabled;

    readonly verificationStatus = ProjectJobStatus.AvailableForVerification;

    rejectingOrApproving = false;

    constructor(
        @Inject('ProjectJobService') private projectJobService: ProjectJobService,
        @Inject('ObjectService') private objectService: ObjectService,
        private modalService: BsModalService,
        protected router: Router,
        protected activatedRoute: ActivatedRoute,
        private toast: ToastrService,
        @Inject('FeatureToggleCheckService') private featureToggleCheckService: FeatureToggleCheckService,
        private elementRef: ElementRef,
        private translateService: TranslateService
    ) {}

    getAnswer(position: number) {
        return FormUtils.getFormItemLastAnswer(this.jobForm.answers, position);
    }

    isQuestionVisible(position: number) {
        return FormUtils.isQuestionVisible(this.jobForm, position);
    }

    isChapterVisible(position: number) {
        return FormUtils.isChapterVisible(this.jobForm, position);
    }

    isQuestionChoiceSelected(choice: QuestionChoice) {
        return FormUtils.isQuestionChoiceSelected(this.jobForm, choice);
    }

    parseTabularJson(value: string) {
        try {
            return JSON.parse(value);
        } catch (e) {
            console.error('Unable to retrieve tabular rows: ', e);
        }
    }

    getObject(project: number, id: string) {
        const key = `${project}-${id}`;
        if (!(key in this.objects)) {
            this.objects[key] = this.objectService.getObject(project, +id).toPromise();
        }
        return this.objects[key];
    }

    parseFormulaJson(value: string) {
        try {
            return JSON.parse(value);
        } catch (e) {
            console.error('Unable to retrieve formulas: ', e);
        }
    }

    parseImageAnswer(answer: string): string[] {
        return answer?.split(',').filter(item => item != '')
    }

    dependentQuestionPosition(formItem: Question | ChapterFormItem | ProjectJobFormChapter) {
        const firstDependentChoiceId = (formItem.questionDependency || []).map(it => it.id)[0];

        if (!firstDependentChoiceId) {
            return null;
        }

        for (const [chapterIndex, chapter] of this.jobForm.chapters.entries()) {
            for (const [index, question] of chapter.questions.entries()) {
                if (question.type === QuestionType.choice
                    && question.choices.map(it => it.id).includes(firstDependentChoiceId)) {
                    return `${chapterIndex + 1}.${index + 1}`;
                }
            }
        }
    }

    async openImagePreview(imageUuid: string) {
        if (await this.previewAnswerImageEnabled$.pipe(take(1)).toPromise()) {
            this.modalService.show(ImageAnswerPreviewModalComponent, {
                class: 'modal-xl bg-dark',
                initialState: {
                    imageSrc: imageUuid
                },
            });
        }
    }

    downloadFiles(
        question: TextQuestion | NumberQuestion | ChoiceQuestion | DatetimeQuestion | SignatureQuestion | ObjectQuestion | PhotoQuestion
    ) {
        const zipFile = new JSZip();
        const folder = zipFile.folder('images');

        let imageUuids: string[];
        if (question.type === 'photo') {
            imageUuids = this.getAnswer(question.position).value.split(',');
        } else if (question.type === 'choice') {
            imageUuids = this.getAnswer(question.position).remarkImage.split(',');
        }

        Promise.all(imageUuids.filter(uuid => uuid != '').map((imageUuid) => fetch(`/blob/${imageUuid}`)
            .then(async (r) => {
                return [r, await r.blob()];
            })
            .then(([r, blob]) => ({
                uuid: imageUuid,
                image: blob as Blob,
                filename: `${imageUuid}.${(mimetypeToExtension as {[key: string]: string})[blob.type]}`
            })))
        ).then((res) => {
            res.forEach((image) => {
                folder.file(image.filename, image.image);
            });
            zipFile.generateAsync({type: 'blob'}).then((content) => {
                FileSaver.saveAs(content, 'fotos.zip');
            });
        });
    }

    shouldShowDownloadButton(
        question:
            TextQuestion |
            NumberQuestion |
            ChoiceQuestion |
            DatetimeQuestion |
            SignatureQuestion |
            ObjectQuestion |
            PhotoQuestion
    ): boolean {
        const answer = this.getAnswer(question.position);
        return answer && (
            question.type === 'photo'
            || (question.type === 'choice' && !!answer.remarkImage)
        );
    }

    async rejectJob() {
        try {
            this.rejectingOrApproving = true;
            if (FeatureToggle.ProjectJobShowRejectReason) {
                this.openRejectModal();
            } else {
                await this.projectJobService.transition({
                    ...this.projectJob,
                    id: this.jobForm.id
                }, ProjectJobStatus.Rejected).toPromise();
                this.toast.success('Opdracht afgekeurd');
            }
        } catch (e) {
            console.error('error while rejecting job', e);
            this.toast.error('Opdracht afkeuren mislukt');
        } finally {
            this.rejectingOrApproving = false;
        }
    }

    openRejectModal() {
        const modal = this.modalService.show(ProjectJobRejectModalComponent, {
            class: 'modal-dialog-centered'
        });
        const projectJobRejectModal = modal.content as ProjectJobRejectModalComponent;

        this.modalService.onHidden.pipe(
            take(1)
        ).subscribe(async () => {
            if (projectJobRejectModal.result === 'confirmed') {
                const reason = projectJobRejectModal.form.get('projectJobRejectGroup').value;
                await this.projectJobService.transition(
                    {...this.projectJob, id: this.jobForm.id}, ProjectJobStatus.Rejected, reason !== '' ? reason : null
                ).toPromise();
            }

            this.toast.success('Opdracht afgekeurd');
            await this.goToNextVerificationJob();
        });
    }

    async approveJob() {
        try {
            this.rejectingOrApproving = true;
            await this.projectJobService.transition({
                ...this.projectJob,
                id: this.jobForm.id
            }, ProjectJobStatus.Approved).toPromise();
            this.toast.success('Opdracht goedgekeurd');
            await this.goToNextVerificationJob();
        } catch (e) {
            const errorMessage = this.translateService.instant(e.error.message);
            return this.toast.error(errorMessage);
        } finally {
            this.rejectingOrApproving = false;
        }
    }

    async goToNextVerificationJob() {
        const tableFilter = Filters.getFilteringFromParams(this.activatedRoute.snapshot.queryParams);
        tableFilter.filter['status'] = ProjectJobStatus.AvailableForVerification;

        const verificationJobs = await firstValueFrom(this.projectJobService.getList(0, tableFilter));
        const tableFilterQueryParams = Filters.tableFilterToQueryParamsObject(tableFilter);
        const nextJob = verificationJobs.content[0]?.id;

        if (nextJob) {
            await this.router.navigate(['/beheer/project-jobs/', nextJob], {
                replaceUrl: true,
                queryParams: tableFilterQueryParams
            });
        } else {
            await this.router.navigate(['/beheer/project-jobs/'], {queryParams: tableFilterQueryParams});
        }
    }

    private scrollToImageUUID(imageUuid: string | null) {
        const observer = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                if (mutation.type === 'childList') {
                    mutation.addedNodes.forEach(element => {
                        if ((element as HTMLElement).id === imageUuid) {
                            (element as HTMLElement).scrollIntoView();
                            observer.disconnect();
                        }
                    });
                }
            });
        });
        observer.observe(this.elementRef.nativeElement, { subtree: true, childList: true });
    }
}
