<app-modal modalTitle="{{'Vervolgopdracht koppelen'|translate}}" modalSaveBtnLabel="{{ getSaveButtonText() }}" (save)="submit()"
           (cancel)="closeModal('cancelled')">
    <form [formGroup]="form" *ngIf="form">
        <div class="u-mb-m">
            <div [appFormControlError]="form.get('formType')" class="vwui-form-group mb-4">
                <label for="formType" class="vwui-label">{{'Formulier'|translate}}</label>
                <app-form-type-select id="formType" formControlName="formType"></app-form-type-select>
            </div>
        </div>
        <div class="u-mb-m">
            <div [appFormControlError]="form.get('title')" class="vwui-form-group">
                <label class="vwui-label" for="title">{{'Opdrachtnaam'|translate}}</label>
                <input
                    class="vwui-input"
                    id="title"
                    name="title"
                    formControlName="title"
                    autocomplete="off"
                    type="text"
                    placeholder="{{'Opdrachtnaam'|translate}}">
            </div>
        </div>
        <div class="u-mb-m">
            <div [appFormControlError]="form.get('recoveryTime')" class="vwui-form-group">
                <label for="recoveryTime" class="vwui-label">{{'Hersteltermijn'|translate}}</label>
                <ng-select
                    id="recoveryTime"
                    formControlName="recoveryTime"
                    bindLabel="value"
                    bindValue="key"
                    placeholder="{{'Kies een hersteltermijn'|translate}}"
                    [items]="recoveryTimes"
                    [clearable]="true">
                </ng-select>
            </div>
        </div>
        <div *appHasFeatureToggle="maximoToggle" class="u-mb-m">
            <div [appFormControlError]="form.get('actionTaker')" class="vwui-form-group">
                <label for="actionTaker" class="vwui-label">{{'Actienemer'|translate}}</label>
                <ng-select
                    id="actionTaker"
                    formControlName="actionTaker"
                    bindLabel="label"
                    bindValue="value"
                    placeholder="{{'Actienemer'|translate}}"
                    [items]="actionTakers"
                    [clearable]="true">
                </ng-select>
            </div>
        </div>
        <button type="submit" class="d-none"><!-- Needed to submit with enter --></button>
    </form>
</app-modal>
