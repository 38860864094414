// @ts-strict-ignore
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormType, FormTypes, formTypeStatuses} from '../../models/form-type';
import {FormControl, FormGroup, UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {firstValueFrom, Subscription, switchMap} from 'rxjs';
import {FormTypeService} from '../../services/form-type.service';
import {ToastrService} from 'ngx-toastr';
import {Subscriptions} from '../../utils/subscriptions';
import {Forms} from '../../utils/forms';
import {UserService} from '../../services/user.service';
import {AuthenticationService} from '../../services/authentication.service';
import {distinctUntilChanged} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {FeatureToggle} from '../../models/feature-toggle';
import {HistoryService} from "../../services/history.service";

@Component({
    selector: 'app-form-type-detail',
    templateUrl: './form-type-detail.component.html'
})
export class FormTypeDetailComponent implements OnInit, OnDestroy {
    public formType: FormType;
    public form = new FormGroup({
        id: new UntypedFormControl(null),
        title: new UntypedFormControl(null, [Validators.required, Validators.maxLength(320)]),
        status: new UntypedFormControl('Open', [Validators.required]),
        code: new UntypedFormControl('', [Validators.required, Validators.maxLength(255)]),
        paulaObjectTypes: new UntypedFormControl([], [Validators.required]),
        companies: new UntypedFormControl([]),
        tags: new UntypedFormControl([]),
        contactPerson: new UntypedFormControl(this.authenticationService.loggedInEmail$.value),
        type: new UntypedFormControl(FormTypes.FormType),
        askLocation: new FormControl<boolean>(false, {nonNullable: true}),
        revisionNumber: new UntypedFormControl(null, [Validators.maxLength(255)]),
        showLocationOnMap: new FormControl(true, {nonNullable: true}),
        inspectionForm: new FormControl<boolean>(false, {nonNullable: true}),
        copyAnswers: new FormControl(false, {nonNullable: true}),
        executorCanCreate: new FormControl<boolean>(true, {nonNullable: true}),
        askExecutorToFillFormAgain: new FormControl<boolean>(false, {nonNullable: true})
    });
    public formTypeStatuses = formTypeStatuses;
    public showFormNumberHelp = false;

    public inspectionFormSyncToggle = FeatureToggle.InspectionFormSync;

    private subscriptions: Subscription[] = [];

    isNew = true;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        @Inject('FormTypeService') private formTypeService: FormTypeService,
        @Inject('UserService') private userService: UserService,
        private authenticationService: AuthenticationService,
        private toast: ToastrService,
        fb: UntypedFormBuilder,
        private translate: TranslateService,
        private historyService: HistoryService
    ) {
        this.subscriptions.push(this.form.valueChanges.subscribe(formValues => {
            this.isNew = formValues.id === null;
        }));
    }

    get formTitle() {
        return this.formType ? this.formType.title : 'Nieuw formulier';
    }

    ngOnInit(): void {
        const routeDataSubscription = this.route.data.subscribe((data) => {
            if (data.formType) {
                this.formType = data.formType;
                this.patchForm(this.formType);
            }
        });

        if (this.isNew) {
            this.subscriptions.push(this.authenticationService.loggedInEmail$.pipe(
                distinctUntilChanged(),
                switchMap(it => this.userService.getUserByUsername(it)),
            ).subscribe(user => {
                const companiesControl = this.form.get('companies');
                if (!companiesControl.value || companiesControl.value.length === 0) {
                    companiesControl.patchValue(user?.company ? [user.company] : []);
                }
            }));
        }

        this.subscriptions.push(routeDataSubscription);

        this.subscriptions.push(this.form.get('code').valueChanges.subscribe(data => {
            this.formTypeService.getByCode(data).subscribe((response: FormType[]) => {
                this.showFormNumberHelp = Forms.validateFormNumber(this.form.get('code'), response, this.form);
            });
        }));

        this.subscriptions.push(this.form.controls.executorCanCreate.valueChanges.subscribe(executorCanCreate => {
            if (executorCanCreate) {
                this.form.controls.askExecutorToFillFormAgain.enable();
            } else {
                this.form.controls.askExecutorToFillFormAgain.disable();
                this.form.controls.askExecutorToFillFormAgain.setValue(false);
            }
        }))

    }

    ngOnDestroy(): void {
        Subscriptions.unsubscribeAll(this.subscriptions);
    }

    async save() {
        Forms.updateValueAndValidityRecursive(this.form);
        if (!this.form.valid) {
            this.toastInvalidForm();
            return;
        }

        try {
            let formTypeRequest = this.form.getRawValue();
            let formTypeResponse: FormType;
            if (this.form.controls.contactPerson.value) {
                formTypeRequest.contactPerson = this.form.controls.contactPerson.value.username;
            }
            if (this.isNew) {
                formTypeResponse = await firstValueFrom(this.formTypeService.postFormType(formTypeRequest));

                if (formTypeResponse.id) {
                    await this.router.navigate(['beheer', 'form-types', formTypeResponse.id], {replaceUrl: true});
                }
            } else {
                formTypeResponse = await firstValueFrom(this.formTypeService.putFormType(formTypeRequest));
            }

            this.formType = formTypeResponse;
            this.patchForm(this.formType);

            this.toast.success(
                this.translate.instant('Opgeslagen'),
                this.translate.instant('Succes')
            );
        } catch (error) {
            console.error(error);
            this.toast.error(
                this.translate.instant('Opslaan mislukt'),
                this.translate.instant('Fout')
            );
        }
    }

    cancel() {
        this.goBack();
    }

    goBack() {
        this.historyService.goBack(['beheer', 'form-types']);
    }

    private toastInvalidForm() {
        this.toast.error(
            this.translate.instant('Vul alle verplichte velden in (in het rood aangegeven)'),
            this.translate.instant('Fout')
        );
    }

    private patchForm(formType: FormType) {
        this.form.patchValue({
            ...formType,
            contactPerson: {username: formType.contactPerson}
        });
    }
}
