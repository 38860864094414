<app-toolbar
    [showAddBtn]="true"
    [addBtnRoute]="'/beheer/object-types/new'"
    addBtnLabel="{{'Nieuw objecttype'|translate}}">
</app-toolbar>
<div class="vwui-container">
    <app-table
        *ngIf="paginator$|async;let paginator"
        [paginator]="paginator"
        [headerTitles]="tableHeaders"
        (filterChanged)="onTableFilterChange($event)">
        <ng-template let-item>
            <tr [routerLink]="['/beheer/object-types/', item.id]">
                <td>{{ item.title }}</td>
                <td>{{ item.code }}</td>
                <td>{{ item.description | truncate }}</td>
                <td>{{ item.companies | arrayPropertyValues: 'name'}}</td>
                <td>{{ $any(objectTypeStatusTitles)[item.status] }}</td>
            </tr>
        </ng-template>
    </app-table>
</div>
