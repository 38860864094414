<app-toolbar
    [showAddBtn]="true"
    [addBtnRoute]="'/beheer/projects/new'"
    addBtnLabel="{{'Nieuw project'|translate}}">
</app-toolbar>
<div class="vwui-container">
    <app-table *ngIf="paginator$|async;let paginator" [paginator]="paginator" [headerTitles]="headerTitles" (filterChanged)="onTableFilterChange($event)">
        <ng-template let-item>
            <a class="vwui-table-row" [routerLink]="['/beheer/projects/', item.id]">
                <td>{{ item.name }}</td>
                <td>{{item.code}}</td>
                <td><span class="vwui-badge" [class.is-success]="item.active">{{ item.active ? 'Actief' : 'Gedeactiveerd' }}</span></td>
            </a>
        </ng-template>
    </app-table>
</div>
