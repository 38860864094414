// @ts-strict-ignore
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {TableFilter} from '../components/table/table.component';
import {Filters} from '../utils/filters';
import {PageResponse} from '../models/page-response';
import {Project} from '../models/project';
import {Observable} from 'rxjs';
import {FormType} from '../models/form-type';
import {map} from 'rxjs/operators';

export interface ProjectService {
    getProjects(page: number, filter?: TableFilter): Observable<PageResponse<Project>>;
    getProject(id: number): Observable<Project>;
    putProject(project: Project): Observable<Project>;
    postProject(project: Project): Observable<Project>;
    exists(code: string): Observable<number>;
    exportProject(id: number): Observable<{ filename: string, data: Blob }>;
    cloneFormType(projectId: number, title: string, formType: number, code: string): Observable<FormType>;
}

@Injectable({
  providedIn: 'root'
})
export class ProjectServiceImpl implements ProjectService {
    constructor(private httpClient: HttpClient) {
    }

    getProjects(page: number, filter?: TableFilter) {
        let params = new HttpParams().set('page', '' + page);

        if (filter) {
            params = Filters.applyFiltering(filter, params);
        }

        return this.httpClient.get<PageResponse<Project>>('/api/v1/projects', {params});
    }

    getProject(id: number) {
        return this.httpClient.get<Project>(`/api/v1/projects/${id}`);
    }

    putProject(project: Project) {
        return this.httpClient.put<Project>(`/api/v1/projects/${project.id}`, project);
    }

    postProject(project: Project) {
        return this.httpClient.post<Project>('/api/v1/projects', project);
    }

    exists(code: string): Observable<number> {
        const params = new HttpParams().set('code', code);

        return this.httpClient.get<number>(`api/v1/projects/exists`, {params});
    }

    cloneFormType(projectId: number, title: string, formType: number, code: string): Observable<FormType> {
        return this.httpClient.post<FormType>(`/api/v1/projects/${projectId}/forms/copy`, {
            title,
            formType,
            code
        });
    }

    exportProject(project: number): Observable<{ filename: string, data: Blob }> {
        return this.httpClient.get(`api/v1/projects/${project}/export`, {
            observe: 'response',
            responseType: 'blob'
        }).pipe(map(it => {
            const contentDisposition = it.headers.get('Content-Disposition');
            const filename = contentDisposition.match(/filename="(.+)"/)[1];
            return {filename, data: it.body};
        }));
    }
}
