import {EntitySelectComponent} from './item-select.component';
import {Component, forwardRef, Inject, Input} from '@angular/core';
import {Project} from '../../models/project';
import {Observable, of} from 'rxjs';
import {PageResponse} from '../../models/page-response';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {ProjectService} from '../../services/project.service';

@Component({
    selector: 'app-project-select',
    templateUrl: './item-select.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ProjectSelectComponent),
            multi: true
        }
    ]
})
export class ProjectSelectComponent extends EntitySelectComponent<Project, {}> {

    @Input() projectActive?: 'actief' | 'gedeactiveerd';

    constructor(
        @Inject('ProjectService') private projectService: ProjectService,
    ) {
        super();

        this.placeholder = 'Selecteer een project';
    }

    getFilter(): Observable<{}> {
        return of({});
    }

    getLabelText(item: Project): string {
        return item.name;
    }

    loadMore(page: number, filter: { term: string }): Observable<PageResponse<Project>> {
        return this.projectService.getProjects(page, {
            filter: {
                name: filter && filter.term ? filter.term : '',
                active: this.projectActive ? this.projectActive : ''
            }
        });
    }
}
