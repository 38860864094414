// @ts-strict-ignore
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {WorkerGroupService} from '../../services/worker-group.service';
import {ToastrService} from 'ngx-toastr';
import {WorkerGroup} from '../../models/worker-group';
import {Subscriptions} from '../../utils/subscriptions';
import {HistoryService} from "../../services/history.service";

@Component({
    selector: 'app-worker-group-detail',
    templateUrl: './worker-group-detail.component.html'
})
export class WorkergroupDetailComponent implements OnInit, OnDestroy {

    workergroup: WorkerGroup;
    form: UntypedFormGroup;

    private subscriptions: Subscription[] = [];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        @Inject('WorkerGroupService') private workergroupService: WorkerGroupService,
        private toast: ToastrService,
        fb: UntypedFormBuilder,
        private historyService: HistoryService,
    ) {
        this.form = this.createForm(fb);
    }

    get workergroupId(): string {
        return this.form.get('id').value;
    }

    get workergroupName(): string {
        return this.workergroup ? this.workergroup.name : 'Nieuwe medewerkersgroep';
    }

    isNew(): boolean {
        return !this.workergroupId;
    }

    ngOnDestroy(): void {
        Subscriptions.unsubscribeAll(this.subscriptions);
    }

    ngOnInit(): void {
        const routeDataSubscription = this.route.data.subscribe((data) => {
            if (data.workergroup) {
                this.workergroup = data.workergroup;
                this.form.patchValue(this.workergroup);
            }
        });

        this.subscriptions.push(routeDataSubscription);
    }

    async save() {
        if (!this.form.valid) {
            this.toast.error('Vul alle verplichte velden in (in het rood aangegeven)');
            return;
        }

        try {
            let workergroup;

            if (this.isNew()) {
                workergroup = await this.workergroupService.postWorkerGroup(this.form.value).toPromise();

                this.toast.success('Opgeslagen');

                if (workergroup.id) {
                    this.router.navigate(['beheer', 'workergroups', workergroup.id], {replaceUrl: true});
                }
            } else {
                workergroup = await this.workergroupService.putWorkerGroup(this.form.value).toPromise();

                this.toast.success('Opgeslagen');
                this.workergroup = workergroup;
                this.form.patchValue(workergroup);
            }
        } catch (error) {
            console.error(error);
            this.toast.error('Opslaan mislukt');
        }
    }

    cancel() {
        this.historyService.goBack(['beheer', 'workergroups']);
    }

    goToWorkerGroups() {
        this.router.navigate([this.router.url, 'workers']);
    }

    private createForm(fb: UntypedFormBuilder): UntypedFormGroup {
        return fb.group({
            id: fb.control(null),
            name: fb.control(null, [Validators.required]),
            workerGroupUsers: fb.control([]),
            projects: fb.control([])
        });
    }
}
