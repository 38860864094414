import {Component, forwardRef, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators} from "@angular/forms";
import {getEmptyTableColumn, TableColumnNumber} from "../../models/table-column";
import {QuestionType} from "../../models/form-item";

type ColumnConfig = TableColumnNumber['config'];
const emptyConfig = (getEmptyTableColumn(QuestionType.number) as TableColumnNumber).config

@Component({
  selector: 'app-question-edit-tabular-column-number',
  templateUrl: './question-edit-tabular-column-number.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => QuestionEditTabularColumnNumberComponent),
            multi: true
        }
    ]
})
export class QuestionEditTabularColumnNumberComponent implements OnInit, OnDestroy, ControlValueAccessor {
    subscription: Subscription | null = null;
    disabled = false;

    onChange: (value: ColumnConfig) => void = () => {}
    onTouched: () => void = () => {}

    configForm = new FormGroup({
        totalDecimal: new FormControl<number>(emptyConfig.totalDecimal, {
            nonNullable: true,
            validators: [Validators.min(0), Validators.max(12)]
        }),
        showAverage: new FormControl<boolean>(emptyConfig.showAverage, {
            nonNullable: true
        }),
        showSum: new FormControl<boolean>(emptyConfig.showSum, {
            nonNullable: true
        })
    });

    constructor() {
    }

    ngOnInit() {
        this.subscription = this.configForm.valueChanges.subscribe(value => {
            this.onChange(this.configForm.getRawValue())
            this.onTouched()
        })
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe()
    }

    registerOnChange(fn: (value: ColumnConfig) => void): void {
        this.onChange = fn
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled
    }

    writeValue(value: ColumnConfig | null): void {
        if (value === null) {
            console.error("QuestionEditTabularColumnNumberComponent doesnt support null values")
            this.configForm.reset()
            return;
        }

        this.configForm.patchValue(value, {emitEvent: false})
    }
}
