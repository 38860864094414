import {Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {AbstractListComponent} from '../../pages/abstract-list.component';
import {PageResponse} from '../../models/page-response';
import {SearchFilterValue, TableFilter} from '../table/table.component';
import {User} from '../../models/user';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../services/user.service';

@Component({
    selector: 'app-user-select-card',
    templateUrl: './user-select-card.component.html'
})
export class UserSelectCardComponent extends AbstractListComponent<User> implements OnChanges {
    @Input() filter: TableFilter = {filter: {}};
    @Input() hiddenUsers: User[] = [];
    @Output() userSelected = new EventEmitter<User>();

    constructor(
        @Inject('UserService') private userService: UserService,
        activatedRoute: ActivatedRoute,
        router: Router,
    ) {
        super(activatedRoute, router);
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.filter) {
            // call searchChanges on filter change to refresh data
            const currentFilter = await this.tableFilter$.pipe(take(1)).toPromise();
            this.searchChanged(currentFilter && currentFilter.filter && currentFilter.filter.search || '');
        }
    }

    searchChanged(search: SearchFilterValue) {
        this.onTableFilterChange({
            ...this.filter,
            filter: {
                ...this.filter.filter,
                'username-displayName': search
            }
        });
    }

    loadData(page: number, tableFilter: TableFilter): Observable<PageResponse<User>> {
        return this.userService.getUsers(page, tableFilter);
    }

    filterHidden(users: User[]) {
        return users.filter(user =>
            -1 === this.hiddenUsers.findIndex(hiddenUser => hiddenUser.username === user.username)
        );
    }

}
