import {RecoveryTime} from "./recovery-time";
import {FormType} from "./form-type";

export interface NumberQuestionFollowUp {
    id?: string;
    lessThan: number | null;
    greaterThan: number | null;
    title: string;
    formType: FormType;
    recoveryTime: RecoveryTime | null;
    actionTaker: string | null;
}

export enum NumberQuestionFollowUpType {
    LessThan,
    Between,
    GreaterThan
}

export const followUpChoices: { type: NumberQuestionFollowUpType, label: string }[] = [
    {
        type: NumberQuestionFollowUpType.LessThan,
        label: 'Antwoord kleiner dan (antwoord < max )'
    },
    {
        type: NumberQuestionFollowUpType.Between,
        label: 'Antwoord tussen ( min < antwoord < max )'
    },
    {
        type: NumberQuestionFollowUpType.GreaterThan,
        label: 'Antwoord groter dan ( min < antwoord )'
    }
]

export function determineFollowUpType(value: NumberQuestionFollowUp): NumberQuestionFollowUpType {
    if (value.lessThan !== null && value.greaterThan === null) {
        return NumberQuestionFollowUpType.LessThan
    } else if(value.lessThan === null && value.greaterThan !== null) {
        return NumberQuestionFollowUpType.GreaterThan
    }
    return NumberQuestionFollowUpType.Between
}
