import {Identifiable} from './identifiable';

export enum PaulaObjectTypeStatus {
    Active = 'Active',
    Concept = 'Concept',
    Cancelled = 'Cancelled'
}

export interface PaulaObjectType extends Identifiable {
    title: string;
    code: string;
    description: string;
    companies: string[];
    status: PaulaObjectTypeStatus;
}

export const paulaObjectTypeStatusTitles = {
    Active: 'Actief',
    Concept: 'Concept',
    Cancelled: 'Vervallen'
};
