<ng-container *ngFor="let item of questionSetItems; index as chapterIndex">
    <p *ngIf="showChapters"><strong>{{ getChapterNumber(item, chapterIndex + 1) }}. {{ item.title }}</strong>
        <span class="vwui-badge is-success" *ngIf="dependentQuestionPosition(item)">
            {{'Afhankelijk van'|translate}}: {{ dependentQuestionPosition(item) }}
        </span>
    </p>
    <div *ngFor="let question of item.children; index as questionIndex">
        <p>
            <span>{{ getChapterNumber(question, chapterIndex + 1) }}.{{ questionIndex + 1 }} {{ question.title }}</span>
            <span class="vwui-badge is-primary">{{ question.type | questionTypeTitle }}</span>
            <span *ngIf="question?.formulaFields?.label" class="vwui-badge is-primary">
                {{ question.formulaFields.label }}
            </span>
            <span class="vwui-badge" *ngIf="question.required">{{'Verplicht'|translate}}</span>
            <span class="vwui-badge is-success" *ngIf="dependentQuestionPosition(question)">
                {{'Afhankelijk van'|translate}}: {{ dependentQuestionPosition(question) }}
            </span>
            <span [innerHTML]="question.description" class="text-muted small child-paragraph-no-margin"></span>
        </p>
    </div>
</ng-container>
