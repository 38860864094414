<app-form-type-example [hidden]="!formExampleVisible"
    *ngIf="form.controls.formType.valid && form.controls.formType.dirty"
    [form]="form.controls.formType?.value">
</app-form-type-example>
<app-modal modalTitle="{{'Formulier toevoegen'|translate}}" modalSaveBtnLabel="{{'Toevoegen'|translate}}" (save)="cloneForm()"
           (cancel)="closeModal('cancelled')">
    <form [formGroup]="form" *ngIf="form" (submit)="cloneForm()">
        <div class="u-mb-m">
            <div [appFormControlError]="form.get('company')" class="vwui-form-group mb-4">
                <label for="formType" class="vwui-label">{{'Bedrijf (optioneel)'|translate}}</label>
                <app-company-select id="company" formControlName="company" [mustHaveForms]="true"></app-company-select>
            </div>
        </div>
        <div class="u-mb-m">
            <div [appFormControlError]="form.get('objectTypes')" class="vwui-form-group">
                <label for="objectTypes" class="vwui-label">{{'Objecttypes'|translate}}</label>
                <app-paula-object-type-select id="objectTypes" [multiple]="true"
                                              formControlName="objectTypes"></app-paula-object-type-select>
            </div>
        </div>
        <div class="u-mb-m">
            <div [appFormControlError]="form.get('formType')" class="vwui-form-group mb-4">
                <label for="formType" class="vwui-label">{{'Formulier'|translate}}</label>
                <app-form-type-select id="formType" formControlName="formType"
                                      [objectTypesFilter]="form.get('objectTypes').value"
                                      [companyFilter]="form.get('company').value"></app-form-type-select>
                <small class="badge badge-primary mt-1 vwui-cursor-pointer" *ngIf="form.controls.formType?.valid"
                       (click)="showFormExample()"
                >{{ 'Formulier bekijken'|translate }}
                </small>
            </div>
        </div>
        <div class="u-mb-m">
            <div [appFormControlError]="form.get('title')" class="vwui-form-group">
                <label class="vwui-label" for="title">{{'Formuliernaam'|translate}}</label>
                <input
                    class="vwui-input"
                    id="title"
                    name="title"
                    formControlName="title"
                    autocomplete="off"
                    type="text"
                    placeholder="{{'Formuliernaam'|translate}}">
            </div>
        </div>
        <div class="u-mb-m">
            <div [appFormControlError]="form.get('code')" class="vwui-form-group">
                <label class="vwui-label" for="code">{{'Formuliernummer'|translate}}</label>
                <input
                    class="vwui-input"
                    id="code"
                    name="code"
                    formControlName="code"
                    autocomplete="off"
                    type="text"
                    placeholder="{{'Formuliernummer'|translate}}">
            </div>
        </div>
        <div class="u-mb-m">
            <div [appFormControlError]="form.get('contactPerson')" class="vwui-form-group mb-4">
                <label for="contactPerson" class="vwui-label">{{ 'Contactpersoon'|translate }}</label>
                <app-user-select id="contactPerson" formControlName="contactPerson"></app-user-select>
            </div>
        </div>
        <div class="u-mb-m">
            <div [appFormControlError]="form.get('tags')" class="vwui-form-group mb-4">
                <label for="tags" class="vwui-label">{{'Tags'|translate}}</label>
                <app-tag-select id="tags" formControlName="tags" [multiple]="true"></app-tag-select>
            </div>
        </div>
        <div class="u-mb-m">
            <div [appFormControlError]="form.get('executionGroups')" class="vwui-form-group mb-4">
                <label for="executionGroups" class="vwui-label">{{'Disciplines(optioneel)'|translate}}</label>
                <app-execution-group-select id="executionGroups"
                                            formControlName="executionGroups"></app-execution-group-select>
            </div>
        </div>
        <div class="u-mb-m">
            <div [appFormControlError]="form.get('projectExecutionGroups')" class="vwui-form-group mb-4">
                <label for="projectExecutionGroups" class="vwui-label">{{'Projectdisciplines(optioneel)'|translate}}</label>
                <app-project-execution-group-select [projectId]="projectId"
                                                    id="projectExecutionGroups"
                                                    formControlName="projectExecutionGroups">
                </app-project-execution-group-select>
            </div>
        </div>
        <button type="submit" class="d-none"><!-- Needed to submit with enter --></button>
    </form>
</app-modal>
