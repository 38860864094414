// @ts-strict-ignore
import {Component, forwardRef, Inject, Input} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';
import {ItemSelectComponent} from './item-select.component';
import {createPageResponse, PageResponse} from '../../models/page-response';
import {GraphService} from "../../services/graph.service";
import {filter, map} from "rxjs/operators";
import {GraphUser} from "../../models/graph-user";

@Component({
    selector: 'app-graph-user-select',
    templateUrl: './item-select.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => GraphUserSelectComponent),
            multi: true
        },
    ]
})
export class GraphUserSelectComponent extends ItemSelectComponent<GraphUser, {graphId: number}> {
    private filter = new BehaviorSubject<{graphId: number}>(null)

    @Input()
    set graphId(graphId: number) {
        this.filter.next(graphId === null ? null : {graphId})
    }

    constructor(
        @Inject('GraphService') private graphService: GraphService,
    ) {
        super();
    }

    loadMore(page: number, filter: {term: string, graphId: number}): Observable<PageResponse<GraphUser>> {
        return this.graphService.getUsers(filter.graphId, filter.term).pipe(
            map(users => createPageResponse(users))
        );
    }

    getLabelText(item: GraphUser) {
        return item.displayName + ' - ' + item.mail;
    }

    getFilter(): Observable<{graphId: number}> {
        return this.filter.pipe(
            filter(val => val !== null)
        )
    }


    compare(a: GraphUser, b: GraphUser): boolean {
        return a.username === b.username
    }
}
