<div class="vwui-form-group u-mb-m">
    <label class="vwui-label">{{'Vervolgopdracht'|translate}}</label>
    <div>
        {{'Koppel een vervolgopdracht op basis van getal'|translate}}
    </div>
</div>
<div class="d-flex flex-column vwui-card p-3 mb-4" *ngFor="let item of followUps">
    <div class="d-flex justify-content-between">
        <h6>{{ followUpLabel(item) }}</h6>
        <div class="mb-1 vwui-btn-grp">
            <button class="vwui-btn-no-bg" type="button" (click)="updateFollowUp(item)">
                <app-icon iconName="edit-2"></app-icon>
            </button>
            <button id="delete" class="vwui-btn-no-bg" (click)="deleteFollowUp(item)">
                <app-icon iconName="trash-2"></app-icon>
            </button>
        </div>
    </div>
    <span>{{ 'Opdrachtnaam'|translate}}: {{ item.title }}</span>
    <span>{{ 'Formulier'|translate}}: {{ item.formType?.title }}</span>
    <span *ngIf="item.recoveryTime; let recoveryTime">
        {{ 'Hersteltermijn'|translate}}: {{ $any(recoveryTimes)[recoveryTime] }}
    </span>
    <ng-container *appHasFeatureToggle="maximoToggle">
        <span *ngIf="item.actionTaker">
        {{'Actienemer'|translate}}: {{ item.actionTaker }}
    </span>
    </ng-container>
</div>
<div class="d-flex">
    <ng-select
        [formControl]="followUpChoiceControl"
        bindLabel="label"
        bindValue="type"
        id="select_a_option"
        placeholder="{{ 'Kies een keuze'|translate }}"
        class="flex-grow-1"
        [items]="followUpChoices"
        [clearable]="false">
    </ng-select>
    <button class="vwui-btn ml-3"
            type="button" [disabled]="followUpChoiceControl.value === null" (click)="configureNumberQuestionFollowup(followUpChoiceControl.value)">
        {{'Koppelen'|translate}}
    </button>
</div>

