import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: 'questionTypeLocation',
})
export class QuestionTypeLocationPipe implements PipeTransform {
    transform(value: string, type: 'from'|'to'): string {
        try {
            const isFrom = type !== 'to';

            if (value) {
                const {
                    gpsLatitudeFrom,
                    gpsLatitudeTo,
                    gpsLongitudeFrom,
                    gpsLongitudeTo
                } = JSON.parse(value)

                const gpsFrom = gpsLatitudeFrom && gpsLongitudeFrom ? `${gpsLatitudeFrom}, ${gpsLongitudeFrom}` : '';
                const gpsTo = gpsLatitudeTo && gpsLongitudeTo ? `${gpsLatitudeTo}, ${gpsLongitudeTo}` : '';

                return isFrom ? gpsFrom : gpsTo;
            }
        } catch (e) {
            console.warn('Unable to decode location', e);
        }

        return '';
    };
}
