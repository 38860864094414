// @ts-strict-ignore
import {Component, Inject, OnInit} from '@angular/core';
import {Forms} from '../../utils/forms';
import {FollowUpProjectJobTemplate} from '../../models/form-item';
import {recoveryTimeSelectOptions} from '../../models/recovery-time';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ProjectJobService} from '../../services/project-job.service';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Project} from '../../models/project';
import {FeatureToggle} from '../../models/feature-toggle';
import {TranslateService} from '@ngx-translate/core';
import {actionTakers} from "../../models/action-taker";
import {ModalResult} from "../../models/modal.result";

@Component({
    selector: 'app-follow-up-project-job-modal',
    templateUrl: './follow-up-project-job-modal.component.html'
})
export class FollowUpProjectJobModalComponent implements OnInit {
    form: UntypedFormGroup;
    project: Project;
    initialForm?: FollowUpProjectJobTemplate;
    onClose: (result: ModalResult, data: FollowUpProjectJobTemplate) => void;

    readonly recoveryTimes = recoveryTimeSelectOptions;
    readonly maximoToggle = FeatureToggle.Maximo;
    readonly actionTakers = actionTakers;

    constructor(
        @Inject('ProjectJobService') private projectJobService: ProjectJobService,
        protected bsModalRef: BsModalRef,
        private fb: UntypedFormBuilder,
        private translateService: TranslateService,
    ) {
    }

    ngOnInit() {
        this.createForm(this.fb);
        // Pre-fill title field
        this.form.controls.projectForm.valueChanges.subscribe(val => {
            if (val) {
                this.form.controls.title.patchValue(this.form.controls.projectForm.value.title);
            }
        });
    }

    submit() {
        if (!this.form.valid) {
            Forms.updateValueAndValidityRecursive(this.form);
            return;
        }

        this.closeModal('confirmed', {
            ...this.form.value
        });
    }

    closeModal(result: ModalResult, data: any = null) {
        this.onClose(result, data);
        this.bsModalRef.hide();
    }

    createForm(fb: UntypedFormBuilder) {
        this.form = fb.group({
            title: fb.control(null, [Validators.required, Validators.maxLength(255)]),
            projectForm: fb.control(null, [Validators.required]),
            recoveryTime: fb.control(null),
            actionTaker: fb.control(null)
        });

        if (this.initialForm) {
            this.form.patchValue(this.initialForm);
        }
    }

    getSaveButtonText() {
        const translationKey = this.initialForm ? 'Aanpassen' : 'Koppelen';
        return this.translateService.instant(translationKey);
    }
}
