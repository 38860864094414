<div
    class="vwui-card__header"
    [class.has-action]="isChapter"
    [class.is-selected]="isSelected"
    (click)="onEdit($event)"
>
    <div>
        <div>{{ chapterNumber }}. {{ title }}
            <span class="vwui-badge is-success" *ngIf="dependentQuestionPosition">
                {{'Afhankelijk van'|translate}}: {{ dependentQuestionPosition }}
            </span>
        </div>
        <div *ngIf="subtitle">
            <span class="vwui-badge is-primary">{{ subtitle }}</span>
        </div>
    </div>

    <app-action-menu #actionMenu *ngIf="isChapter">
        <button class="vwui-menu-item" (click)="onEdit($event)">
            <app-icon iconName="edit-2"></app-icon>
            {{'Bewerken'|translate}}
        </button>
        <button class="vwui-menu-item" (click)="onDuplicate($event)">
            <app-icon iconName="duplicate"></app-icon>
            {{'Kopieren'|translate}}
        </button>
        <button class="vwui-menu-item is-danger" (click)="onRemove($event)">
            <app-icon iconName="trash-2"></app-icon>
            {{'Verwijderen'|translate}}
        </button>
    </app-action-menu>

    <app-action-menu #actionMenu *ngIf="!isChapter">
        <button class="vwui-menu-item is-danger" (click)="onRemove($event)">
            <app-icon iconName="trash-2"></app-icon>
            {{'Vragenset verwijderen'|translate}}
        </button>
    </app-action-menu>
</div>
