<div class="app-login-overlay">
    <div class="app-login-overlay__container">
        <div class="vwui-card content-centered">
            <div class="vwui-card__header">
                {{'iOS code wordt geactiveerd'| translate}}
            </div>
            <div class="vwui-card__content">
                <div *ngIf="!message; else showMessage" class="vwui-spinner vwui-spinner--large"></div>
            </div>
        </div>
    </div>
</div>

<ng-template #showMessage>
    <p>{{message}}</p>
</ng-template>
