// @ts-strict-ignore
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {map, shareReplay, startWith, switchMap} from 'rxjs/operators';
import {FeatureToggle, FeatureToggleData} from '../models/feature-toggle';

@Injectable()
export class FeatureToggleCheckService {

    private _featureTogglesChanged = new Subject<void>();
    private featureToggles$ = this._featureTogglesChanged.asObservable().pipe(
        startWith<void, null>(null),
        switchMap(() => this.httpClient.get<Array<FeatureToggleData>>('/api/v1/feature-toggles'))
    ).pipe(shareReplay(1));

    static isEnabled(featureToggles: Array<FeatureToggleData>, toggleToCheck: FeatureToggle) {
        const matchingToggle = featureToggles.find(toggle => toggle.featureToggleName === toggleToCheck);
        return matchingToggle && matchingToggle.enabled;
    }

    static isEnabledForName(featureToggles: FeatureToggleData[], featureToggleName: string) {
        const matchingToggle = featureToggles.find(toggle => toggle.featureToggleName === featureToggleName);
        return matchingToggle && matchingToggle.enabled;
    }

    constructor(private httpClient: HttpClient) {
    }

    featureToggles() {
        return this.featureToggles$;
    }

    featureToggle(toggleToCheck: FeatureToggle): Observable<boolean> {
        return this.featureToggles$.pipe(
            map(featureToggles => FeatureToggleCheckService.isEnabled(featureToggles, toggleToCheck))
        );
    }

}
