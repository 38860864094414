<app-toolbar
    [showAddBtn]="true"
    [addBtnRoute]="'/beheer/projects/' + project.id + '/execution-groups/new'"
    addBtnLabel="Nieuwe projectdiscipline">
</app-toolbar>
<div class="vwui-container">
    <app-table *ngIf="paginator$|async;let paginator" [paginator]="paginator" [headerTitles]="headerTitles" (filterChanged)="onTableFilterChange($event)">
        <ng-template let-item>
            <tr [routerLink]="['/beheer/projects', project.id, 'execution-groups', item.id]">
                <td>{{ item.title }}</td>
            </tr>
        </ng-template>
    </app-table>
</div>
