// @ts-strict-ignore
import {Component} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {UntypedFormArray, UntypedFormControl} from '@angular/forms';
import {MapLayerData} from '../../models/maplayerdata';
import {ModalResult} from "../../models/modal.result";


@Component({
    selector: 'app-project-map-layer-properties-modal',
    templateUrl: './project-map-layer-properties-modal.component.html',
})
export class ProjectMapLayerPropertiesModalComponent {
    destinationControl: UntypedFormControl = null;

    onClose: (result: ModalResult, destination: MapLayerData) => void;

    constructor(
        protected bsModalRef: BsModalRef,
    ) {
    }

    get mapLayerDataPropertyControls() {
        return (this.destinationControl.get('objectModalProperties') as UntypedFormArray).controls as UntypedFormControl[];
    }

    closeModal(result: ModalResult, data: any = null) {
        this.onClose(result, data);
        this.bsModalRef.hide();
    }

    submit() {
        if (!this.destinationControl.valid) {
            this.destinationControl.updateValueAndValidity();
            return;
        }

        this.closeModal('confirmed', this.destinationControl.value);
    }

}
