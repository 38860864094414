import {HttpClient, HttpParams} from '@angular/common/http';
import {PageResponse} from '../models/page-response';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {QuestionSet} from '../models/question-set';
import {TableFilter} from '../components/table/table.component';
import {Filters} from '../utils/filters';

export interface QuestionSetService {
    getQuestionSets(page: number, filter?: TableFilter): Observable<PageResponse<QuestionSet>>;
    getQuestionSet(id: number): Observable<QuestionSet>;
    putQuestionSet(questionSet: QuestionSet): Observable<QuestionSet>;
    postQuestionSet(questionSet: QuestionSet): Observable<QuestionSet>;
    exists(title: string): Observable<number>;
}

@Injectable()
export class QuestionSetServiceImpl implements QuestionSetService {
    constructor(private httpClient: HttpClient) {
    }

    getQuestionSets(page: number, filter?: TableFilter): Observable<PageResponse<QuestionSet>> {
        let params = new HttpParams().set('page', '' + page);

        if (filter) {
            params = Filters.applyFiltering(filter, params);
        }

        return this.httpClient.get<PageResponse<QuestionSet>>('/api/v1/question-sets', {params});
    }

    getQuestionSet(id: number) {
        return this.httpClient.get<QuestionSet>(`api/v1/question-sets/${id}`);
    }

    putQuestionSet(questionSet: QuestionSet) {
        return this.httpClient.put<QuestionSet>(`api/v1/question-sets/${questionSet.id}`, questionSet);
    }

    postQuestionSet(questionSet: QuestionSet) {
        return this.httpClient.post<QuestionSet>('api/v1/question-sets', questionSet);
    }

    exists(title: string) {
        const params = new HttpParams().set('title', title);

        return this.httpClient.get<number>(`api/v1/question-sets/exists`, {params});
    }
}
