<app-toolbar
    [showAddBtn]="true"
    addBtnLabel="{{'Formulier toevoegen'|translate}}"
    (add)="openAddFormModal()">
    <button class="vwui-btn has-icon" (click)="openAddMultipleFormsModal()">
        <app-icon iconName="plus"></app-icon>
        {{'Meerdere formulieren toevoegen'|translate}}
    </button>
</app-toolbar>
<div class="vwui-container is-full" *ngIf="project">
    <app-multi-select-table #table *ngIf="paginator$|async;let paginator"
                            [paginator]="paginator"
                            [headerTitles]="tableHeaders"
                            [enableContextMenu]="true"
                            [enableMultiSelect]="false"
                            (filterChanged)="onTableFilterChange($event)"
                            (itemClick)="onFormClick($event)">
        <ng-template #contextMenuHeader></ng-template>
        <ng-template #actions></ng-template>
        <ng-template #row let-item>
            <td>{{ item.title }}</td>
            <td>{{ item.code }}</td>
            <td>{{ item.paulaObjectTypes | arrayPropertyValues:'title' }}</td>
            <td>
                <ng-container *ngFor="let group of item?.executionGroups">
                    {{ group.title }}
                </ng-container>
                <ng-container *ngFor="let group of item?.projectExecutionGroups">
                    {{ group.title }}
                </ng-container>
            </td>
            <td>{{ item.contactPerson }}</td>
            <td>
                {{ item.updatedAt | dateFormat }}
                <small class="text-muted">{{ item.updatedBy }}</small>
            </td>
            <td>
                <ng-container *ngFor="let tag of item.tags">
                    <app-tag [className]="tag.className">{{tag.name}}</app-tag>
                </ng-container>
            </td>
            <td class="vwui-table__fixed-column-width vwui-multi-select-table--unclickable">
                <div class="vwui-table__actions">
                    <app-action-menu #actionMenu>
                        <button type="button" class="vwui-menu-item is-danger" (click)="transitionTo($event, item, 'Archived')">
                            <app-icon iconName="trash-2"></app-icon>
                            {{'Vervallen'|translate}}
                        </button>
                        <button type="button" class="vwui-menu-item" (click)="downloadProjectForm(item)">
                            <app-icon iconName="pdf"></app-icon>
                            {{ 'Formulier exporteren naar PDF'|translate }}
                        </button>
                    </app-action-menu>
                </div>
            </td>
        </ng-template>
    </app-multi-select-table>
</div>
