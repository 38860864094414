// @ts-strict-ignore
import {WorkerGroup} from '../../models/worker-group';
import {AbstractCrudServiceMock} from './abstract-crud.service.mock';
import {WorkerGroupService} from '../worker-group.service';
import {Observable} from 'rxjs';
import {TableFilter} from '../../components/table/table.component';
import {PageResponse} from '../../models/page-response';
import {Injectable} from '@angular/core';

export const mockWorkergroup: Array<WorkerGroup> = [
    {
        id: 1,
        name: 'Team Rood Vleugel A',
        workerGroupUsers: [],
        projectCount: 0
    }
];
@Injectable()
export class WorkerGroupServiceMock extends AbstractCrudServiceMock<WorkerGroup> implements WorkerGroupService {

    constructor() {
        super(mockWorkergroup);
    }

    deleteWorkerGroup(id: number): Observable<WorkerGroup> {
        const group: WorkerGroup = null;

        this.getDetail(id).subscribe((workerGroup: WorkerGroup) => {
            Object.assign(group, workerGroup);
        });

        return this.delete(group);
    }

    getDetail(workergroupId: number): Observable<WorkerGroup> {
        return this.get(workergroupId);
    }

    getList(page: number, filter?: TableFilter): Observable<PageResponse<WorkerGroup>> {
        return this.getAll();
    }

    postWorkerGroup(workergroup: WorkerGroup): Observable<WorkerGroup> {
        return this.post(workergroup);
    }

    putWorkerGroup(workergroup: WorkerGroup): Observable<WorkerGroup> {
        return this.put(workergroup);
    }

    getWorkerGroup(id: number): Observable<WorkerGroup> {
        return this.get(id);
    }

    getWorkerGroups(search: string): Observable<PageResponse<WorkerGroup>> {
        return this.getAll();
    }
}
