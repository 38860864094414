// @ts-strict-ignore
import {Component, forwardRef, Inject, Injector, OnInit} from '@angular/core';
import {PictureUtil} from "../../utils/picture";
import {ToastrService} from "ngx-toastr";
import {v4 as uuid} from 'uuid';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl} from "@angular/forms";
import {UploadService} from "../../services/upload.service";
import {BehaviorSubject, firstValueFrom} from "rxjs";

@Component({
    selector: 'app-multi-image-upload',
    templateUrl: './multi-image-upload.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MultiImageUploadComponent),
            multi: true
        }
    ],
})
export class MultiImageUploadComponent implements OnInit, ControlValueAccessor {
    readonly MAX_IMAGES = 5;
    readonly imagesSubject = new BehaviorSubject<string[]>([]);
    readonly uploadingSubject = new BehaviorSubject<boolean>(false);
    readonly disabledSubject = new BehaviorSubject<boolean>(false);

    onTouched = () => {};
    onChange = (newValue: string) => {};

    control: NgControl | null = null;

    constructor(
        private toast: ToastrService,
        @Inject('UploadService') private uploadService: UploadService,
        private injector: Injector
    ) {
    }

    ngOnInit() {
        this.control = this.injector.get(NgControl, null);
    }

    writeValue(value: string): void {
        this.imagesSubject.next(value ? value.split(',') : []);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabledSubject.next(isDisabled);
    }

    async addPhoto(input: HTMLInputElement) {
        if (this.disabledSubject.value || this.uploadingSubject.value) {
            return
        }

        try {
            this.uploadingSubject.next(true)

            const maxAddCount = Math.min(this.MAX_IMAGES - this.imagesSubject.value.length, input.files.length)
            for (let i = 0; i < maxAddCount; i++) {
                const resized = await PictureUtil.resizeBlob(input.files[i]);
                const uniqueId = uuid();

                await firstValueFrom(this.uploadService.uploadImage(uniqueId, resized));

                this.imagesSubject.next([...this.imagesSubject.value, uniqueId])

                this.onTouched();
                this.onChange(this.imagesSubject.value.toString());
            }
        } catch (e) {
            console.error('File upload failed')
        } finally {
            input.value = '';
            this.uploadingSubject.next(false)
        }
    }

    async removePhoto(uuid: string) {
        if (this.disabledSubject.value) {
            return
        }

        this.imagesSubject.next(this.imagesSubject.value.filter(id => id !== uuid))

        this.onTouched();
        this.onChange(this.imagesSubject.value.toString());
    }
}
