import {AuthCheckService} from '../auth-check.service';
import {Observable, of} from 'rxjs';
import {Authority} from '../../models/authority';
import {UserRoles} from '../../models/user';
import {Injectable} from '@angular/core';

@Injectable()
export class AuthCheckServiceMock extends AuthCheckService {
    checkAuthorized(): Promise<Object> {
        return Promise.resolve(true);
    }

    authorities(): Observable<Array<Authority>> {
        return of([
            {authority: UserRoles.RoleApplicationAdmin},
            {authority: UserRoles.RoleMasterDataAdmin},
            {authority: UserRoles.RoleCoordinator}
        ]);
    }
}
