<app-toolbar
    [showAddBtn]="true"
    [addBtnRoute]="'/beheer/obstruction-codes/new'"
    addBtnLabel="{{'Nieuw obstructiecode'|translate}}">
</app-toolbar>
<app-table #table *ngIf="paginator$|async;let paginator"
    [paginator]="paginator"
    [headerTitles]="tableHeaders"
    (filterChanged)="onTableFilterChange($event)">
    <ng-template let-item>
        <tr [routerLink]="['/beheer/obstruction-codes/', item.id]">
            <td>{{ item.code }}</td>
            <td>{{ item.name }}</td>
        </tr>
    </ng-template>
</app-table>
