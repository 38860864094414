// @ts-strict-ignore
import {Component, forwardRef, Inject, Input, OnChanges, SimpleChanges} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';
import {EntitySelectComponent} from './item-select.component';
import {PageResponse} from '../../models/page-response';
import {FormTypeService} from '../../services/form-type.service';
import {FormType} from '../../models/form-type';
import {PaulaObjectType} from '../../models/paula-object-type';
import {Company} from '../../models/company';

@Component({
    selector: 'app-form-type-select',
    templateUrl: './form-type-select.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FormTypeSelectComponent),
            multi: true
        },
    ]
})
export class FormTypeSelectComponent extends EntitySelectComponent
    <FormType, {objectTypes?: PaulaObjectType[], company?: Company}> implements OnChanges {

    @Input() objectTypesFilter: PaulaObjectType[];
    @Input() companyFilter: Company;
    filter: BehaviorSubject<{objectTypes?: PaulaObjectType[], company?: Company}>;

    constructor(
        @Inject('FormTypeService') private formTypeService: FormTypeService,
    ) {
        super();

        this.placeholder = 'Selecteer een formulier uit de bibliotheek';
    }

    getFilter(): Observable<{ objectTypes?: PaulaObjectType[], company?: Company }> {
        if (!this.filter) {
            this.filter = new BehaviorSubject({objectTypes: this.objectTypesFilter, company: this.companyFilter});
        }

        return this.filter;
    }

    loadMore(page: number, filter: {objectTypes: PaulaObjectType[], company: Company, term: string}): Observable<PageResponse<FormType>> {
        return this.formTypeService.getList(page, {
            filter: {
                status: 'vrijgegeven',
                'paulaObjectTypes.title': filter && filter.objectTypes ? filter.objectTypes.map(it => it.title).join(',') : '',
                'companies.name': filter && filter.company ? filter.company.name : '',
                'tags.name-title-code': filter && filter.term ? filter.term : '',
            }
        });
    }

    getLabelText(item: FormType) {
        // This method is not used, custom label templates are being used
        return '';
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.objectTypesFilter) {
            this.filter?.next({...this.filter.value, objectTypes: changes.objectTypesFilter.currentValue});
        }
        if (changes.companyFilter) {
            this.filter?.next({...this.filter.value, company: changes.companyFilter.currentValue});
        }
    }
}
