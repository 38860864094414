// @ts-strict-ignore
import {Component, Input, OnDestroy} from '@angular/core';
import {Choice, FollowUpFormTemplate} from '../../models/form-item';
import {UntypedFormControl} from '@angular/forms';
import {BsModalService} from 'ngx-bootstrap/modal';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs';
import {recoveryTimes} from '../../models/recovery-time';
import {FeatureToggle} from '../../models/feature-toggle';
import {FollowUpFormModalComponent} from '../follow-up-form-modal/follow-up-form-modal.component';
import {FormType} from '../../models/form-type';
import {ModalResult} from "../../models/modal.result";

@Component({
    selector: 'app-follow-up-forms',
    templateUrl: './question-follow-up-forms.component.html'
})
export class QuestionFollowUpFormsComponent implements OnDestroy {

    public _choicesControl: UntypedFormControl;
    public _multipleControl: UntypedFormControl;
    public _followUpForm: FormType;

    private choiceControlSubscription: Subscription;
    private multipleControlSubscription: Subscription;

    @Input()
    set choicesControl(choiceControl: UntypedFormControl) {
        this._choicesControl = choiceControl;
        this.updateChoicesWithoutFollowUpFormTemplate(this._choicesControl.value);

        if (this.choiceControlSubscription) {
            this.choiceControlSubscription.unsubscribe();
        }
        this.choiceControlSubscription = choiceControl.valueChanges.subscribe(
            choices => this.updateChoicesWithoutFollowUpFormTemplate(choices)
        );
    }

    @Input()
    set multipleControl(multipleControl: UntypedFormControl) {
        this._multipleControl = multipleControl;

        if (this.multipleControlSubscription) {
            this.multipleControlSubscription.unsubscribe();
        }
        this.multipleControlSubscription = this._multipleControl.valueChanges.subscribe(
            (multiple) => {
                if (multiple) {
                    this.removeFollowUpFormTemplateFromChoices();
                }
            }
        );
    }

    @Input()
    set followUpForm(followUpForm: FormType) {
        this._followUpForm = followUpForm;
    }

    followUpChoiceControl = new UntypedFormControl(null);
    choicesWithoutFollowUpProjectJobTemplate: Choice[];
    recoveryTimes = recoveryTimes;

    maximoToggle = FeatureToggle.Maximo;

    constructor(
        private modalService: BsModalService,
        private toast: ToastrService
    ) {
    }

    ngOnDestroy(): void {
        if (this.choiceControlSubscription) {
            this.choiceControlSubscription.unsubscribe();
        }
        if (this.multipleControlSubscription) {
            this.multipleControlSubscription.unsubscribe();
        }
    }

    configureFollowUpFormForChoice(choiceId: string) {
        this.modalService.show<any>(FollowUpFormModalComponent, {
            class: 'modal-dialog-centered',
            initialState: {
                onClose: (result: ModalResult, template: FollowUpFormTemplate) => {
                    if (result === 'confirmed' && template) {
                        this.addFollowUpFormTemplateToChoice(choiceId, template);
                    }

                    if (result === 'error') {
                        this.toast.error('Koppelen niet gelukt');
                    }
                }
            }
        });
    }

    deleteFollowUpFormForChoice(choiceId: string) {
        const choices = this._choicesControl.value as Choice[];

        const selectedChoice = choices.find(it => it.id === choiceId);
        selectedChoice.followUpFormTemplate = null;

        this._choicesControl.setValue(choices);
    }

    updateFollowUpFormForChoice(selectedChoice: Choice, event: MouseEvent) {
        event.stopPropagation();
        event.preventDefault();

        this.modalService.show<any>(FollowUpFormModalComponent, {
            class: 'modal-dialog-centered',
            initialState: {
                initialForm: selectedChoice.followUpFormTemplate,
                onClose: (result: ModalResult, template: FollowUpFormTemplate) => {
                    if (result === 'confirmed' && template) {
                        this.addFollowUpFormTemplateToChoice(selectedChoice.id, template);
                    }

                    if (result === 'error') {
                        this.toast.error('Aanpassen niet gelukt');
                    }
                }
            }
        });
    }

    private removeFollowUpFormTemplateFromChoices() {
        this._choicesControl.setValue(
            (this._choicesControl.value as Choice[]).map(choice => {
                choice.followUpFormTemplate = null;
                return choice;
            })
        );
    }

    private addFollowUpFormTemplateToChoice(choiceId: string, template: FollowUpFormTemplate) {
        const choices = this._choicesControl.value as Choice[];

        const selectedChoice = choices.find(it => it.id === choiceId);
        selectedChoice.followUpFormTemplate = template;

        this._choicesControl.setValue(choices);
    }

    private updateChoicesWithoutFollowUpFormTemplate(choices: Choice[]) {
        this.followUpChoiceControl.setValue('');
        this.choicesWithoutFollowUpProjectJobTemplate = choices.filter(it => !it.followUpFormTemplate);
    }
}
