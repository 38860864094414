// @ts-strict-ignore
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors} from '@angular/forms';
import {Object} from '../../models/object';
import {Observable, Subject, Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {ObjectService} from '../../services/object.service';
import {ToastrService} from 'ngx-toastr';
import {Subscriptions} from '../../utils/subscriptions';
import {objectDescriptionFormControls, objectFormControls, objectLocationTypes} from './object-form-controls';
import {Forms} from '../../utils/forms';
import {Project} from '../../models/project';
import {HistoryService} from "../../services/history.service";
import {PaulaObjectType} from '../../models/paula-object-type';

@Component({
    selector: 'app-object-detail',
    templateUrl: './object-detail.component.html',
})
export class ObjectDetailComponent implements OnInit, OnDestroy {
    form: UntypedFormGroup;
    object: Object;
    project: Project;
    cancel$ = new Subject<void>();

    formControls = objectFormControls;
    locationTypes = objectLocationTypes;
    descriptionFormControls = objectDescriptionFormControls;

    private subscriptions: Subscription[] = [];

    constructor(
        private route: ActivatedRoute,
        @Inject('ObjectService') private objectService: ObjectService,
        private toast: ToastrService,
        private router: Router,
        fb: UntypedFormBuilder,
        private historyService: HistoryService,
    ) {
        this.form = this.createForm(fb);
    }

    ngOnInit(): void {
        this.subscriptions.push(this.route.data.subscribe((data: {object: Object, project: Project}) => {
            if (data.object) {
                this.object = data.object;
                this.form.patchValue(this.object);
            }

            if (data.project) {
                this.project = data.project;
            }
        }));
        this.subscriptions.push(this.route.paramMap.subscribe((paramMap) => {
            this.form.patchValue({project: +paramMap.get('project')});
        }));
    }

    ngOnDestroy(): void {
        this.form.reset();
        Subscriptions.unsubscribeAll(this.subscriptions);
    }

    get objectTitle() {
        return this.object ? this.object.objectOmschrijvingKort : 'Nieuw object';
    }

    async save() {
        if (!this.form.valid) {
            Forms.updateValueAndValidityRecursive(this.form);
            return;
        }

        const newObject = this.form.value as Object;

        try {
            let result;

            if (newObject.id) {
                result = await this.objectService.putObject((newObject.project as any).id, newObject).toPromise();
            } else {
                result = await this.objectService.postObject((newObject.project as any).id, newObject).toPromise();
            }
            this.toast.success('Opgeslagen');
            this.form.patchValue(result);

            this.goBack();
        } catch (ex) {
            console.error('Unable to save object', ex);
            this.toast.error('Opslaan mislukt');
        }
    }

    cancel() {
        this.cancel$.next();
    }

    goBack() {
        this.historyService.goBack(['/beheer/projects', this.form.value.project], {queryParams: {tab: 'objects'}});
    }

    validateObjectTypeNotTaken(control: AbstractControl): Observable<ValidationErrors> {
        const projectId = +this.form.get('project').value;
        const currentId = +this.form.get('id').value;
        const objectId = this.form.get('objectId').value;

        return Forms.validateNotTaken(control, currentId, this.objectService.exists(projectId, objectId));
    }

    private createForm(fb: UntypedFormBuilder): UntypedFormGroup {
        const group = fb.group({
            id: fb.control(null),
            project: fb.control(null)

        });

        for (const formControl of this.formControls) {
            group.addControl(formControl.name, formControl.control);
        }

        for (const formControl of this.descriptionFormControls) {
            group.addControl(formControl.name, formControl.control);
        }

        const objectIdControl = group.get('objectId');
        objectIdControl.setAsyncValidators(this.validateObjectTypeNotTaken.bind(this));

        return group;
    }
}
