// @ts-strict-ignore
import {Component, Inject, Input} from '@angular/core';
import {Project} from '../../models/project';
import {AbstractListComponent} from '../../pages/abstract-list.component';
import {HeaderTitle, TableFilter} from '../table/table.component';
import {ProjectExecutionGroupService} from '../../services/project-execution-group.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ProjectExecutionGroup} from '../../models/project-execution-group';
import {Observable} from 'rxjs';
import {PageResponse} from '../../models/page-response';

@Component({
    selector: 'app-project-execution-group-tab',
    templateUrl: './project-execution-group-tab.component.html'
})
export class ProjectExecutionGroupTabComponent extends AbstractListComponent<ProjectExecutionGroup> {
    @Input() project: Project;

    headerTitles: HeaderTitle[] = [
        {sortKey: 'title', filterKey: 'title', title: 'Projectdisciplinenaam'},
    ];

    constructor(
        @Inject('ProjectExecutionGroupService') private projectExecutionGroupService: ProjectExecutionGroupService,
        protected activatedRoute: ActivatedRoute,
        protected router: Router,
    ) {
        super(activatedRoute, router);
    }

    loadData(page: number, filter: TableFilter, search?: string): Observable<PageResponse<ProjectExecutionGroup>> {
        return this.projectExecutionGroupService.getProjectExecutionGroups(this.project.id, page, filter)
    }
}
