<label class="vwui-input-control" [ngClass]="checkboxClass">
    <input
        class="vwui-input"
        [disabled]="disabled"
        [type]="type"
        [name]="name"
        [value]="value"
        [checked]="checked"
        (change)="onChange($event)"
    >
    <span>
        <ng-content></ng-content>
    </span>
</label>
