<ng-container *ngIf="(coordinator$|async) as coordinators">
    <div class="vwui-container is-full">
        <div class="vwui-grid">
            <div class="vwui-grid__column is-fixed">
                <app-user-select-card (userSelected)="addUser($event)" [filter]="userFilter$|async"
                                      [hiddenUsers]="project.coordinators"></app-user-select-card>
            </div>
            <div class="vwui-grid__column">
                <div class="u-mb-l" *ngIf="coordinators.length > 0; else empty">
                    <app-users-table title="Coördinatoren" [total]="coordinators.length">
                        <thead>
                        <tr>
                            <th>{{'Medewerker'|translate}}</th>
                            <th>{{'Werkgever'|translate}}</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let user of sortByDisplayName(coordinators)">
                            <td>
                                <del *ngIf="user?.roles?.indexOf('ROLE_COORDINATOR') === -1; else activeCoordinator" tooltip="Inactieve gebruiker, deze gebruiker kan niet inloggen als coördinator.">
                                    {{ user.displayName }}<br>
                                    {{ user.username }}
                                </del>
                                <ng-template #activeCoordinator>
                                    {{ user.displayName }}<br>
                                    {{ user.username }}
                                </ng-template>
                            </td>
                            <td>{{ user?.company?.name }}</td>
                            <td>
                                <ng-container *ngIf="user.username !== (loggedInEmail$ | async)">
                                    <div class="vwui-table__actions">
                                        <app-action-menu>
                                            <button class="vwui-menu-item is-danger"
                                                    (click)="removeUser(user)">
                                                <app-icon iconName="trash-2"></app-icon>
                                                {{'Verwijderen'|translate}}
                                            </button>
                                        </app-action-menu>
                                    </div>
                                </ng-container>
                            </td>
                        </tr>
                        </tbody>
                    </app-users-table>
                </div>
                <ng-template #empty>
                    <app-users-empty-card title="Nog geen coördinatoren toegevoegd">
                        {{'Selecteer een coördinator om deze toegang te verlenen tot het project'|translate}}
                    </app-users-empty-card>
                </ng-template>
            </div>
        </div>
    </div>
</ng-container>
