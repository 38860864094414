import {Observable} from 'rxjs';
import {PageResponse} from '../models/page-response';
import {TableFilter} from '../components/table/table.component';
import {WorkerGroup} from '../models/worker-group';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Filters} from '../utils/filters';

export interface WorkerGroupService {
    getList(page: number, filter?: TableFilter): Observable<PageResponse<WorkerGroup>>;
    getDetail(id: number): Observable<WorkerGroup>;
    postWorkerGroup(workerGroup: WorkerGroup): Observable<WorkerGroup>;
    putWorkerGroup(workerGroup: WorkerGroup): Observable<WorkerGroup>;
    deleteWorkerGroup(id: number): Observable<WorkerGroup>;
    getWorkerGroup(id: number): Observable<WorkerGroup>;
    getWorkerGroups(search: string): Observable<PageResponse<WorkerGroup>>;
}

@Injectable()
export class WorkerGroupServiceImpl implements WorkerGroupService {

    constructor(private httpClient: HttpClient) {
    }

    deleteWorkerGroup(id: number): Observable<WorkerGroup> {
        return this.httpClient.delete<WorkerGroup>(`/api/v1/worker-groups/delete/${id}`);
    }

    getDetail(id: number): Observable<WorkerGroup> {
        return this.httpClient.get<WorkerGroup>(`/api/v1/worker-groups/${id}`);
    }

    getList(page: number, filter?: TableFilter): Observable<PageResponse<WorkerGroup>> {
        let params = new HttpParams().set('page', '' + page);

        if (filter) {
            params = Filters.applyFiltering(filter, params);
        }

        return this.httpClient.get<PageResponse<WorkerGroup>>(`/api/v1/worker-groups`, {params});
    }

    postWorkerGroup(workergroup: WorkerGroup): Observable<WorkerGroup> {
        return this.httpClient.post<WorkerGroup>(`/api/v1/worker-groups`, workergroup);
    }

    putWorkerGroup(workergroup: WorkerGroup): Observable<WorkerGroup> {
        return this.httpClient.put<WorkerGroup>(`/api/v1/worker-groups/${workergroup.id}`, workergroup);
    }

    getWorkerGroup(id: number): Observable<WorkerGroup> {
        return this.httpClient.get<WorkerGroup>(`/api/v1/worker-groups/${id}`);
    }

    getWorkerGroups(search: string): Observable<PageResponse<WorkerGroup>> {
        return this.httpClient.get<PageResponse<WorkerGroup>>('/api/v1/worker-groups', {params: {
            search: `name:${search}`
        }});
    }
}
