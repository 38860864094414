import {ChangeDetectionStrategy, Component, OnDestroy} from '@angular/core';
import {AsideService} from './aside.service';

@Component({
    selector: 'app-aside',
    templateUrl: './aside.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AsideComponent implements OnDestroy {
    $asideOpen = this.asideService.asideOpen;

    constructor(
        private asideService: AsideService
    ) {
    }

    ngOnDestroy() {
        this.asideService.close();
    }

}
