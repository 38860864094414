<div class="vwui-container is-small">
    <div class="vwui-card">
        <div class="vwui-card__header">{{'Kaartlagen'|translate}}</div>
        <div class="vwui-card__content">
            <form *ngIf="form" [formGroup]="form" (submit)="save()" [class.form-is-valid]="form.valid">
                <div class="vwui-form-group u-mb-m" [appFormControlError]="form.get('configurationUrl')">
                    <label for="configurationUrl" class="vwui-label">{{'Configuratie URL'|translate}}</label>
                    <input id="configurationUrl" class="vwui-input" name="configurationUrl"
                           formControlName="configurationUrl" type="text" autocomplete="off"
                           placeholder="https://...">
                </div>
                <div formGroupName="mapLayersData">
                    <div class="vwui-form-group u-mb-m mt-3" *ngFor="let control of mapLayerControls; index as i"
                         [appFormControlError]="control">
                        <div class="float-right" *appHasFeatureToggle="'projectMapLayerProperties'" >
                            <app-action-menu #actionMenu>
                                <button type="button" class="vwui-menu-item" (click)="addMaplayerProperties(control)">
                                    <app-icon iconName="edit-2"></app-icon>
                                    {{ 'Laaginstellingen'|translate }}
                                </button>
                            </app-action-menu>
                        </div>
                        <label [for]="'layer'+i" class="vwui-label">
                            {{'Kaartlaag'|translate}} #{{ i+1 }}
                        </label>
                        <input [id]="'layer'+i" class="vwui-input project-map__mapurl" name="mapUrl" [formControl]="control.get('mapUrl')" type="text"
                               autocomplete="off" placeholder="https://..."
                        >
                    </div>
                </div>
                <button type="submit" class="d-none"></button>
            </form>
        </div>
    </div>
</div>

<app-actionbar *ngIf="form && form.dirty && !detailsOpen" (save)="save()" (cancel)="cancel()"></app-actionbar>
