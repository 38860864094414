<div class="vwui-list has-action" [class.is-sorting]="isSorting">
    <div [sortablejs]="questions" [sortablejsOptions]="sortableOptions">
        <app-question-list-item
            *ngFor="let formItem of questions; trackBy: trackByFormItems; index as i"
            [readonly]="readonly"
            [isSelected]="isSelected(formItem)"
            [getDependentQuestionPosition]="getDependentQuestionPosition"
            [chapterNumber]="chapterNumber"
            [chapterPosition]="i + 1"
            [formItem]="formItem"
            (edit)="edit.emit(formItem)"
            (duplicate)="duplicate.emit(formItem)"
            (remove)="remove.emit(formItem)"></app-question-list-item>
    </div>
    <div class="vwui-list__action">
        <ng-content></ng-content>
    </div>
</div>
