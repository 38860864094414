import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Inject, Injectable} from '@angular/core';
import {ProjectExecutionGroupService} from '../services/project-execution-group.service';
import {ProjectExecutionGroup} from '../models/project-execution-group';

@Injectable({
    providedIn: 'root'
})
export class ProjectExecutionGroupResolver implements Resolve<ProjectExecutionGroup> {

    constructor(
        @Inject('ProjectExecutionGroupService') private executionGroupService: ProjectExecutionGroupService,
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.executionGroupService.getProjectExecutionGroup(+route.params.project, +route.params.id);
    }
}
