<app-toolbar
    [showAddBtn]="true"
    [addBtnRoute]="'/beheer/companies/new'"
    addBtnLabel="{{'Nieuw bedrijf'|translate}}">
</app-toolbar>
<div class="vwui-container">
    <app-table
        *ngIf="paginator$|async;let paginator"
        [paginator]="paginator"
        [headerTitles]="[{sortKey: 'name', filterKey: 'name', title: 'Bedrijvennaam'}]"
        (filterChanged)="onTableFilterChange($event)">
        <ng-template let-item>
            <tr [routerLink]="['/beheer/companies/', item.id]">
                <td>{{ item.name }}</td>
            </tr>
        </ng-template>
    </app-table>
</div>
