import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-actionbar',
    templateUrl: './actionbar.component.html'
})
export class ActionbarComponent implements OnInit, OnDestroy {
    @Input() saveDisabled = false;
    @Output() cancel = new EventEmitter<void>();
    @Output() save = new EventEmitter<void>();

    constructor() {
    }

    ngOnInit() {
        document.body.classList.add('actionbar-visible');
    }

    ngOnDestroy() {
        document.body.classList.remove('actionbar-visible');
    }

}
