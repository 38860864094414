<div
    class="vwui-list-item" [class.has-action]="!readonly"
    [class.is-selected]="isSelected"
    (click)="onEdit()"
>
    <div class="vwui-list-item__content vwui-list-item__content--auto-height">
        <div>
            {{ chapterNumber }}.{{ chapterPosition }} {{ formItem.title }}
            <span class="vwui-badge is-primary">{{ formItem.type | questionTypeTitle }}</span>
            <span *ngIf="asFormulaQuestionOrNull(formItem) as formulaQuestion" class="vwui-badge is-primary">
                {{ formulaQuestion.formulaFields.label }}
            </span>
            <span class="vwui-badge" *ngIf="isRequired(formItem)">{{'Verplicht'|translate}}</span>
            <span class="vwui-badge is-success" *ngIf="dependentQuestionPosition">
                {{'Afhankelijk van'|translate}}: {{ dependentQuestionPosition }}
            </span>
            <span [innerHTML]="formItem.description" class="text-muted small child-paragraph-no-margin"></span>
        </div>

        <app-action-menu #actionMenu *ngIf="!readonly">
            <button class="vwui-menu-item" (click)="onEdit()">
                <app-icon iconName="edit-2"></app-icon>
                {{'Bewerken'|translate}}
            </button>
            <button class="vwui-menu-item" (click)="onDuplicate()">
                <app-icon iconName="duplicate"></app-icon>
                {{'Kopieren'|translate}}
            </button>
            <button class="vwui-menu-item is-danger" (click)="onRemove($event)">
                <app-icon iconName="trash-2"></app-icon>
                {{'Verwijderen'|translate}}
            </button>
        </app-action-menu>
    </div>
</div>
