<app-toolbar [showBackBtn]="true"
             [backBtnRoute]="['/beheer/projects', this.form.value.project.id]"
             [backBtnExtras]="{queryParams: {tab: 'objects'}}"
             [toolbarTitle]="objectTitle"
             [goBack$]="cancel$"
></app-toolbar>
<div class="vwui-container is-small" *ngIf="project">
    <div class="vwui-card">
        <div class="vwui-card__header">{{'Object informatie'|translate}}</div>
        <div class="vwui-card__content">
            <form [formGroup]="form" (submit)="save()" [class.form-is-valid]="form.valid">
                <ng-container *ngFor="let formControl of formControls">
                    <ng-container *ngIf="(formControl.visibleForDefaultProject || project.hasMaintenanceContract)
                        && (formControl.isVisible === undefined || formControl.isVisible(form.value))">
                        <div class="u-mb-m">
                            <div class="vwui-form-group" [appFormControlError]="form.get(formControl.name)">
                                <ng-container *ngIf="formControl.type !== 'toggle'">
                                    <label [for]="formControl.name" class="vwui-label">{{ formControl.label }}</label>

                                    <input *ngIf="formControl.type === 'text' || formControl.type === 'number'"
                                           [id]="formControl.name"
                                           [name]="formControl.name"
                                           [type]="formControl.type"
                                           [formControlName]="formControl.name"
                                           class="vwui-input"
                                           autocomplete="off">

                                    <app-paula-object-type-select
                                        *ngIf="formControl.name === 'objectType'"
                                        [id]="formControl.name"
                                        [formControlName]="formControl.name">
                                    </app-paula-object-type-select>

                                    <ng-select
                                        *ngIf="formControl.name === 'objectLocationType'"
                                        placeholder="{{ 'Selecteer een locatietype'|translate }}"
                                        bindValue="value"
                                        [id]="formControl.name"
                                        [items]="locationTypes"
                                        [formControlName]="formControl.name"
                                        [clearable]="false">
                                    </ng-select>
                                </ng-container>
                                <ng-container *ngIf="formControl.type === 'toggle'">
                                    <label class="vwui-input-control is-toggle has-label mb-4 vwui-label">
                                        <input class="vwui-input" type="checkbox" [formControlName]="formControl.name">
                                        <span>{{formControl.label}}</span>
                                        <span class="checkbox"></span>
                                    </label>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
                <ng-container *ngFor="let formControl of descriptionFormControls">
                    <ng-container *ngIf="formControl.visibleForDefaultProject || project.hasMaintenanceContract">
                        <div class="u-mb-m">
                            <div class="vwui-form-group" [appFormControlError]="form.get(formControl.name)">
                                <label [for]="formControl.name" class="vwui-label">{{ formControl.label }}</label>

                                <input [id]="formControl.name"
                                       [name]="formControl.name"
                                       [type]="formControl.type"
                                       [formControlName]="formControl.name"
                                       class="vwui-input"
                                       autocomplete="off">
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
                <button type="submit" class="d-none"><!-- Needed to submit with enter --></button>
            </form>
        </div>
    </div>
</div>

<app-actionbar *ngIf="form.dirty" (cancel)="cancel()" (save)="save()"></app-actionbar>
