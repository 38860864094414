// @ts-strict-ignore
import {Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup, UntypedFormControl, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {Forms} from '../../utils/forms';
import {ProjectJobService} from '../../services/project-job.service';
import {ProjectJob, ProjectJobExtraLatLong, ProjectJobStatus} from '../../models/project-job';
import {ChoiceQuestion} from '../../models/job-form/choice-question';
import {firstValueFrom} from "rxjs";

@Component({
    selector: 'app-project-job-detail-tab',
    templateUrl: './project-job-detail-tab.component.html'
})
export class ProjectJobDetailTabComponent implements OnChanges {
    @Input() projectJob: ProjectJob;
    @Output() projectJobSaved = new EventEmitter<ProjectJob>();

    form = new FormGroup({
        title: new FormControl<string>(null, [Validators.required, Validators.maxLength(320)]),
        startDate: new FormControl<Date>(null),
        extraFields: new FormGroup({
            locationFrom: new FormControl<string>(null, [Validators.maxLength(255)]),
            locationTo: new FormControl<string>(null, [Validators.maxLength(255)]),
            gpsLatitudeFrom: new FormControl<number>(null, [Validators.maxLength(255)]),
            gpsLatitudeTo: new FormControl<number>(null, [Validators.maxLength(255)]),
            gpsLongitudeFrom: new FormControl<number>(null, [Validators.maxLength(255)]),
            gpsLongitudeTo: new FormControl<number>(null, [Validators.maxLength(255)])
        }),
        attachments: new UntypedFormControl([])
    });

    constructor(
        @Inject('ProjectJobService') private projectJobService: ProjectJobService,
        private toast: ToastrService
    ) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.projectJob && changes.projectJob.currentValue) {
            const job: ProjectJob = changes.projectJob.currentValue;
            this.form.patchValue(job);
            if (job.status === ProjectJobStatus.Concept
                || job.status === ProjectJobStatus.InProgress
                || job.status === ProjectJobStatus.AvailableForInspection
            ) {
                this.form.enable();
            } else {
                this.form.disable();
            }
        }
    }

    onLocationSelected(event: ProjectJobExtraLatLong) {
        this.form.patchValue({
            extraFields: {
                gpsLatitudeFrom: event.gpsLatitudeFrom,
                gpsLatitudeTo: event.gpsLatitudeTo,
                gpsLongitudeFrom: event.gpsLongitudeFrom,
                gpsLongitudeTo: event.gpsLongitudeTo
            }
        });
        this.form.markAsDirty();
    }

    async save() {
        if (!this.form.valid) {
            Forms.updateValueAndValidityRecursive(this.form);
            return;
        }

        try {
            const result = await firstValueFrom(this.projectJobService.patchProjectJobDetails(
                this.projectJob.id,
                this.form.getRawValue()
            ));

            this.toast.success('Opgeslagen');
            this.form.patchValue(result);
            this.projectJobSaved.emit({
                ...this.projectJob,
                ...this.form.getRawValue()
            });

        } catch (error) {
            console.error('Unable to save projectJob', error);
            this.toast.error('Opslaan mislukt');
        }
    }

    cancel() {
        history.back();
    }

    getLastStatusChange(projectJob: ProjectJob) {
        return projectJob.submits && projectJob.submits.length > 0
            ? projectJob.submits.map(it => it.submittedAt).reduce((a, b) => a > b ? a : b)
            : null;
    }

    getInspectorNames(projectJob: ProjectJob) {
        const names = projectJob.answers
            .map(it => it.createdBy)
            .filter((value, index, array) => array.indexOf(value) === index)
            .join(', ');
        return names === '' ? '-' : names;
    }

    getOriginatedQuestion(): ChoiceQuestion {
        if (this.projectJob && this.projectJob.extraFields.originatedQuestion && this.projectJob.originalProjectJob) {
            return this.projectJob.originalProjectJob.formItems.find(formItem => {
                return formItem.id === this.projectJob.extraFields.originatedQuestion;
            }) as ChoiceQuestion;
        }
    }
}
