<div class="vwui-card is-basic" *ngIf="config">
    <div class="vwui-card__header" *ngIf="config.length > 1">
        <div class="vwui-filter">
            <ng-container *ngFor="let conf of config;index as i">
                <div class="vwui-filter__item" [class.is-active]="i === activeFilter" (click)="selectFilter(i)">
                    {{ conf.title }}
                    <ng-container *ngIf="i === activeFilter && conf.isGroup"> ({{ (conf.searchData|async)?.length }})
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </div>
    <app-search (searchChanged)="search.emit($event)"
                [placeholder]="config[activeFilter]?.isGroup ? 'Zoeken' : 'Zoek op achternaam of e-mailadres'"></app-search>
    <ng-container *ngFor="let conf of config;index as i">
        <ng-container *ngIf="i === activeFilter">
            <ng-container *ngIf="conf.searchData|async as list">
                <!-- Render users table -->
                <ng-container *ngIf="!conf.isGroup">
                    <ng-container *ngIf="list.length > 0; else searchEmpty">
                        <table class="vwui-table has-hover has-action">
                            <tr *ngFor="let user of list" (click)="userSelected.emit(user)">
                                <td>
                                    {{ user.displayName }}<br>
                                    {{ user.mail }}
                                </td>
                                <td>{{ user.companyName }}</td>
                                <td class="vwui-table__arrow-icon">
                                    <app-icon iconName="arrow-right"></app-icon>
                                </td>
                            </tr>
                        </table>
                    </ng-container>
                    <ng-template #searchEmpty>
                        <table class="vwui-table">
                            <tr>
                                <td>{{'Er zijn geen medewerkers gevonden'|translate}}</td>
                            </tr>
                        </table>
                    </ng-template>
                </ng-container>
                <!-- Render groups table -->
                <ng-container *ngIf="conf.isGroup">
                    <ng-container *ngIf="list.length > 0; else searchEmpty">
                        <table class="vwui-table has-hover has-action">
                            <tr *ngFor="let group of list" (click)="groupSelected.emit(group)">
                                <td>{{ group.name }}</td>
                                <td class="vwui-table__arrow-icon">
                                    <app-icon iconName="arrow-right"></app-icon>
                                </td>
                            </tr>
                        </table>
                    </ng-container>
                    <ng-template #searchEmpty>
                        <table class="vwui-table">
                            <tr>
                                <td>{{'Er zijn geen groepen gevonden'|translate}}</td>
                            </tr>
                        </table>
                    </ng-template>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>
</div>
