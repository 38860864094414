// @ts-strict-ignore
import {Component, Input, OnDestroy} from '@angular/core';
import {Choice, FollowUpProjectJobTemplate} from '../../models/form-item';
import {UntypedFormControl} from '@angular/forms';
import {FollowUpProjectJobModalComponent} from '../follow-up-project-job-modal/follow-up-project-job-modal.component';
import {ProjectJob} from '../../models/project-job';
import {BsModalService} from 'ngx-bootstrap/modal';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs';
import {recoveryTimes} from '../../models/recovery-time';
import {FeatureToggle} from '../../models/feature-toggle';
import {ModalResult} from "../../models/modal.result";

@Component({
    selector: 'app-follow-up-project-jobs',
    templateUrl: './question-follow-up-project-jobs.component.html'
})
export class QuestionFollowUpProjectJobsComponent implements OnDestroy {

    _choicesControl: UntypedFormControl;
    _multipleControl: UntypedFormControl;
    _followUpProjectJob: ProjectJob;

    private choiceControlSubscription: Subscription;
    private multipleControlSubscription: Subscription;

    @Input()
    set choicesControl(choiceControl: UntypedFormControl) {
        this._choicesControl = choiceControl;
        this.updateChoicesWithoutFollowUpProjectJobTemplate(this._choicesControl.value);

        if (this.choiceControlSubscription) {
            this.choiceControlSubscription.unsubscribe();
        }
        this.choiceControlSubscription = choiceControl.valueChanges.subscribe(
            choices => this.updateChoicesWithoutFollowUpProjectJobTemplate(choices)
        );
    }

    @Input()
    set multipleControl(multipleControl: UntypedFormControl) {
        this._multipleControl = multipleControl;

        if (this.multipleControlSubscription) {
            this.multipleControlSubscription.unsubscribe();
        }
        this.multipleControlSubscription = this._multipleControl.valueChanges.subscribe(
            (multiple) => {
                if (multiple) {
                    this.removeFollowUpProjectJobTemplateFromChoices();
                }
            }
        );
    }

    @Input()
    set followUpProjectJob(followUpProjectJob: ProjectJob) {
        this._followUpProjectJob = followUpProjectJob;
    }

    followUpChoiceControl = new UntypedFormControl(null);
    choicesWithoutFollowUpProjectJobTemplate: Choice[];
    recoveryTimes = recoveryTimes;

    maximoToggle = FeatureToggle.Maximo;

    constructor(
        private modalService: BsModalService,
        private toast: ToastrService
    ) {
    }

    ngOnDestroy(): void {
        if (this.choiceControlSubscription) {
            this.choiceControlSubscription.unsubscribe();
        }
        if (this.multipleControlSubscription) {
            this.multipleControlSubscription.unsubscribe();
        }
    }

    configureFollowUpProjectJobForChoice(choiceId: string) {
        this.modalService.show(FollowUpProjectJobModalComponent, {
            class: 'modal-dialog-centered',
            initialState: {
                project: this._followUpProjectJob.project,
                onClose: (result: ModalResult, template: FollowUpProjectJobTemplate) => {
                    if (result === 'confirmed' && template) {
                        this.addFollowUpProjectJobTemplateToChoice(choiceId, template);
                    }

                    if (result === 'error') {
                        this.toast.error('Koppelen niet gelukt');
                    }
                }
            }
        });
    }

    deleteFollowUpProjectJobForChoice(choiceId: string) {
        const choices = this._choicesControl.value as Choice[];

        const selectedChoice = choices.find(it => it.id === choiceId);
        selectedChoice.followUpProjectJobTemplate = null;

        this._choicesControl.setValue(choices);
    }

    updateFollowUpProjectJobForChoice(selectedChoice: Choice, event: MouseEvent) {
        event.stopPropagation();
        event.preventDefault();

        this.modalService.show(FollowUpProjectJobModalComponent, {
            class: 'modal-dialog-centered',
            initialState: {
                project: this._followUpProjectJob.project,
                initialForm: selectedChoice.followUpProjectJobTemplate,
                onClose: (result: ModalResult, template: FollowUpProjectJobTemplate) => {
                    if (result === 'confirmed' && template) {
                        this.addFollowUpProjectJobTemplateToChoice(selectedChoice.id, template);
                    }

                    if (result === 'error') {
                        this.toast.error('Aanpassen niet gelukt');
                    }
                }
            }
        });
    }

    private removeFollowUpProjectJobTemplateFromChoices() {
        this._choicesControl.setValue(
            (this._choicesControl.value as Choice[]).map(choice => {
                choice.followUpProjectJobTemplate = null;
                return choice;
            })
        );
    }

    private addFollowUpProjectJobTemplateToChoice(choiceId: string, template: FollowUpProjectJobTemplate) {
        const choices = this._choicesControl.value as Choice[];

        const selectedChoice = choices.find(it => it.id === choiceId);
        selectedChoice.followUpProjectJobTemplate = template;

        this._choicesControl.setValue(choices);
    }

    private updateChoicesWithoutFollowUpProjectJobTemplate(choices: Choice[]) {
        this.followUpChoiceControl.setValue('');
        this.choicesWithoutFollowUpProjectJobTemplate = choices.filter(it => !it.followUpProjectJobTemplate);
    }
}
