// @ts-strict-ignore
import {Directive, Input, OnDestroy, OnInit} from '@angular/core';
import {ControlValueAccessor, FormControl} from '@angular/forms';
import {debounceTime, distinctUntilChanged, map, tap} from 'rxjs/operators';
import {filteredInfiniteScrollObservable} from '../../utils/pagination';
import {BehaviorSubject, combineLatest, defer, Observable, Subscription} from 'rxjs';
import {PageResponse} from '../../models/page-response';
import {Identifiable} from '../../models/identifiable';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class ItemSelectComponent<T, F extends {[key: string]: any}> implements ControlValueAccessor, OnInit, OnDestroy {
    @Input() multiple = false;
    placeholder: string;

    formControl = new FormControl<T>(null);
    disabled = false;

    private onChange: (value: T) => void;
    private onTouched: () => void;

    private formSubscription: Subscription;

    onInput = new BehaviorSubject<string>('');
    loadMoreSubject = new BehaviorSubject<void>(null);
    loading = false;
    items = filteredInfiniteScrollObservable(
        combineLatest([
            defer(() => this.getFilter()),
            this.onInput.pipe(debounceTime(200), distinctUntilChanged())
        ]).pipe(map(([filter, term]) => ({...filter, term: term}))),
        this.loadMoreSubject,
        (page, filter) => {
            this.loading = true;
            return this.loadMore(page, filter).pipe(
                tap(() => this.loading = false)
            );
        }
    );

    scrollToEnd = () => this.loadMoreSubject.next(null);

    abstract loadMore(page: number, term: F&{term: string}): Observable<PageResponse<T>>;

    abstract getLabelText(item: T): string;

    abstract getFilter(): Observable<F>;

    compare(a: T, b: T): boolean {
        return a === b;
    }

    registerOnChange(fn: (value: T) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
        isDisabled ? this.formControl.disable({emitEvent: false}) : this.formControl.enable({emitEvent: false});
    }

    writeValue(value: T): void {
        this.formControl.setValue(value, {emitEvent: false});
    }

    ngOnInit(): void {
        this.formSubscription = this.formControl.valueChanges.subscribe((value) => {
            if (this.onChange) {
                this.onChange(value);
            }
            if (this.onTouched) {
                this.onTouched();
            }
        });
    }

    ngOnDestroy(): void {
        if (this.formSubscription) {
            this.formSubscription.unsubscribe();
        }
    }
}

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class EntitySelectComponent<E extends Identifiable, F> extends ItemSelectComponent<E, F> {
    compare(a: E, b: E): boolean {
        if (a === null || b === null) {
            return false;
        }

        return a.id === b.id;
    }
}
