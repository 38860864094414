import esri = __esri;

export class ProjectMapConfig {
    static readonly jobLayerFields: esri.FieldProperties[] = [
        {name: 'id', type: 'oid'},
        {name: 'status', type: 'string'},
        {name: 'title', type: 'string'},
        {name: 'createdAt', type: 'date'},
        {name: 'createdBy', type: 'string'},
        {name: 'code', type: 'string'},
        {name: 'objectOmschrijvingKort', type: 'string'},
        {name: 'href', type: 'string'},
        {name: 'locationFrom', type: 'string'},
        {name: 'locationTo', type: 'string'}
    ];

    static readonly jobPopupTemplate = {
        title: '{title}',
        actions: [{
            id: 'job-detail',
            title: 'Open opdracht',
            className: 'esri-icon-review'
        } as esri.ActionButton],
        overwriteActions: true,
        outFields: ['*'],
        content: [
            {
                type: 'fields',
                fieldInfos: [
                    {fieldName: 'createdAt', label: 'Aangemaakt op'},
                    {fieldName: 'createdBy', label: 'Aangemaakt door'},
                    {
                        fieldName: 'code', label: 'Opdrachtnummer'
                    },
                    {
                        fieldName: 'objectOmschrijvingKort', label: 'Object'
                    },
                    {fieldName: 'locationFrom', label: 'Locatie van'},
                    {fieldName: 'locationTo', label: 'Locatie tot'}
                ] as esri.FieldInfo[]
            }
        ] as esri.ContentProperties[]
    };

    static readonly baseMarker = {
        type: 'simple-marker',
        size: 16,
        outline: {
            color: '#FFF',
            width: 2
        }
    } as esri.SimpleMarkerSymbolProperties;

    static readonly jobMarkerRenderer: esri.UniqueValueRendererProperties = {
        type: 'unique-value',
        field: 'status',
        uniqueValueInfos: [
            {
                value: 'Concept',
                symbol: {...ProjectMapConfig.baseMarker, color: '#a0a0a0'},
                label: 'Concept'
            },
            {
                value: 'AvailableForInspection',
                symbol: {...ProjectMapConfig.baseMarker, color: '#1f9bd6'},
                label: 'Voor uitvoering'
            },
            {
                value: 'AvailableForVerification',
                symbol: {...ProjectMapConfig.baseMarker, color: '#ff7100'},
                label: 'Voor verificatie'
            }, {
                value: 'Archived',
                symbol: {...ProjectMapConfig.baseMarker, color: '#545454'},
                label: 'Vervallen'
            },
            {
                value: 'Approved',
                symbol: {...ProjectMapConfig.baseMarker, color: '#24ab46'},
                label: 'Goedgekeurd'
            },
            {
                value: 'InProgress',
                symbol: {...ProjectMapConfig.baseMarker, color: '#003c73'},
                label: 'In uitvoering'
            },
            {
                value: 'Rejected',
                symbol: {...ProjectMapConfig.baseMarker, color: '#c80000'},
                label: 'Afgekeurd'
            },
        ]
    } as esri.UniqueValueRendererProperties;
}
