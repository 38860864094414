<app-modal modalTitle="{{'Projectformulier overnemen'|translate}}" modalSaveBtnLabel="{{'Kopieren'|translate}}" (save)="submit($event)"
           (cancel)="this.bsModalRef.hide()" [loadingSaveBtn]="true">
    <form [formGroup]="form" (submit)="submit()">
        <div class="u-mb-m">
            <div [appFormControlError]="form.controls.project" class="vwui-form-group mb-4">
                <label for="project" class="vwui-label">{{'Project'|translate}}</label>
                <app-project-select id="project" formControlName="project"></app-project-select>
            </div>
        </div>
        <div class="u-mb-m">
            <div [appFormControlError]="form.controls.projectForm" class="vwui-form-group mb-4">
                <label for="projectForm" class="vwui-label">{{'Projectformulier'|translate}}</label>
                <app-project-form-select id="projectForm" formControlName="projectForm"
                     [projectFilter]="form.controls.project.value" [showCode]="true">
                </app-project-form-select>
            </div>
        </div>

        <div *ngIf="isLoading$|async" class="vwui-spinner"></div>
        <div *ngIf="(formExists$|async) === 'existing'" class="alert alert-warning">
            Waarschuwing: Er bestaat al een formulier met hetzelfde formuliernummer in master databeheer.
        </div>

        <button type="submit" class="d-none"><!-- Needed to submit with enter --></button>
    </form>
</app-modal>
