import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FileUploadEvent} from './file-upload.interface';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-file-upload-btn',
    templateUrl: './file-upload-btn.component.html'
})
export class FileUploadBtnComponent {
    @Input() accept = '.xlsx';
    @Input() loaderEnabled = true;
    @Input() maxSize = 0;
    @Input() disabled = false;
    @Output() fileChange = new EventEmitter<FileUploadEvent>();

    showLoader = false;

    constructor(
        private toast: ToastrService,
        private translate: TranslateService,
    ) {
    }

    onFileChange(event: Event) {
        const fileInput = event.target
        if (fileInput instanceof HTMLInputElement && fileInput.files && fileInput.files.length) {
            const totalFileSize = +Array.from(fileInput.files)
                .map(file => (file as any).size)
                .reduce((val: number, total: number) => total + val, 0);
            if (this.maxSize > 0 && totalFileSize > this.maxSize) {
                this.toast.error(this.translate.instant(
                    'Upload exceeds the maximum file size {{max}}',
                    {max: `${this.maxSize / 1000000}mb`}
                ));
                return;
            }

            this.showLoader = true;

            this.fileChange.emit({
                target: fileInput,
                complete: () => {
                    this.showLoader = false;
                    fileInput.value = '';
                }
            });
        }
    }
}
