// @ts-strict-ignore
import {Directive, HostBinding, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {AbstractControl, FormControlStatus} from '@angular/forms';
import {Subscription} from 'rxjs';
import {startWith} from 'rxjs/operators';

@Directive({
    selector: '[appFormControlError]'
})
export class ControlFormErrorDirective implements OnDestroy, OnChanges {

    @HostBinding('class.has-error') hasError = false;
    @Input() appFormControlError: AbstractControl;

    private statusSubscription: Subscription;

    ngOnDestroy(): void {
        if (this.statusSubscription) {
            this.statusSubscription.unsubscribe();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.appFormControlError) {
            this.statusSubscription?.unsubscribe();
            this.statusSubscription = this.appFormControlError?.statusChanges?.pipe(
                startWith(this.appFormControlError.status)
            ).subscribe((status: FormControlStatus) => {
                this.hasError = status === 'INVALID' &&
                    (this.appFormControlError?.dirty === true || this.appFormControlError?.touched == true);
            });
        }
    }
}
