import {HttpErrorResponse} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';

export class ErrorToast {
    static handleExcelImportErrors(toast: ToastrService, response: HttpErrorResponse) {
        if (response.error && response.error.fieldErrors) {
            const fieldErrors = response.error.fieldErrors;
            return ErrorToast.handleExcelImportFieldErrors(toast, fieldErrors);
        }
        return false;
    }

    static handleExcelImportFieldErrors(toast: ToastrService, fieldErrors: any) {
        if (fieldErrors.cell) {
            toast.error(
                `Het importeren van object(en) is mislukt, fout in cell ${fieldErrors.cell}`
                + `, ongeldige waarde: ${fieldErrors.value}`
            );
            return true;
        }
        if (fieldErrors.row) {
            toast.error(`Het importeren van objecten is mislukt, fout in rij ${fieldErrors.row}`);
            return true;
        }
        if (fieldErrors.sheet) {
            toast.error(`Het importeren van objecten is mislukt, werkblad '${fieldErrors.sheet}' ontbreekt`);
            return true;
        }

        return false;
    }
}
