import {Component, Inject} from '@angular/core';
import {QuestionSetService} from '../../services/question-set.service';
import {AbstractListComponent} from "../abstract-list.component";
import {QuestionSet} from "../../models/question-set";
import {ActivatedRoute, Router} from "@angular/router";
import {TableFilter} from "../../components/table/table.component";
import {Observable} from "rxjs";
import {PageResponse} from "../../models/page-response";

@Component({
    selector: 'app-question-set-list',
    templateUrl: './question-set-list.component.html'
})
export class QuestionSetListComponent extends AbstractListComponent<QuestionSet> {
    constructor(
        @Inject('QuestionSetService') public questionSetService: QuestionSetService,
        activatedRoute: ActivatedRoute,
        router: Router,
    ) {
        super(activatedRoute, router);
    }

    loadData(page: number, filter: TableFilter, search: string | undefined): Observable<PageResponse<QuestionSet>> {
        return this.questionSetService.getQuestionSets(page, filter);
    }
}
