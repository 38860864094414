<app-toolbar [showBackBtn]="true" backBtnRoute="/beheer/projects" [toolbarTitle]="projectTitle">
    <app-loading-btn iconName="download" *ngIf="project" (btnClick)="exportProject($event)">{{'Project exporteren'|translate}}</app-loading-btn>
    <button class="vwui-btn has-icon" *ngIf="project" tooltip="{{'Opdrachten op kaart bekijken'|translate}}" container="body"
            (click)="openMap()">
        <app-icon iconName="map"></app-icon>
        {{'Projectkaart openen'|translate}}
    </button>
    <a class="vwui-btn has-icon" *ngIf="project" tooltip="{{'Opdrachten in de lijst bekijken'|translate}}"
        [routerLink]="'/beheer/project-jobs'"
        [queryParams]="{'filter-project.name': project?.name}">
        <app-icon iconName="menu"></app-icon>
        {{'Opdrachtenlijst openen'|translate}}
    </a>
</app-toolbar>
<div class="vwui-tabs" *ngIf="project">
    <button class="vwui-tabs__item" [class.is-active]="currentTab === 'detail'" (click)="setCurrentTab('detail')">
        {{'Algemeen'|translate}}
    </button>
    <button class="vwui-tabs__item" [class.is-active]="currentTab === 'mapLayers'" (click)="setCurrentTab('mapLayers')">
        {{'Kaartlagen'|translate}}
    </button>
    <button class="vwui-tabs__item" [class.is-active]="currentTab === 'coordinators'" (click)="setCurrentTab('coordinators')">
        {{'Coördinatoren'|translate}}
    </button>
    <button class="vwui-tabs__item" [class.is-active]="currentTab === 'users'" (click)="setCurrentTab('users')">
        {{'Projectteam'|translate}}
    </button>
    <button class="vwui-tabs__item" [class.is-active]="currentTab === 'execution-groups'" (click)="setCurrentTab('execution-groups')">
        {{'Projectdisciplines'|translate}}
    </button>
    <button class="vwui-tabs__item" [class.is-active]="currentTab === 'forms'" (click)="setCurrentTab('forms')">
        {{'Formulieren'|translate}}
    </button>
    <button class="vwui-tabs__item" [class.is-active]="currentTab === 'objects'" (click)="setCurrentTab('objects')">
        {{'Objecten'|translate}}
    </button>
    <button class="vwui-tabs__item" [class.is-active]="currentTab === 'export'" (click)="setCurrentTab('export')">
        {{'Rapport'|translate}}
    </button>
</div>

<app-project-detail-tab *ngIf="currentTab === 'detail'" [project]="project" (projectSaved)="onProjectSave($event)"></app-project-detail-tab>
<app-project-coordinators-tab *ngIf="currentTab === 'coordinators'" [project]="project" (projectSaved)="onProjectSave($event)"></app-project-coordinators-tab>
<app-project-users-tab *ngIf="currentTab === 'users'" [project]="project" (projectSaved)="onProjectSave($event)"></app-project-users-tab>
<app-project-execution-group-tab *ngIf="currentTab === 'execution-groups'" [project]="project"></app-project-execution-group-tab>
<app-project-forms-tab *ngIf="currentTab === 'forms'" [project]="project"></app-project-forms-tab>
<app-project-object-tab *ngIf="currentTab === 'objects'" [project]="project"></app-project-object-tab>
<app-project-map-layers-tab *ngIf="currentTab === 'mapLayers'" [project]="project" (projectSaved)="onProjectSave($event)"></app-project-map-layers-tab>
<app-project-export-tab *ngIf="currentTab === 'export'" [project]="project" (projectSaved)="onProjectSave($event)"></app-project-export-tab>
