import {Component} from '@angular/core';
import {NavService} from '../nav/nav.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent {

  constructor(
      private translate: TranslateService,
      private navService: NavService
  ) { }

  onToggleNav() {
    this.navService.toggleNav();
  }
}
