// @ts-strict-ignore
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    UntypedFormControl,
    ValidationErrors,
    Validators
} from '@angular/forms';
import {BehaviorSubject, Observable, of, Subscription, timer} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {User, UserProject} from '../../models/user';
import {Subscriptions} from '../../utils/subscriptions';
import {UserService} from '../../services/user.service';
import {ToastrService} from 'ngx-toastr';
import {map, shareReplay, switchMap, take} from 'rxjs/operators';
import {AuthCheckService} from '../../services/auth-check.service';
import {HttpErrorResponse} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {AppleContentCodeService} from '../../services/apple-content-code.service';
import {AppleContentCode} from '../../models/apple-content-code';
import {HistoryService} from "../../services/history.service";

@Component({
    selector: 'app-user-detail',
    templateUrl: './user-detail.component.html'
})
export class UserDetailComponent implements OnInit, OnDestroy {

    form = new FormGroup({
        id: new FormControl<number>(null),
        username: new FormControl<string>('', [Validators.required, Validators.email], [this.validateUsername.bind(this)]),
        roles: new FormControl<string[]>([]),
        signatureId: new FormControl<string>(null)
    });
    user: User;
    dirtyForm = false;
    showWarning = false;

    userProjects$ = this.route.data.pipe(
        switchMap(_ => this.user ? this.userService.getUserProjects(this.user.id) : of([])),
        shareReplay(1)
    );
    userProjects: UserProject[] = [];

    userContentCodeSubject = new BehaviorSubject<AppleContentCode>(null);
    userContentCode$: Observable<AppleContentCode> = this.userContentCodeSubject.asObservable();

    private subscriptions: Array<Subscription> = [];

    constructor(
        private route: ActivatedRoute,
        @Inject('UserService') private userService: UserService,
        private toast: ToastrService,
        private router: Router,
        @Inject('AuthCheckService') private authCheckService: AuthCheckService,
        @Inject('AppleContentCodeService') private appleContentCodeService: AppleContentCodeService,
        private translateService: TranslateService,
        private historyService: HistoryService,
    ) {}

    ngOnInit() {
        this.showWarning = this.shouldShowPermissionWarning();
        this.subscriptions.push(
            this.route.data.subscribe((data) => {
                if (data.user) {
                    this.user = data.user;
                    this.form.patchValue(this.user, {emitEvent: false});
                    this.appleContentCodeService.getCodeForUser(data.user.username)
                        .pipe(take(1))
                        .subscribe(val => this.userContentCodeSubject.next(val));
                }
            })
        );
        this.subscriptions.push(
            this.form.valueChanges.subscribe(async () => {
                this.dirtyForm = true;
                this.showWarning = this.shouldShowPermissionWarning();
            })
        );
        this.subscriptions.push(
            this.userProjects$.subscribe(userProjects => {
                this.userProjects = userProjects;
                this.showWarning = this.shouldShowPermissionWarning();
            })
        );
    }

    ngOnDestroy(): void {
        Subscriptions.unsubscribeAll(this.subscriptions);
    }

    async save() {
        const user = this.form.value as User;

        try {
            let result;

            if (user.id) {
                result = await this.userService.putUser(user).toPromise();
            } else {
                result = await this.userService.postUser(user).toPromise();
            }
            this.toast.success('Opgeslagen');
            this.dirtyForm = false;
            this.form.patchValue(result, {emitEvent: false});

            this.goBack();
            this.authCheckService.authChanged();
        } catch (ex) {
            if (ex instanceof HttpErrorResponse && ex.error.message === 'Invalid username') {
                this.toast.error('Opslaan mislukt: gebruiker kon niet gevonden worden in AD');
            } else {
                this.toast.error('Opslaan mislukt');
            }
            console.error('Unable to save user', ex);
        }
    }

    cancel() {
        this.goBack();
    }

    goBack() {
        this.historyService.goBack(['/beheer/users']);
    }

    validateUsername(control: AbstractControl): Observable<ValidationErrors> {
        const currentId = +this.form.get('id').value;

        return timer(300).pipe(
            switchMap(() => {
                if (!control.value) {
                    return of(null);
                }

                return this.userService.exists(control.value);
            }),
            map(existingId => (!currentId && existingId) || (currentId !== +existingId && existingId) ? {titleExists: true} : null)
        );
    }

    /**
     * Determines whether the user requires a role for a certain project but doesn't have that role anymore
     */
    shouldShowPermissionWarning() {
        const userRoles = this.form.get('roles').value;
        return this.userProjects.some((userProject) => {
            return userProject.roles.some(projectRole => userRoles.indexOf(projectRole) === -1);
        });
    }

    rolesToTranslatedString(roles: string[]) {
        return roles.map(role => this.translateService.instant(role)).join(', ');
    }

    async decouple() {
        try {
            await this.userService.decoupleContentCode(this.user.id).toPromise();
            this.userContentCodeSubject.next(null);
        } catch (ex) {
            console.error('Exception occurred while decoupling content code', ex);
        }
    }
}
