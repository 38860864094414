<app-modal modalTitle="{{'Medewerker toevoegen'|translate}}" (cancel)="cancel()" (save)="confirm()">
    <div class="vwui-card is-small u-mb-m" *ngIf="executionGroupHolder">
        <div class="vwui-card__content">
            <p *ngIf="executionGroupHolder.user.displayName">{{ executionGroupHolder.user.displayName }}</p>
            <p>{{ executionGroupHolder.user.mail }}</p>
            <p class="u-mb-none" *ngIf="executionGroupHolder.user.companyName">{{ executionGroupHolder.user.companyName }}</p>
        </div>
    </div>
    <form [formGroup]="form" *ngIf="form">
        <div [appFormControlError]="form.get('executionGroups')" class="vwui-form-group mb-4">
            <label for="executionGroups" class="vwui-label">{{'Disciplines(optioneel)'|translate}}</label>
            <app-execution-group-select id="executionGroups" formControlName="executionGroups"></app-execution-group-select>
        </div>
        <div *ngIf="form.contains('projectExecutionGroups')"
             [appFormControlError]="form.get('projectExecutionGroups')"
             class="vwui-form-group mb-4">
            <label for="executionGroups" class="vwui-label">{{'Projectdisciplines(optioneel)'|translate}}</label>
            <app-project-execution-group-select [projectId]="$any(executionGroupHolder)['project']"
                                                id="projectExecutionGroups"
                                                formControlName="projectExecutionGroups">
            </app-project-execution-group-select>
        </div>
    </form>

</app-modal>
