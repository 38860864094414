<app-modal [modalTitle]="'Vervolgopdracht koppelen'|translate"
           [modalSaveBtnLabel]="(form.value.id ? 'Aanpassen': 'Koppelen')|translate"
           (save)="submit()"
           (cancel)="closeModal('cancelled')">
    <form [formGroup]="form">
        <div class="u-mb-m">
            <div class="vwui-form-group">
                <label for="type" class="vwui-label"></label>
                <ng-select
                    formControlName="type"
                    bindLabel="label"
                    bindValue="type"
                    id="type"
                    placeholder="{{ 'Kies een keuze'|translate }}"
                    class="flex-grow-1"
                    [items]="followUpChoices"
                    [clearable]="false">
                </ng-select>
            </div>
        </div>
        <div class="u-mb-m" *ngIf="form.controls['greaterThan'].enabled">
            <div [appFormControlError]="form.controls['greaterThan']" class="vwui-form-group required">
                <label class="vwui-label" for="greaterThan">{{'Min'|translate}}</label>
                <input
                    class="vwui-input"
                    id="greaterThan"
                    name="greaterThan"
                    formControlName="greaterThan"
                    autocomplete="off"
                    type="number"
                    placeholder="{{'Minimum waarde'|translate}}">
            </div>
        </div>

        <div class="u-mb-m" *ngIf="form.controls['lessThan'].enabled">
            <div [appFormControlError]="form.controls['lessThan']" class="vwui-form-group required">
                <label class="vwui-label" for="lessThan">{{'Max'|translate}}</label>
                <input
                    class="vwui-input"
                    id="lessThan"
                    name="lessThan"
                    formControlName="lessThan"
                    autocomplete="off"
                    type="number"
                    placeholder="{{'Maximum waarde'|translate}}">
            </div>
        </div>

        <div class="u-mb-m">
            <div [appFormControlError]="form.get('formType')" class="vwui-form-group mb-4">
                <label for="formType" class="vwui-label">{{'Formulier'|translate}}</label>
                <app-project-form-select *ngIf="project; else formTypeSelect" [projectFilter]="project"
                                         id="formType" formControlName="formType"></app-project-form-select>
                <ng-template #formTypeSelect>
                    <app-form-type-select  id="formType" formControlName="formType"></app-form-type-select>
                </ng-template>
            </div>
        </div>
        <div class="u-mb-m">
            <div [appFormControlError]="form.get('title')" class="vwui-form-group">
                <label class="vwui-label" for="title">{{'Opdrachtnaam'|translate}}</label>
                <input
                    class="vwui-input"
                    id="title"
                    name="title"
                    formControlName="title"
                    autocomplete="off"
                    type="text"
                    placeholder="{{'Opdrachtnaam'|translate}}">
            </div>
        </div>
        <div class="u-mb-m">
            <div [appFormControlError]="form.get('recoveryTime')" class="vwui-form-group">
                <label for="recoveryTime" class="vwui-label">{{'Hersteltermijn'|translate}}</label>
                <ng-select
                    id="recoveryTime"
                    formControlName="recoveryTime"
                    bindLabel="value"
                    bindValue="key"
                    placeholder="{{'Kies een hersteltermijn'|translate}}"
                    [items]="recoveryTimes"
                    [clearable]="true">
                </ng-select>
            </div>
        </div>
        <div *appHasFeatureToggle="maximoToggle" class="u-mb-m">
            <div [appFormControlError]="form.get('actionTaker')" class="vwui-form-group">
                <label for="actionTaker" class="vwui-label">{{'Actienemer'|translate}}</label>
                <ng-select
                    id="actionTaker"
                    formControlName="actionTaker"
                    bindLabel="label"
                    bindValue="value"
                    placeholder="{{'Actienemer'|translate}}"
                    [items]="actionTakers"
                    [clearable]="true">
                </ng-select>
            </div>
        </div>
        <button type="submit" class="d-none"><!-- Needed to submit with enter --></button>
    </form>
</app-modal>
