import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Company} from '../models/company';
import {CompanyService} from '../services/company.service';

@Injectable({
    providedIn: 'root'
})
export class CompanyResolver implements Resolve<Company> {
    constructor(
        @Inject('CompanyService') private companyService: CompanyService
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Company> {
        return this.companyService.getCompany(+route.params.id);
    }
}
