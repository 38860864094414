<app-modal [modalTitle]="getModalTitle()" modalSaveBtnLabel="{{'Kopieren'|translate}}" (save)="submit()"
           (cancel)="closeModal('cancelled')">
    <form>
        <div class="u-mb-m">
            <div [appFormControlError]="destinationControl" class="vwui-form-group mb-4">
                <label class="vwui-label">{{getModalDescription()}}</label>
                <ng-select [items]="chapters"
                           [hideSelected]="true"
                           [formControl]="destinationControl"
                           [multiple]="false"
                           [clearable]="true"
                           bindLabel="title"
                           placeholder="{{'kies een hoofdstuk'|translate}}"
                ></ng-select>
            </div>
        </div>
        <button type="submit" class="d-none"><!-- Needed to submit with enter --></button>
    </form>
</app-modal>

