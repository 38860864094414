// @ts-strict-ignore
import {Component, Inject, OnInit} from '@angular/core';
import {BsModalService} from 'ngx-bootstrap/modal';
import {
    AppleContentCodesImportModalComponent
} from '../../components/apple-content-codes-import-modal/apple-content-codes-import-modal.component';
import {Filters} from '../../utils/filters';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {TableFilter} from '../../components/table/table.component';
import {AppleContentCodeService} from '../../services/apple-content-code.service';
import {notificationThreshold} from '../../models/apple-content-code';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {AbstractListComponent} from "../abstract-list.component";
import {PageResponse} from "../../models/page-response";
import {AppleContentCodesImport} from "../../models/apple-content-code-import";

@Component({
    selector: 'app-apple-content-codes-list',
    templateUrl: './apple-content-codes-list.component.html'
})
export class AppleContentCodesListComponent extends AbstractListComponent<AppleContentCodesImport> implements OnInit {

    headerTitles = [
        {title: 'ID', sortKey: 'orderId', filterKey: 'orderId'},
        {title: 'Bestandsnaam', sortKey: 'uploadFilename', filterKey: 'uploadFilename'},
        {title: 'Aantal codes'},
        {title: 'Resterend'},
        {title: 'Uploaddatum', sortKey: 'createdAt', filterKey: 'createdAt'},
    ];
    appliedFilter: TableFilter;

    private amountOfAvailableCodes: number;

    constructor(
        private modalService: BsModalService,
        private toast: ToastrService,
        private translate: TranslateService,
        @Inject('AppleContentCodeService') public appleContentCodeService: AppleContentCodeService,
        activatedRoute: ActivatedRoute,
        router: Router,
    ) {
        super(activatedRoute, router);
    }

    async ngOnInit() {
        super.ngOnInit()
        this.subscriptions.push(
            this.activatedRoute.queryParams.subscribe((queryParams) => {
                if (queryParams) {
                    this.appliedFilter = Filters.getFilteringFromParams(queryParams);
                }
            }),
            this.appleContentCodeService.amountOfAvailableCodes$.subscribe((amountOfAvailableCodes) => {
                this.amountOfAvailableCodes = amountOfAvailableCodes;
                if (this.amountOfAvailableCodes < notificationThreshold && this.amountOfAvailableCodes !== null) {
                    this.openNotificationModal();
                }
            })
        );
    }

    loadData(page: number, filter: TableFilter, search: string | undefined): Observable<PageResponse<AppleContentCodesImport>> {
        return this.appleContentCodeService.getImports(page, filter);
    }

    openModal() {
        this.modalService.show(AppleContentCodesImportModalComponent, {
            class: 'modal-dialog-centered',
            initialState: {
                onClose: () => this.refresh()
            }
        });
    }

    openNotificationModal() {
        this.toast.error(this.translate.instant('Er zijn nog maar X contentcodes over', {amount: this.amountOfAvailableCodes}));
    }
}
