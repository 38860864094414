import {Component, forwardRef, Inject} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {EntitySelectComponent} from './item-select.component';
import {PageResponse} from '../../models/page-response';
import {ExecutionGroupService} from '../../services/execution-group.service';
import {ExecutionGroup} from '../../models/execution-group';

@Component({
    selector: 'app-execution-group-select',
    templateUrl: './item-select.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ExecutionGroupSelectComponent),
            multi: true
        },
    ]
})
export class ExecutionGroupSelectComponent extends EntitySelectComponent<ExecutionGroup, {}> {

    constructor(
        @Inject('ExecutionGroupService') private executionGroupService: ExecutionGroupService,
    ) {
        super();

        this.placeholder = 'Selecteer één of meerdere disciplines';
        this.multiple = true;
    }

    loadMore(page: number, filter: {term: string}): Observable<PageResponse<ExecutionGroup>> {
        return this.executionGroupService.getExecutionGroups(page, {
            filter: {
                title: filter && filter.term ? filter.term : ''
            }
        });
    }

    getLabelText(item: ExecutionGroup) {
        return item.title;
    }

    getFilter(): Observable<{}> {
        return of({});
    }
}
