import {AppleContentCodeService} from '../apple-content-code.service';
import {Observable, of} from 'rxjs';
import {TableFilter} from '../../components/table/table.component';
import {PageResponse} from '../../models/page-response';
import {AppleContentCode} from '../../models/apple-content-code';
import {AppleContentCodesImport} from '../../models/apple-content-code-import';
import {Injectable} from '@angular/core';

const imports = [{
    'orderId': 'MV64JZMLM3',
    'purchaser': 'Peter Kwarts <peter@recognize.nl>',
    'product': 'Asset Check, v0.8.8',
    'uploadFilename': 'Asset Check (1).xls',
    'totalCodes': 100,
    'unusedCodes': 100,
    'createdAt': new Date()
}];

@Injectable()
export class AppleContentCodeServiceMock implements AppleContentCodeService {
    amountOfAvailableCodes$: Observable<number> = of(12);
    getImports(page: number, filter?: TableFilter): Observable<PageResponse<AppleContentCodesImport>> {
        return of(
            {
                content: imports,
                empty: imports.length === 0,
                first: true,
                last: true,
                number: 0,
                numberOfElements: imports.length,
                size: 25,
                totalElements: imports.length,
                totalPages: 1
            });
    }

    uploadContentCodes(file: File): Observable<any> {
        return of();
    }

    activateCode(): Observable<AppleContentCode> {
        return of({
            id: 1,
            link: 'https://apple.link',
            code: '123456',
            used: true
        });
    }

    getCodeForUser(username: string): Observable<AppleContentCode> {
        return of({
            id: 1,
            link: 'https://apple.link',
            code: '123456',
            used: true
        });
    }

    changed(): void {
    }
}
