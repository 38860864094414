import {Component, Inject} from '@angular/core';
import {CompanyService} from '../../services/company.service';
import {AbstractListComponent} from "../abstract-list.component";
import {Company} from "../../models/company";
import {TableFilter} from "../../components/table/table.component";
import {Observable} from "rxjs";
import {PageResponse} from "../../models/page-response";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
    selector: 'app-company-list',
    templateUrl: './company-list.component.html'
})
export class CompanyListComponent extends AbstractListComponent<Company> {
    constructor(
        @Inject('CompanyService') public companyService: CompanyService,
        activatedRoute: ActivatedRoute,
        router: Router,
    ) {
        super(activatedRoute, router);
    }

    loadData(page: number, filter: TableFilter, search: string | undefined): Observable<PageResponse<Company>> {
        return this.companyService.getCompanies(page, filter);
    }
}
