// @ts-strict-ignore
import {Directive, ElementRef, HostListener, OnInit, Optional} from '@angular/core';
import {NgControl} from '@angular/forms';

@Directive({
  selector: '[appTextareaAutoresize]'
})
export class TextareaAutoresizeDirective implements OnInit {

    constructor(
        private elementRef: ElementRef,
        @Optional() private control: NgControl | null
    ) { }

    @HostListener('input')
    onInput() {
        if (this.control === null) {
            this.resize();
        }
    }

    ngOnInit() {
        this.control?.valueChanges.subscribe(() => {
            this.resize();
        });

        this.resize();
    }

    resize() {
        this.elementRef.nativeElement.style.height = 'auto';
        this.elementRef.nativeElement.style.overflow = 'hidden';
        this.elementRef.nativeElement.style.height = this.elementRef.nativeElement.scrollHeight + 'px';
    }
}
