import {Component, Inject} from "@angular/core";
import {SharepointCredentialService} from "../../services/sharepoint-credential.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Observable} from "rxjs";
import {TableFilter} from "../../components/table/table.component";
import {AbstractListComponent} from "../abstract-list.component";
import {PageResponse} from "../../models/page-response";
import {SharepointCredentialListDTO} from "../../models/sharepoint-credential";

@Component({
    selector: 'app-sharepoint-credential-list',
    templateUrl: './sharepoint-credential-list.component.html'
})
export class SharepointCredentialListComponent extends AbstractListComponent<SharepointCredentialListDTO> {
    tableHeaders = [
        {sortKey: 'title', filterKey: 'title', title: 'Titel'},
        {sortKey: 'hostname', filterKey: 'hostname', title: 'Domeinnaam'}
    ];

    constructor(
        @Inject('SharepointCredentialService') private sharepointCredentialService: SharepointCredentialService,
        protected activatedRoute: ActivatedRoute,
        protected router: Router
    ) {
        super(activatedRoute, router)
    }

    loadData(page: number, filter: TableFilter, search: string | undefined): Observable<PageResponse<SharepointCredentialListDTO>> {
        return this.sharepointCredentialService.getSharepointCredentials(page, filter)
    }
}
