// @ts-strict-ignore
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ObjectTypeService} from '../../services/object-type.service';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators} from '@angular/forms';
import {PaulaObjectType} from '../../models/paula-object-type';
import {Observable, Subscription} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {Subscriptions} from '../../utils/subscriptions';
import {Forms} from '../../utils/forms';
import {HistoryService} from "../../services/history.service";

@Component({
    selector: 'app-object-type-detail',
    templateUrl: './object-type-detail.component.html'
})
export class ObjectTypeDetailComponent implements OnInit, OnDestroy {

    form: UntypedFormGroup;
    objectType: PaulaObjectType;
    private subscriptions: Subscription[] = [];

    ObjectTypeStatusItems = [
        {label: 'Actief', value: 'Active'},
        {label: 'Concept', value: 'Concept'},
        {label: 'Vervallen', value: 'Cancelled'}
    ];

    constructor(
        private route: ActivatedRoute,
        @Inject('ObjectTypeService') private objectTypeService: ObjectTypeService,
        private toast: ToastrService,
        private router: Router,
        fb: UntypedFormBuilder,
        private historyService: HistoryService,
    ) {
        this.createForm(fb);
    }

    ngOnInit(): void {
        const routeDataSubscription = this.route.data.subscribe((data) => {
            if (data.objectType) {
                this.objectType = data.objectType;
                this.form.patchValue(this.objectType);
            }
        });
        this.subscriptions.push(routeDataSubscription);
    }

    ngOnDestroy(): void {
        Subscriptions.unsubscribeAll(this.subscriptions);
    }

    async save() {
        if (!this.form.valid) {
            Forms.updateValueAndValidityRecursive(this.form);
            this.toastInvalidForm();
            return;
        }

        const newObjectType = this.form.value as PaulaObjectType;

        try {
            let result;
            if (newObjectType.id) {
                result = await this.objectTypeService.putObjectType(newObjectType).toPromise();
            } else {
                result = await this.objectTypeService.postObjectType(newObjectType).toPromise();
            }
            this.toast.success('Opgeslagen');
            this.form.patchValue(result);

            this.goBack();
        } catch (ex) {
            console.error('Unable to save object type', ex);
            this.toast.error('Opslaan mislukt');
        }
    }

    cancel() {
        this.goBack();
    }

    goBack() {
        this.historyService.goBack(['/beheer/object-types']);
    }

    validateObjectTypeNotTaken(control: AbstractControl): Observable<ValidationErrors> {
        const currentId = +this.form.get('id').value;

        return Forms.validateNotTaken(control, currentId, this.objectTypeService.exists(control.value));
    }

    validateObjectTypeCodeNotTaken(control: AbstractControl): Observable<ValidationErrors> {
        const currentId = +this.form.get('id').value;

        return Forms.validateNotTaken(control, currentId, this.objectTypeService.codeExists(control.value));
    }

    private createForm(fb: UntypedFormBuilder) {
        this.form = fb.group({
            id: fb.control(null),
            title: fb.control(null, [Validators.required, Validators.maxLength(255)]),
            code: fb.control(null, [Validators.required, Validators.maxLength(255)]),
            description: fb.control(null, [Validators.maxLength(10000)]),
            companies: fb.control(null),
            status: fb.control(null, [Validators.required]),
        });

        this.form.get('title').asyncValidator = this.validateObjectTypeNotTaken.bind(this);
        this.form.get('code').asyncValidator = this.validateObjectTypeCodeNotTaken.bind(this);

    }

    private toastInvalidForm() {
        this.toast.error('Vul alle verplichte velden in (in het rood aangegeven)');
    }
}
