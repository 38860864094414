// @ts-strict-ignore
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {InterfaceProcessingErrorService} from '../../services/interface-processing-error.service';
import {TableFilter} from '../../components/table/table.component';
import {Filters} from '../../utils/filters';
import {ActivatedRoute, Router} from '@angular/router';
import {lastValueFrom, Observable, Subject, Subscription} from 'rxjs';
import {InterfaceProcessingError, InterfaceProcessingErrorType} from '../../models/interface-processing-error';
import {FeatureToggle} from '../../models/feature-toggle';
import {ToastrService} from 'ngx-toastr';
import {AbstractListComponent} from "../abstract-list.component";
import {mergeQueryParamsAndNavigate} from "../../utils/merge-query-params-and-navigate";
import {PageResponse} from "../../models/page-response";
import {Identifiable} from "../../models/identifiable";

@Component({
    selector: 'app-interface-processing-error-list',
    templateUrl: './interface-processing-error-list.component.html'
})
export class InterfaceProcessingErrorListComponent extends AbstractListComponent<InterfaceProcessingError> implements OnInit, OnDestroy {

    private queryParamSubscription: Subscription;
    private resetSelectionSubject = new Subject<void>();

    maximoToggle = FeatureToggle.Maximo;
    viseToggle = FeatureToggle.Vise;
    sharepointToggle = FeatureToggle.SharepointJobExport;

    resetSelection$ = this.resetSelectionSubject.asObservable();

    headerTitles = [
        {title: 'Referentie', filterKey: 'reference'},
        {title: 'Foutmelding', filterKey: 'message'},
        {title: 'Opgetreden op', filterKey: 'occurredAt'},
        {title: 'Richting', filterKey: 'direction'}
    ];
    filters = [
        {title: 'Alles', type: undefined},
        {title: 'Maximo', type: InterfaceProcessingErrorType.Maximo, featureToggle: this.maximoToggle},
        {title: 'Vise', type: InterfaceProcessingErrorType.Vise, featureToggle: this.viseToggle},
        {title: 'Quintiq', type: InterfaceProcessingErrorType.Quintiq},
        {title: 'Sharepoint', type: InterfaceProcessingErrorType.Sharepoint, featureToggle: this.sharepointToggle},
        {title: 'OpdrachtStatus', type: InterfaceProcessingErrorType.JobTransition},
    ];

    appliedFilter: TableFilter;
    bulkRetryable = false;

    constructor(
        @Inject('InterfaceProcessingErrorService') public interfaceProcessingErrorService: InterfaceProcessingErrorService,
        private toast: ToastrService,
        activatedRoute: ActivatedRoute,
        router: Router,
    ) {
        super(activatedRoute, router);
        this.reset();
    }

    loadData(page: number, filter: TableFilter): Observable<PageResponse<InterfaceProcessingError>> {
        return this.interfaceProcessingErrorService.getInterfaceProcessingErrors(page, filter);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.queryParamSubscription = this.activatedRoute.queryParams.subscribe((queryParams) => {
            if (queryParams) {
                this.appliedFilter = Filters.getFilteringFromParams(queryParams);
            }
        });
    }

    onItemClick(interfaceProcessingError: Identifiable) {
        this.router.navigate(['/beheer/interface-processing-errors/', interfaceProcessingError.id]);
    }

    async onResolveMultiple(processingErrors: Identifiable[]) {
        await this.onResolve(...processingErrors);
    }

    async onResolve(...processingErrors: Identifiable[]) {
        try {
            for (const error of processingErrors) {
                await lastValueFrom(this.interfaceProcessingErrorService.resolve(error.id));
            }
        } catch (error) {
            console.error('Unable to resolve, processing error: ', error);
            this.toast.error('Oplossen mislukt, probeer het later opnieuw');
        } finally {
            this.reset();
        }
    }

    async onDeleteMultiple(processingErrors: Identifiable[]) {
        await this.onDelete(...processingErrors);
    }

    async onDelete(...processingErrors: Identifiable[]) {
        try {
            for (const error of processingErrors) {
                await lastValueFrom(this.interfaceProcessingErrorService.deleteInterfaceProcessingError(error.id));
            }
        } catch (error) {
            console.error('Unable to delete, processing error: ', error);
            this.toast.error('Verwijderen mislukt, probeer het later opnieuw');
        } finally {
            this.reset();
        }
    }

    async onRetryMultiple(processingErrors: Identifiable[]) {
        await this.onRetry(...processingErrors);
    }

    async onRetry(...processingErrors: Identifiable[]) {
        try {
            for (const error of processingErrors) {
                await lastValueFrom(this.interfaceProcessingErrorService.retryInterfaceProcessingError(error.id));
            }
        } catch (error) {
            console.error('Unable to delete, processing error: ', error);
            this.toast.error('Opnieuw versturen mislukt, probeer het later opnieuw');
        } finally {
            this.reset();
        }
    }

    private reset() {
        mergeQueryParamsAndNavigate(
            this.router,
            this.activatedRoute,
            {page: 0}
        );
        this.refresh()

        this.resetSelectionSubject.next();
    }

    deactivateTypeFilter() {
        const queryParams = this.activatedRoute.snapshot.queryParamMap;
        const newQueryParams: { [key: string]: string } = {};

        for (const key of queryParams.keys) {
            if (key !== 'filter-type') {
                newQueryParams[key] = queryParams.get(key);
            }
        }

        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: newQueryParams,
        });
    }

    selectTypeFilter(type: undefined | InterfaceProcessingErrorType) {
        if (type === null || type === undefined) {
            this.deactivateTypeFilter();
            return;
        }

        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: {
                'filter-type': type,
            },
            queryParamsHandling: 'merge',
        });
    }

    onlySharepointErrorsSelected(selection: InterfaceProcessingError[]) {
        return selection.every(it => it.type === InterfaceProcessingErrorType.Sharepoint);
    }

    onSelectionChanged(items: InterfaceProcessingError[]) {
        this.bulkRetryable = items.every(it => it.retryable);
    }
}
