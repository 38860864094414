// @ts-strict-ignore
import {Injectable} from "@angular/core";
import {AbstractCrudServiceMock} from "./abstract-crud.service.mock";
import {PaulaObstructionCode} from "../../models/paula-obstruction-code";
import {ObstructionCodeService} from "../obstruction-code.service";
import {Observable, of} from "rxjs";
import {PageResponse} from "../../models/page-response";

export const mockObstructionCodes: Array<PaulaObstructionCode> = [
    {
        id: 1,
        code: 'OBC1001',
        name: 'Werkvoorbereiden',
    },
    {
        id: 2,
        code: 'OBC1004',
        name: 'Civiel aanleggen',
    }
]

@Injectable()
export class ObstructionCodeServiceMock extends AbstractCrudServiceMock<PaulaObstructionCode> implements ObstructionCodeService {

    constructor() {
        super(mockObstructionCodes)
    }

    getObstructionCodes(page: number): Observable<PageResponse<PaulaObstructionCode>> {
        return this.getAll();
    }

    getObstructionCode(id: number): Observable<PaulaObstructionCode> {
        return this.get(id);
    }

    exists(code: string): Observable<number> {
        const result = mockObstructionCodes.find(item => item.code == code)

        return of(result ? result.id: null)
    }

    postObstructionCode(obstructionCode: PaulaObstructionCode): Observable<PaulaObstructionCode> {
        return this.post(obstructionCode);
    }

    putObstructionCode(obstructionCode: PaulaObstructionCode): Observable<PaulaObstructionCode> {
        return this.put(obstructionCode)
    }
}
