import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {Project} from '../../models/project';
import {SharepointService} from '../sharepoint.service';

@Injectable()
export class SharepointServiceMock implements SharepointService {
    testConnection(project: Project): Observable<string> {
        return of('Connected');
    }
}
