<div class="vwui-card">
    <div class="vwui-card__header">{{'Vragensets'|translate}}</div>
    <div class="vwui-card__scroll">
        <div class="vwui-list">
            <div *ngFor="let questionSet of filteredQuestionSets$|async"
                 class="vwui-list-item has-action"
                 [class.is-selected]="isSelected(questionSet)"
                 [class.collapsible-open]="isSelected(questionSet)"
                 (click)="selectQuestionSet(questionSet)">
                <div class="vwui-list-item__content">{{ questionSet.title }}</div>
                <div class="vwui-list-item__content-collapsible" *ngIf="isSelected(questionSet)">
                    <app-question-set-items [questionSet]="questionSet"></app-question-set-items>
                </div>
            </div>
            <div class="vwui-list-item" *ngIf="paginator.hasMore">
                <div class="vwui-list-item__content justify-content-center">
                    <button class="vwui-btn" (click)="paginator.loadMore()">{{'Meer laden'|translate}}</button>
                </div>
            </div>
        </div>
    </div>
    <div class="vwui-card__footer">
        <button class="vwui-btn" (click)="close()">{{'Sluiten'|translate}}</button>
        <button class="vwui-btn is-primary" (click)="addSelectedQuestionSet()" [disabled]="!selectedQuestionSet">
            {{'Aan formulier toevoegen'|translate}}
        </button>
    </div>
</div>
