// @ts-strict-ignore
import {UserService} from '../user.service';
import {Injectable} from '@angular/core';
import {AbstractCrudServiceMock} from './abstract-crud.service.mock';
import {Observable, of} from 'rxjs';
import {User, UserProject, UserRoles} from '../../models/user';
import {PageResponse} from '../../models/page-response';
import {mockProjects} from './project.service.mock';
import {mockCompanies} from './company.service.mock';
import {SearchFilter, TableFilter} from '../../components/table/table.component';

export const mockUsers: Array<User> = [
    {
        id: 2,
        username: 'j.hobert@recognize.nl',
        mail: 'j.hobert@recognize.nl',
        displayName: 'Juul Hobert',
        company: mockCompanies[0],
        roles: [UserRoles.RoleMasterDataAdmin],
        authenticationType: 'Azure',
        tenantId: 'mocktenantid',
    },
    {
        id: 1,
        username: 'b.wesselink@recognize.nl',
        mail: 'b.wesselink@recognize.nl',
        displayName: 'Bart Wesselink',
        company: mockCompanies[1],
        roles: [UserRoles.RoleExecutor],
        authenticationType: 'Azure',
        tenantId: 'mocktenantid',
    }
];

export const inactiveUser: User = {
    id: 3,
    username: 't.reinders@recognize.nl',
    mail: 't.reinders@recognize.nl',
    displayName: 'Tom Reinders',
    company: mockCompanies[1],
    roles: [],
    authenticationType: 'Azure',
    tenantId: 'mocktenantid',
};

export const mockUserProjects: UserProject[] = [
    {
        project: mockProjects[0],
        roles: [UserRoles.RoleExecutor, UserRoles.RoleCoordinator]
    }
];

@Injectable()
export class UserServiceMock extends AbstractCrudServiceMock<User> implements UserService {

    constructor() {
        super(mockUsers);
    }

    getUsers(page: number, filter?: TableFilter): Observable<PageResponse<User>> {
        // Filter on empty roles gives inactive users
        if (filter.filter.roles === 'ROLE_INACTIVE') {
            const data = [inactiveUser];

            return of(
                {
                    content: data,
                    empty: data.length === 0,
                    first: true,
                    last: true,
                    number: 0,
                    numberOfElements: data.length,
                    size: 25,
                    totalElements: data.length,
                    totalPages: 1
                });
        }

        return this.getAll(filter);
    }

    getUsersByRole(role: UserRoles): Observable<PageResponse<User>> {
        const searchFilter: SearchFilter = {
            roles: role,
        };

        const filter: TableFilter = {
            sort: null,
            filter: searchFilter
        };

        return this.getUsers(0, filter);
    }

    getUserByUsername(username: string): Observable<User> {
        return of(this.data.find(item => item.username === username));
    }

    getUser(id: number): Observable<User> {
        if (id === 3) {
            return of(inactiveUser);
        }

        return this.get(id);
    }

    postUser(user: User): Observable<User> {
        return this.post(user);
    }

    putUser(user: User): Observable<User> {
        return this.put(user);
    }

    exists(username: string): Observable<number> {
        const result = mockUsers.find(item => item.username === username);

        return of(result ? result.id : null);
    }

    getUserProjects(id: number): Observable<UserProject[]> {
        return of(mockUserProjects);
    }

    decoupleContentCode(id: number): Observable<any> {
        return of(true);
    }
}
