export const nlLanguage = {
    '"WONUM" cannot be empty': '"WONUM" mag niet leeg zijn.',
    'Aan formulier toevoegen': 'Aan formulier toevoegen',
    'Aanbevolen resolutie 1035x460 (aspect ratio 9:4)': 'Aanbevolen resolutie 1035x460 (aspect ratio 9:4)',
    'Aantal cijfers achter de komma': 'Aantal cijfers achter de komma',
    'Aantal medewerkers': 'Aantal medewerkers',
    'Aantal vragen': 'Aantal vragen',
    'Actienemer': 'Actienemer',
    'Afbeelding opgeslagen': 'Afbeelding opgeslagen',
    'Afbeelding opslaan mislukt': 'Afbeelding opslaan mislukt',
    'Afhankelijk van': 'Afhankelijk van',
    'Afhankelijkheid': 'Afhankelijkheid',
    'Afkeuren': 'Afkeuren',
    'Algemeen': 'Algemeen',
    'Als iemand anders inloggen': 'Als iemand anders inloggen',
    'Annuleren': 'Annuleren',
    'Antwoord(en)': 'Antwoord(en)',
    'Antwoorden': 'Antwoorden',
    'An unexpected error occurred during the sync': 'Onverwachte error tijdens synchronisatie',
    'Apple contentcodes toevoegen': 'Apple contentcodes toevoegen',
    'Apple contentcodes': 'Apple contentcodes',
    'Applicatiebeheerders ontvangen een waarschuwingsbericht per e-mail, zodra er minder dan X contentcodes over zijn.': 'Applicatiebeheerders ontvangen een waarschuwingsbericht per e-mail, zodra er minder dan {{amount}} contentcodes over zijn.',
    'Authentication failed. Project: {{projectName}}. Job: {{projectJobCode}}. Site: {{siteId}}': 'Authenticatie mislukt. Project: {{projectName}}. Opdracht: {{projectJobCode}}. Sharepoint site: {{siteId}}',
    'Azure': 'Azure',
    'Bedrijf': 'Bedrijf',
    'Bedrijfnaam': 'Bedrijfsnaam',
    'Bedrijven': 'Bedrijven',
    'Beheerderstoestemming verlenen': 'Beheerderstoestemming verlenen',
    'Beheerderstoestemming': 'Beheerderstoestemming',
    'Bewerken': 'Bewerken',
    'Chapter does not exist': 'Er is een ontbrekende of ongeldige verwijzing naar een hoofdstuk op werkblad {{sheet}}, cel {{column}}{{row}}. Controleer het bestand',
    'Chapter is not unique': 'Hoofdstuk komt meermaals voor op werkblad {{sheet}}, rij {{row}}. Controleer het bestand',
    'Choice does not exist': 'Er is een ontbrekende verwijzing naar een keuze op werkblad {{sheet}}, rij {{row}}. Controleer het bestand',
    'Code already in use': 'Formuliernummer bestaat al.',
    'Code geactiveerd': 'Code geactiveerd',
    'Code is bij een eerdere import al geïmporteerd': 'Code is bij een eerdere import al geïmporteerd',
    'Column: {{column}} not found in site: {{siteId}}. Project: {{projectName}}. Job: {{projectJobCode}}': 'Metadatakolom "{{column}}" bestaat niet op Sharepoint site {{siteId}}. Project: {{projectName}}. Opdracht: {{projectJobCode}}.',
    'Company does not exists': 'Bedrijf {{value}} bestaat niet. Voeg het bedrijf handmatig toe en lees de sheet opnieuw in',
    'Contact person does not exists': 'Contactpersoon {{value}} is geen {{applicationTitle}} gebruiker. Pas de contactpersoon aan of laat deze registeren als gebruiker',
    'Contactpersoon': 'Contactpersoon',
    'Contentcodes importeren': 'Contentcodes importeren',
    'Content type: {{contentType}} not found in site: {{siteId}}. Project: {{projectName}}. Job: {{projectJobCode}}': 'Contenttype "{{contentType}}" bestaat niet op Sharepoint site {{siteId}}. Project: {{projectName}}. Opdracht: {{projectJobCode}}.',
    'Coordinator': 'Coordinator',
    'Coördinatoren': 'Coördinatoren',
    'Data can\'t be read. Make sure you\'re using the Asset Check import template without changing the structure.': 'Bestand kan niet ingelezen worden. Maak gebruik van het Asset Check importsjabloon zonder hier wijzigingen op toe te passen',
    'Datum': 'Datum',
    'De volgende tekens mogen niet gebruikt worden: " * : < > ? |': 'De volgende tekens mogen niet gebruikt worden: " * : < > ? |',
    'Deze vraag bevat privacy gevoelige informatie': 'Deze vraag bevat privacy gevoelige informatie',
    'Geen inloggegevens gevonden voor domein': 'Geen inloggegevens gevonden voor domein {{domain}}',
    'Discipline': 'Discipline',
    'Disciplinenaam': 'Disciplinenaam',
    'Disciplines(optioneel)': 'Disciplines(optioneel)',
    'Download': 'Download',
    'E - mailadres': 'E-mailadres',
    'Empty KTA-ID, it must be non-empty (reference: {{updateId}})': 'Leeg KTA-ID, dit mag niet leeg zijn (referentienummer: {{updateId}})',
    'Er is iets mis gegaan bij het downloaden van de import template': 'Er is iets mis gegaan bij het downloaden van de import template',
    'Er is iets mis gegaan bij het importeren van formulieren': 'Er is iets mis gegaan bij het importeren van formulieren',
    'Er zijn geen groepen gevonden': 'Er zijn geen groepen gevonden',
    'Er zijn geen iOS codes beschikbaar op dit moment': 'Er zijn geen iOS codes beschikbaar op dit moment',
    'Er zijn geen items gevonden': 'Er zijn geen items gevonden',
    'Er zijn geen medewerkers gevonden': 'Er zijn geen medewerkers gevonden',
    'Er zijn geen resultaten': 'Er zijn geen resultaten',
    'Er zijn minder dan 20 contentcodes over': 'Er zijn minder dan 20 contentcodes over',
    'Er zijn nog geen keuzes toegevoegd': 'Er zijn nog geen keuzes toegevoegd',
    'Er zijn nog maar X contentcodes over': 'Er zijn nog maar {{amount}} contentcodes over',
    'Excel bestand': 'Excel bestand',
    'Exporteer naar Sharepoint': 'Exporteer naar Sharepoint',
    'Exporteren van de opdracht is mislukt.': 'Exporteren van de opdracht is mislukt.',
    'Exporteren van opdrachten is mislukt.': 'Exporteren van opdrachten is mislukt.',
    'File is empty': 'Bestand is leeg',
    'File is encrypted and can\'t be read': 'Bestand is versleuteld en kan niet ingelezen worden',
    'Form type does not exists': 'Er is een ontbrekende of ongeldige verwijzing naar een formulier op werkblad {{sheet}}, cel {{column}}{{row}}. Controleer het bestand',
    'Form type is not unique': 'Formulier in sheet {{sheet}} met rijnummer {{row}} bestaat al. Pas het formuliernummer en / of revisienummer aan',
    'Formulier bekijken': 'Formulier bekijken',
    'Formulier exporteren naar PDF': 'Formulier exporteren naar PDF',
    'Formulier kopieren': 'Formulier kopiëren',
    'Formulier toevoegen': 'Formulier toevoegen',
    'Meerdere formulieren toevoegen': 'Meerdere formulieren toevoegen',
    'Formulier': 'Formulier',
    'Formulieren importeren': 'Formulieren importeren',
    'Formulieren zijn succesvol geïmporteerd': 'Formulieren zijn succesvol geïmporteerd',
    'Formulieren': 'Formulieren',
    'Formuliernaam': 'Formuliernaam',
    'Formuliernummer is reeds in gebruik': 'Formuliernummer is reeds in gebruik',
    'Formuliernummer': 'Formuliernummer',
    'Foto': 'Foto',
    'GPS coördinaten': 'GPS coördinaten',
    'GUID "{{guid}}" is not a valid UUID format': 'GUID "{{guid}}" is geen geldig UUID formaat',
    'Geavanceerd': 'Geavanceerd',
    'Gebruiker': 'Gebruiker',
    'Gebruikers': 'Gebruikers',
    'Geen beheerderstoestemming': 'Geen beheerderstoestemming',
    'Geen opdrachten met locatie': 'Geen opdrachten met locatie',
    'Geen toegang': 'Geen toegang',
    'Gekoppeld formulier': 'Gekoppeld formulier',
    'Gemiddelde tolerantie': 'Gemiddelde tolerantie',
    'Gemiddelde': 'Gemiddelde',
    'Geo': 'Geo',
    'Geplande startdatum - tijd': 'Geplande startdatum - tijd',
    'Gerealiseerde verlenging(mm)': 'Gerealiseerde verlenging(mm)',
    'Gewijzigd': 'Gewijzigd',
    'Goedkeuren': 'Goedkeuren',
    'Groepen': 'Groepen',
    'Groepnaam': 'Groepnaam',
    'Handtekening': 'Handtekening',
    'Header {{headerName}} in sheet {{sheet}} is invalid': 'Header {{headerName}} ontbreekt in sheet {{sheet}}',
    'Helptekst': 'Helptekst',
    'Hersteltermijn': 'Hersteltermijn',
    'Het is wel mogelijk gebruik te maken van de kaart, maar hierop zullen geen opdrachten zichtbaar zijn': 'Het is wel mogelijk gebruik te maken van de kaart, maar hierop zullen geen opdrachten zichtbaar zijn',
    'Het vraagtype kan niet meer gewijzigd worden, nadat deze is opgeslagen': 'Het vraagtype kan niet meer gewijzigd worden, nadat deze is opgeslagen.',
    'Hoofdfilter': 'Hoofdfilter',
    'Hoofdstuk': 'Hoofdstuk',
    'Ik heb het begrepen': 'Ik heb het begrepen',
    'Image was not included in the import data': 'De afbeelding voor rij {{row}} op werkblad {{sheet}} kon niet gevonden worden in het zip bestand. Controleer de bestandsnamen',
    'Image type unsupported': 'Bestandstype {{imageType}} van afbeelding {{imageName}} wordt niet ondersteund',
    'Importeren': 'Importeren',
    'Importeer formulieren': 'Importeer formulieren',
    'Inkomend': 'Inkomend',
    'Inloggen geslaagd, deze popup sluit automatisch': 'Inloggen geslaagd, deze popup sluit automatisch',
    'Inloggen met Azure': 'Inloggen met Azure',
    'Inloggen': 'Inloggen',
    'Inlogknop achtergrond links': 'Inlogknop achtergrond links',
    'Inlogknop achtergrond rechts': 'Inlogknop achtergrond rechts',
    'Inlogknop tekstkleur': 'Inlogknop tekstkleur',
    'Inlogmethode': 'Inlogmethode',
    'Inlogmethodes': 'Inlogmethodes',
    'Inspectieformulier': 'Keuringsformulier',
    'Invalid import value - ALL': 'Waarde {{value}} in werkblad {{sheet}}, cel {{column}}{{row}} is ongeldig. Controleer het bestand',
    'Invalid import value - ROW': 'werkblad {{sheet}}, rij {{row}} is ongeldig. Controleer het bestand',
    'Is al eerder geïmporteerd, Order ID is niet uniek': 'Is al eerder geïmporteerd, Order ID is niet uniek',
    'Je bent uitgelogd': 'Je bent uitgelogd',
    'Job title change to "{{jobTitle}}" is not allowed for a job with status "{{status}}"': 'Opdrachtnaam wijzigen naar "{{jobTitle}}" is niet toegestaan voor een opdracht met de status "{{status}}"',
    'KTA-ID "{{kta_id}}" is already in use, it should be unique': 'KTA-ID "{{kta_id}}" is reeds in gebruik, dit moet uniek zijn',
    'Kaart bekijken': 'Kaart bekijken',
    'Kaartlaag': 'Kaartlaag',
    'Kaartlagen': 'Kaartlagen',
    'Keurder': 'Keurder',
    'Keuze': 'Keuze',
    'Keuzes': 'Keuzes',
    'Kies bestand': 'Kies bestand',
    'Kies een hersteltermijn': 'Kies een hersteltermijn',
    'Kies een keuze': 'Kies een keuze',
    'Kies een logo': 'Kies een logo',
    'Kies een opdrachtgever': 'Kies een opdrachtgever',
    'Kies een rapportage sjabloon': 'Kies een rapportage sjabloon',
    'Kies een tekenset': 'Kies een tekenset',
    'Kies een type formule': 'Kies een type formule',
    'Kies een vraag': 'Kies een vraag',
    'Kies het hoofdstuk waarnaar de vraag moet worden gekopieerd': 'Kies het hoofdstuk waarnaar de vraag moet worden gekopieerd',
    'Kies het hoofdstuk waarachter dit hoofstuk moet worden gekopieerd': 'Kies het hoofdstuk waarachter dit hoofstuk moet worden gekopieerd',
    'Klik hieronder om in te loggen met uw account': 'Klik hieronder om in te loggen met uw account',
    'Klik op hoofdstuk om te beginnen met het formulier': 'Klik op hoofdstuk om te beginnen met het formulier',
    'Klik op vragenset of hoofdstuk om te beginnen met het formulier': 'Klik op vragenset of hoofdstuk om te beginnen met het formulier',
    'Kopieer formulier': 'Kopieer formulier',
    'Kopieren': 'Kopiëren',
    'Koppel een vervolgopdracht aan een keuze': 'Koppel een vervolgopdracht aan een keuze',
    'Koppelen': 'Koppelen',
    'Lengte(m)': 'Lengte(m)',
    'Let op: Alleen het geselecteerde venster wordt weergegeven in de app': 'Let op: Alleen het geselecteerde venster wordt weergegeven in de app',
    'Let op: Er zijn geen opdrachten met een locatie beschikbaar in het huidige project': 'Let op: Er zijn geen opdrachten met een locatie beschikbaar in het huidige project',
    'Locatie': 'Locatie',
    'Login': 'Login',
    'Master databeheer': 'Master databeheer',
    'Maximaal 100 karakters': 'Maximaal 100 karakters',
    'Maximaal 4500 karakters': 'Maximaal 4500 karakters',
    'Maximum waarde': 'Maximum waarde',
    'Medewerker toevoegen': 'Medewerker toevoegen',
    'Medewerker': 'Medewerker',
    'Medewerkers': 'Medewerkers',
    'Medewerkersgroep': 'Medewerkersgroep',
    'Medewerkersgroepen beheren': 'Medewerkersgroepen beheren',
    'Medewerkersgroepnaam': 'Medewerkersgroepnaam',
    'Meer laden': 'Meer laden',
    'Meerdere antwoorden mogelijk': 'Meerdere antwoorden mogelijk',
    'Meerdere regels': 'Meerdere regels',
    'Minimum waarde': 'Minimum waarde',
    'Multiple form types with same code and revision number exist': 'De formulierverwijzing op werkblad {{sheet}}, cel {{column}}{{row}} is niet uniek, er bestaan meerdere formulieren met dezelfde combinatie van formuliernummer en revisie',
    'Naam': 'Naam',
    'Naar uitvoering': 'Naar uitvoering',
    'Nieuw bedrijf': 'Nieuw bedrijf',
    'Nieuw objecttype': 'Nieuw objecttype',
    'Nieuw project': 'Nieuw project',
    'Nieuwe gebruiker': 'Nieuwe gebruiker',
    'Nieuwe inlogmethode': 'Nieuwe inlogmethode',
    'Nieuwe medewerkersgroep': 'Nieuwe medewerkersgroep',
    'Nieuwe opdracht': 'Nieuwe opdracht',
    'Nieuwe vragenset': 'Nieuwe vragenset',
    'Nog geen inhoud toegevoegd': 'Nog geen inhoud toegevoegd',
    'Nog geen medewerkers toegevoegd': 'Nog geen medewerkers toegevoegd',
    'Object "{{paula_object_identifier}}" does not exists in project "{{project_form_identifier}}" and could not be created based on object type': 'Object met ObjectID "{{paula_object_identifier}}" bestaat niet in project "{{project_form_identifier}}" en kon niet aangemaakt worden op basis van een objecttype.',
    'Object "{{paula_object_identifier}}" is invalid. Probably the object type does not match with the form': 'Object "{{paula_object_identifier}}" is ongeldig. Waarschijnlijk komt het object-type van dit object niet overeen met het object-type van het formulier.',
    'Object ID': 'Object ID',
    'Object informatie': 'Object informatie',
    'Object toevoegen': 'Object toevoegen',
    'Object type does not exists': 'Objecttype {{value}} bestaat niet. Voeg het objecttype handmatig toe en lees de sheet opnieuw in',
    'Object': 'Object',
    'Objecten exporteren': 'Objecten exporteren',
    'Objecten importeren': 'Objecten importeren',
    'Objecten': 'Objecten',
    'Objecttype "{{object_type}}" does not exists': 'Objecttype "{{object_type}}" bestaat niet binnen het systeem.',
    'Objecttype ID': 'Objecttype ID',
    'Objecttype': 'Objecttype',
    'Objecttypenaam': 'Objecttypenaam',
    'Objecttypes': 'Objecttypes',
    'Om het vraagtype te wijzigen dient u de vraag te verwijderen en opnieuw toe te voegen': 'Om het vraagtype te wijzigen dient u de vraag te verwijderen en opnieuw toe te voegen',
    'Omschrijving locatie': 'Omschrijving locatie',
    'Omschrijving locatie tot': 'Omschrijving locatie tot',
    'Omschrijving locatie van': 'Omschrijving locatie van',
    'Omschrijving': 'Omschrijving',
    'Onderhoudscontract': 'Onderhoudscontract (Maximo)',
    'Opdracht export e-mailadres': 'Opdracht export e-mailadres',
    'Opdrachteigenschappen': 'Opdrachteigenschappen',
    'Opdrachten exporteren': 'Opdrachten exporteren',
    'Opdrachten importeren': 'Opdrachten importeren',
    'Opdrachten op kaart bekijken': 'Opdrachten op kaart bekijken',
    'Opdrachten in de lijst bekijken': 'Opdrachten in de lijst bekijken',
    'Opdrachtgever': 'Opdrachtgever',
    'Opdrachtgevers': 'Opdrachtgevers',
    'Opdrachtinformatie': 'Opdrachtinformatie',
    'Opdrachtnaam': 'Opdrachtnaam',
    'Opdrachtnummer': 'Opdrachtnummer',
    'Opdrachtnummer moet uniek zijn': 'Opdrachtnummer moet uniek zijn',
    'OpenID Connect configuratie-url': 'OpenID Connect configuratie-url',
    'OpenID Connect': 'OpenID Connect',
    'Opgelost': 'Opgelost',
    'Opmerking': 'Opmerking',
    'Opslaan': 'Opslaan',
    'Paula': 'Paula',
    'Project "{{project_identifier}}" does not exists': 'Project met projectnummer "{{project_identifier}}" bestaat niet binnen het systeem.',
    'Project actief': 'Project actief',
    'Project exporteren': 'Project exporteren',
    'Project form "{{project_form_identifier}}" does not exists': 'Formulier met formuliernummer "{{project_form_identifier}}" bestaat niet binnen het project.',
    'Project': 'Project',
    'Project: {{projectName}} not configured correctly for Sharepoint export. Job: {{projectJobCode}}. Site: {{siteId}}': 'De Sharepoint-export is bij project: {{name}} niet correct ingesteld. Opdracht: {{projectJobCode}}. Sharepoint site: {{siteId}}.',
    'Projecten': 'Projecten',
    'Projectinformatie': 'Projectinformatie',
    'Projectkaart openen': 'Projectkaart openen',
    'Projectnaam is verplicht': 'Projectnaam is verplicht',
    'Projectnaam': 'Projectnaam',
    'Projectnummer is reeds in gebruik': 'Projectnummer is reeds in gebruik',
    'Projectnummer is verplicht': 'Projectnummer is verplicht',
    'Projectnummer': 'Projectnummer',
    'Projectteam': 'Projectteam',
    'Question does not exist': 'Er is een ontbrekende of ongeldige verwijzing naar een vraag op werkblad {{sheet}}, cel {{column}}{{row}}. Controleer het bestand',
    'Question is not unique': 'Vraag komt meermaals voor op werkblad {{sheet}}, rij {{row}}. Controleer het bestand',
    'ROLE_COORDINATOR': 'Coördinator',
    'ROLE_EXECUTOR': 'Uitvoering',
    'Rapport': 'Rapport',
    'Rapportage sjabloon': 'Rapportage sjabloon',
    'Received error report from Talend: {{message}}': 'Foutrapportage ontvangen vanuit Talend: {{message}}',
    'Received unsuccessful response from Talend: {{message}}': 'Formulier niet correct ontvangen door Talend: {{message}}',
    'Revisienummer': 'Revisienummer',
    'Rollen': 'Rollen',
    'Selecteer een coördinator om deze toegang te verlenen tot het project': 'Selecteer een coördinator om deze toegang te verlenen tot het project',
    'Selecteer een excelsheet': 'Selecteer een excelsheet',
    'Selecteer een project': 'Selecteer een project',
    'Selecteer een zip bestand met afbeeldingen': 'Selecteer een zip bestand met afbeeldingen',
    'Selecteer hieronder het project dat u op de kaart wilt bekijken': 'Selecteer hieronder het project dat u op de kaart wilt bekijken',
    'Selecteer medewerkers of groepen om het projectteam samen te stellen': 'Selecteer medewerkers of groepen om het projectteam samen te stellen',
    'Selecteer medewerkers om medewerkersgroepen samen te stellen': 'Selecteer medewerkers om medewerkersgroepen samen te stellen',
    'Service-account': 'Service-account',
    'Sharepoint export-map is verplicht': 'Sharepoint export-map is verplicht',
    'Sharepoint site {{siteId}} not found. Project: {{projectName}}. Job: {{projectJobCode}}': 'Sharepoint-site {{siteId}} niet gevonden. Project: {{projectName}}. Opdracht: {{projectJobCode}}',
    'Syncing job update failed': 'Synchroniseren van opdrachtwijziging mislukt.',
    'Sharepoint site-ID is verplicht': 'Sharepoint site-ID is verplicht',
    'Sheet can\'t have more than {{maxRows}}': 'Sheet heef teveel rijen (maximaal {{maxRows}} toegestaan)',
    'Sluiten': 'Sluiten',
    'Something went wrong: {{message}}': 'Onbekende fout: {{message}}',
    'Something went wrong: {{message}}. Project: {{projectName}}. Job: {{projectJobCode}}. Site: {{siteId}}': 'Onbekende fout: {{message}}. Project: {{projectName}}. Opdracht: {{projectJobCode}}. Sharepoint site: {{siteId}}.',
    'Statuswijzigingen importeren': 'Statuswijzigingen importeren',
    'Soort inlogmethode': 'Soort inlogmethode',
    'Standaard PDF-rapportage': 'Standaard PDF-rapportage',
    'Standaard antwoord invullen': 'Standaard antwoord invullen',
    'Standaard antwoord': 'Standaard antwoord',
    'Standaard project toelichting': '(gebruikers van deze inlogmethode worden automatisch lid van het projectteam van het standaard-project na inloggen)',
    'Standaard project': 'Standaard project',
    'Status': 'Status',
    'Statussen zijn succesvol bijgewerkt': 'Statussen zijn succesvol bijgewerkt',
    'Statusdatum - tijd': 'Statusdatum - tijd',
    'Subfilter': 'Subfilter',
    'Tabelvraaglabel': 'Tabelvraaglabel',
    'Te verlengen(mm)': 'Te verlengen(mm)',
    'Tekenset': 'Tekenset',
    'Tekst': 'Tekst',
    'Temperatuur(°C)': 'Temperatuur(°C)',
    'Tijd': 'Tijd',
    'Titel': 'Titel',
    'Toevoegen': 'Toevoegen',
    'Toon een logo in de export.': 'Toon een logo in de export.',
    'Toon opdracht locatie in de export': 'Toon opdracht locatie in de export',
    'Totaal': 'Totaal',
    'Type vraag': 'Type vraag',
    'U bent niet gemachtigd om deze pagina te bekijken': 'U bent niet gemachtigd om deze pagina te bekijken',
    'Uitgaand': 'Uitgaand',
    'Unable to sync project form, Talend may be offline or responded with a http status other than 2xx': 'Fout bij het synchroniseren van een projectspecifiekformulier, Talend is mogelijk offline of heeft geantwoord met een statuscode anders dan 2xx',
    'Unauthorized for site {{sitedId}}. Project: {{projectName}}. Job: {{projectJobCode}}': 'Geen toegang tot Sharepoint site {{siteId}}. Project: {{projectName}}. Opdracht: {{projectJobCode}}',
    'Upload exceeds the maximum file size {{max}}': 'De upload overschrijdt de maximale bestandsgrootte van {{max}}',
    'Uploaden': 'Uploaden',
    'Variablen': 'Variablen',
    'Verplicht': 'Verplicht',
    'Vervallen': 'Vervallen',
    'Vervolgopdracht koppelen': 'Vervolgopdracht koppelen',
    'Vervolgopdracht': 'Vervolgopdracht',
    'Verwijder': 'Verwijder',
    'Verwijderen': 'Verwijderen',
    'Voeg een foto toe door hier te klikken of hier naar toe te slepen': 'Voeg een foto toe door hier te klikken of hier naar toe te slepen.',
    'Voeg eerst een keuze toe aan deze vraag om een tolerantie in te kunnen stellen': 'Voeg eerst een keuze toe aan deze vraag om een tolerantie in te kunnen stellen.',
    'Voeg hoger in het formulier een keuzevraag toe om hier een afhankelijkheid van een eerder antwoord in te kunnen stellen': 'Voeg hoger in het formulier een keuzevraag toe om hier een afhankelijkheid van een eerder antwoord in te kunnen stellen.',
    'Voorafgaande opdracht': 'Voorafgaande opdracht',
    'Voorafgaande vraag': 'Voorafgaande vraag',
    'Vraag locatie (uit / aan)': 'Vraag locatie (uit / aan)',
    'Vraag': 'Vraag',
    'Vraagreferentie': 'Interne vraagreferentie',
    'Vraagreferentie moet uniek zijn binnen het formulier': 'Vraagreferentie moet uniek zijn binnen het formulier',
    'Vragen beheren': 'Vragen beheren',
    'Vragenset verwijderen': 'Vragenset verwijderen',
    'Vragenset': 'Vragenset',
    'Vragensetnaam': 'Vragensetnaam',
    'Vragensets': 'Vragensets',
    'Vul de contentcodes aan om dit bericht niet meer te krijgen': 'Vul de contentcodes aan om dit bericht niet meer te krijgen',
    'Waar moet het aan voldoen?': 'Waar moet het aan voldoen?',
    'Waardes': 'Waardes',
    'Waarschuwing: Indien er een formulier word geimporteerd met dezelfde code als een bestaand formulier, dan zullen alle vragen vervangen worden door de vragen uit de import': 'Waarschuwing: Indien er een formulier word geimporteerd met dezelfde code als een bestaand formulier, dan zullen alle vragen vervangen worden door de vragen uit de import',
    'Werkgever': 'Werkgever',
    'Werkordernummer': 'Werkordernummer',
    'Zoek op achternaam of e-mailadres': 'Zoek op achternaam of e-mailadres',
    'Zoeken': 'Zoeken',
    'application(client) ID': 'application(Client) ID',
    'current job status doesn\'t allow sending mail': 'Versturen is niet mogelijk voor de huidige opdracht-status',
    'directory(tenant) ID': 'directory(tenant) ID',
    'iOS code activeren mislukt': 'iOS code activeren mislukt',
    'iOS code wordt geactiveerd': 'iOS code wordt geactiveerd',
    'job.export.resend': 'Opdracht export e-mail opnieuw versturen',
    'job.export.resend.success': 'Opdracht export e-mail is verstuurd',
    'jobNotifyEmail is empty': 'Opdracht export e-mailadres van het project is leeg',
    'kies een hoofdstuk': 'kies een hoofdstuk',
    'project.sharepoint.connection_status.Connected': 'Verbonden',
    'project.sharepoint.connection_status.Disabled': 'Uitgezet',
    'project.sharepoint.connection_status.SiteNotFound': 'Site niet gevonden',
    'project.sharepoint.connection_status.Unauthenticated': 'Authenticatie mislukt',
    'project.sharepoint.connection_status.Unauthorized': 'Geen toegang',
    'project.sharepoint.connection_status.Unknown': 'Onbekend',
    'project_form.create.conflict': 'Dit formulier kon niet toegevoegd worden omdat deze werkopvolgingen bevat met circulaire afhankelijkheden',
    'secret': 'secret',
    'Unable to send result to Vise': 'Fout bij versturen van feedback naar Vise',
    'Form type "{{form_type_identifier}}" does not exists': 'Formulier-type "{{form_type_identifier}}" bestaat niet',
    'Form type "{{form_type_identifier}}" does not match existing ProjectForm type': 'Formulier-type "{{form_type_identifier}}" komt niet overeen met bestaand Project-formulier',
    'Unknown status "{{status}}"': 'Onbekende status "{{status}}"',
    'Status transition to "{{status}}" is not allowed': 'Statusovergang naar \"{{status}}\" is niet toegestaan',
    'ProjectJob form can\'t be updated': 'Opdracht formulier kan niet aangepast worden',
    'ProjectJob project can\'t be updated': 'Opdracht project kan niet aangepast worden',
    'ProjectJob object can\'t be updated': 'Opdracht object kan niet aangepast worden',
    'Bijlages': 'Bijlages',
    'file-upload.max-file-count-exceeded': 'Bijlage {{fileName}} overgeslagen, max aantal bijlages ({{maxFileCount}}) overschreven',
    'file-upload.max-file-size-exceeded': 'Bijlage {{fileName}} overgeslagen, max bestandsgrootte ({{maxFileSize}})',
    'Object "{{paula_object_identifier}}" does not exists in project "{{project_form_identifier}}"': 'Object "{{paula_object_identifier}}" does not exists in project "{{project_form_identifier}}"',
    'Formulieren toevoegen': 'Formulieren toevoegen',
    'Aantal Formulieren toevoegen': '{{total}} Formulier(en) toevoegen',
    "user.requires.signature": "Handtekening vereist voor goedkeuring binnen dit project, vraag een applicatiebeheerder om deze voor je in te stellen.",
    "image.upload.failed": "Upload mislukt door een technische fout. Probeer het later nog eens of neem contact op met de applicatiebeheerder.",
    "image.upload.exceeded": "Grootte van de afbeelding overschrijdt de toegestane limiet van 25 MB.",
    "image.upload.unsupported": "Bestandstype wordt niet ondersteund."
};
