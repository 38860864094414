import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Inject, Injectable} from '@angular/core';
import {ExecutionGroupService} from '../services/execution-group.service';
import {ExecutionGroup} from '../models/execution-group';

@Injectable({
    providedIn: 'root'
})
export class ExecutionGroupResolver implements Resolve<ExecutionGroup> {

    constructor(
        @Inject('ExecutionGroupService') private executionGroupService: ExecutionGroupService,
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.executionGroupService.getExecutionGroup(+route.params.id);
    }
}
