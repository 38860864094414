import {Component} from '@angular/core';
import {filter, take} from 'rxjs/operators';
import {AuthenticationService, AuthenticationState} from '../../services/authentication.service';

@Component({
    selector: 'app-login-overlay',
    templateUrl: './login-overlay.component.html'
})
export class LoginOverlayComponent {

    authenticationState$ = this.authenticationService.authenticationState$;

    constructor(
        private authenticationService: AuthenticationService
    ) {}

    async startInteractiveAuthentication() {
        if (this.authenticationState$.value !== AuthenticationState.Pending) {
            this.authenticationService.ensureAuthenticated();
            // wait until state is 'pending'
            await this.authenticationState$.pipe(filter(it => it === AuthenticationState.Pending), take(1)).toPromise();
        }
        this.authenticationService.triggerInteractiveAuthentication();
    }

}
