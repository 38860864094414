import {Component, forwardRef, OnDestroy, OnInit} from '@angular/core';
import {ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR} from "@angular/forms";
import {TableColumnText} from "../../models/table-column";
import {Subscription} from "rxjs";

type ColumnConfig = TableColumnText['config'];

@Component({
    selector: 'app-question-edit-tabular-column-text',
    templateUrl: './question-edit-tabular-column-text.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => QuestionEditTabularColumnTextComponent),
            multi: true
        }
    ]
})
export class QuestionEditTabularColumnTextComponent implements OnInit, OnDestroy, ControlValueAccessor {
    subscription: Subscription | null = null;
    disabled = false;

    onChange: (value: ColumnConfig) => void = () => {}
    onTouched: () => void = () => {}

    configForm = new FormGroup({
        multiline: new FormControl<boolean>(false, {
            nonNullable: true
        })
    });

    constructor() {
    }

    ngOnInit() {
        this.subscription = this.configForm.valueChanges.subscribe(value => {
            this.onChange(this.configForm.getRawValue())
            this.onTouched()
        })
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe()
    }

    registerOnChange(fn: (value: ColumnConfig) => void): void {
        this.onChange = fn
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled
    }

    writeValue(value: ColumnConfig | null): void {
        if (value === null) {
            console.error("QuestionEditTabularColumnTextComponent doesnt support null values")
            this.configForm.reset()
            return;
        }

        this.configForm.patchValue(value, {emitEvent: false})
    }

}
