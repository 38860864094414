<app-toolbar
    [showAddBtn]="true"
    [addBtnRoute]="'/beheer/form-types/new'"
    addBtnLabel="Nieuw formulier"
>
    <app-file-upload-btn (fileChange)="importFormTypeStatuses($event)" accept=".xlsx" [maxSize]="5000000"
                         [disabled]="importingFormTypeStatuses" data-ci="import-statuses-button">
        {{'Statuswijzigingen importeren'|translate}}
    </app-file-upload-btn>


    <app-file-upload-btn *ngIf="!featureFormTypeImportV2Enabled"
                         (fileChange)="importFormTypes($event)"
                         [accept]="featureFormTypeImportV2Enabled ? '.xlsx' : '.zip'" [maxSize]="25000000"
                         [disabled]="importingFormType" data-ci="import-form-types-button">
        {{'Formulieren importeren'|translate}}
    </app-file-upload-btn>
    <button *ngIf="featureFormTypeImportV2Enabled"
            class="vwui-btn has-icon"
            (click)="downloadImportTemplate()"
            [disabled]="downloadingTemplate" data-ci="download-template-button">
        <app-icon iconName="download" *ngIf="!downloadingTemplate; else loaderIcon"></app-icon>
        <ng-template #loaderIcon>
            <div class="vwui-spinner"></div>
        </ng-template>
        {{'Import template downloaden'|translate}}
    </button>
    <button *ngIf="featureFormTypeImportV2Enabled"
                         class="vwui-btn has-icon"
                         (click)="openImportV2Modal()"
                         [disabled]="importingFormType" data-ci="import-form-types-button">
        <app-icon iconName="upload" *ngIf="!importingFormType; else loaderIcon"></app-icon>
        <ng-template #loaderIcon>
            <div class="vwui-spinner"></div>
        </ng-template>
        {{'Formulieren importeren'|translate}}
    </button>
    <button class="vwui-btn has-icon" (click)="openCopyProjectFormModal()">
        <app-icon iconName="duplicate"></app-icon>
        {{'Formulier kopieren vanuit project'}}
    </button>
</app-toolbar>
<div class="vwui-container is-full">
    <app-table
        *ngIf="paginator$|async;let paginator"
        [paginator]="paginator"
        [headerTitles]="tableHeaders"
        (filterChanged)="onTableFilterChange($event)">
        <ng-template let-item>
            <a class="vwui-table-row" [routerLink]="['/beheer/form-types/', item.id]">
                <td class="td-15">{{ item.title }}</td>
                <td>{{ item.code }}</td>
                <td>{{ item.status | formTypeStatus }}</td>
                <td>{{ item.paulaObjectTypes | arrayPropertyValues:'title' }}</td>
                <td>{{ item.companies | arrayPropertyValues: 'name'}}</td>
                <td class="td-10">{{ item.contactPerson }}</td>
                <td>
                    {{ item.updatedAt | dateFormat }}
                    <div *ngIf="item.updatedBy">
                        <small class="text-muted td-10">
                            {{ item.updatedBy }}
                        </small>
                    </div>
                    <div>
                        <small class="text-muted">
                            Created: {{ item.createdAt | dateFormat }}
                        </small>
                    </div>
                </td>
                <td>
                    <ng-container *ngFor="let tag of item.tags">
                        <app-tag [className]="tag.className">{{tag.name}}</app-tag>
                    </ng-container>
                </td>
                <td class="vwui-table__fixed-column-width vwui-multi-select-table--unclickable">
                    <div class="vwui-table__actions">
                        <app-action-menu>
                            <button [routerLink]="['/beheer/form-types/' + item.id + '/copy']" type="button"
                                    class="vwui-menu-item">
                                <app-icon iconName="calendar"></app-icon>
                                {{ 'Formulier kopieren'|translate }}
                            </button>
                            <button type="button" class="vwui-menu-item" (click)="downloadForm(item)">
                                <app-icon iconName="pdf"></app-icon>
                                {{ 'Formulier exporteren naar PDF'|translate }}
                            </button>
                        </app-action-menu>
                    </div>
                </td>
            </a>
        </ng-template>
    </app-table>
</div>
