// @ts-strict-ignore
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ProjectJobService} from '../../services/project-job.service';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {Forms} from '../../utils/forms';
import {ProjectJobCopyRequest} from '../../models/project-job-copy-request';
import {ProjectJob} from '../../models/project-job';
import {HttpErrorResponse} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {ModalResult} from "../../models/modal.result";


@Component({
    selector: 'app-project-job-clone-modal',
    templateUrl: './project-job-clone-modal.component.html'
})
export class ProjectJobCloneModalComponent implements OnInit, OnDestroy {
    projectJob: ProjectJob;

    form = new UntypedFormGroup({
        title: new UntypedFormControl('', [Validators.required, Validators.maxLength(255)]),
        code: new UntypedFormControl('', [Validators.required, Validators.maxLength(255)], [this.validateProjectJobNotTaken.bind(this)])
    })

    formSubscriptions: Subscription[] = [];

    saving = false;

    onClose: (result: ModalResult, data: ProjectJobCopyRequest[]) => void;

    constructor(
        @Inject('ProjectJobService') private projectJobService: ProjectJobService,
        private toast: ToastrService,
        protected bsModalRef: BsModalRef,
    ) {
    }

    ngOnDestroy(): void {
        this.formSubscriptions.forEach(s => s.unsubscribe());
    }

    async ngOnInit() {
        this.form.controls.title.setValue(this.projectJob.title + '-kopie');
        this.form.controls.code.setValue(this.projectJob.code + '-kopie')
    }

    closeModal(result: ModalResult, data: any = null) {
        this.onClose(result, data);
        this.bsModalRef.hide();
    }

    private validateProjectJobNotTaken(control: AbstractControl): Observable<ValidationErrors> {
        return Forms.validateNotTaken(control, 0, this.projectJobService.exists(control.value));
    }

    async cloneJob() {
        if (!this.form.valid || this.saving) {
            Forms.updateValueAndValidityRecursive(this.form);
            return;
        }

        try {
            this.saving = true;

            await this.projectJobService.cloneProjectJob(this.projectJob, this.form.controls.title.value, this.form.controls.code.value).toPromise()
            this.closeModal('confirmed');
        } catch (ex) {
            if (ex instanceof HttpErrorResponse && ex.error.code === 'maximo-vise-clone-error') {
                this.toast.error('Deze opdracht is gekoppeld aan Vise of Maximo en kan daarom niet gekopieerd worden.')
            } else if (ex instanceof HttpErrorResponse && ex.status === 400 && ex.error.fieldErrors.code === 'Already in use') {
                this.toast.error('Het nieuwe opdrachtnummer moet uniek zijn.')
            } else {
                console.error('Error during cloning of the project job', ex);
                this.closeModal('error');
            }
        } finally {
            this.saving = false;
        }
    }
}
