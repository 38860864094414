import {QuestionType} from "./form-item";
import {FormControl, FormGroup} from "@angular/forms";

export interface AbstractTableColumn {
    type: string;
    key: string;
    label: string;
    config: {};
}

export interface TableColumnText extends AbstractTableColumn {
    type: QuestionType.text;
    config: {
        multiline: boolean;
    }
}

export interface TableColumnNumber extends AbstractTableColumn {
    type: QuestionType.number
    config: {
        totalDecimal: number;
        showAverage: boolean;
        showSum: boolean;
    }
}

export type TableColumn = TableColumnText | TableColumnNumber;
export type TableColumnByType<T, U = TableColumn> = U extends { type: T } ? U: never;

export type TableColumnFormGroup = FormGroup<{
    type: FormControl<TableColumn['type']>;
    key: FormControl<string>;
    label: FormControl<string>;
    config: FormControl<TableColumn['config']>
}>

export function getEmptyTableColumn(type: TableColumn['type']): TableColumn {
    switch (type) {
        case QuestionType.text:
            return {
                type,
                key: '',
                label: '',
                config: {
                    multiline: false
                }
            }
        case QuestionType.number:
            return {
                type,
                key: '',
                label: '',
                config: {
                    totalDecimal: 0,
                    showAverage: false,
                    showSum: false
                }
            }
        default:
            return unknownColumnType(type)
    }
}

export function unknownColumnType(type: never): never {
    throw new Error(`Unknown column type '${type}' provided`)
}
