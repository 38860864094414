<app-toolbar
    [showAddBtn]="true"
    [addBtnRoute]="'/beheer/sharepoint-credentials/new'"
    addBtnLabel="{{'Nieuw SharePoint inloggegevens'|translate}}">
</app-toolbar>
<app-table #table *ngIf="paginator$|async;let paginator"
    [paginator]="paginator"
    [headerTitles]="tableHeaders"
    (filterChanged)="onTableFilterChange($event)">
    <ng-template let-item>
        <tr [routerLink]="['/beheer/sharepoint-credentials/', item.id]">
            <td>{{ item.title }}</td>
            <td>{{ item.hostname }}</td>
        </tr>
    </ng-template>
</app-table>
