import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {PaulaObjectType} from '../models/paula-object-type';
import {Inject, Injectable} from '@angular/core';
import {ObjectTypeService} from '../services/object-type.service';

@Injectable({
    providedIn: 'root'
})
export class ObjectTypeResolver implements Resolve<PaulaObjectType> {

    constructor(
        @Inject('ObjectTypeService') private objectTypeService: ObjectTypeService
    ) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.objectTypeService.getObjectType(+route.params.id);
    }
}
