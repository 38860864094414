<app-header>
    <img class="logo" src="beheer/assets/logo.svg">
</app-header>
<app-nav [mainNavItems]="navItems">
    <ng-template let-navItem>
        <ng-container *appHasRole="navItem.data.role">
            <div class="vwui-nav-list__header" *ngIf="navItem.header">
                {{ navItem.header }}
            </div>
            <ng-container *ngFor="let navLink of navItem.items">
                <a *appHasFeatureToggle="navLink.data?.featureToggle" class="vwui-nav-list__item vwui-nav-badge"
                   [routerLink]="navLink.routerLink"
                   routerLinkActive="is-active">
                    {{ navLink.label }}
                    <span *ngIf="navLink.data?.featureToggle === 'appleContentCodes' && (this.amountOfAvailableCodes$ | async) !== null && (this.amountOfAvailableCodes$ | async) < notificationThreshold" class="vwui-nav-badge-item">
                        {{ (this.amountOfAvailableCodes$|async) }}
                    </span>
                </a>
            </ng-container>
        </ng-container>
    </ng-template>
</app-nav>

<main class="vwui-main">
    <router-outlet></router-outlet>
</main>
