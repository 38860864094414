// @ts-strict-ignore
import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {WorkerGroupUser} from '../../models/worker-group-user';
import {ProjectAuthorization} from '../../models/project-authorization';

@Component({
    selector: 'app-user-execution-group-modal',
    templateUrl: './user-execution-group-modal.component.html'
})
export class UserExecutionGroupModalComponent implements OnInit {
    executionGroupHolder: WorkerGroupUser | ProjectAuthorization;

    form: UntypedFormGroup;
    result: 'cancelled'|'confirmed';

    constructor(
        private bsModalRef: BsModalRef
    ) {}

    ngOnInit(): void {
        if (this.executionGroupHolder) {
            this.form = new UntypedFormGroup({
                executionGroups: new UntypedFormControl(this.executionGroupHolder.executionGroups)
            });

            if ('projectExecutionGroups' in this.executionGroupHolder) {
                this.form.addControl('projectExecutionGroups', new UntypedFormControl(
                    this.executionGroupHolder.projectExecutionGroups
                ))
            }
        }
    }

    cancel() {
        this.result = 'cancelled';
        this.bsModalRef.hide();
    }

    confirm() {
        this.result = 'confirmed';
        this.bsModalRef.hide();
    }
}
