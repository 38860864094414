export enum FeatureToggle {
    Maximo = 'maximo',
    Vise = 'vise',
    AppleContentCodes = 'appleContentCodes',
    PrivacyEnabled = 'privacyEnabled',
    CustomersEnabled = 'customersEnabled',
    SharepointJobExport = 'sharepointJobExport',
    AddToSummaryEnabled = 'addToSummaryEnabled',
    ProjectJobShowRejectReason = 'projectJobShowRejectReason',
    projectJobAnswerMetaEnabled = 'projectJobAnswerMetaEnabled',
    previewAnswerImageEnabled = 'previewAnswerImageEnabled',
    downloadAnswerImageEnabled = 'downloadAnswerImageEnabled',
    CustomerSpeciticReportTemplatesEnabled = 'customerSpecificReportTemplateEnabled',
    ProjectMapLayerProperties = 'projectMapLayerProperties',
    FormTypeImportV2 = 'formTypeImportV2',
    InspectionFormSync = 'inpectionFormSync',
    ObstructionCodes = 'obstructionCodes'
}

export interface FeatureToggleData {
    featureToggleName: FeatureToggle;
    enabled: boolean;
}

export type FeatureToggleName = FeatureToggle | `${FeatureToggle}`;
