import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient, HttpEvent} from '@angular/common/http';
import {UploadedFile} from '../models/uploaded-file';

export interface UploadService {
    uploadImage(id: string, file: Blob): Observable<UploadedFile>;
    uploadFile(id: string, file: Blob, filename: string): Observable<HttpEvent<UploadedFile>>;
    uploadFile(id: string, file: File): Observable<HttpEvent<UploadedFile>>;
}

@Injectable()
export class UploadServiceImpl implements UploadService {

    constructor(private httpClient: HttpClient) {
    }

    uploadFile(id: string, file: Blob, filename: string): Observable<HttpEvent<UploadedFile>>;
    uploadFile(id: string, file: File): Observable<HttpEvent<UploadedFile>>;
    uploadFile(id: string, file: Blob, filename?: string): Observable<HttpEvent<UploadedFile>> {
        if (filename === undefined && file instanceof File) {
            filename = file.name;
        }

        const formData = new FormData();
        formData.append('id', id);
        formData.append('file', file, filename);
        return this.httpClient.post<UploadedFile>('/api/v1/upload/file', formData, {
            reportProgress: true,
            observe: 'events'
        });
    }

    uploadImage(id: string, file: Blob): Observable<UploadedFile> {
        const formData = new FormData();
        formData.append('id', id);
        formData.append('file', file);
        return this.httpClient.post<UploadedFile>('/api/v1/upload/image', formData);
    }
}
