// @ts-strict-ignore
import {Observable, of} from 'rxjs';
import {PageResponse} from '../../models/page-response';
import {AbstractCrudServiceMock} from './abstract-crud.service.mock';
import {Injectable} from '@angular/core';
import {ExecutionGroup} from '../../models/execution-group';
import {ExecutionGroupService} from '../execution-group.service';

export const mockExecutionGroups: Array<ExecutionGroup> = [
    {
        id: 2,
        title: 'Schiphol tunnel'
    },
    {
        id: 1,
        title: 'Treinspoor'
    }
];

@Injectable()
export class ExecutionGroupServiceMock extends AbstractCrudServiceMock<ExecutionGroup> implements ExecutionGroupService {

    constructor() {
        super(mockExecutionGroups);
    }

    getExecutionGroups(page: number): Observable<PageResponse<ExecutionGroup>> {
        return this.getAll();
    }

    getExecutionGroup(id: number) {
        return this.get(id);
    }

    putExecutionGroup(executionGroup: ExecutionGroup): Observable<ExecutionGroup> {
        return this.put(executionGroup);
    }

    postExecutionGroup(executionGroup: ExecutionGroup) {
        return this.post(executionGroup);
    }

    exists(title: string) {
        const result = mockExecutionGroups.find(item => item.title === title);

        return of(result ? result.id : null);
    }
}
