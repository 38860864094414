// @ts-strict-ignore
import {ChangeDetectionStrategy, Component, EventEmitter, Input, NgZone, OnInit, Output} from '@angular/core';
import {FormItem, SelectedFormItem} from '../../models/form-item';
import {Options} from 'sortablejs';
import {FormUtils} from '../../utils/form-utils';
import {FormBuilderService} from '../../services/form-builder.service';

@Component({
    selector: 'app-questions-list',
    templateUrl: './questions-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuestionsListComponent implements OnInit {
    @Input() readonly = false;
    @Input() parentFormItem: FormItem;
    @Input() chapterNumber: number;
    @Input() questions: FormItem[];
    @Input() selectedFormItem?: SelectedFormItem;
    @Input() questionSetPosition?: number;
    @Input() formItemChildren: FormItem[];

    @Output() edit = new EventEmitter<FormItem>();
    @Output() duplicate = new EventEmitter<FormItem>();
    @Output() remove = new EventEmitter<FormItem>();
    @Output() add = new EventEmitter<void>();
    @Output() addQuestionSet = new EventEmitter<void>();
    @Output() addChapter = new EventEmitter<void>();
    @Output() sorted = new EventEmitter<FormItem[]>();

    dependentQuestionPosition: string;

    constructor(
        private zone: NgZone,
        private formBuilderService: FormBuilderService
    ) {
    }

    sortableOptions: Options;
    isSorting = false;

    ngOnInit() {
        this.sortableOptions = {
            disabled: this.readonly,
            onUpdate: () => {
                this.sortQuestions();
            },
            onStart: () => {
                this.zone.run(() => this.isSorting = true);
            },
            onEnd: () => {
                this.zone.run(() => this.isSorting = false);
            }
        };
    }

    isSelected(formItem: FormItem): boolean {
        return (this.selectedFormItem && this.selectedFormItem.formItem) && formItem.id === this.selectedFormItem.formItem.id;
    }

    sortQuestions() {
        this.questions.forEach((question, index) => question.position = index + 1);
        this.sorted.emit(this.questions);
    }

    getDependentQuestionPosition = (formItem: FormItem) => {
        const offsetChapter = this.questionSetPosition ? FormUtils.getChapterNumberFromPosition(this.questionSetPosition ) : 1;

        return FormUtils.dependentQuestionPosition(
            formItem,
            this.formBuilderService.getPossibleDependencies(this.parentFormItem, true),
            offsetChapter
        );
    }

    trackByFormItems(index: number, item: FormItem) {
        if (!item) {
            return null;
        }

        return item.id;
    }
}
