import {Inject, Injectable} from "@angular/core";
import {PaulaObstructionCode} from "../models/paula-obstruction-code";
import {ObstructionCodeService} from "../services/obstruction-code.service";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class ObstructionCodeResolver implements Resolve<PaulaObstructionCode> {
    constructor(
        @Inject('ObstructionCodeService') private obstructionCodeService: ObstructionCodeService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.obstructionCodeService.getObstructionCode(+route.params.id);
    }
}
