import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'app-aside-actionbar',
  templateUrl: './aside-actionbar.component.html'
})
export class AsideActionbarComponent {
    @Output() cancel = new EventEmitter<void>();
    @Output() save = new EventEmitter<void>();
}
