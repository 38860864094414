// @ts-strict-ignore
import {EntitySelectComponent} from './item-select.component';
import {Component, forwardRef, Inject, Input, OnChanges, SimpleChanges} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {Project} from '../../models/project';
import {Object} from '../../models/object';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {createEmptyPageResponse, PageResponse} from '../../models/page-response';
import {ObjectService} from '../../services/object.service';
import {PaulaObjectType} from '../../models/paula-object-type';

@Component({
    selector: 'app-object-select',
    templateUrl: './object-select.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ObjectSelectComponent),
            multi: true
        },
    ]
})
export class ObjectSelectComponent extends EntitySelectComponent<Object, { project: Project, objectTypes: PaulaObjectType[] }>
    implements OnChanges {

    @Input() project: Project;
    @Input() objectTypes: PaulaObjectType[];
    filter: Subject<{ project: Project, objectTypes: PaulaObjectType[] }>;

    constructor(
        @Inject('ObjectService') private objectService: ObjectService,
    ) {
        super();

        this.placeholder = 'Selecteer een object';
        this.multiple = true;
    }

    getFilter() {
        if (!this.filter) {
            this.filter = new BehaviorSubject({project: this.project, objectTypes: this.objectTypes});
        }

        return this.filter;
    }

    getLabelText(item: Object): string {
        return item.objectOmschrijvingKort;
    }

    loadMore(page: number, filterParams: { project: Project, objectTypes: PaulaObjectType[], term: string })
        : Observable<PageResponse<Object>> {

        if (filterParams.project && this.objectTypes) {
            return this.objectService.searchObjects(
                filterParams.project.id,
                page,
                filterParams && filterParams.term ? filterParams.term : '*',
                {objectTypes: this.objectTypes.map(it => it.id).join(',')},
            );
        }

        return of(createEmptyPageResponse<Object>());
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.project || changes.objectTypes) {
            this.filter?.next({project: this.project, objectTypes: this.objectTypes});
        }
    }
}
