// @ts-strict-ignore
import {Component, Input} from '@angular/core';
import {AbstractFormItem, FormItem} from '../../models/form-item';
import {QuestionSet} from '../../models/question-set';
import {Forms} from '../../utils/forms';
import {FormUtils} from '../../utils/form-utils';

@Component({
    selector: 'app-question-set-items',
    templateUrl: './question-set-items.component.html'
})
export class QuestionSetItemsComponent {
    @Input() chapterNumber: number;
    @Input() showChapters = true;
    @Input() questionSet: QuestionSet;

    get questionSetItems() {
        return Forms.convertToChapterTree(this.questionSet);
    }

    public getChapterNumber(item: AbstractFormItem, index: number) {
        if (!this.chapterNumber) {
            return index;
        } else {
            return FormUtils.getChapterNumberFromPosition( item.position, this.chapterNumber);
        }
    }

    public dependentQuestionPosition(item: FormItem) {
        return FormUtils.dependentQuestionPosition(item, this.questionSet.formItems, this.chapterNumber);
    }

}
