<app-modal
    [modalTitle]="'Formulieren zijn succesvol geïmporteerd' | translate"
    [showSaveBtn]="false"
    [showCloseBtn]="true"
    [modalCancelBtnLabel]="'Sluiten' | translate"
    (cancel)="close()"
>
    <table class="vwui-table">
        <thead>
            <tr>
                <th>{{ "Formuliernummer" | translate }}</th>
                <th>{{ "Revisienummer" | translate }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let form of importedForms">
                <td>{{ form.code }}</td>
                <td>{{ form.revision }}</td>
            </tr>
        </tbody>
    </table>
</app-modal>
