// @ts-strict-ignore
import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html'
})
export class IconComponent {
  @Input() iconName: string;

  get icon() {
    return `beheer/assets/vwui/svg/icons.svg#icon-${this.iconName}`;
  }
}
