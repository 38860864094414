import {Component, Inject} from '@angular/core';
import {AbstractListComponent} from '../abstract-list.component';
import {ExecutionGroup} from '../../models/execution-group';
import {HeaderTitle, TableFilter} from '../../components/table/table.component';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {PageResponse} from '../../models/page-response';
import {ExecutionGroupService} from '../../services/execution-group.service';

@Component({
    selector: 'app-execution-group-list',
    templateUrl: './execution-group-list.component.html'
})
export class ExecutionGroupListComponent extends AbstractListComponent<ExecutionGroup> {
    headerTitles: HeaderTitle[] = [
        {sortKey: 'title', filterKey: 'title', title: 'Disciplinenaam'},
    ];

    constructor(
        @Inject('ExecutionGroupService') private executionGroupService: ExecutionGroupService,
        protected activatedRoute: ActivatedRoute,
        protected router: Router,
    ) {
        super(activatedRoute, router);
    }

    loadData(page: number, filter: TableFilter): Observable<PageResponse<ExecutionGroup>> {
        return this.executionGroupService.getExecutionGroups(page, filter);
    }
}
