// @ts-strict-ignore
import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-external-link',
    templateUrl: './external-link.component.html'
})
export class ExternalLinkComponent {
    @Input() link: string;

    openExternalLink(event: Event) {
        event.stopPropagation();
        event.preventDefault();

        let urlPrefix = '';

        if (!/^http[s]?:\/\//.test(this.link)) {
            urlPrefix += 'https://';
        }

        window.open(`${urlPrefix}${this.link}`, '_blank');
    }
}
