// @ts-strict-ignore
import {Component, Inject} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {GraphUser} from "../../models/graph-user";
import {BsModalRef} from "ngx-bootstrap/modal";
import {Graph} from "../../models/graph";
import {UserService} from "../../services/user.service";
import {firstValueFrom} from "rxjs";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";

@Component({
    selector: 'app-create-user-modal',
    templateUrl: './create-user-modal.component.html'
})
export class CreateUserModalComponent {
    saving = false;
    form = new FormGroup({
        graph: new FormControl<Graph>(null, Validators.required),
        user: new FormControl<GraphUser>(null, Validators.required)
    })

    constructor(
        @Inject("UserService") private userService: UserService,
        protected bsModalRef: BsModalRef,
        private toastr: ToastrService,
        private router: Router,
    ) {
    }

    closeModal() {
        this.bsModalRef.hide();
    }

    async submit() {
        if(!this.form.valid || this.saving) {
            return;
        }

        try {
            this.saving = true;
            const user = await firstValueFrom(this.userService.postUser({
                ...this.form.value.user,
                roles: ['ROLE_EXECUTOR'],
                company: null
            }))

            await this.router.navigate(['/beheer/users/', user.id])
            this.bsModalRef.hide()
        } catch (e) {
            console.error('User submit failed', e);
            this.toastr.error("Opslaan mislukt")
        } finally {
            this.saving = false;
        }
    }
}
