import {Pipe, PipeTransform} from '@angular/core';
import {formTypeTitles} from '../models/form-type';

@Pipe({
    name: 'formTypeStatus',
})
export class FormTypeStatusTitlePipe implements PipeTransform {
    transform(value: keyof typeof formTypeTitles) {
        if (!value) {
            return '';
        }

        return formTypeTitles[value] || '';
    }
}
