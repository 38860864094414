<app-toolbar
    [showAddBtn]="true"
    addBtnLabel="{{'Object toevoegen'|translate}}"
    [addBtnRoute]="'/beheer/projects/'+project.id+'/objects/new'"
>
    <app-file-upload-btn (fileChange)="onFileChange($event)">{{'Objecten importeren'|translate}}</app-file-upload-btn>
    <app-loading-btn iconName="download" (btnClick)="exportObjects($event)">{{'Objecten exporteren'|translate}}</app-loading-btn>
</app-toolbar>
<div class="vwui-container is-full" *ngIf="project">
    <app-table class="vwui-table__large" *ngIf="paginator$|async; let paginator" [paginator]="paginator" [headerTitles]="getHeaders()" (filterChanged)="onTableFilterChange($event)" [enableScroll]="true">
        <app-search [searchControl]="searchField"></app-search>
        <ng-template let-item>
            <tr [routerLink]="['/beheer/projects/', project.id, 'objects', item.id]">
                <td>{{ item.objectId }}</td>
                <td>{{ item.objectOmschrijvingKort }}</td>
                <td>{{ item.objectOmschrijvingLang }}</td>
                <td *ngIf="project.hasMaintenanceContract">{{ item.objectFilter }}</td>
                <td *ngIf="project.hasMaintenanceContract">{{ item.objectGeo }}</td>
                <td>{{ item.objectType.title }}</td>
                <td>
                    <span class="vwui-badge" [class.is-success]="item.objectStatus">
                        {{ item.objectStatus ? 'Actief' : 'Inactief' }}
                    </span>
                </td>
                <td>
                    <app-external-link *ngIf="item.objectDocumentLink" [link]="item.objectDocumentLink"></app-external-link>
                </td>
            </tr>
        </ng-template>
    </app-table>
</div>
