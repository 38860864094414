// @ts-strict-ignore
import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-project-job-reject-modal',
    templateUrl: './project-job-reject-modal.component.html'
})
export class ProjectJobRejectModalComponent implements OnInit {

    form: UntypedFormGroup;
    result: 'cancelled' | 'confirmed';

    constructor(
        private bsModalRef: BsModalRef,
        private fb: UntypedFormBuilder
    ) {
    }

    ngOnInit(): void {
        this.createForm(this.fb);
    }

    cancel() {
        this.result = 'cancelled';
        this.bsModalRef.hide();
    }

    confirm() {
        if (this.form.valid) {
            this.result = 'confirmed';
            this.bsModalRef.hide();
        }
    }

    createForm(fb: UntypedFormBuilder) {
        this.form = fb.group({
            projectJobRejectGroup: fb.control('', [Validators.maxLength(1200)])
        });
    }
}
