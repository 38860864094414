// @ts-strict-ignore
import {Component, forwardRef, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {BehaviorSubject, combineLatest, Observable, of, Subscription} from 'rxjs';
import {EntitySelectComponent} from './item-select.component';
import {PageResponse} from '../../models/page-response';
import {SharepointCredentialListDTO} from '../../models/sharepoint-credential';
import {SharepointCredentialService} from '../../services/sharepoint-credential.service';
import {tap} from "rxjs/operators";

@Component({
    selector: 'app-sharepoint-credential-select',
    templateUrl: './item-select.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SharepointCredentialSelectComponent),
            multi: true
        },
    ]
})
export class SharepointCredentialSelectComponent
    extends EntitySelectComponent<SharepointCredentialListDTO, {}>
    implements OnInit, OnDestroy
{
    private selectHostnameSubject = new BehaviorSubject<string | null>(null);
    private selectHostnameSubscription: Subscription | null = null;

    @Input() set selectHostname(hostname: string | null) {
        this.selectHostnameSubject.next(hostname)
    }

    constructor(
        @Inject('SharepointCredentialService') private sharepointCredentialService: SharepointCredentialService,
    ) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        this.selectHostnameSubscription = combineLatest([
            this.items,
            this.selectHostnameSubject
        ]).subscribe(([items, domain]) => {
            if(domain == null || domain == this.formControl.value?.hostname) {
                // Do nothing if domain is null or already selected
                return;
            }

            this.formControl.patchValue(items.find(item => item.hostname == domain) || null)
        });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.selectHostnameSubscription.unsubscribe();
    }

    loadMore(page: number, filterInput: {term: string}): Observable<PageResponse<SharepointCredentialListDTO>> {
        const filter: {[key: string]: string} = {
            name: filterInput && filterInput.term ? filterInput.term : '',
        };

        return this.sharepointCredentialService.getSharepointCredentials(page, {filter}).pipe(
            tap(response => {
                if (response.last == false
                    && response.content.find(item => item.hostname == this.selectHostnameSubject.value) == null
                ) {
                    // Queue loading of next page if available, and we haven't found the item yet in current items
                    setTimeout(() => {

                    })
                }
            })
        )
    }

    getLabelText(item: SharepointCredentialListDTO) {
        return item.title;
    }

    getFilter(): Observable<{}> {
        return of({});
    }
}
