<app-toolbar [showBackBtn]="true" (back)="goBack()"></app-toolbar>
<div class="vwui-container is-small">
    <div class="vwui-card">
        <div class="vwui-card__header">{{'Bedrijf'|translate}}</div>
        <div class="vwui-card__content">
            <form [formGroup]="form" (submit)="save()">
                <div class="vwui-form-group" [appFormControlError]="form.get('name')">
                    <label for="name" class="vwui-label">{{'Bedrijfnaam'|translate}}</label>
                    <input id="name" class="vwui-input" name="name" type="text" formControlName="name" autocomplete="off">
                </div>

                <button type="submit" class="d-none"><!-- Needed to submit with enter --></button>
            </form>
        </div>
    </div>
</div>
<app-actionbar *ngIf="form.dirty" (cancel)="cancel()" (save)="save()"></app-actionbar>
