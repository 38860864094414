
export interface FormulaConfig {
    label: string;
    fields: FormulaField[];
    tolerances: FormulaTolerance[];
}

export interface AbstractFormulaField {
    label: string;
    fieldName: string;
    totalDecimal: number;
}

export interface FormulaInput extends AbstractFormulaField {
    type: 'input';
}

export interface FormulaOutput extends AbstractFormulaField {
    type: 'output';
    formula: string;
}

export type FormulaField = FormulaInput | FormulaOutput

export interface FormulaTolerance {
    label: string;
    formula: string;
}


export const BuiltinFormulas: FormulaConfig[] = [
    {
        label: "Spoorstaaf",
        fields: [
            {
                type: "input",
                label: "Lengte (m)",
                fieldName: "railLength",
                totalDecimal: 1
            },
            {
                type: "input",
                label: "Temperatuur (°C)",
                fieldName: "railTemperature",
                totalDecimal: 1
            },
            {
                type: "output",
                label: "Te verlengen (mm)",
                fieldName: "railExtension",
                formula: "railLength * 0.012 * (25 - railTemperature)",
                totalDecimal: 0
            },
            {
                type: "input",
                label: "Gerealiseerde verlenging (mm)",
                fieldName: "insertedRail",
                totalDecimal: 0
            }
        ],
        tolerances: [
            {
                label: "De gerealiseerde verlenginglengte is te laag",
                formula: "insertedRail < (railExtension - (railLength * 0.012 * 3))"
            },
            {
                label: "De gerealiseerde verlenginglengte is te hoog",
                formula: "insertedRail > (railExtension + (railLength * 0.012 * 3))"
            },
        ]
    },
    {
        label: "Spoorstaaf tunnel",
        fields: [
            {
                type: "input",
                label: "Lengte (m)",
                fieldName: "railLength",
                totalDecimal: 1
            },
            {
                type: "input",
                label: "Temperatuur (°C)",
                fieldName: "railTemperature",
                totalDecimal: 1
            },
            {
                type: "output",
                label: "Te verlengen (mm)",
                fieldName: "railExtension",
                formula: "railLength * 0.012 * (18 - railTemperature)",
                totalDecimal: 0
            },
            {
                type: "input",
                label: "Gerealiseerde verlenging (mm)",
                fieldName: "insertedRail",
                totalDecimal: 0
            }
        ],
        tolerances: [
            {
                label: "De gerealiseerde verlenginglengte is te laag",
                formula: "insertedRail < (railExtension - (railLength * 0.012 * 3))"
            },
            {
                label: "De gerealiseerde verlenginglengte is te hoog",
                formula: "insertedRail > (railExtension + (railLength * 0.012 * 3))"
            },
        ]
    },
    {
        label: "Oppervlakte",
        fields: [
            {
                type: "input",
                label: "Lengte (mm)",
                fieldName: "length",
                totalDecimal: 0
            },
            {
                type: "input",
                label: "Breedte (mm)",
                fieldName: "width",
                totalDecimal: 0
            },
            {
                type: "output",
                label: "Oppervlakte (mm2)",
                fieldName: "surfaceArea",
                formula: "length * width",
                totalDecimal: 0
            },
        ],
        tolerances: []
    }
]
