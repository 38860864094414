<app-modal
    class=vwui-modal-table
    modalTitle="{{'Formulieren toevoegen'|translate}}"
    modalSaveBtnLabel="{{'Aantal Formulieren toevoegen'|translate : {total: selection.length} }}"
    [loadingSaveBtn]="true"
    [enableSaveBtn]="selection.length > 0"
    (cancel)="closeModal('cancelled')"
    (save)="cloneMultipleForms($event)">
    <div class="scrollable">
        <app-table
            [paginator]="paginator"
            [headerTitles]="tableHeaders"
            [standalone]="true"
            (filterChanged)="onTableFilterChange($event)">
            <ng-template let-item>
                <tr (click)="toggleItemSelected(item)">
                    <td class="vwui-multi-select-table__checkbox-column">
                        <app-checkbox (click)="stopPropagation($event)" [checked]="isSelected(item)" (valueChange)="setItemSelected($event.target.checked, item)"></app-checkbox>
                    </td>
                    <td class="td-15">{{ item.title }}</td>
                    <td>{{ item.code }}</td>
                    <td>{{ item.companies | arrayPropertyValues: 'name'}}</td>
                    <td>
                        <ng-container *ngFor="let tag of item.tags">
                            <app-tag [className]="tag.className">{{tag.name}}</app-tag>
                        </ng-container>
                    </td>
                    <td>{{ item.paulaObjectTypes | arrayPropertyValues:'title' }}</td>
                </tr>
            </ng-template>
        </app-table>
    </div>
</app-modal>
