import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {ProjectService} from '../../services/project.service';

import {ProjectJobService} from '../../services/project-job.service';
import {ProjectJobExtraLatLong} from '../../models/project-job';
import Map from '@arcgis/core/Map';
import MapView from "@arcgis/core/views/MapView";
import Graphic from "@arcgis/core/Graphic";
import SimpleLineSymbolProperties = __esri.SimpleLineSymbolProperties;
import PolylineProperties = __esri.PolylineProperties;
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import PointProperties = __esri.PointProperties;
import {ProjectMapConfig} from "../../pages/project-map/project-map.config";
import Locate from "@arcgis/core/widgets/Locate";

@Component({
    selector: 'app-job-location-select-map',
    templateUrl: './job-location-select-map.component.html',
})
export class JobLocationSelectMapComponent implements AfterViewInit, OnDestroy, OnChanges {
    @Input() coordinates: ProjectJobExtraLatLong | null = null;
    @Input() enabled: boolean = true;
    @Output() coordinatesPicked = new EventEmitter<ProjectJobExtraLatLong>();

    @ViewChild('mapContainer')
    private mapContainer: ElementRef | null = null;

    private map: Map | null = null
    private mapView: MapView | null = null;
    private graphicsLayer: GraphicsLayer | null = null;

    constructor(
        @Inject('ProjectService') private projectService: ProjectService,
        @Inject('ProjectJobService') private projectJobService: ProjectJobService,
    ) {
    }

    async ngAfterViewInit(): Promise<void> {
        await this.initializeMap();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.drawCoordinates();
    }

    ngOnDestroy(): void {
        this.mapView?.destroy();
        this.mapView = null;
    }

    private async drawCoordinates() {
        if (!this.graphicsLayer || !this.coordinates) {
            console.warn('couldn\'t draw coordinates', this.graphicsLayer, this.coordinates);
            return;
        }

        const hasFrom = this.coordinates.gpsLongitudeFrom !== null && this.coordinates.gpsLatitudeFrom;
        const hasTo = this.coordinates.gpsLongitudeTo && this.coordinates.gpsLatitudeTo;

        this.graphicsLayer.removeAll();
        if (hasFrom && hasTo) {
            this.drawLine([[[
                this.coordinates.gpsLongitudeFrom!!,
                this.coordinates.gpsLatitudeFrom!!
            ], [
                this.coordinates.gpsLongitudeTo!!,
                this.coordinates.gpsLatitudeTo!!
            ]]]);
        }
        if (hasFrom) {
            this.drawPoint('#008CBD', this.coordinates.gpsLatitudeFrom!!, this.coordinates.gpsLongitudeFrom!!);
        }
        if (hasTo) {
            this.drawPoint('#50C892', this.coordinates.gpsLatitudeTo!!, this.coordinates.gpsLongitudeTo!!);
        }
    }

    private drawPoint(color: string, latitude: number, longitude: number) {
        this.graphicsLayer?.add(new Graphic({
            geometry: {
                type: 'point',
                latitude,
                longitude
            } as PointProperties,
            symbol: {...ProjectMapConfig.baseMarker, color}
        }));
    }

    private drawLine(paths: number[][][]) {
        this.graphicsLayer?.add(new Graphic({
            geometry: {
                type: 'polyline',
                paths
            } as PolylineProperties,
            symbol: {
                type: 'simple-line',
                color: '#000',
                width: 2
            } as SimpleLineSymbolProperties
        }));
    }

    private handleCoordinateClicked(latitude: number, longitude: number) {
        if(!this.enabled || !this.coordinates) {
            return;
        }

        let hasFrom = !!(this.coordinates.gpsLongitudeFrom && this.coordinates.gpsLatitudeFrom);
        const hasTo = !!(this.coordinates.gpsLongitudeTo && this.coordinates.gpsLatitudeTo);

        // If both coordinates are known then clear coordinates
        if (hasFrom && hasTo) {
            this.coordinates.gpsLatitudeTo = null;
            this.coordinates.gpsLongitudeTo = null;
            this.coordinates.gpsLatitudeFrom = null;
            this.coordinates.gpsLongitudeFrom = null;
            hasFrom = false;
        }

        if (hasFrom) {
            this.coordinates.gpsLatitudeTo = latitude;
            this.coordinates.gpsLongitudeTo = longitude;
        } else {
            this.coordinates.gpsLatitudeFrom = latitude;
            this.coordinates.gpsLongitudeFrom = longitude;
        }

            this.coordinatesPicked.emit(this.coordinates);
            this.drawCoordinates();
    }

    private async initializeMap() {
        try {
            // Configure the Map
            this.map = new Map({basemap: 'topo-vector'});
            // Initialize the MapView
            this.mapView = new MapView({
                container: this.mapContainer?.nativeElement,
                map: this.map,
                center: [5.1060327, 51.9843037],
                zoom: 12
            });

            this.graphicsLayer = new GraphicsLayer();
            this.map.add(this.graphicsLayer);

            const locate = new Locate({
                view: this.mapView
            });

            this.mapView.ui.add(locate, 'top-left');

            this.drawCoordinates();

            if (this.coordinates?.gpsLatitudeFrom) {
                this.graphicsLayer.when().then(() => this.mapView?.goTo(this.graphicsLayer?.graphics));
            }

            this.mapView.on('click', event => {
                this.handleCoordinateClicked(event.mapPoint.latitude, event.mapPoint.longitude);
            });
        } catch (error) {
            console.error('EsriLoader: ', error);
        }
    }
}
