import {Pipe, PipeTransform} from '@angular/core';
import {projectJobStatusTitles} from '../models/project-job';

@Pipe({
    name: 'ProjectJobStatus',
})
export class ProjectJobStatusPipe implements PipeTransform {
    transform(value: keyof typeof projectJobStatusTitles) {
        if (!value) {
            return '';
        }

        return projectJobStatusTitles[value] || '';
    }
}
