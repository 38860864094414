import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {TableFilter} from '../components/table/table.component';
import {Filters} from '../utils/filters';
import {PageResponse} from '../models/page-response';
import {AuthenticationProviderConfig} from '../models/authentication-provider-config';
import {Observable} from 'rxjs';

export interface AuthenticationProviderConfigService {
    getRegistrations(page: number, filter?: TableFilter): Observable<PageResponse<AuthenticationProviderConfig>>;
    getRegistration(id: number): Observable<AuthenticationProviderConfig>;
    putRegistration(registration: AuthenticationProviderConfig): Observable<AuthenticationProviderConfig>;
    postRegistration(registration: AuthenticationProviderConfig): Observable<AuthenticationProviderConfig>;
    exists(tenantId: string): Observable<number>;
}

@Injectable()
export class AuthenticationProviderConfigServiceImpl implements AuthenticationProviderConfigService {

    constructor(private httpClient: HttpClient) {
    }

    getRegistrations(page: number, filter?: TableFilter) {
        let params = new HttpParams().set('page', '' + page);

        if (filter) {
            params = Filters.applyFiltering(filter, params);
        }

        return this.httpClient.get<PageResponse<AuthenticationProviderConfig>>('/api/v1/registrations', {params});
    }

    getRegistration(id: number) {
        return this.httpClient.get<AuthenticationProviderConfig>(`api/v1/registrations/${id}`);
    }

    putRegistration(registration: AuthenticationProviderConfig) {
        return this.httpClient.put<AuthenticationProviderConfig>(`api/v1/registrations/${registration.id}`, registration);
    }

    postRegistration(registration: AuthenticationProviderConfig) {
        return this.httpClient.post<AuthenticationProviderConfig>('api/v1/registrations', registration);
    }

    exists(tenantId: string) {
        return this.httpClient.get<number>(`api/v1/registrations/exists`, {
            params: {tenantId}
        });
    }
}
