// @ts-strict-ignore
import {Component, Input} from '@angular/core';
import {Paginator} from '../../utils/paginator';

@Component({
    selector: 'app-paginator',
    templateUrl: './paginator.component.html'
})
export class PaginatorComponent {
    @Input() paginator: Paginator<any>;
}
