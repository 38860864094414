import {Component, EventEmitter, HostListener, Input, Output} from '@angular/core';

@Component({
  selector: 'app-add-btn',
  templateUrl: './add-btn.component.html'
})
export class AddBtnComponent {
    @Input() hasActions = false;
    @Input() actionsVisible = false;

    @Output() add = new EventEmitter<void>();

    @HostListener('document:click')
    @HostListener('document:touchstart')
    onDocumentClick() {
        if (this.actionsVisible) {
            this.hideActions();
        }
    }

    onClick(event: Event) {
        event.stopPropagation();
        event.preventDefault();

        if (this.hasActions) {
            this.showActions();
        } else {
            this.add.emit();
        }
    }

    showActions() {
        this.actionsVisible = true;
    }

    hideActions() {
        this.actionsVisible = false;
    }
}
