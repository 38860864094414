import {Component, Inject} from "@angular/core";
import {ObstructionCodeService} from "../../services/obstruction-code.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Observable} from "rxjs";
import {TableFilter} from "../../components/table/table.component";
import {AbstractListComponent} from "../abstract-list.component";
import {PaulaObstructionCode} from "../../models/paula-obstruction-code";
import {PageResponse} from "../../models/page-response";

@Component({
    selector: 'app-obstruction-code-list',
    templateUrl: './obstruction-code-list.component.html'
})
export class ObstructionCodeListComponent extends AbstractListComponent<PaulaObstructionCode> {
    tableHeaders = [
        {sortKey: 'code', filterKey: 'code', title: 'Obstructiecode'},
        {sortKey: 'name', filterKey: 'name', title: 'Naam'}
    ];

    constructor(
        @Inject('ObstructionCodeService') private obstructionCodeService: ObstructionCodeService,
        protected activatedRoute: ActivatedRoute,
        protected router: Router,
    ) {
        super(activatedRoute, router)
    }

    loadData(page: number, filter: TableFilter, search: string | undefined): Observable<PageResponse<PaulaObstructionCode>> {
        return this.obstructionCodeService.getObstructionCodes(page, filter)
    }
}
