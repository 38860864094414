import {TableFilter} from '../components/table/table.component';
import {Observable} from 'rxjs';
import {PageResponse} from '../models/page-response';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Filters} from '../utils/filters';
import {Tag} from '../models/tag';

export interface TagService {
    getTag(id: number): Observable<Tag>;
    getTags(page: number, filter?: TableFilter): Observable<PageResponse<Tag>>;
    postTag(tag: Tag): Observable<Tag>;
    exists(name: string): Observable<number>;
}

@Injectable()
export class TagServiceImpl implements TagService {

    constructor(private httpClient: HttpClient) {
    }

    getTag(id: number): Observable<Tag> {
        return this.httpClient.get<Tag>(`/api/v1/tags/${id}`);
    }

    getTags(page: number, filter?: TableFilter) {
        let params = new HttpParams().set('page', '' + page);

        if (filter) {
            params = Filters.applyFiltering(filter, params);
        }

        return this.httpClient.get<PageResponse<Tag>>(`/api/v1/tags`, {params});
    }

    postTag(tag: Tag) {
        return this.httpClient.post<Tag>('api/v1/tags', tag);
    }

    exists(name: string) {
        const params = new HttpParams().set('name', name);

        return this.httpClient.get<number>(`api/v1/tags/exists`, {params});
    }
}
