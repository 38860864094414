<div class="vwui-action-menu" [class.d-none]="isMenuEmpty()">
    <button class="vwui-action-menu__trigger" (click)="openMenu($event)">
        <app-icon iconName="more-vertical"></app-icon>
    </button>
    <div class="vwui-action-menu__content" [class.is-open]="menuOpen" [class.grow-upward]="growUpward">
        <div class="vwui-menu" #menuContent>
            <ng-content></ng-content>
        </div>
    </div>
</div>
