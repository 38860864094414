import {Component, forwardRef, Inject, Input, OnChanges, SimpleChanges} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {EntitySelectComponent} from './item-select.component';
import {createEmptyPageResponse, PageResponse} from '../../models/page-response';
import {ProjectForm} from '../../models/form-type';
import {Project} from '../../models/project';
import {ProjectFormService} from '../../services/project-form.service';

@Component({
    selector: 'app-project-form-select',
    templateUrl: './item-select.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ProjectFormSelectComponent),
            multi: true
        },
    ]
})
export class ProjectFormSelectComponent extends EntitySelectComponent<ProjectForm, {project: Project | null}> implements OnChanges {
    @Input() projectFilter: Project | null = null;
    @Input() showCode: boolean = false;

    filter?: Subject<{project: Project | null}>;

    constructor(
        @Inject('ProjectFormService') private projectFormService: ProjectFormService,
    ) {
        super();

        this.placeholder = 'Selecteer een formulier uit het project';
    }

    getFilter(): Observable<{ project: Project | null }> {
        if (!this.filter) {
            this.filter = new BehaviorSubject({project: this.projectFilter});
        }

        return this.filter;
    }

    loadMore(page: number, filter: {project: Project, term: string}): Observable<PageResponse<ProjectForm>> {
        if (filter.project) {
            return this.projectFormService.getList(filter.project.id, page, {
                filter: {
                    title: filter && filter.term ? filter.term : ''
                }
            });
        }

        return of(createEmptyPageResponse<ProjectForm>());
    }

    getLabelText(item: ProjectForm) {
        return this.showCode
            ? `${item.title} - ${item.code}`
            : item.title;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.projectFilter) {
            this.filter?.next({project: changes.projectFilter.currentValue});
        }
    }
}
