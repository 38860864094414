// @ts-strict-ignore
import {ProjectAddAuthorizationRequestInterface, ProjectAuthorizationsService} from '../project-authorizations.service';
import {Observable, of} from 'rxjs';
import {mockProjects} from './project.service.mock';
import {ProjectAuthorization} from '../../models/project-authorization';
import {Injectable} from '@angular/core';
import {Project} from '../../models/project';
import {mockUsers} from './graph.service.mock';
import {mockExecutionGroups} from './execution-group.service.mock';
import {GraphUserReference} from '../../models/graph-user';
import {mockProjectExecutionGroups} from './project-execution-group.service.mock';

@Injectable()
export class ProjectAuthorizationsServiceMock implements ProjectAuthorizationsService {

    getAuthorizations(projectId: number): Observable<Array<ProjectAuthorization>> {
        const foundProject = mockProjects.find(project => project.id === projectId);

        return of(foundProject.authorizations);
    }

    addAuthorization(projectId: number, request: ProjectAddAuthorizationRequestInterface): Observable<Project> {
        const foundUser = mockUsers.find(user => user.username === request.graphUser.username);
        const foundProject = mockProjects.find(project => project.id === projectId);
        const matchingExecutionGroups = mockExecutionGroups
            .filter(executionGroup => request.executionGroupIds.indexOf(executionGroup.id) !== -1);

        const matchingProjectExecutionGroups = mockProjectExecutionGroups
            .filter(executionGroup => request.projectExecutionGroupIds.indexOf(executionGroup.id) !== -1);

        foundProject.authorizations = [...foundProject.authorizations, {
            executionGroups: matchingExecutionGroups,
            projectExecutionGroups: matchingProjectExecutionGroups,
            user: foundUser,
            project: projectId
        }];

        return of(foundProject);
    }

    removeAuthorization(projectId: number, request: {graphUser: GraphUserReference}): Observable<Project> {
        const foundProject = mockProjects.find(project => project.id === projectId);
        foundProject.authorizations = foundProject.authorizations
            .filter(authorization => {
                return !(authorization.user.username === request.graphUser.username
                    && authorization.user.tenantId === request.graphUser.tenantId
                );
            });

        return of(foundProject);
    }
}
