import {HttpClient, HttpParams} from '@angular/common/http';
import {PageResponse} from '../models/page-response';
import {PaulaObjectType} from '../models/paula-object-type';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {TableFilter} from '../components/table/table.component';
import {Filters} from '../utils/filters';

export interface ObjectTypeService {
    getObjectTypes(page: number, filter?: TableFilter): Observable<PageResponse<PaulaObjectType>>;
    getObjectType(id: number): Observable<PaulaObjectType>;
    putObjectType(objectType: PaulaObjectType): Observable<PaulaObjectType>;
    postObjectType(objectType: PaulaObjectType): Observable<PaulaObjectType>;
    exists(title: string): Observable<number>;
    codeExists(code: string): Observable<number>;
}

@Injectable()
export class ObjectTypeServiceImpl implements ObjectTypeService {
    constructor(private httpClient: HttpClient) {
    }

    getObjectTypes(page: number, filter?: TableFilter) {
        let params = new HttpParams().set('page', '' + page);

        if (filter) {
            params = Filters.applyFiltering(filter, params);
        }

        return this.httpClient.get<PageResponse<PaulaObjectType>>('/api/v1/object-types', {params});
    }

    getObjectType(id: number) {
        return this.httpClient.get<PaulaObjectType>(`api/v1/object-types/${id}`);
    }

    putObjectType(objectType: PaulaObjectType) {
        return this.httpClient.put<PaulaObjectType>(`api/v1/object-types/${objectType.id}`, objectType);
    }

    postObjectType(objectType: PaulaObjectType) {
        return this.httpClient.post<PaulaObjectType>('api/v1/object-types', objectType);
    }

    exists(title: string) {
        const params = new HttpParams().set('title', title);

        return this.httpClient.get<number>(`api/v1/object-types/exists`, {params});
    }

    codeExists(code: string) {
        const params = new HttpParams().set('code', code);

        return this.httpClient.get<number>(`api/v1/object-types/exists`, {params});
    }
}
