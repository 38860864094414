<div class="vwui-card">
    <div class="vwui-card__header">{{'Bijlages'|translate}}
        <div class="ml-auto">({{ getFileCount() }} / {{ maxFileCount }})</div>
    </div>
    <div class="vwui-list">
        <div class="vwui-list-item" [class.d-none]="disabled || files.length >= maxFileCount">
            <ngx-file-drop
                class="app-file-upload__file-drop"
                [showBrowseBtn]="true"
                [directory]="false"
                [browseBtnLabel]="'Klik hier om een bijlage toe te voegen'|translate"
                browseBtnClassName="app-file-upload__file-drop__browse-btn"
                (onFileDrop)="onDropped($event)"
            ></ngx-file-drop>
        </div>
        <div *ngFor="let file of pendingFiles"
             class="vwui-list-item has-progress"
             [style.--progress]="file.progress">
            <div class="vwui-list-item__content vwui-list-item__content--auto-height">
                {{ file.file.name }}
                <div class="vwui-spinner"></div>
            </div>
        </div>

        <div class="vwui-list-item has-action"
             *ngFor="let file of files"
             (click)="onFileClicked(file)">
            <div class="vwui-list-item__content vwui-list-item__content--auto-height">
                {{ file.originalFilename }}
                <button tooltip="{{'Verwijderen'|translate}}" type="button" class="vwui-icon-btn"
                        [class.d-none]="disabled"
                        (click)="removeFile($event, file)">
                    <app-icon iconName="trash-2" tooltip="{{'Verwijderen'|translate}}"></app-icon>
                </button>
            </div>
        </div>
    </div>
</div>


