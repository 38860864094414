<app-modal modalTitle="{{'Gebruiker aanmaken'|translate}}" modalSaveBtnLabel="Opslaan" (save)="submit()"
           (cancel)="closeModal()" [loadingSaveBtn]="saving">
    <form [formGroup]="form">
        <div class="u-mb-m">
            <div [appFormControlError]="form.controls.graph" class="vwui-form-group mb-4">
                <label for="graph" class="vwui-label">{{'Inlogmethode'|translate}}</label>
                <app-graph-select id="graph" formControlName="graph">
                </app-graph-select>
            </div>
        </div>
        <div class="u-mb-m">
            <div [appFormControlError]="form.controls.user" class="vwui-form-group">
                <label class="vwui-label" for="user">{{'Gebruiker'|translate}}</label>
                <app-graph-user-select id="user" formControlName="user" [graphId]="form.controls.graph.value?.id">
                </app-graph-user-select>
            </div>
        </div>
        <button type="submit" class="d-none"><!-- Needed to submit with enter --></button>
    </form>
</app-modal>
