<app-toolbar [toolbarTitle]="formTitle" [showBackBtn]="true" (back)="goBack()"></app-toolbar>
<div class="vwui-container is-small">
    <div class="vwui-card">
        <form [formGroup]="form">
            <div class="vwui-card__header">
                <div class="vwui-card__title">{{ 'Kopieer formulier'|translate }}</div>
            </div>
            <div class="vwui-card__content">
                <div class="vwui-form-group mb-4">
                    <label for="status" class="vwui-label">{{ 'Status'|translate }}</label>
                    <ng-select id="status" formControlName="status" [items]="formTypeStatuses" [clearable]="false"
                               bindLabel="label" bindValue="value">
                    </ng-select>
                </div>
                <div class="vwui-form-group mb-4">
                    <label for="title" class="vwui-label">{{ 'Formuliernaam'|translate }}</label>
                    <input id="title" type="text" class="vwui-input" formControlName="title">
                </div>
                <div [appFormControlError]="form.get('code')" class="vwui-form-group mb-4">
                    <label for="code" class="vwui-label">{{ 'Formuliernummer'|translate }}</label>
                    <input id="code" name="code" type="text" class="vwui-input" formControlName="code">
                    <div class="badge badge-info" *ngIf="showFormNumberHelp">
                        {{ 'Formuliernummer is reeds in gebruik'|translate }}
                    </div>
                </div>
                <div class="vwui-form-group mb-4">
                    <label for="paulaObjectTypes" class="vwui-label">{{ 'Objecttypes'|translate }}</label>
                    <app-paula-object-type-select id="paulaObjectTypes"
                                                  formControlName="paulaObjectTypes" [multiple]="true">
                    </app-paula-object-type-select>
                </div>
                <div class="vwui-form-group mb-4">
                    <label for="companies" class="vwui-label">{{ 'Bedrijven'|translate }}</label>
                    <app-company-select id="companies"
                                        formControlName="companies" [multiple]="true">
                    </app-company-select>
                </div>
                <div [appFormControlError]="form.get('tags')" class="vwui-form-group mb-4">
                    <label for="tags" class="vwui-label">{{'Tags'|translate}}</label>
                    <app-tag-select id="tags" formControlName="tags" [multiple]="true"></app-tag-select>
                </div>
                <div class="vwui-form-group mb-4">
                    <label for="contactPerson" class="vwui-label">{{ 'Contactpersoon'|translate }}</label>
                    <app-user-select id="contactPerson" formControlName="contactPerson"></app-user-select>
                </div>
                <div [appFormControlError]="form.get('revisionNumber')" class="vwui-form-group mb-4">
                    <label for="revisionNumber" class="vwui-label">{{ 'Revisienummer'|translate }}</label>
                    <input id="revisionNumber" name="revisionNumber" type="text" class="vwui-input" formControlName="revisionNumber"
                           placeholder="{{ 'Revisienummer'|translate }}">
                </div>
                <div class="vwui-form-group mb-4">
                    <label class="vwui-input-control is-toggle has-label mb-4 vwui-label">
                        <input class="vwui-input" type="checkbox" formControlName="askLocation">
                        <span>{{'Vraag locatie (uit / aan)'|translate}}</span>
                        <span class="checkbox"></span>
                    </label>
                </div>
                <div class="vwui-form-group mb-4" *ngIf="form.get('askLocation').value" >
                    <label class="vwui-input-control is-toggle has-label mb-4 vwui-label">
                        <input class="vwui-input" type="checkbox" formControlName="showLocationOnMap">
                        <span>{{'Toon locatie op kaart (uit / aan)'|translate}}</span>
                        <span class="checkbox"></span>
                    </label>
                </div>
            </div>
        </form>
    </div>
</div>
<app-actionbar *ngIf="form.dirty" (cancel)="cancel()" (save)="save()" [saveDisabled]="saving"></app-actionbar>
