import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html'
})
export class SearchComponent {
    @Input() placeholder = 'Zoeken...';
    @Input() searchControl = this.fb.control('');

    @Output() searchChanged = new EventEmitter<string>();

    constructor(private fb: UntypedFormBuilder) {
        this.searchControl.valueChanges.subscribe(val => this.searchChanged.emit(val));
    }
}
