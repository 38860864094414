// @ts-strict-ignore
import {Component, OnDestroy, OnInit} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {FeatureToggle} from "../../models/feature-toggle";
import {RecoveryTime, recoveryTimeSelectOptions} from "../../models/recovery-time";
import {Subscription} from "rxjs";
import {startWith} from "rxjs/operators";
import {
    determineFollowUpType,
    followUpChoices,
    NumberQuestionFollowUp,
    NumberQuestionFollowUpType
} from "../../models/number-question-follow-up";
import {FormType} from "../../models/form-type";
import {actionTakers} from "../../models/action-taker";
import {Forms} from "../../utils/forms";
import {ModalResult} from "../../models/modal.result";
import {Project} from "../../models/project";


@Component({
    selector: 'app-follow-up-number-question-modal',
    templateUrl: './follow-up-number-question-modal.component.html'
})
export class FollowUpNumberQuestionModalComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];

    onClose: (result: ModalResult, value: NumberQuestionFollowUp) => void;
    project: Project | null = null;

    readonly form = new FormGroup({
        id: new FormControl<string | null>(null),

        type: new FormControl<NumberQuestionFollowUpType>(NumberQuestionFollowUpType.Between),
        lessThan: new FormControl(undefined, Validators.required),
        greaterThan: new FormControl(undefined, Validators.required),

        title: new FormControl<string>('', [Validators.required, Validators.maxLength(255)]),
        formType: new FormControl<FormType | null>(null),
        recoveryTime: new FormControl<RecoveryTime | null>(null),
        actionTaker: new FormControl<string | null>(null)
    })

    readonly maximoToggle = FeatureToggle.Maximo;
    readonly actionTakers = actionTakers
    readonly recoveryTimes = recoveryTimeSelectOptions;
    readonly followUpChoices = followUpChoices

    set formValue(value: NumberQuestionFollowUp) {
        this.form.patchValue({
            ...value,
            id: value.id || null,
            type: determineFollowUpType(value)
        }, {emitEvent: false})
    }

    set type(type: NumberQuestionFollowUpType) {
        this.form.controls['type'].setValue(type)
    }

    constructor(
        protected bsModalRef: BsModalRef
    ) {
    }

    ngOnInit() {
        this.subscriptions.push(this.form.controls['type'].valueChanges.pipe(
            startWith(this.form.controls['type'].value)
        ).subscribe(type => {
            switch (type) {
                case NumberQuestionFollowUpType.LessThan:
                    this.form.controls.lessThan.enable()
                    this.form.controls.greaterThan.disable()
                    break;
                case NumberQuestionFollowUpType.Between:
                    this.form.controls.lessThan.enable()
                    this.form.controls.greaterThan.enable()
                    break;
                case NumberQuestionFollowUpType.GreaterThan:
                    this.form.controls.lessThan.disable()
                    this.form.controls.greaterThan.enable()
                    break;
            }
        }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach(it => it.unsubscribe());
    }

    submit() {
        if (!this.form.valid) {
            Forms.updateValueAndValidityRecursive(this.form);
            return;
        }

        const {type, ...followUp} = {
            // Set to null and let formValue overwrite to prevent undefined fields
            lessThan: null,
            greaterThan: null,
            ...this.form.value
        }

        this.closeModal('confirmed', followUp as NumberQuestionFollowUp)
    }

    closeModal(result: ModalResult, data: NumberQuestionFollowUp = null) {
        this.onClose(result, data);
        this.bsModalRef.hide();
    }
}
