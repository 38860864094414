import {Component, Input} from '@angular/core';
import {FormItem} from '../../models/form-item';
import {FormUtils} from '../../utils/form-utils';

@Component({
    selector: 'app-form-viewer',
    templateUrl: './form-viewer.component.html'
})
export class FormViewerComponent {
    @Input() formItems: FormItem[] = [];

    public dependentQuestionPosition(formItem: FormItem) {
        return FormUtils.dependentQuestionPosition(formItem, this.formItems);
    }

    public getChapterNumber(formPosition: number) {
        return FormUtils.getChapterNumberFromPosition(formPosition);
    }

    public getChapterPosition(formPosition: number) {
        return FormUtils.getChapterPositionFromPosition(formPosition);
    }

}
