// @ts-strict-ignore
import {Injectable} from '@angular/core';
import {AbstractCrudServiceMock} from './abstract-crud.service.mock';
import {Observable, of} from 'rxjs';
import {PageResponse} from '../../models/page-response';
import {TableFilter} from '../../components/table/table.component';
import {Tag} from '../../models/tag';
import {TagService} from '../tag.service';

export const mockTags: Array<Tag> = [
    {
        id: 1,
        name: 'Infrastructuur',
        className: '1'
    },
    {
        id: 2,
        name: 'CO2',
        className: '2'
    }
];

@Injectable()
export class TagServiceMock extends AbstractCrudServiceMock<Tag> implements TagService {
    constructor() {
        super(mockTags);
    }

    getTags(page: number, filter?: TableFilter): Observable<PageResponse<Tag>> {
        return this.getAll(filter);
    }

    exists(name: string): Observable<number> {
        const result = mockTags.find(item => item.name === name);

        return of(result ? result.id : null);
    }

    getTag(id: number): Observable<Tag> {
        return this.get(id);
    }

    postTag(tag: Tag): Observable<Tag> {
        return this.post(tag);
    }

}
