<div *appHasFeatureToggle="customersEnabled">
    <app-toolbar
        [showAddBtn]="true"
        [addBtnRoute]="'/beheer/customers/new'"
        addBtnLabel="{{'Nieuwe opdrachtgever'|translate}}">
    </app-toolbar>
    <div class="vwui-container is-full">
        <app-table
            [paginator]="paginator"
            [headerTitles]="[{sortKey: 'name', filterKey: 'name', title: 'Opdrachtgevers'}, {title:''}]"
            (filterChanged)="onTableFilterChange($event)">
            <ng-template let-item>
                <tr [routerLink]="['/beheer/customers/', item.id]">
                    <td>
                        {{ item.name }} <br>
                        <small class="text-muted">{{ item.description }}</small>
                    </td>
                    <td class="vwui-table__fixed-column-width vwui-multi-select-table--unclickable">
                        <div class="vwui-table__actions">
                            <app-action-menu #actionMenu>
                                <button type="button" class="vwui-menu-item is-danger" (click)="deleteCustomer(item)">
                                    <app-icon iconName="trash-2"></app-icon>
                                    {{ 'Verwijderen'|translate }}
                                </button>
                            </app-action-menu>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </app-table>
    </div>
</div>

