<app-toolbar [showBackBtn]="true"></app-toolbar>
<div class="vwui-container">
    <div class="vwui-card">
        <div class="vwui-card__header">{{'Inlogmethode'|translate}}</div>
        <div class="vwui-card__content">
            <form [formGroup]="form" (submit)="save()" [class.form-is-valid]="form.valid">
                <div class="vwui-form-group mb-4" [appFormControlError]="form.get('title')">
                    <label for="title" class="vwui-label">{{'Titel'|translate}}</label>
                    <input id="title" class="vwui-input" name="title" type="text" formControlName="title"
                           autocomplete="off">
                </div>
                <div class="row">
                    <div class="col-sm-6 col-xl-3 vwui-form-group mb-4"
                         [appFormControlError]="form.get('brandingTextColor')">
                        <label for="brandingTextColor" class="vwui-label">{{'Inlogknop tekstkleur'|translate}}</label>
                        <input id="brandingTextColor" class="vwui-input" name="tenantId" type="color"
                               formControlName="brandingTextColor">
                    </div>
                    <div class="col-sm-6 order-sm-1 col-xl-3 vwui-form-group mb-4"
                         [appFormControlError]="form.get('brandingFirstColor')">
                        <label for="brandingFirstColor"
                               class="vwui-label">{{'Inlogknop achtergrond links'|translate}}</label>
                        <input id="brandingFirstColor" class="vwui-input" name="tenantId" type="color"
                               formControlName="brandingFirstColor">
                    </div>
                    <div class="col-sm-6 order-sm-2 col-xl-3 vwui-form-group mb-4"
                         [appFormControlError]="form.get('brandingSecondColor')">
                        <label for="brandingSecondColor"
                               class="vwui-label">{{'Inlogknop achtergrond rechts'|translate}}</label>
                        <input id="brandingSecondColor" class="vwui-input" name="tenantId" type="color"
                               formControlName="brandingSecondColor">
                    </div>
                    <div class="col-sm-6 order-sm-0 col-xl-3 order-xl-3 mb-4">
                        <label class="vwui-label">
                            Inlogknop preview
                        </label>
                        <button class="vwui-btn is-block is-login" type="button"
                                [style.--btn-color]="form.get('brandingTextColor').value"
                                [style.--btn-bg-left]="form.get('brandingFirstColor').value"
                                [style.--btn-bg-right]="form.get('brandingSecondColor').value">
                            {{ form.get('title').value || 'Inloggen' }}
                        </button>
                    </div>
                </div>

                <div class="vwui-form-group mb-4">
                    <label for="defaultProject" class="vwui-label">
                        {{'Standaard project'|translate}} {{'Standaard project toelichting'|translate}}
                    </label>
                    <app-project-select id="defaultProject" formControlName="defaultProject">
                    </app-project-select>
                </div>

                <div class="vwui-form-group mb-4">
                    <label class="vwui-label">{{ 'Soort inlogmethode'|translate }}</label>
                    <div class="vwui-options">
                        <label class="vwui-options__option" [class.is-active]="form.get('type').value === 'azure'">
                            {{'Azure'|translate}}
                            <input name="type" type="radio" value="azure" formControlName="type">
                        </label>
                        <label class="vwui-options__option" [class.is-active]="form.get('type').value === 'openid'">
                            {{'OpenID Connect'|translate}}
                            <input name="type" type="radio" value="openid" formControlName="type">
                        </label>
                    </div>
                </div>

                <div class="vwui-form-group mb-4"
                     [appFormControlError]="form.get('loginClientId')">
                    <label for="loginClientId" class="vwui-label">
                        {{'Login' | translate}} {{'application(client) ID'|translate}}
                    </label>
                    <input id="loginClientId" class="vwui-input" name="loginClientId" type="text"
                           formControlName="loginClientId"
                           autocomplete="off">
                </div>
                <div class="vwui-form-group mb-4"
                     [appFormControlError]="form.get('loginSecret')">
                    <label for="loginSecret" class="vwui-label">
                        {{'Login'|translate}} {{'secret'|translate}}
                    </label>
                    <input id="loginSecret" class="vwui-input" name="loginSecret" type="text"
                           formControlName="loginSecret"
                           autocomplete="off">
                </div>
                <div *ngIf="form.get('loginTenantId').enabled" class="vwui-form-group mb-4"
                     [appFormControlError]="form.get('loginTenantId')">
                    <label for="loginTenantId" class="vwui-label">
                        {{'Login'|translate}} {{'directory(tenant) ID'|translate}}
                    </label>
                    <input id="loginTenantId" class="vwui-input" name="tenantId" type="text"
                           formControlName="loginTenantId"
                           autocomplete="off">
                </div>

                <div *ngIf="form.get('tenantId').enabled" class="vwui-form-group mb-4"
                     [appFormControlError]="form.get('tenantId')">
                    <label for="tenantId" class="vwui-label">
                        {{'Service-account'|translate}} {{'Directory(tenant) ID'|translate}}
                    </label>
                    <input id="tenantId" class="vwui-input" name="tenantId" type="text" formControlName="tenantId"
                           autocomplete="off">
                </div>
                <div *ngIf="form.get('clientId').enabled" class="vwui-form-group mb-4"
                     [appFormControlError]="form.get('clientId')">
                    <label for="clientId" class="vwui-label">
                        {{'Service-account'|translate}} {{'application(client) ID'|translate}}
                    </label>
                    <input id="clientId" class="vwui-input" name="clientId" type="text" formControlName="clientId"
                           autocomplete="off">
                </div>
                <div *ngIf="form.get('secret').enabled" class="vwui-form-group mb-4"
                     [appFormControlError]="form.get('secret')">
                    <label for="secret" class="vwui-label">
                        {{'Service-account'|translate}} {{'secret'|translate}}
                    </label>
                    <input id="secret" class="vwui-input" name="secret" type="text" formControlName="secret"
                           autocomplete="off">
                </div>

                <div *ngIf="form.get('openidConfiguration').enabled" class="vwui-form-group mb-4"
                     [appFormControlError]="form.get('openidConfiguration')">
                    <label for="openidConfiguration" class="vwui-label">
                        {{'OpenID Connect configuratie-url'|translate}}
                    </label>
                    <input id="openidConfiguration" class="vwui-input" name="openidConfiguration" type="text" formControlName="openidConfiguration"
                           autocomplete="off">
                </div>

                <div *ngIf="config && config.id && form.get('type').value === 'azure'" class="vwui-form-group mb-4">
                    <label for="secret" class="vwui-label">
                        {{'Service-account'|translate}} {{'Beheerderstoestemming'|translate}}
                    </label>
                    <span *ngIf="config.consentGranted; else noConsent" class="vwui-badge is-success text-nowrap">
                        <app-icon iconName="check"></app-icon>
                        {{'Beheerderstoestemming'|translate}}
                    </span><br>
                    <ng-template #noConsent>
                        <span class="vwui-badge is-danger text-nowrap"><app-icon iconName="x"></app-icon>
                            {{'Geen beheerderstoestemming'|translate}}
                        </span>
                    </ng-template>
                    <br>
                    <a class="vwui-btn" [href]="getConsentUrl()">{{'Beheerderstoestemming verlenen'|translate}}</a>
                </div>
                <button type="submit" class="d-none"><!-- Needed to submit with enter --></button>
            </form>
        </div>
    </div>
</div>

<app-actionbar *ngIf="form.dirty" (cancel)="goBack()" (save)="save()"></app-actionbar>
