// @ts-strict-ignore
import {Directive, HostBinding, Input} from '@angular/core';
import {BlobService} from '../services/blob.service';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';

@Directive({
    selector: '[appBlobHref]'
})
export class BlobHrefDirective {

    // eslint-disable-next-line max-len
    private readonly placeholder = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJYAAACWCAMAAAAL34HQAAAAG1BMVEXMzMyWlpaqqqq3t7fFxcW+vr6xsbGjo6OcnJyLKnDGAAAA8UlEQVR42u3UjarDMAhAYY2J+v5PfGc3oQHGfwOXnQ8oYRyISFfB/wYAAAAAAAAAAAAAAAAAAKAi4vridci0L1FXHT1raD3C3ZeIqbvOL1FXHT3IVOvGMLmEiwyXl7p4qt2irjp60lx2jbWmlFqCpZQcMmKLuuroUbNu1FAddZ4iK6WYmq5b1FVHR8byudLf53qU0LFFXXV0YKxiuS/C1baoq4PbKkv31yYz9qirY++W1a0enz/ZkOK51O5RVx0d2FaGudrnu7XeP5uM3KKuOnp+rBWaJvev/MhrtHvUVUcAAAAAAAAAAAAAAAAAAOCX/QFU6QRUeVG11wAAAABJRU5ErkJggg==';

    @HostBinding('src') src: SafeUrl;

    constructor(
        private blobService: BlobService,
        private sanitizer: DomSanitizer
    ) {
        this.src = this.placeholder;
    }

    @Input() set photoUuid(value: string) {
        this.blobService.getBlob(value).toPromise().then((blob) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                this.src = this.sanitizer.bypassSecurityTrustUrl(`${reader.result}`);
            };
        });
    }
}
