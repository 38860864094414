// @ts-strict-ignore
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {TableFilter} from '../components/table/table.component';
import {Filters} from '../utils/filters';
import {PageResponse} from '../models/page-response';
import {BehaviorSubject, Observable} from 'rxjs';
import {AppleContentCode} from '../models/apple-content-code';
import {ContentCodesAmount} from '../models/content-codes-amount';
import {map, shareReplay, switchMap} from 'rxjs/operators';
import {AppleContentCodesImport} from '../models/apple-content-code-import';

export interface AppleContentCodeService {
    amountOfAvailableCodes$: Observable<number>;
    activateCode(): Observable<AppleContentCode>;
    getCodeForUser(username: string): Observable<AppleContentCode>;
    getImports(page: number, filter?: TableFilter): Observable<PageResponse<AppleContentCodesImport>>;
    uploadContentCodes(file: File): Observable<any>;
    changed(): void;
}

@Injectable()
export class AppleContentCodeServiceImpl implements AppleContentCodeService {
    private refresh$ = new BehaviorSubject<void>(null);

    public amountOfAvailableCodes$: Observable<number> = this.refresh$.pipe(
        switchMap(() => this.httpClient.get<ContentCodesAmount>('api/v1/apple-content-codes/amount-available-codes')),
        map(it => it.amount),
        shareReplay(1)
    );

    constructor(
        private httpClient: HttpClient,
    ) {
    }

    getImports(page: number, filter?: TableFilter) {
        let params = new HttpParams().set('page', '' + page);

        if (filter) {
            params = Filters.applyFiltering(filter, params);
        }

        return this.httpClient.get<PageResponse<AppleContentCodesImport>>(`/api/v1/apple-content-codes`, {params});
    }

    uploadContentCodes(file: File) {
        const data = new FormData();
        data.append('file', file, file.name);

        return this.httpClient.post(`api/v1/apple-content-codes/import`, data);
    }

    activateCode() {
        return this.httpClient.get<AppleContentCode>('api/v1/apple-content-codes/activate');
    }

    getCodeForUser(username: string) {
        return this.httpClient.get<AppleContentCode>('api/v1/apple-content-codes/user', {
            params: {username}
        });
    }

    changed() {
        this.refresh$.next();
    }
}
