// @ts-strict-ignore
import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {Project, ProjectExportTemplate} from '../../models/project';
import {ImageAnnotationModalComponent} from '../image-annotation-modal/image-annotation-modal.component';
import {v4 as uuid} from 'uuid';
import {FileUtil} from '../../utils/file';
import {ToastrService} from 'ngx-toastr';
import {FormControl, FormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {BsModalService} from 'ngx-bootstrap/modal';
import {ProjectService} from '../../services/project.service';
import {UploadService} from '../../services/upload.service';
import {firstValueFrom} from "rxjs";

@Component({
    selector: 'app-project-export-tab',
    templateUrl: './project-export-tab.component.html',
})
export class ProjectExportTabComponent implements OnInit {
    @Input() project: Project;
    @Output() projectSaved = new EventEmitter<Project>();

    public fileName = this.translateService.instant('Kies bestand');
    public file: File;
    public form = new FormGroup({
        logo: new FormControl<unknown>(null),
        gis: new FormControl<boolean>(null),
        exportTemplate: new FormControl<ProjectExportTemplate>(ProjectExportTemplate.DEFAULT)
    });

    constructor(
        @Inject('ProjectService') private projectService: ProjectService,
        @Inject('UploadService') private uploadService: UploadService,
        private translateService: TranslateService,
        private modalService: BsModalService,
        private toast: ToastrService,
    ) { }

    ngOnInit() {
        const {logo, gis, exportTemplate} = this.project

        this.form.setValue({logo, gis, exportTemplate})
    }

    async save() {
        const project = this.form.value as Project;
        const projectUpdated = {...this.project, ...project};

        try {
            let projectResponse: Project;
            if (projectUpdated.id) {
                projectResponse = await firstValueFrom(this.projectService.putProject(projectUpdated));

                this.projectSaved.emit(projectResponse);
                this.toast.success(this.translateService.instant('Opgeslagen'));
                this.form.patchValue(projectResponse);
            }
        } catch (error) {
            console.error('Unable to save project', error);
            this.toast.error(this.translateService.instant('Opslaan mislukt'));
        }
    }

    cancel() {
        history.back();
    }

    showImagePreview(imageSrc: string|null) {
        this.modalService.show(ImageAnnotationModalComponent, {
            ignoreBackdropClick: true,
            class: 'modal-xl bg-dark',
            initialState: {
                imageSrc,
                saveCallback: (async data => {
                    try {
                        const file = await this.uploadService.uploadImage(uuid(), FileUtil.dataURItoBlob(data)).toPromise();
                        this.form.controls.logo.setValue(file.id);
                        this.toast.success(this.translateService.instant('Afbeelding opgeslagen'));
                    } catch (e) {
                        this.toast.error(this.translateService.instant('Afbeelding opslaan mislukt'));
                        console.error(e);
                    }
                })
            } as Partial<ImageAnnotationModalComponent>
        });
    }
}
