// @ts-strict-ignore
import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-empty-state-message',
    templateUrl: './empty-state-message.component.html'
})
export class EmptyStateMessageComponent {
    @Input() title: string;
    @Input() imageSrc: string;
}
