import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {WorkerGroup} from '../models/worker-group';
import {Inject, Injectable} from '@angular/core';
import {WorkerGroupService} from '../services/worker-group.service';

@Injectable({
    providedIn: 'root'
})
export class WorkerGroupResolver implements Resolve<WorkerGroup> {

    constructor(
      @Inject('WorkerGroupService') private workerGroupService: WorkerGroupService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.workerGroupService.getWorkerGroup(+route.params.id);
    }
}
