// @ts-strict-ignore
import {Component, Inject} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {FileUploadEvent} from '../file-upload-btn/file-upload.interface';
import {AppleContentCodeService} from '../../services/apple-content-code.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {LoadingBtnEvent} from '../loading-btn/loading-btn.interface';
import {notificationThreshold} from '../../models/apple-content-code';

@Component({
    selector: 'app-apple-content-codes-import-modal',
    templateUrl: './apple-content-codes-import-modal.component.html'
})
export class AppleContentCodesImportModalComponent {
    file: File;
    fileName = this.translateService.instant('Kies bestand');
    onClose: () => void;
    public notificationThreshold = notificationThreshold;

    constructor(
        private modalRef: BsModalRef,
        @Inject('AppleContentCodeService') private appleContentCodeServiceService: AppleContentCodeService,
        private toast: ToastrService,
        private translateService: TranslateService
    ) {
    }

    cancel() {
        this.modalRef.hide();
    }

    async startUpload(event: LoadingBtnEvent) {
        try {
            await this.appleContentCodeServiceService.uploadContentCodes(this.file).toPromise();
            this.toast.success(this.translateService.instant('Import gelukt'));
        } catch (error) {
            let extraMessage = '';
            if (error && error.error && error.error.globalErrors[0]) {
                extraMessage = this.translateService.instant(error.error.globalErrors[0]);
            }

            this.toast.error(this.translateService.instant('Import mislukt') + (extraMessage.length ? `: ${extraMessage}` : ''));
        } finally {
            if (this.onClose) {
                this.onClose();
            }
            this.modalRef.hide();
            this.appleContentCodeServiceService.changed();
            event.complete();
        }
    }

    onFileChange(event: FileUploadEvent) {
        this.file = event.target.files[0];
        this.fileName = this.file.name;
    }
}
