import {AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {expand, first, map, scan, shareReplay, switchMap, takeLast} from 'rxjs/operators';
import {ProjectService} from '../../services/project.service';
import {ActivatedRoute, Router} from '@angular/router';

import {EMPTY, firstValueFrom, Subscription} from 'rxjs';
import {ProjectJobService} from '../../services/project-job.service';
import {ProjectMapConfig} from './project-map.config';
import {ProjectJob} from '../../models/project-job';
import {Project} from '../../models/project';
import {ObjectService} from '../../services/object.service';
import {Object as PaulaObject} from '../../models/object';
import {BsModalService} from 'ngx-bootstrap/modal';
import {
    NoJobsWithLocationModalComponent
} from '../../components/no-jobs-with-location-modal/no-jobs-with-location-modal.component';
import {HistoryService} from "../../services/history.service";

import Map from '@arcgis/core/Map';
import MapView from "@arcgis/core/views/MapView";
import GroupLayer from "@arcgis/core/layers/GroupLayer";
import Extent from "@arcgis/core/geometry/Extent";
import LayerList from "@arcgis/core/widgets/LayerList";
import Locate from "@arcgis/core/widgets/Locate";
import FeatureLayerProperties = __esri.FeatureLayerProperties;
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import Legend from "@arcgis/core/widgets/Legend";
import SpatialReference from "@arcgis/core/geometry/SpatialReference";
import PointProperties = __esri.PointProperties;
import SimpleRenderer from "@arcgis/core/renderers/SimpleRenderer";
import WebMap from "@arcgis/core/WebMap";
import {ArcgisService} from "../../services/arcgis.service";
import {configurePortalAndAuthentication} from "../../utils/arcgis-utils";
import Graphic from "@arcgis/core/Graphic";
import ListItem from "@arcgis/core/widgets/LayerList/ListItem";

@Component({
    selector: 'app-project-map',
    templateUrl: './project-map.component.html',
})
export class ProjectMapComponent implements AfterViewInit, OnDestroy {
    @ViewChild('mapContainer')
    private mapContainer: ElementRef | null = null;

    private map: Map | null = null;
    private mapView: MapView | null = null;
    private projectJobsLayerExtent: Extent | null = null;
    private currentLocationFailed = false;
    private subscriptions: Subscription[] = [];

    project$ = this.route.paramMap.pipe(
        switchMap(paramMap => this.projectService.getProject(+(paramMap.get('project') || 0))),
        shareReplay(1)
    );

    constructor(
        @Inject('ProjectService') private projectService: ProjectService,
        @Inject('ProjectJobService') private projectJobService: ProjectJobService,
        @Inject('ObjectService') private objectService: ObjectService,
        private route: ActivatedRoute,
        private router: Router,
        private modalService: BsModalService,
        private historyService: HistoryService,
        private arcgisService: ArcgisService
    ) {
    }

    async ngAfterViewInit(): Promise<void> {
        this.subscriptions.push(this.project$.subscribe(async project => {
            await this.initializeMap(project);

            await Promise.allSettled([
                this.initializeProjectMapLayers(project),
                this.initializeProjectJobsLayer(project),
                this.initializeProjectObjectsLayer(project)
            ])

            try {
                await this.mapView?.goTo(await this.getCurrentLocation());
            } catch (e) {
                console.warn('getCurrentPosition failed', e);
                this.currentLocationFailed = true;
                if (this.projectJobsLayerExtent) {
                    // Center on visible project jobs if available and gps failed
                    await this.mapView?.goTo(this.projectJobsLayerExtent);
                }
            }
        }))
    }

    ngOnDestroy(): void {
        this.mapView?.destroy();
        this.mapView = null;

        this.subscriptions.forEach(it => it.unsubscribe());
    }

    goBack() {
        this.route.paramMap.pipe(first()).subscribe(map => {
            this.historyService.goBack([`/beheer/projects/${map.get('project')}`]);
        })
    }

    private async initializeMap(project: Project) {
        try {
            this.mapView?.destroy()
            this.mapView = null

            if (project.configurationUrl) {
                await configurePortalAndAuthentication(
                    project.configurationUrl,
                    (portalToken, serverUrl) => firstValueFrom(
                        this.arcgisService.getToken(portalToken, serverUrl)
                    )
                )

                const itemId = project.configurationUrl.split('/').pop()
                this.map = new WebMap({
                    portalItem: {id: itemId}
                })
            } else {
                // Configure the Map
                this.map = new Map({
                    basemap: 'topo-vector'
                });
            }

            // Initialize the MapView
            this.mapView = new MapView({
                container: this.mapContainer?.nativeElement,
                map: this.map,
                center: [5.1060327, 51.9843037],
                zoom: 12,
                popup: {
                    collapseEnabled: false
                },
                highlightOptions: {
                    color: [100, 100, 100],
                    fillOpacity: 0,
                    haloOpacity: 1
                }
            });

            const layerList = new LayerList({
                view: this.mapView
            });
            const locate = new Locate({
                view: this.mapView,
                graphic: null as unknown as Graphic
            });

            const legend = new Legend({
                view: this.mapView,
            });

            this.mapView.ui.add(layerList, 'bottom-left');
            this.mapView.ui.add(locate, 'top-left');
            this.mapView.ui.add(legend, 'bottom-right');
        } catch (error) {
            console.error('Failed to initialize map', error);
        }
    }

    private async initializeProjectMapLayers(project: Project) {
        project.mapLayersData.forEach((mapLayer) => {
            if (!mapLayer.mapUrl) {
                return;
            }

            const featureLayerConfig: FeatureLayerProperties = {url: mapLayer.mapUrl};

            if (mapLayer.objectModalTitle || mapLayer.objectModalProperties || mapLayer.objectModalShowAttachments) {
                const mapLayerModalContent = [];

                if (mapLayer.objectModalProperties && mapLayer.objectModalProperties.length > 0) {
                    mapLayerModalContent.push({
                        type: 'fields',
                        fieldInfos: mapLayer.objectModalProperties,
                    });
                }

                if (mapLayer.objectModalShowAttachments) {
                    mapLayerModalContent.push({
                        type: 'attachments',
                    });
                }

                featureLayerConfig.popupTemplate = {
                    title: mapLayer.objectModalTitle || '',
                    content: mapLayerModalContent,
                };
            }

            const featureLayer = new FeatureLayer(featureLayerConfig);

            this.map?.add(featureLayer);
        });
    }

    private async initializeProjectJobsLayer(project: Project) {
        this.projectJobsLayerExtent = null;

        const jobs = await this.getProjectJobsWithCoordinates(project);
        if (jobs.length === 0) {
            this.modalService.show(NoJobsWithLocationModalComponent, {class: 'modal-dialog-centered'});
        }

        this.mapView?.popup?.on('trigger-action', (event) => {
            if (event.action.id === 'job-detail') {
                this.router.navigateByUrl(this.mapView?.popup.selectedFeature.attributes.href);
            }
        });

        const jobLayer = await this.createJobLayer(jobs);
        this.map?.add(jobLayer);

        if (jobs.length > 0) {
            this.projectJobsLayerExtent = jobLayer.fullExtent
            if (this.currentLocationFailed && this.projectJobsLayerExtent) {
                // Center on visible project jobs if available and gps failed
                await this.mapView?.goTo(this.projectJobsLayerExtent);
            }
        }
    }

    private async initializeProjectObjectsLayer(project: Project) {
        const objects = await this.getProjectObjectsWithCoordinates(project);

        this.map?.add(new FeatureLayer({
            title: 'Objecten',
            legendEnabled: false,
            fields: [
                {name: 'id', type: 'oid'},
                {name: 'objectId', type: 'string'},
                {name: 'objectOmschrijvingKort', type: 'string'},
            ],
            objectIdField: 'id',
            geometryType: 'point',
            spatialReference: SpatialReference.WGS84,
            source: objects.map((it) => ({
                geometry: {
                    type: 'point',
                    latitude: it.objectYvan,
                    longitude: it.objectXvan
                } as PointProperties,
                attributes: {
                    ...it,
                },
            })),
            popupTemplate: {
                title: '{objectId}',
                actions: [],
                overwriteActions: true,
                outFields: ['*'],
                content: '{objectOmschrijvingKort}',
            },
            renderer: new SimpleRenderer({
                symbol: {...ProjectMapConfig.baseMarker, color: '#222f3e'},
            }),
        }));
    }

    private async createJobLayer(jobs: ProjectJob[]) {
        return new FeatureLayer({
            title: `Opdrachten`,
            fields: ProjectMapConfig.jobLayerFields,
            objectIdField: 'id',
            geometryType: 'point',
            spatialReference: SpatialReference.WGS84,
            source: jobs.map(it => {
                return ({
                    geometry: {
                        type: 'point',
                        latitude: it.extraFields.gpsLatitudeFrom,
                        longitude: it.extraFields.gpsLongitudeFrom
                    } as PointProperties,
                    attributes: {
                        id: it.id,
                        status: it.status,
                        title: it.title,
                        code: it.code,
                        objectOmschrijvingKort: it.paulaObject.objectOmschrijvingKort,
                        createdAt: new Date(it.createdAt).getTime(),
                        createdBy: it.createdBy || 'Systeem',
                        href: `/beheer/project-jobs/${it.id}`,
                        locationFrom: it.extraFields.locationFrom || '-',
                        locationTo: it.extraFields.locationTo || '-'
                    }
                });
            }),
            popupTemplate: ProjectMapConfig.jobPopupTemplate,
            renderer: ProjectMapConfig.jobMarkerRenderer
        })
    }

    private async getProjectJobsWithCoordinates(project: Project) {
        const getJobs = (page: number) => this.projectJobService
            .getList(page, {
                filter: {
                    'project.id': {operator: '=', value: project.id.toString()},
                }
            })
            .pipe(map(it => ({page: page, response: it})));

        const jobHasCoordinates = (job: ProjectJob) => {
            return job.extraFields.gpsLatitudeFrom !== null && job.extraFields.gpsLongitudeFrom !== null;
        };

        // Expand runs a check for every 'next' on the observable and can return a new observable which is injected
        // This is used to make the observable request every page until it has reached 'response.totalPages'
        // Then scan is used to merge them all together, and takeLast to only get the complete list
        return await firstValueFrom(getJobs(0).pipe(
            expand(it => it.page < it.response.totalPages - 1 ? getJobs(it.page + 1) : EMPTY),
            scan((acc, it) => acc.concat(it.response.content.filter(jobHasCoordinates)), [] as ProjectJob[]),
            takeLast(1)
        ));
    }

    private async getProjectObjectsWithCoordinates(project: Project) {
        const getObjects = (page: number) => this.objectService
            .getObjects(project.id, page)
            .pipe(map((response) => ({page, response})));

        const objectIsActiveAndHasCoordinates = (object: PaulaObject) => {
            return object.objectStatus && object.objectXvan !== null && object.objectYvan !== null;
        };

        return await firstValueFrom(getObjects(0).pipe(
            expand((it) => it.page < it.response.totalPages - 1 ? getObjects(it.page + 1) : EMPTY),
            scan((acc, it) => acc.concat(it.response.content.filter(objectIsActiveAndHasCoordinates)), [] as PaulaObject[]),
            takeLast(1)
        ));
    }

    private async getCurrentLocation() {
        const position = await new Promise<GeolocationPosition>((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject, {
                enableHighAccuracy: false,
                timeout: 60 * 1000, // 60 Seconds timeout
                maximumAge: 5 * 60 * 1000 // Allow cached location from max 5 min ago
            });
        });

        return [position.coords.longitude, position.coords.latitude];
    }
}
