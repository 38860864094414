<app-toolbar [toolbarTitle]="formTitle" [showBackBtn]="true"></app-toolbar>

<div class="vwui-container is-small">

    <div class="animated" [class.move-to-right]="questionSetsVisible">
        <ng-container *ngIf="formItems.length > 0; else isEmpty">
            <ng-container *ngFor="let formItem of formItems; index as i">
                <ng-container *ngIf="isChapterFormItem(formItem)">
                    <div class="vwui-card u-mb-l" data-ci="chapter-card">
                        <app-form-item-chapter-title
                            [chapterNumber]="formItem.chapterNumber"
                            [title]="formItem.title"
                            [formItem]="formItem"
                            [isSelected]="isChapterSelected(formItem)"
                            (edit)="selectFormItem('chapter', formItem, formItem)"
                            (duplicate)="duplicateFormItem('chapter', formItem)"
                            (remove)="deleteFormItem(formItem)">
                        </app-form-item-chapter-title>
                        <app-questions-list
                            [parentFormItem]="formItem"
                            [chapterNumber]="formItem.chapterNumber"
                            [selectedFormItem]="selectedFormItem"
                            [questions]="formItem.children"
                            (edit)="selectFormItem('question', $event, formItem)"
                            (duplicate)="duplicateFormItem('question', $event)"
                            (remove)="deleteFormItem($event)"
                            (sorted)="onQuestionsSort(formItem, $event)">
                            <app-add-btn [hasActions]="true">
                                <div class="add-btn-actions">
                                    <button (click)="addFormItem('questionSet', formItem)" *ngIf="allowAddQuestionSets">
                                        {{'Vragensets'|translate}}
                                    </button>
                                    <button (click)="addFormItem('chapter', formItem)">{{'Hoofdstuk'|translate}}</button>
                                    <button (click)="addFormItem('question', formItem)">{{'Vraag'|translate}}</button>
                                </div>
                            </app-add-btn>
                        </app-questions-list>
                    </div>
                </ng-container>
                <ng-container *ngIf="isQuestionSetFormItem(formItem)">
                    <ng-container *ngFor="let questionSetFormItem of formItem.children; let last = last">
                        <div class="vwui-card u-mb-l">
                            <app-form-item-chapter-title
                                [isChapter]="false"
                                [chapterNumber]="questionSetFormItem.chapterNumber"
                                [title]="questionSetFormItem.title"
                                [formItem]="formItem"
                                [formItemChildren]="formItem.children"
                                [subtitle]="formItem.title"
                                (remove)="deleteFormItem(formItem)"></app-form-item-chapter-title>
                            <app-questions-list
                                [readonly]="true"
                                [chapterNumber]="questionSetFormItem.chapterNumber"
                                [questions]="questionSetFormItem.children"
                                [selectedFormItem]="selectedFormItem"
                                [formItemChildren]="formItem.children"
                                [parentFormItem]="formItem"
                                [questionSetPosition]="formItem.position"
                            >
                                <app-add-btn [hasActions]="true" *ngIf="last">
                                    <div class="add-btn-actions">
                                        <button (click)="addFormItem('questionSet', formItem)" *ngIf="allowAddQuestionSets">
                                            {{'Vragensets'|translate}}
                                        </button>
                                        <button (click)="addFormItem('chapter', formItem)">
                                            {{'Hoofdstuk'|translate}}
                                        </button>
                                    </div>
                                </app-add-btn>
                            </app-questions-list>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
        <ng-template #isEmpty>
            <div class="vwui-card u-mb-l">
                <div class="vwui-card__content">
                    <app-empty-state-message
                        title="{{'Nog geen inhoud toegevoegd'|translate}}"
                        imageSrc="beheer/assets/empty-form-state.svg">
                        <ng-container *ngIf="allowAddQuestionSets">
                            {{'Klik op vragenset of hoofdstuk om te beginnen met het formulier'|translate}}
                        </ng-container>
                        <ng-container *ngIf="!allowAddQuestionSets">
                            {{'Klik op hoofdstuk om te beginnen met het formulier'|translate}}
                        </ng-container>
                    </app-empty-state-message>
                    <app-add-btn
                        [hasActions]="true"
                        [actionsVisible]="true">
                        <div class="add-btn-actions">
                            <button (click)="addFormItem('questionSet', null)" *ngIf="allowAddQuestionSets">
                                {{'Vragensets'|translate}}
                            </button>
                            <button (click)="addFormItem('chapter', null)">
                                {{'Hoofdstuk'|translate}}
                            </button>
                        </div>
                    </app-add-btn>
                </div>
            </div>
        </ng-template>
    </div>
    <div class="animated" [class.move-to-left]="!questionSetsVisible" *ngIf="allowAddQuestionSets">
        <app-question-set-card
            [questionSetsSelected]="questionSetsSelected"
            (cancel)="hideQuestionSets()"
            (add)="saveQuestionSetItem($event)"></app-question-set-card>
    </div>
</div>
<app-aside>
    <app-question-edit
        [formType]="formType"
        [selectedFormItem]="selectedFormItem"
        [followUpProjectJob]="isProjectForm && formType"
        [followUpForm]="isFormType && formType"
        (save)="saveFormItem($event)"
        (cancel)="closeAside()"></app-question-edit>
</app-aside>
