import {Component, Inject} from '@angular/core';
import {HeaderTitle, TableFilter} from '../../components/table/table.component';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {ProjectService} from '../../services/project.service';
import {AbstractListComponent} from '../abstract-list.component';
import {Project} from '../../models/project';
import {PageResponse} from '../../models/page-response';

@Component({
    selector: 'app-project-list',
    templateUrl: './project-list.component.html'
})
export class ProjectListComponent extends AbstractListComponent<Project> {
    headerTitles: HeaderTitle[] = [
        {sortKey: 'name', filterKey: 'name', title: 'Projectnaam'},
        {sortKey: 'code', filterKey: 'code', title: 'Projectnummer'},
        {sortKey: 'active', filterKey: 'active', title: 'Status'},
    ];

    constructor(
        @Inject('ProjectService') private projectService: ProjectService,
        protected activatedRoute: ActivatedRoute,
        protected router: Router,
    ) {
        super(activatedRoute, router);
    }

    loadData(page: number, filter: TableFilter): Observable<PageResponse<Project>> {
        return this.projectService.getProjects(page, filter);
    }
}
