// @ts-strict-ignore
import {Component, Inject, OnInit} from '@angular/core';
import {AppleContentCodeService} from '../../services/apple-content-code.service';
import {combineLatest, timer} from 'rxjs';
import {map} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-apple-content-get-code-url',
    templateUrl: './apple-content-get-code-url.component.html'
})
export class AppleContentGetCodeUrlComponent implements OnInit {
    message: string;

    constructor(
        private translate: TranslateService,
        @Inject('AppleContentCodeService')  private appleContentCodeService: AppleContentCodeService
    ) {
    }

    ngOnInit() {
        this.activateAppleCode();
    }

    async activateAppleCode() {
        try {
            const code = await combineLatest([
                timer(2500),
                this.appleContentCodeService.activateCode()
            ]).pipe(
                map(([_, result]) => result)
            ).toPromise();

            this.message = this.translate.instant('Code geactiveerd');

            window.parent.postMessage(code.link, '*');
        } catch (e) {
            console.error('activateAppleCode error', e);

            this.message = this.translate.instant(e.status && e.status === 404 ? 'Er zijn geen iOS codes beschikbaar op dit moment' : 'iOS code activeren mislukt');
        }
    }
}
