<form *ngIf="form" [formGroup]="form" (submit)="save()" [class.form-is-valid]="form.valid">
    <div class="vwui-container is-small">
        <div class="vwui-card">
            <div class="vwui-card">
                <div class="vwui-card__header">{{ 'Rapport'|translate }}</div>
                <div class="vwui-card__content">
                    <div class="vwui-form-group u-mb-m mt-3">
                        <label class="vwui-input-control vwui-label">
                            {{ 'Logo'|translate }}
                        </label>
                        <app-image-upload formControlName="logo" (showImagePreview)="showImagePreview($event)"></app-image-upload>
                        <small class="vwui-form-group__helper-text">{{ 'Toon een logo in de export.'|translate }}</small>
                    </div>
                    <div class="vwui-form-group u-mb-m mt-3" [appFormControlError]="form.controls.gis">
                        <label for="gis" class="vwui-label">{{ 'Locatie'|translate }}</label>
                        <div class="d-flex align-items-center">
                            <div class="vwui-toggle">
                                <input id="gis" class="vwui-input" type="checkbox" formControlName="gis">
                                <span class="vwui-toggle__element"></span>
                            </div>
                            <small class="vwui-form-group__helper-text">{{ 'Toon opdracht locatie in de export'|translate }}</small>
                        </div>
                    </div>
                    <div class="vwui-form-group u-mb-m mt-3" [appFormControlError]="form.controls.exportTemplate">
                        <label for="exportTemplate" class="vwui-label">{{'Type rapport'|translate}}*</label>
                        <ng-select id="exportTemplate" formControlName="exportTemplate">
                            <ng-option [value]="'DEFAULT'">Standaard rapport</ng-option>
                            <ng-option [value]="'WELDING'">Las rapport</ng-option>
                        </ng-select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

<app-actionbar *ngIf="form.dirty" (save)="save()" (cancel)="cancel()"></app-actionbar>
