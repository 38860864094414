// @ts-strict-ignore
export interface PopupOptions {
    name?: string;
    width?: number;
    height?: number;
    left?: number;
    top?: number;
    delay?: number;
}

export interface AuthorizationSuccessResponse {
    code: string;
    state: string;
}

export default async function open(url: string, popupOptions: PopupOptions): Promise<AuthorizationSuccessResponse> {
    const width = popupOptions.width || 500;
    const height = popupOptions.height || 500;
    popupOptions = Object.assign({}, {
        name: 'Authentication popup',
        width: width,
        height: height,
        left: window.screenX + ((window.outerWidth - width) / 2),
        top: window.screenY + ((window.outerHeight - height) / 2.5),
        delay: 300
    }, popupOptions);

    const optionsStr = stringifyOptions(popupOptions);
    const popup = window.open(url, popupOptions.name, optionsStr);
    try {
        popup.focus();
        return await poll(popup, popupOptions.delay);
    } catch (e) {
        throw e;
    } finally {
        popup.close();
    }
}

// Poll popup window to determine if redirect back has happened
function poll(popup: Window, delay: number): Promise<AuthorizationSuccessResponse> {
    return new Promise((resolve, reject) => {
        const intervalId = setInterval(function polling() {

            try {
                const documentOrigin = document.location.host;
                const popupWindowOrigin = popup.location.host;

                if (popupWindowOrigin === documentOrigin && (popup.location.search || popup.location.hash)) {
                    const queryParams = popup.location.search.substring(1).replace(/\/$/, '');
                    // const hashParams = popup.location.hash.substring(1).replace(/[\/$]/, '');
                    const params = parseUrlParams(queryParams);

                    clearInterval(intervalId);
                    if (params.error) {
                        reject(params.error);
                    } else {
                        resolve({
                            code: params.code,
                            state: params.state
                        });
                    }
                }
            } catch (e) {
                if (!(e instanceof DOMException)) {
                    throw e;
                }
            }
        }, delay);
    });
}

// Convert options to windowFeatures string
function stringifyOptions(options: PopupOptions): string {
    return Object.entries(options)
        .map(([key, value]) => `${key}=${value}`)
        .join(',');
}

// parse url param string to key value
function parseUrlParams(paramString: string): any {
    return paramString.split('&').reduce((params, hash) => {
        const [key, val] = hash.split('=');
        return Object.assign(params, {[key]: decodeURIComponent(val)});
    }, {});
}
