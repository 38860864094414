// @ts-strict-ignore
import {Component, Inject, OnDestroy} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Forms} from '../../utils/forms';
import {ProjectFormService} from '../../services/project-form.service';
import {FormType, ProjectForm} from '../../models/form-type';
import {PaulaObjectType} from '../../models/paula-object-type';
import {FormTypeService} from '../../services/form-type.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from '../../services/authentication.service';

@Component({
    selector: 'app-project-form-modal',
    templateUrl: './project-form-modal.component.html'
})
export class ProjectFormModalComponent implements OnDestroy {
    public projectId: number;
    public form: UntypedFormGroup;
    public formType: FormType;
    public formSubscriptions: Subscription[] = [];
    public result: 'cancelled' | 'confirmed' | 'confirmed_with_workorders';
    public formExampleVisible =  false;

    private loggedInEmail$ = this.authenticationService.loggedInEmail$;

    showFormExample() {
        this.formExampleVisible = !this.formExampleVisible;
        this.bsModalRef.setClass(this.formExampleVisible ? 'modal-dialog--content-columns-2' : '');
    }

    constructor(
        @Inject('ProjectFormService') private projectFormService: ProjectFormService,
        @Inject('FormTypeService') private formTypeService: FormTypeService,
        private bsModalRef: BsModalRef,
        private toast: ToastrService,
        private translate: TranslateService,
        fb: UntypedFormBuilder,
        private authenticationService: AuthenticationService,
    ) {
        this.createForm(fb);
    }

    ngOnDestroy(): void {
        this.formSubscriptions.forEach(subscription => subscription.unsubscribe());
    }

    closeModal(result: 'cancelled' | 'confirmed' | 'confirmed_with_workorders') {
        this.result = result;
        this.bsModalRef.hide();
    }

    validateCodeNotTaken(control: AbstractControl): Observable<ValidationErrors> {
        return Forms.validateNotTaken(
            control,
            null,
            this.projectFormService.exists(this.projectId, control.value)
        );
    }

    createForm(fb: UntypedFormBuilder) {
        this.form = fb.group({
            title: fb.control(null, [Validators.required, Validators.maxLength(320)]),
            code: fb.control(null, [Validators.required], this.validateCodeNotTaken.bind(this)),
            objectTypes: fb.control([], [Validators.required]),
            formType: fb.control(null, [Validators.required]),
            company: fb.control(null),
            tags: fb.control([]),
            contactPerson: fb.control({username: this.loggedInEmail$.value}),
            executionGroups: fb.control([]),
            projectExecutionGroups: fb.control([]),
        });

        this.setDisabled();

        this.formSubscriptions.push(
            this.form.controls.objectTypes.valueChanges.subscribe((value: PaulaObjectType[]) => {
                const formType: FormType = this.form.value.formType;
                if (!formType) {
                    return;
                }
                // Check that currently selected form has all the selected objectTypes, otherwise clear the formType field
                const formTypeMatchesObjectTypes = value.reduce((matches, objectType) => {
                    return matches && formType.paulaObjectTypes.some(type => type.id === objectType.id);
                }, true);
                if (!formTypeMatchesObjectTypes) {
                    this.form.controls.formType.reset();
                }
            }),
            this.form.controls.formType.valueChanges.subscribe((value: FormType) => {
                if (value) {
                    this.form.controls.title.patchValue(value.title);
                    this.form.controls.code.patchValue(value.code);
                    this.form.controls.tags.patchValue(value.tags);
                    this.form.controls.contactPerson.patchValue({username: value.contactPerson});
                    if (this.form.controls.objectTypes.value.length === 0) {
                        this.form.controls.objectTypes.patchValue(value.paulaObjectTypes);
                    }
                    this.setEnabled();
                } else {
                    this.setDisabled();
                }
            })
        );
    }

    setDisabled() {
        this.form.controls.title.disable();
        this.form.controls.code.disable();
        this.form.controls.tags.disable();
        this.form.controls.contactPerson.disable();
        this.form.controls.executionGroups.disable();
    }

    setEnabled() {
        this.form.controls.title.enable();
        this.form.controls.code.enable();
        this.form.controls.tags.enable();
        this.form.controls.contactPerson.enable();
        this.form.controls.executionGroups.enable();
    }

    async cloneForm() {
        if (!this.form.valid) {
            Forms.updateValueAndValidityRecursive(this.form);
            return;
        }

        try {
            let contactPerson = this.form.value.contactPerson;
            if (contactPerson && typeof contactPerson === 'object') {
                contactPerson = contactPerson.username;
            }

            const projectFormResponse = await this.projectFormService.cloneFormType(
                this.projectId,
                this.form.value.title,
                this.form.value.formType.id,
                this.form.value.code,
                this.form.value.objectTypes,
                this.form.value.tags,
                contactPerson
            ).toPromise();

            const projectFormBody: ProjectForm = {
                ...projectFormResponse.projectForm,
                executionGroups: this.form.value.executionGroups,
                projectExecutionGroups: this.form.value.projectExecutionGroups,
                contactPerson: contactPerson
            };

            await this.projectFormService.putProjectForm(this.projectId, projectFormBody).toPromise();

            this.closeModal(projectFormResponse.hasWorkorderForms ? 'confirmed_with_workorders' : 'confirmed');
        } catch (error) {
            console.error('Clone form error', error);

            if (error && error.status === 409) {
                let message: string;
                if (error.error.message === 'ProjectForm with the same code already exists') {
                    message = `Het formulier kan niet toegevoegd worden want er bestaat al een formulier in dit project met code: ${error.error.code}.`;
                } else {
                    message = this.translate.instant('project_form.create.conflict');
                }
                this.toast.error(message);
            } else if (error && error.error && error.error.globalErrors) {
                for (const message of error.error.globalErrors) {
                    const translatedMessage = this.translate.instant(message);
                    this.toast.error(translatedMessage);
                }
            }
        }
    }
}
