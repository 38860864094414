import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {FormType, FormTypeRequest} from '../models/form-type';
import {PageResponse} from '../models/page-response';
import {Observable} from 'rxjs';
import {TableFilter} from '../components/table/table.component';
import {Filters} from '../utils/filters';
import {FormTypeImportStatus} from '../models/form-type-import-status';

export interface FormTypeService {
    getList(page: number, filter?: TableFilter): Observable<PageResponse<FormType>>;
    findByStatus(status: string): Observable<FormType[]>;
    getByCompany(company: number): Observable<FormType[]>;
    getByCode(code: string): Observable<FormType[]>;
    getDetail(formTypeId: number): Observable<FormType>;
    postFormType(formType: FormTypeRequest): Observable<FormType>;
    postFormTypeCopy(formType: FormType): Observable<FormType>;
    putFormType(formType: FormTypeRequest): Observable<FormType>;
    importFormTypesV1(file: File): Observable<any>;
    importFormTypesV2(workbook: File, imageZip: File): Observable<FormTypeImportStatus>;
    importStatus(importId: number): Observable<FormTypeImportStatus>;
    downloadForm(formType: FormType): Observable<HttpResponse<Blob>>;
    copyProjectForm(projectFormId: number): Observable<FormType>
}

@Injectable()
export class FormTypeServiceImpl implements FormTypeService {
    constructor(private httpClient: HttpClient) {
    }

    getList(page: number, filter?: TableFilter) {
        let params = new HttpParams().set('page', '' + page);

        if (filter) {
            params = Filters.applyFiltering(filter, params);
        }

        return this.httpClient.get<PageResponse<FormType>>('/api/v1/form-types', {params});
    }

    findByStatus(status: string): Observable<FormType[]> {
        const params = new HttpParams().set('status', '' + status);
        return this.httpClient.get<FormType[]>('/api/v1/form-types/findByStatus', {params});
    }

    getByCompany(companyId: number): Observable<FormType[]> {
        const params = new HttpParams().set('company', '' + companyId);
        return this.httpClient.get<FormType[]>('/api/v1/form-types/findByCompany', {params});
    }

    getByCode(code: string): Observable<FormType[]> {
        const params = new HttpParams().set('code', '' + code);
        return this.httpClient.get<FormType[]>('/api/v1/form-types/getByCode', {params});
    }

    getDetail(formTypeId: number) {
        return this.httpClient.get<FormType>(`/api/v1/form-types/${formTypeId}`);
    }

    postFormType(formType: FormType) {
        return this.httpClient.post<FormType>(`/api/v1/form-types`, formType);
    }

    postFormTypeCopy(formType: FormType) {
        return this.httpClient.post<FormType>(`/api/v1/form-types/copy/${formType.id}`, formType);
    }

    putFormType(formType: FormType) {
        return this.httpClient.put<FormType>(`/api/v1/form-types/${formType.id}`, formType);
    }

    importFormTypesV1(file: File): Observable<any> {
        const data = new FormData();
        data.append('file', file, file.name);

        return this.httpClient.post(`api/v1/form-types/import`, data);
    }

    importFormTypesV2(workbook: File, imageZip: File): Observable<FormTypeImportStatus> {
        const data = new FormData();
        data.append('workbook', workbook, workbook.name);
        if (imageZip) {
            data.append('imageZip', imageZip, imageZip.name);
        }

        return this.httpClient.post<FormTypeImportStatus>(`api/v1/form-types/import`, data);
    }

    importStatus(importId: number): Observable<FormTypeImportStatus> {
        return this.httpClient.get<FormTypeImportStatus>(`/api/v1/form-types/import/${importId}/status`);
    }

    downloadForm(formType: FormType): Observable<HttpResponse<Blob>> {
        return this.httpClient.get(`/api/v1/form-export/${formType.id}`, { responseType: 'blob', observe: 'response' });
    }

    copyProjectForm(projectFormId: number): Observable<FormType> {
        return this.httpClient.post<FormType>(`/api/v1/form-types/copy-project-form/${projectFormId}`, null)
    }
}
