import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {FormItem} from '../models/form-item';
import {Observable} from 'rxjs';
import {FormTypeApiEndpoint} from '../models/form-type';

export interface QuestionService {
    postQuestion(formTypeId: number, question: Omit<FormItem, 'id'>, endpoint: FormTypeApiEndpoint): Observable<FormItem>;
    putQuestion(formTypeId: number, question: FormItem, endpoint: FormTypeApiEndpoint): Observable<FormItem>;
    deleteQuestion(formTypeId: number, question: FormItem, endpoint: FormTypeApiEndpoint): Observable<FormItem>;
}

@Injectable()
export class QuestionServiceImpl implements QuestionService {
    constructor(private httpClient: HttpClient) {
    }

    postQuestion(formTypeId: number, question: FormItem, endpoint: FormTypeApiEndpoint) {
        return this.httpClient.post<FormItem>(`/api/v1/${endpoint}/${formTypeId}/form-items`, question);
    }

    putQuestion(formTypeId: number, question: FormItem, endpoint: FormTypeApiEndpoint) {
        return this.httpClient.put<FormItem>(`/api/v1/${endpoint}/${formTypeId}/form-items/${question.id}`, question);
    }

    deleteQuestion(formTypeId: number, question: FormItem, endpoint: FormTypeApiEndpoint) {
        return this.httpClient.delete<FormItem>(`/api/v1/${endpoint}/${formTypeId}/form-items/${question.id}`);
    }
}
