// @ts-strict-ignore
import {ChangeDetectionStrategy, Component, forwardRef, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ChoiceQuestion, FormItem, QuestionType} from '../../models/form-item';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'app-question-answer',
    templateUrl: './question-answer.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => QuestionAnswerComponent),
            multi: true
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionAnswerComponent implements ControlValueAccessor, OnChanges {
    @Input() label: string;
    @Input() question: FormItem;
    @Input() _answerValue: string;
    @Input() overrideAllowMultiple = false;

    checkboxAnswers: any = {};

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.question) {
            const currentValue = changes.question.currentValue as FormItem;
            const previousValue = changes.question.previousValue as FormItem;

            if (currentValue.type === QuestionType.choice) {
                // Reset answerValue if multiple property is changed
                if (previousValue && previousValue.type === QuestionType.choice && currentValue.multiple !== previousValue.multiple) {
                    if (!this.overrideAllowMultiple && currentValue.multiple !== previousValue.multiple) {
                        this.answerValue = '';
                    }
                }

                this.setupCheckboxValues();
            }
        }
    }

    get isTextQuestion() {
        return this.question.type === QuestionType.text && !this.question.multiline;
    }

    get isMultilineTextQuestion() {
        return this.question.type === QuestionType.text && this.question.multiline;
    }

    get isNumberQuestion() {
        return this.question.type === QuestionType.number;
    }

    get isListQuestion() {
        return this.question.type === QuestionType.list || this.question.type === QuestionType.table;
    }

    get isChoiceQuestion() {
        return this.question.type === QuestionType.choice;
    }

    get isMultipleChoiceQuestion() {
        const question = this.question as ChoiceQuestion;

        return this.overrideAllowMultiple || question.multiple;
    }

    get questionChoices() {
        const question = this.question as ChoiceQuestion;

        return question.choices;
    }

    get checkboxType() {
        return this.isMultipleChoiceQuestion ? 'checkbox' : 'radio';
    }

    get answerValue() {
        return this._answerValue;
    }

    set answerValue(value: any) {
        this._answerValue = value;
        this.onValueChange(value);
    }

    onValueChange = (_: string) => {};

    writeValue(value: string): void {
        if (value !== undefined) {
            this.answerValue = value;
        }
    }

    registerOnChange(fn: any): void {
        this.onValueChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    onInputChange(event: InputEvent, value: string|number) {
        if (event.data === '.') {
            return;
        }

        if (this.isNumberQuestion) {
            value = ('' + value).replace(',', '.');
        }

        this.answerValue = value.toString();
    }

    onCheckboxChange(event: Event) {
        const { checked, value } = (event.target as HTMLInputElement);

        if (this.isMultipleChoiceQuestion) {
            this.checkboxAnswers[value] = checked;
        } else {
            Object.keys(this.checkboxAnswers).forEach(key => {
                this.checkboxAnswers[key] = key === value;
            });
        }

        this.updateAnswerCheckbox();
    }

    updateAnswerCheckbox() {
        const answerValues: string[] = [];

        Object.keys(this.checkboxAnswers).map(key => {
            if (this.checkboxAnswers[key]) {
                answerValues.push(key);
            }
        });

        this.answerValue = answerValues.join(',');
    }

    setupCheckboxValues() {
        const question = this.question as ChoiceQuestion;
        const answers = this.answerValue ? this.answerValue.split(',') : [];

        question.choices.forEach(choice => {
            this.checkboxAnswers[choice.id] = answers.indexOf(choice.id) !== -1;
        });
    }

    isChecked(id: string) {
        return this.checkboxAnswers[id];
    }
}
