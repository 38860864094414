import {Directive, HostListener} from '@angular/core';

/**
 * Workaround that fixes routerLinks on elements in action-menu on table triggering the <a href> link of the row
 */
@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[routerLink]'
})
export class RouterlinkStopPropagationDirective {
    @HostListener('click', ['$event'])
    onClick(event: Event) {
        try {
            event.stopPropagation()
            event.preventDefault()
        } catch (e) {
            debugger;
        }
    }
}
