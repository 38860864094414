import {Inject, Injectable} from "@angular/core";
import {SharepointCredential} from "../models/sharepoint-credential";
import {SharepointCredentialService} from "../services/sharepoint-credential.service";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class SharepointCredentialResolver implements Resolve<SharepointCredential> {
    constructor(
        @Inject('SharepointCredentialService') private sharepointCredentialService: SharepointCredentialService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.sharepointCredentialService.getSharepointCredential(+route.params.id);
    }
}
