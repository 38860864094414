<app-toolbar></app-toolbar>

<div *appHasFeatureToggle="[maximoToggle, viseToggle]" class="vwui-container">
    <div class="table-filter">
        <div class="vwui-filter">
            <ng-container *ngFor="let filter of filters">
                <div class="vwui-filter__item"
                     *appHasFeatureToggle="filter?.featureToggle"
                     [class.is-active]="appliedFilter?.filter?.type === filter.type"
                     (click)="selectTypeFilter(filter.type)">
                    {{ filter.title }}
                </div>
            </ng-container>
        </div>
    </div>

    <app-multi-select-table #table
                            *ngIf="paginator$|async as paginator"
                            [paginator]="paginator" [headerTitles]="headerTitles" [enableContextMenu]="true"
                            (selectionChanged)="onSelectionChanged($any($event))"
                            (filterChanged)="onTableFilterChange($event)"
                            (itemClick)="onItemClick($any($event))"
                            [resetSelection$]="resetSelection$"
    >
        <ng-template #contextMenuHeader></ng-template>
        <ng-template #actions>
            <button *ngIf="onlySharepointErrorsSelected($any(table.selection))" tooltip="{{'Verstuur opnieuw'|translate}}" type="button" class="vwui-icon-btn" (click)="onRetryMultiple(table.selection)">
                <app-icon iconName="refresh" tooltip="{{'Verstuur opnieuw'|translate}}"></app-icon>
            </button>
            <button *ngIf="bulkRetryable" tooltip="{{'Opgelost'|translate}}" type="button" class="vwui-icon-btn" (click)="onResolveMultiple(table.selection)">
                <app-icon iconName="check" tooltip="{{'Opgelost'|translate}}"></app-icon>
            </button>
            <button tooltip="{{'Verwijder'|translate}}" type="button" class="vwui-icon-btn" (click)="onDeleteMultiple(table.selection)">
                <app-icon iconName="trash-2" tooltip="{{'Verwijder'|translate}}"></app-icon>
            </button>
        </ng-template>
        <ng-template #row let-item>
            <td>{{ item?.workOrder?.workOrderId || item?.assetUpdate?.updateId || item?.reference }}</td>
            <td [translate]="item.message" [translateParams]="item.data"></td>
            <td>{{ item?.occurredAt |dateFormat }}</td>
            <td>
                <ng-container [ngSwitch]="item?.type">
                    <ng-container *ngSwitchCase="'Sharepoint'">{{ 'Uitgaand'| translate }}</ng-container>
                    <ng-container *ngSwitchCase="'Vise'">
                        {{ (item?.talendJobUpdateSync ? 'Uitgaand' : 'Inkomend') | translate }}
                    </ng-container>
                    <ng-container *ngSwitchCase="'Maximo'">
                        {{ ['SendWorkorderUpdate', 'SendServiceRequest'].indexOf(item?.workOrder?.type) !== -1 ? ('Uitgaand'| translate) : ('Inkomend'| translate) }}
                    </ng-container>
                    <ng-container *ngSwitchCase="'JobTransition'">{{ 'Opdrachtstatus'| translate }}</ng-container>
                    <ng-container *ngSwitchDefault>{{ 'Inkomend'| translate }}</ng-container>
                </ng-container>
            </td>
            <td class="vwui-table__fixed-column-width vwui-multi-select-table--unclickable">
                <div class="vwui-table__actions">
                    <app-action-menu #actionMenu>
                        <button *ngIf="item?.retryable" class="vwui-menu-item is-success" (click)="onResolve(item)">
                            <app-icon iconName="check"></app-icon>
                            {{'Opgelost'|translate}}
                        </button>
                        <button class="vwui-menu-item is-danger" (click)="onDelete(item)">
                            <app-icon iconName="trash-2"></app-icon>
                            {{'Verwijder'|translate}}
                        </button>
                    </app-action-menu>
                </div>
            </td>
        </ng-template>
    </app-multi-select-table>
</div>
