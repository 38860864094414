// @ts-strict-ignore
import {ChangeDetectorRef, Component, EventEmitter, forwardRef, Inject, Input, Output} from '@angular/core';
import {FileSystemFileEntry, NgxFileDropEntry} from 'ngx-file-drop';
import {v4 as uuid} from 'uuid';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {UploadService} from '../../services/upload.service';
import {lastValueFrom} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-image-upload',
    templateUrl: './image-upload.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ImageUploadComponent),
            multi: true
        }
    ],
})
export class ImageUploadComponent implements ControlValueAccessor {
    @Input() multiple = true;
    @Input() accept = "image/jpeg, image/png";
    @Output() showImagePreview = new EventEmitter<string|null>();

    private onChange: (value: string) => void;
    private onTouched: () => void;
    disabled = false;
    value: string = null;
    loading = false;
    maxFileSize = 25 * 1024 * 1024;

    constructor(
        @Inject('UploadService') private uploadService: UploadService,
        private changeDetectorRef: ChangeDetectorRef,
        private translateService: TranslateService,
        private toastService: ToastrService
    ) {
    }

    get src() {
        return this.value !== null ? `/blob/${this.value}` : null;
    }

    dropped(files: NgxFileDropEntry[]) {
        for (const droppedFile of files) {
            if (droppedFile.fileEntry.isFile) {
                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                this.loading = true;

                fileEntry.file(async (file: File) => {
                    try {
                        if (file.size > this.maxFileSize) {
                            const toastErrorMessage = this.translateService.instant('image.upload.exceeded')
                            return this.toastService.error(toastErrorMessage);
                        }

                        const fileUpload = await lastValueFrom(this.uploadService.uploadImage(uuid(), file))
                        this.value = fileUpload.id;

                        this.onTouched();
                        this.onChange(this.value);

                        // Needed to make new image show up because parent component is ChangeDetection.OnPush
                        this.changeDetectorRef.markForCheck();
                    } catch (r) {
                        const defaultErrorMessage = this.translateService.instant('image.upload.failed');
                        const unsupportedMessage = this.translateService.instant('image.upload.unsupported');

                        if (r.error.message.includes('Unsupported file')) {
                            return this.toastService.error(unsupportedMessage);
                        } else {
                            return this.toastService.error(defaultErrorMessage);
                        }
                    } finally {
                        this.loading = false;
                    }
                });
            } else {
                // It was a directory (empty directories are added, otherwise only files)
            }
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = (value) => {
            fn(value);
        };
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(value: string): void {
        this.value = value;
    }

    clear() {
        this.value = null;
        this.onTouched();
        this.onChange(this.value);
    }
}
