import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Inject, Injectable} from '@angular/core';
import {User} from '../models/user';
import {UserService} from '../services/user.service';

@Injectable({
    providedIn: 'root'
})
export class UserResolver implements Resolve<User> {

    constructor(
        @Inject('UserService') private userService: UserService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.userService.getUser(+route.params.id);
    }
}
