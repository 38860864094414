<ng-container>
    <div [ngClass]="tableContainerClassList">
        <ng-content select="app-search"></ng-content>
        <app-paginator [paginator]="paginator"></app-paginator>
        <div [class.vwui-table-container-scroll]="enableScroll">
            <table class="vwui-table" [ngClass]="{'has-hover': !paginator.isEmpty, 'has-action': !paginator.isEmpty}" >
                <thead>
                <tr>
                    <th class="vwui-multi-select-table__checkbox-column" *ngIf="enableMultiSelect"></th>
                    <ng-container *ngFor="let headerTitle of headerTitles; trackBy: trackHeaderBy">
                        <th *ngIf="isHeaderVisible(headerTitle)" (click)="changeSort(headerTitle?.sortKey)"
                            [class.sortable]="headerTitle.sortKey !== undefined">
                            {{ headerTitle.title }}
                            <span class="vwui-table__sort-icon"
                                    [class.is-ascending]="isKeyAscending(headerTitle?.sortKey)"
                                    [class.is-descending]="isKeyDescending(headerTitle?.sortKey)">
                                    <app-icon iconName="chevron-down"></app-icon>
                            </span>
                        </th>
                    </ng-container>
                    <th *ngIf="enableContextMenu">
                        <ng-template [ngTemplateOutlet]="contextMenuHeaderTemplate"></ng-template>
                    </th>
                </tr>
                <tr>
                    <th class="vwui-multi-select-table__checkbox-column" *ngIf="enableMultiSelect"></th>
                    <ng-container *ngFor="let headerTitle of headerTitles; trackBy: trackHeaderBy">
                        <th *ngIf="isHeaderVisible(headerTitle)">
                            <ng-container *ngIf="headerTitle" [ngSwitch]="headerTitle.type">
                                <input *ngSwitchCase="'date'"
                                       class="vwui-input" type="text" placeholder="{{'Zoeken'|translate}}"
                                       mwlFlatpickr dateFormat="d-m-Y" [mode]="'range'" [value]="filterValue(headerTitle.filterKey)"
                                       (input)="changeFilter($event, headerTitle, headerTitle.filterKey)">
                                <input #headerTitlePop="bs-tooltip"
                                    *ngSwitchDefault class="vwui-input" type="text"
                                    placeholder="{{'Zoeken'|translate}}" [value]="filterValue(headerTitle.filterKey)"
                                    (input)="changeFilter($event, headerTitle, headerTitle.filterKey, headerTitlePop)"
                                    [tooltip]="'Minimaal ' + headerTitle.minCharacters + ' karakters vereist'"
                                    triggers="">
                            </ng-container>
                        </th>
                    </ng-container>
                    <th *ngIf="enableContextMenu"></th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngIf="(paginator.content$|async) as items">
                    <tr *ngIf="items.length > 0 && enableMultiSelect" class="no-action">
                        <th class="vwui-multi-select-table__checkbox-column align-middle">
                            <app-checkbox [checked]="allSelected(items)" (valueChange)="setAllSelected($event.target.checked, items)"></app-checkbox>
                        </th>
                        <th [colSpan]="columnCount - 1" class="align-middle">
                            <div class="vwui-multi-select-table__actions">
                                <ng-template *ngIf="selection.length > 0" [ngTemplateOutlet]="actionsTemplate"></ng-template>
                            </div>
                        </th>
                    </tr>
                    <tr *ngFor="let item of items" (click)="onItemClick($event.target, item)">
                        <td class="vwui-multi-select-table__checkbox-column" *ngIf="enableMultiSelect">
                            <app-checkbox (click)="stopPropagation($event)" [checked]="isSelected(item)" (valueChange)="setItemSelected($event.target.checked, item)"></app-checkbox>
                        </td>
                        <ng-template [ngTemplateOutlet]="templateRef" [ngTemplateOutletContext]="{$implicit: item}"></ng-template>
                    </tr>
                </ng-container>
                <tr *ngIf='paginator.isEmpty'>
                    <td [colSpan]="!headerTitles || headerTitles.length">
                        {{'Er zijn geen resultaten'|translate}}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <app-paginator *ngIf="!paginator.isEmpty" [paginator]="paginator"></app-paginator>
    </div>
</ng-container>
