import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Graph} from '../models/graph';
import {GraphUser} from '../models/graph-user';

export interface GraphService {
    getGraphs(): Observable<Graph[]>;
    getUsers(graphId: number, search: string): Observable<GraphUser[]>;
}

@Injectable()
export class GraphServiceImpl implements GraphService {

    constructor(private httpClient: HttpClient) {
    }

    getGraphs() {
        return this.httpClient.get<Graph[]>('/api/v1/graph');
    }

    getUsers(graphId: number, search: string) {
        return this.httpClient.get<GraphUser[]>(`/api/v1/graph/${graphId}`, {params: {search}});
    }
}
