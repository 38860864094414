// @ts-strict-ignore
import {ObjectTypeService} from '../object-type.service';
import {Observable, of} from 'rxjs';
import {PageResponse} from '../../models/page-response';
import {PaulaObjectType, PaulaObjectTypeStatus} from '../../models/paula-object-type';
import {AbstractCrudServiceMock} from './abstract-crud.service.mock';
import {Injectable} from '@angular/core';

export const mockObjectTypes: Array<PaulaObjectType> = [
    {
        id: 2,
        title: 'Boortoren',
        code: 'BOOR',
        description: null,
        companies: null,
        status: PaulaObjectTypeStatus.Active
    },
    {
        id: 1,
        title: 'Hoogspanningsmast',
        code: null,
        description: null,
        companies: null,
        status: PaulaObjectTypeStatus.Active
    }
];

@Injectable()
export class ObjectTypeServiceMock extends AbstractCrudServiceMock<PaulaObjectType> implements ObjectTypeService {

    constructor() {
        super(mockObjectTypes);
    }

    getObjectTypes(page: number): Observable<PageResponse<PaulaObjectType>> {
        return this.getAll();
    }

    getObjectType(id: number) {
        return this.get(id);
    }

    exists(title: string) {
        const result = mockObjectTypes.find(item => item.title === title);

        return of(result ? result.id : null);
    }

    codeExists(code: string): Observable<number> {
        const result = mockObjectTypes.find(item => item.code === code);

        return of(result ? result.id : null);
    }

    postObjectType(objectType: PaulaObjectType) {
        return this.post(objectType);
    }

    putObjectType(objectType: PaulaObjectType): Observable<PaulaObjectType> {
        return this.put(objectType);
    }
}
