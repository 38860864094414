import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {AuthCheckService} from '../../services/auth-check.service';
import {Router} from '@angular/router';
import {UserRoles} from '../../models/user';
import {Subscription} from 'rxjs';
import {Subscriptions} from '../../utils/subscriptions';
import {BsModalService} from 'ngx-bootstrap/modal';
import {NotAuthorizedModalComponent} from '../../components/not-authorized-modal/not-authorized-modal.component';
import {AuthenticationService} from "../../services/authentication.service";

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit, OnDestroy {

    private modalShown = false;
    private subscriptions: Subscription[] = [];

    constructor(
        @Inject('AuthCheckService') private authService: AuthCheckService,
        private authenticationService: AuthenticationService,
        private router: Router,
        private modalService: BsModalService
    ) {}

    ngOnInit(): void {
        const subscription = this.authService.authorities().subscribe((authorities) => {
            const roles = authorities.map(authority => authority.authority);

            let route: string[] = [];
            if (roles.indexOf(UserRoles.RoleMasterDataAdmin) !== -1) {
                route = ['/beheer/object-types'];
            } else if (roles.indexOf(UserRoles.RoleApplicationAdmin) !== -1) {
                route = ['/beheer/users'];
            } else if (roles.indexOf(UserRoles.RoleCoordinator) !== -1) {
                route = ['/beheer/projects'];
            }

            if (route.length > 0) {
                this.router.navigate(route);
            } else {
                this.showNotAuthorizedModal();
            }
        });
        this.subscriptions.push(subscription);
    }

    ngOnDestroy(): void {
        Subscriptions.unsubscribeAll(this.subscriptions);
    }

    showNotAuthorizedModal() {
        if (!this.modalShown) {
            const modal = this.modalService.show(NotAuthorizedModalComponent, {
                backdrop: 'static',
                ignoreBackdropClick: true,
                keyboard: false,
                class: 'modal-dialog-centered'
            });

            const notAuthorizedModal = modal.content as NotAuthorizedModalComponent;
            notAuthorizedModal.loginAgainClick.subscribe(() => {
                this.authenticationService.logout();
                window.location.reload();
            });
        }

        this.modalShown = true;
    }
}
