<div class="image-file-upload">
    <div *ngIf="src" class="image-file-upload__image-container">
        <img class="image-file-upload__image" [src]="src" (click)="showImagePreview.emit(src)">
        <div class="image-file-upload__image-remove-btn" (click)="clear()">
            <app-icon iconName="trash-2"></app-icon>
        </div>
    </div>
    <ngx-file-drop
        *ngIf="!src"
        class="image-file-upload__file-drop"
        [class.image-file-upload--loading]="loading"
        [accept]="accept"
        [showBrowseBtn]="true"
        [disabled]="disabled"
        [multiple]="multiple"
        (onFileDrop)="dropped($event)"
        dropZoneLabel="{{'Voeg een foto toe door hier te klikken of hier naar toe te slepen'|translate}}"
    >
    </ngx-file-drop>
    <div class="vwui-spinner" *ngIf="loading"></div>
</div>
