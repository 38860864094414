// @ts-strict-ignore
import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import {ProjectService} from '../../services/project.service';
import {Project} from '../../models/project';
import {
    AbstractControl, FormControl,
    FormGroup,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    ValidationErrors,
    Validators
} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {Forms} from '../../utils/forms';
import {
    firstValueFrom,
    merge,
    Observable,
    Subscription
} from 'rxjs';
import {Subscriptions} from '../../utils/subscriptions';
import {FeatureToggleCheckService} from '../../services/feature-toggle-check.service';
import {FeatureToggle} from '../../models/feature-toggle';
import {TranslateService} from '@ngx-translate/core';
import {startWith} from 'rxjs/operators';
import {SharepointService} from '../../services/sharepoint.service';
import {escapeRegExp} from '../../utils/regexp';
import {Customer} from '../../models/customer';
import {SharepointCredentialListDTO} from '../../models/sharepoint-credential';

@Component({
    selector: 'app-project-detail-tab',
    templateUrl: './project-detail-tab.component.html'
})
export class ProjectDetailTabComponent implements OnInit, OnDestroy {
    @Input() project: Project | null; // null if new
    @Output() projectSaved = new EventEmitter<Project>();

    sharepointConnectionStatus: string;
    sharepointHostname: string | null = null;
    emailNotificationTags = [
        "{{opdrachtNaam}}",
        "{{projectNummer}}",
        "{{opdrachtNummer}}",
        "{{objectKorteOmschrijving}}",
        "{{omschrijvingLocatieVan}}",
        "{{omschrijvingLocatieTot}}"
    ];

    form = new FormGroup({
        id: new FormControl<number>(null),
        name: new FormControl<string>(null, [Validators.required, Validators.maxLength(255)]),
        code: new FormControl<string>(null, Validators.required, [this.validateProjectNotTaken.bind(this)]),
        active: new FormControl<boolean>(true, [Validators.required]),
        vise: new FormControl<boolean>(false),
        wid: new FormControl<string>('', [Validators.maxLength(255)]),
        hasMaintenanceContract: new FormControl<boolean>(false),
        requireVerification: new FormControl<boolean>(false),
        requireSignature: new FormControl<boolean>(false),
        customer: new FormControl<Customer>(undefined),
        jobNotifyEmail: new FormControl<string>(null, [Validators.email]),
        jobNotifyEmailSubject: new FormControl<string>(null, [Validators.maxLength(255)]),

        sharepointExportEnabled: new FormControl<boolean>(false),
        sharepointUrl: new FormControl<string>({value: null, disabled: true}, [this.validateSharepointUrl.bind(this)]),
        sharepointOverrule: new FormControl<boolean>({value: false, disabled: true}),
        sharepointSiteId: new FormControl<string>({value: null, disabled: true}, [Validators.required]),
        sharepointExportPath: new FormControl<string>({value: null, disabled: true}, [Validators.required, Validators.pattern(/^[^"*:<>?|]+$/)]),
        sharepointLibrary: new FormControl<string>({value: null, disabled: true}, [Validators.required]),
        sharepointCredential: new FormControl<SharepointCredentialListDTO>({value: null, disabled: true}, [Validators.required])
    });

    private subscriptions: Subscription[] = [];

    private sharepointRegex = /^https:\/\/[^.]+\.sharepoint\.com\/sites\/([^\/]+)\/([^\/]+)\/Forms\/([^\/.]+)\.aspx/;

    constructor(
        @Inject('ProjectService') private projectService: ProjectService,
        @Inject('SharepointService') private sharepointService: SharepointService,
        @Inject('FeatureToggleCheckService') private featureToggleCheckService: FeatureToggleCheckService,
        private toast: ToastrService,
        private router: Router,
        private route: ActivatedRoute,
        private fb: UntypedFormBuilder,
        private translateService: TranslateService
    ) {
    }

    ngOnInit() {
        this.addViseValidators();

        if (this.project) {
            this.form.patchValue(this.project)

            try {
                if (this.project.sharepointUrl) {
                    this.sharepointHostname = (new URL(this.project.sharepointUrl)).hostname
                }
            } catch (e) {
                console.error("Failed to set sharepointHostname from url", e)
            }
        }

        this.featureToggleCheckService.featureToggles().subscribe(featureToggles => {
            if (FeatureToggleCheckService.isEnabled(featureToggles, FeatureToggle.CustomersEnabled)) {
                this.form.controls.customer.enable()
            } else {
                this.form.controls.customer.disable()
            }
            if (FeatureToggleCheckService.isEnabled(featureToggles, FeatureToggle.SharepointJobExport)) {
                this.createSharepointFieldsDisabledStateSubscription();
                this.createParseSharepointUrlSubscription();
            }
        });

        if (this.project && this.project.sharepointExportEnabled) {
            this.testSharepointConnection(this.project);
        }
    }

    ngOnDestroy(): void {
        Subscriptions.unsubscribeAll(this.subscriptions);
    }

    addEmailNotificationTag(event: MouseEvent) {
        const button = event.target as HTMLButtonElement;
        const value = this.form.controls.jobNotifyEmailSubject.value
            ? this.form.controls.jobNotifyEmailSubject.value + " " + button.dataset['tag']
            : button.dataset['tag']

        this.form.patchValue({ jobNotifyEmailSubject: value });
        this.form.markAsDirty();
    }

    async save() {
        this.form.markAllAsTouched();
        this.form.controls.wid.updateValueAndValidity();
        if (!this.form.valid) {
            Forms.updateValueAndValidityRecursive(this.form);
            return;
        }

        // Some Sharepoint form controls are in the disabled state due to the Sharepoint overrule toggle,
        // and are therefore not returned in form.value.
        // To work around this take the sharepoint values from the raw form value with also includes disabled fields
        let formValue = this.form.value as Project;
        const {
            sharepointExportEnabled,
            sharepointUrl,
            sharepointOverrule,
            sharepointSiteId,
            sharepointExportPath,
            sharepointLibrary,
            sharepointCredential,
            jobNotifyEmailSubject
        } = this.form.getRawValue();
        formValue = {
            ...formValue,
            sharepointExportEnabled,
            sharepointUrl,
            sharepointOverrule,
            sharepointSiteId: decodeURI(sharepointSiteId),
            sharepointExportPath,
            sharepointLibrary: decodeURI(sharepointLibrary),
            sharepointCredential,
            jobNotifyEmailSubject: jobNotifyEmailSubject || null
        };

        try {
            let projectResponse: Project;

            if (formValue.id) {
                const editProject = {...this.project, ...formValue};

                projectResponse = await firstValueFrom(this.projectService.putProject(editProject));

                this.projectSaved.emit(projectResponse);
            } else {
                projectResponse = await firstValueFrom(this.projectService.postProject(formValue));

                if (projectResponse.id) {
                    await this.router.navigate(['beheer', 'projects', projectResponse.id], {replaceUrl: true});
                }
            }

            this.toast.success(this.translateService.instant('Opgeslagen'));
            this.form.patchValue(projectResponse);

            if (formValue.sharepointExportEnabled) {
                await this.testSharepointConnection(formValue);
            }
        } catch (error) {
            console.error('Unable to save project', error);
            this.toast.error(this.translateService.instant('Opslaan mislukt'));
        }
    }

    cancel() {
        history.back();
    }

    private validateProjectNotTaken(control: AbstractControl): Observable<ValidationErrors> {
        const currentId = this.form ? +this.form.controls.id.value : 0;

        return Forms.validateNotTaken(control, currentId, this.projectService.exists(control.value));
    }

    private addViseValidators() {
        const viseFormControl = this.form.controls.vise;
        this.subscriptions.push(viseFormControl.valueChanges.pipe(
            startWith(!!this.form.value.vise)
        ).subscribe(enabled => {
            const widFormControl = this.form.controls.wid;
            if (widFormControl) {
                if (enabled) {
                    widFormControl.setValidators([Validators.required, Validators.maxLength(255)]);
                } else {
                    widFormControl.clearValidators();
                }
            } else {
                console.warn('Expected wid form control but was undefined');
            }
        }));
    }

    private async testSharepointConnection(project: Project) {
        try {
            this.sharepointConnectionStatus = await firstValueFrom(this.sharepointService.testConnection(project));
        } catch (error) {
            console.error(error);
            this.sharepointConnectionStatus = 'Unknown';
        }
    }

    private validateSharepointUrl(): (control: AbstractControl) => ValidationErrors | null {
        return (control) => {
            const url = control.value;

            if (!(typeof url === 'string') || !url.match(this.sharepointRegex)) {
                return {'invalidUrl': 'Unable to parse Sharepoint URL'};
            }

            return null;
        };
    }

    private createParseSharepointUrlSubscription() {
        const sharepointUrlSubscription = this.form.controls.sharepointUrl.valueChanges.subscribe(url => {
            let match;
            if (typeof url === 'string' && (match = decodeURI(url).match(this.sharepointRegex))) {
                const [, siteId, library] = match;

                this.form.controls.sharepointSiteId.setValue(siteId, {emitEvent: false});
                this.form.controls.sharepointLibrary.setValue(library, {emitEvent: false});

                try {
                    const parsedUrl = new URL(url);
                    const searchParams = parsedUrl.searchParams;
                    const sharepointId = searchParams.get('id');

                    this.sharepointHostname = parsedUrl.hostname;

                    const sharepointIdRegex = new RegExp(`\/sites\/${escapeRegExp(siteId.replace('%20', ' '))}\/${escapeRegExp(library.replace('%20', ' '))}(.+)`);
                    let sharepointIdMatch;
                    if (typeof sharepointId === 'string' && (sharepointIdMatch = sharepointId.match(sharepointIdRegex))) {
                        const [, sharepointExportPath] = sharepointIdMatch;
                        this.form.controls.sharepointExportPath.setValue(sharepointExportPath, {emitEvent: false});
                    } else {
                        this.form.controls.sharepointExportPath.setValue('/', {emitEvent: false});
                    }
                } catch (err) {
                    console.error('Unable to parse URL', err);
                }
            }
        });
        this.subscriptions.push(sharepointUrlSubscription);
    }

    private createSharepointFieldsDisabledStateSubscription() {
        this.subscriptions.push(merge(
            this.form.controls.sharepointExportEnabled.valueChanges,
            this.form.controls.sharepointOverrule.valueChanges
        ).pipe(
            startWith(null)
        ).subscribe(() => {
            // Credentials dropdown is always disabled because it's automatically selected based on domain
            this.form.controls.sharepointCredential.disable({emitEvent: false})

            const {sharepointExportEnabled, sharepointOverrule} = this.form.getRawValue()

            if (sharepointExportEnabled === false) {
                this.form.controls.sharepointOverrule.disable({emitEvent: false})

                this.form.controls.sharepointUrl.disable({emitEvent: false})

                this.form.controls.sharepointSiteId.disable({emitEvent: false})
                this.form.controls.sharepointExportPath.disable({emitEvent: false})
                this.form.controls.sharepointLibrary.disable({emitEvent: false})
            } else if (sharepointOverrule === true) {
                this.form.controls.sharepointOverrule.enable({emitEvent: false})

                this.form.controls.sharepointUrl.disable({emitEvent: false})

                this.form.controls.sharepointSiteId.enable({emitEvent: false})
                this.form.controls.sharepointLibrary.enable({emitEvent: false})
                this.form.controls.sharepointExportPath.enable({emitEvent: false})

            } else {
                this.form.controls.sharepointOverrule.enable({emitEvent: false})

                this.form.controls.sharepointUrl.enable({emitEvent: false})

                this.form.controls.sharepointSiteId.disable({emitEvent: false})
                this.form.controls.sharepointLibrary.disable({emitEvent: false})
                this.form.controls.sharepointExportPath.disable({emitEvent: false})
            }
        }))
    }
}
