import {Identifiable} from './identifiable';
import {Project} from './project';
import {Company} from './company';

export enum UserRoles {
    RoleApplicationAdmin = 'ROLE_APPLICATION_ADMIN',
    RoleExecutor = 'ROLE_EXECUTOR',
    RoleCoordinator = 'ROLE_COORDINATOR',
    RoleMasterDataAdmin = 'ROLE_MASTER_DATA_ADMIN',
    RoleInactive = 'ROLE_INACTIVE' // Not a real role
}

export interface User extends Identifiable {
    authenticationType?: string;
    username: string;
    displayName: string;
    mail: string;
    tenantId: string;
    roles: string[];
    company: Company;
    signatureId?: string;
}

export interface UserProject {
    project: Project;
    roles: string[];
}
