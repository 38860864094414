import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
    transform(value: string, maxCharacters?: number, truncateTrail?: string): string {
        if (value === null) {
            return '';
        }
        const limit = maxCharacters || 20;
        const trail = truncateTrail || '...';

        return value.length > limit ? value.substring(0, limit - trail.length) + trail : value;
    }
}
