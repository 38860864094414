// @ts-strict-ignore
import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ActionMenuComponent} from '../../vwui/action-menu/action-menu.component';
import {FormItem, QuestionType} from '../../models/form-item';
import {FormUtils} from '../../utils/form-utils';
import {FormBuilderService} from '../../services/form-builder.service';

@Component({
    selector: 'app-form-item-chapter-title',
    templateUrl: './form-item-chapter-title.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormItemChapterTitleComponent implements OnInit {
    @ViewChild('actionMenu') actionMenu: ActionMenuComponent;

    @Input() isChapter = true;
    @Input() chapterNumber: number;
    @Input() title: string;
    @Input() subtitle: string;
    @Input() isSelected = false;
    @Input() formItem?: FormItem;
    @Input() formItemChildren: FormItem[];

    @Output() edit = new EventEmitter<void>();
    @Output() duplicate = new EventEmitter<void>();
    @Output() remove = new EventEmitter<void>();

    dependentQuestionPosition: string;

    constructor(
        private formBuilderService: FormBuilderService
    ) {
    }

    ngOnInit() {
        this.dependentQuestionPosition = this.getDependentQuestionPosition();
    }

    closeMenu() {
        if (this.actionMenu) {
            this.actionMenu.closeMenu();
        }
    }

    onEdit(event: Event) {
        event.preventDefault();
        event.stopPropagation();

        this.closeMenu();
        this.edit.emit();
    }

    onDuplicate(event: Event) {
        event.preventDefault();
        event.stopPropagation();

        this.closeMenu();
        this.duplicate.emit();
    }

    onRemove(event: Event) {
        event.preventDefault();
        event.stopPropagation();

        this.remove.emit();
        this.closeMenu();
    }

    getDependentQuestionPosition() {
        let offsetChapter = 1;
        let formItemToCheck = this.formItem;

        // Question set - We need to find the chapter inside the form item
        // So that we can use the form items position for the chapter offset
        if (!this.isChapter && this.formItem.type === QuestionType.questionSet) {
            offsetChapter = FormUtils.getChapterNumberFromPosition(this.formItem.position);
            formItemToCheck = this.formItem.questionSet.formItems.find((questionSetItem) => {
                return questionSetItem.type === QuestionType.chapter &&
                    FormUtils.getChapterNumberFromPosition(questionSetItem.position) - 1 === this.chapterNumber - offsetChapter;
            });
        }

        if (formItemToCheck) {
            return FormUtils.dependentQuestionPosition(
                formItemToCheck,
                this.formBuilderService.getPossibleDependencies(this.formItem, false, this.formItemChildren),
                offsetChapter);
        } else {
            return null;
        }
    }
}
