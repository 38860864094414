import {HttpClient, HttpParams} from '@angular/common/http';
import {PageResponse} from '../models/page-response';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {TableFilter} from '../components/table/table.component';
import {Filters} from '../utils/filters';
import {ExecutionGroup} from '../models/execution-group';

export interface ExecutionGroupService {
    getExecutionGroups(page: number, filter?: TableFilter): Observable<PageResponse<ExecutionGroup>>;
    getExecutionGroup(id: number): Observable<ExecutionGroup>;
    putExecutionGroup(executionGroup: ExecutionGroup): Observable<ExecutionGroup>;
    postExecutionGroup(executionGroup: ExecutionGroup): Observable<ExecutionGroup>;
    exists(title: string): Observable<number>;
}

@Injectable()
export class ExecutionGroupServiceImpl implements ExecutionGroupService {
    constructor(private httpClient: HttpClient) {
    }

    getExecutionGroups(page: number, filter?: TableFilter) {
        let params = new HttpParams().set('page', '' + page);

        if (filter) {
            params = Filters.applyFiltering(filter, params);
        }

        return this.httpClient.get<PageResponse<ExecutionGroup>>('/api/v1/execution-groups', {params});
    }

    getExecutionGroup(id: number) {
        return this.httpClient.get<ExecutionGroup>(`api/v1/execution-groups/${id}`);
    }

    putExecutionGroup(executionGroup: ExecutionGroup) {
        return this.httpClient.put<ExecutionGroup>(`api/v1/execution-groups/${executionGroup.id}`, executionGroup);
    }

    postExecutionGroup(executionGroup: ExecutionGroup) {
        return this.httpClient.post<ExecutionGroup>('api/v1/execution-groups', executionGroup);
    }

    exists(title: string) {
        const params = new HttpParams().set('title', title);

        return this.httpClient.get<number>(`api/v1/execution-groups/exists`, {params});
    }
}
