<app-toolbar [showBackBtn]="true" (back)="goBack()"></app-toolbar>

<div class="vwui-container is-small">
    <div class="vwui-card">
        <div class="vwui-card__header">{{'SharePoint inloggegevens'|translate}}</div>
        <div class="vwui-card__content">
            <form [formGroup]="form" (submit)="save()">
                <div class="vwui-form-group mb-4" [appFormControlError]="form.get('title')">
                    <label for="title" class="vwui-label">{{'Titel'|translate}}</label>
                    <input id="title" class="vwui-input" name="title" type="text" formControlName="title" autocomplete="off">
                </div>
                <div class="vwui-form-group mb-4" [appFormControlError]="form.get('authenticationType')">
                    <label for="authenticationType" class="vwui-label">{{'Authenticatietype'|translate}}</label>
                    <ng-select id="authenticationType" formControlName="authenticationType">
                        <ng-option value="Password">Sharepoint gebruiker / password</ng-option>
                        <ng-option value="ClientCredentials">App registratie / client_credentials</ng-option>
                    </ng-select>
                </div>
                <div class="vwui-form-group mb-4" [appFormControlError]="form.get('hostname')">
                    <label for="hostname" class="vwui-label">{{'Domeinnaam'|translate}}</label>
                    <input id="hostname" class="vwui-input" name="hostname" type="text" formControlName="hostname"
                           autocomplete="off" placeholder="domeinnaam.sharepoint.com">
                </div>
                <div class="vwui-form-group mb-4" [appFormControlError]="form.get('tenantId')">
                    <label for="tenantId" class="vwui-label">{{'Tenant ID'|translate}}</label>
                    <input id="tenantId" class="vwui-input" name="tenantId" type="text" formControlName="tenantId" autocomplete="off">
                </div>
                <div class="vwui-form-group mb-4" [appFormControlError]="form.get('clientId')">
                    <label for="clientId" class="vwui-label">{{'Client ID'|translate}}</label>
                    <input id="clientId" class="vwui-input" name="clientId" type="text" formControlName="clientId" autocomplete="off">
                </div>
                <div class="vwui-form-group mb-4" [appFormControlError]="form.get('clientSecret')">
                    <label for="clientSecret" class="vwui-label">{{'Client Secret'|translate}}</label>
                    <input id="clientSecret" class="vwui-input" name="clientSecret" type="text" formControlName="clientSecret" autocomplete="off">
                </div>
                <div *ngIf="form.controls.username.enabled" class="vwui-form-group mb-4" [appFormControlError]="form.get('username')">
                    <label for="username" class="vwui-label">{{'Gebruikersnaam'|translate}}</label>
                    <input id="username" class="vwui-input" name="username" type="text" formControlName="username" autocomplete="off">
                </div>
                <div *ngIf="form.controls.password.enabled" class="vwui-form-group mb-4" [appFormControlError]="form.get('password')">
                    <label for="password" class="vwui-label">{{'Wachtwoord'|translate}}</label>
                    <input id="password" class="vwui-input" name="password" type="text" formControlName="password" autocomplete="off">
                </div>
                <button type="submit" class="d-none"><!-- Needed to submit with enter --></button>
            </form>
        </div>
    </div>
</div>

<app-actionbar *ngIf="form.dirty" (cancel)="cancel()" (save)="save()"></app-actionbar>
