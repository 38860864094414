<ng-select [compareWith]="compare"
           [items]="items | async"
           [hideSelected]="true"
           [loading]="loading"
           [typeahead]="onInput"
           (scrollToEnd)="scrollToEnd()"
           [formControl]="formControl"
           [multiple]="multiple"
           [placeholder]="placeholder"
           [clearable]="true"
>
    <ng-template ng-label-tmp let-item="item" let-clear="clear">
        <span>{{ getLabelText(item) }}</span>
        <span *ngIf="multiple" class="ng-select-clear-icon" (click)="clear(item)" aria-hidden="true">
            <app-icon iconName="x"></app-icon>
        </span>
    </ng-template>
    <ng-template ng-option-tmp let-item="item">
        <div class="mb-2">{{ getLabelText(item) }}</div>
        <table class="ng-select-table">
            <thead>
                <tr>
                    <th>{{'Object ID'|translate}}</th>
                    <th>{{'Hoofdfilter'|translate}}</th>
                    <th>{{'Subfilter'|translate}}</th>
                    <th>{{'Geo'|translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{{ item?.objectId }}</td>
                    <td>{{ item?.objectFilter }}</td>
                    <td>{{ item?.objectSubFilter }}</td>
                    <td>{{ item?.objectGeo }}</td>
                </tr>
            </tbody>
        </table>
    </ng-template>
</ng-select>
