// @ts-strict-ignore
import {Observable, of, throwError} from 'rxjs';
import {Injectable} from '@angular/core';
import {FormTypeStatusService} from '../form-type-status.service';
import {FormTypeStatusImportStatus} from '../../models/form-type-status-import-status';

@Injectable()
export class FormTypeStatusServiceMock implements FormTypeStatusService {
    importFormTypeStatus(file: File): Observable<FormTypeStatusImportStatus> {
        if (file.name === 'bad.xlsx') {
            return of({id: 1, status: 'Queued', lastError: null, importReport: null});
        } else if (file.name === 'good.xlsx') {
            return of({id: 2, status: 'Queued', lastError: null, importReport: null});
        }
        return throwError('File unknown: expect good.xlsx or bad.xlsx');
    }

    importStatus(importId: number): Observable<FormTypeStatusImportStatus> {
        if (importId === 1) {
            // return error
            return of({
                id: 1, status: 'Finished', lastError: null, importReport: {
                    success: false,
                    errors: [{
                        message: 'must match \"^(Concept|Vrijgegeven|Ingetrokken)$\"',
                        data: {row: '2', field: 'Status', sheet: 'Formulier status', value: 'blaat', column: 'C'}
                    }]
                }
            });
        } else if (importId === 2) {
            // return importedForms
            return of({
                id: 2, status: 'Finished', lastError: null, importReport: {
                    success: true,
                    errors: [],
                    importedForms: [{code: '1', revision: '1'}]
                }
            });
        }
        return throwError('File unknown: expect good.xlsx or bad.xlsx');
    }
}
