// @ts-strict-ignore
import {ObjectService} from '../object.service';
import {Observable, of} from 'rxjs';
import {PageResponse} from '../../models/page-response';
import {Object} from '../../models/object';
import {AbstractCrudServiceMock} from './abstract-crud.service.mock';
import {Injectable} from '@angular/core';
import {mockProjects} from './project.service.mock';
import {mockObjectTypes} from './object-type.service.mock';
import {map} from 'rxjs/operators';
import {ImportStatus} from '../../models/import-status';

export const mockObjects: Array<Object> = [
    {
        id: 888,
        project: mockProjects[0].id,
        objectOmschrijvingKort: 'Boortoren object',
        objectOmschrijvingLang: null,
        objectType: mockObjectTypes[0],
        objectLocationType: 'Linear',
        objectId: 'Boortoren object',
        objectXtot: null,
        objectRFID: null,
        objectQR: null,
        objectParent: null,
        objectOmschrijving20: null,
        objectOmschrijving19: null,
        objectOmschrijving18: null,
        objectOmschrijving17: null,
        objectOmschrijving16: null,
        objectOmschrijving15: null,
        objectOmschrijving14: null,
        objectOmschrijving13: null,
        objectOmschrijving12: null,
        objectOmschrijving11: null,
        objectOmschrijving10: null,
        objectOmschrijving9: null,
        objectOmschrijving8: null,
        objectOmschrijving7: null,
        objectOmschrijving6: null,
        objectOmschrijving5: null,
        objectOmschrijving4: null,
        objectOmschrijving3: null,
        objectGeo: 'Waddenzee',
        objectFilter: 'Boren Toren',
        objectSubFilter: null,
        objectStatus: null,
        objectDocumentLink: null,
        objectXvan: null,
        objectYtot: null,
        objectYvan: null
    }
];

@Injectable()
export class ObjectServiceMock extends AbstractCrudServiceMock<Object> implements ObjectService {

    importStartedAt: Date|null = null;

    constructor() {
        super(mockObjects);
    }

    searchObjects(project: number, page: number, search: string, queryParams?: object): Observable<PageResponse<Object>> {
        return this.getAll().pipe(
            map(pageResponse => {
                pageResponse.content = pageResponse.content.filter(paulaObject => {
                    return search === '*' || paulaObject.objectOmschrijvingKort.toLocaleUpperCase().indexOf(search.toUpperCase()) !== -1;
                });

                return pageResponse;
            }),
        );
    }

    getObjects(project: number, page: number): Observable<PageResponse<Object>> {
        return this.getAll();
    }

    getObject(project: number, id: number) {
        return this.get(id);
    }

    postObject(project: number, object: Object) {
        return this.post(object);
    }

    putObject(project: number, object: Object): Observable<Object> {
        return this.put(object);
    }

    importObjects(project: number, file: File): Observable<any> {
        this.importStartedAt = new Date();

        return of(1);
    }

    importObjectsStatus(project: number, statusId: number): Observable<ImportStatus> {
        if (this.importStartedAt === null || !this.importDelayElapsed()) {
            return of({
                id: 1,
                ownedBy: 'j.hobert@vwtelecom.com',
                status: 'Queued',
                lastError: null
            });
        }

        return of({
            id: 1,
            ownedBy: 'j.hobert@vwtelecom.com',
            status: 'Finished',
            lastError: null
        });
    }

    exportObjects(project: number): Observable<{ filename: string; data: Blob }> {
        return of(null);
    }

    exists(project: number, objectId: string): Observable<number> {
        return of(null);
    }

    private importDelayElapsed() {
        // @ts-ignore
        return (this.importStartedAt - (new Date())) > 500;
    }
}
