import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {InterfaceProcessingErrorDetail} from '../models/interface-processing-error';
import {InterfaceProcessingErrorService} from '../services/interface-processing-error.service';

@Injectable({
    providedIn: 'root'
})
export class InterfaceProcessingErrorResolver implements Resolve<InterfaceProcessingErrorDetail> {
    constructor(
        @Inject('InterfaceProcessingErrorService') private interfaceProcessingErrorService: InterfaceProcessingErrorService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<InterfaceProcessingErrorDetail> {
        return this.interfaceProcessingErrorService.getInterfaceProcessingError(+route.params.id);
    }
}
