import {UntypedFormControl, Validators} from '@angular/forms';
import {PaulaObjectLocationTypeControl} from '../../models/paula-object-location-type';
import {Object} from "../../models/object";

interface ObjectFormControl {
    label: string;
    name: string;
    type: 'text'|'number'|'toggle'|'select';
    visibleForDefaultProject: boolean;
    control: UntypedFormControl;
    isVisible?: (formValue: Partial<Object>) => boolean
}

function generateDescriptionControls(): ObjectFormControl[] {
    const controls: ObjectFormControl[] = [];

    for (let i = 3; i < 21; i++) {
        controls.push(
            {
                label: `Omschrijving ${i}`,
                name: `objectOmschrijving${i}`,
                type: 'text',
                control: new UntypedFormControl(null, [Validators.maxLength(255)]),
                visibleForDefaultProject: false
            }
        );
    }

    return controls;
}

export const objectLocationTypes: PaulaObjectLocationTypeControl[] = [
    {
        label: 'Linear object',
        value: 'Linear'
    },
    {
        label: 'Puntobject',
        value: 'Point'
    }
]

export const objectFormControls: ObjectFormControl[] = [
    {
        label: 'ObjectID',
        name: 'objectId',
        type: 'text',
        control: new UntypedFormControl(null, [Validators.maxLength(255)]),
        visibleForDefaultProject: true
    },
    {
        label: 'Korte omschrijving',
        name: 'objectOmschrijvingKort',
        type: 'text',
        control: new UntypedFormControl(null, [Validators.required, Validators.maxLength(255)]),
        visibleForDefaultProject: true
    },
    {
        label: 'Lange omschrijving',
        name: 'objectOmschrijvingLang',
        type: 'text',
        control: new UntypedFormControl(null, [Validators.maxLength(255)]),
        visibleForDefaultProject: true
    },
    {
        label: 'Hoofdfilter',
        name: 'objectFilter',
        type: 'text',
        control: new UntypedFormControl(null, [Validators.maxLength(255)]),
        visibleForDefaultProject: false
    },
    {
        label: 'Subfilter',
        name: 'objectSubFilter',
        type: 'text',
        control: new UntypedFormControl(null, [Validators.maxLength(255)]),
        visibleForDefaultProject: false
    },
    {
        label: 'Geo',
        name: 'objectGeo',
        type: 'text',
        control: new UntypedFormControl(null, [Validators.maxLength(255)]),
        visibleForDefaultProject: false
    },
    {
        label: 'Objecttype',
        name: 'objectType',
        type: 'select',
        control: new UntypedFormControl(null, [Validators.maxLength(255), Validators.required]),
        visibleForDefaultProject: true
    },
    {
        label: 'Locatietype object',
        name: 'objectLocationType',
        type: 'select',
        control: new UntypedFormControl(null, [Validators.maxLength(255), Validators.required]),
        visibleForDefaultProject: true,
    },
    {
        label: 'Actief',
        name: 'objectStatus',
        type: 'toggle',
        control: new UntypedFormControl(true),
        visibleForDefaultProject: true
    },
    {
        label: 'Document link',
        name: 'objectDocumentLink',
        type: 'text',
        control: new UntypedFormControl(null, [Validators.maxLength(255)]),
        visibleForDefaultProject: true
    },
    {
        label: 'GPS latitude van',
        name: 'objectYvan',
        type: 'number',
        control: new UntypedFormControl(null),
        visibleForDefaultProject: true
    },
    {
        label: 'GPS longitude van',
        name: 'objectXvan',
        type: 'number',
        control: new UntypedFormControl(null),
        visibleForDefaultProject: true
    },
    {
        label: 'GPS latitude tot',
        name: 'objectYtot',
        type: 'number',
        control: new UntypedFormControl(null),
        visibleForDefaultProject: true,
        isVisible: (object) => object.objectLocationType === "Linear"
    },
    {
        label: 'GPS longitude tot',
        name: 'objectXtot',
        type: 'number',
        control: new UntypedFormControl(null),
        visibleForDefaultProject: true,
        isVisible: (object) => object.objectLocationType === "Linear"
    },
    {
        label: 'Parent object',
        name: 'objectParent',
        type: 'text',
        control: new UntypedFormControl(null, [Validators.maxLength(255)]),
        visibleForDefaultProject: false
    },
    {
        label: 'QR code',
        name: 'objectQR',
        type: 'text',
        control: new UntypedFormControl(null, [Validators.maxLength(255)]),
        visibleForDefaultProject: false
    },
    {
        label: 'RFID',
        name: 'objectRFID',
        type: 'text',
        control: new UntypedFormControl(null, [Validators.maxLength(255)]),
        visibleForDefaultProject: false
    },
];

export const objectDescriptionFormControls = generateDescriptionControls();
