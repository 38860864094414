import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './header/header.component';
import {NavComponent} from './nav/nav.component';
import {RouterModule} from '@angular/router';
import {IconComponent} from './icon/icon.component';
import {AvatarComponent} from './avatar/avatar.component';
import {UserComponent} from './user/user.component';
import {AsideComponent} from './aside/aside.component';
import {ActionbarComponent} from './actionbar/actionbar.component';
import {ToolbarComponent} from './toolbar/toolbar.component';
import {AsideActionbarComponent} from './aside-actionbar/aside-actionbar.component';
import {ActionMenuComponent} from './action-menu/action-menu.component';
import {SearchComponent} from './search/search.component';
import {TagComponent} from './tag/tag.component';
import {ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [
      HeaderComponent,
      NavComponent,
      IconComponent,
      AvatarComponent,
      UserComponent,
      AsideComponent,
      ActionbarComponent,
      ToolbarComponent,
      AsideActionbarComponent,
      ActionMenuComponent,
      SearchComponent,
      TagComponent
  ],
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        TranslateModule
    ],
  exports: [
      HeaderComponent,
      NavComponent,
      IconComponent,
      AvatarComponent,
      UserComponent,
      AsideComponent,
      AsideActionbarComponent,
      ActionbarComponent,
      ToolbarComponent,
      ActionMenuComponent,
      SearchComponent,
      TagComponent
  ]
})
export class VwuiModule { }
