// @ts-strict-ignore
import {Component, Inject, OnInit} from '@angular/core';
import {UserService} from '../../services/user.service';
import {TableFilter} from '../../components/table/table.component';
import {User, UserRoles} from '../../models/user';
import {ActivatedRoute, Router} from '@angular/router';
import {Filters} from '../../utils/filters';
import {Observable} from 'rxjs';
import {AbstractListComponent} from "../abstract-list.component";
import {mergeQueryParamsAndNavigate} from "../../utils/merge-query-params-and-navigate";
import {PageResponse} from "../../models/page-response";
import {BsModalService} from "ngx-bootstrap/modal";
import {CreateUserModalComponent} from "../../components/create-user-modal/create-user-modal.component";

@Component({
    selector: 'app-user-list',
    templateUrl: './user-list.component.html'
})
export class UserListComponent extends AbstractListComponent<User> implements OnInit {
    headerTitles = [
        {title: 'Gebruikersnaam', sortKey: 'username', filterKey: 'username'},
        {title: 'Naam', sortKey: 'displayName', filterKey: 'displayName'},
        {title: 'Bedrijf', sortKey: 'company.name', filterKey: 'company.name'}
    ];

    userFilters = [
        {title: 'Alles', role: undefined},
        {title: 'Applicatiebeheerder', role: UserRoles.RoleApplicationAdmin},
        {title: 'Coördinator', role: UserRoles.RoleCoordinator},
        {title: 'Masterdatabeheerder', role: UserRoles.RoleMasterDataAdmin},
        {title: 'Uitvoering', role: UserRoles.RoleExecutor},
        {title: 'Inactief', role: UserRoles.RoleInactive}
    ];

    appliedFilter: TableFilter;

    constructor(
        @Inject('UserService') public userService: UserService,
        activatedRoute: ActivatedRoute,
        router: Router,
        private modalService: BsModalService
    ) {
        super(activatedRoute, router);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.subscriptions.push(
            this.activatedRoute.queryParams.subscribe((queryParams) => {
                this.appliedFilter = Filters.getFilteringFromParams(queryParams);
            })
        );
    }

    loadData(page: number, filter: TableFilter, search: string | undefined): Observable<PageResponse<User>> {
        return this.userService.getUsers(page, filter);
    }

    async selectRoleFilter(role: UserRoles) {
        if (role === null || role === undefined) {
            this.deactivateRoleFilters();
            return;
        }

        await mergeQueryParamsAndNavigate(this.router, this.activatedRoute, {
            'filter-roles': role,
        })
    }

    deactivateRoleFilters() {
        const queryParams = this.activatedRoute.snapshot.queryParamMap;
        const newQueryParams: { [key: string]: string } = {};

        for (const key of queryParams.keys) {
            if (key !== 'filter-roles') {
                newQueryParams[key] = queryParams.get(key);
            }
        }

        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: newQueryParams,
        });
    }

    createUser() {
        this.modalService.show(CreateUserModalComponent)
    }
}
