import {AbstractCrudServiceMock} from './abstract-crud.service.mock';
import {FormType, FormTypes, FormTypeStatus} from '../../models/form-type';
import {FormTypeService} from '../form-type.service';
import {Observable, of, throwError} from 'rxjs';
import {PageResponse} from '../../models/page-response';
import {mockObjectTypes} from './object-type.service.mock';
import * as moment from 'moment';
import {Choice, ChoiceQuestion, FormItem} from '../../models/form-item';
import {mockCompanies} from './company.service.mock';
import {FormTypeImportStatus} from '../../models/form-type-import-status';
import {Injectable} from '@angular/core';

export const mockFormTypes: Array<FormType> = [
    {
        id: 1,
        title: 'Boortoren formulier',
        status: FormTypeStatus.Open,
        code: '0001',
        paulaObjectTypes: [mockObjectTypes[0]],
        formItems: [],
        companies: [mockCompanies[0]],
        tags: [],
        createdBy: 'w.slaghekke@recognize.nl',
        createdAt: moment().toISOString(),
        updatedAt: moment().toISOString(),
        type: FormTypes.FormType,
        askLocation: false,
        showLocationOnMap: false,
        inspectionForm: false,
        executorCanCreate: false,
        copyAnswers: false,
        contactPerson: null,
        revisionNumber: '1',
        askExecutorToFillFormAgain: false
    }
];

@Injectable()
export class FormTypeServiceMock extends AbstractCrudServiceMock<FormType> implements FormTypeService {
    constructor() {
        super(mockFormTypes);
    }

    getList(page: number): Observable<PageResponse<FormType>> {
        return this.getAll();
    }

    getDetail(formTypeId: number): Observable<FormType> {
        return this.get(formTypeId);
    }

    getByCompany(company: number): Observable<FormType[]> {
        return of(mockFormTypes.filter(it => company in it.companies.map(x => x.id)));
    }

    findByStatus(status: string): Observable<FormType[]> {
        return of(mockFormTypes);
    }

    getByCode(code: string): Observable<FormType[]> {
        return of(mockFormTypes);
    }

    postFormType(formType: FormType): Observable<FormType> {
        return this.post(formType);
    }

    postFormTypeCopy(formType: FormType): Observable<FormType> {
        return this.post(formType);
    }

    putFormType(formType: FormType): Observable<FormType> {
        return this.put(formType);
    }

    nextChoiceId() {
        const formItems = this.data
            .map((formType) => formType.formItems);
        const flatQuestions = (<FormItem[]>[]).concat(...formItems);
        const choices = flatQuestions
            .filter((question): question is ChoiceQuestion => question.type == 'choice')
            .map(question => question.choices);
        const flatChoices = (<Choice[]>[]).concat(...choices);
        const highestId = flatChoices.reduce((highest, choice) => {
            if (choice.id && +choice.id > highest) {
                return +choice.id;
            }

            return highest;
        }, 0);

        return (highestId + 1).toString();
    }

    importFormTypesV1(file: File): Observable<any> {
        return this.put({} as FormType);
    }

    importFormTypesV2(workbook: File, imageZip: File): Observable<FormTypeImportStatus> {
        if (workbook.name === 'bad.xlsx') {
            return of({id: 1, status: 'Queued', lastError: null, importReport: null});
        } else if (workbook.name === 'good.xlsx') {
            return of({id: 2, status: 'Queued', lastError: null, importReport: null});
        }
        return throwError('File unknown: expect good.xlsx or bad.xlsx');
    }

    importStatus(importId: number): Observable<FormTypeImportStatus> {
        if (importId === 1) {
            // return error
            return of({id: 1, status: 'Finished', lastError: null, importReport: {
                success: false,
                errors: [{
                    message: 'Data can\'t be read. Make sure you\'re using the Asset Check import template without changing the structure.',
                    data: {}
                }]
            }});
        } else if (importId === 2) {
            // return importedForms
            return of({
                id: 2, status: 'Finished', lastError: null, importReport: {
                    success: true,
                    errors: [],
                    importedForms: [{code: '1', revision: '1'}]
                }
            });
        }
        return throwError('File unknown: expect good.xlsx or bad.xlsx');
    }

    downloadForm(formType: FormType): Observable<any> {
        throw new Error('Not implemented');
    }

    copyProjectForm(projectFormId: number): Observable<FormType> {
        return this.get(1)
    }
}
