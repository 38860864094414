// @ts-strict-ignore
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Forms} from '../../utils/forms';
import {FollowUpFormTemplate, FollowUpProjectJobTemplate} from '../../models/form-item';
import {recoveryTimeSelectOptions} from '../../models/recovery-time';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ProjectJobService} from '../../services/project-job.service';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {FeatureToggle} from '../../models/feature-toggle';
import {TranslateService} from '@ngx-translate/core';
import {FormType, FormTypeStatus} from '../../models/form-type';
import {Subscription} from 'rxjs';
import {FormTypeService} from '../../services/form-type.service';
import {actionTakers} from "../../models/action-taker";
import {ModalResult} from "../../models/modal.result";

@Component({
    selector: 'app-follow-up-form-modal',
    templateUrl: './follow-up-form-modal.component.html'
})
export class FollowUpFormModalComponent implements OnInit, OnDestroy {
    public form: UntypedFormGroup;
    public forms: FormType[] = [];
    public initialForm?: FollowUpFormTemplate;
    onClose: (result: ModalResult, data: FollowUpProjectJobTemplate) => void;

    readonly recoveryTimes = recoveryTimeSelectOptions;
    readonly maximoToggle = FeatureToggle.Maximo;
    readonly actionTakers = actionTakers

    private subscriptions: Subscription[] = [];

    constructor(
        @Inject('ProjectJobService') private projectJobService: ProjectJobService,
        @Inject('FormTypeService') private formTypeService: FormTypeService,
        protected bsModalRef: BsModalRef,
        private fb: UntypedFormBuilder,
        private translateService: TranslateService,
    ) {
    }

    ngOnInit() {
        this.createForm(this.fb);
        // Pre-fill title field
        this.subscriptions.push(this.form.controls.formType.valueChanges.subscribe(val => {
            if (val) {
                this.form.controls.title.patchValue(this.form.controls.formType.value.title);
            }
        }));

        this.subscriptions.push(this.formTypeService.findByStatus(FormTypeStatus.Released).subscribe(forms => {
            this.forms = forms;
        }));
    }

    ngOnDestroy(): void {
        if (this.subscriptions) {
            this.subscriptions.forEach(subscription => subscription.unsubscribe);
        }
    }

    submit() {
        if (!this.form.valid) {
            Forms.updateValueAndValidityRecursive(this.form);
            return;
        }

        this.closeModal('confirmed', {
            ...this.form.value
        });
    }

    closeModal(result: ModalResult, data: any = null) {
        this.onClose(result, data);
        this.bsModalRef.hide();
    }

    createForm(fb: UntypedFormBuilder) {
        this.form = fb.group({
            title: fb.control(null, [Validators.required, Validators.maxLength(255)]),
            formType: fb.control(null),
            recoveryTime: fb.control(null),
            actionTaker: fb.control(null)
        });

        if (this.initialForm) {
            this.form.patchValue(this.initialForm);
        }
    }

    getSaveButtonText() {
        const translationKey = this.initialForm ? 'Aanpassen' : 'Koppelen';
        return this.translateService.instant(translationKey);
    }
}
