// @ts-strict-ignore
import {Component, Inject, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {TableFilter} from '../../components/table/table.component';
import {WorkerGroupService} from '../../services/worker-group.service';
import {ActionMenuComponent} from '../../vwui/action-menu/action-menu.component';
import {ToastrService} from 'ngx-toastr';
import {AbstractListComponent} from '../abstract-list.component';
import {WorkerGroup} from '../../models/worker-group';
import {PageResponse} from '../../models/page-response';

@Component({
    selector: 'app-worker-group-list',
    templateUrl: './worker-group-list.component.html'
})
export class WorkerGroupListComponent extends AbstractListComponent<WorkerGroup> {
    @ViewChild('actionmenu') actionMenu: ActionMenuComponent;

    tableHeaders = [
        {sortKey: 'name', filterKey: 'name', title: 'Medewerkersgroepnaam'},
        {title: 'Aantal medewerkers'}
    ];

    constructor(
        @Inject('WorkerGroupService') private workergroupService: WorkerGroupService,
        protected activatedRouter: ActivatedRoute,
        protected router: Router,
        private toast: ToastrService,
    ) {
        super(activatedRouter, router);
    }

    loadData(page: number, filter: TableFilter): Observable<PageResponse<WorkerGroup>> {
        return this.workergroupService.getList(page, filter);
    }

    async onRemove(event: Event, workerGroupId: number) {
        event.preventDefault();
        event.stopPropagation();

        try {
            await this.workergroupService.deleteWorkerGroup(workerGroupId).toPromise();

            this.refresh();
            this.toast.success('Verwijderd');
        } catch (e) {
            this.toast.error('Verwijderen mislukt');
        }
    }
}
