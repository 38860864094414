import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavService {
  navOpen: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() { }

  toggleNav() {
    const currentNavOpen = this.navOpen.getValue() as boolean;

    this.navOpen.next(!currentNavOpen);
  }
}
