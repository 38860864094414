// @ts-strict-ignore
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ProjectJobService} from '../../services/project-job.service';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {Forms} from '../../utils/forms';
import {Project} from '../../models/project';
import {ProjectJobCopyRequest} from '../../models/project-job-copy-request';
import {pairwise, startWith} from 'rxjs/operators';
import {ProjectForm} from '../../models/form-type';
import {Object} from '../../models/object';
import {ModalResult} from "../../models/modal.result";

@Component({
    selector: 'app-project-job-modal',
    templateUrl: './project-job-modal.component.html'
})
export class ProjectJobModalComponent implements OnInit, OnDestroy {
    project: Project;

    form: UntypedFormGroup;
    formSubscriptions: Subscription[] = [];

    pendingSave = false;

    onClose: (result: ModalResult, data: ProjectJobCopyRequest[]) => void;

    constructor(
        @Inject('ProjectJobService') private projectJobService: ProjectJobService,
        protected bsModalRef: BsModalRef,
        private fb: UntypedFormBuilder
    ) {
    }

    ngOnDestroy(): void {
        this.formSubscriptions.forEach(s => s.unsubscribe());
    }

    async ngOnInit() {
        this.createForm(this.fb);
        this.createFormSubscriptions();

        // Load initial project if needed
        if (this.project) {
            this.form.controls.project.patchValue(this.project);
        }

        try {
            const code = await this.projectJobService.generateJobCode().toPromise();
            const codeControl = this.form.get('code');
            if (!codeControl.value) {
                codeControl.patchValue(code);
            }
        } catch (e) {
            console.error('Failed to generate jobCode', e);
        }
    }

    closeModal(result: ModalResult, data: any = null) {
        this.onClose(result, data);
        this.bsModalRef.hide();
    }

    createForm(fb: UntypedFormBuilder) {
        this.form = fb.group({
            title: fb.control(null, [Validators.required, Validators.maxLength(320)]),
            code: fb.control(null, [Validators.required, Validators.maxLength(255)], [this.validateProjectJobNotTaken.bind(this)]),
            project: fb.control(null, [Validators.required]),
            projectForm: fb.control(null, [Validators.required]),
            objects: fb.control(null, [Validators.required]),
        });
    }

    private validateProjectJobNotTaken(control: AbstractControl): Observable<ValidationErrors> {
        return Forms.validateNotTaken(control, 0, this.projectJobService.exists(control.value));
    }

    private createFormSubscriptions() {
        // Reset formType select if project value is changed
        this.formSubscriptions.push(
            this.form.controls.project.valueChanges.subscribe(val => {
                if (val) {
                    this.project = this.form.controls.project.value;

                    if (this.form.controls.projectForm.value) {
                        if (val.id !== this.form.controls.projectForm.value.project) {
                            this.form.controls.projectForm.reset();
                            this.form.controls.title.reset();
                        }
                    }

                    // Validate each project object
                    if (this.form.controls.objects.value) {
                        for (const object of this.form.controls.objects.value) {
                            if (val.id !== object.project) {
                                this.form.controls.objects.reset();
                                this.form.controls.title.reset();
                                break;
                            }
                        }
                    }
                }
            })
        );

        // Always reset objects on project form value change
        this.form.controls.projectForm.valueChanges.pipe(
            startWith<ProjectForm>(null as ProjectForm),
            pairwise()
        ).subscribe(([previous, current]) => {
            // Dont reset if id stayed the same
            if (previous && current && previous.id === current.id) {
                return;
            }
            if (this.form.controls.objects.value) {
                this.form.controls.objects.reset();
                this.form.controls.title.reset();
            }
        });

        // Pre-fill title field
        this.form.controls.projectForm.valueChanges.subscribe(val => {
            if (val) {
                this.form.controls.title.patchValue(this.form.controls.projectForm.value.title);
            }
        });
    }

    async submit() {
        await this.saveAndCloneForm();
    }

    async saveAndCloneForm() {
        if (!this.form.valid) {
            Forms.updateValueAndValidityRecursive(this.form);
            return;
        }
        if (this.pendingSave) {
            console.warn('Save already in progress');
        }

        // Save job and clone selected form
        try {
            this.pendingSave = true;

            for (const copyRequest of this.formToCopyRequests(this.form.value)) {
                await this.projectJobService.cloneProjectFormToJob(this.form.value.project.id, copyRequest).toPromise();
            }
            this.closeModal('confirmed');
        } catch (error) {
            console.error('Error during saving and cloning form for project job', error);
            this.closeModal('error');
        } finally {
            this.pendingSave = false;
        }
    }

    private formToCopyRequests(formData: {
        objects: Object[],
        title: string,
        code: string,
        projectForm: number | ProjectForm
    }): ProjectJobCopyRequest[] {
        if (!formData || !formData.objects) {
            return [];
        }

        return formData.objects
            .map(object => {
                const titleSuffix = ', ' + object.objectOmschrijvingKort;
                const maxTitleLength = 320;
                return ({
                    title: formData.title.substr(0, maxTitleLength - titleSuffix.length) + titleSuffix,
                    code: formData.code,
                    projectForm: typeof formData.projectForm === 'number' ? formData.projectForm : +formData.projectForm.id,
                    paulaObject: +object.id
                });
            });
    }
}
