import {PageResponse} from '../../models/page-response';
import {Observable, of} from 'rxjs';
import {Identifiable} from '../../models/identifiable';
import {tap} from 'rxjs/operators';
import {TableFilter} from '../../components/table/table.component';

export class AbstractCrudServiceMock<T extends Identifiable> {

    constructor(protected data: Array<T>) {
    }

    protected static matchesFilter(filter: TableFilter, item: {[key: string]: string}) {
        for (const key of Object.keys(filter.filter)) {
            const singleFilter = filter.filter[key];
            const filterValue = typeof singleFilter === 'object' ? singleFilter.value : singleFilter;
            const filterOperator = typeof singleFilter === 'object' ? singleFilter.operator : ':';
            const propertyValue = AbstractCrudServiceMock.getPropertyValue(item, key);

            if (filterOperator == '=' && filterValue != propertyValue) {
                return false
            } else if (filterOperator == ':' && (!propertyValue || propertyValue.indexOf(filterValue) === -1)) {
                return false;
            }
        }
        return true;
    }

    protected static getPropertyValue(item: any, propertyPath: string): string {
        let data = item;
        for (const part of propertyPath.split('.')) {
            if (data.hasOwnProperty(part)) {
                data = data[part];
            } else {
                return '';
            }
        }
        return data;
    }

    protected getAll(filter?: TableFilter) {
        const data = filter
            ? this.data.filter(it => AbstractCrudServiceMock.matchesFilter(filter, it as any))
            : this.data;

        const result: PageResponse<T> = {
            content: data,
            empty: data.length === 0,
            first: true,
            last: true,
            number: 0,
            numberOfElements: data.length,
            size: 25,
            totalElements: data.length,
            totalPages: 1
        };

        return of(result);
    }

    protected get(id: number): Observable<T> {
        const result = this.data.find(item => +item.id === +id);
        if (result === undefined) {
            throw new Error(`No item found for id '${id}'`)
        }

        return of(result);
    }

    protected post(newData: T) {
        newData.id = this.nextId();
        this.data.push(newData);

        return this.get(newData.id);
    }

    protected put(newData: Partial<T> & Identifiable): Observable<T> {
        return this.get(newData.id).pipe(
            tap(item => {
                Object.assign(item, newData);
            })
        );
    }

    protected delete(data: T): Observable<T> {
        this.data = this.data.filter(question => +question.id !== +data.id);

        return of(data);
    }

    protected nextId(): number {
        const highestId = this.data.reduce((highest, item) => {
            if (item.id > highest) {
                return item.id;
            }

            return highest;
        }, 0);

        return highestId + 1;
    }
}
