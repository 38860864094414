export const recoveryTimes = {
    'DirectlyRecovered': 'Direct hersteld',
    'Directly' : 'Direct',
    'TwentyFourHours': 'Spoed < 24 uur',
    'FortyEightHours': '< 48 uur herstellen',
    'OneWeek': '< 1 week herstellen',
    'TwoWeeks': '< 2 weken herstellen',
    'FourWeeks': '< 4 weken herstellen',
    'OneMonth': '< 1 maand herstellen',
    'TwoMonths': '< 2 maand herstellen',
    'ThreeMonths': '< 3 maand herstellen',
    'FourMonths': '< 4 maand herstellen',
    'SixMonths': '< 6 maand herstellen',
    'OneYear': '< 1 jaar herstellen',
};

export type RecoveryTime = keyof typeof recoveryTimes

export const recoveryTimeSelectOptions = Object.entries(recoveryTimes).map(([key, value]) => {
    return {key, value};
})
