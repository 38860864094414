<ng-select [compareWith]="compare"
           [items]="items | async"
           [hideSelected]="true"
           [loading]="loading"
           [typeahead]="onInput"
           (scrollToEnd)="scrollToEnd()"
           [formControl]="formControl"
           [multiple]="multiple"
           [placeholder]="placeholder"
           [clearable]="true"
>
    <ng-template ng-label-tmp let-item="item" let-clear="clear">
        <span>{{item.code}} | {{item.title}}</span>
        <span *ngIf="multiple" class="ng-select-clear-icon" (click)="clear(item)" aria-hidden="true">
            <app-icon iconName="x"></app-icon>
        </span>
    </ng-template>
    <ng-template ng-option-tmp let-item="item">
        <span>{{item.code}} | {{item.title}}</span>
        <br *ngIf="item.tags.length" />
        <span *ngFor="let tag of item.tags"><app-tag [className]="tag.className">{{tag.name}}</app-tag></span>
    </ng-template>
</ng-select>
