<div class="vwui-card is-basic">
    <app-search (searchChanged)="searchChanged($event)" placeholder="{{'Zoek op achternaam of e-mailadres'|translate}}"></app-search>
    <ng-container *ngIf="paginator$|async as paginator">
        <table class="vwui-table" [ngClass]="{'has-hover': !paginator.isEmpty, 'has-action': !paginator.isEmpty}">
            <tr *ngFor="let user of filterHidden(paginator.content$|async)" (click)="userSelected.emit(user)">
                <td>
                    {{ user.displayName }}<br>
                    {{ user.username }}
                </td>
                <td>{{ user?.company?.name }}</td>
                <td class="vwui-table__arrow-icon">
                    <app-icon iconName="arrow-right"></app-icon>
                </td>
            </tr>
            <tr *ngIf="paginator.isEmpty">
                <td>{{'Er zijn geen medewerkers gevonden'|translate}}</td>
            </tr>
        </table>
        <app-paginator *ngIf="!paginator.isEmpty" [paginator]="paginator"></app-paginator>
    </ng-container>
</div>
