// @ts-strict-ignore
import {Component, forwardRef, Input, OnInit, QueryList, ViewChildren} from '@angular/core';
import {
    ControlContainer,
    ControlValueAccessor,
    Form,
    FormControl,
    FormGroup,
    NG_VALUE_ACCESSOR,
    Validators
} from '@angular/forms';
import {Choice, ChoiceQuestion} from '../../models/form-item';
import {CheckboxComponent} from '../checkbox/checkbox.component';
import {FormUtils} from '../../utils/form-utils';
import {Forms} from '../../utils/forms';

@Component({
    selector: 'app-question-dependency',
    templateUrl: './question-dependency.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => QuestionDependencyComponent),
            multi: true
        }
    ]
})
export class QuestionDependencyComponent implements ControlValueAccessor {
    @Input() previousQuestions: Array<ChoiceQuestion>;
    @ViewChildren('questionChoices') checkboxes: QueryList<CheckboxComponent>;

    disabled = false;
    selectedQuestion: ChoiceQuestion = null;
    selectedChoices: Array<Choice> = [];
    private onChange: (value: Array<Choice>) => void;
    private onTouched: () => void;

    registerOnChange(fn: any): void {
        this.onChange = (value) => {
            fn(value);
        };
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(choices: Array<Choice>): void {
        if (!choices) {
            return;
        }

        this.selectedQuestion = null;

        const choiceIds = choices.map(choice => choice.id);
        for (const question of this.previousQuestions) {
            for (const choice of question.choices) {
                if (choiceIds.indexOf(choice.id) !== -1) {
                    this.selectedQuestion = question;
                }
            }
        }

        if (this.selectedQuestion) {
            this.selectedChoices = choices;
        }
    }

    questionDependencyChanged(event: Event) {
        const target = event.target as HTMLInputElement;
        const questionId = +target.value;

        const previousQuestion = this.selectedQuestion;
        this.selectedQuestion = this.previousQuestions.find(question => question.id === questionId);

        if (previousQuestion !== this.selectedQuestion) {
            this.selectedChoices = [];
        }

        this.onTouched();
        this.onChange(this.selectedChoices);
    }

    choiceChanged() {
        this.selectedChoices.length = 0; // clear

        if (this.checkboxes) {
            this.checkboxes
                .filter((checkbox) => checkbox.checked)
                .map((checkbox) => {
                    return this.selectedQuestion.choices.find(choice => choice.id === checkbox.value);
                })
                .forEach((choice: Choice) => this.selectedChoices.push(choice));
        }

        this.onTouched();
        this.onChange(this.selectedChoices);
    }

    getChapterNumber(item: ChoiceQuestion) {
        return FormUtils.getChapterNumberFromPosition(item.position);
    }

    getChapterPosition(item: ChoiceQuestion) {
        return FormUtils.getChapterPositionFromPosition(item.position);
    }

    get selectedChoiceIds() {
        return this.selectedChoices.map(choice => choice.id);
    }
}
