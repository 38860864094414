<div class="vwui-header">
  <div class="vwui-header__left">
    <button class="vwui-header__nav-toggle" (click)="onToggleNav()">
      <app-icon iconName="menu"></app-icon>
    </button>
    <div class="vwui-header__logo">
        <ng-content></ng-content>
    </div>
  </div>
  <div class="vwui-header__right">
    <ng-content select="app-user"></ng-content>
  </div>
</div>
