<app-modal modalTitle="Kaartlaaginstellingen" modalSaveBtnLabel="{{'Afsluiten'|translate}}" (save)="submit()" [showCloseBtn]="false"
           (cancel)="closeModal('cancelled')">
    <form>
        <div class="vwui-form-group u-mb-m mt-3">
            <div [appFormControlError]="destinationControl" class="vwui-form-group mb-4">
                <label class="vwui-label">{{'Vul hieronder de kaartlaaginstellingen aan'|translate}}</label>
            </div>
                <label for="objectModalTitle">{{'Titel'|translate}}</label>
                <input id="objectModalTitle" class="vwui-input u-mb-m" name="objectModalTitle" [formControl]="destinationControl.get('objectModalTitle')" type="text">
                <label class="vwui-input-control is-toggle has-label mb-4 vwui-label">
                    <input class="vwui-input" type="checkbox" [formControl]="destinationControl.get('objectModalShowAttachments')">
                    <span>{{'Toon bijlagen'|translate}}</span>
                    <span class="checkbox"></span>
                </label>

            <table class="vwui-table">
            <tr>
                <th class="u-pa-s">{{'Label'|translate}}</th>
                <th class="u-pa-s">{{'Veldnaam'|translate}}</th>
            </tr>
            <tr *ngFor="let control of mapLayerDataPropertyControls; index as i"
                [appFormControlError]="control">
                <td class="u-pa-s">
                    <input class="vwui-input project-map__label" name="label" [formControl]="control.get('label')" type="text">
                </td>
                <td class="u-pa-s">
                    <input class="vwui-input project-map__label" name="fieldName" [formControl]="control.get('fieldName')" type="text">
                </td>
            </tr>
            </table>
        </div>
        <button type="submit" class="d-none"><!-- Needed to submit with enter --></button>
    </form>
</app-modal>

