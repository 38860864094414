// @ts-strict-ignore
import {Component} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import * as FileSaver from 'file-saver';
import {BlobService} from '../../services/blob.service';
import {mimetypeToExtension} from '../../utils/mime-type';

@Component({
    selector: 'app-image-answer-preview-modal',
    templateUrl: './image-answer-preview-modal.component.html'
})
export class ImageAnswerPreviewModalComponent {
    imageSrc: string;

    constructor(
        private blob: BlobService,
        private bsModalRef: BsModalRef
    ) {
    }

    close() {
        this.bsModalRef.hide();
    }

    public async downloadFile() {
        const blob = await this.blob.getBlob(this.imageSrc).toPromise();
        const blobType = blob.type
        const extension = blobType in mimetypeToExtension
            ? mimetypeToExtension[blobType as keyof typeof mimetypeToExtension]
            : mimetypeToExtension['image/jpeg'];

        FileSaver.saveAs(blob, `${this.imageSrc}.${extension}`);
    }

    public getImageUrl(): string {
        return `/blob/${this.imageSrc}`;
    }
}
