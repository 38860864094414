import {Injectable} from '@angular/core';
import {FormItem} from '../models/form-item';
import {FormUtils} from '../utils/form-utils';

@Injectable({
    providedIn: 'root'
})
export class FormBuilderService {
    formItems: FormItem[] = [];

    constructor() {
    }

    initFormBuilder(formItems: FormItem[]) {
        this.formItems = formItems;
    }

    getPossibleDependencies(formItem: FormItem, includeOwnChapterQuestions: boolean, reducedSet: FormItem[] = []) {
        const possibleFormItemsToDependOn = reducedSet.length > 0 ? reducedSet : this.formItems;

        let formItemPosition = formItem.position;
        if (includeOwnChapterQuestions) {
            formItemPosition = Math.ceil((formItemPosition + 1 ) / 1000) * 1000;
        }

        return FormUtils.getPossibleDependencies(formItemPosition, possibleFormItemsToDependOn);
    }
}
