<app-toolbar [showBackBtn]="true" backBtnRoute="/beheer/interface-processing-errors"></app-toolbar>

<div class="vwui-container is-full">
    <div class="row">
        <div class="col-lg-12">
            <div class="vwui-card">
                <div class="vwui-card__header">Foutmelding</div>
                <div class="vwui-card__content">
                    <form [formGroup]="form">
                        <div [appFormControlError]="form.get('message')" class="vwui-form-group mb-4">
                            <label for="message" class="vwui-label">Foutmelding</label>
                            <input id="message" name="username" type="text" class="vwui-input" formControlName="message">
                        </div>
                        <div [appFormControlError]="form.get('xml')" class="vwui-form-group mb-4">
                            <label for="xml" class="vwui-label">XML Bericht</label>
                            <textarea
                                #xmlField
                                id="xml"
                                class="vwui-textarea"
                                rows="5"
                                autocomplete="off"
                                formControlName="xml"
                                appTextareaAutoresize></textarea>
                        </div>
                        <div [appFormControlError]="form.get('data')" class="vwui-form-group mb-4">
                            <label for="data" class="vwui-label">Data</label>
                            <textarea id="data"
                                      class="vwui-textarea"
                                      rows="5"
                                      autocomplete="off"
                                      formControlName="data"
                                      appTextareaAutoresize></textarea>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
