// @ts-strict-ignore
import {Component, Inject, Input} from '@angular/core';
import {Project} from '../../models/project';
import {Observable, timer} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {HeaderTitle, TableFilter} from '../table/table.component';
import {ObjectService} from '../../services/object.service';
import {AbstractListComponent} from '../../pages/abstract-list.component';
import {PageResponse} from '../../models/page-response';
import {UntypedFormBuilder} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {FileUploadEvent} from '../file-upload-btn/file-upload.interface';
import {LoadingBtnEvent} from '../loading-btn/loading-btn.interface';
import {ErrorToast} from '../../utils/error-toast';
import {FileUtil} from '../../utils/file';
import {filter, switchMap, take} from 'rxjs/operators';

interface ProjectObjectHeaderTitle extends HeaderTitle {
    visibleForDefaultProject: boolean;
}

@Component({
    selector: 'app-project-object-tab',
    templateUrl: './project-object-tab.component.html'
})
export class ProjectObjectTabComponent extends AbstractListComponent<Object> {
    @Input() project: Project;
    headerTitles: ProjectObjectHeaderTitle[] = [
        {visibleForDefaultProject: true, sortKey: 'objectId', filterKey: 'objectId', title: 'ObjectID'},
        {
            visibleForDefaultProject: true,
            sortKey: 'objectOmschrijvingKort',
            filterKey: 'objectOmschrijvingKort',
            title: 'Korte omschrijving'
        },
        {
            visibleForDefaultProject: true,
            sortKey: 'objectOmschrijvingLang',
            filterKey: 'objectOmschrijvingLang',
            title: 'Lange omschrijving'
        },
        {visibleForDefaultProject: false, sortKey: 'objectFilter', filterKey: 'objectFilter', title: 'Hoofdfilter'},
        {visibleForDefaultProject: false, sortKey: 'objectGeo', filterKey: 'objectGeo', title: 'Geo'},
        {visibleForDefaultProject: true, sortKey: 'objectType.title', filterKey: 'objectType.title', title: 'Objecttype'},
        {visibleForDefaultProject: true, sortKey: 'objectStatus', title: 'Status'},
        {visibleForDefaultProject: false, title: 'Document'},
    ];
    importLoading = false;

    constructor(
        @Inject('ObjectService') private objectService: ObjectService,
        protected activatedRoute: ActivatedRoute,
        protected router: Router,
        private fb: UntypedFormBuilder,
        private toast: ToastrService,
    ) {
        super(activatedRoute, router);
    }

    getHeaders() {
        return this.project.hasMaintenanceContract
            ? this.headerTitles
            : this.headerTitles.filter(it => it.visibleForDefaultProject);
    }

    loadData(page: number, tableFilter: TableFilter, search: string): Observable<PageResponse<Object>> {
        if (search) {
            return this.objectService.searchObjects(this.project.id, page, search);
        }

        return this.objectService.getObjects(this.project.id, page, tableFilter);
    }

    async exportObjects(event: LoadingBtnEvent) {
        try {
            const {filename, data} = await this.objectService.exportObjects(this.project.id).toPromise();
            FileUtil.downloadBlobAsFile(data, filename);
        } finally {
            event.complete();
        }
    }

    async onFileChange(event: FileUploadEvent) {
        try {
            this.importLoading = true;
            const statusId = await this.objectService.importObjects(this.project.id, event.target.files[0]).toPromise();
            const importStatus = await timer(300, 1000).pipe(
                filter(() => this.importLoading),
                switchMap(() => this.objectService.importObjectsStatus(this.project.id, statusId)),
                filter(it => it.status !== 'Queued'),
                take(1)
            ).toPromise();

            if (importStatus.status === 'Finished') {
                this.toast.success('De object(en) zijn succesvol geïmporteerd.');
                await this.refresh();
            } else {
                if (importStatus.lastError) {
                    const fieldErrors = JSON.parse(importStatus.lastError);
                    ErrorToast.handleExcelImportFieldErrors(this.toast, fieldErrors);
                }
            }
        } catch (e) {
            console.error('Failed to import objects', e);

            this.toast.error('Het importeren van object(en) is mislukt');
        } finally {
            event.target.value = '';
            event.complete();
            this.importLoading = false;
        }
    }
}
