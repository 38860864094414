<app-modal modalTitle="Opdracht kopieren" modalSaveBtnLabel="{{'Kopieren'|translate}}" [loadingSaveBtn]="false" (save)="cloneJob()" (cancel)="closeModal('cancelled')">
    <form [formGroup]="form" *ngIf="form">
        <div class="u-mb-m">
            <div [appFormControlError]="form.get('title')" class="vwui-form-group">
                <label class="vwui-label" for="title">{{'Opdrachtnaam'|translate}}</label>
                <input
                    class="vwui-input"
                    id="title"
                    name="title"
                    formControlName="title"
                    autocomplete="off"
                    type="text"
                    placeholder="{{'Opdrachtnaam'|translate}}">
            </div>
        </div>
        <div class="u-mb-m">
            <div [appFormControlError]="form.get('code')" class="vwui-form-group">
                <label class="vwui-label" for="title">{{'Opdrachtnummer'|translate}}</label>
                <input
                    class="vwui-input"
                    id="code"
                    name="code"
                    formControlName="code"
                    autocomplete="off"
                    type="text"
                    placeholder="{{'Opdrachtnummer'|translate}}">
            </div>
            <div *ngIf="form.get('code').hasError('exists') && form.get('code').touched" class="text-danger">
                <small>{{ 'Opdrachtnummer moet uniek zijn'|translate }}</small>
            </div>
        </div>
        <button type="submit" class="d-none"><!-- Needed to submit with enter --></button>
    </form>
</app-modal>
