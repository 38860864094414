import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class ImportTemplateService {
    constructor(private httpClient: HttpClient) {
    }

    getTemplate() {
        return this.httpClient.get(`/api/v1/import-template`, {
            responseType: 'blob'
        });
    }
}
