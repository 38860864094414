import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Inject, Injectable} from '@angular/core';
import {Project} from '../models/project';
import {ProjectService} from '../services/project.service';

@Injectable({
    providedIn: 'root'
})
export class ObjectProjectResolver implements Resolve<Project> {

    constructor(
        @Inject('ProjectService') private projectService: ProjectService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.projectService.getProject(+route.params.project);
    }
}
