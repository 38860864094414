import {ChangeDetectionStrategy, Component, ElementRef, HostListener, Input, ViewChild} from '@angular/core';

@Component({
    selector: 'app-action-menu',
    templateUrl: './action-menu.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionMenuComponent {
    @ViewChild('menuContent', {static: true}) content?: ElementRef;
    @Input() growUpward = false;

    menuOpen = false;

    @HostListener('click', ['$event'])
    @HostListener('touchstart', ['$event'])
    onElementClick(event: Event) {
        console.log('clicked, stopped propagation');
        debugger;
        event.stopPropagation();

        this.closeMenu();
    }

    @HostListener('document:click', ['$event'])
    @HostListener('document:touchstart', ['$event'])
    onDocumentClick(event: Event) {
        this.closeMenu();
    }

    isMenuEmpty() {
        const childNodes: Node[] = Array.from(this.content?.nativeElement.childNodes || []);

        return !childNodes.some(childNode => childNode.nodeType !== Node.COMMENT_NODE);
    }

    openMenu(event: Event) {
        event.stopPropagation();
        event.preventDefault();
        document.body.click();

        this.menuOpen = true;
    }

    closeMenu() {
        this.menuOpen = false;
    }
}
