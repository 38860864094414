import {Component} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-no-jobs-with-location-modal',
  templateUrl: './no-jobs-with-location-modal.component.html'
})
export class NoJobsWithLocationModalComponent {
  constructor(private bsModalRef: BsModalRef) {
  }

  close() {
    this.bsModalRef.hide();
  }

}
