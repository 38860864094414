// @ts-strict-ignore
import {Directive, Inject, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthCheckService} from '../services/auth-check.service';
import {Subscription} from 'rxjs';
import {Authority} from '../models/authority';

@Directive({
    selector: '[appHasRole]'
})
export class HasRoleDirective implements OnInit, OnDestroy {

    @Input('appHasRole') parameters: String;

    private authChangeSubscription: Subscription;

    constructor(
        @Inject('AuthCheckService') private authCheckService: AuthCheckService,
        protected templateRef: TemplateRef<any>,
        protected viewContainer: ViewContainerRef
    ) {}

    ngOnInit(): void {
        this.authChangeSubscription = this.authCheckService
            .authorities()
            .subscribe((authorities) => this.rerender(authorities));
    }

    ngOnDestroy(): void {
        if (this.authChangeSubscription) {
            this.authChangeSubscription.unsubscribe();
        }
    }

    private rerender(authorities: Authority[]) {
        const index = authorities.findIndex(authority => {
            if (Array.isArray(this.parameters)) {
                return this.parameters.some(role => authority.authority === role);
            }

            return authority.authority === this.parameters;
        });

        if (index !== -1) {
            this.viewContainer.clear();
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }
}
