// @ts-strict-ignore
import {Component, EventEmitter, Inject, Input, Output} from '@angular/core';
import {QuestionSetService} from '../../services/question-set.service';
import {QuestionSet} from '../../models/question-set';
import {QuestionSetQuestion} from '../../models/form-item';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {InfiniteScrollPaginator} from '../../utils/infinite-scroll-paginator';

@Component({
    selector: 'app-question-set-card',
    templateUrl: './question-set-card.component.html'
})
export class QuestionSetCardComponent {
    @Input() questionSetsSelected: QuestionSetQuestion[];
    @Output() cancel = new EventEmitter<void>();
    @Output() add = new EventEmitter<QuestionSet>();

    paginator = new InfiniteScrollPaginator(page => this.questionSetService.getQuestionSets(page));
    filteredQuestionSets$: Observable<QuestionSet[]> = this.paginator.content$.pipe(
        map(it => it.filter(questionSet => questionSet.formItems.length > 0))
    );

    selectedQuestionSet: QuestionSet;

    constructor(
        @Inject('QuestionSetService') private questionSetService: QuestionSetService,
    ) {
    }

    selectQuestionSet(questionSet: QuestionSet) {
        this.selectedQuestionSet = questionSet;
    }

    addSelectedQuestionSet() {
        if (this.selectedQuestionSet) {
            this.add.emit(this.selectedQuestionSet);
            this.selectedQuestionSet = null;
        }
    }

    close() {
        this.selectedQuestionSet = null;
        this.cancel.emit();
    }

    isSelected(questionSet: QuestionSet): boolean {
        return this.selectedQuestionSet && this.selectedQuestionSet.id === questionSet.id;
    }
}
