// @ts-strict-ignore
import {Component, OnDestroy, OnInit} from '@angular/core';
import {InterfaceProcessingErrorDetail} from '../../models/interface-processing-error';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-interface-processing-error-detail',
    templateUrl: './interface-processing-error-detail.component.html'
})
export class InterfaceProcessingErrorDetailComponent implements OnInit, OnDestroy {

    form: UntypedFormGroup;
    processingError: InterfaceProcessingErrorDetail;
    private subscriptions: Subscription[] = [];

    constructor(
        private route: ActivatedRoute,
        private translateService: TranslateService
    ) {
        this.form = new UntypedFormGroup({
            message: new UntypedFormControl(''),
            xml: new UntypedFormControl(''),
            data: new UntypedFormControl('')
        });
        this.form.get('message').disable();
        this.form.get('xml').disable();
        this.form.get('data').disable();
    }

    ngOnInit() {
        this.subscriptions.push(
            this.route.data.subscribe((data: {processingError: InterfaceProcessingErrorDetail}) => {
                if (data.processingError) {
                    this.processingError = data.processingError;
                    this.form.patchValue({
                        message: this.translateService.instant(this.processingError.message, this.processingError.data),
                        xml: this.formatXml(this.processingError.xml),
                        data: this.formatJson(this.processingError.data)
                    });
                }
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    private formatXml(xml: string) {
        let formatted = '';
        const reg = /(>)(<)(\/*)/g;
        xml = xml.replace(reg, '$1\r\n$2$3');
        let pad = 0;
        xml.split('\r\n').forEach((node) => {
            let indent = 0;
            if (node.match(/.+<\/\w[^>]*>$/)) {
                indent = 0;
            } else if (node.match(/^<\/\w/)) {
                if (pad !== 0) {
                    pad -= 1;
                }
            } else if (node.match(/^<\w([^>]*[^\/])?>.*$/)) {
                indent = 1;
            } else {
                indent = 0;
            }

            let padding = '';
            for (let i = 0; i < pad; i++) {
                padding += '  ';
            }

            formatted += padding + node + '\r\n';
            pad += indent;
        });

        return formatted;
    }

    private formatJson(data: any) {
        try {
            return JSON.stringify(this.processingError.data, null, 4);
        } catch (e) {
            console.error('formatJson failed', e);
            return '-';
        }
    }
}
