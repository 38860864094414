// @ts-strict-ignore
import {Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Project} from '../../models/project';
import {ToastrService} from 'ngx-toastr';
import {WorkerGroupService} from '../../services/worker-group.service';
import {UserService} from '../../services/user.service';
import {User, UserRoles} from '../../models/user';
import {TableFilter} from '../table/table.component';
import {ProjectCoordinatorsService} from '../../services/project-coordinators.service';
import {filter, switchMap} from 'rxjs/operators';
import {AuthenticationService} from '../../services/authentication.service';

@Component({
    selector: 'app-project-coordinators-tab',
    templateUrl: './project-coordinators-tab.component.html'
})
export class ProjectCoordinatorsTabComponent implements OnChanges {
    @Input() project: Project;
    @Output() projectSaved = new EventEmitter<Project>();

    coordinatorsSubject = new BehaviorSubject(0);
    coordinator$ = this.coordinatorsSubject.pipe(
        filter(id => id > 0),
        switchMap(projectId => {
            return this.projectCoordinatorsService.getCoordinators(projectId);
        })
    );
    userFilter$ = new BehaviorSubject<TableFilter>({filter: {roles: UserRoles.RoleCoordinator}});
    loggedInEmail$ = this.authenticationService.loggedInEmail$;

    constructor(
        @Inject('UserService') private userService: UserService,
        @Inject('ProjectCoordinatorsService') private projectCoordinatorsService: ProjectCoordinatorsService,
        @Inject('WorkerGroupService') private workerGroupService: WorkerGroupService,
        private authenticationService: AuthenticationService,
        private toast: ToastrService
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.project && changes.project.currentValue) {
            const project = (changes.project.currentValue as Project);
            this.coordinatorsSubject.next(+project.id);
        }
    }

    async addUser(coordinator: User) {
        try {
            const project = await this.projectCoordinatorsService.addCoordinator(this.project.id, coordinator.id).toPromise();

            this.projectSaved.emit(project);
            this.userFilter$.next(this.userFilter$.value);
            this.toast.success('Opgeslagen');
        } catch (e) {
            this.toast.error('Opslaan mislukt');
        }
    }

    async removeUser(coordinator: User) {
        try {
            const project = await this.projectCoordinatorsService.removeCoordinator(this.project.id, coordinator.id).toPromise();

            this.projectSaved.emit(project);
            this.userFilter$.next(this.userFilter$.value);
            this.toast.success('Verwijderd');
        } catch (error) {
            this.toast.error('Verwijderen mislukt');
        }
    }

    sortByDisplayName(coordinators: Array<User>) {
        return coordinators.sort(
            (a: User, b: User) => {
                    return a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1
            }
        )
    }
}
