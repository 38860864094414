import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ArcgisToken} from "../models/arcgis-token";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ArcgisService {
    constructor(
        private client: HttpClient
    ) {
    }

    getToken(
        portalToken?: string,
        serverUrl?: string
    ): Observable<ArcgisToken> {
        const data = new FormData()
        if (portalToken && serverUrl) {
            data.set('token', portalToken)
            data.set('serverUrl', serverUrl)
        }

        return this.client.post<ArcgisToken>('/api/v1/arcgis/token', data)
    }
}
