// @ts-strict-ignore
import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-users-table',
  templateUrl: './users-table.component.html'
})
export class UsersTableComponent {
  @Input() title: string;
  @Input() total: number;
}
