// @ts-strict-ignore
import {Component} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-image-annotation-modal',
  template: '<app-image-annotation [imageSrc]="imageSrc" (cancel)="onCancel()" (save)="onSave($event)"></app-image-annotation>',
})
export class ImageAnnotationModalComponent {
    imageSrc: string;
    saveCallback: (data: string) => Promise<void>;

    constructor(private modalRef: BsModalRef) {
    }

    onCancel() {
        this.modalRef.hide();
    }

    async onSave(data: string) {
        if (this.saveCallback) {
            await this.saveCallback(data);
        }
        this.modalRef.hide();
    }
}
