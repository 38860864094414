// @ts-strict-ignore
import {ProjectJobForm} from '../models/job-form/project-job-form';
import {Question} from '../models/job-form/question';
import {ProjectJobAnswer} from '../models/job-form/project-job-answer';
import {ProjectJobFormChapter} from '../models/job-form/project-job-form-chapter';
import {QuestionChoice} from '../models/job-form/question-choice';
import {AbstractFormItem, AbstractQuestion, FormItem, QuestionType} from '../models/form-item';

export class FormUtils {
    static mergeForm(upstream: ProjectJobForm, local: ProjectJobForm) {
        const form: ProjectJobForm = {
            ...local,
            ...upstream
        };

        form.answers = upstream.answers;
        local.answers.forEach(answer => {
            // Add local answer if it doesnt exist upstream
            if (-1 === form.answers.findIndex(it => it.position === answer.position)) {
                form.answers.push(answer);
            }
        });

        return form;
    }

    static getChapter(form: ProjectJobForm): ProjectJobFormChapter {
        for (const chapter of form.chapters) {
            if (chapter.position === (Math.floor(form.currentPosition / 1000) * 1000)) {
                return chapter;
            }
        }
    }

    static getFormItem(form: ProjectJobForm): Question {
        for (const chapter of form.chapters) {
            for (const question of chapter.questions) {
                if (question.position === form.currentPosition) {
                    return question;
                }
            }
        }
    }

    static getFormItemAnswers(answers: ProjectJobAnswer[], position: number): ProjectJobAnswer[] {
        return answers.filter(answer => answer.position === position);
    }

    static getFormItemLastAnswer(answers: ProjectJobAnswer[], position: number): ProjectJobAnswer {
        const matchingAnswers = this.getFormItemAnswers(answers, position);

        if (matchingAnswers && matchingAnswers.length) {
            return matchingAnswers.reduce((prev, current) => prev.revision > current.revision ? prev : current);
        }
    }

    static getFormItemAnswer(answers: ProjectJobAnswer[], position: number, revision: number): ProjectJobAnswer {
        for (const answer of answers) {
            if (answer.position === position && answer.revision === revision) {
                return answer;
            }
        }
    }

    static getAnswer(form: ProjectJobForm) {
        const { answers, currentPosition, status } = form;

        const matchingAnswers = answers.filter(answer => answer.position === currentPosition);

        if (matchingAnswers.length > 0) {
            return matchingAnswers.reduce((prev, current) => prev.revision > current.revision ? prev : current);
        }
    }

    static getRevisionAnswer(form: ProjectJobForm) {
        const { answers, currentPosition, answerRevision } = form;

        return FormUtils.getFormItemAnswer(answers, currentPosition, answerRevision);
    }

    static getPreviousPosition(form: ProjectJobForm): number {
        let previousQuestionPosition = null;
        for (const chapter of form.chapters) {
            for (const question of chapter.questions) {
                if (question.position === form.currentPosition) {
                    return previousQuestionPosition;
                } else if (this.isQuestionVisible(form, question.position)) {
                    previousQuestionPosition = question.position;
                }
            }
        }
    }

    static getNextPosition(form: ProjectJobForm): number {
        let foundCurrentQuestion = false;
        for (const chapter of form.chapters) {
            for (const question of chapter.questions) {
                if (question.position === form.currentPosition) {
                    foundCurrentQuestion = true;
                } else if (foundCurrentQuestion && this.isQuestionVisible(form, question.position)) {
                    return question.position;
                }
            }
        }
    }

    static isQuestionVisible(form: ProjectJobForm, position: number): boolean {
        const question = this.getQuestionByPosition(form, position);
        if (!question.questionDependency || question.questionDependency.length === 0) {
            return true;
        }

        return -1 !== question.questionDependency.findIndex(choice => {
            return this.isQuestionChoiceSelected(form, choice);
        });
    }

    static isChapterVisible(form: ProjectJobForm, position: number): boolean {
        const chapter = this.getChapterByPosition(form, position);
        if (!chapter.questionDependency || chapter.questionDependency.length === 0) {
            return true;
        }

        return -1 !== chapter.questionDependency.findIndex(choice => {
            return this.isQuestionChoiceSelected(form, choice);
        });
    }

    static getQuestionByPosition(form: ProjectJobForm, position: number): Question {
        for (const chapter of form.chapters) {
            for (const question of chapter.questions) {
                if (question.position === position) {
                    return question;
                }
            }
        }
        throw new Error(`Couldn't find question with position ${position}`);
    }

    static getChapterByPosition(form: ProjectJobForm, position: number): ProjectJobFormChapter {
        for (const chapter of form.chapters) {
            if (chapter.position === position) {
                return chapter;
            }
        }
        throw new Error(`Couldn't find chapter with position ${position}`);
    }

    static isQuestionChoiceSelected(form: ProjectJobForm, choice: QuestionChoice): boolean {
        for (const chapter of form.chapters) {
            for (const question of chapter.questions) {
                if (question.type === 'choice' && -1 !== question.choices.findIndex(it => it.id === choice.id)) {
                    const lastAnswer = this.getFormItemLastAnswer(form.answers, question.position);
                    return lastAnswer && lastAnswer.value && -1 !== lastAnswer.value.toString().indexOf(choice.id);
                }
            }
        }
    }

    static getLatestImageUUIDsForForm(form: ProjectJobForm): string[] {
        const imageUUIDs: string[] = [];

        for (const chapter of form.chapters) {
            for (const question of chapter.questions) {
                // Helptext image UUID
                this.addImageUUIDForHelpQuestion(imageUUIDs, question);

                // Add images for last question answer only
                const answer = FormUtils.getFormItemLastAnswer(form.answers, question.position);
                this.addImageUUIDsForQuestionAndAnswer(imageUUIDs, question, answer);
            }
        }

        return imageUUIDs;
    }

    static getAllImageUUIDsForForm(form: ProjectJobForm): string[] {
        const imageUUIDs: string[] = [];

        for (const chapter of form.chapters) {
            for (const question of chapter.questions) {
                // Helptext image UUID
                this.addImageUUIDForHelpQuestion(imageUUIDs, question);

                const questionAnswers = this.getFormItemAnswers(form.answers, question.position);

                // Add image UUIDs for all available and relevant answer values
                for (const answer of questionAnswers) {
                    this.addImageUUIDsForQuestionAndAnswer(imageUUIDs, question, answer);
                }
            }
        }

        return imageUUIDs;
    }

    private static addImageUUIDForHelpQuestion(imageUUIDs: string[], question: Question) {
        if (question.descriptionType === 'image' && question.imageDescription !== null) {
            imageUUIDs.push(question.imageDescription);
        }
    }

    /**
     * Adds image UUID's for the given question ans answer combination to the given Array of imageUUIDs.
     * @param imageUUIDs string[] of image UUIDs
     * @param question Question
     * @param answer ProjectJobAnswer
     */
    private static addImageUUIDsForQuestionAndAnswer(imageUUIDs: string[], question: Question, answer: ProjectJobAnswer) {
        // Images from photo value
        if (question.type === 'photo') {
            if (answer && answer.value !== null) {
                const images = answer.value
                    ? answer.value.split(',').filter(item => item != '')
                    : [];
                images.forEach((image) => imageUUIDs.push(image));
            }
        }

        // Images from signature value
        if (question.type === 'signature') {
            if (answer && answer.value !== null) {
                imageUUIDs.push(answer.value);
            }
        }
    }

    /**
     * Determines the dependent question number for the given formItem
     * @param formItemToCheck           The form item to check for dependencies
     * @param formItems                 The form items on which the given form item can possibly depend
     * @param offsetChapter             In question sets the eventual chapter can be determined by appending
     *                                  the question set chapter to the question chapter
     */
    public static dependentQuestionPosition(formItemToCheck: AbstractFormItem | AbstractQuestion, formItems: FormItem[], offsetChapter: number = 1) {
        if (!('questionDependency' in formItemToCheck)) {
            return;
        }

        const firstDependentChoiceId = (formItemToCheck.questionDependency || []).map(it => it.id)[0];

        if (!firstDependentChoiceId || !formItems) {
            return null;
        }

        for (const [, question] of formItems.entries()) {
            if (question.type === QuestionType.choice
                && question.choices.map(it => it.id).includes(firstDependentChoiceId)) {
                return `${FormUtils.getChapterNumberFromPosition(question.position, offsetChapter)}.${FormUtils.getChapterPositionFromPosition(question.position)}`;
            }
        }
    }

    /**
     * Gets the chapter number based on the position value in the form item
     * @param formPosition              The position of the form item ( question, question-set or chapter )
     * @param offsetChapter             In question sets the eventual chapter can be determined by appending
     *                                   the question set chapter to the question chapter
     * @return integer
     */
    public static getChapterNumberFromPosition(formPosition: number, offsetChapter: number = 1) {
        return Math.floor(formPosition / 1000) + offsetChapter - 1;
    }

    /**
     * Determines the question number inside of the given chapter
     * @param formPosition             The position of the form item ( question, question-set or chapter )
     */
    public static getChapterPositionFromPosition(formPosition: number) {
        return formPosition - FormUtils.getChapterNumberFromPosition(formPosition) * 1000;
    }

    /**
     * Get a list of all previous questions that can induce dependencies
     * @param formItemPosition        Anything before this form item position is fair game to depend on
     * @param allFormItems            The complete set of form items the question can depend on
     *                                 NOTE - This means that question sets cannot depend outside of their scope
     *                                 And that questions below this question set cannot depend on questions inside the question set
     *
     * @return FormItem[]
     */
    public static getPossibleDependencies(formItemPosition: number, allFormItems: FormItem[]) {
        return allFormItems.map((item) => {
            if (item.type === QuestionType.chapter ) {
                return item.children;
            } else if (item.type === QuestionType.questionSet ) {
                return [];
            } else {
                return [item];
            }
        }).reduce((acc, val) => acc.concat(val), [])
        .filter((item) => {
            return item.type === QuestionType.choice && item.position < formItemPosition;
        });
    }
}
