// @ts-strict-ignore
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {LoadingBtnEvent} from './loading-btn.interface';

@Component({
    selector: 'app-loading-btn',
    templateUrl: './loading-btn.component.html'
})
export class LoadingBtnComponent {
    @Input() iconName: string;
    @Input() class: string;
    @Input() disabled = false;

    @Output() btnClick = new EventEmitter<LoadingBtnEvent>();

    showLoader = false;

    onClick() {
        this.showLoader = true;

        this.btnClick.emit({
            complete: () => this.showLoader = false
        });
    }
}
