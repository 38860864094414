// @ts-strict-ignore
import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {WorkerGroup} from '../../models/worker-group';
import {SearchConfig} from '../../components/users-search/users-search.component';
import {BehaviorSubject, Observable, of, Subscription, switchMap} from 'rxjs';
import {catchError, debounceTime, map, take} from 'rxjs/operators';
import {GraphService} from '../../services/graph.service';
import {ToastrService} from 'ngx-toastr';
import {WorkerGroupService} from '../../services/worker-group.service';
import {Subscriptions} from '../../utils/subscriptions';
import {ActivatedRoute, Router} from '@angular/router';
import {
    UserExecutionGroupModalComponent
} from '../../components/user-execution-group-modal/user-execution-group-modal.component';
import {WorkerGroupUser} from '../../models/worker-group-user';
import {GraphUser} from '../../models/graph-user';
import {BsModalService} from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-worker-group-builder',
    templateUrl: './worker-group-builder.component.html'
})
export class WorkerGroupBuilderComponent implements OnInit, OnDestroy {

    @Input() workerGroup: WorkerGroup;
    @Output() workerGroupSaved = new EventEmitter<WorkerGroup>();

    private subscriptions: Subscription[] = [];

    search$ = new BehaviorSubject('');

    searchConfigs$: Observable<SearchConfig[]> = this.graphService.getGraphs().pipe(
        map(graphs => graphs.map(graph => ({
            title: graph.title,
            isGroup: false,
            searchData: this.search$.pipe(
                debounceTime(100),
                switchMap(search => this.graphService.getUsers(graph.id, search).pipe(
                    catchError((err) => {
                        console.error(err);
                        return of([]);
                    })
                )),
                map(it => it.filter(user => {
                    return -1 === this.workerGroup.workerGroupUsers.findIndex(workerGroupUser => {
                        return workerGroupUser.user.username.toLowerCase() === user.username.toLowerCase();
                    });
                })),
            )
        })))
    );

    constructor(
        @Inject('GraphService') private graphService: GraphService,
        @Inject('WorkerGroupService') private workerGroupService: WorkerGroupService,
        private toast: ToastrService,
        private route: ActivatedRoute,
        private router: Router,
        private modalService: BsModalService
    ) {
    }

    ngOnDestroy(): void {
        Subscriptions.unsubscribeAll(this.subscriptions);
    }

    ngOnInit(): void {
        const routeDataSubscription = this.route.data.subscribe((data) => {
            if (data.workergroup) {
                this.workerGroup = data.workergroup;
            }
        });

        this.subscriptions.push(routeDataSubscription);
    }

    get workerGroupTitle() {
        if (this.workerGroup) {
            return this.workerGroup.name;
        }
    }

    goBack() {
        if (this.workerGroup) {
            this.router.navigate([`/beheer/workergroups/`, this.workerGroup.id]);
        }
    }

    searchChanged(search: string) {
        this.search$.next(search);
    }

    openUserModal(workerGroupUser: WorkerGroupUser) {
        let userIndex = this.workerGroup.workerGroupUsers.findIndex(
            it => it.user.username === workerGroupUser.user.username
        );

        const modal = this.modalService.show(UserExecutionGroupModalComponent, {
            class: 'modal-dialog-centered',
            initialState: {executionGroupHolder: workerGroupUser} as Partial<UserExecutionGroupModalComponent>
        });
        const userExecutionGroupModal = modal.content as UserExecutionGroupModalComponent;

        this.modalService.onHidden.pipe(
            take(1)
        ).subscribe(async () => {
            if (userExecutionGroupModal.result === 'confirmed') {
                workerGroupUser.executionGroups = userExecutionGroupModal.form.controls.executionGroups.value;

                if (userIndex !== -1) {
                    this.workerGroup.workerGroupUsers[userIndex] = workerGroupUser;
                } else {
                    userIndex = this.workerGroup.workerGroupUsers.push(workerGroupUser) - 1;
                }

                try {
                    await this.workerGroupService.putWorkerGroup(this.workerGroup).toPromise();
                    this.search$.next(this.search$.value);
                    this.toast.success('Opgeslagen');
                } catch (e) {
                    this.toast.error('Opslaan mislukt');
                }
            }
        });
    }

    addUser(user: GraphUser) {
        this.openUserModal({workerGroup: this.workerGroup.id, user, executionGroups: []});
    }

    async removeUser(user: GraphUser) {
        try {
            this.workerGroup.workerGroupUsers = this.workerGroup.workerGroupUsers.filter(wgu => wgu.user.username !== user.username);

            await this.workerGroupService.putWorkerGroup(this.workerGroup).toPromise();

            this.search$.next(this.search$.value);
            this.toast.success('Verwijderd');
        } catch (error) {
            this.toast.error('Verwijderen mislukt');
        }
    }
}
