<div class="vwui-container is-full">
    <div class="row">
        <div class="col">
            <div class="vwui-card">
                <div class="vwui-card__header">{{'Opdrachteigenschappen'|translate}}</div>
                <div class="vwui-card__content">
                    <form [formGroup]="form" (submit)="save()" [class.form-is-valid]="form.valid">
                        <div class="vwui-form-group u-mb-m">
                            <label for="code" class="vwui-label">{{'Opdrachtnummer'|translate}}</label>
                            <input id="code" class="vwui-input is-plain" name="code" type="text"
                                   autocomplete="off"
                                    disabled [value]="projectJob.code">
                        </div>
                        <div class="vwui-form-group u-mb-m" [appFormControlError]="form.get('title')">
                            <label for="title" class="vwui-label">{{'Omschrijving'|translate}}</label>
                            <input id="title" class="vwui-input" name="title" formControlName="title" type="text"
                                   autocomplete="off">
                            <div *ngIf="form.get('title').getError('maxlength') as error" class="text-danger">
                                <small>
                                    {{ ('Maximaal'|translate) + ' ' + error['requiredLength'] + ' ' + ('karakters'|translate) }}
                                    ({{ ('huidige invoer'|translate) + ': ' + error['actualLength'] }})
                                </small>
                            </div>
                        </div>
                        <div class="vwui-form-group u-mb-m" [appFormControlError]="form.get('startDate')">
                            <label for="startDate" class="vwui-label">{{'Geplande startdatum - tijd'|translate}}</label>
                            <input id="startDate" class="vwui-input" name="startDate" formControlName="startDate"
                                   type="text" mwlFlatpickr [convertModelValue]="true" altFormat="d-m-Y H:i" dateFormat="Z"
                                   [altInput]="true" altInputClass="vwui-input" [enableTime]="true" [time24hr]="true">
                        </div>
                        <ng-container [formGroup]="form.controls.extraFields">
                            <div class="vwui-form-group u-mb-m"
                                 [appFormControlError]="form.get('extraFields.locationFrom')">
                                <label for="locationFrom" class="vwui-label">
                                    {{ projectJob.paulaObject.objectLocationType === 'Linear' ? ('Omschrijving locatie van'|translate) : ('Omschrijving locatie'|translate) }}
                                </label>
                                <input id="locationFrom" class="vwui-input" name="locationFrom"
                                       formControlName="locationFrom" type="text" autocomplete="off">
                            </div>
                            <div class="vwui-form-group u-mb-m"
                                 *ngIf="projectJob.paulaObject.objectLocationType === 'Linear'"
                                 [appFormControlError]="form.get('extraFields.locationTo')">
                                <label for="locationTo" class="vwui-label">{{'Omschrijving locatie tot'|translate}}</label>
                                <input id="locationTo" class="vwui-input" name="locationTo"
                                       formControlName="locationTo" type="text" autocomplete="off">
                            </div>
                            <div class="vwui-form-group u-mb-m"
                                 [appFormControlError]="form.get('extraFields.gpsLatitudeFrom')">
                                <label class="vwui-label">{{'GPS coördinaten'|translate}}</label>
                                <app-job-location-select-map
                                    [coordinates]="this.form.get('extraFields').value"
                                    (coordinatesPicked)="onLocationSelected($event)"
                                    [enabled]="form.enabled"
                                ></app-job-location-select-map>
                            </div>
                        </ng-container>
                        <button type="submit" class="d-none"></button>
                    </form>
                </div>
            </div>
        </div>
        <div class="col" [formGroup]="form">
            <app-file-upload formControlName="attachments" class="d-block mb-5"></app-file-upload>
            <div class="vwui-card">
                <div class="vwui-card__header">{{'Opdrachtinformatie'|translate}}</div>
                <div class="vwui-card__content">
                    <div class="vwui-form-group u-mb-m">
                        <label for="projectName" class="vwui-label">{{ 'Projectnaam'|translate }}</label>
                        <input id="projectName" class="vwui-input is-plain" name="projectName" type="text"
                               autocomplete="off"
                               disabled [value]="projectJob.projectName">
                    </div>
                    <div class="vwui-form-group u-mb-m">
                        <label for="paulaObject" class="vwui-label">Object</label>
                        <input id="paulaObject" class="vwui-input is-plain" name="paulaObject" type="text"
                               autocomplete="off"
                               disabled [value]="projectJob.paulaObject.objectOmschrijvingKort">
                    </div>
                    <div class="vwui-form-group u-mb-m">
                        <label for="createdBy" class="vwui-label">{{'Coordinator'|translate}}</label>
                        <input id="createdBy" class="vwui-input is-plain" name="createdBy" type="text"
                               autocomplete="off"
                               disabled [value]="projectJob.createdBy || 'Systeem'">
                    </div>
                    <div class="vwui-form-group u-mb-m">
                        <label for="originalProjectFormTitle" class="vwui-label">{{'Gekoppeld formulier'|translate}}</label>
                        <input id="originalProjectFormTitle" class="vwui-input is-plain"
                               name="originalProjectFormTitle" type="text" autocomplete="off"
                               disabled [value]="projectJob.originalProjectForm?.title">
                    </div>
                    <div class="vwui-form-group u-mb-m" *ngIf="this.projectJob.originalProjectJob as originalProjectJob">
                        <label for="originalProjectJob" class="vwui-label">{{'Voorafgaande opdracht'|translate}}</label>
                        <input id="originalProjectJob" class="vwui-input is-plain"
                               name="originalProjectJob" type="text" autocomplete="off"
                               disabled [value]="originalProjectJob.title">
                    </div>
                    <div class="vwui-form-group u-mb-m" *ngIf="getOriginatedQuestion() as question">
                        <label for="originatedQuestion" class="vwui-label">{{'Voorafgaande vraag'|translate}}</label>
                        <input id="originatedQuestion" class="vwui-input is-plain"
                               name="originatedQuestion" type="text" autocomplete="off"
                               disabled [value]="question.title">
                    </div>
                    <div class="vwui-form-group u-mb-m">
                        <label for="deadline" class="vwui-label">{{'Hersteltermijn'|translate}}</label>
                        <input id="deadline" class="vwui-input is-plain" name="deadline" type="text"
                               autocomplete="off"
                               disabled [value]="(projectJob.deadline | dateFormat) || '-'">
                    </div>
                    <div class="vwui-form-group u-mb-m">
                        <label for="status" class="vwui-label">{{'Status'|translate}}</label>
                        <input id="status" class="vwui-input is-plain" name="status" type="text" autocomplete="off"
                               disabled [value]="projectJob.status | ProjectJobStatus | translate">
                    </div>
                    <div class="vwui-form-group u-mb-m">
                        <label for="statusUpdatedAt" class="vwui-label">{{'Statusdatum - tijd'|translate}}</label>
                        <input id="statusUpdatedAt" class="vwui-input is-plain" name="statusUpdatedAt" type="text"
                               autocomplete="off"
                               disabled [value]="(getLastStatusChange(projectJob) | dateFormat) || '-'">
                    </div>
                    <div class="vwui-form-group u-mb-m">
                        <label for="inspectorNames" class="vwui-label">{{'Keurder'|translate}}</label>
                        <input id="inspectorNames" class="vwui-input is-plain" name="inspectorNames" type="text"
                               autocomplete="off"
                               disabled [value]="getInspectorNames(projectJob)">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-actionbar *ngIf="form.dirty" (save)="save()" (cancel)="cancel()"></app-actionbar>
