// @ts-strict-ignore
import {Component, Inject, Input, OnInit} from '@angular/core';
import {FormType} from '../../models/form-type';
import {FormTypeService} from '../../services/form-type.service';
import {take} from 'rxjs/operators';
import {FormItem} from '../../models/form-item';

@Component({
    selector: 'app-form-type-example',
    templateUrl: './form-type-example.component.html'
})
export class FormTypeExampleComponent implements OnInit {
    public formType: FormType;
    public formItems: FormItem[] = [];
    public formIdValue: any;

    @Input()
    set form(form: FormType) {
        this.formType = form;
        this.formIdValue = form.id;
        this.getFormItems();
    }

    constructor(
        @Inject('FormTypeService') private formTypeService: FormTypeService
    ) { }

    ngOnInit() {
        this.getFormItems();
    }

    public getFormItems() {
        this.formTypeService.getDetail(this.formIdValue).pipe(take(1)).subscribe(data => {
            this.formItems = data.formItems;
        });
    }
}
