// @ts-strict-ignore
import {Component, ContentChild, Input, TemplateRef} from '@angular/core';
import {NavItem, NavLink} from './nav.interface';
import {NavService} from './nav.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html'
})
export class NavComponent {
    @ContentChild(TemplateRef) templateRef: TemplateRef<unknown>
    @Input() readonly mainNavItems: NavItem[];
    @Input() readonly bottomNavItems: NavLink[];

    navOpen: boolean;

    private $navOpenSubscription: Subscription;

    constructor(
        private navService: NavService
    ) {
        this.$navOpenSubscription = navService.navOpen.subscribe(navOpen => this.navOpen = navOpen);
    }

    get hasBottomNavItems() {
        return this.bottomNavItems && this.bottomNavItems.length > 0;
    }

    toggleNav() {
        this.navService.toggleNav();
    }
}
