import {ActivatedRoute, Router} from '@angular/router';
import {TableFilter} from '../components/table/table.component';
import {Filters} from '../utils/filters';
import {BehaviorSubject, combineLatest, finalize, Observable, of, Subscription} from 'rxjs';
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    filter,
    first,
    map,
    mapTo,
    shareReplay,
    startWith,
    switchMap
} from 'rxjs/operators';
import {Paginator} from '../utils/paginator';
import {createEmptyPageResponse, PageResponse} from '../models/page-response';
import {UntypedFormControl} from '@angular/forms';
import {Directive, OnDestroy, OnInit} from '@angular/core';
import {Subscriptions} from '../utils/subscriptions';
import {mergeQueryParamsAndNavigate} from "../utils/merge-query-params-and-navigate";

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class AbstractListComponent<T> implements OnInit, OnDestroy {
    private refresh$ = new BehaviorSubject<number>(1);

    protected tableFilter$ = this.activatedRoute.queryParams.pipe(
        map(it => Filters.getFilteringFromParams(it)),
        filter(it => it !== null),
        startWith(null),
        distinctUntilChanged((previous, current) => {
            return JSON.stringify(previous) === JSON.stringify(current);
        }),
        debounceTime(300),
        shareReplay(1)
    );

    protected search$ = this.activatedRoute.queryParams.pipe(
        map(it => Filters.getSearchFromParams(it)),
        startWith(null),
        distinctUntilChanged(),
        debounceTime(100)
    );

    searchField = new UntypedFormControl();

    paginator$ = combineLatest([
        combineLatest([this.tableFilter$, this.search$]).pipe(
            distinctUntilChanged((previous, current) => {
                let prevFilter, prevSearch, prevPage;
                [prevFilter, prevSearch] = previous;

                let currentFilter, currentSearch, currentPage;
                [currentFilter, currentSearch] = current;

                return prevSearch === currentSearch && JSON.stringify(prevFilter) === JSON.stringify(currentFilter);
            })
        ),
        this.refresh$
    ]).pipe(
        switchMap((values) => {
            let [[tableFilter, search]] = values;

            // Wait for paginator to emit content before setting it as new paginator to prevent flash of empty data
            const paginator = new Paginator(page => {
                return this.loadData(page, tableFilter, search).pipe(
                    catchError<PageResponse<T>, Observable<PageResponse<T>>>(error => {
                        console.error(error);

                        return of(createEmptyPageResponse<T>())
                    })
                );
            }, this.isInitialLoad && +this.activatedRoute.snapshot.queryParams['page'] || 0);
            this.isInitialLoad = false;

            const pageSubscription = paginator.page$.subscribe(newPage => {
                mergeQueryParamsAndNavigate(
                    this.router,
                    this.activatedRoute,
                    {page: newPage.toString()}
                );
            });

            return paginator.content$.pipe(
                mapTo(paginator),
                finalize(() => {
                    pageSubscription.unsubscribe();
                })
            );
        }),
        shareReplay(1),
    );

    protected subscriptions: Subscription[] = [];
    private isInitialLoad = true;

    protected constructor(
        protected activatedRoute: ActivatedRoute,
        protected router: Router,
    ) {
    }

    ngOnInit(): void {
        this.isInitialLoad = true;
        this.activatedRoute.queryParams.pipe(first()).subscribe(
            (params) => {
                if (params.q) {
                    this.searchField.setValue(params.q, {emitEvent: false});
                }
            }
        ).unsubscribe();

        this.subscriptions.push(
            this.searchField.valueChanges
                .pipe(debounceTime(200))
                .subscribe(value => this.onSearchChange(value)),
            this.tableFilter$.subscribe(tableFilter => {
                // Clear search field when sorting or filter is active
                if (tableFilter) {
                    const hasFilter = Object.keys(tableFilter.filter).length > 0;
                    const hasSort = tableFilter.sort && tableFilter.sort.sort !== undefined && tableFilter.sort.direction !== undefined;

                    if (hasFilter || hasSort) {
                        this.searchField.setValue('', {emitEvent: false});
                    }
                }
            })
        );
    }

    ngOnDestroy(): void {
        Subscriptions.unsubscribeAll(this.subscriptions);
    }

    abstract loadData(page: number, filter: TableFilter | null, search?: string): Observable<PageResponse<T>>;

    refresh() {
        this.refresh$.next(1);
    }

    onTableFilterChange(tableFilter: TableFilter) {
        // Also reset page to 0 when filters change
        Filters.saveFilteringToRoute(tableFilter, this.activatedRoute, this.router, {page: 0});
    }

    onSearchChange(search: string) {
        Filters.saveSearchToRoute(search, this.activatedRoute, this.router);
    }
}
