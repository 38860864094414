<app-modal modalTitle="Nieuwe opdracht" modalSaveBtnLabel="{{'Toevoegen'|translate}}" [loadingSaveBtn]="true" (save)="submit()" (cancel)="closeModal('cancelled')">
    <form [formGroup]="form" *ngIf="form">
        <div class="u-mb-m">
            <div [appFormControlError]="form.get('project')" class="vwui-form-group">
                <label for="project" class="vwui-label">{{'Project'|translate}}</label>
                <app-project-select [projectActive]="'actief'" id="project" formControlName="project"></app-project-select>
            </div>
        </div>
        <div class="u-mb-m">
            <div [appFormControlError]="form.get('projectForm')" class="vwui-form-group mb-4">
                <label for="projectForm" class="vwui-label">{{'Formulier'|translate}}</label>
                <app-project-form-select id="projectForm" formControlName="projectForm" [projectFilter]="form.get('project').value"></app-project-form-select>
            </div>
        </div>
        <div class="u-mb-m">
            <div [appFormControlError]="form.get('title')" class="vwui-form-group">
                <label class="vwui-label" for="title">{{'Opdrachtnaam'|translate}}</label>
                <input
                    class="vwui-input"
                    id="title"
                    name="title"
                    formControlName="title"
                    autocomplete="off"
                    type="text"
                    placeholder="{{'Opdrachtnaam'|translate}}">
            </div>
        </div>
        <div class="u-mb-m">
            <div [appFormControlError]="form.get('code')" class="vwui-form-group">
                <label class="vwui-label" for="title">{{'Opdrachtnummer'|translate}}</label>
                <input
                    class="vwui-input"
                    id="code"
                    name="code"
                    formControlName="code"
                    autocomplete="off"
                    type="text"
                    placeholder="{{'Opdrachtnummer'|translate}}">
            </div>
        </div>
        <div class="u-mb-m">
            <div [appFormControlError]="form.get('objects')" class="vwui-form-group">
                <label for="objects" class="vwui-label">{{'Object'|translate}}</label>
                <app-object-select id="objects" formControlName="objects" [project]="form.get('project').value" [objectTypes]="form.get('projectForm').value?.paulaObjectTypes"></app-object-select>
            </div>
        </div>
        <button type="submit" class="d-none"><!-- Needed to submit with enter --></button>
    </form>
</app-modal>
