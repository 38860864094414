<div class="app-login-overlay" *ngIf="(authenticationState$ | async) !== 'authenticated'">
    <div class="app-login-overlay__container">
        <div class="vwui-card">
            <div class="vwui-card__header">{{'application.title'|translate}}</div>
            <div class="vwui-card__content">
                <p>{{'Klik hieronder om in te loggen met uw account'|translate}}</p>
                <button
                    class="vwui-btn is-primary"
                    [disabled]="(authenticationState$ | async) === 'waiting_for_popup'"
                    (click)="startInteractiveAuthentication()">{{'Inloggen'|translate}}
                </button>
            </div>
        </div>
    </div>
    <div class="app-login-overlay__backdrop"></div>
</div>
